import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'delivery-stats',
  templateUrl: './delivery-stats.component.html',
  styleUrls: ['./delivery-stats.component.css']
})
export class DeliveryStatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
