import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { field } from '../../../../interfaces/global.interfaces';
import { NULL, isEmpty, maxWidthOfColumnInfo, typeStyle, derriveField, splitCamelCase } from '../dynamic-table/dynamic-table.component';
import { Type } from '../../../../enums';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng';
import { ExcelService } from '../../../../services/excel.service';

@Component({
  selector: 'dynamic-table-basic',
  templateUrl: './dynamic-table-basic.component.html',
  styleUrls: ['./dynamic-table-basic.component.scss']
})
export class DynamicTableBasicComponent implements OnInit {
  @ViewChild('dt', { static: false }) table: Table;
  @Input('data') public getData: any[] = [];
  @Input('title') public title: string = "";
  @Input('paginator') public paginator: boolean = false;
  @Input('export') public export: boolean = false;
  @Input('exportFileName') public exportFileName: string = '';
  @Output() onAny: EventEmitter<any> = new EventEmitter();
  public data: any[] = [];
  public fields: field[] = [];
  public globalFilters: string[] = [];
  public exportingExcel: boolean = false;
  public typeStyle = typeStyle;
  public derriveField = derriveField;
  public splitCamelCase = splitCamelCase;

  constructor(private messageService: MessageService, private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.globalFilters = [];
    this.fields = [];
    this.data = [];
    if (this.getData.length === 0) return;

    // set global filters
    this.globalFilters = this.excludeFields();
    // set fields
    this.fields = this.globalFilters.map(fieldName => {
      return {
        key: fieldName,
        header: this.splitCamelCase(fieldName),
        type: this.derriveField(fieldName, this.getData.map(o => o[fieldName])),
        width: maxWidthOfColumnInfo(fieldName, this.getData)
      };
    });
    const filters = Object.keys(this.getData[0]);
    //only if there are theres fields then we will add the actions column
    const hasFields = ['dbnnumber', 'route', 'consigneelegalentityid', 'legalentityid'];
    const found = filters.some(x => hasFields.includes(x.toLowerCase()));
    if (found) {
      this.fields.push({
        header: 'Actions',
        key: 'actions',
        type: Type.BUTTON,
        width: '150px'
      });
    }
    // set data
    this.data = this.getData;
    this.fields.forEach(f => {
      for (let i = 0; i < this.data.length; i++) {
        const v = this.getData[i];
        this.data[i][f.key] = isEmpty(v[f.key]) ? NULL : v[f.key];
      }
    });
  }

  public viewFileOwnerTasks(record: any) {
    this.onAny.emit(record);
  }
  private excludeFields(): string[] {
    //exclude fields NEEDS to be lower case
    const excludeFields = ['route', 'usertaskname', 'id', 'user', 'assigneduser', 'colour', 'reference', 'name', 'assignee'];
    const allKeys = Object.keys(this.getData[0]);
    return _.filter(allKeys, x => {
      return !excludeFields.includes(x.toLowerCase()) && x.substring(x.length - 2, x.length) != 'Id';
    });
  }
  public viewFile(dbnNumber: string) {
    const fileId = dbnNumber.split('/')[1];
    window.open(`file-view?query=${fileId}`);
  }
  public viewConsignee(legalEntityId: number, legalEntityTypeId: number) {
    window.open(`manage-legal-entity/${legalEntityId}/${legalEntityTypeId}`);
  }
  public async generateExcel() {
    this.exportingExcel = true;
    await this.excelService.generateGenericExcel(this.data, this.exportFileName);
    this.exportingExcel = false;
  }
  public viewTask(route: string) { window.open(route); }
  public isDateOnly(str: string) { return (new Date(str).toLocaleTimeString().match('12:00:00 AM')); }
  public getDBNNumber(row: any): string { return row[Object.keys(row).find(key => key.toLowerCase() === 'dbnnumber')] ?? 'File'; }
  public isEmpty(val): boolean { return isEmpty(val); }
  public toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}