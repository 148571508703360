import { LogBase } from './../../../../services/logger.service';
import { Component, OnInit } from '@angular/core';
import { FileOwnerUserTaskApiService } from '../../../../services/api/user-tasks/file-owner-user-task-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { WhatsAppTalkToAgentForAFile } from '../../../../interfaces/user-tasks/file-owner-user-tasks';

@Component({
  selector: 'app-whatsapp-talk-to-agent-for-a-file',
  templateUrl: './whatsapp-talk-to-agent-for-a-file.component.html',
  styleUrls: ['./whatsapp-talk-to-agent-for-a-file.component.scss'],
})
export class WhatsappTalkToAgentForAFileComponent implements OnInit {
  private _fileId: number = 0;
  public userTask: WhatsAppTalkToAgentForAFile;

  public showSpinner: boolean = false;
  public postSpinner: boolean = false;
  public showDialog: boolean = false;

  constructor(
    private fileOwnerUserTaskApi: FileOwnerUserTaskApiService,
    private route: ActivatedRoute,
    private router: Router,
    private log: LogBase,
    private messageService: MessageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.showSpinner = true;
      this._fileId = params['fileId'];
      if (!this._fileId) this.toastMessage('error', 'Failed to get the file. Please contact support.', '');
      else await this.getUserTask();
      this.showSpinner = false;
    });
  }

  private async getUserTask(): Promise<void> {
    try {
      const response = await this.fileOwnerUserTaskApi.GetWhatsappTalkToAgentUserTask(this._fileId);
      if (!response) this.toastMessage('error', 'Failed to set up page. Please contact support.', '');
      else if (response.errorCode !== 0) this.toastMessage('error', response.errorMessage, '');
      else this.userTask = response.result;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Completly failed to set up page. Please contact support.', '');
    }
  }

  public async completeUserTask(): Promise<void> {
    this.postSpinner = true;
    try {
      const response = await this.fileOwnerUserTaskApi.DoWhatsappTalkToAgentUserTask(this._fileId, this.userTask.userTaskId);
      if (!response) this.toastMessage('error', 'Failed to complete user task. Please contact support.', '');
      else if (response.errorCode !== 0) this.toastMessage('error', response.errorMessage, '');
      else {
        this.toastMessage('success', response.errorMessage, '');
        setTimeout(() => {
          this.router.navigate(['/task-list']);
        }, 1200);
      }
    } catch (err) {
      this.log.error(err);
    }
    this.postSpinner = false;
  }

  public goToWhatsapp() { window.open('/WhatsApp'); }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
