import { Component, Input, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng';
import { LogBase } from './../../../services/logger.service';
import { WhatsappApiService } from '../../../services/api/whatsapp-api.service';
import { WARoomCount, WARoomsAndChats, WAVoyageTemplateDetails, WAVoyageTemplates } from '../../../interfaces/global.interfaces';
import { Table } from 'primeng/table';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { WAAutoResponderStats } from '../../../interfaces/whatsapp.interface';
import { WhatsappProductService } from '../whatsapp-product/whatsapp-product.service';

@Component({
  selector: 'app-whatsapp-dashboard',
  templateUrl: './whatsapp-dashboard.component.html',
  styleUrls: ['./whatsapp-dashboard.component.scss'],
  providers: [ConfirmationService],
})
export class WhatsappDashboardComponent {
  @Input() fileNumber: string = null;
  @ViewChild('dt') table: Table;
  @ViewChild('vd') tableBroadCastTemplate: Table;

  showSpinner: boolean = false;
  roomStatsSpinner: boolean = false;
  voyageTemplatesSpinner: boolean = false;
  autoResponderStatsSpinner: boolean = false;
  showDrawer: boolean = true;
  spinnerLoad: boolean = false;
  viewChatMessages: boolean = false;

  chatMessages: any = null;
  roomCountTotal: number = 0;
  allChats: WARoomsAndChats[] = [];
  roomCounts: WARoomCount[] = [];
  voyageTemplates: WAVoyageTemplates[];
  voyageDetails: WAVoyageTemplateDetails[];
  selectedVoyageTemplate: WAVoyageTemplates;
  autoResponderStats: WAAutoResponderStats;
  totalAutoResponderRequests: number = 0;

  activeState: any[] = [
    { label: 'Open', value: '1' },
    { label: 'Closed', value: '0' },
  ];
  inbound: any[] = [
    { label: 'Inbound', value: 0 },
    { label: 'Outbound', value: 1 }
  ];
  rooms: any[] = [
    { label: 'Lobby DT', value: 'Lobby DT' },
    { label: 'Admin', value: 'Admin' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Dispatch', value: 'Dispatch' },
  ];

  constructor(
    private log: LogBase,
    private messageService: MessageService,
    private whatsappService: WhatsappApiService,
    public whatsappProductService: WhatsappProductService,
    private titleService: Title
  ) { this.titleService.setTitle("WhatsApp Dashboard"); }

  public refresh() {
    this.getWAAllRoomChats()
    this.getWAVoyageTemplates();
    this.getWAAutoResponderStats();
  }

  public async getWAAllRoomChats() {
    if (!this.whatsappProductService.selectedProduct) return;
    this.roomStatsSpinner = true;
    try {
      this.allChats = [];
      this.roomCounts = [];
      this.roomCountTotal = 0;
      const messages = await this.whatsappService.WAGetAllRoomChats(this.whatsappProductService.selectedProduct.id);
      if (!messages) this.toastMessage('error', 'Failed to get all room chats', '');
      else if (messages.errorCode != 0) this.toastMessage('error', messages.errorMessage, '');
      else {
        this.allChats = messages.result;
        this.allChats.map(chat => {
          //used for the time from inbound - outbound column filter
          if (!chat.timeDiff) chat.direction = 1;
          else chat.direction = 0;
        });
        this.getWARoomCount();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get all room chats', '');
      this.log.error(error);
    }
    this.roomStatsSpinner = false;
  }

  public getWARoomCount() {
    this.roomCounts = [];
    this.roomCountTotal = 0;

    const uniqueRooms: WARoomsAndChats[] = _.uniqBy(this.allChats, 'roomId');

    for (const room of uniqueRooms) {
      const currentRoom = this.allChats.filter(x => x.roomId == room.roomId);

      this.roomCountTotal += currentRoom.length;

      this.roomCounts.push({
        count: currentRoom.length,
        roomName: currentRoom[0].roomName
      });
    }
  }

  public async getChatMessages(chatId: number) {
    this.spinnerLoad = true;
    try {
      const messages = await this.whatsappService.WAGetChatMessagesByChatId(chatId);
      if (!messages) this.toastMessage('error', 'Failed to get chat messages. Please contact support.', '');
      else if (messages.errorCode !== 0) this.toastMessage('error', messages.errorMessage, '');
      else {
        this.chatMessages = messages.result;
        this.viewChatMessages = true
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get chat messages', '');
      this.log.error(error)
    }
    this.spinnerLoad = false;
  }

  public async getWAVoyageTemplates() {
    if (!this.whatsappProductService.selectedProduct) return;
    this.voyageTemplatesSpinner = true;
    try {
      this.voyageTemplates = [];
      const voyageTemplates = await this.whatsappService.WAGetVoyageTemplates(this.whatsappProductService.selectedProduct.id);
      if (!voyageTemplates) this.toastMessage('error', 'Failed to get voyage templates. Please contact support.', '');
      else if (voyageTemplates.errorCode !== 0) this.toastMessage('error', voyageTemplates.errorMessage, '');
      else this.voyageTemplates = voyageTemplates.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get sent voyage messages', '');
      this.log.error(error)
    }
    this.voyageTemplatesSpinner = false;
  }

  public async getWAAutoResponderStats() {
    if (!this.whatsappProductService.selectedProduct) return;
    this.autoResponderStatsSpinner = true;
    this.totalAutoResponderRequests = 0;
    try {
      this.autoResponderStats = null;
      const autoResponderStats = await this.whatsappService.WAGetAutoResponderStats(this.whatsappProductService.selectedProduct.id);
      if (!autoResponderStats) this.toastMessage('error', 'Failed to get auto responder stats. Please contact support.', '');
      else if (autoResponderStats.errorCode !== 0) this.toastMessage('error', autoResponderStats.errorMessage, '');
      else {
        this.autoResponderStats = autoResponderStats.result;
        this.totalAutoResponderRequests = 0;
        this.autoResponderStats.breakDowns.map(x => this.totalAutoResponderRequests += x.requestAmount);
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get auto responder stats', '');
      this.log.error(error);
    }
    this.autoResponderStatsSpinner = false;
  }

  public async getWAVoyageTemplateDetails(rowSelected: WAVoyageTemplates) {
    this.spinnerLoad = true;
    try {
      const result = await this.whatsappService.WAGetVoyageTemplateDetails(rowSelected.vesselId, rowSelected.voyageId);
      if (!result) this.toastMessage('error', 'Failed to get voyage templates details. Please contact support.', '');
      else if (result.errorCode !== 0) this.toastMessage('error', result.errorMessage, '');
      else {
        this.voyageDetails = result.result;
        this.selectedVoyageTemplate = rowSelected;
        this.scrollToBottom();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get voyage template details', '');
      this.log.error(error)
    }
    this.spinnerLoad = false;
  }

  public scrollToBottom() {
    setTimeout(() =>
        window.scroll({
          top: document.body.scrollHeight - window.innerHeight,
          behavior: "smooth",
        }),105
    );
  }

  public onFilterDateSelect(table: string, date: any, propertyName: string) { this[table].filter(this.formatDate(date).toString(), propertyName, 'contains') }
  public formatDate(date: string) { return moment(new Date(date)).format('DD/MM/yy'); }
  public formatMessageTime(time: any) { return moment(time).format('DD/MM/yy hh:mm'); }
  public viewFile(fileId: number) { window.open(`file-view?query=${fileId}`); }
  public toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }) }
}