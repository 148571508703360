import { Injectable } from '@angular/core';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';
import { TransactionType } from '../../../enums';

@Injectable({
  providedIn: 'root'
})

export class TransactionIntentApiService {
  private serverUrl = `${this.config.getServerUrl()}api/transactionintents/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  //Endpoint: api/transactionintents/addAdminTransaction
  //Controller: TransactionIntentsController.cs
  //Proc Name: Multiple procs
  public async AddAdminTransaction(fileId: number, amount: number, currencyId: number, effectiveDate: string, transactionReason: string, transactionType: TransactionType): Promise<HttpResponse | null> {
    try {
      const payload = {
        fileId,
        amount,
        currencyId,
        effectiveDate,
        transactionReason,
        transactionType
      } as any;
      const url = `${this.serverUrl}addAdminTransaction`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/transactionintents/getAdminTransactionTypes
  //Controller: TransactionIntentsController.cs
  //Proc Name: pr_getAdminTransactionTypes
  public async GetAdminTransactionTypes(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getAdminTransactionTypes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}