import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LogBase } from '../../logger.service';
import { Groups } from '../../../enums';
import { MessageService } from 'primeng';
import * as _ from 'lodash';

@Injectable()
export class AuthManager implements CanActivate {

    constructor(
        private auth: AuthenticationService,
        private log: LogBase,
        private router: Router,
        private messageService: MessageService
    ) { } 

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        try {
            if (this.auth.isTokenValid()) {
                let roles = route.data['roles'] as number[];
                if (!roles) return true;
                else if (roles.includes(Groups.ALL)) return true;
                else {
                    let editPermissions = this.auth.getEditPermissions();
                    if (editPermissions) {
                        let found = false;
                        roles.forEach(role => {
                            editPermissions.forEach(perm => {
                                if (role == perm.id) found = true;
                            });
                        });
                        if (!found) {
                            this.messageService.add({
                                severity: 'warn',
                                summary: '',
                                detail: 'You do not have the appropriate permissions to view this resource!'
                            });
                        }
                        return found;
                    } else {
                        this.messageService.add({
                            severity: 'warn',
                            summary: '',
                            detail: 'You do not have the appropriate permissions to view this resource!'
                        });
                    }
                } 
            } else {
                this.auth.clearUser
                this.router.navigate(['/login']);
                return false;
            }
        } catch (error) {
            this.router.navigate(['/login']);
            this.log.error(error);
            return false;
        }
    }
}
