import { HandoverApiService } from './../../../../services/api/handover-api.service';
import { LegalEntityApiService } from './../../../../services/api/legal-entity-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng';
import { ClearingStatus } from '../../../../enums';
import { Dropdown, HandoverFile } from '../../../../interfaces/global.interfaces';
import { SharedUserTaskApiService } from '../../../../services/api/user-tasks/shared-user-task-api.service';

@Component({
  selector: 'app-add-clearing-agent',
  templateUrl: './add-clearing-agent.component.html',
  styleUrls: ['./add-clearing-agent.component.scss']
})
export class AddClearingAgentComponent implements OnInit {

  @Input() fileId: number = 0;
  @Output() onLoaded = new EventEmitter();
  @Output() onClearingAgentChange = new EventEmitter();
  
  fileDetails: HandoverFile;
  selectedClearingAgent: Dropdown;
  selectedContacts: Dropdown[];
  clearingAgents: Dropdown[];
  contacts: Dropdown[];

  showSpinner: boolean = true;
  clearingAgentSpinner: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private handoverApi: HandoverApiService,
    private sharedUserTaskApi: SharedUserTaskApiService,
    private legalEntityApi: LegalEntityApiService
  ) { }

  get clearingStatusType(): typeof ClearingStatus { return ClearingStatus; }

  async ngOnInit() {
    if (this.fileId || this.getRouteParams()) {
      await this.getClearingAgents();
      await this.getFileDetails();
      this.showSpinner = false;
    }
    this.onLoaded.emit();  
  }

  async getRouteParams() {
    this.route.params.subscribe(params => {
      this.fileId = +params['fileId']
      if ( !this.fileId) { 
        this.toastMessage('error', 'Failed to set up page. There is no file id. Please contact support', '');
        return false;
      }
      return true;
    });
  }

  async getFileDetails() {
    try {
      let retval = await this.handoverApi.getHandoverFileDetails(this.fileId);
      if (!retval) { 
        this.toastMessage('error', 'Failed to get file details. Please contact support', '');
      }
      else if (retval.errorCode != 0) {
        this.toastMessage('error', retval.errorMessage, '');
      }
      else {
        this.fileDetails = retval.result;
        if (this.fileDetails.clearingAgentId) await this.setClearingAgentDetails();
      }
    }
    catch (err) {
      console.log(err)
      this.toastMessage('error', 'Failed to get file details. Please contact support', '');
    }
  }

  async setClearingAgentDetails() {
    let clearingAgent = this.clearingAgents.find(x => x.value == this.fileDetails.clearingAgentId)
    if (!clearingAgent) return;
    this.selectedClearingAgent = {
      value: this.fileDetails.clearingAgentId,
      label: clearingAgent.label
    }
    await this.getStaff();
    this.selectedContacts = this.fileDetails.clearingAgentStaffMembers;
  }

  async getClearingAgents() {
    try {
      let retval = await this.legalEntityApi.getClearingAgents()
      if (!retval) {
        this.toastMessage('error', 'Failed to get clearing agents. Please contact support', '');
      }
      else if (retval.errorCode != 0) {
        this.toastMessage('error', retval.errorMessage, '');
      }
      else {
        this.clearingAgents = retval.result;
      }
    }
    catch (err) {
      console.log(err)
      this.toastMessage('error', 'Failed to get clearing agents. Please contact support', '');
    }
  }

  async getStaff() {
    this.selectedContacts = null;
    try {
      let retval = await this.legalEntityApi.getClearingAgentStaffEmailAddresses(this.selectedClearingAgent.value);
      if (!retval) {
        this.toastMessage('error', 'Failed to get who to communicate with. Please contact support', '');
      }
      else {
        this.contacts = retval;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get who to communicate with. Please contact support', '');
    }
  }

  async addUpdateClearingAgent() {
    this.clearingAgentSpinner = true;
    try {
      let payload = {
        fileId: this.fileId,
        clearingStatusId: this.fileDetails.clearingStatusId,
        clearingAgentId: this.selectedClearingAgent.value,
        communicateWith: this.selectedContacts
      }
      let retval = await this.sharedUserTaskApi.AssignClearingAgent(payload);
      if (!retval) {
        this.toastMessage('error', `Failed to assign clearing agent to file. Please contact support`, '');
      }
      else if (retval.errorCode < 0) {
        this.toastMessage('error', retval.errorMessage, '');
      }
      else {
        this.toastMessage('success', retval.errorMessage, '');
        if (retval.errorCode == 1) {
          this.router.navigate(['/task-list']);
        }
      }
      this.onClearingAgentChange.emit();
    }
    catch (error) {
      this.toastMessage('error', `Failed to assign clearing agent to file. Please contact support`, '');
    }
    this.clearingAgentSpinner = false;
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
