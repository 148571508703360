<div class="ui-g-12 center" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && userTask && routes && routes.length > 0" class="ui-g card card-w-title">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 file-number-header">
      <h3>Inspection Route Update Failed for </h3>
      <file-number [fileNumber]="userTask.dbnNumber"></file-number>
    </div>
  </div>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'250px'}">
      <p-header>
        Inspection Workorder details
      </p-header>

      <div class="ui-g-4">
        <div class="information">
          <strong>Inspection done by</strong>
          <label>
            {{ userTask.assignedUsername || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Fail date</strong>
          <label>
            {{ userTask.failDate || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Inspection completed date</strong>
          <label>
            {{ userTask.inspectionDate || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Inspection route Update</strong>
          <label>
            {{ userTask.locationAction || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-8">
        <div class="information">
          <strong>Route update fail reason</strong>
          <label>
            {{ userTask.failReason || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Currently At</strong>
          <label>
            {{ userTask.currentlyAt || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-8">
        <div class="information">
          <strong>Route</strong>
          <label>
            {{ userTask.route || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="ui-g-12">
      <div class="information">
        <strong>Routes</strong>
        <p-dropdown [options]="routes" optionLabel="route" [style]="{'width':'100%'}" [filter]="true"
          [autoDisplayFirst]="false" [(ngModel)]="selectedRoute">
        </p-dropdown>
      </div>
    </div>
  </div>

  <div class="ui-g-12 actions">
    <button pRipple pButton label="Complete task without re syncing inspection" (click)="completeTask(null)"
      *ngIf="!postSpinner" class="width-100" pTooltip="Route will not be changed"></button>
    <button pRipple pButton [disabled]="!selectedRoute" label="Change Route and resync inspection"
      (click)="completeTask(selectedRoute.routeId)" *ngIf="!postSpinner" class="width-100"
      pTooltip="Route will change to {{userTask.locationAction}} on the {{userTask.inspectionDate}}"></button>

    <button *ngIf="postSpinner" pRipple class="p-button-info submit width-100" pButton type="button" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</div>