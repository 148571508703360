import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { LogBase } from '../../services/logger.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../services/config.service'
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import * as _ from 'lodash';
import { TableColumns } from '../../interfaces/global.interfaces';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../services/api/auth/authentication.service';

interface Supplier {
  id: number;
  Name: string;
  LegalEntityId: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class FileUploadComponent implements OnInit {

  msgs: any = [];
  legalEntityId: any

  tempUploadChoices: any = [];

  hasChosenType: boolean = false;

  uploadedFiles: any = [];
  cols: TableColumns[] = [{
    field: "Message",
    header: "Error",
    width: "100%"
  }];

  apiUrl: any;

  uploadOptions: any[] = [{
      description: 'RORO Excel Spreadsheet',
      ClearingStatusId: 1
    },
    {
      description: 'Handover Excel Spreadsheet',
      ClearingStatusId: 2
    }
  ];
  selectedOption;

  suppliers: Supplier[] = []
  selectedSupplier: any; // Supplier;

  importResults: any;

  showSpinner: boolean = false;
  uploadPassed: boolean = null;

  exceptionMessage: string = '';
  url: string = '';
  headers: HttpHeaders = null

  constructor(
    private api: ApiService,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private titleService: Title,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS File-Upload");
  }

  async ngOnInit() {
    try {
      this.legalEntityId = this.authService.getLegalEntityId;
      const serverURL = this.config.getServerUrl();
      this.url = serverURL + "api/Upload/ImportRORO?SupplierId="
      this.suppliers = await this.api.getSuppliers()
      this.setUpHeaders()
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
  }

  setUpHeaders() {
    this.headers = new HttpHeaders()
      .set("Token", this.authService.getSessionToken ?? '')
      .set("Id", this.authService.getLegalEntityId.toString() ?? '');
  }

  async setURL() {
    this.apiUrl = this.url + this.selectedSupplier.Id + '&UserId=' + this.legalEntityId + "&ClearingStatusId=" + this.selectedOption.ClearingStatusId;
    this.hasChosenType = true;
  }

  async onBeforeUpload(event) {
    this.showSpinner = true;
    document.getElementById("upload").style.visibility = "hidden";
  }

  async onUpload(event) {
    try {
      this.showSpinner = false;
      document.getElementById("upload").style.visibility = "visible";
      this.importResults = JSON.parse(event.originalEvent.body.result);
      let rows = [];
      for (let i = 0; i < this.importResults.ErrorMessages.length; i++) {
        if (this.importResults.ErrorMessages[i].Errors != '' || this.importResults.ErrorMessages[i].Warnings != '') {
          rows.push(this.importResults.ErrorMessages[i]);
        }
      }
      this.importResults.ErrorMessages = rows;

      if (this.importResults.ExceptionMessage == null) {
        this.importResults.ExceptionMessage = '';
      }
      this.exceptionMessage = this.importResults.ExceptionMessage;
      this.uploadPassed = this.importResults.Success;

      if (this.exceptionMessage.length == 0 && this.uploadPassed) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'CSV Uploaded and processed successfully'
        });
        document.getElementById("upload").style.visibility = "visible";
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'CSV could not be imported...'
        });
      }
    } catch (error) {
      this.log.error(error)
    }
  }

  onError(event) {
    try {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed To Upload File'
      });
      this.showSpinner = false;
    } catch (error) {
      this.log.error(error)
    }
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername
        var date = new Date();
        var emailBody: string = 'Component Name: File Summary File Upload<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';

        var subject: string = 'NovaDT Error Message - Component: File Summary File Upload';
        var res = this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      },
      reject: () => {

      }
    });
  }
}