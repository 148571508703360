<div class="topbar clearfix">
  <div class="topbar-left">
    <div class="logo" style="background-size: contain;"></div>
  </div>
  <div class="uat-heading">
    <h1>{{ environment?.uatMessage?.toUpperCase() }}</h1>
  </div>
  <div class="topbar-right">
    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
      <i></i>
    </a>

    <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
      <li #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
        <a href="" (click)="app.onTopbarItemClick($event,settings)">
          <i class="topbar-icon material-icons">settings</i>
          <span class="topbar-item-name">Settings</span>
        </a>
        <ul class="ultima-menu animated fadeInDown">
          <li role="menuitem">
            <div class="ui-g">
              <div class="ui-g-12">
                <p-scrollPanel [style]="{width: '100%', height: '500px'}" styleClass="myPanelContent">
                  <div class="ui-g-12">
                    <label><strong>TASKS</strong></label>
                    <hr />
                  </div>
                  <a (click)="assignFiles()">
                    <i class="fa fa-user"></i>
                    <label>Round Robin</label>
                  </a>
                  <div class="ui-g-12">
                    <label><strong>SHIPPING</strong></label>
                    <hr />
                  </div>
                  <a (click)="manageShippingLines()">
                    <i class="fas fa-ship"></i>
                    <label>Shipping Lines</label>
                  </a>
                  <a (click)="manageVessels()">
                    <i class="material-icons">directions_boat</i>
                    <label>Vessels/Voyages</label>
                  </a>
                  <a (click)="manageContainers()">
                    <i class="material-icons">view_compact</i>
                    <label>Containers</label>
                  </a>
                  <a (click)="goToRouteBuilder()">
                    <i class="material-icons">map</i>
                    <label>Route Builder</label>
                  </a>
                  <div class="ui-g-12">
                    <label><strong>REFERENCE TABLES</strong></label>
                    <hr />
                  </div>
                  <a (click)="manageVehicles()">
                    <i class="fas fa-car"></i>
                    <label>Vehicles/Models</label>
                  </a>
                  <a (click)="manageColors()">
                    <i class="fas fa-palette"></i>
                    <label>Vehicle Colours</label>
                  </a>
                  <a (click)="manageBorderPost()">
                    <i class="fas fa-map-marked-alt"></i>
                    <label>Border Posts</label>
                  </a>
                  <a (click)="manageDocumentCategory()">
                    <i class="fas fa-file-invoice"></i>
                    <label>Document Categories</label>
                  </a>
                  <a (click)="manageMBL()">
                    <i class="material-icons">dashboard</i>
                    <label>Master Bills</label>
                  </a>
                  <div class="ui-g-12">
                    <label><strong>FINANCE</strong></label>
                    <hr />
                  </div>
                  <a (click)="manageInvoiceLineItemDescription()">
                    <i class="fas fa-file-invoice"></i>
                    <label>Invoice Defaults</label>
                  </a>
                  <br />
                </p-scrollPanel>
              </div>
            </div>
          </li>
        </ul>
      </li>

      <li #files [ngClass]="{'active-top-menu':app.activeTopbarItem === files}">
        <a href="#" (click)="app.onTopbarItemClick($event,files)">
          <i class="topbar-icon material-icons animated swing">add</i>
          <span class="topbar-item-name">New File</span>
        </a>

        <ul class="ultima-menu animated fadeInDown">
          <li role="menuitem">
            <div class="ui-g">
              <div class="ui-g-12">
                <p-scrollPanel [style]="{width: '100%', height: '120px'}" styleClass="myPanelContent">
                  <div class="ui-g-12">
                    <label><strong>CREATE NEW FILE</strong></label>
                    <hr />
                    <a (click)="openFile()">
                      <i class="fas fa-file-invoice"></i>
                      <label>New File</label>
                    </a>
                    <a (click)="importCSV()">
                      <i class="fas fa-file-invoice"></i>
                      <label>Supplier Spreadsheet</label>
                    </a>
                  </div>
                </p-scrollPanel>
              </div>
            </div>
          </li>
        </ul>
      </li>

      <li class="search-item">
        <span class="md-inputfield cursorHand">
          <input type="text" pInputText [(ngModel)]="searchQuery" (keyup.enter)="search()" />
          <label>Search</label>
          <i class="topbar-icon material-icons" (click)="search()">search</i>
        </span>
      </li>
    </ul>
  </div>
</div>