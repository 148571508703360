import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Dropdown, UserTasks } from '../../interfaces/global.interfaces';
import { MessageService } from 'primeng/api';
import { SummaryDashboardApiService } from '../../services/api/summary-dashboard-api.service';
import { LogBase } from '../../services/logger.service';
import { FileApiService } from '../../services/api/file-api.service';
import * as _ from 'lodash';
import { UserTaskApiService } from '../../services/api/user-tasks/user-tasks-api.service';
import { Groups, UserTaskType } from '../../enums';
import { ReferenceApiService } from '../../services/api/reference-api.service';
import { AddAdhocUserTaskRequest } from '../../interfaces/user-tasks/adhoc-user-tasks.interface';

@Component({
  selector: 'user-task-dashboard',
  templateUrl: './user-task-dashboard.component.html',
  styleUrls: ['./user-task-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserTaskDashboardComponent implements OnInit {

  userTasks: UserTasks[] = [];
  loading: boolean = false;

  //ad hoc user task
  adhocUserTaskTypes: Dropdown[] = [];
  fileOptions: Dropdown[] = [];
  selectedFileOption: Dropdown | null = null;
  selectedAdhocUserTaskType: UserTaskType | null = null;
  userTaskHeading: string | null = null;
  userTaskDescription: string | null = null;
  users: Dropdown[] = [];
  selectedUser: number | null = null;
  showDialog: boolean = false;
  addingAdhocUserTask: boolean = false;

  public get userTaskType(): typeof UserTaskType { return UserTaskType; }

  constructor(
    private messageService: MessageService,
    private log: LogBase,
    private summaryDashboardApiService: SummaryDashboardApiService,
    private referenceApiService: ReferenceApiService,
    private userTaskApiService: UserTaskApiService,
    private filesApiService: FileApiService
  ) { }

  async ngOnInit() {
    await this.getUserTasksOutstanding();
    await this.getAdhocUserTaskTypes();
    await this.getRoundRobinUsersByGroupId();
  }

  private async getUserTasksOutstanding() {
    this.loading = true;
    try {
      const retval = await this.summaryDashboardApiService.getOutstandingUserTasksDashboard();
      if (!retval) this.toastMessage('error', 'Failed to get outstanding user tasks. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.userTasks = retval.result;
    }
    catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get user tasks. Something went wrong our side. Please contact support.', '');
    }
    this.loading = false;
  }

  private async getAdhocUserTaskTypes(): Promise<void> {
    try {
      const retval = await this.userTaskApiService.getAdhocUserTaskTypes();
      if (!retval) this.toastMessage('error', 'Failed to get adhoc user task types. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.adhocUserTaskTypes = retval.result;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get adhoc user task types. Please contact support.', '');
    }
  }

  private async getRoundRobinUsersByGroupId() {
    try {
      const retval = await this.referenceApiService.getRoundRobinUsersByGroupId(Groups.ADHOC);
      if (!retval) this.toastMessage('error', 'Failed to get round robin users. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.users = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get round robin users. Please contact support.', '');
    }
  }

  public async searchForFiles(search: string) {
    try {
      const retval = await this.filesApiService.searchForFiles(search);
      if (!retval) this.toastMessage('error', 'Failed to search for files. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.fileOptions = retval.result;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to search for files. Something went wrong our side. Please contact support.', '');
    }
  }

  public async addAdhocUserTask() {
    this.addingAdhocUserTask = true;
    try {
      const payload: AddAdhocUserTaskRequest = {
        adhocUserTaskType: this.selectedAdhocUserTaskType,
        fileId: this.selectedFileOption?.value,
        userId: this.selectedUser,
        userTaskDescription: this.userTaskDescription,
        userTaskHeading: this.userTaskHeading
      };
      const retval = await this.userTaskApiService.AddAdhocUserTask(payload);
      if (!retval) this.toastMessage('error', 'Failed to create adhoc user task. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        this.closeDialog();
        await this.getUserTasksOutstanding();
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to create adhoc user task. Something went wrong our side. Please contact support.', '');
    }
    this.addingAdhocUserTask = false;
  }

  public disableUserDropDown() {
    if (!this.selectedAdhocUserTaskType) return true;
    else if (this.selectedAdhocUserTaskType === UserTaskType.ADHOC_USER_TASK_FOR_A_FILE && (this.addingAdhocUserTask || !this.selectedFileOption?.value))
      return true;
    else if (this.selectedAdhocUserTaskType === UserTaskType.ADHOC_USER_TASK_GENERAL && this.addingAdhocUserTask) return true;
    else return false;
  }

  public closeDialog() {
    this.showDialog = false;
    this.fileOptions = null;
    this.selectedFileOption = null;
    this.selectedUser = null;
    this.userTaskDescription = null;
    this.userTaskHeading = null;
    this.selectedAdhocUserTaskType = null;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}