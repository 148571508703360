import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng';
import { AppComponent } from './app.component';

@Component({
  templateUrl: './app.menu.component.html',
  selector: 'app-menu'
})

export class AppMenuComponent implements OnInit {

  @Input() reset: boolean;
  items: MenuItem[];

  constructor(public app: AppComponent) { }

  ngOnInit() { this.buildMenuModel(); }

  buildMenuModel() {
    this.items = [
      {
        label: 'Task List',
        icon: 'view_list',
        routerLink: ['task-list']
      },
      {
        label: 'Communicator',
        icon: 'email',
        routerLink: ['communicator']
      },
      {
        label: 'Bulk Updater',
        icon: 'present_to_all',
        routerLink: ['bulk-updater']
      },
      {
        label: 'Contacts',
        icon: 'contacts',
        routerLink: ['display-contact']
      },
      {
        label: 'Manage Files Preferences',
        icon: 'folder_open',
        routerLink: ['manage-files-preferences']
      },
      {
        label: 'Route Status Updater',
        icon: 'map',
        routerLink: ['route-status-updater']
      },
      {
        label: 'Dispatch Manager',
        icon: 'local_shipping',
        routerLink: ['dispatch-manager']
      },
      {
        label: 'Summary Dashboard',
        icon: 'dashboard',
        routerLink: ['morning-page']
      },
      {
        label: 'Whatsapp Dashboard',
        icon: 'dashboard',
        routerLink: ['whatsapp-dashboard']
      },
      {
        label: 'Whatsapp Manager',
        icon: 'chat_bubble',
        routerLink: ['whatsapp-manager']
      },
      {
        label: 'Whatsapp History',
        icon: 'history',
        routerLink: ['whatsapp-history']
      },
      {
        label: 'Whatsapp',
        icon: 'chat_bubble',
        routerLink: ['WhatsApp']
      },
      {
        label: 'SA Exports',
        icon: 'shopping_cart',
        routerLink: ['saex']
      },
      {
        label: 'Avecs Finance',
        icon: 'language',
        routerLink: ['avecs-finance']
      },
      {
        label: 'Finance',
        icon: 'attach_money',
        routerLink: ['bfj-cif']
      },

      {
        label: 'Views',
        icon: 'view_quilt',
        routerLink: ['views']
      },
      {
        label: 'Jobs',
        icon: 'table',
        routerLink: ['jobs']
      },
      {
        label: 'Log Out',
        icon: 'vpn_key',
        routerLink: ['login']
      }
    ];
  }
}
