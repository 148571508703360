import { FileApiService } from './../../../services/api/file-api.service';
import { LegalEntityApiService } from './../../../services/api/legal-entity-api.service';
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Input } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import "rxjs/add/operator/filter";
import { DomSanitizer } from "@angular/platform-browser";
import { SelectItem } from "primeng";
import { LogBase } from "../../../services/logger.service";
import { ConfirmationService } from "primeng";
import { MessageService } from 'primeng/api';
import { Title } from "@angular/platform-browser";
import { TableColumns } from "../../../interfaces/global.interfaces";
import { SmsApiService } from '../../../services/api/sms-api.service';
import { EmailApiService } from '../../../services/api/email-api.service';

@Component({
  selector: "app-correspondence",
  styleUrls: ["./correspondence.component.scss"],
  templateUrl: "./correspondence.component.html",
  providers: [ConfirmationService],
})

export class CorrespondenceComponent implements OnInit {

  @Input() public fileId: number;
  @Input() public legalEntityId: number = null;
  @Output() public onLoaded = new EventEmitter();

  public emails: any[];
  public cols: TableColumns[] = [
    { field: "from", header: "From Email", width: '200px' },
    { field: "fromUser", header: "From User", width: '120px' },
    { field: "toEmail", header: "To", width: '200px' },
    { field: "subject", header: "Subject", width: '280px' },
    { field: "sent", header: "Sent", width: '150px' },
    { field: "status", header: "Status", width: '150px' }
  ];
  public emailBody: string = '';
  public subject: string = '';
  public emailHtml: any;
  public EmailHeader: any;
  public emailQueueId: number = 0;
  public emailAdresses: any = [];
  public emailSubject: string = '';

  public file: any;
  public ConsigneeDetails: any;

  public smsData: any;

  public EmailFromChoices: SelectItem[];
  public selectedChoice: any;

  public showSpinner: boolean = false;
  public showActionSpinner: boolean = false;
  public loadIcon: string = 'fas fa-sync';
  public displayDialog: boolean;
  public sendEmailSpinner: boolean = false;
  public showResendEmailDialog: boolean = false;

  constructor(
    private api: ApiService,
    private legalEntityApi: LegalEntityApiService,
    private emailApiService: EmailApiService,
    private fileApi: FileApiService,
    private smsApi: SmsApiService,
    private sanitizer: DomSanitizer,
    private log: LogBase,
    private messageService: MessageService,
    private titleService: Title
  ) {
    this.titleService.setTitle("AVECS Correspondence");
  }

  async ngOnInit() {
    this.showSpinner = true;
    await this.refreshList();
    this.showSpinner = false;
  }

  public async refreshList() {
    this.loadIcon = 'fas fa-sync fa-spin';
    try {
      if (this.legalEntityId) {
        this.emails = await this.legalEntityApi.getEmailsByLegalEntityId(this.legalEntityId);
        this.smsData = await this.smsApi.getSMSDataByLegalEntityId(this.legalEntityId);
      } else {
        this.file = await this.api.getFileById(this.fileId);
        this.ConsigneeDetails = await this.api.getFileConsigneeDetailsById(this.file.ConsigneeLegalEntityId);
        this.emails = await this.fileApi.getEmails(this.fileId);
        this.emailAdresses = await this.api.GetFileEmailAdresses(this.fileId);
        this.smsData = await this.smsApi.getFileSMSData(this.fileId);
      }
      this.EmailFromChoices = await this.api.getEmailFromChoices();
    } catch (error) {
      this.log.error(error);
    }
    this.onLoaded.emit();
    this.loadIcon = 'fas fa-sync';
  }

  public async sendFreeTextEmail() {
    this.sendEmailSpinner = true;
    try {
      const res = await this.emailApiService.sendFreeTextEmail(this.ConsigneeDetails.Email, this.selectedChoice.Label, this.emailBody, this.subject, this.fileId, this.ConsigneeDetails.Id);
      if (!res) this.messageService.add({ severity: "error", summary: "Error", detail: "Failed to send email. Please contact support." });
      else if (res.errorCode !== 0) this.messageService.add({ severity: "error", summary: "Error", detail: res.errorMessage });
      else {
        this.messageService.add({ severity: "success", summary: "Success", detail: res.errorMessage });
        this.emails = await this.fileApi.getEmails(this.fileId);
        this.emailBody = undefined;
        this.subject = undefined;
      }
    } catch (error) {
      this.messageService.add({ severity: "error", summary: "Error", detail: "Failed to send email. Please contact support." });
      this.log.error(error);
    }
    this.sendEmailSpinner = false;
  }

  public async resendEmail() {
    this.showActionSpinner = true;
    try {
      const res = await this.emailApiService.resendEmail(this.emailQueueId);
      if (!res) this.messageService.add({ severity: "error", summary: "Error", detail: "Failed to resend email. Please contact support" });
      else if (res.errorCode !== 0) this.messageService.add({ severity: "error", summary: "Error", detail: res.errorMessage });
      else {
        this.messageService.add({ severity: "success", summary: "Success", detail: res.errorMessage });
        this.showResendEmailDialog = false;
        this.emails = await this.fileApi.getEmails(this.fileId);
      }
    } catch (error) {
      this.messageService.add({ severity: "error", summary: "Error", detail: "Failed to resend email. Please contact support" });
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public viewEmail(email: any) {
    try {
      this.EmailHeader = email.subject;
      this.emailHtml = email.html || email.body;
      this.emailHtml = this.sanitizer.bypassSecurityTrustHtml(this.emailHtml);
      this.displayDialog = true;
    } catch (error) {
      this.log.error(error);
    }
  }

  public showResendDialog(email: any) {
    this.emailQueueId = email.id;
    this.emailSubject = email.subject;
    this.showResendEmailDialog = true;
  }
}