<div class="ui-g card card-w-title">
  <div class="ui-g-6">
    <h3 class="first">Client Bulk Import</h3>
  </div>
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g">
      <div class="ui-g-4">
        <p-dropdown [options]="suppliers" optionLabel="Name" [(ngModel)]="selectedSupplier" [filter]="true"
          placeholder="Select a Supplier" [style]="{'width':'200%'}"></p-dropdown>
      </div>
      <div class="ui-g-8"></div>
      <div class="ui-g-4" *ngIf="selectedSupplier">
        <p-dropdown [options]="uploadOptions" optionLabel="description" [(ngModel)]="selectedOption"
          (onChange)="setURL()" placeholder="Select Import Type" [style]="{'width':'100%'}"></p-dropdown>
      </div>
      <div class="ui-g-8"></div>
    </div>
    <p-progressSpinner *ngIf="showSpinner" [style]="{width: '40px', height: '40px'}" strokeWidth="6" fill="#EEEEEE"
      animationDuration="2s"></p-progressSpinner>
    <div class="ui-g" *ngIf="hasChosenType">
      <div class="ui-g-12">
        <p-fileUpload id="upload" name="file[]" [url]="apiUrl" [headers]="headers" (onUpload)="onUpload($event)"
          (onError)="onError()" (onBeforeUpload)="onBeforeUpload($event)" (onBeforeSend)="onBeforeSend($event)">
          <ng-template pTemplate="content">
            <li *ngFor="let file of uploadedFiles">{{file.name}}</li>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
    <div class="ui-g" *ngIf="uploadPassed != null">
      <div class="ui-g-12">
        <div class="ui-g-6" *ngIf="!uploadPassed">
          <div class="ui-g-12">
            <h3 class="first">Import Data Errors</h3>
          </div>
          <div class="ui-g-12">
            <label class="first">Please fix these errors before importing this file</label>
          </div>
        </div>
        <div class="ui-g-6" *ngIf="uploadPassed">
          <div class="ui-g-12">
            <h3 class="first">Import Data Info</h3>
          </div>
          <div class="ui-g-12">
            <label class="first">Please see a summary below</label>
          </div>
        </div>
        <div class="ui-g-6" *ngIf="!uploadPassed">
          <div class="ui-g-12">
            <h3 class="first">Exception Message</h3>
          </div>
          <div class="ui-g-12">
            <label>{{importResults.ExceptionMessage}}</label>
          </div>
        </div>
        <div class="ui-g-6" *ngIf="uploadPassed">
          <div class="ui-g-12">
            <h3 class="first">Message</h3>
          </div>
          <div class="ui-g-12">
            <label>Upload successful please see below for more details</label>
          </div>
        </div>
      </div>
      <div class="ui-g-12">
        <div class="ui-g-2">
          <label>Processed Rows: {{importResults.RowsProcessed}}</label>
        </div>
        <div class="ui-g-2">
          <label>Ignored Rows: {{importResults.RowsSkipped}}</label>
        </div>
        <div class="ui-g-2">
          <label>New Files: {{importResults.RowsCreated}}</label>
        </div>
        <div class="ui-g-2">
          <label>Updated Files: {{importResults.RowsUpdated}}</label>
        </div>
        <div class="ui-g-2">
          <label>Failed Rows:
            {{importResults.RowsProcessed - importResults.RowsCreated - importResults.RowsSkipped- importResults.RowsUpdated}}</label>
        </div>
      </div>
      <div class="ui-g-12">
        <p-table #dt [columns]="cols" [value]="importResults.ErrorMessages" exportFilename="ImportErrors"
          [pageLinks]="3" [rows]="50" [paginator]="true" dataKey="error" [autoLayout]="true"
          [globalFilterFields]="['RowNumber','ReferenceNumber', 'Errors', 'Warnings']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i style="color: white;" class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search keyword" style="color: #fff !important;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Row Number</th>
              <th>Reference Number</th>
              <th>Import Error(s)</th>
              <th>Warnings</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-error>
            <tr>
              <td>{{error.RowNumber}}</td>
              <td>{{error.ReferenceNumber}}</td>
              <td class="wrapText">{{error.Errors}}</td>
              <td class="wrapText">{{error.Warnings}}</td>
          </ng-template>
        </p-table>
      </div>


    </div>
  </div>
</div>