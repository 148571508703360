<div class="ui-g-12 centerSpinner" *ngIf="showLoadingSpinner">
  <p-progressSpinner id="p-spinner" [style]="{ width: '50px', height: '50px' }" strokeWidth="6" fill="#EEEEEE"
    animationDuration="2s"></p-progressSpinner>
</div>

<div class="ui-g-12 card card-w-title" *ngIf="!showLoadingSpinner && fileId && failReasons">
  <div class="ui-g-12">
    <h1>Fail Reasons for Storage Invoice Auto Generation</h1>
    <p>Please ensure all checks are correct</p>
  </div>
  <div class="ui-g-12">
    <p><strong>Error Message:</strong> {{ failReasons.failReason }}</p>
    <p><strong>Error Date:</strong> {{ failReasons.failDate}}</p>
  </div>
  <div class="ui-g-12 ui-g-nopad">
    <ul>
      <ng-container>
        <ul style="list-style-type: disc; padding-left: 20px;">
          <li style="display: flex; justify-content: flex-start; align-items: center;">
            <strong style="min-width: 150px;">Default Info:</strong>
            <div style="display: flex; align-items: center;">
              <span style="margin-right: 3px;">{{ failReasons.defaultInfo ? 'Yes' : 'No' }}</span>
              <span style="color: green;" *ngIf="failReasons.defaultInfo">&#10004;</span>
              <span style="color: red;" *ngIf="!failReasons.defaultInfo">&#10060;</span>
            </div>
          </li>
          <li style="display: flex; justify-content: flex-start; align-items: center;">
            <strong style="min-width: 150px;">Clearing Agent if PH:</strong>
            <div style="display: flex; align-items: center;">
              <span style="margin-right: 3px;">{{ failReasons.clearingAgentIfPH ? 'Yes' : 'No' }}</span>
              <span style="color: green;" *ngIf="failReasons.clearingAgentIfPH">&#10004;</span>
              <span style="color: red;" *ngIf="!failReasons.clearingAgentIfPH">&#10060;</span>
            </div>
          </li>
          <li style="display: flex; justify-content: flex-start; align-items: center;">
            <strong style="min-width: 150px;">Currently In storage:</strong>
            <div style="display: flex; align-items: center;">
              <span style="margin-right: 3px;">{{ failReasons.currentlyInStorage ? 'Yes' : 'No' }}</span>
              <span style="color: green;" *ngIf="failReasons.currentlyInStorage">&#10004;</span>
              <span style="color: red;" *ngIf="!failReasons.currentlyInStorage">&#10060;</span>
            </div>
          </li>
          <li style="display: flex; justify-content: flex-start; align-items: center;">
            <strong style="min-width: 150px;">Storage Has Matrix:</strong>
            <div style="display: flex; align-items: center;">
              <span style="margin-right: 3px;">{{ failReasons.storageLocationHasMatrix ? 'Yes' : 'No' }}</span>
              <span style="color: green;" *ngIf="failReasons.storageLocationHasMatrix">&#10004;</span>
              <span style="color: red;" *ngIf="!failReasons.storageLocationHasMatrix">&#10060;</span>
            </div>
          </li>
          <li style="display: flex; justify-content: flex-start; align-items: center;">
            <strong style="min-width: 150px;">File Has Unload Date:</strong>
            <div style="display: flex; align-items: center;">
              <span style="margin-right: 3px;">{{ failReasons.fileHasUnloadDate ? 'Yes' : 'No' }}</span>
              <span style="color: green;" *ngIf="failReasons.fileHasUnloadDate">&#10004;</span>
              <span style="color: red;" *ngIf="!failReasons.fileHasUnloadDate">&#10060;</span>
            </div>
          </li>
        </ul>
      </ng-container>
      <p style="margin-left: -30px;"><strong>Once all checks are correct and all errors have been addressed, please
          retry
          generating
          the storage invoice.</strong></p>
    </ul>
  </div>
  <div class="ui-g-12">
    <button *ngIf="!actionSpinner" pButton class="width-100" (click)="retryStorageInvoiceGeneration()" label="Retry
      Storage Auto Gen"></button>
    <button *ngIf="actionSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</div>