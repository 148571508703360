<div class="centerSpinner" *ngIf="loadingSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>
<div *ngIf="shipmentDetailsForm && !loadingSpinner" class="ui-g-12" [formGroup]="shipmentDetailsForm">
  <div class="ui-g-12">
    <!-- <p-fieldset legend="Shipping Details"> -->
    <div class="ui-g-6">
      <div class="ui-g">
        <p-fieldset legend="File Details">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%'}" [options]="fileDocumentSources"
                  formControlName="fileDocumentSource" [autoDisplayFirst]="false" optionLabel="label" [showClear]="true"
                  required></p-dropdown>
                <label class="pad-left" for="float-input">File Document Source *</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%'}" [options]="fileTypeItems" formControlName="filetype"
                  [autoDisplayFirst]="false" optionLabel="Name" (onChange)="setFileType($event)" [showClear]="true"
                  required></p-dropdown>
                <label class="pad-left" for="float-input">Type *</label>
              </span>
            </div>
          </div>
        </p-fieldset>
        <p-fieldset legend="Shipping Details">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-12 ">
              <span class="ui-g-12 ui-float-label">
                <p-autoComplete [(ngModel)]="selectedMBL" [suggestions]="MBL" (completeMethod)="searchMBL($event)"
                  field="MBL" [dropdown]="true" (onSelect)="getVoyageDetails($event)" formControlName="mblNo">
                  <ng-template let-mbl pTemplate="item">
                    <div class="ui-helper-clearfix">
                      {{mbl.MBL}}
                    </div>
                  </ng-template>
                </p-autoComplete>
                <label class="pad-left" for="float-input">MBL *</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <span class="ui-g-12 ui-float-label">
                <input class="percent100" type="text" pInputText formControlName="vesselName">
                <label class="pad-left" for="float-input">Vessel Name</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <span class="ui-g-12 ui-float-label">
                <input class="percent100" type="text" pInputText formControlName="voyageNo">
                <label class="pad-left" for="float-input">Voyage #</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <app-location [LocationTypeId]="1" formControlName="portOfDeparture" ShowFloatLabel="true"
                FloatLabel="Port Of Departure *" (OnSelectLocation)="onSelectDepartureCity($event)" [Required]="true">
              </app-location>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <app-location [LocationTypeId]="1" formControlName="portOfArrival" ShowFloatLabel="true"
                FloatLabel="Port Of Arrival *" (OnSelectLocation)="onSelectArrivalCity($event)" [Required]="true">
              </app-location>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <span class="ui-g-12 ui-float-label">
                <p-calendar [style]="{'width':'100%'}" showButtonBar="true" [readonlyInput]="true"
                  [inputStyle]="{'width':'100%'}" required formControlName="etd" dateFormat="yy/mm/dd">
                </p-calendar>
                <label class="pad-left" for="float-input">ETD *</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <span class="ui-g-12 ui-float-label">
                <p-calendar [style]="{'width':'100%'}" showButtonBar="true" [readonlyInput]="true"
                  [inputStyle]="{'width':'100%'}" required formControlName="sob" dateFormat="yy/mm/dd">
                </p-calendar>
                <label class="pad-left" for="float-input">SOB *</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <span class="ui-g-12 ui-float-label">
                <p-calendar [style]="{'width':'100%'}" showButtonBar="true" [readonlyInput]="true"
                  [inputStyle]="{'width':'100%'}" formControlName="eta" dateFormat="yy/mm/dd">
                </p-calendar>
                <label class="pad-left" for="float-input">ETA</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-12 text-center">
              <strong><label class="pad-left">Tranship Details</label></strong>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%'}" [options]="vesselNames" [filter]="true"
                  formControlName="vesselNameTranship" optionLabel="Name" [showClear]="true" [autoDisplayFirst]="false"
                  (onChange)="getVoyageNumbersTranship()">
                </p-dropdown>
                <label class="pad-left" for="float-input">Vessel Name</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%', 'minWidth':'100%'}" [options]="voyagesTranship" [filter]="false"
                  maxlength="20" [disabled]="!shipmentDetailsForm.value.vesselNameTranship" [autoDisplayFirst]="false"
                  formControlName="voyageNoTranship" optionLabel="Name" [showClear]="true">
                </p-dropdown>
                <label class="pad-left" for="float-input">Voyage #</label>
              </span>
            </div>
          </div>
        </p-fieldset>
      </div>
    </div>
    <div class="ui-g-6">
      <div class="ui-g">
        <p-fieldset legend="Supplier Details">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6 ">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%'}" [options]="suppliers" [filter]="true" [autoDisplayFirst]="false"
                  formControlName="supplier" optionLabel="Name" [showClear]="true" required></p-dropdown>
                <label class="pad-left" for="float-input">Supplier *</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
              <span class="ui-g-12 ui-float-label">
                <input class="percent100 margin-top-3px" required type="text" pInputText formControlName="referenceNo">
                <label class="pad-left" for="float-input">Supplier Reference# *</label>
              </span>
            </div>
          </div>
        </p-fieldset>
        <p-fieldset legend="Delivery">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-12 ">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%'}" [options]="courierCompanies" [filter]="false"
                  [autoDisplayFirst]="false" formControlName="courierCompany" optionLabel="label" [showClear]="true">
                </p-dropdown>
                <label class="pad-left" for="float-input">Courier</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-12 ">
              <span class="ui-g-12 ui-float-label">
                <input class="percent100" type="text" pInputText formControlName="trackingNo">
                <label class="pad-left" for="float-input">Tracking</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <span class="ui-g-12 ui-float-label">
                <p-calendar [style]="{'width':'100%'}" showButtonBar="true" [readonlyInput]="true"
                  [inputStyle]="{'width':'100%'}" formControlName="courierOriginalReceivedDate" dateFormat="yy/mm/dd">
                </p-calendar>
                <label class="pad-left" for="float-input">Originals Received</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown [style]="{'width':'100%'}" [options]="borderAgents" [filter]="false" formControlName="agent"
                  [autoDisplayFirst]="false" optionLabel="label" [showClear]="true"
                  (onChange)="onAgentComplete($event)">
                </p-dropdown>
                <label class="pad-left" for="float-input">Border Agent</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-md-12 text-center">
              <strong><label class="pad-left">Master Route</label></strong>
              <i pRipple class="fa fa-plus-circle floatRight add-route" (click)="createNewRoute()">
              </i>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <span class="ui-g-12 ui-float-label">
                <p-dropdown styleClass="ui-g-12" hide="enableRouting" (onChange)="onSelectRoute($event)"
                  [style]="{'width': '100%'}" [autoDisplayFirst]="false" [options]="routes" formControlName="route"
                  optionLabel="route" [filter]="true" [showClear]="true">
                </p-dropdown>
                <label class="pad-left" for="float-input">Route *</label>
              </span>
            </div>
          </div>
        </p-fieldset>
      </div>
    </div>
    <div class="ui-g-12">
      <button pRipple pButton class="p-button-info" icon="fas fa-save" iconPos="right" label="Save & Next"
        (click)="Next()" [disabled]="!validForm()">
      </button>
    </div>
  </div>
</div>