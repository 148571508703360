<div class="ui-g card card-w-title">
    <div class="ui-g-12">
        <div class="ui-g-2">
            <h1>Communicator</h1>
        </div>
        <div class="ui-g-10 buttons">
            <button pRipple pButton class="p-button-info margin-right" [disabled]="checkIfValid()" label="SMS"
                icon="fas fa-comment-alt" (click)="showSMSDialog()">
            </button>
            <button pRipple pButton class="p-button-info margin-right" [disabled]="checkIfValid()" label="Email"
                icon="fas fa-envelope" (click)="showEmailDialog()">
            </button>
            <button pRipple pButton class="p-button-info margin-right" [disabled]="checkIfValid()"
                label="Export Selection" icon="fas fa-download" (click)="dt.exportCSV({selectionOnly:true})"></button>
            <button pRipple pButton class="p-button-info margin-right" [disabled]="checkIfExportAllValid()"
                label="Export All" icon="fas fa-download" (click)="dt.exportCSV()"></button>
            <p-menu #menu [popup]="true" [model]="filterMenuitems"></p-menu>
            <button pRipple class="p-button-info" type="button" pButton icon="pi pi-bars" label="Export Filters"
                (click)="menu.toggle($event)"></button>
        </div>
    </div>
    <div class="ui-g-12">
        <p-progressSpinner align="center" *ngIf="showSpinner" [style]="{width: '30px', height: '30px'}" strokeWidth="4"
            fill="#EEEEEE" animationDuration="3s">
        </p-progressSpinner>
    </div>

    <div class="ui-g-5">
        <p-panel header="Message/Export" [style]="{'height':'auto'}">
            <p-tabView (onChange)="setTabIndex($event)">
                <p-tabPanel header="Current Voyages" leftIcon="fas fa-history">
                    <ng-template pTemplate="content">
                        <div class="p-grid">
                            <div class="ui-g-4">
                                <label>Vessel/Voyage:</label>
                            </div>
                            <div class="ui-g-8">
                                <p-dropdown [options]="vessels" [(ngModel)]="selectedActiveVoyage" optionLabel="label"
                                    [filter]="true" (onChange)="disableFilterButton = false"
                                    placeholder="Select a Vessel Voyage">
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="By Vessel/Voyage" leftIcon="fas fa-history">
                    <ng-template pTemplate="content">
                        <div class="p-grid">
                            <div class="ui-g-4"><label>Vessel:</label></div>
                            <div class="ui-g-8">
                                <p-dropdown [options]="allVessels" [(ngModel)]="selectedVessel" [filter]="true"
                                    placeholder="Select a Vessel" optionLabel="name" (onChange)="getVoyages()">
                                </p-dropdown>
                            </div>

                            <div class="ui-g-4">
                                <label>Voyage:</label>
                            </div>
                            <div class="ui-g-8">
                                <p-dropdown [options]="voyages" optionLabel="name" placeholder="Select a Voyage"
                                    [filter]="true" [(ngModel)]="selectedVoyage"
                                    (onChange)="disableFilterButton = false">
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="By Date" leftIcon="fas fa-car">
                    <ng-template pTemplate="content">
                        <div class="p-grid">
                            <div class="ui-g-3">
                                <label>From</label>
                            </div>
                            <div class="ui-g-9">
                                <p-calendar [(ngModel)]="fromDate" dateFormat="yy/mm/dd"></p-calendar>
                            </div>
                            <div class="ui-g-3">
                                <label>To</label>
                            </div>
                            <div class="ui-g-9">
                                <p-calendar [(ngModel)]="toDate" dateFormat="yy/mm/dd"
                                    (onSelect)="disableFilterButton = false"></p-calendar>
                            </div>
                        </div>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </p-panel>
        <div class="ui-g">
            <div class="p-col-12 rightInDiv">
                <button pRipple pButton class="p-button-info" *ngIf="!showSpinner" [disabled]="disableFilterButton"
                    label="Filter" icon="fas fa-filter" (click)="filter()"></button>
            </div>
        </div>
    </div>

    <div class="ui-g-7">
        <p-panel header="Additional Filters" [style]="{'height':'auto'}">
            <div class="p-grid">
                <div class="p-col-12 p-lg-4 p-md-3">
                    <p-checkbox label="Payments Received" [(ngModel)]="paymentReceived" binary="false"></p-checkbox>
                </div>
                <div class="p-col-12 p-lg-2 p-md-2">
                    <label>File Type</label>
                </div>
                <div class="p-col-12 p-lg-6 p-md-7">
                    <p-radioButton name="group1" value="0" label="All" [(ngModel)]="fileType" inputId="preopt1">
                    </p-radioButton>
                    <p-radioButton name="group1" value="1" [style]="{'margin-left': '10px'}" label="AVECS"
                        [(ngModel)]="fileType" inputId="preopt2"></p-radioButton>
                    <p-radioButton name="group1" value="2" [style]="{'margin-left': '10px'}" label="Handover"
                        [(ngModel)]="fileType" inputId="preopt3"></p-radioButton>
                    <p-radioButton name="group1" value="3" [style]="{'margin-left': '10px'}" label="Partial Handover"
                        [(ngModel)]="fileType" inputId="preopt4"></p-radioButton>
                </div>
                <div class="p-col-12 p-lg-6 p-md-6">
                    <p-checkbox label="Payments Outstanding" [(ngModel)]="paymentOutstanding" binary="false">
                    </p-checkbox>
                </div>
                <div class="p-col-12 p-lg-2 p-md-2">
                    <label>Country</label>
                </div>
                <div class="p-col-12 p-lg-4 p-md-4">
                    <p-dropdown [style]="{'width':'120px'}" [options]="countries" [(ngModel)]="selectedCountry"
                        [filter]="true" placeholder="Select a Country" optionLabel="name" [showClear]="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-lg-6 p-md-6">
                    <p-checkbox label="CIF Clients" [(ngModel)]="cIFClients" binary="false"></p-checkbox>
                </div>
                <div class="p-col-12 p-lg-2 p-md-2">
                    <label>Car Carrier</label>
                </div>
                <div class="p-col-12 p-lg-4 p-md-4">
                    <p-dropdown [style]="{'width':'120px'}" [options]="carriers" [(ngModel)]="selectedCarrier"
                        [filter]="true" placeholder="Select a Carrier" optionLabel="name" [showClear]="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-lg-6 p-md-6">
                    <p-checkbox label="Passport/ID Received" [(ngModel)]="passportReceived" binary="false">
                    </p-checkbox>
                </div>
                <div class="p-col-12 p-lg-2 p-md-2">
                    <label>Supplier</label>
                </div>
                <div class="-col-12 p-lg-4 p-md-4">
                    <p-dropdown [style]="{'width':'120px'}" [options]="suppliers" [(ngModel)]="selectedSupplier"
                        [filter]="true" placeholder="Select a Supplier" optionLabel="name" [showClear]="true">
                    </p-dropdown>
                </div>
                <div class="-col-12 p-lg-12 p-md-12">
                    <p-checkbox label="Passport/ID NOT Received" [(ngModel)]="passportNotReceived" binary="false">
                    </p-checkbox>
                </div>
            </div>
        </p-panel>
    </div>

</div>
<div class="ui-g-12">
    <p-table #dt [value]="files" [(selection)]="selectedFiles" dataKey="fileId" [showCurrentPageReport]="true"
        [rowHover]="true" [scrollable]="true" [rows]="1000" scrollHeight="400px"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="['dBNNumber','borderPost','description']" [columns]="cols" exportFilename="ExportedFiles"
        (onRowSelect)="onRowSelect(selectedFiles)" (onRowUnselect)="onRowUnselect(selectedFiles)"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" selectionMode="multiple">
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search keyword" style="color: #fff;" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th width="50px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th width="90px"></th>
                <th *ngFor="let col of columns" width="{{col.width}}" pSortableColumn="{{col.field}}">
                    {{col.header}} <p-sortIcon field="{{col.field}}">
                    </p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
            <tr (dblclick)="doubleClickFile(rowData)">
                <td width="50px">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td width="90px"> <button pButton type="button" icon="fas fa-qrcode" class="ui-button-rounded"
                        (click)="setupVCard(rowData.consigneeId)" pTooltip="View QR Code for Consignee"></button>
                    <button pButton type="button" icon="fas fa-folder" class="ui-button-rounded"
                        (click)="doubleClickFile(rowData)" pTooltip="Open File"></button>
                </td>
                <td *ngFor="let col of columns" width="{{col.width}}">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            Selected {{selectedFiles? selectedFiles.length : 0}} of {{files? files.length : 0}} files
        </ng-template>
        <!-- <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No cargo found.</td>
                </tr>
            </ng-template> -->
    </p-table>
</div>

<p-dialog [(visible)]="displaySMS" modal="modal" [responsive]="true" [draggable]="true">
    <p-header>
        Send SMS to Selected Clients
    </p-header>
    <textarea pInputTextarea [rows]="5" [cols]="33" [(ngModel)]="SMSText"></textarea>
    <p-footer>
        <button pRipple pButton class="p-button-info" label="Cancel" icon="fas fa-comment-alt"
            (click)="hideSMSDialog()"></button>
        <button pRipple pButton class="p-button-info" [disabled]="true" label="Send SMS"
            icon="fas fa-comment-alt"></button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="displayEmail" modal="modal" [style]="{width: '60vw'}" [responsive]="true" [draggable]="true">
    <p-header>
        Send Email to Selected Clients
    </p-header>
    <div class="ui-g">
        <div class="ui-g-2">
            <label>From :</label>
        </div>
        <div class="ui-g-10">
            <p-dropdown optionLabel="name" placeholder="Select a Sender" [options]="EmailAdresses"
                [(ngModel)]="emailFrom" [style]="{'width':'400px'}"></p-dropdown>
        </div>
        <div class="ui-g-2">
            <label>Templates (Optional):</label>
        </div>
        <div class="ui-g-4">
            <p-dropdown [options]="emailTemplates" [(ngModel)]="selecteEmailTemplate" (onChange)="getTemplateContent()"
                placeholder="Select a Template" [style]="{'width':'300px'}" optionLabel="Description">
            </p-dropdown>
        </div>
        <div class="ui-g-6">
            <button pRipple pButton class="p-button-info" icon="fas fa-times-circle" label="Clear Template"
                (click)="clearTemplate()"></button>
        </div>
        <div class="ui-g-2">
            <label>Subject :</label>
        </div>
        <div class="ui-g-10">
            <input type="text" pInputText size="60" [(ngModel)]="EmailSubject" />
        </div>
        <div class="ui-g-12">
            <p-editor [(ngModel)]="EmailText" [readonly]="emailReadOnly" [style]="{'height':'320px'}">
                <p-header>
                    <span class="ql-formats">
                        <select class="ql-size">
                            <option value="small">Small</option>
                            <option selected></option>
                            <option value="large">Large</option>
                            <option value="huge">Huge</option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-bold" aria-label="Bold"></button>
                        <button class="ql-italic" aria-label="Italic"></button>
                        <button class="ql-underline" aria-label="Underline"></button>
                        <button class="ql-strike" aria-label="Strike"></button>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" title="List"></button>
                        <button class="ql-bullet" title="Bullet"></button>
                        <select title="Text Alignment" class="ql-align">
                            <option selected>Alignment</option>
                            <option value="center" label="Center"></option>
                            <option value="right" label="Right"></option>
                            <option value="justify" label="Justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button aria-label="Link" class="ql-link"></button>
                    </span>
                </p-header>
            </p-editor>
        </div>
    </div>
    <p-footer>
        <button pRipple pButton class="p-button-info" label="Cancel" icon="fas fa-times-circle"
            (click)="hideEmailDialog()"></button>
        <button pRipple pButton class="p-button-info" [disabled]="disableSendEmailButton()" label="Send Email"
            icon="fas fa-comment-alt" (click)="sendEmail()"></button>
    </p-footer>
</p-dialog>


<p-dialog header="Scan QR Code for Contact Info" [style]="{width: '20vw'}" [dismissableMask]="true"
    [(visible)]="displayQRCode" [responsive]="true" showEffect="fade" [modal]="true">
    <div class="ui-g">
        <div class="ui-g-12 centerInDiv">
            <qr-code [value]="vCardData" [size]="150"></qr-code>
        </div>
    </div>
    <p-footer>
        <button pRipple class="p-button-info" label="Done" type="button" (click)="displayQRCode = false"
            pButton></button>
    </p-footer>
</p-dialog>