<div class="overlay" *ngIf="showLoadingSpinner">
  <p-progressSpinner class="content" [style]="{ width: '50px', height: '50px' }" strokeWidth="8" fill="#EEEEEE"
    animationDuration="2s"></p-progressSpinner>
</div>
<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Vessel Management:</h1>
  </div>
  <div class="ui-g-12">
    <p-table #vt [autoLayout]="true" [value]="vesselNames" [scrollable]="true" [rows]="50" scrollHeight="350px"
      [paginator]="true" [globalFilterFields]="['Name']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add a Vessel"
              (click)="addVesselDialogue()" type="button" style="float: right; margin-right: 15px"
              icon="fas fa-plus"></button>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="vt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search vessels" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Vessel Name</th>
          <th>
            Actions
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td [ngStyle]="{
                  'background-color':
                    rowData.VesselCompanyLegalEntityId == -1 ||
                    rowData.VesselCompanyLegalEntityId == 0
                      ? '#FFC1CD'
                      : 'transparent'
                }">
            {{ rowData.Name }}
          </td>
          <td>
            <button pRipple class="p-button-info" pTooltip="Voyages" tooltipPosition="bottom" pButton type="button"
              icon="fas fa-ship" (click)="getVesselInfo(rowData)"></button>
            <button pRipple class="p-button-info" pTooltip="Delete Vessel" tooltipPosition="bottom" pButton
              type="button" icon="fas fa-trash-alt" (click)="deleteVessel(rowData)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-container *ngIf="voyages">
    <div class="ui-g-12">
      <h1>Voyages:</h1>
    </div>
    <div class="ui-g-12">
      <p-table #vo [autoLayout]="true" [value]="voyages" [columns]="itemCols" [scrollable]="true" [rows]="50"
        scrollHeight="350px" [paginator]="true" [globalFilterFields]="['VesselName', 'VesselCompanyName', 'Name',
        'AssignedTo']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left">
              <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add a Voyage"
                (click)="addVoyage()" type="button" style="float: right; margin-right: 15px"
                icon="fas fa-plus"></button>
            </span>
            <span class="p-input-icon-left p-ml-auto">
              <i style="color: white;" class="pi pi-search"></i>
              <input pInputText type="text" (input)="vo.filterGlobal($event.target.value, 'contains')"
                placeholder="Search voyages" style="color: #fff !important;" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let itemcol of itemCols">
              {{ itemcol.header }}
            </th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [ngStyle]="{
              'background-color':
                rowData.ETA == '' || rowData.Name == ''
                  ? 'red'
                  : 'transparent'
            }">
            <td>
              {{ vesselInfo.VesselName }}
            </td>
            <td>
              {{ vesselInfo.VesselCompanyName }}
            </td>
            <td>
              {{ rowData.Name }}
            </td>
            <td>
              {{ rowData.ETA }}
            </td>
            <td>
              {{ rowData.CargoItemCount }}
            </td>
            <td>
              {{ rowData.AssignedTo }}
            </td>
            <td>
              <button pRipple class="p-button-info" pTooltip="View Cargo" tooltipPosition="bottom" pButton type="button"
                icon="far fa-eye" (click)="viewCargoForVoyage(rowData)"></button>
              <button pRipple class="p-button-info" pTooltip="Edit Voyage" tooltipPosition="bottom" pButton
                type="button" icon="far fa-edit" (click)="displayEditVoyageDialogue(rowData)"></button>
              <button pRipple class="p-button-info" pTooltip="Delete Voyage" tooltipPosition="bottom" pButton
                type="button" icon="fas fa-trash-alt" (click)="deleteVoyage(rowData)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>

<!-- VESSEL DIALOGS -->
<p-dialog header="Add Vessel" modal="true" [(visible)]="displayDialog" [style]="{ width: '500px', height: '260px'}">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>Please ensure that you have added The Shipping Line as a Contact, and
        set the Company Type to 'Shipping Line'</label>
    </div>
    <div class="ui-g-3">
      <label>Vessel Name</label>
    </div>
    <div class="ui-g-9">
      <input class="width-100" pInputText [(ngModel)]="VesselName" />
    </div>
    <div class="ui-g-3">
      <label>Link to Contact</label>
    </div>
    <div class="ui-g-9">
      <p-dropdown [options]="vesselCompanys" [(ngModel)]="selectedShippingLine" placeholder="Select a Shipping line"
        optionLabel="Name" [filter]="true" appendTo="body" [disabled]="!VesselName" [style]="{width: '100%'}">
      </p-dropdown>
    </div>
    <div class="ui-g-12">
      <button pRipple class="p-button-info width-100" pButton type="button" (click)="addVessel()" label="Save"
        [disabled]="!VesselName || !selectedShippingLine"></button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Delete Vessel" [(visible)]="deleteVesselDialogue" width="500" [responsive]="true" showEffect="fade"
  [modal]="true" height="200">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="Vessel Name">Are you sure you want to delete this vessel?</label>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!deleteSpinner" pRipple class="p-button-info width-100" type="submit" pButton label="Yes"
      (click)="continueDeleteVessel()"></button>
    <button *ngIf="deleteSpinner" [disabled]="true" pRipple class="p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner"></i>
    </button>
  </p-footer>
</p-dialog>

<!-- VOYAGE DIALOGS -->
<p-dialog header="Edit Voyage {{ vesselInfo?.VesselName }} [{{ vesselInfo?.VesselCompanyName }}]"
  [(visible)]="editVoyageDialogue" [responsive]="true" showEffect="fade" [modal]="true" [dismissableMask]="true"
  [responsive]="true" showEffect="fade" [style]="{width: '60vw'}">
  <div class="ui-g">
    <div class="ui-g-2">
      <label for="Voyage Number">Voyage Number</label>
    </div>
    <div class="ui-g-10">
      <input pInputText class="width-100" [(ngModel)]="voyageNumber" />
    </div>
    <div class="ui-g-2">
      <label for="Voyage ETA">Voyage ETA</label>
    </div>
    <div class="ui-g-10">
      <p-calendar [(ngModel)]="voyageETA" appendTo="body" [readonlyInput]="true" dataType="string"
        dateFormat="yy/mm/dd">
      </p-calendar>
    </div>
    <div class="ui-g-2">
      <label for="Voyage Number">Assigned To</label>
    </div>
    <div class="ui-g-10">
      <p-dropdown [options]="voyageAssignees" optionLabel="label" dataKey="value" [(ngModel)]="selectedVoyageAssignee"
        [style]="{width: '100%'}" placeholder="Select an Assignee" optionLabel="label" [filter]="true" appendTo="body">
      </p-dropdown>
    </div>
    <div class="ui-g">
      <div class="ui-g-12">
        <h3>Voyage History:</h3>
      </div>
      <div class="ui-g-12" *ngIf="voyageHistory.length == 0">
        <p>No history found</p>
      </div>
      <div class="ui-g-12" *ngIf="voyageHistory.length > 0">
        <p-table #vh [autoLayout]="true" [value]="voyageHistory" [paginator]="true" [rows]="50" [scrollable]="true"
          scrollHeight="350px" [globalFilterFields]="['assignedFrom', 'assignedTo', 'assignedBy']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i style="color: white;" class="pi pi-search"></i>
                <input pInputText type="text" (input)="vh.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search voyage history" style="color: #fff !important;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Assigned Date</th>
              <th>Assigned From</th>
              <th>Assigned To</th>
              <th>Assigned By</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>
                {{ formatDate(rowData.insertDate) }}
              </td>
              <td>
                {{ rowData.assignedFrom }}
              </td>
              <td>
                {{ rowData.assignedTo }}
              </td>
              <td>
                {{ rowData.assignedBy }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p-footer class="ui-g-12">
      <button pRipple class="p-button-info width-100" pButton type="submit" label="Save" (click)="updateVoyage()"
        [disabled]="disabled()"></button>
    </p-footer>
  </div>
</p-dialog>

<p-dialog header="Create New Voyage" modal="true" appendTo="body" width="450" minWidth="400" responsive="true"
  [(visible)]="newVoyage" blockScroll="true" [style]="{width: '500px', height: '250px'}">
  <div>
    <div class="ui-g-4">
      <label>Voyage Number: </label>
    </div>
    <div class="ui-g-8">
      <input type="text" class="width-100" maxlength="15" pInputText [(ngModel)]="voyageNumber" />
    </div>
    <div class="ui-g-4">
      <label>Voyage ETA: </label>
    </div>
    <div class="ui-g-8">
      <p-calendar [(ngModel)]="voyageETA" dataType="string" dateFormat="yy/mm/dd" appendTo="body">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info width-100" pButton type="submit" label="Save" (click)="saveNewVoyage()"
      [disabled]="disabled()"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Reassign Voyages" [(visible)]="reassignVoyagesDialog" [responsive]="true" showEffect="fade"
  [modal]="true" [dismissableMask]="true" [responsive]="true" showEffect="fade" (onHide)="generateNewEditVoyageForm()">
  <div class="ui-g ui-fluid">
    <div class="ui-g-12">
      <label><strong>{{ selectedVessel?.Name }}</strong> has voyage(s) with cargo. Please reassign all of the cargo
        to other voyage(s) before deleting the vessel.</label>
    </div>
  </div>
</p-dialog>

<p-dialog header="Delete Voyage" [(visible)]="deleteVoyageDialogue" width="500" [responsive]="true" showEffect="fade"
  [modal]="true" height="200">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="Vessel Name">Are you sure you want to delete this voyage?</label>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!deleteSpinner" pRipple class="p-button-info width-100" type="submit" pButton label="Yes"
      (click)="continueDeleteVoyage()"></button>
    <button *ngIf="deleteSpinner" [disabled]="true" pRipple class="p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Voyages Exist" [(visible)]="deleteVesselWarningDialogue" width="500" [responsive]="true"
  showEffect="fade" [modal]="true" height="200">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="Vessel Name">This Vessel has Voyages assigned to it. Please Reassign the Cargo to
        other Voyages before deleting this vessel</label>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button pRipple class="p-button-info" style="margin-top: 15px" type="submit" pButton label="Okay"
        icon="fa fa-check" (click)="closeVoyagesExistWarning()"></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="Reassign Cargo to another Voyage" modal="true" [(visible)]="displayReassignVoyageModal"
  [style]="{ width: '50vw' }">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>This voyage has cargo assigned to it. Please select another Voyage to
        reassign this cargo to:</label>
    </div>
    <div class="ui-g-3">
      <label>Vessel</label>
    </div>
    <div class="ui-g-9">
      <p-dropdown [style]="{width: '100%'}" [options]="vesselNames" [(ngModel)]="selectedReassignVessel"
        placeholder="Select a Vessel" optionLabel="Name" (onChange)="getReassignVoyages()" [filter]="true"
        appendTo="body"></p-dropdown>
    </div>
    <div class="ui-g-3">
      <label>Voyage</label>
    </div>
    <div class="ui-g-9">
      <p-dropdown [options]="reassignVoyages" [style]="{width: '100%'}" optionLabel="Name" placeholder="Select a Voyage"
        [(ngModel)]="selectedReassignVoyage" [filter]="true" appendTo="body"></p-dropdown>
    </div>
    <div class="ui-g-12">
      <button pRipple class="p-button-info width-100" [disabled]="!selectedReassignVessel || !selectedReassignVoyage"
        pButton type="button" (click)="reassignVoyage()" label="Reassign"></button>
    </div>
  </div>
</p-dialog>

<p-dialog header="{{ vesselInfo?.VesselName }} [{{ vesselInfo?.VesselCompanyName }}] - {{ selectedVoyage?.Name }} Cargo"
  modal="true" [(visible)]="displayCargo" [style]="{ width: '1300px' }">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-table #voc [columns]="cols" [autoLayout]="true" [rows]="50" [value]="voyageCargo" scrollHeight="550px"
        [scrollable]="true" [paginator]="true"
        [globalFilterFields]="['DBNNumber', 'ForeignReference', 'Consignee','Description']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i style="color: white;" class="pi pi-search"></i>
              <input pInputText type="text" (input)="voc.filterGlobal($event.target.value, 'contains')"
                placeholder="Search voyage cargo" style="color: #fff !important;" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>