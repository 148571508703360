<div *ngIf="!editMode">
  <div class="ui-g-4">
    <p-fieldset legend="Vessels/Voyage" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>Vessel Name:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.VesselName : ''}}
        </div>
        <div class="ui-g-5">
          <strong>Voyage Number:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.VoyageNumber : ''}}
        </div>
        <div class="ui-g-5">
          <strong>Master Bill of Lading:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.MBL : ''}}
        </div>
        <div class="ui-g-5">
          <strong>Transship Vessel Name:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.TransshipVesselName : ''}}
        </div>
        <div class="ui-g-5">
          <strong>Transship Voyage Number:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.TransshipVoyageNumber : ''}}
        </div>
      </div>
    </p-fieldset>
    <p-fieldset legend="Departure/Arrival" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>Port of Departure:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.PortDeparture : ''}}
        </div>
        <div class="ui-g-5">
          <strong>Port of Arrival:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.PortArrival : ''}}
        </div>
      </div>
    </p-fieldset>
  </div>
  <div class="ui-g-4">

    <p-fieldset legend="Dates" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>ETD:</strong>
        </div>
        <div class="ui-g-7">
          {{ETD}}
        </div>
        <div class="ui-g-5">
          <strong>ETA:</strong>
        </div>
        <div class="ui-g-7">
          {{ETA}}
        </div>
        <div class="ui-g-5">
          <strong>SOB:</strong>
        </div>
        <div class="ui-g-7">
          {{SOB}}
        </div>
      </div>
    </p-fieldset>
    <p-fieldset legend="BOE" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>BOE #:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.BOENumber : ''}}
        </div>
        <div class="ui-g-5">
          <strong>BOE Date:</strong>
        </div>
        <div class="ui-g-7">
          {{BOEDate}}
        </div>
      </div>
    </p-fieldset>
    <p-fieldset legend="Border Post" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>Border Post:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.BorderPost : ''}}
        </div>
      </div>
    </p-fieldset>
  </div>
  <div class="ui-g-4">
    <p-fieldset legend="Route (*Required)" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-12">
          {{Shipping && Shipping.Route ? Shipping.Route : 'No Route Found For File'}}
        </div>
      </div>
    </p-fieldset>
  </div>


  <div class="ui-g-12 editButton" *ngIf="!readOnly">
    <button pRipple class="p-button-info" pButton type="button" header="edit" icon="far fa-edit"
      (click)="toggleEditMode()"></button>
  </div>
</div>

<div *ngIf="editMode">

  <div class="ui-g-4">
    <p-fieldset legend="Vessels/Voyage" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>Vessel Name:</strong>
        </div>
        <div class="ui-g-7">
          <p-dropdown [options]="vessels" optionLabel="Name" [(ngModel)]="selectedVessel" [filter]="true"
            (onChange)="setvessel()" placeholder="Select a Vessel" [style]="{'width':'100%'}">
          </p-dropdown>
        </div>
        <div class="ui-g-5">
          <strong>Voyage Number:</strong>
        </div>
        <div class="ui-g-7">
          <p-dropdown [options]="voyages" [(ngModel)]="selectedVoyage" optionLabel="Name" [filter]="true"
            placeholder="Select a Voyage" [style]="{'width':'100%'}" (onChange)="getMBLNumbers()">
          </p-dropdown>
        </div>
        <div class="ui-g-5">
          <strong>Master Bill of Lading:</strong>
        </div>
        <div class="ui-g-7">
          <p-dropdown [options]="mblNumbers" optionLabel="MBL" (onChange)="setMBLNumber()" [filter]="true" [(ngModel)]="
                        selectedMBL" placeholder="Select MBL Number"></p-dropdown>

        </div>
      </div>
    </p-fieldset>
    <p-fieldset legend="Departure/Arrival" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>Port of Departure:</strong>
        </div>
        <div class="ui-g-7">
          <app-location [LocationTypeId]="1" [(ngModel)]="portOfDeparture"
            (OnSelectLocation)="onSelectDepartureCity($event)" [Required]="true">
          </app-location>
        </div>
        <div class="ui-g-5">
          <strong>Port of Arrival:</strong>
        </div>
        <div class="ui-g-7">
          <app-location [LocationTypeId]="1" [(ngModel)]="portOfArrival"
            (OnSelectLocation)="onSelectArrivalCity($event)" [Required]="true">
          </app-location>
        </div>


      </div>
    </p-fieldset>
  </div>
  <div class="ui-g-4">
    <p-fieldset legend="Dates" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>ETD:</strong>
        </div>
        <div class="ui-g-7">
          <p-calendar [style]="{'width':'100%'}" [(ngModel)]="Shipping.ETD" dateFormat="yy/mm/dd"
            [readonlyInput]="true">
          </p-calendar>
        </div>
        <div class="ui-g-5">
          <strong>ETA:</strong>
        </div>
        <div class="ui-g-7">
          <p-calendar [style]="{'width':'100%'}" [disabled]="true" [(ngModel)]="ETA" dateFormat="yy/mm/dd">
          </p-calendar>
        </div>
        <div class="ui-g-5">
          <strong>Ship On Board:</strong>
        </div>
        <div class="ui-g-7">
          <p-calendar [style]="{'width':'100%'}" [(ngModel)]="Shipping.SOB" dateFormat="yy/mm/dd"
            [readonlyInput]="true">
          </p-calendar>
        </div>
      </div>
    </p-fieldset>
    <p-fieldset legend="BOE" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>BOE Number:</strong>
        </div>
        <div class="ui-g-7">
          <div class="ui-g-4"><input type="text" pInputText [(ngModel)]="Shipping.BOENumber" />
          </div>
        </div>
        <div class="ui-g-5">
          <strong>BOE Date:</strong>
        </div>
        <div class="ui-g-7">
          <p-calendar [(ngModel)]="Shipping.BOEDate" dateFormat="yy/mm/dd" [readonlyInput]="true">
          </p-calendar>
        </div>
      </div>
    </p-fieldset>
    <p-fieldset legend="Border Post" [style]="{'height':'auto'}">
      <div class="ui-g">
        <div class="ui-g-5">
          <strong>Border Post:</strong>
        </div>
        <div class="ui-g-7">
          {{Shipping? Shipping.BorderPost : ''}}
        </div>
      </div>
    </p-fieldset>
  </div>
  <div class="ui-g-4">
    <p-fieldset legend="Route (*Required)" [style]="{'height':'auto'}">
      <div class="ui-g" [style.background-color]="routeChanged ? '#ffc1cd' : 'transparent'">
        <div class="ui-g-11">
          <p-dropdown *ngIf="routes.length" hide="enableRouting" (onChange)="onSelectRoute($event)" [options]="routes"
            [(ngModel)]="selectedRoute" [style]="{'width':'100%'}" optionLabel="route" [filter]="true"
            [showClear]="true" placeholder="Select a Route">
          </p-dropdown>
          <label *ngIf="!routes.length">Please add a new route...</label>
        </div>
        <div class="ui-g-1">
          <button pRipple pButton class="p-button-info" icon="fa fa-plus-circle" class="floatRight"
            (click)="createNewRoute()"></button>
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="ui-g-12 editButton">
    <button pRipple class="p-button-info" pButton type="button" icon="fas fa-times" (click)="cancelEdit()"></button>
    <button pRipple class="p-button-info" [disabled]="routeChanged" pButton type="button" icon="fas fa-check"
      (click)="update(false)"></button>
  </div>
</div>

<p-dialog *ngIf="showConfirmRouteChange" [modal]="true" [draggable]="false" [style]="{width: '50%', height: '30vh'}">
  <p-header>
    Warning
  </p-header>
  <h1>{{UpdateRouteError}}</h1>
  <p>Would you like to update the route anyway? Some information may be pulled through to the new route but there may
    also be some data loss.</p>
  <div class="ui-g-12"></div>

</p-dialog>