import { Dropdown, FileOwnerInfoView, FileOwnerTask } from "../../interfaces/global.interfaces";
import { UserTaskApiService } from "../../services/api/user-tasks/user-tasks-api.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LogBase } from "./../../services/logger.service";
import { ConfirmationService } from "primeng";
import { ChangeDetectorRef } from '@angular/core';
import { MessageService } from "primeng/api";
import { ApiService } from "../../services/api.service";
import { Title } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { UserTask, UserTaskSummary, VoyageUserTask } from "../../interfaces/user-tasks/user-tasks.interfaces";
import * as _ from 'lodash';
import { UserTaskStatus } from "../../enums";
import { FileApiService } from "../../services/api/file-api.service";

@Component({
  selector: "app-task-list",
  templateUrl: "./task-list.component.html",
  styleUrls: ["./task-list.component.scss"],
  providers: [ConfirmationService, DatePipe],
})
export class TaskListComponent implements OnInit {

  voyageUserTaskSummary: UserTaskSummary[];

  voyages: Dropdown[];
  selectedVoyage: number;

  selectedTaskGroup: FileOwnerTask[];
  selectedTaskDetails: FileOwnerInfoView;
  selectedTask: UserTask;

  userTaskSummary: UserTaskSummary[];
  userTasks: any;//has to be type any because of dynamic
  userTaskSearch: string = '';

  showSpinner: boolean = true;
  showCompleteTasks = false;
  waitForUserTasks: boolean = false;
  waitForVoyageUserTasks: boolean = false;

  constructor(
    private router: Router,
    private log: LogBase,
    private messageService: MessageService,
    private fileApiService: FileApiService,
    private api: ApiService,
    private titleService: Title,
    private userTaskApiService: UserTaskApiService,
    private cd: ChangeDetectorRef
  ) {
    this.titleService.setTitle("AVECS Task List");
  }

  async ngOnInit() { await this.refreshList(); }

  public async refreshList() {
    this.showSpinner = true;
    await this.getUserTaskSummary();
    await this.refreshFileOwnerDetails();
    await this.getVoyages();
    this.showSpinner = false;
  }

  public async getUserTaskSummary() {
    try {
      const retval = await this.userTaskApiService.GetUserTaskSummary();
      if (!retval) this.toastMessage('error', 'Failed to get your task list. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.userTaskSummary = retval.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  public async onUserTaskSelect(tabIndex: number) {
    this.waitForUserTasks = true;
    try {
      const userTaskTypeId = this.userTaskSummary[tabIndex].userTaskTypeId;
      const response = await this.userTaskApiService.GetUserTasksByUserTaskTypeId(userTaskTypeId);
      if (!response) this.toastMessage('error', 'Failed to get user tasks. Please contact support.', '');
      else if (response.errorCode != 0) this.toastMessage('error', response.errorMessage, '');
      else this.userTaskSummary[tabIndex].userTasks = response.result;
    } catch (err) {
      this.log.error(err);
    }
    this.waitForUserTasks = false;
  }

  public async refreshFileOwnerDetails() {
    if (this.selectedTask && this.selectedTask.genericId) {
      await this.getFileOwnerTasksByFileId(this.selectedTask.genericId);
    }
  }

  public async getVoyages() {
    try {
      const retval = await this.api.getAssignedVoyages();
      if (!retval || retval.length === 0) return;
      this.voyages = retval;
      this.selectedVoyage = this.voyages[0].value;
      if (this.selectedVoyage) await this.getVoyageUserTaskSummary();
    } catch (error) {
      this.toastMessage('error', 'Failed getting voyages. Please contact support', '');
      this.log.error(error);
    }
  }

  public async getVoyageUserTaskSummary() {
    this.showSpinner = true;
    try {
      const res = await this.userTaskApiService.getVoageUserTaskSummary(this.selectedVoyage);
      if (!res) this.toastMessage('error', 'Failed to get user tasks for voyage. Please contact support', '');
      else if (res.errorCode !== 0) this.toastMessage('error', res.errorMessage, '');
      else this.voyageUserTaskSummary = res.result;
    } catch (error) {
      this.toastMessage('error', 'Failed getting voyage user task summary. Please contact support', '');
      this.log.error(error);
    }
    this.showSpinner = false;
  }

  public async getVoyageUserTasks(tabIndex: number) {
    this.waitForVoyageUserTasks = true;
    try {
      const voyageUserTaskSummary = this.voyageUserTaskSummary[tabIndex];
      const res = await this.userTaskApiService.GetUserTasksByVoyageIdAndUserTaskTypeId(this.selectedVoyage, voyageUserTaskSummary.userTaskTypeId);
      if (!res) this.toastMessage('error', 'Failed to get user tasks by voyage. Please contact support.', '');
      else if (res.errorCode !== 0) this.toastMessage('error', res.errorMessage, '');
      else voyageUserTaskSummary.userTasks = res.result;
    } catch (error) {
      this.toastMessage('error', 'Failed getting voyage user tasks. Please contact support', '');
      this.log.error(error);
    }
    this.waitForVoyageUserTasks = false;
  }

  public async showCompleteTasksPopup(task: UserTask) {
    this.selectedTask = task;
    await this.getFileOwnerTasksByFileId(this.selectedTask.genericId);
    this.showCompleteTasks = true;
  }

  public async getFileOwnerTasksByFileId(fileId: number) {
    this.showSpinner = true;
    try {
      const resp = await this.fileApiService.getFileOwnerTasksByFileId(fileId);
      if (!resp) this.toastMessage('error', 'Failed to get file owner tasks. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.selectedTaskGroup = resp.result.tasks;
        this.selectedTaskDetails = resp.result.details;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed getting file owner user tasks. Please contact support', '');
      this.log.error(error);
    }
    this.showSpinner = false;
  }

  public async completeFileOwnerUserTaskById(userTask: FileOwnerTask) {
    try {
      userTask.showSpinner = true;
      const resp = await this.userTaskApiService.UpdateUserTask(userTask.id, UserTaskStatus.COMPLETED);
      if (resp == null) this.toastMessage('error', `Failed to complete ${userTask.taskName}. Please contact support.`, '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.toastMessage('success', resp.errorMessage, '');
        userTask.completed = 1;
        this.checkOtherFileOwnerTasks(userTask);
      }
      userTask.showSpinner = false;
    } catch (error) {
      this.toastMessage('error', 'Failed to complete file owner user task. Please contact support', '');
      this.log.error(error);
    }
  }

  //if the user has expanded on check sms for example
  //and they say they have completed the sms task and other tasks
  //remove those tasks from the check route/email list
  public checkOtherFileOwnerTasks(task: FileOwnerTask) {
    this.waitForUserTasks = true;

    const userTaskSummary = this.userTaskSummary?.find(uts => {
      if (uts.userTasks) {
        const _userTasks: UserTask[] = uts.userTasks as UserTask[];
        _userTasks.find((ut: UserTask) => ut.genericId === this.selectedTask.genericId);
      }
    });

    //will not be null if they haven't opened up a user task accordian heading
    if (userTaskSummary) {
      // get the index of the user task summary header of the accordian
      const idx = this.userTaskSummary.findIndex(x => x.userTaskName == task.taskName);

      this.userTaskSummary[idx].count--;
      if (this.userTaskSummary[idx].userTasks) {
        const userTaskIdx = this.userTaskSummary[idx].userTasks.findIndex((x: { genericId: number; }) => x.genericId == this.selectedTask.genericId);
        this.userTaskSummary[idx].userTasks.splice(userTaskIdx, 1);
      }
    }

    const voyageUserTaskSummary = this.voyageUserTaskSummary?.find(uts => {
      if (uts.userTasks) {
        const _userTasks: VoyageUserTask[] = uts.userTasks as VoyageUserTask[];
        _userTasks.find((ut: VoyageUserTask) => ut.genericId === this.selectedTask.genericId);
      }
    });

    if (voyageUserTaskSummary) {
      // get the index of the voyage user task summary header of the accordian
      const idx = this.voyageUserTaskSummary.findIndex(x => x.userTaskName == task.taskName);

      this.voyageUserTaskSummary[idx].count--;
      if (this.voyageUserTaskSummary[idx].userTasks) {
        const userTaskIdx = this.voyageUserTaskSummary[idx].userTasks.findIndex((x: { genericId: number; }) => x.genericId == this.selectedTask.genericId);
        this.voyageUserTaskSummary[idx].userTasks.splice(userTaskIdx, 1);
      }
    }

    this.cd.detectChanges();
    this.waitForUserTasks = false;
  }

  public openFile(fileId: string) { window.open(`file-view?query=${fileId}`); }
  public legalEnityRoute(legalEntityId: number, legalEntityTypeId: number) { this.router.navigate([`/manage-legal-entity/${legalEntityId}/${legalEntityTypeId}`]); }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}