<div *ngIf="fileId" class="ui-g card card-w-title">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 file-number-header">
      <h3>Unverified WhatsApp Documents For </h3>
      <file-number [fileNumber]="fileId"></file-number>
    </div>
  </div>

  <div class="ui-g-12">
    <p-table [loading]="showSpinner" #dtL [value]="documents" class="ui-g-12" dataKey="documentId" [paginator]="true"
      [rows]="50" [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['fileName','documentCategory']"
      [resizableColumns]="true" [autoLayout]="true">
      <ng-template pTemplate="caption">
        <div class="p-d-flex custom-note-buttons">
          <span class="p-input-icon-left create-item">
            <button pRipple class=" p-button-info ui-button-success " pButton type="button" pTooltip="Refresh Documents"
              [icon]="loadIcon" (click)="refreshDocuments()"></button>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search" style="color: white;"></i>
            <input pInputText type="text" (input)="dtL.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Document Name</th>
          <th>Document Category</th>
          <th>Uploaded Date</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr [pEditableRow]="document">
          <td>{{document.fileName}}</td>
          <td>{{document.documentCategory}}</td>
          <td>{{formatDate(document.uploadedDate)}}</td>
          <td class="actions">
            <!-- View Document -->
            <button [disabled]="document.spinner" #viewFile pRipple class="p-button-info" *ngIf="document.fileName.includes('.pdf') || document.fileName.includes('svg') ||
              document.fileName.includes('jpg') || document.fileName.includes('jpeg') ||
              document.fileName.includes('png')" pButton (click)="displayImageViewerDialogue(document)" type="button"
              icon="fas fa-book" (onHide)="closeViewer()" pTooltip="View Document" tooltipPosition="left"></button>
            <!-- Download Document -->
            <button [disabled]="document.spinner" pRipple class="p-button-info" type="button"
              (click)="documentApiService.downloadDocument(document.documentId, document.fileName)" pButton icon="fas
              fa-file-download" pTooltip="Download Document" tooltipPosition="left"></button>
            <!-- Update Document Category -->
            <button [disabled]="document.spinner" *ngIf="document.documentCategoryId == 56" pRipple
              class="p-button-info" type="button" (click)="showWhatsKindOfDocumentDialog(document)" pButton icon="fas fa-light
              fa-file" pTooltip="Update document category" tooltipPosition="left"></button>
            <!-- Approve Document -->
            <button #approveDoc [disabled]="document.documentCategoryId == 56 || document.spinner" pRipple
              class="p-button-info" pButton type="button" (click)="approveDocument(document)" icon="ui-icon-check"
              [pTooltip]="approveDoc.disabled ? 'Update document category' : 'Approve Document'"
              tooltipPosition="left"></button>
            <!-- Reject Document -->
            <button [disabled]="document.spinner" pRipple class="p-button-info" pButton type="button"
              (click)="rejectDocument(document)" icon="ui-icon-clear" pTooltip="Reject Document"
              tooltipPosition="left"></button>
            <!-- Spinner -->
            <button *ngIf="document.spinner" disabled pRipple class=" p-button-info" pButton type="button"
              icon="fas fa-sync fa-spin"></button>
          </td>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>No documents found.</td>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Preview {{ selectedDocument?.fileName }}" [dismissableMask]="true" [positionTop]="60"
  [(visible)]="documentViewer" [contentStyle]="{width: '800px', height: '550px'}" [responsive]="true" [draggable]="true"
  (onHide)="images = []; documentViewer = false;">
  <app-image-viewer class="imgView" *ngIf="images.length > 0" [zoomInButton]="false" [showPDFOnlyOption]="false"
    [showOptions]="false" [fullscreen]="false" [zoomOutButton]="false" [rotate]="false" [images]="images"
    [idContainer]="'idOnHTML'" [loadOnInit]="true">
  </app-image-viewer>
</p-dialog>

<p-dialog [(visible)]="showDocumentCategoryDialog" [draggable]="true" [resizable]="false" [modal]="true"
  (onHide)="!documentCategorySpinner ? showDocumentCategoryDialog = false : null" [responsive]="true"
  [style]="{'width': '35vw', 'height': '30vh'}" [positionTop]="60" [dismissableMask]="true">
  <p-header>
    Update Document Category
  </p-header>

  <div class="ui-g-12 dialog">
    <span class="ui-g-12 ui-float-label">
      <input style="width: 100%" type="text" pInputText [value]="selectedDocument?.fileName" disabled="true" />
      <label class="float-label" for="float-input">Document Name</label>
    </span>

    <span class="ui-g-12 ui-float-label">
      <p-dropdown [options]="documentCategories" [(ngModel)]="selectedDocumentCategory" optionLabel="label"
        [style]="{'width':'100%'}" [filter]="true" [showClear]="true" appendTo="body" [autoDisplayFirst]="false">
      </p-dropdown>
      <label class="float-label" for="float-input">Document Category *</label>
    </span>

    <button *ngIf="!documentCategorySpinner" pRipple pButton label="Update Document Category"
      (click)="updateDocumentCategory()" [disabled]="!selectedDocumentCategory"></button>
    <button *ngIf="documentCategorySpinner" [disabled]="true" pRipple class="p-button-info" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</p-dialog>