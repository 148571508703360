import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { LogBase } from '../logger.service';
import { WebApi2Service } from '../web.api2.service';
import { OnDemandType } from '../../enums';
import { HttpResponse } from '../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class OnDemandApiService {

  private serverUrl = `${this.config.getServerUrl()}api/ondemand/`;

  constructor(
    private webApi: WebApi2Service,
    private log: LogBase,
    private config: ConfigService
  ) { }

  public async getOnDemandTemplates(onDemandTypeId: OnDemandType) {
    try {
      const url = `${this.serverUrl}getOnDemandTemplates?onDemandTypeId=${onDemandTypeId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async getCriteria(onDemandTemplateId) {
    try {
      const url = `${this.serverUrl}getCriteriaForOnDemandTemplate?onDemandTemplateId=${onDemandTemplateId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async getOnDemandWithPayload(payload, endpoint): Promise<HttpResponse | null> {
    try {
      // dynamic endpoint
      let url = this.config.getServerUrl() + "api/" + endpoint;
      // dynamic parameters
      let keys = Object.keys(payload);
      // start params
      if (keys.length > 0) { url += "?"; }
      // add each
      keys.forEach(key => { url += (key + "=" + payload[key] + "&"); });
      // remove last extra "&" if there are keys
      if (keys.length > 0) { url = url.substring(0, url.length - 1); };
      // now do normal Http Get
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async getDropdownParamsForOnDemandCriteria(onDemandCriteriaId: number) {
    try {
      const url = `${this.serverUrl}getDropdownParamsForCriteria?onDemandCriteriaId=${onDemandCriteriaId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
}