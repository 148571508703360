<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Route Status Updater</h1>
  </div>

  <div class="ui-g-12">
    <app-filter-search [ruleSetId]="0"></app-filter-search>
  </div>

  <div class="ui-g-12 height-5em">
    <button pRipple pButton [disabled]="!filterDataService.selectedFiles || filterDataService.selectedFiles.length == 0"
      label="Update File(s) Route Status" (click)="getMatchingRouteLegsFromMasterRouteIds()"
      class="width-100 margin-top-1em height-100"></button>
  </div>
</div>

<p-dialog [(visible)]="showDialog" [modal]="true" [draggable]="true" [style]="{width: '480px', height: '260px'}">
  <p-header>
    Update File(s) Route Status
  </p-header>

  <div class="overlay" *ngIf="waitingResults || submittingRequest"> </div>
  <div class="progress-spinner" *ngIf="waitingResults">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
    </p-progressSpinner>
  </div>

  <div class="ui-g-12" *ngIf="routeLegs && routeLegs.length > 0">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="routeLegs" [filter]="true" [(ngModel)]="selectedRouteLeg" optionLabel="route"
          appendTo="body" [style]="{'width': '100%'}" [autoDisplayFirst]="false" [showClear]="true">
        </p-dropdown>
        <label class="pad-left" for="float-input">Route Leg *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-calendar [(ngModel)]="UpdateDate" [showTime]="true" appendTo="body">
        </p-calendar>
        <label class="pad-left" for="float-input">Date *</label>
      </span>
    </div>
  </div>

  <div class="ui-g-12" *ngIf="routeLegs && routeLegs.length > 0 && !submittingRequest">
    <button pRipple pButton [disabled]="!selectedRouteLeg" label="Update Route Status"
      (click)="updateFilesRouteStatus()" class="width-100 height-100"></button>
  </div>

  <div class="ui-g-12" *ngIf="!waitingResults && (!routeLegs || routeLegs.length == 0)">
    <label>There are no route legs that match all the files selected.</label>
  </div>

  <div class="progress-spinner" *ngIf="submittingRequest">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
    </p-progressSpinner>
  </div>
</p-dialog>