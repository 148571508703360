import { Component, OnInit } from '@angular/core';
import { InvoiceApiService } from '../../../../services/api/finance/invoice-api.service';
import { Invoice, InvoiceRequest } from '../../../../interfaces/finance-interfaces';
import { LogBase } from '../../../../services/logger.service';
import { MessageService } from 'primeng';
import * as _ from 'lodash';
import { Router } from '@angular/router';
@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html'
})
export class InvoiceEditComponent implements OnInit {

  fileId: number = 0;
  invoiceId: number = 0;
  actionSpinner: boolean = false;
  isAdhoc: boolean;

  constructor(
    private router: Router,
    private invoiceApi: InvoiceApiService,
    private log: LogBase,
    private messageService: MessageService,
  ) { }

  async ngOnInit() {
    /*
      0 : ""
      1 : "invoice"
      2 : "edit"
      3: "0" - 0 = non adhoc, 1 = adhoc
      4 : "invoice Id"
    */
    const params = this.router.url.split('/');
    this.invoiceId = +params[4];
    this.isAdhoc = +params[3] == 1;
  }

  editInvoice = async (payload: any) => {
    const emailInvoicee = payload.emailInvoicee;
    const invoice: Invoice = payload.invoice;
    this.fileId = payload.fileId;
    this.actionSpinner = true;
    try {
      const payload: InvoiceRequest = { invoice: invoice, fileId: this.fileId };
      const retval = await this.invoiceApi.EditInvoice(payload, emailInvoicee);
      if (!retval) this.toastMessage('error', 'Failed to edit invoice. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
      }
    }
    catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to edit invoice. Please contact support.', '');
    }
    this.actionSpinner = false;
  };

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}