import { LogBase } from './../../services/logger.service';
import { MonthlyStats } from '../../interfaces/global.interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Api2Service } from '../../services/api2.service';
import { MessageService, UIChart } from 'primeng';
import * as moment from 'moment';

@Component({
  selector: 'monthly-car-movement',
  templateUrl: './monthly-car-movement.component.html',
  styleUrls: ['./monthly-car-movement.component.css'],
  providers: []
})
export class MonthlyCarMovementComponent implements OnInit {

  @ViewChild("chart") chart: UIChart;

  StartDate: any;
  EndDate: any;
  showSpinner: boolean = false;
  data: any = {
    labels: [],
    datasets: [
      {
        label: 'CL',
        backgroundColor: '#60c9f9',
        borderColor: '#000000',
        fill: false,
        data: []
      },
      {
        label: 'RE',
        backgroundColor: '#ff717f ',
        borderColor: '#000000',
        fill: false,
        data: []
      },
      {
        label: 'PH',
        backgroundColor: 'rgba(242, 172, 84)',
        borderColor: '',
        fill: false,
        data: []
      }
    ]
  };
  monthlyStats: MonthlyStats[] = [];

  constructor(
    private log: LogBase,
    private api: Api2Service,
    private messageService: MessageService,
  ) { }

  async ngOnInit() {
    this.StartDate = moment().startOf('year');
    this.EndDate = moment().endOf('year');
    await this.getMonthlyCarMovementStats();
  }

  async getMonthlyCarMovementStats() {
    this.showSpinner = true;
    this.data.datasets[0].data = [];
    this.data.datasets[1].data = [];
    this.data.datasets[2].data = [];
    this.monthlyStats = [];
    this.data.labels = [];
    try {
      this.StartDate = moment(this.StartDate).format('yy/MM/DD'); //ensure DD/MM to get number not day(Tuesday)
      this.EndDate = moment(this.EndDate).endOf('month').format('yy/MM/DD');
      const stats = await this.api.getMonthlyCarMovementStats(this.StartDate, this.EndDate);
      if (!stats) {
        this.toastMessage('error', 'Failed to get monthly stats of car movement files', '');
        this.showSpinner = false;
        return;
      }

      this.monthlyStats = stats;
      //CL Data
      for (let i = 0; i < this.monthlyStats[0].files.length; i++) {
        this.data.datasets[0].data.push(this.monthlyStats[0].files[i].amount);
      }
      //RE Data
      for (let i = 0; i < this.monthlyStats[1].files.length; i++) {
        this.data.datasets[1].data.push(this.monthlyStats[1].files[i].amount);
        this.data.labels.push(this.monthlyStats[0].files[i].monthName);
      }
      //PH Data
      for (let i = 0; i < this.monthlyStats[2].files.length; i++) {
        this.data.datasets[2].data.push(this.monthlyStats[2].files[i].amount);
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get monthly stats of car movement files', '');
      this.log.error(error);
    }
    if (this.chart) { //if chart is undefined, dont refresh 
      this.chart.refresh();
    }
    this.showSpinner = false;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}