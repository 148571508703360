import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';
import { Invoice, InvoiceRequest } from '../../../interfaces/finance-interfaces';
import { InvoiceApiService } from '../../../services/api/finance/invoice-api.service';
import { LogBase } from '../../../services/logger.service';

@Component({
  selector: 'app-storage-invoice',
  templateUrl: './storage-invoice.component.html'
})
export class StorageInvoiceComponent implements OnInit {

  //component
  fileId: number = 0;
  postSpinner: boolean = false;

  constructor(
    private router: Router,
    private invoiceApi: InvoiceApiService,
    private log: LogBase,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    /*
      0 : "" 
      1: "storage"
      2 : "invoice"
      3 : "123126"
    */
    let params = this.router.url.split('/');
    this.fileId = +params[3];
  }

  //payload any will have the object of Invoice and the property of emailInvoicee
  async postInvoice(payload: {emailInvoicee: boolean, invoice: Invoice}) {
    const emailInvoicee = payload.emailInvoicee;
    const invoice: Invoice = payload.invoice;
    this.postSpinner = true;
    try {
      const payload: InvoiceRequest = { invoice, fileId: this.fileId };
      const retval = await this.invoiceApi.CreateInvoice(payload, emailInvoicee);
      if (!retval) this.toastMessage('error', `Failed to generate invoice. Please contact support.`, '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
      }
    } catch (err) {
      this.log.error(err);
    }
    this.postSpinner = false;
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}