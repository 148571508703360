import { NoteItem } from '../../interfaces/global.interfaces';
import { Injectable } from '@angular/core';
import { HttpResponse } from '../../interfaces/result-interfaces';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";

@Injectable({
  providedIn: 'root'
})
export class FileApiService {

  private serverUrl = `${this.config.getServerUrl()}api/files/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/files/getOutstandingBalanceForFile
  // Controller Name: FilesController.cs
  // Proc Name: pr_GetOutstandingBalanceForFile
  async getOutstandingBalanceForFile(fileId: number): Promise<HttpResponse | null> {
    try {
      var url = `${this.serverUrl}getOutstandingBalanceForFile?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/getFileDataByLegalEntityId
  // Controller Name: FilesController.cs
  // Proc Name: pr_GetFileDataByLegalEntityId
  async getFileDataByLegalEntityId(contactId: any) {
    try {
      const url = `${this.serverUrl}getFileDataByLegalEntityId?LegalEntityId=${contactId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/addFilePermission
  // Controller Name: FilesController.cs
  // Proc Name: Multiple procs called
  async addFilePermission(LegalEntityID: any, FileId: any, GroupId: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}addFilePermission?LegalEntityID=${LegalEntityID}&FileId=${FileId}&GroupId=${GroupId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/getDBNNumber
  // Controller Name: FilesController.cs
  // Proc Name: pr_GetDBNNumber
  public async getDBNNumber(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getDBNNumber?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/getFileEmails
  // Controller Name: FilesController.cs
  // Proc Name: Multiple procs called
  public async getEmails(id: number) {
    try {
      var url = `${this.serverUrl}getFileEmails?FileId=${id}`;
      const emails = await this.webApi.post(null, url, null);
      if (emails.errorCode == 0) {
        return emails.result;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/getNotesByFileId
  // Controller Name: FilesController.cs
  // Proc Name: pr_GetNotesByFileId
  async getNotesByFileId(fileId: any): Promise<NoteItem[] | null> {
    try {
      const url = `${this.serverUrl}getNotesByFileId?FileId=${fileId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/searchForFiles
  // Controller Name: FilesController.cs
  // Proc Name: pr_searchForFiles
  public async searchForFiles(search: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}searchForFiles?search=${search}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/TermsAndConditions
  // Controller Name: FilesController.cs
  // Proc Name: pr_WAGetTermsAndConditions
  public async GetTermsAndConditions(FileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}termsAndConditions?FileId=${FileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/files/acceptTerms
  // Controller Name: FilesController.cs
  // Proc Name: Multiple procs called
  public async acceptTerms(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}acceptTerms?fileId=${fileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  // Endpoint: api/files/declineTerms
  // Controller Name: FilesController.cs
  // Proc Name: pr_DeclineTermsAndConditions
  public async declineTerms(FileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}declineTerms?FileId=${FileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async updateFileInformation(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateFileInformation`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async getFileOwnerTasksByFileId(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetFileOwnerTasksByFileId?FileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async updateCargoItem(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateCargoItem`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}