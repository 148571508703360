import { FileApiService } from './../../../services/api/file-api.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from './../../../services/api.service';
import { LogBase } from '../../../services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../../services/config.service';
import { Title } from "@angular/platform-browser";
import * as _ from 'lodash';
import { Dropdown, LegalEntityFiles } from '../../../interfaces/global.interfaces';
import { Groups, LegalEntityRolesTypes, LegalEntityType } from '../../../enums';
import { LegalEntityApiService } from '../../../services/api/legal-entity-api.service';
import { RegexValidators } from '../../../regex-validators';
import { CompanyStaffMembers, ContactPerson, CountryCodes, LegalEntity, LegalEntityCompanyTypes, LegalEntityRoles, UserGroups } from '../../../interfaces/legal-entity-interfaces';
import { PortalService } from '../../../services/api/portal.service';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: 'app-manage-legal-entity',
  templateUrl: './manage-legal-entity.component.html',
  styleUrls: ['./manage-legal-entity.component.scss']
})

export class ManageLegalEntityComponent implements OnInit {

  //add / edit person/company 
  contactDetailsForm: FormGroup;
  get getRawFormData(): any { return this.contactDetailsForm.getRawValue(); }

  legalEntityDetails: LegalEntity = null;

  companyTypes: LegalEntityCompanyTypes[] = [];
  titles: Dropdown[] = [];
  countries: Dropdown[] = [];
  legalEntityRoles: LegalEntityRoles[] = [];
  selectedAddStaffMember: ContactPerson = null;
  newCompanyName: string;
  oldCellPhone1: string;
  oldCellPhone2: string;
  cellPhoneMask: string = '*******?*********';
  cellPhone2Mask: string;

  editStaffMemberSpinner: boolean = false;
  removeStaffMemberSpinner: boolean = false;
  addStaffMemberDialog: boolean = false;
  grantPortalUserAccess: boolean = false;
  sameAsPhysical: boolean = false;
  submitted: boolean = false;
  showSpinner: boolean = true;
  showSaveSpinner: boolean = false;
  staffMemberDialog: boolean = false;
  convertCompanySpinner: boolean = false;
  displayConvertCompanyDialog: boolean = false;
  //add / edit person/company

  //staff member details
  selectedRole: LegalEntityRoles;
  people: any;
  selectedPerson: string;

  companyStaffMembers: CompanyStaffMembers[] = [];
  selectedStaffMember: CompanyStaffMembers;
  countryCodes: CountryCodes[];
  selectedCellPhoneDialingCode: CountryCodes;
  //staff member details

  //files
  files: LegalEntityFiles[] = [];
  //files

  //permission access 
  passwordForm: FormGroup = null;
  username: string;

  groups: Dropdown[] = [
    { label: "Transport Manager", value: Groups.TRANSPORTER },
    { label: "Driver", value: Groups.DRIVER },
    { label: "Consignee", value: Groups.CONSIGNEE },
    { label: "Claims", value: Groups.CLAIMS },
    { label: "Agency/Dealer", value: Groups.AGENCY },
    { label: "Supplier", value: Groups.SUPPLIER }
  ];
  addPermissionPayload: any = {
    fileId: null,
    groupId: null,
    active: 0,
    sendWelcomeEmail: false,
    notifyContactAboutFile: false
  };
  otherPermissions: UserGroups[] = [];
  permissions: UserGroups[] = [];
  filePermissions: any;
  selectedPermission: any = {};
  editPermission: boolean = false;
  addPermission: boolean = false;
  confirmDeletePermission: boolean = false;
  hasActiveUserAccount: boolean = false;
  systemAccessSpinner: boolean = false;
  permissionsSpinner: boolean = false;
  disableUserName: boolean = false;
  //permission access

  //component
  legalEntityTypeId: number;
  contactId: number;

  hasPortalLogin: boolean = false;
  contactForm: boolean = false;
  hideDetails: boolean = false;
  systemAdmin: boolean = false;
  portalAdmin: boolean = false;
  userAdmin: boolean = false;
  //component

  constructor(
    private api: ApiService,
    private legalEntityApi: LegalEntityApiService,
    private fileApi: FileApiService,
    private fb: FormBuilder,
    private log: LogBase,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private config: ConfigService,
    private titleService: Title,
    private portal: PortalService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Manage Legal Entity");
  }

  public get legalEntityType(): typeof LegalEntityType { return LegalEntityType; }

  async ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      this.contactForm = false;
      this.contactId = +params.get("id");
      this.legalEntityTypeId = +params.get("legalEntityTypeId");
      await this.setUpPage();
      this.contactForm = true;
    });
  }

  async setUpPage() {
    try {
      await this.loggedInUserPermissions();
      await this.getTitles();
      await this.getLegalEntityRoles();
      await this.getCompanyTypes();
      await this.getCountries();
      await this.setupScreen();
      this.companyStaffMembers = await this.legalEntityApi.getCompanyStaff(this.contactId);
      this.hasPortalLogin = await this.portal.hasPortalLogin(this.contactId);
      this.showSpinner = false;
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
  }

  async setupScreen() {
    if (this.contactId) {
      this.legalEntityDetails = await this.legalEntityApi.getLegalEntityById(this.contactId);
      if (this.legalEntityTypeId == this.legalEntityType.PERSON) {
        await this.setUpPermissionsForm();
        await this.getFilesForContact();
        await this.getPermissionAccess();
        await this.loadUsernameIfExists();
        await this.checkActiveUserAccount();
        this.otherPermissions = await this.legalEntityApi.getStaffMemberUnassignedPermissions(this.contactId);
        this.permissions = await this.legalEntityApi.getStaffMemberPermissions(this.contactId);
        this.titleService.setTitle("AVECS Edit Person");
      }
      else if (this.legalEntityTypeId == this.legalEntityType.COMPANY) {
        this.titleService.setTitle("AVECS Edit Company");
        //avecs
        if (this.contactId == 1) this.hideDetails = true;
      }
    }
    else if (!this.contactId && this.legalEntityTypeId == this.legalEntityType.PERSON) this.titleService.setTitle("AVECS Add Person");
    else if (!this.contactId && this.legalEntityTypeId == this.legalEntityType.COMPANY) this.titleService.setTitle("AVECS Add Company");
    await this.setUpDetailsForm();
  }

  async loggedInUserPermissions() {
    this.systemAdmin = this.authService.canEdit(Groups.SYSTEM_ADMIN);
    this.portalAdmin = this.authService.canEdit(Groups.PORTAL_ADMIN);
    if (this.portalAdmin || this.systemAdmin) this.userAdmin = true;
  }

  async getCountries() {
    try {
      this.countries = await this.api.getCountries();
    } catch (error) {
      this.showError('Error: Failed to get country list', error);
      this.log.error(error);
    }
  }

  async getCompanyTypes() {
    try {
      this.companyTypes = await this.legalEntityApi.getCompanyTypes();
    }
    catch (error) {
      this.showError('Error Getting Company Types (getCompanyTypes())', error);
      this.log.error(error);
    }
  }

  async getLegalEntityRoles() {
    try {
      let retval = await this.legalEntityApi.GetLegalEntityRoles();
      if (retval) {
        this.legalEntityRoles = retval;
      }
      else {
        this.toastMessage('error', 'Failed to get legal entity roles. Please contact support', '');
      }
    }
    catch (error) {
      this.showError('Error Getting Person Types (getPersonTypes())', error);
      this.log.error(error);
    }
  }

  async getTitles() {
    try {
      this.titles = await this.api.getTitles();
    } catch (error) {
      this.showError('Error Getting Titles (getTitles())', error);
      this.log.error(error);
    }
  }

  async getFilesForContact() {
    try {
      this.files = await this.fileApi.getFileDataByLegalEntityId(this.contactId);
    } catch (error) {
      this.showError("Error Getting Files For Contact (getFilesForContact())", error);
      this.log.error(error);
    }
  }

  async getPermissionAccess() {
    try {
      this.filePermissions = await this.api.getUserFilePermissions(this.contactId);
    } catch (error) {
      this.showError("Error Getting permission access For Contact (getPermissionAccess())", error);
      this.log.error(error);
    }
  }

  async onSubmit(form: any) {
    this.showSaveSpinner = true;
    this.submitted = true;
    this.contactDetailsForm.disable();
    try {
      form.legalEntityTypeId = this.legalEntityTypeId;
      form.cellPhoneDialingCode = form.cellPhoneDialingCode?.code;
      form.cellPhone2DialingCode = form.cellPhone2DialingCode?.code;
      if (form.cellPhone) form.cellPhone = form.cellPhoneDialingCode + form.cellPhone;
      if (form.cellPhone2) form.cellPhone2 = form.cellPhone2DialingCode + form.cellPhone2;
      const res = await this.legalEntityApi.saveOrUpdateLegalEntity(form);
      if (!res) {
        this.toastMessage('error', 'Failed to create legal entity. Please contact support.', '');
      }
      else {
        if (res.errorCode != 0) {
          this.toastMessage('error', res.errorMessage, '');
        }
        else {
          this.toastMessage('success', res.errorMessage, '');
          await this.setupScreen();
        }
      }
    } catch (error) {
      this.showError('Error On Submiting (onSubmit())', error);
      this.log.error(error);
    }

    setTimeout(() => {
      this.contactDetailsForm.enable();
      this.disableCellPhoneFields('cellPhone', 'cellPhoneDialingCode');
      this.disableCellPhoneFields('cellPhone2', 'cellPhone2DialingCode');
    }, 100);

    this.showSaveSpinner = false;
  }

  async setUpDetailsForm() {
    try {
      await this.getCountryCodes('');
      //setting the cell phone dialing codes to be selected, if is a dialing code selected for the legal entity
      const cellPhoneDialingCode = this.countryCodes.find(x => x.code == this.legalEntityDetails?.cellphoneDialingCode);
      const cellPhone2DialingCode = this.countryCodes.find(x => x.code == this.legalEntityDetails?.cellphone2DialingCode);

      //checking if there is a dialing code selected for the legal entity
      //if there is we are removing the dialing code part from the number 
      if (cellPhoneDialingCode && this.legalEntityDetails && this.legalEntityDetails.cellphone) {
        this.legalEntityDetails.cellphone = this.legalEntityDetails.cellphone.substring(cellPhoneDialingCode.code.length);
      }

      if (cellPhone2DialingCode && this.legalEntityDetails && this.legalEntityDetails.cellphone2) {
        this.legalEntityDetails.cellphone2 = this.legalEntityDetails.cellphone2.substring(cellPhone2DialingCode.code.length);
      }

      this.contactDetailsForm = this.fb.group({
        id: new FormControl(this.legalEntityDetails?.id || 0),
        legalEntityTypeId: new FormControl(this.legalEntityTypeId),
        name: new FormControl(this.legalEntityDetails?.name || '', Validators.required),
        address1: new FormControl(this.legalEntityDetails?.address1 || '', Validators.required),
        address2: new FormControl(this.legalEntityDetails?.address2 || '', Validators.required),
        address3: new FormControl(this.legalEntityDetails?.address3 || ''),
        postalAddress1: new FormControl(this.legalEntityDetails?.postalAddress1 || ''),
        postalAddress2: new FormControl(this.legalEntityDetails?.postalAddress2 || ''),
        postalAddress3: new FormControl(this.legalEntityDetails?.postalAddress3 || ''),
        postalCode: new FormControl(this.legalEntityDetails?.postalCode || ''),
        cellPhone2: new FormControl(this.legalEntityDetails?.cellphone2 || '', Validators.pattern(RegexValidators.PHONE_NUMBER)),
        cellPhone2DialingCode: new FormControl(cellPhone2DialingCode),
        city: new FormControl(this.legalEntityDetails?.city || '', Validators.required),
        countryId: new FormControl(this.legalEntityDetails?.countryId, Validators.required),
        telephone1: new FormControl(this.legalEntityDetails?.telephone1 || ''),
        telephone2: new FormControl(this.legalEntityDetails?.telephone2 || ''),
        email: new FormControl(this.legalEntityDetails?.email || '', [Validators.required, Validators.pattern(RegexValidators.EMAIL)]),
        email2: new FormControl(this.legalEntityDetails?.email2 || '', Validators.pattern(RegexValidators.EMAIL)),
        vip: new FormControl(this.legalEntityDetails?.vip || false),
        sameAsPhysical: new FormControl(this.sameAsPhysical)
      });

      this.oldCellPhone1 = this.legalEntityDetails?.cellphone;
      this.oldCellPhone2 = this.legalEntityDetails?.cellphone2;

      if (this.legalEntityTypeId == this.legalEntityType.PERSON) {
        this.contactDetailsForm.addControl('cellPhone', new FormControl(this.legalEntityDetails?.cellphone, [Validators.required, Validators.pattern(RegexValidators.PHONE_NUMBER)]));
        this.contactDetailsForm.addControl('cellPhoneDialingCode', new FormControl(cellPhoneDialingCode, Validators.required));
        this.contactDetailsForm.addControl('lastName', new FormControl(this.legalEntityDetails?.lastName, Validators.required));
        this.contactDetailsForm.addControl('middleNames', new FormControl(this.legalEntityDetails?.middleNames));
        this.contactDetailsForm.addControl('titleId', new FormControl(this.legalEntityDetails?.titleId || null, Validators.required));
      }
      else if (this.legalEntityTypeId == this.legalEntityType.COMPANY) {
        this.contactDetailsForm.addControl('cellPhone', new FormControl(this.legalEntityDetails?.cellphone, Validators.pattern(RegexValidators.PHONE_NUMBER)));
        this.contactDetailsForm.addControl('cellPhoneDialingCode', new FormControl(cellPhoneDialingCode));
        this.contactDetailsForm.addControl('vatNumber', new FormControl(this.legalEntityDetails?.vatNumber));
        this.contactDetailsForm.addControl('customsCode', new FormControl(this.legalEntityDetails?.customsCode));
        this.contactDetailsForm.addControl('companyTypes', new FormControl(this.legalEntityDetails?.companyTypes, Validators.required));
        this.contactDetailsForm.addControl('registrationNumber', new FormControl(this.legalEntityDetails?.registrationNumber));
      }
      else throw new Error("legalEntityType not implemented");

      const formControlVaraibles = this.getRawFormData;

      if (!formControlVaraibles.cellPhoneDialingCode) {
        this.contactDetailsForm.controls['cellPhone'].disable();
      }

      if (!formControlVaraibles.cellPhone2DialingCode) {
        this.contactDetailsForm.controls['cellPhone2'].disable();
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async setUpPermissionsForm() {
    this.passwordForm = this.fb.group({
      password: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.required),
      systemAccess: new FormControl(false),
      username: new FormControl(this.username || null, Validators.required),
    }, { validator: PasswordValidation.MatchPassword }
    );
  }

  setPasswordMatchToolTip() {
    if (this.passwordForm.controls.confirmPassword.errors?.MatchPassword) {
      return "Passwords Don't Match";
    }
    return 'Passwords Match';
  }

  async searchPerson(event: any) {
    try {
      var res = await this.api.searchPerson(event.query);
      if (res.errorCode == 0) {
        this.people = res.result;
      } else this.showError("Error Searching Person (searchPerson())", '');
    } catch (error) {
      this.showError("Error Searching Person (searchPerson())", error);
      this.log.error(error);
    }
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: Add Person<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: Add Person';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      }
    });
  }

  editStaffMember(contact: CompanyStaffMembers) {
    this.staffMemberDialog = true;
    this.selectedStaffMember = { ...contact };
  }

  async editFilePermission(permission: any) {
    this.selectedPermission = permission;
    this.editPermission = true;
  }

  async addNewPermission() {
    this.addPermissionPayload = {
      fileId: null,
      groupId: null,
      sendWelcomeEmail: false,
      notifyContactAboutFile: false
    };
    this.addPermission = true;
  }

  async addUserFilePermission() {
    this.permissionsSpinner = true;
    var userExists = true;
    if (!this.hasPortalLogin) {
      userExists = await this.addPortalUser();
    }

    if (userExists) {
      var retval = await this.fileApi.addFilePermission(this.contactId, this.addPermissionPayload.fileId, this.addPermissionPayload.groupId);
      if (retval) {
        if (retval.errorCode == 0) {
          this.messageService.add({ severity: "info", summary: "Success", detail: "User File Permission Added", });
          if (this.hasPortalLogin && this.addPermissionPayload.notifyContactAboutFile) {
            await this.portal.notifyPortalUserAboutFilePermissionChange(this.contactId);
            this.messageService.add({ severity: "info", summary: "Success", detail: "Email sent to User", });
          }
          this.filePermissions = await this.api.getUserFilePermissions(this.contactId);
        }
        else {
          this.messageService.add({ severity: "error", summary: "Error", detail: retval?.errorMessage || 'Failed to add file permission. Please contact support.', });
        }
      } else {
        this.messageService.add({ severity: "error", summary: "Error", detail: retval?.errorMessage || 'Failed to add file permission. Please contact support.', });
      }
    }
    this.permissionsSpinner = false;
    this.addPermission = false;
  }

  async addPortalUser() {
    this.showSpinner = true;
    let retval = false;
    try {
      var portalUserId = await this.portal.checkPortalUserExists(this.contactId);
      if (portalUserId.result == 0) {
        // New user...
        if (this.legalEntityDetails.email) {
          var duplicates = await this.legalEntityApi.checkForDuplicateEmailAddresses(this.contactId);
          if (!duplicates) {
            await this.portal.addPortalUser(this.contactId, this.addPermissionPayload.groupId, this.addPermissionPayload.fileId);
            this.hasPortalLogin = true;
            retval = true;
            this.toastMessage('info', 'Portal User Permissions Updated, and Notification Email sent', '');
          } else {
            this.toastMessage('info', 'Duplicate Email Address Found', 'Please ensure that this Contact has a unique Email address for logging in');
          }
        } else {
          this.toastMessage('error', 'Email address Missing', 'You cannot add a portal user without an email account');
        }
      }
    } catch (error) {
      this.log.error(error);
    }
    this.showSpinner = false;
    return retval;
  }

  async updateUserFilePermission() {
    this.permissionsSpinner = true;
    let retval = await this.api.updateUserFilePermission(this.selectedPermission.UserPermissionId, this.selectedPermission.Active);
    if (retval) {
      this.toastMessage('success', 'Success', 'User File Permission Update');
      this.filePermissions = await this.api.getUserFilePermissions(this.contactId);
    } else {
      this.toastMessage('error', 'Error', 'Failed To Update User File Permission');
    }
    this.editPermission = false;
    this.permissionsSpinner = false;
  }

  async deletePermission() {
    this.permissionsSpinner = true;
    let retval = await this.api.deletePermission(this.selectedPermission.UserPermissionId);
    if (retval) {
      this.toastMessage('success', 'Success', 'User File Permission Deleted');
      this.filePermissions = await this.api.getUserFilePermissions(this.contactId);
    } else {
      this.toastMessage('error', 'Error', 'Failed To Delete User File Permission');
    }
    this.confirmDeletePermission = false;
    this.permissionsSpinner = false;
  }

  async checkActiveUserAccount() {
    try {
      var res = await this.legalEntityApi.hasActiveUserAccount(this.contactId);
      let control = this.passwordForm.get("systemAccess");
      if (res == 1) {
        control.setValue(true);
      } else {
        control.setValue(false);
      }
      this.hasActiveUserAccount = this.passwordForm.value.systemAccess;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCountryCodes(searchCountryCode: string) {
    try {
      const res = await this.legalEntityApi.getCountryCodes(searchCountryCode);
      if (!res) {
        this.toastMessage('error', 'Failed to get country codes. Please contact support.', '');
        return;
      } else if (res.errorCode != 0) {
        this.toastMessage('error', res.errorMessage, '');
        return;
      }
      this.countryCodes = res.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  disableCellPhoneFields(cellPhoneformControlName: string, dialingCodeFormControlName: string): void {
    this.onDialingCodeSelect(cellPhoneformControlName, dialingCodeFormControlName);
  }

  onDialingCodeSelect(cellPhoneformControlName: string, dialingCodeFormControlName: string) {
    if (!this.contactDetailsForm.get(dialingCodeFormControlName).value) {
      this.contactDetailsForm.controls[cellPhoneformControlName].disable();
      return;
    } else {
      this.contactDetailsForm.controls[cellPhoneformControlName].enable();
    }

    if (cellPhoneformControlName == 'cellPhone') {
      this.contactDetailsForm.get(cellPhoneformControlName).setValue(this.oldCellPhone1);
    } else if (cellPhoneformControlName == 'cellPhone2') {
      this.contactDetailsForm.get(cellPhoneformControlName).setValue(this.oldCellPhone2);
    }
  }

  //pls no
  //I tried man 
  //this makes the dialing code dropdown change colour 
  //if there is a cell phone but no dialing code selected
  //this would only happen if the legal entities are old ones where they don't have a dialing code in the db
  setDialingCodeColour(cellPhoneformControlName: string, dialingCodeFormControlName: string): string {
    const rawFormData = this.getRawFormData;

    if (rawFormData[cellPhoneformControlName] && !rawFormData[dialingCodeFormControlName]?.code) {
      return 'linear-gradient(to bottom, #e62a10, #e62a10), linear-gradient(to bottom, #e62a10, #e62a10)';
    } else {
      return 'linear-gradient(to bottom, #3e464c, #3e464c), linear-gradient(to bottom, #bdbdbd, #bdbdbd)';
    }
  }

  getDialingCode(dialingCodeFormControlName: string): string {
    const dialingCode = this.contactDetailsForm.get(dialingCodeFormControlName).value?.code;
    if (!dialingCode) return '';
    else return `(${dialingCode})`;
  }

  canSubmitContactDetailsForm() {
    const formControlVaraibles = this.getRawFormData;
    if (formControlVaraibles.cellPhone2DialingCode?.country && !formControlVaraibles.cellPhone2) {
      return true;
    } else if (formControlVaraibles.cellPhone2 && !formControlVaraibles.cellPhone2DialingCode?.country) {
      return true;
    } else if (formControlVaraibles.cellPhone && !formControlVaraibles.cellPhoneDialingCode?.country) {
      return true;
    } else if (formControlVaraibles.cellPhoneDialingCode && !formControlVaraibles.cellPhoneDialingCode.country && !formControlVaraibles.cellPhone) {
      return true;
    } else if (!this.contactDetailsForm.valid) {
      return true;
    }
    return false;
  }

  clearDialingCodeAndCellPhone(cellPhoneformControlName: string, dialingCodeFormControlName: string) {
    this.contactDetailsForm.get(cellPhoneformControlName).setValue(null);
    this.contactDetailsForm.get(dialingCodeFormControlName).setValue(null);
    this.disableCellPhoneFields(cellPhoneformControlName, dialingCodeFormControlName);
  }

  async loadUsernameIfExists() {
    try {
      this.username = await this.api.getUsernameByLegalEntityId(this.contactId);
      let control = this.passwordForm.get("username");
      if (this.username) {
        this.disableUserName = true;
        control.disable();
        control.setValue(this.username);
      } else {
        this.disableUserName = false;
        control.enable();
      }
    } catch (error) {
      this.showError("Error Loading Username If Exists (loadUsernameIfExists())", error);
      this.log.error(error);
    }
  }

  async saveUser() {
    this.systemAccessSpinner = true;
    this.passwordForm.disable();
    try {
      if (this.disableUserName) {
        let retval = await this.legalEntityApi.updateSystemUser(this.contactId, this.passwordForm.value.password, this.passwordForm.value.systemAccess);
        if (!retval) {
          this.toastMessage('error', 'Failed to update system user. Please contact support.', '');
        } else if (retval.errorCode > 0) {
          this.toastMessage('error', retval.errorMessage, '');
        } else {
          this.toastMessage('success', retval.errorMessage, '');
          this.hasActiveUserAccount = this.passwordForm.value.systemAccess;
        }
      } else {
        var retval = await this.legalEntityApi.saveSystemUser(this.contactId, this.passwordForm.value.password, this.passwordForm.value.systemAccess, this.passwordForm.value.username);
        if (!retval) {
          this.toastMessage('error', 'Failed to save system user. Please contact support.', '');
        } else if (retval.errorCode < 0) {
          this.toastMessage('error', retval.errorMessage, '');
        } else {
          this.toastMessage('success', retval.errorMessage, '');
          this.hasActiveUserAccount = this.passwordForm.value.systemAccess;
        }
      }
    } catch (error) {
      this.log.error(error);
    }
    setTimeout(() => {
      this.passwordForm.enable();
    }, 100);
    await this.loadUsernameIfExists();
    await this.checkActiveUserAccount();
    this.systemAccessSpinner = false;
  }

  async addStaffMember() {
    this.removeStaffMemberSpinner = true;
    try {
      let r = await this.legalEntityApi.addCompanyPerson(this.contactId, this.selectedAddStaffMember.id, this.selectedRole.id);
      if (!r) {
        this.toastMessage('error', 'Failed to add company person. Please contact support', '');
      } else if (r.errorCode != 0) {
        this.toastMessage('error', r.errorMessage, '');
      } else {
        this.toastMessage('success', 'Staff member added.', '');
      }

      if (this.grantPortalUserAccess) {
        if (this.selectedRole.id == LegalEntityRolesTypes.TRANSPORT_DRIVER ||
          this.selectedRole.id == LegalEntityRolesTypes.TRANSPORT_MANAGER ||
          this.selectedRole.id == LegalEntityRolesTypes.SUPPLIER ||
          this.selectedRole.id == LegalEntityRolesTypes.COURIER) {
          var retval = await this.portal.checkPortalUserExists(this.selectedAddStaffMember.id);
          if (retval.result == 0) {
            var portalUserId = await this.portal.addPortalUser(this.selectedAddStaffMember.id, 0, 0);
            if (!portalUserId) {
              this.toastMessage('error', 'Staff member failed to be added. Please contact support.', '');
            }
          } else if (retval.errorCode != 0) {
            this.toastMessage('error', retval.errorMessage, '');
          } else {
            this.toastMessage('info', 'Portal User already exists, nothing to do.', '');
          }
        }
      }
      this.companyStaffMembers = await this.legalEntityApi.getCompanyStaff(this.contactId);
      this.selectedAddStaffMember = null;
      this.selectedRole = null;
    } catch (error) {
      this.toastMessage('error', 'Staff member failed to be added. Please contact support.', '');
      this.log.error(error);
      console.error(error);
    }
    this.removeStaffMemberSpinner = false;
    this.addStaffMemberDialog = false;
  }

  async saveUserPermissions(permissions: UserGroups[]) {
    try {
      for (let perm of permissions) {
        const retval = await this.legalEntityApi.addPermission(this.contactId, perm.id);
        if (!retval) {
          this.toastMessage('error', `Failed to add ${perm.group}. Please contact support.`, '');
        } else if (retval.errorCode != 0) {
          this.toastMessage('error', retval.errorMessage, '');
        } else {
          this.toastMessage('success', retval.errorMessage, '');
        }
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async removeUserPermissions(permissions: UserGroups[]) {
    try {
      for (const perm of permissions) {
        const retval = await this.legalEntityApi.removePermission(this.contactId, perm.id);
        if (!retval) {
          this.toastMessage('error', `Failed to remove ${perm.group}. Please contact support.`, '');
        } else if (retval.errorCode != 0) {
          this.toastMessage('error', retval.errorMessage, '');
        } else {
          this.toastMessage('success', retval.errorMessage, '');
        }
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async removeStaffMember() {
    this.removeStaffMemberSpinner = true;
    try {
      let retval = await this.legalEntityApi.removeStaffMemberFromCompany(this.contactId, this.selectedStaffMember.legalEntityId);
      if (!retval) {
        this.toastMessage('error', 'Failed to remove staff member. Please contact support.', '');
      } else if (retval.errorCode < 0) {
        this.toastMessage('error', retval.errorMessage, '');
      } else {
        //remove staff member
        let idx = this.companyStaffMembers.findIndex(x => x.legalEntityId == this.selectedStaffMember.legalEntityId);
        this.companyStaffMembers.splice(idx, 1);
        this.toastMessage('success', retval.errorMessage, '');
      }
    }
    catch (err) {
      this.toastMessage('error', 'Failed to remove staff member. Please contact support.', '');
      this.log.error(err);
    }
    this.removeStaffMemberSpinner = false;
    this.staffMemberDialog = false;
  }

  async editLegalEntityCompanyRoles() {
    this.editStaffMemberSpinner = true;
    try {
      let retval = await this.legalEntityApi.editLegalEntityCompanyRoles(this.selectedStaffMember.roles, this.contactId, this.selectedStaffMember.legalEntityId);
      if (!retval) {
        this.toastMessage('error', 'Failed to edit staff member. Please contact support.', '');
      } else if (retval.errorCode < 0) {
        this.toastMessage('error', retval.errorMessage, '');
      } else {
        this.companyStaffMembers = await this.legalEntityApi.getCompanyStaff(this.contactId);
        this.toastMessage('success', retval.errorMessage, '');
      }
    }
    catch (err) {
      this.toastMessage('error', 'Failed to edit staff member. Please contact support.', '');
      this.log.error(err);
    }
    this.editStaffMemberSpinner = false;
    this.staffMemberDialog = false;
  }

  checkLegalEntityTypeForHeader() {
    if (this.contactId) {
      if (this.legalEntityTypeId == this.legalEntityType.PERSON) {
        return `Edit Person: ${this.legalEntityDetails.name} ${this.legalEntityDetails.lastName}`;
      } else if (this.legalEntityTypeId == this.legalEntityType.COMPANY) {
        return 'Edit Company: ' + this.legalEntityDetails.name;
      }
    }
    else {
      if (this.legalEntityTypeId == this.legalEntityType.PERSON) {
        return 'Add Person';
      } else if (this.legalEntityTypeId == this.legalEntityType.COMPANY) {
        return 'Add Company';
      }
    }
  }

  setupOptions() {
    if (this.legalEntityTypeId == this.legalEntityType.COMPANY) {
      return [{
        label: 'Login to Website as Company',
        icon: "fas fa-key",
        command: () => {
          this.websiteLogin();
        },
      }
      ];
    }
    else if (this.legalEntityTypeId == this.legalEntityType.PERSON) {
      return [{
        label: 'Login to Website as Client',
        icon: "fas fa-key",
        command: () => {
          this.websiteLogin();
        },
      },
      {
        label: "Convert to Company",
        icon: "fa fa-refresh",
        command: () => {
          this.displayConvertCompanyDialog = true;
        },
      },
      ];
    }
  }

  async ConvertToCompany() {
    this.convertCompanySpinner = true;
    try {
      let retval = await this.legalEntityApi.convertPersonToCompany(this.contactId, this.newCompanyName);

      if (retval) {
        this.toastMessage('info', 'Person Converted to Company', '');
        this.router.navigate(['/manage-legal-entity/', this.contactId, LegalEntityType.COMPANY]);
      } else {
        this.toastMessage('error', 'Failed To Convert Person to Company', '');
      }
    } catch (error) {
      this.showError("Error Converting Person to Company", error);
      this.log.error(error);
    }
    this.displayConvertCompanyDialog = false;
    this.convertCompanySpinner = false;
  }

  setPostalSameAsPhysical() {
    this.sameAsPhysical = !this.sameAsPhysical;
    let postalAddress1 = this.contactDetailsForm.get("postalAddress1");
    let postalAddress2 = this.contactDetailsForm.get("postalAddress2");
    let postalAddress3 = this.contactDetailsForm.get("postalAddress3");

    if (this.sameAsPhysical) {
      postalAddress1.setValue(this.contactDetailsForm.get("address1").value);
      postalAddress1.disable();
      postalAddress2.setValue(this.contactDetailsForm.get("address2").value);
      postalAddress2.disable();
      postalAddress3.setValue(this.contactDetailsForm.get("address3").value);
      postalAddress3.disable();
    } else {
      postalAddress1.setValue(null);
      postalAddress1.enable();
      postalAddress2.setValue(null);
      postalAddress2.enable();
      postalAddress3.setValue(null);
      postalAddress3.enable();
    }
  }

  async websiteLogin() {
    try {
      let URL = await this.api.getWebsiteOTPLink(this.contactId);
      if (URL) {
        window.open(URL, "_blank");
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This Legal Entity does not have a portal login' });
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  systemAccessHeader() { return !this.disableUserName ? 'Add System User Access' : 'Edit System User Acess'; }
  viewContact(id: number) { this.router.navigate(['/manage-legal-entity/', id, LegalEntityType.PERSON]); }
  goToFile(file: LegalEntityFiles) { this.router.navigate(["/file-view"], { queryParams: { query: file.fileId } }); }
  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}

export class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
    let password = AC.get("password").value;
    let confirmPassword = AC.get("confirmPassword").value;
    if (password !== confirmPassword) {
      AC.get("confirmPassword").setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }
}