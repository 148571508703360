import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { Api2Service } from '../../../services/api2.service';
import { LogBase } from '../../../services/logger.service';
import { Files } from '../../../interfaces/filter-search-interfaces';
import { FilterDataService } from '../../filter-search/filter-search-data.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-route-status-updater',
  templateUrl: './route-status-updater.component.html',
  styleUrls: ['./route-status-updater.component.css']
})
export class RouteStatusUpdaterComponent implements OnInit {

  routeLegs: any[] = null;
  selectedRouteLeg: any = null;

  UpdateDate: any;

  showSpinner: boolean = false;
  showDialog: boolean = false;
  waitingResults: boolean = false;
  submittingRequest: boolean = false;

  constructor(
    private log: LogBase,
    private messageService: MessageService,
    private api2: Api2Service,
    public filterDataService: FilterDataService,
    private titleService: Title,
    private cd: ChangeDetectorRef
  ) { this.titleService.setTitle("Route Status Updater"); }

  ngOnInit() { }

  async getMatchingRouteLegsFromMasterRouteIds() {
    this.showDialog = true;
    this.waitingResults = true;
    this.routeLegs = null;
    this.selectedRouteLeg = null;
    try {
      let masterRouteIds: number[] = [];
      for (var item of this.filterDataService.selectedFiles) {
        masterRouteIds.push(item.parentRouteId);
      }
      let retval = await this.api2.getMatchingRouteLegsFromMasterRouteIds(masterRouteIds);
      if (retval) {
        this.routeLegs = retval;
      }
      else {
        this.toastMessage('error', 'Failed to get matching route legs from master route Ids', '');
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get matching route legs from master route Ids', '');
      this.log.error(err);
    }
    this.waitingResults = false;
  }

  async updateFilesRouteStatus() {
    this.submittingRequest = true;
    try {
      let fileIds: number[] = [];
      for (var item of this.filterDataService.selectedFiles) {
        fileIds.push(item.fileId);
      }

      if (this.UpdateDate > new Date()) {
        this.toastMessage('error', 'Cannot set date into the future.', '');
        this.submittingRequest = false;
        return;
      }

      const payload = {
        fileIds: fileIds,
        locationId: this.selectedRouteLeg.locationId,
        typeId: this.selectedRouteLeg.typeId,
        updateDate: moment(this.UpdateDate).format('YYYY/MM/DD hh:mm:ss')
      };

      const retval = await this.api2.updateFilesRouteStatus(payload);
      if (!retval) this.toastMessage('error', 'Failed to update file(s) route status. Please contact support.', '');
      else if (retval.errorCode == 0) {
        this.toastMessage('success', 'File(s) route status updated', '');
        await this.filterDataService.refreshData(0);
        this.filterDataService.updateSelectedFiles();
      } else {
        if (retval.result.routeStatusFailures || retval.result.transporterFailures) {
          if (retval.result.routeStatusFailures && retval.result.routeStatusFailures.length > 0) {
            this.filterDataService.errorDialogTitle = 'Error(s) Updating File Route Status';
            this.toastMessage('warn', 'Some files failed to update.', '');
            if (!this.filterDataService.failedFiles) this.filterDataService.failedFiles = [];
            this.filterDataService.failedFiles.push(...retval.result.routeStatusFailures);
          }
          if (retval.result.transporterFailures && retval.result.transporterFailures.length > 0) {
            this.filterDataService.errorDialogTitle ? this.filterDataService.errorDialogTitle += " & Transporter Warnings" : this.filterDataService.errorDialogTitle = "File(s) with Transporter Warning(s)";
            this.toastMessage('info', 'Some files have no transporter assigned.', '');
            if (!this.filterDataService.failedFiles) this.filterDataService.failedFiles = [];
            this.filterDataService.failedFiles.push(...retval.result.transporterFailures);
          }
          this.submittingRequest = false;
          this.filterDataService.showErrorDialog = true;
        }

        await this.filterDataService.refreshData(0);
        this.filterDataService.updateSelectedFiles();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to update file(s) route status. Please contact support.', '');
      this.log.error(err);
    }
    this.showDialog = false;
    this.submittingRequest = false;
  }

  toastMessage(severity, summary, detail) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail
    });
  }
}
