import { Injectable } from '@angular/core';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";
import { Dropdown } from '../../interfaces/global.interfaces';
import { HttpResponse } from '../../interfaces/result-interfaces';
import { BorderPostStatus, Groups } from '../../enums';
import { PartialHandoverMatrix, ROROCIFMatrix } from '../../interfaces/finance-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ReferenceApiService {

  private serverUrl = `${this.config.getServerUrl()}api/reference/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/reference/AddStandardMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_AddStandardMatrix
  public async addStandardMatrix(PastelCode: string, Description: string, AmountRand: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}AddStandardMatrix?PastelCode=${PastelCode}&Description=${Description}&AmountRand=${AmountRand}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  // Endpoint: api/reference/updateStandardMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_updateStandardMatrix
  public async updateStandardMatrix(id: number, amount: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateStandardMatrix?Id=${id}&AmountRand=${amount}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return;
  }

  // Endpoint: api/reference/addHandoverMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_addHandoverMatrix
  public async addHandoverMatrix(selectedShippingLine: any, pastelCode: string, description: string, amount: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}addHandoverMatrix?SelectedShippingLine=${selectedShippingLine}&PastelCode=${pastelCode}&Description=${description}&amount=${amount}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  // Endpoint: api/reference/updateHandoverMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_updateHandoverMatrix
  public async updateHandoverMatrix(payload): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateHandoverMatrix`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getFileDocumentSources
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetPartialHandoverMatrix
  public async GetPartialHandoverMatrix(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetPartialHandoverMatrix`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/GetHandoverMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetHandoverMatrix
  public async GetHandoverMatrix(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetHandoverMatrix`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/GetStandardMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetStandardMatrix
  public async GetStandardMatrix(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetStandardMatrix`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/GetROROCIFMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_getROROCIFMatrix
  public async getROROCIFMatrix(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetROROCIFMatrix`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getFileDocumentSources
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetFileDocumentSources
  public async getDocumentSources(): Promise<Dropdown[] | null> {
    try {
      const url = `${this.serverUrl}getFileDocumentSources`;
      const retval = await this.webApi.get(null, url);
      if (retval.errorCode == 0) return retval.result;
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/GetRoundRobinUsersByGroupId
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetRoundRobinUsersByGroupId
  public async getRoundRobinUsersByGroupId(groupId: Groups): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetRoundRobinUsersByGroupId?groupId=${groupId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getAssignToTypes
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_getAssignToTypes
  public async getAssignToTypes(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getAssignToTypes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getAllGroupsAndRoundRobinUsers
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetAllGroupsAndRoundRobinUsers
  public async getAllGroupsAndRoundRobinUsers(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getAllGroupsAndRoundRobinUsers`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getBorderPosts
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetBorderPosts
  public async getBorderPosts(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getBorderPosts`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  // Endpoint: api/reference/getBorderPostStatus
  // Controller Name: ReferenceController.cs
  // Proc Name: getBorderPostStatus
  public async getBorderPostStatus(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getBorderPostStatus`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/addBorderPost
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_addBorderPost
  public async addBorderPost(borderPost: string, exitBorderPost: string, defaultCIFDestination: string, borderPostStatus: BorderPostStatus): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}addBorderPost?BorderPost=${borderPost}&ExitBorderPost=${exitBorderPost}&DefaultCIFDestination=${defaultCIFDestination}&borderPostStatus=${borderPostStatus}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/updateBorderPost
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_updateBorderPost
  public async updateBorderPost(borderPostId: number, borderPost: string, exitBorderPost: string, defaultCIFDestination: string, borderPostStatus: BorderPostStatus): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateBorderPost?borderPostId=${borderPostId}&BorderPost=${borderPost}&ExitBorderPost=${exitBorderPost}&DefaultCIFDestination=${defaultCIFDestination}&borderPostStatus=${borderPostStatus}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/updateColor
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_updateColor
  public async updateColor(id: number, color: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateColor?id=${id}&color=${color}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/addColor
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_addColor
  public async addColor(color: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}addColor?color=${color}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getDocumentCategory
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_getDocumentCategory
  async getDocumentCategory(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getDocumentCategory`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/updateDocumentCategory
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_updateDocumentCategory
  async updateDocumentCategory(id: number, documentCategory: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateDocumentCategory?Id=${id}&DocumentCategory=${documentCategory}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/GetROROMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetROROMatrix
  public async getROROMatrix(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetROROMatrix`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/GetGeneralStatus
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_GetGeneralStatus
  public async getGeneralStatus(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetGeneralStatus`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/getShippingLines
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_getShippingLines
  public async getShippingLines(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getShippingLines`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/updatePartialHandoverLineItem
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_UpdatePartialHandoverMatrix
  public async updatePartialHandoverMatrix(payload: PartialHandoverMatrix): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updatePartialHandoverMatrix`;
      return await this.webApi.post(null, url, payload as any);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/addPartialHandoverMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_addPartialHandoverMatrix
  public async addPartialHandoverMatrix(payload): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}addPartialHandoverMatrix`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/UpdateROROMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_UpdateROROMatrix
  public async updateROROMatrix(payload): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}UpdateROROMatrix`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/UpsertROROCIFMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_UpdateROROCIFMatrix
  public async upsertROROCIFMatrix(payload: ROROCIFMatrix): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}UpsertROROCIFMatrix`;
      return await this.webApi.post(null, url, payload as any);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/reference/addPartialHandoverMatrix
  // Controller Name: ReferenceController.cs
  // Proc Name: pr_addPartialHandoverMatrix
  public async addROROCIFMatrix(validTillDate: string, fromValidTillDate: string): Promise<HttpResponse | null> {
    try {
      const payload = {
        validTillDate: validTillDate,
        fromValidTillDate: fromValidTillDate
      } as any;
      const url = `${this.serverUrl}addROROCIFMatrix`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}