import { Component, OnInit } from '@angular/core';
import { Api2Service } from '../../../services/api2.service';
import { DispatchLoads, DispatchPlanType, LoadType, TableColumns } from '../../../interfaces/global.interfaces';
import { LogBase } from '../../../services/logger.service';
import { MessageService } from 'primeng';
import { FilterDataService } from '../../filter-search/filter-search-data.service';

@Component({
  selector: 'dispatch-planning',
  templateUrl: './dispatch-planning.component.html',
  styleUrls: ['./dispatch-planning.component.scss']
})

export class DispatchPlanningComponent implements OnInit {
  public loadTableColumns: TableColumns[] = [
    { field: 'name', header: 'Load Name' },
    { field: 'dateCreated', header: 'Created Date' },
    { field: 'estimatedDeparture', header: 'ETD' },
    { field: 'numberOfCargoOnLoad', header: '# Of Cargo On Load' },
    { field: 'loadCargoValue', header: 'Cargo Value' },
    { field: 'destination', header: 'Destination' },
    { field: 'cargoLoadAssignmentStatus', header: 'Status' },
    { field: 'driverName', header: 'Driver Name' },
    { field: 'assignedDate', header: 'Date Assigned	' },
    { field: 'truckInfo', header: 'Truck' },
    { field: 'trailerInfo', header: 'Trailer' }
  ];
  public loads: DispatchLoads[];
  public planAssignmentType: DispatchPlanType[];
  public statusData: { cargoAssignmentStatusId: number, cargoAssignmentStatus: string; }[] = [];

  public get loadTypes(): typeof LoadType { return LoadType; }

  constructor(
    private api2: Api2Service,
    private log: LogBase,
    private messageService: MessageService,
    public filterDataService: FilterDataService
  ) { }

  async ngOnInit() {
    await this.getCurrentDispatchLoads();
    await this.GetDispatchPlanStatusForAssignment();
  }

  private async GetDispatchPlanStatusForAssignment() {
    try {
      const retval = await this.api2.GetDispatchPlanStatusForAssignment();
      if (retval) {
        this.planAssignmentType = retval;
      }
      else this.toastMessage('error', 'Failed to get dispatch plan status for assignment. Please contact support.', '');
    }
    catch (ex) {
      this.log.error("Failed to get status for dispatch assigntment type");
    }
  }

  //Get current loads that are active
  public async getCurrentDispatchLoads() {
    try {
      const loadsDelivery = await this.api2.GetDeliveryLoads();
      if (loadsDelivery) {
        this.loads = loadsDelivery;
        this.statusData = this.loads.map(load => ({ cargoAssignmentStatusId: load.cargoLoadAssignmentStatusId, cargoAssignmentStatus: load.cargoLoadAssignmentStatus }));
      } else this.toastMessage('error', 'Failed to get dispatch load plans. Please contact support.', '');
    } catch (ex) {
      this.log.error("Failed to get dispatch load plan data");
    }
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}