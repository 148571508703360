import { LegalEntityType } from './../../../enums';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { LogBase } from '../../../services/logger.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-consignee-details',
  templateUrl: './consignee-details.component.html',
  styleUrls: ['./consignee-details.component.css']
})

export class ConsigneeDetailsComponent implements OnInit {

  @Input() activeIndex: number;
  @Output() indexEventFunction = new EventEmitter<any>();

  msgs: any[] = [];

  // Form
  // consigneeDetailsForm: any;
  // contactDetailsForm: any;

  people: any;

  consigneeSearchDetails: any;
  contactSearchDetails: any;

  consigneeDetails: any;
  contactDetails: any;

  consigneeId: number;
  contactId: number;

  showConsigneeDetails: boolean = false;
  showContactDetails: boolean = false;

  hasSavedBefore: boolean = false;

  // Other


  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private log: LogBase,
    private titleService: Title,

  ) {
    this.titleService.setTitle("AVECS Consignee-Details");
  }

  async ngOnInit() {
    await this.setup();
  }


  async setup() {
    let consignee = Number(localStorage.getItem("singleFileCreateClientId"));
    if (consignee > 0) {
      this.consigneeId = consignee;
      var res = await this.api.getNewlyCreatedPersonById(this.consigneeId);
      if (res) {
        this.consigneeDetails = res
        this.consigneeSearchDetails = res
        await this.setConsigneeDetails()
      }
    }

    let contact = Number(localStorage.getItem("singleFileCreateContactId"));
    if (contact > 0) {
      this.contactId = contact;
      var res = await this.api.getNewlyCreatedPersonById(this.contactId);
      if (res) {
        this.contactDetails = res
        await this.setContactDetails();
      }
    }
  }

  getConsigneeDetails(data: any) {
    if (data) {
      this.consigneeId = data.id;
      this.consigneeDetails = {
        Id: data.id,
        City: data.city,
        Address: data.address,
        Email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
        Person: data.person,
        Cellphone: data.cellphone
      }
      this.setConsigneeDetails();
    }
  }

  getContactsDetails(data: any) {
    if (data) {
      this.contactId = data.id;
      this.contactDetails = data;
      this.contactDetails = {
        Id: data.id,
        City: data.city,
        Address: data.address,
        Email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
        Person: data.person,
        Cellphone: data.cellphone
      }
      this.setContactDetails();
    }
  }

  async setConsigneeDetails() {
    if (!this.consigneeDetails.Cellphone) {
      this.consigneeDetails.Cellphone = 'N/A'
    }
    if (!this.consigneeDetails.City) {
      this.consigneeDetails.City = 'N/A'
    }
    if (!this.consigneeDetails.Address) {
      this.consigneeDetails.Address = 'N/A'
    }
    this.showConsigneeDetails = true;
  }

  async setContactDetails() {
    if (!this.contactDetails.Cellphone) {
      this.contactDetails.Cellphone = 'N/A'
    }
    if (!this.contactDetails.City) {
      this.contactDetails.City = 'N/A'
    }
    if (!this.contactDetails.Address) {
      this.contactDetails.Address = 'N/A'
    }
    this.showContactDetails = true;
  }

  async searchPerson(event: any) {
    try {
      var res = await this.api.searchPerson(event.query);
      this.people = res.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  async createNewContact() {
    await this.setStorageItems();
    this.router.navigate([`/manage-legal-entity/add/${LegalEntityType.PERSON}`]);
  }

  async editConsignee() {
    await this.setStorageItems();
    this.router.navigate([`/manage-legal-entity/${this.consigneeId}/${LegalEntityType.PERSON}`]);
  }

  async Back() {
    this.activeIndex = 0;
    await this.setStorageItems();
    await this.saveToLocalStorageAndEmitEvent();
  }

  async Next() {
    this.activeIndex = 2;
    await this.setStorageItems();
    await this.saveToLocalStorageAndEmitEvent();
  }

  async setStorageItems() {
    if (this.consigneeId) {
      localStorage.setItem('singleFileCreateClientId', this.consigneeId.toString());
    }
    if (this.contactId) {
      localStorage.setItem('singleFileCreateContactId', this.contactId.toString());
    }
  }

  async saveToLocalStorageAndEmitEvent() {
    localStorage.setItem('pageStateRules', JSON.stringify({ activeIndex: this.activeIndex }));
    this.indexEventFunction.emit({ action: 'index changed', params: this.activeIndex })
  }
}