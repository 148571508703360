<summary-dashboard></summary-dashboard>
<div class="overlay" *ngIf="showSpinner">
  <p-progressSpinner class="content" [style]="{ width: '50px', height: '50px' }" strokeWidth="8" fill="#EEEEEE"
    animationDuration="2s"></p-progressSpinner>
</div>

<div class="ui-g-12 card card-w-title" *ngIf="!showSpinner">
  <p-tabView>
    <!--Tab - Voyage Centric-->
    <p-tabPanel header="Voyage Centric">
      <p-table #dt dataKey="voyageId" [value]="uniqueVessels" [rows]="25" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} files"
        [globalFilterFields]="['vesselName','voyageNumber', 'eta']" [columns]="headers" [filterDelay]="0">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search keyword" style="color: white" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr class="table-labels">
            <th style="width: 3rem"></th>
            <th pSortableColumn="vesselName">Vessel Name<p-sortIcon field="vesselName"> </p-sortIcon>
            </th>
            <th pSortableColumn="voyageNumber">Voyage Number<p-sortIcon field="voyageNumber">
              </p-sortIcon>
            </th>
            <th pSortableColumn="eta">ETA<p-sortIcon field="eta"> </p-sortIcon>
            </th>
            <th pSortableColumn="totalCount">Total Count<p-sortIcon field="totalCount"> </p-sortIcon>
            </th>
            <th pSortableColumn="dbnCount">DBN Count<p-sortIcon field="dbnCount">
              </p-sortIcon>
            </th>
            <th pSortableColumn="reCount">RE Count<p-sortIcon field="reCount">
              </p-sortIcon>
            </th>
            <th pSortableColumn="phCount">PH Count<p-sortIcon field="phCount"> </p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-file let-expanded="expanded">
          <tr>
            <td>
              <button type="button" pButton pRipple [pRowToggler]="file"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{file.vesselName}}</td>
            <td>{{file.voyageNumber}}</td>
            <td>{{file.eta}}</td>
            <td>{{file.totalCount ? file.totalCount : 0}}</td>
            <td class="dbn">{{file.dbnCount ? file.dbnCount : 0}}</td>
            <td class="re">{{file.reCount ? file.reCount : 0}}</td>
            <td class="ph">{{file.phCount ? file.phCount : 0}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-location>
          <tr>
            <td colspan="8">
              <div class="ui-g-12">
                <p-table #dt2 class="rowTable" [value]="location.locationCount" dataKey="voyageId" [paginator]="true"
                  [rows]="10" [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['location']"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [showCurrentPageReport]="true" [autoLayout]="true" [resizableColumns]="true">
                  <ng-template pTemplate="caption">
                    <b>Showing {{dt2.totalRecords}} Records</b>
                    <div style="text-align: right">
                      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                      <input (input)="dt2.filterGlobal($event.target.value, 'contains')" type="text" pInputText
                        size="50" placeholder="Search Keyword" style="width:auto; color: white">
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="location">Current Location<p-sortIcon field="location">
              </p-sortIcon>
            </th>
            <th pSortableColumn="countCurrLocByLocType">Cargo Count<p-sortIcon field="countCurrLocByLocType">
              </p-sortIcon>
            </th>
            <th>View Files</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-loc>
          <tr>
            <td>{{loc.location}}</td>
            <td>{{loc.countCurrLocByLocType}}</td>
            <button class="filesButton" pButton type="button"
              (click)="showDialog(loc.voyageId, loc.currentLocationId, loc.location)" icon="pi pi-list"></button>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">There are no outstanding tasks.</td>
          </tr>
        </ng-template>
      </p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>
</p-tabPanel>

<!--Tab - Location Centric-->
<p-tabPanel header="Location Centric">
  <div class="container">
    <!-- <p-card> -->
    <p class="headers">Files Not Delivered To Final Destination</p>
    <p-accordion>
      <p-accordionTab>
        <p-header>
          <div class="accordionHeaders">
            <img class="accordionImage" [src]="'assets/images/locationtypes/(4).png'" />
            <h3>Warehouse {{filterWarehouse.length}}</h3>
          </div>
        </p-header>
        <p-table [value]="uniqueWarehouse">
          <ng-template pTemplate="header">
            <tr>
              <th style="font-weight: bold;">Warehouse</th>
              <th style="font-weight: bold;">Less Than 45 Days</th>
              <th style="font-weight: bold;">45 Days - 3 Months</th>
              <th style="font-weight: bold;">3-4 Months</th>
              <th style="font-weight: bold;">Over 5 Months</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-file>
            <tr>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName)">
                {{file.currentLocationName}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 1)">
                {{file.lessThanFortyFiveDays}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 2)">
                {{file.fourtyFiveDaysToThreeMonths}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 3)">
                {{file.threeToFourMonths}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 4)">
                {{file.overFiveMonths}}
                <i class="pi pi-eye"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
      <p-accordionTab>
        <p-header>
          <div class="accordionHeaders">
            <img class="accordionImage" [src]="'assets/images/locationtypes/(1).png'" />
            <h3>Port {{filterPort.length}}</h3>
          </div>
        </p-header>
        <p-table [value]="uniquePort">
          <ng-template pTemplate="header">
            <tr>
              <th style="font-weight: bold;">Port</th>
              <th style="font-weight: bold;">Less Than 45 Days</th>
              <th style="font-weight: bold;">45 Days - 3 Months</th>
              <th style="font-weight: bold;">3-4 Months</th>
              <th style="font-weight: bold;">Over 5 Months</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-file>
            <tr>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName)" icon="pi pi-eye">
                {{file.currentLocationName}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 1)" icon="pi pi-eye">
                {{file.lessThanFortyFiveDays}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 2)" icon="pi pi-eye">
                {{file.fourtyFiveDaysToThreeMonths}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 3)" icon="pi pi-eye">
                {{file.threeToFourMonths}}
                <i class="pi pi-eye"></i></td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(file.currentLocationName, 4)" icon="pi pi-eye">
                {{file.overFiveMonths}}
                <i class="pi pi-eye"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
      <p-accordionTab>
        <p-header>
          <div class="accordionHeaders">
            <img class="accordionImage" [src]="'assets/images/locationtypes/(3).png'" />
            <h3>In Transit {{filterTransit.length}}</h3>
          </div>
        </p-header>
        <p-table [value]="uniqueTransit">
          <ng-template pTemplate="header">
            <tr>
              <th style="font-weight: bold;">Route</th>
              <th style="font-weight: bold;">
                Less Than 45 Days</th>
              <th style="font-weight: bold;">45 Days - 3 Months
              </th>
              <th style="font-weight: bold;">3-4 Months</th>
              <th style="font-weight: bold;">Over 5 Months</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-trans>
            <tr>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(trans.currentLocationName)" icon="pi pi-eye">
                {{trans.currentLocationName}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(trans.currentLocationName, 1)" icon="pi pi-eye">
                {{trans.lessThanFortyFiveDays}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(trans.currentLocationName, 2)" icon="pi pi-eye">
                {{trans.fourtyFiveDaysToThreeMonths}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(trans.currentLocationName, 3)" icon="pi pi-eye">
                {{trans.threeToFourMonths}}
                <i class="pi pi-eye"></i>
              </td>
              <td pTooltip="View Files" class="hover"
                (click)="getFileDetailsOnAccordionTableDataClick(trans.currentLocationName, 4)" icon="pi pi-eye">
                {{trans.overFiveMonths}}
                <i class="pi pi-eye"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>
    <div class="ui-g-12" *ngIf="bottomTable.length > 0">
      <p class="headers">Showing Files For {{bottomTableHeading}}<p>
          <p-table #bt [value]="bottomTable" [autoLayout]="true" [paginator]="true" [rows]="10"
            [rowsPerPageOptions]="[10,25,50]"
            [globalFilterFields]="['dbn', 'vesselName', 'voyageNumber','foreignReference','description', 'eta','fileOwner']"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true" [resizableColumns]="true">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="bt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search keyword" style="color: white" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="font-weight: bold;" pSortableColumn="dbn">
                  File Id
                  <p-sortIcon field="dbn"></p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="foreignReference">
                  Foreign Reference
                  <p-sortIcon field="foreignReference"></p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="description">
                  Description
                  <p-sortIcon field="description"> </p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="vesselName">
                  Vessel Name
                  <p-sortIcon field="vesselName"></p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="voyageNumber">
                  Voyage Number
                  <p-sortIcon field="voyageNumber"></p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="eta">
                  ETA
                  <p-sortIcon field="eta"></p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="daysSinceETA">
                  Days Since ETA
                  <p-sortIcon field="daysSinceETA"></p-sortIcon>
                </th>
                <th style="font-weight: bold;" pSortableColumn="fileOwner">
                  File Owner
                  <p-sortIcon field="fileOwner"></p-sortIcon>
                </th>
                <th style="font-weight: bold;">
                  Actions
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
              <tr>
                <td>{{file.dbn}}</td>
                <td>{{file.foreignReference}}</td>
                <td>{{file.description}}</td>
                <td>{{file.vesselName}}</td>
                <td>{{file.voyageNumber}}</td>
                <td>{{file.eta}}</td>
                <td>{{file.daysSinceETA}}</td>
                <td>{{file.fileOwner}}</td>
                <td>
                  <button pTooltip="View {{file?.dbn}}" tooltipPosition="left" pButton type="button"
                    icon="far fa-folder" (click)="viewFile(file?.dbn)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
    </div>
  </div>
</p-tabPanel>
</p-tabView>
</div>

<p-dialog header="Showing Files for {{locName}}" [(visible)]="display" [style]="{width: '75vw'}">
  <p-table #d [value]="locationFiles" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
    [paginator]="true" [autoLayout]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} files"
    [globalFilterFields]="['fileId', 'currentLocation', 'description']">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="d.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword" style="color: white" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>FileId</th>
        <th>Description</th>
        <th>Current Location</th>
        <th>Actions</th>
      </tr>
      <tr class="table-labels">
        <th>
          <input pInputText class="width-100" type="text" (input)="d.filter($event.target.value, 'fileId', 'contains')">
        </th>
        <th>
          <input pInputText class="width-100" type="text"
            (input)="d.filter($event.target.value, 'description', 'contains')">
        </th>
        <th>
          <input pInputText class="width-100" type="text"
            (input)="d.filter($event.target.value, 'currentLocation', 'contains')">
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-loc>
      <tr>
        <td>{{loc.fileId}}</td>
        <td>{{loc.description}}</td>
        <td>{{loc.currentLocation}}</td>
        <td><button pTooltip="View {{loc?.fileId}}" tooltipPosition="left" pButton type="button" icon="far fa-folder"
            (click)="viewFile(loc?.fileId)"></button></td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>