import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ConfigService } from '../../../services/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogBase } from '../../../services/logger.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { DocumentStatusType } from '../../../enums';
import * as moment from 'moment';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { TableColumns } from '../../../interfaces/global.interfaces';
import { DocumentApiService } from '../../../services/api/document-api.service';

@Component({
  selector: 'app-document-history',
  templateUrl: './document-history.component.html',
  styleUrls: ['./document-history.component.scss'],
  providers: [ConfirmationService]
})

export class DocumentHistoryComponent implements OnInit {
  @Output() onLoaded = new EventEmitter();
  @Output() OnTermsInfoChanged = new EventEmitter<any>();
  @Input() DBNum: any;

  msgs: any = [];
  body: any;
  apiUrl: any;
  apiBorderDutiesUrl: any;
  apiSAD500Url: any;
  borderDutiesCategoryId: any;
  SAD500CategoryId: any;
  BillofEntryNo: any;
  BillOfLading: any;
  MRNNumber: any;
  LRNNumber: any;
  ParentItemNumber: any;
  borderPost: any[];
  selectedBorderPost: any;
  legalEntityId: any;
  UploadChoicesData: any[];
  uploadChoices: any[];
  uploadDocumentSpinner: boolean = false;
  hasChosenType: boolean = false;
  selectedChoice: any = {};
  documents: any[];
  displayImageViewer: boolean = false;
  showSpinner: boolean = false;
  theUploadHeaders: HttpHeaders;
  images = [];
  itemCols: TableColumns[] = [
    { field: 'FileName', header: 'Name' },
    { field: 'DocumentCategory', header: 'Category' },
    { field: 'InsertDate', header: 'Insert Date' },
    { field: 'StatusDate', header: 'Status' },
    { field: 'DocumentSource', header: 'Document Source' }
  ];

  loadIcon: string = 'fas fa-sync';

  constructor(
    private api: ApiService,
    private config: ConfigService,
    // private http: HttpClient,
    public documentApiService: DocumentApiService,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private titleService: Title,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Document History");
  }

  async ngOnInit() {
    this.showSpinner = true;
    try {
      this.legalEntityId = this.authService.getLegalEntityId;
      var docChoice = await this.api.getDocumentUploadChoices();
      this.UploadChoicesData = docChoice.result;
      this.apiUrl = this.config.getServerUrl() + 'api/Upload/uploadFileDocument';
      this.apiBorderDutiesUrl = this.config.getServerUrl() + 'api/Upload/uploadBorderDutiesDoc';
      this.apiSAD500Url = this.config.getServerUrl() + 'api/Upload/uploadSAD500Doc';
      this.borderDutiesCategoryId = await this.api.getDocumentCategoryIdByPath('border_post_duties');
      this.SAD500CategoryId = await this.api.getDocumentCategoryIdByPath('SAD500');
      this.theUploadHeaders = new HttpHeaders().set('Token', this.authService.getSessionToken).append('Id', this.authService.getLegalEntityId.toString());
      this.documents = await this.api.getAllFileDocuments(this.DBNum);
      this.borderPost = await this.api.getActualBorderPosts();
      await this.populateDropdown();
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
    this.showSpinner = false;
    this.onLoaded.emit();
  }

  async populateDropdown() {
    this.setUploadChoices();
  }

  closeViewer() {
    this.images = [];
    this.displayImageViewer = false;
  }

  async refreshList() {
    this.loadIcon = 'fas fa-sync fa-spin';
    try {
      this.documents = await this.api.getAllFileDocuments(this.DBNum);
    } catch (error) {
      this.showError('Error Refreshing List (refreshList())', error);
      this.log.error(error);
    }
    this.loadIcon = 'fas fa-sync';
  }

  setUploadChoices() {
    try {
      var tempData = [];
      for (let x = 0; x < this.UploadChoicesData.length; x++) {
        const item = this.UploadChoicesData[x];
        const newRecord = {
          label: item.DocumentCategory,
          value: item
        };
        tempData.push(newRecord);
      }
      this.uploadChoices = tempData;
    } catch (error) {
      this.log.error(error);
    }
  }

  async dropDownChoice() {
    this.hasChosenType = true;
  }

  hideFileUpload() {
    document.getElementById("fileUploadChoice").style.visibility = "hidden";
    document.getElementById("fileUpload").style.visibility = "hidden";
  }

  showFileUpload() {
    document.getElementById("fileUploadChoice").style.visibility = "visible";
    document.getElementById("fileUpload").style.visibility = "visible";
  }

  onBeforeSend(event: any) {
    this.uploadDocumentSpinner = true;
    event.formData.append('fileId', this.DBNum);
    event.formData.append('documentCategoryId', this.selectedChoice.value.Id);
  }

  onBeforeBorderDutiesSend(event: any) {
    this.uploadDocumentSpinner = true;
    event.formData.append('FileId', this.DBNum);
    event.formData.append('CategoryId', this.borderDutiesCategoryId);
    event.formData.append('BorderPostId', this.selectedBorderPost.value);
  }

  onBeforeSAD500Send(event: any) {
    this.uploadDocumentSpinner = true;
    event.formData.append('FileId', this.DBNum);
    event.formData.append('CategoryId', this.SAD500CategoryId);
    event.formData.append('BillofEntryNo', this.BillofEntryNo);
    event.formData.append('BillOfLading', this.BillOfLading);
    event.formData.append('MRNNumber', this.MRNNumber);
    event.formData.append('LRNNumber', this.LRNNumber);
    event.formData.append('ParentItemNumber', this.ParentItemNumber);
  }

  onUpload(event: any) {
    var res = event.originalEvent.body;
    if (res.errorCode == 0) {
      this.showFileUpload();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File Uploaded' });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to Upload File' });
    }
    this.uploadDocumentSpinner = false;
  }

  onBorderDutiesUpload(event: any) {
    console.error(event.originalEvent);
    var res = event.originalEvent.body;
    if (res.errorCode == 0) {
      this.showFileUpload();
      this.selectedBorderPost = undefined;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File Uploaded' });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to Upload File' });
    }
    this.uploadDocumentSpinner = false;
  }

  onSAD500Upload(event: any) {
    console.error(event.originalEvent);
    var res = event.originalEvent.body;
    if (res.errorCode == 0) {
      this.showFileUpload();
      this.BillofEntryNo = undefined;
      this.BillOfLading = undefined;
      this.MRNNumber = undefined;
      this.LRNNumber = undefined;
      this.ParentItemNumber = undefined;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File Uploaded' });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to Upload File' });
    }
    this.uploadDocumentSpinner = false;
  }

  onError() {
    try {
      this.uploadDocumentSpinner = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed To Upload File' });
    } catch (error) {
      this.log.error(error);
      this.uploadDocumentSpinner = false;
    }
  }

  async downloadDocumentforView(document) {
    try {
      const documentId = document.Id;
      let image = await this.api.viewClientDocument(documentId);
      if (image) {
        this.images = [image];
      } else {
        console.error('error');
      }
    }
    catch (error) {
      this.log.error(error);
    }
  }

  displayImageViewerDialogue(document) {
    this.displayImageViewer = true;
    this.downloadDocumentforView(document);
  }

  checkIfValueIsDate(value: any) {
    var date = moment(value);
    if (date.isValid()) {
      return date.format('YYYY/MM/DD HH:mm');
    }
    else {
      if (!value) return '-';
      else return value;
    }
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: File Summary Document History<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: File Summary Document History';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, this.DBNum, -1);
      }
    });
  }

  setStatusTypeColour(statusId: number) {
    if (statusId == DocumentStatusType.VERIFIED) return 'verified';
    else if (statusId == DocumentStatusType.UNVERIFIED) return 'unverified';
    else if (statusId == DocumentStatusType.CANCELLED) return 'cancelled';
    else if (statusId == DocumentStatusType.EXPIRED) return 'expired';
    else if (statusId == DocumentStatusType.DECLINED) return 'declined';
    else return '';
  }

}
