import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency, Invoice, LineItem, LineItemDescriptionOptions } from '../../../../interfaces/finance-interfaces';
import { Dropdown } from '../../../../interfaces/global.interfaces';
import { CargoItems } from '../../../../interfaces/filter-search-interfaces';
import { splitCamelCase as splitCamelCaseImport } from '../../../on-demand/any-view-table/dynamic-table/dynamic-table.component';
import { InvoiceApiService } from '../../../../services/api/finance/invoice-api.service';
import { CurrencyApiService } from '../../../../services/api/finance/currency-api.service';
import { LegalEntityApiService } from '../../../../services/api/legal-entity-api.service';
import { ApiService } from '../../../../services/api.service';
import { LogBase } from '../../../../services/logger.service';
import { MessageService } from 'primeng';
import { Title } from '@angular/platform-browser';
import { FinanceHelperService } from '../../../../services/finance-helper.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { InvoiceService } from '../invoice.service';
import { InvoiceType } from '../../../../enums';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit {

  //inputs and outputs
  @Input() fileId: number = 0;
  @Input() actionLabel: string = '';
  @Input() isAdhoc: boolean;
  
  @Input() actionSpinner: boolean = false;
  @Input() invoiceId: number = 0;
  @Output() invoiceEdit: EventEmitter<any> = new EventEmitter();
  @Output() invoiceCreate: EventEmitter<any> = new EventEmitter();

  //currencies
  selectedCurrency: Currency;
  currencyRates: any;
  currencies: Currency[];

  //bank account
  bankAccounts: Dropdown[];
  selectedBankAccountOne: Dropdown;
  selectedBankAccountTwo: Dropdown;

  //invoice
  selectLegalEntityTo: Dropdown;
  selectedInvoicee: Dropdown;
  invoicees: Dropdown[];
  discountAmount: number = 0;
  effectiveDate: Date;
  vatRate: number = 0;
  cargoItems: CargoItems[] = [];
  lineItemDescriptionOptions: LineItemDescriptionOptions[] = [];
  invoice: Invoice = this.getInvoiceModel;
  selectedLineItemDescriptions: any = { 0: this.getNewBlankLineItemDescription };
  lineItems: LineItem[];
  destination: string;
  originalInvoicePayload: Invoice = null;

  //component
  emailInvoicee: boolean = true;
  fileExists: boolean = false;
  showSpinner: boolean = false;
  editSpinner: boolean = false;
  showFileCargo: boolean = false;
  includeVAT: boolean = false;

  constructor(
    private invoiceApi: InvoiceApiService,
    private currencyApi: CurrencyApiService,
    private legalEntityApi: LegalEntityApiService,
    private api: ApiService,
    private invoiceService: InvoiceService,
    private log: LogBase,
    private messageService: MessageService,
    public titleService: Title,
    public financeHelper: FinanceHelperService // used in the html
  ) { }

  get getInvoice(): Invoice { return this.invoice; }
  get getOriginalInvoicePayload(): Invoice { return this.originalInvoicePayload; }
  get getNewBlankLineItem(): LineItem {
    return {
      amount: 0,
      netAmount: null,
      costingMatrixId: null,
      costingMatrixTypeId: null,
      description: '',
      pastelCode: '',
      quantity: 1
    };
  }
  get getIncludingVAT(): number { return (1 + (this.vatRate / 100)); }
  get getNewBlankLineItemDescription(): LineItemDescriptionOptions { return { label: '', value: '', costingMatrixId: null, costingMatrixTypeId: null }; }
  get getDiscount(): number { return this.discountAmount || 0.00; }
  get getVatAmount(): number { return this.includeVAT ? +((this.getInvoiceTotalExcludingVat) * (this.vatRate / 100)).toFixed(2) : 0.00; }
  get getInvoiceTotalExcludingVat(): number {
    const amount = _.sumBy(this.getInvoice.lineItems, (o) => {
      return o.amount * o.quantity;
    });
    return amount - this.getDiscount;
  }
  get getInvoiceTotalIncludingVat(): number { return this.getInvoiceTotalExcludingVat + this.getVatAmount; }
  get getInvoiceModel(): Invoice {
    const invoiceModel: Invoice = {
      accountId: 1,
      invoiceTypeId: 1,
      invoiceId: 0,
      currencyCode: '',
      date: undefined,
      invoicee: '',
      invoiceType: '',
      invoiceStatusId: 0,
      invoiceStatus: '',
      invoiceNumber: null,
      clearingAgentId: 0,
      clearingStatusId: 1,
      dbnNumber: '',
      genericId: null,
      genericTypeId: 5,
      totalExcVat: null,
      totalAmount: null,
      vatAmount: 0,
      vatRate: 0,
      discountAmount: 0,
      discountRate: 0,
      bankAccountOneId: null,
      bankAccountTwoId: null,
      currencyId: null,
      currencySymbol: null,
      legalEntityToId: null,
      effectiveDate: null,
      documentId: null,
      lineItems: [],
      removedLineItems: [],
    };
    return invoiceModel;
  }
  set setDiscountAmount(value: number) {
    if (!value) this.discountAmount = 0;
    else if (value < 0) {
      this.discountAmount = -value;
    }
    else if (value >= this.getInvoiceTotalExcludingVat) {
      this.toastMessage('warn', 'Discount amount MUST be less than invoice total', '');
      this.discountAmount = 0;
    }
    else {
      this.discountAmount = value;
    }
  }
  set setBankAccountOne(value: number) { this.getInvoice.bankAccountOneId = value; }
  set setBankAccountTwo(value: number) { this.getInvoice.bankAccountTwoId = value; }

  async ngOnInit() {
    try {
      this.showSpinner = true;
      //if there is an invoice id then we are editing an invoice
      if (this.invoiceId) await this.getInvoiceByInvoiceId();
      else this.invoice = this.getInvoiceModel;
      this.setUpPageDetails();
      await this.getInvoiceSetupData();
      if (this.fileExists) {
        await this.getVATRate();
        await this.getCurrencyRates();
        await this.getCurrencies();
        await this.getCargoItems();
        await this.getLineItems();
        await this.setScreenForEditMode();
        if (!this.isAdhoc && !this.invoiceId) this.autoSelectInvoiceLineItems();
      }
      this.showSpinner = false;
    } catch (err) {
      this.log.error(err);
    }
  }

  autoSelectInvoiceLineItems() {
    try {
      for (let i = 0; i < this.lineItems.length; i++) {
        //don't auto add lien items with amounts of 0
        if (this.lineItems[i].amount == 0) continue;
        //don't auto add the partial handover line item additional storage week
        else if (this.invoice.invoiceTypeId == InvoiceType.PARTIAL_HANDOVER && this.lineItems[i].costingMatrixId == 4) continue;

        this.addLineItem();
        const lineItem = this.lineItems[i];

        //what the below does is
        //the this.selectedLineItemDescriptions object looks like as follows:
        //0: {...}, 1: {...}, 2: {...} etc...
        //so we want to get the max object key number
        //and if the max key is not the same as the current array index we go ahead and say index--
        //this is because index can be 4 but the max key value from selectedLineItemDescriptions is at 3
        //so we will get an index out of bounds
        const allSelectedLineItemDescriptions = Object.keys(this.selectedLineItemDescriptions).map(x => +x);
        const maxIndex: number = Math.max(...allSelectedLineItemDescriptions);
        let index = i;
        if(maxIndex != index) index--;

        this.selectedLineItemDescriptions[index] = {
          label: lineItem.description,
          value: lineItem.description,
          costingMatrixId: lineItem.costingMatrixId,
          costingMatrixTypeId: lineItem.costingMatrixTypeId
        };
        this.onInvoiceItemAdd(lineItem.description, index);
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to automatically preselect all the invoice line items. Please contact support.', '');
      console.error(err);      
    }
  }

  async getInvoiceByInvoiceId() {
    try {
      const retval = await this.invoiceApi.GetInvoiceByInvoiceId(this.invoiceId);
      if (!retval) this.toastMessage('error', 'Failed to get invoice. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.invoice = JSON.parse(JSON.stringify(retval.result.invoice));//because of our good friend referencing
        this.originalInvoicePayload = retval.result.invoice;
        this.fileId = retval.result.fileId;
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get invoice. Please contact support.', '');
    }
  }

  async getInvoiceSetupData() {
    try {
      const retval = await this.invoiceApi.GetInvoiceSetUpData(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to set up page. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.getInvoice.dbnNumber = retval.result.dbnNumber;
        this.getInvoice.clearingStatusId = retval.result.clearingStatusId;
        this.getInvoice.clearingAgentId = retval.result.clearingAgentId;
        if (this.originalInvoicePayload) {
          this.originalInvoicePayload.clearingAgentId = retval.result.clearingAgentId;
          this.originalInvoicePayload.removedLineItems = [];
        }
        this.bankAccounts = retval.result.bankAccounts;
        this.invoicees = retval.result.invoicees;
        this.currencies = retval.result.currencies;
        this.getInvoice.currencyId = this.currencies[1].id;
        this.getInvoice.currencySymbol = this.currencies[1].symbol;
        this.selectedCurrency = this.currencies[1];
        this.destination = retval.result.destination;
        this.fileExists = true;
      }
    }
    catch (err) {
      this.log.error(err);
    }
  }

  setUpPageDetails() {
    this.titleService.setTitle(`${this.actionLabel} Invoice`);
    this.getInvoice.genericId = this.getInvoice.genericId || this.fileId;
  }

  async getVATRate() {
    try {
      const retval = await this.currencyApi.getVATRate();
      if (!retval) this.toastMessage('error', 'Failed to get VAT Rate. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.vatRate = +retval.result;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get VAT Rate. Please contact support.', '');
    }
  }

  async getCurrencies() {
    try {
      const retval = await this.currencyApi.getLocalCurrencies();
      if (!retval) this.toastMessage('error', 'Failed to get currencies. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.currencies = retval.result;
        this.getInvoice.currencyId = this.currencies[1].id;
        this.getInvoice.currencySymbol = this.currencies[1].symbol;
        this.selectedCurrency = this.currencies[1];
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async getCargoItems() {
    try {
      const retval = await this.api.getFileCargo(this.fileId);
      if(!retval) this.toastMessage('error', 'Failed to view cargo item details. Please contact support', '');
      else {
        for (var item of retval.Vehicles) {
          this.cargoItems.push(item);
        }
        for (var item of retval.ContainerItems) {
          this.cargoItems.push(item);
        }
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to view cargo item details. Please contact support', '');
      this.log.error(err);
    }
  }
  
  async getCurrencyRates() {
    try {
      const retval = await this.api.getInvoiceExchangeRates();
      if (!retval) this.toastMessage('error', 'Failed to get invoice exchange rates Rate. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.currencyRates = retval.result;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get invoice exchange rates Rate. Please contact support.', '');
    }
  }

  async getLineItems() {
    try {
      const retval = await this.invoiceApi.GetLineItemsForFileId(this.fileId, this.getInvoice.currencyId, this.isAdhoc);
      if(!retval) this.toastMessage('error', 'Failed to get invoice line items for file. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.lineItems = retval.result.lineItems.map(r => { r.description = this.splitCamelCase(r.description); return r; });
        this.lineItemDescriptionOptions = this.lineItems.map(r => { return { label: r.description, value: r.description }; });
        this.invoice.invoiceTypeId = retval.result.invoiceTypeId;
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get invoice line items for file. Please contact support.', '');
    }
  }

  async getLegalEntityDetails(selectedInvoicee: Dropdown) {
    try {
      const retval = await this.legalEntityApi.getLegalEntityById(selectedInvoicee.value);
      if (!retval) this.toastMessage('error', 'Failed to get the invoicee details. Please contact support.', '');
      else {
        this.selectedInvoicee = {
          label: retval.name,
          value: retval.id
        };
        this.getInvoice.legalEntityToId = selectedInvoicee.value;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  addLineItem() {
    if (this.getInvoice.lineItems.length == this.lineItems.length) {
      this.toastMessage('warn', '', 'Maximum amount of line items.');
      return;
    }
    this.getInvoice.lineItems.push(this.getNewBlankLineItem);
    this.selectedLineItemDescriptions[this.getInvoice.lineItems.length - 1] = this.getNewBlankLineItemDescription;
  }

  onInvoiceItemAdd(value: string, rowIndex: number) {
    const lineItem = this.lineItems.find(x => x.description == value);
    this.selectedLineItemDescriptions[rowIndex] = {
      label: value,
      value: value,
      costingMatrixId: lineItem.costingMatrixId,
      costingMatrixTypeId: lineItem.costingMatrixTypeId
    };
    this.setLineItem(rowIndex, lineItem);
    if (this.getInvoice.lineItems.length == 1) this.setDiscountAmount = this.discountAmount;
  }

  setLineItem(rowIndex: number, lineItem: LineItem) {
    let netAmount = lineItem.amount;
    if (this.includeVAT) {
      netAmount = +((lineItem.amount * lineItem.quantity) * (this.getIncludingVAT)).toFixed(2);
    }

    this.getInvoice.lineItems[rowIndex] = {
      id: this.getInvoice.lineItems[rowIndex].id,
      amount: lineItem.amount,
      netAmount: netAmount,
      costingMatrixId: lineItem.costingMatrixId,
      costingMatrixTypeId: lineItem.costingMatrixTypeId,
      description: lineItem.description,
      pastelCode: lineItem.pastelCode,
      quantity: lineItem.quantity
    };
  }
  
  onInvoiceItemRemove(selectedLineItem: LineItem, rowIndex: number) {
    const lineItem = this.selectedLineItemDescriptions[rowIndex];
    //get the index of the line item we want to remove
    const idx = this.getInvoice.lineItems.findIndex(x => x == selectedLineItem);

    if (lineItem) {
      if (this.actionLabel == 'Edit') {
        if (!this.getInvoice.removedLineItems) this.getInvoice.removedLineItems = [];
        if (selectedLineItem.id) this.getInvoice.removedLineItems.push(selectedLineItem.id);
      }

      this.getInvoice.lineItems.splice(idx, 1);

      for (var keystring of Object.keys(this.selectedLineItemDescriptions)) {
        var rowIndex = +keystring;
        if (rowIndex >= idx) {
          this.selectedLineItemDescriptions[rowIndex] = this.selectedLineItemDescriptions[rowIndex + 1] || this.getNewBlankLineItemDescription;
        }
      }
    }
  }

  //submitting creation of invoice
  createInvoice() {
    this.checkLineItems();
    this.setInvoiceAmounts();
    if (!this.getInvoice.lineItems || this.getInvoice.lineItems.length == 0) return;
    const payload = { invoice: this.getInvoice, emailInvoicee: this.emailInvoicee };
    this.invoiceCreate.emit(payload);
  }

  async editInvoice() {
    this.checkLineItems();
    this.setInvoiceAmounts();
    if (!this.getInvoice.lineItems || this.getInvoice.lineItems.length == 0) return;
    const payload = { invoice: this.getInvoice, emailInvoicee: this.emailInvoicee, fileId: this.fileId };
    this.invoiceEdit.emit(payload);
  }
  
  //remove line items that don't have a net amount
  checkLineItems() {
    let lineItemsArr = [... this.getInvoice.lineItems];

    for (let index = 0; index < lineItemsArr.length; index++) {
      const item = lineItemsArr[index];
      if (!(item.netAmount > 0) || !item.description || item.description == '') {
        this.onInvoiceItemRemove(item, index);
        lineItemsArr.splice(index, 1);
      }
    }

    const dupeLineItems = lineItemsArr.filter((lineItem, i, arr) => arr.findIndex(t => t.description.trim() === lineItem.description.trim()) === i);
    if (dupeLineItems.length != this.getInvoice.lineItems.length) {
      this.toastMessage('warn', 'There are duplicate line items', '');
      return true;
    }
    return false;
  }

  setInvoiceAmounts() {
    this.getInvoice.totalExcVat = this.getInvoiceTotalExcludingVat;
    this.getInvoice.totalAmount = this.getInvoiceTotalIncludingVat;
    this.getInvoice.vatAmount = this.getVatAmount;
    this.getInvoice.discountAmount = this.getDiscount;
  }

  onVATSelect() {
    try {
      this.includeVAT = !this.includeVAT;
      if (this.includeVAT) {
        this.getInvoice.vatRate = this.vatRate;
        this.getInvoice.lineItems?.filter(x => x.netAmount = +((x.amount * x.quantity) * (this.getIncludingVAT)).toFixed(2));
      } else {
        this.getInvoice.vatRate = 0;
        this.getInvoice.lineItems?.filter(x => x.netAmount = +(x.amount * x.quantity).toFixed(2));
      }
    }
    catch (err) {
      this.log.error(err);
    }
  }

  async setScreenForEditMode() {
    if (!this.originalInvoicePayload) return;
    if (this.getInvoice.vatAmount > 0) this.includeVAT = true;

    this.setDiscountAmount = this.getInvoice.discountAmount;
    this.setBankAccountOne = this.getInvoice.bankAccountOneId;
    this.setBankAccountTwo = this.getInvoice.bankAccountTwoId;
    this.effectiveDate = new Date(this.getInvoice.effectiveDate);
    this.getInvoice.removedLineItems = [];
    
    if (!this.selectLegalEntityTo || this.selectLegalEntityTo.value != this.getInvoice.legalEntityToId) {
      this.selectLegalEntityTo = this.invoicees.find(x => x.value == this.getInvoice.legalEntityToId);
      if (this.selectLegalEntityTo) await this.getLegalEntityDetails(this.selectLegalEntityTo);
      else this.toastMessage('warn', 'Could not find the selected invoicee', '');
    }

    this.selectedCurrency = this.currencies.find(x => x.id == this.originalInvoicePayload.currencyId);
    this.getInvoice.currencyId = this.originalInvoicePayload.currencyId;
    this.getInvoice.currencySymbol = this.originalInvoicePayload.currencySymbol;

    for (let i = 0; i < this.getInvoice.lineItems.length; i++) {
      const element = this.getInvoice.lineItems[i];
      this.selectedLineItemDescriptions[i] = {
        label: element.description,
        value: element.description,
        costingMatrixId: element.costingMatrixId,
        costingMatrixTypeId: element.costingMatrixTypeId
      };
    }
  }

  updateLineItems() {
    if (this.getInvoice.lineItems.length == 0) return;
    _.forEach(this.getInvoice.lineItems, (payloadLineItem: LineItem) => {
      let lineItem = _.find(this.lineItems, (o: LineItem) => { return o.description == payloadLineItem.description; });
      if (!lineItem || !lineItem.amount) return;

      payloadLineItem.amount = lineItem.amount;
      var index = _.findIndex(this.getInvoice.lineItems, (k: LineItem) => { return k.description == payloadLineItem.description; });
      this.setLineItem(index, payloadLineItem);
    });
  }
  
  async onCurrencySelect(event: Currency) {
    this.getInvoice.currencyId = event.id;
    this.getInvoice.currencySymbol = event.symbol;
    this.selectedCurrency = event;
    await this.getLineItems();
    this.updateLineItems();
  }
  
  setLineItemNetAmount(rowIndex: number) {
    let lineItem = this.getInvoice.lineItems[rowIndex];
    if (!lineItem.amount) lineItem.amount = 0;
    else if (lineItem.amount < 0) lineItem.amount = -lineItem.amount;
    else if (lineItem.amount > 1000000) lineItem.amount = 1000000;

    lineItem.quantity = lineItem.quantity < 0 ? -lineItem.quantity : lineItem.quantity; // if the line item quantity is negative make it postive
    !lineItem.quantity ? lineItem.quantity = 1 : lineItem.quantity;
    lineItem.netAmount = lineItem.amount * lineItem.quantity;
    if (this.includeVAT) lineItem.netAmount = this.getIncludingVAT * lineItem.netAmount;
  }
  
  isLineItemOther(lineItemDescription: any) {
    //AdHoc
    if (lineItemDescription.costingMatrixId == 3 && lineItemDescription.costingMatrixTypeId == 1)
      return true;
    //PH
    else if (lineItemDescription.costingMatrixId == 8 && lineItemDescription.costingMatrixTypeId == 4)
      return true;
    //RORO
    else if (lineItemDescription.costingMatrixTypeId == 2 && lineItemDescription.label == 'Other')
      return true;
    //RE
    else if (lineItemDescription.costingMatrixTypeId == 5 && lineItemDescription.label == 'Other')
      return true;
    
    return false;
  }
  
  disableLineItem(lineItemDescription: any) {
    if (this.actionLabel == 'Edit' && lineItemDescription.id > 0) return true;
    return false;
  }

  canCreateInvoice() {
    if (!this.getInvoice.bankAccountOneId || !this.getInvoice.bankAccountTwoId || !this.getInvoice.currencyId || !this.selectedInvoicee || !this.getInvoice.effectiveDate
      || this.getInvoice.lineItems.length == 0 || this.getInvoiceTotalExcludingVat == 0) {
      return true;
    }
    return false;
  }

  hasEditted() {
    if (JSON.stringify(this.getOriginalInvoicePayload) != JSON.stringify(this.getInvoice) || this.getOriginalInvoicePayload.discountAmount != this.discountAmount) return true;
    return false;
  }

  onEffectiveDateSelect() { this.getInvoice.effectiveDate = moment(this.effectiveDate).format('yy/MM/DD'); }
  undoEdits() { this.invoice = JSON.parse(JSON.stringify(this.getOriginalInvoicePayload)); this.setScreenForEditMode(); }
  splitCamelCase = splitCamelCaseImport;
  onEmailInvoicee(checked: boolean) { this.emailInvoicee = checked; }
  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}