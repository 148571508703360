import { Component, OnInit } from '@angular/core';
import { StorageAutoGenFailReasons } from '../../../../interfaces/finance-interfaces';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng';
import { LogBase } from "../../../../services/logger.service";
import { UserTaskApiService } from '../../../../services/api/user-tasks/user-tasks-api.service';
import { UserTaskStatus } from "../../../../enums";
import { InvoiceApiService } from '../../../../services/api/finance/invoice-api.service';
import { FinanceUserTaskApiService } from '../../../../services/api/user-tasks/finance-user-task-api.service';

@Component({
  selector: 'app-fix-storage-invoice-issues',
  templateUrl: './fix-storage-invoice-issues.component.html',
  styleUrls: ['./fix-storage-invoice-issues.component.scss']
})
export class FixStorageInvoiceIssuesComponent implements OnInit {

  public failReasons: StorageAutoGenFailReasons;
  public fileId: number;
  public showLoadingSpinner: boolean = false;
  public actionSpinner: boolean = false;

  constructor(
    private financeUserTaskApiService: FinanceUserTaskApiService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private log: LogBase,
    private userTaskApiService: UserTaskApiService,
    private invoice: InvoiceApiService
  ) { }

  async ngOnInit() {
    this.showLoadingSpinner = true;
    this.route.params.subscribe((params) => {
      this.fileId = params.fileId;
    });

    if (!this.fileId) {
      this.showLoadingSpinner = false;
      this.toastMessage('error', 'There is no file present. Please contact support.', '');
      return;
    }

    await this.getAutoGenStorageFailReasons(this.fileId);
    this.showLoadingSpinner = false;
  }

  private async getAutoGenStorageFailReasons(fileId: number) {
    try {
      const retval = await this.financeUserTaskApiService.getStorageAutoGenFailReasons(fileId);
      if (!retval) this.toastMessage("error", "Failed to storage invoice auto gen fail reasons", "");
      else if (retval.errorCode !== 0) this.toastMessage("error", retval.errorMessage, "");
      else this.failReasons = retval.result;
    } catch (error) {
      this.log.error(error);
      this.toastMessage('error', 'Failed to get user tasks. Something went wrong our side. Please contact support.', '');
    }
  }

  public async retryStorageInvoiceGeneration() {
    this.actionSpinner = true;
    try {
      const dayDifferent: number = 0;
      const retval = await this.invoice.ManualGenerateStorageInvoice(this.fileId, dayDifferent);
      if (!retval) this.toastMessage("error", "Failed to manually generate storage invoice", "");
      else if (retval.errorCode > 0) this.toastMessage("error", retval.errorMessage, "");
      else if (retval.errorCode < 0) {
        this.toastMessage("warn", retval.errorMessage, "");
        await this.userTaskApiService.UpdateUserTask(this.failReasons.userTaskId, UserTaskStatus.COMPLETED);
      } else {
        this.toastMessage("success", retval.errorMessage, "");
        await this.userTaskApiService.UpdateUserTask(this.failReasons.userTaskId, UserTaskStatus.COMPLETED);
      }
    } catch (error) {
      this.log.error(error);
      this.toastMessage('error', 'Failed to get user tasks. Something went wrong our side. Please contact support.', '');
    }
    this.actionSpinner = false;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}