import { MenuItem } from 'primeng/api';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'summary-dashboard',
  templateUrl: './summary-dashboard.component.html',
  styleUrls: ['./summary-dashboard.component.css']
})
export class SummaryDashboardComponent {

  menuItems: MenuItem[] = [
  {label: 'Morning Meeting', icon: 'pi pi-fw pi-star', routerLink:'/morning-page'},
      {label: 'Monthly Car Movement', icon: 'pi pi-fw pi-chart-bar', routerLink:'/monthly-car-movement'},
      { label: 'Ship Arrival', icon: 'pi pi-fw pi-calendar', routerLink: '/ship-arrivals' },
      {label: 'Vessel/Voyage Board', icon: 'pi pi-fw pi-bars', routerLink:'/vessel-board'},
    { label: 'Cargo Tracker', icon: 'pi pi-fw pi-chart-bar', routerLink: '/cars-in-durban' },
        { label: 'Outstanding Amounts & Arrear Notices', icon: 'pi pi-fw pi-chart-bar', routerLink: '/outstanding-amount-arrear-notices' },
    { label: 'Outstanding User Tasks', icon: 'pi pi-fw pi-chart-bar', routerLink: '/user-task-dashboard' }
      
    ];;
  activeItem: MenuItem = this.menuItems[0];

   constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle("Summary Dashboard");
  }
}