<div class="ui-g-12 center-progress-spinner" *ngIf="showLoadingSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="6" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Vehicles/Models</h1>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-2"><b>Manufacturer:</b></div>
    <div class="ui-g-8">
      <p-dropdown [options]="Manufacturers" optionLabel="Name" [(ngModel)]="selectedManufacturer"
        (onChange)="getModels()" [autoDisplayFirst]="false" [filter]="true" [style]="{ width: '100%'}">
      </p-dropdown>
    </div>
    <div class="ui-g-2">
      <button pRipple class="p-button-info" pButton type="button" icon="fa fa-plus" pTooltip="Add make"
        tooltipPosition="left" (click)="manufacturerDialog=true"></button>
    </div>
  </div>

  <div class="ui-g-12" *ngIf="Models">
    <p-table #dt [(ngModel)]="selectedModel" [scrollable]="true" scrollHeight="350px" [paginator]="true" [rows]="50"
      [rowsPerPageOptions]="[10,25,50,75]" [columns]="itemCols" [value]="Models" [autoLayout]="true"
      [globalFilterFields]="['Name']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add model"
              (click)="modelDialog=true" type="button" style="float: right; margin-right: 15px"
              icon="fas fa-plus"></button>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search models" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>
            Model
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            {{rowData.Name}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No models found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Add Model" modal="true" [(visible)]="modelDialog" [style]="{ width: '40vw'}">
  <div class="ui-g">
    <div class="ui-g-3">
      <label>Model :</label>
    </div>
    <div class="ui-g-9">
      <input type="text" class="width-100" maxlength="50" pInputText [(ngModel)]="newModel" />
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info width-100" pButton type="button" (click)="SaveNewModel()"
      [disabled]="!newModel" label="Save"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Add Manufacturer" modal="true" [(visible)]="manufacturerDialog" [style]="{ width: '50vw'}">
  <div class="ui-g">
    <div class="ui-g-4">
      <label>Manufacturer :</label>
    </div>
    <div class="ui-g-8">
      <input type="text" class="width-100" maxlength="50" pInputText [(ngModel)]="newManufacturer" />
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info width-100" pButton type="button" (click)="SaveNewManufacturer()"
      [disabled]="!newManufacturer" label="Save"></button>
  </p-footer>
</p-dialog>