import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogBase } from "../../../services/logger.service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { OnDemandApiService } from '../../../services/api/ondemand-api-service';
import { CriteriaType } from '../../../enums';
import { CriteriaResponse } from '../../../interfaces/result-interfaces';

@Component({
	selector: 'app-criteria',
	templateUrl: './criteria.component.html',
	styleUrls: ['./criteria.component.css']
})
export class CriteriaComponent implements OnInit {
	@Input() onDemandTemplateTypeId = null;
	@Input() type: string = "On Demand Task";
	@Output() onSubmit = new EventEmitter<any>();
	@Output() onResult = new EventEmitter<CriteriaResponse>();
	@Output() onClear = new EventEmitter<void>();
	@Output() onTemplateSelect = new EventEmitter<any>();
	payload: any = {};
	criteria: any[] = [];
	templates: any[] = [];
	selectedTemplate: any = undefined;
	disabled: boolean = true;
	showForm: boolean = false;
	showCriteria: boolean = false;
	showSpinner: boolean = false;
	form: FormGroup;

	constructor(
		private ondemandApiService: OnDemandApiService,
		public log: LogBase
	) { }

	async ngOnInit(): Promise<void> {
		await this.getTemplates();
	}

	public async _onTemplateSelect(event) {
		try {
			this.onClear.emit();
			this.showForm = false;
			let resp = await this.ondemandApiService.getCriteria(event.value.id);
			if (!resp) {
				this.log.error("Failed to get criteria for report", "", "criteria.component.ts");
			} else {
				this.criteria = resp;
			}
			await this.setupForm();
			this.onTemplateSelect.emit({ template: event.value });
		}
		catch (err) {
			this.log.error("Failed to get criteria for template", "", "criteria.component.ts");
		}
	}

	public async _onSubmit(): Promise<void> {
		this.showSpinner = true;
		let toReturn: any = {};
		toReturn.template = this.selectedTemplate;
		try {
			toReturn.payload = this.formatParameters();
			toReturn.result = await this.ondemandApiService.getOnDemandWithPayload(toReturn.payload, toReturn.template.url);
			toReturn.success = true;
			this.onResult.emit(toReturn);
			this.clear();
		}
		catch (err) {
			this.log.error("unable to submit on-demand request", err, "criteria._onSubmit");
			toReturn.success = false;
			toReturn.error = err;
			this.onResult.emit(toReturn);
		}
		this.showSpinner = false;
	}
	private clear(): void {
		this.showForm = false;
		this.criteria = [];
		this.payload = {};
		this.selectedTemplate = undefined;
		this.form = undefined;
	}
	private async getTemplates(): Promise<void> {
		try {
			let resp = await this.ondemandApiService.getOnDemandTemplates(this.onDemandTemplateTypeId);
			if (!resp || resp.length == 0) {
				this.log.error("No Templates Found", "", "criteria.getTemplates");
			} else {
				this.templates = resp;
			}
		}
		catch (err) {
			this.log.error('failed to get templates', err, 'criteria.getTemplates');
		}
	}
	private async setupForm(): Promise<void> {
		this.form = new FormGroup({});
		this.payload = {};

		let showCriteria: boolean = false;

		for (var index = 0; index < this.criteria.length; ++index) {
			// simpler
			let criteria = this.criteria[index];

			// new empty object
			this.payload[criteria.variableName] = {};

			// form control
			let formControl = new FormControl();
			formControl.setValue(criteria.defaultValue);
			if (criteria.required) formControl.setValidators([Validators.required]);
			if (criteria.hidden) formControl.disable();

			// add to form
			this.form.addControl(criteria.variableName, formControl);

			// get options if applicable
			let dropdownParams = [CriteriaType.DROPDOWN, CriteriaType.AUTOCOMPLETE].includes(criteria.criteriaTypeId) ? await this.getDropdownParams(criteria) : false;
			if (dropdownParams) this.payload[criteria.variableName].options = await this.getOptionsForDropdownCriteria(dropdownParams, criteria.dropdownDataUrl);

			// default value
			this.payload[criteria.variableName].value = criteria.defaultValue;

			// is hidden?
			if (showCriteria == false) showCriteria = !criteria.hidden;
		}

		this.showForm = true;
		this.showCriteria = showCriteria;
	}

	async getDropdownParams(criteria): Promise<any[]> {
		try {
			let resp = await this.ondemandApiService.getDropdownParamsForOnDemandCriteria(criteria.id);
			return resp;
		}
		catch (err) {
			this.log.error("failed to get dropdown parameters for criteria: " + criteria.variableName, err, "criteria.getDropdownParams()");
		}
	}

	async getOptionsForDropdownCriteria(dropdownParams: any[], url: string) {
		try {
			let dropdownOptionsParams = {};
			dropdownParams.forEach(param => { dropdownOptionsParams[param.name] = param.value; });
			const resp = await this.ondemandApiService.getOnDemandWithPayload(dropdownOptionsParams, url);
			return resp.result;
		}
		catch (err) {
			this.log.error("failed to get dropdown options for criteria. url:" + url, err, "criteria.getOptionsForDropdownCriteria()");
		}
	}

	private formatParameters(): object {
		let payloadToSend = {};
		for (let i = 0; i < this.criteria.length; i++) {
			const criteria = this.criteria[i];
			switch (criteria.variableName) {
				case "fromDate":
					payloadToSend[criteria.variableName] = moment(this.payload[criteria.variableName].value).format("YYYY/MM/DD 00:00:00");
					break;

				case "toDate":
					payloadToSend[criteria.variableName] = moment(this.payload[criteria.variableName].value).format("YYYY/MM/DD 23:59:59");
					break;

				default:
					payloadToSend[criteria.variableName] = this.payload[criteria.variableName].value;
					break;
			}
			if ([CriteriaType.DROPDOWN, CriteriaType.AUTOCOMPLETE].includes(criteria.criteriaTypeId)) {
				payloadToSend[criteria.variableName] = this.payload[criteria.variableName].value.value;
			}
		}
		return payloadToSend;
	}
	public search(pos, searchText): void {
		this.payload[pos].searchOptions = this.payload[pos].options.filter(i => i.label.toLowerCase().includes(searchText.query.toLowerCase()));
	}

	public get criteriaType(): typeof CriteriaType { return CriteriaType; }
}