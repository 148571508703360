<p-toast></p-toast>
<div class="layout-wrapper" [ngClass]="{'layout-compact':layoutCompact}" (click)="onLayoutClick()">

    <div #layoutContainer class="layout-container" [ngClass]="{'menu-layout-static': !isOverlay(),
    'menu-layout-overlay': isOverlay(),
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-horizontal': isHorizontal(),
    'menu-layout-slim': isSlim(),
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive}">

        <app-topbar></app-topbar>


        <div class="layout-menu" [ngClass]="{'layout-menu-dark':darkMenu}" (click)="onMenuClick($event)">
            <div #layoutMenuScroller class="nano">
                <div class="nano-content menu-scroll-content">
                    <app-menu [reset]="resetMenu"></app-menu>
                </div>
            </div>
        </div>


        <div class="layout-main">
            <router-outlet style="padding-bottom: 50px !important;"></router-outlet>

        </div>
        <div style="height: 50px; width:100%; display:block"></div>
        <app-footer-main class="footer" [ngClass]="{ 'footer-shit': !staticMenuDesktopInactive }"></app-footer-main>

        <div class="layout-mask"></div>
    </div>

</div>