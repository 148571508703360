import { Injectable } from '@angular/core';
import { DocumentCategory, UserTaskType } from '../../../enums';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';
@Injectable({
  providedIn: 'root'
})

export class WhatsappUserTaskApiService {
  private serverUrl = `${this.config.getServerUrl()}api/whatsapp/userTask/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  public async GetWhatsAppTalkToAgentGeneralUserTask(chatId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetWhatsAppTalkToAgentGeneralUserTask?chatId=${chatId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async DoWhatsAppTalkToAgentGeneralUserTask(chatId: number, userTaskId: number): Promise<HttpResponse | null> { 
    try {
      const url = `${this.serverUrl}DoWhatsAppTalkToAgentGeneralUserTask?chatId=${chatId}&userTaskId=${userTaskId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/CompleteWhatsappCheckDocumentUserTask
  // Controller Name: WhatsappController.cs
  public async CompleteWhatsappCheckDocumentUserTask(fileId: number, documentCategoryId: DocumentCategory): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}CompleteWhatsappCheckDocumentUserTask?fileId=${fileId}&documentCategoryId=${documentCategoryId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}