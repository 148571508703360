import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DispatchCollecionLoads, Dropdown, FileRowItemDescriptonAndId, FilesWithIssues, LoadType, TableColumns } from '../../../interfaces/global.interfaces';
import { Api2Service } from '../../../services/api2.service';
import { FilterDataService } from '../../filter-search/filter-search-data.service';
import { ConfirmationService, MessageService } from 'primeng';
import * as moment from 'moment';
import { HttpResponse } from '../../../interfaces/result-interfaces';
import { LoadPlanStatus } from '../../../enums';

interface AssignStatus {
  status: string;
  id: number;
}
@Component({
  selector: 'cargo-table',
  templateUrl: './cargo-table.component.html',
  styleUrls: ['./cargo-table.component.scss']
})
export class CargoTableComponent implements OnInit {
  @Output() refreshLoad = new EventEmitter<void>();
  @Input('loadId') loadId!: number;
  @Input('rowData') rowData!: DispatchCollecionLoads;
  @Input('loadName') loadName!: string;
  @Input('loadType') loadType!: LoadType;
  @Input('managerType') managerType: number;
  @Input() statusData: { cargoAssignmentStatusId: number, cargoAssignmentStatus: string; };

  public loadCargo: FileRowItemDescriptonAndId[] = [];
  public loadCargoColumns: TableColumns[] = [
    { field: 'dbnNumber', header: 'DBN Number' },
    { field: 'description', header: 'Description' },
    { field: 'routeStatus', header: 'Route Status' },
    { field: 'zarAmount', header: 'Cargo Value' }
  ];
  public transporters: Dropdown[] = [];
  public selectedTransporter: Dropdown = null;
  public assignStatus: AssignStatus[] = [
    {
      id: 6,
      status: 'Unassigned'
    },
    {
      id: 7,
      status: 'In transit'
    },
    {
      id: 8,
      status: 'Offloaded'
    },
  ];
  public assigned: AssignStatus[] = [
    {
      id: 6,
      status: 'Unassigned'
    }
  ];
  public transit: AssignStatus[] = [
    {
      id: 8,
      status: 'Offloaded'
    }
  ];
  public editedStatus: AssignStatus;
  public selectedDate: any;

  public loading: boolean = true;
  public addCargo: boolean = false;
  public cargoInfoEdit: boolean = false;
  public cargoTransporterEdit: boolean = false;
  public cargoLoadStatusEdit: boolean = false;

  public filesWithIssues: FilesWithIssues[] = [];
  public dialogHeader: string = '';
  public dialogFooter: string = '';
  public showDialog: boolean = false;

  constructor(
    private api2: Api2Service,
    public filterDataService: FilterDataService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    if (this.loadType == LoadType.COLLECTION) {
      this.recallCollectionLoadCargoItems(this.loadId);
    } else if (this.loadType == LoadType.DISPATCH) {
      this.recallDeliveryLoadCargoItems(this.loadId);
    }

    await this.getTransporters();
    this.selectedDate = moment(this.rowData.estimatedDeparture).toDate();

    this.selectedTransporter = {
      label: this.rowData.assignedTo,
      value: this.rowData.transporterId
    };
    this.handleLoadStatus();
  }

  private async recallCollectionLoadCargoItems(loadId: number): Promise<void> {
    const retval = await this.api2.GetCollectionLoadPlanCargoItemsByLoadId(loadId);
    if (retval) {
      this.loadCargo = retval;
      this.loading = false;
    } else {
      this.toastMessage('error', 'Error', 'Failed to recall collection load plan items');
    }
  }

  private async recallDeliveryLoadCargoItems(loadId: number): Promise<void> {
    const retval = await this.api2.GetDeliveryLoadPlanCargoItemsByLoadId(loadId);
    if (retval) {
      this.loadCargo = retval;
      this.loading = false;
    } else {
      this.toastMessage('error', 'Error', 'Failed to recall delivery load plan items');
    }
  }

  public checkIfLoadAddDisabled() {
    return !(this.rowData.dispatchLoadStatusId == 1 || this.rowData.dispatchLoadStatusId == 0);
  }

  public addCargoAction() {
    this.addCargo = !this.addCargo;
    this.goToBottom();
  }

  public async editLoad() {
    try {
      const payload = {
        "LoadId": this.loadId,
        "LoadName": this.loadName,
        "DriverName": this.rowData.driverName,
        "ETD": moment(this.selectedDate).format('YYYY/MM/DD, hh:mm:ss'),
        "TransporterId": this.selectedTransporter.value
      };
      await this.updateCollectionLoad(payload);
      this.refreshLoad.emit();
    } catch (error) {
      this.toastMessage('error', 'Error', 'Failed to update load information. Please contact support.');
    }
  }

  private async updateCollectionLoad(payload: any) {
    const editLoad = await this.api2.EditCollectionDispatchLoadInformation(payload);
    if (editLoad) {
      this.toastMessage('success', 'Success', 'Load information updated');
    } else {
      this.toastMessage('error', 'Error', 'Failed to update collection load information. Please contact support.');
    }
  }

  public async ediCargoAssignmentStatus() {
    try {
      switch (this.loadType) {
        case LoadType.COLLECTION:
          await this.editCollectionCargoAssignmentStatus();
          break;

        case LoadType.DISPATCH:
          await this.editDeliveryCargoAssignmentStatus();
          break;
      }

      this.rowData.cargoLoadAssignmentStatus = this.editedStatus.status;
    } catch (error) {
      this.toastMessage('error', 'Error', `Failed to update status. Please contact support. `);
    }
    this.cargoLoadStatusEdit = false;
  }

  private async editCollectionCargoAssignmentStatus() {
    const retval = await this.api2.EditCollectionCargoAssignmentStatus(this.rowData.cargoLoadAssignmentId, this.editedStatus.id);
    if (!retval) this.toastMessage('error', 'Error', `Could not update collection load status. Please contact support.`);
    else if (retval.errorCode !== 0) this.toastMessage('error', 'Error', `Could not update collection load status. ${retval.errorMessage}`);
    else {
      //there are issues with the files on the load (updating route status)
      if (retval.result) {
        this.dialogHeader = 'Files with Issues for Collection Load Status';
        this.dialogFooter = '*Please note the load status has not been updated.';
        this.filesWithIssues = retval.result;
        this.showDialog = true;
        this.toastMessage('error', 'Error', retval.errorMessage);
        this.editedStatus = null;
      } else {
        this.toastMessage('success', 'Success', retval.errorMessage);
        this.refreshLoad.emit();
      }
    }
  }

  private async editDeliveryCargoAssignmentStatus() {
    const retval = await this.api2.EditDeliveryCargoAssignmentStatus(this.rowData.cargoLoadAssignmentId, this.editedStatus.id);
    if (retval && retval.errorCode == 0) {
      this.toastMessage('success', 'Success', 'Delivery load status updated');
      this.refreshLoad.emit();
    } else this.toastMessage('error', 'Error', `Could not update delivery load status. ${retval.errorMessage}`);
  }

  public async assignLoadToTransporter() {
    try {
      switch (this.loadType) {
        case LoadType.COLLECTION:
          await this.assignCollectionLoadToTransport();
          break;

        case LoadType.DISPATCH:
          await this.assignDeliveryLoadToTransport();
          break;
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to assign load to transporter' + error
      });
    }
    this.cargoTransporterEdit = false;
  }

  private async assignCollectionLoadToTransport() {
    const retval = await this.api2.AssignCollectionLoadToTransport(this.loadId, this.selectedTransporter.value);
    if (!retval) this.toastMessage('error', 'Error', 'Failed to assign load to transporter');
    else if (retval.errorCode !== 0) this.toastMessage('error', 'Error', retval.errorMessage);
    else {
      this.toastMessage('success', 'Success', 'Load assigned to transporter');
      this.rowData.cargoLoadAssignmentId = retval.result;
      this.rowData.dispatchLoadStatusId = LoadPlanStatus.ASSIGNED_TO_TRANSPORTER;
      this.rowData.cargoLoadAssignmentStatusId = LoadPlanStatus.ASSIGNED_TO_TRANSPORTER;
    }
  }

  private async assignDeliveryLoadToTransport() {
    const retval = await this.api2.AssignDeliveryLoadToTransport(this.loadId, this.selectedTransporter.value);
    if (!retval) this.toastMessage('error', 'Error', 'Failed to assign load to transporter');
    else if (retval.errorCode !== 0) this.toastMessage('error', 'Error', retval.errorMessage);
    else this.toastMessage('success', 'Success', 'Load assigned to transporter');
  }

  public deleteLoad() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the following load:' + this.loadName,
      accept: async () => {
        try {
          let deleteLoad: HttpResponse | null = null;
          if (this.loadType == LoadType.COLLECTION) deleteLoad = await this.api2.DeleteCollectionLoadPlan(this.loadId);
          else if (this.loadType == LoadType.DISPATCH) deleteLoad = await this.api2.DeleteDeliveryLoadPlan(this.loadId);

          if (!deleteLoad) this.toastMessage('error', 'Error', 'Failed to remove load. Please contact support.');
          else if (deleteLoad.errorCode !== 0) this.toastMessage('error', 'Error', deleteLoad.errorMessage);
          else {
            this.toastMessage('success', 'Success', deleteLoad.errorMessage);
            this.refreshLoad.emit();
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.message
          });
        }
      }
    });
  }

  public removeCargoItem(itemToRemove: FileRowItemDescriptonAndId) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the following cargo item from the load:' + itemToRemove.description,
      accept: async () => {
        const payload = {
          "FileRowItemsId": itemToRemove.fileRowId,
          "LoadId": this.loadId
        };

        if (this.loadType == LoadType.COLLECTION) {
          let removed = await this.api2.RemoveCollectionLoadCargoItem(payload);
          if (removed.errorCode == 0) {
            await this.recallCollectionLoadCargoItems(this.loadId);
            this.messageService.add({
              severity: 'success',
              summary: 'Cargo removed successfully',
              detail: 'The item you selected was successfully removed from the load Load' + this.loadName
            });
            this.rowData.numberOfCargoOnLoad--;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to remove cargo'
            });
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to remove cargo'
          });
        }
      }
    });
  }

  private async getTransporters() {
    try {
      const retval = await this.api2.getActiveTransporters();
      if (retval) {
        this.transporters = retval;
        if (this.rowData.transporterId) {
          this.selectedTransporter = this.transporters.find(x => x.value == this.rowData.transporterId);
        }
      }
    } catch (error) {
      // TODO: Handle Error Message
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to get transporters'
      });
    }
  }

  public async handleAddToLoad() {
    this.filterDataService.failedFiles = [];
    this.filterDataService.showErrorDialog = false;
    let fileIds: number[] = [];

    for (const file of this.filterDataService.selectedFiles) {
      const retval = await this.api2.GetFileCargoNotSerialised(file.fileId);

      if (!retval || retval.length > 1) {
        this.filterDataService.failedFiles.push({
          dbnNumber: file.dbnNumber,
          failReason: 'Cannot add file to load. File has more than one file row items',
          isFailure: 1,
          warning: ''
        });
        continue;
      }

      fileIds.push(file.fileId);
    }

    if (fileIds.length !== 0) {
      await this.AddToLoad(fileIds);
    }

    if (this.filterDataService.failedFiles.length !== 0) this.filterDataService.showErrorDialog = true;

    if (this.loadType == LoadType.COLLECTION) await this.recallCollectionLoadCargoItems(this.loadId);
    else if (this.loadType == LoadType.DISPATCH) await this.recallDeliveryLoadCargoItems(this.loadId);
  }

  private async AddToLoad(fileIds: number[]) {
    const payload = {
      "FileIds": fileIds,
      "LoadId": this.loadId
    };

    switch (this.loadType) {
      case LoadType.COLLECTION:
        await this.addCollectionLoad(payload);
        break;

      case LoadType.DISPATCH:
        // await this.addDeliveryLoad(payload);
        break;
    }

    await this.filterDataService.refreshData(0);
    this.filterDataService.updateSelectedFiles();
  }

  private async addCollectionLoad(payload: any) {
    const addLoad = await this.api2.AddToCollectionDispatchLoad(payload);
    if (!addLoad) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed to add files to collection load - ${this.loadName}. Please contact support.`,
      });
    } else if (addLoad.errorCode !== 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: addLoad.errorMessage,
      });
      this.filterDataService.failedFiles.push(...addLoad.result);
      this.filterDataService.errorDialogTitle = 'Error(s) Adding Cargo to Collection Load';
    } else {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Files have been added to load ${this.loadName}`
      });
      this.filterDataService.failedFiles = [];
      this.filterDataService.showErrorDialog = false;
      this.filterDataService.errorDialogTitle = '';
      // this.goToTop();
      this.addCargo = false;
    }

    await this.recallCollectionLoadCargoItems(this.loadId);
  }

  private handleLoadStatus() {
    if (this.managerType === LoadType.COLLECTION) {
      this.editedStatus = this.assignStatus.find(a => a.id === this.rowData.cargoLoadAssignmentStatusId);
    } else if (this.managerType === LoadType.DISPATCH) {
      this.editedStatus = this.transit.find(a => a.id === this.rowData.dispatchLoadStatusId);
    }
  }

  //Action to view a file directly from the tables
  public viewFile(dbnNumber: string) {
    const fileId = dbnNumber.split('/')[1];
    window.open(`file-view?query=${fileId}`);
  }

  //set timeout before scrolling down
  //works better as there it a lot to load before scrolling down
  private goToBottom() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  }

  //set timeout before scrolling up
  private goToTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  //Additional functions
  //This will hide and show save button for cargo details
  public toggleEdit() {
    this.cargoInfoEdit = !this.cargoInfoEdit;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}