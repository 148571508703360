import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Api2Service } from '../../../services/api2.service';
import { LogBase } from '../../../services/logger.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../../services/config.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DateTime } from "luxon";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import * as moment from 'moment';

export interface Shipping {
  FileId: number;
  VoyageNumber: string;
  VesselName: string;
  VesselId: number;
  VoyageId: number;
  Company: string;
  VesselCompanyId: number;
  PortDeparture: string;
  PortDepartureId: number;
  PortArrival: string;
  PortArrivalId: number;
  BorderPost: string;
  BorderPostId: number;
  Route: string;
  RouteId: number;
  MBL: string;
  TrackingNo: string;
  BOENumber: string;
  Closed: boolean;
  Cancelled: boolean;
  ETD: any;
  ETA: any;
  SOB: any;
  BOEDate: any;
}

@Component({
  selector: 'app-shipping-info',
  templateUrl: './shipping-info.component.html',
  styleUrls: ['./shipping-info.component.scss'],
  providers: [ConfirmationService, DatePipe]
})

export class ShippingInfoComponent implements OnInit {
  msgs: any[];
  showConfirmRouteChange = false;

  @Input() FileId: any;
  @Output() onLoaded = new EventEmitter();
  noData: any;
  UpdateRouteError;

  showSaveSpinner: boolean = false;
  fileClosed: boolean = false;
  readOnly: boolean = false;
  Shipping: Shipping;
  editMode: boolean = false;
  selectedShippingCompany: any;
  portOfArrival;
  portOfDeparture;
  vessels: any;
  selectedVessel: any;
  voyages: any[];
  selectedVoyage: any;
  routes: any[];
  selectedRoute: any;
  enableRouting: boolean = false;
  routeEdited: boolean = false;
  StartRouteId: any;
  StartRoute: any;
  routeChanged: boolean = false;
  mblNumbers: any[];
  selectedMBL: any;
  ETD: any;
  ETA: any;
  SOB: any;
  BOEDate: any;

  constructor(
    private api: ApiService,
    private api2: Api2Service,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private authService: AuthenticationService,
    private titleService: Title,
    private router: Router
  ) {
    this.titleService.setTitle("AVECS Shipping-Info");
  }

  async ngOnInit() {
    try {
      this.Shipping = await this.api.getShippingInfo(this.FileId);
      this.StartRouteId = this.Shipping.RouteId;
      this.StartRoute = this.Shipping.Route;
      if (this.Shipping.Closed || this.Shipping.Cancelled) {
        this.readOnly = true;
      }
      this.fileClosed = this.Shipping.Closed;
      this.formatDates();
    } catch (error) {
      this.showError('An Error occurred', error);
      this.log.error(error);
    }
    this.onLoaded.emit();
  }

  async setvessel() {
    this.Shipping.VesselId = this.selectedVessel.Id;
    this.Shipping.VesselName = this.selectedVessel.Name;
    await this.getVoyages(this.selectedVessel.Id);
  }

  async getVoyages(vesselid: any) {
    try {
      this.voyages = await this.api.getVoyagesByVesselId(vesselid);
      for (var x = 0; x < this.voyages.length; x++) {
        if (!this.voyages[x].ETA) this.voyages[x].ETA = '';
      }
    } catch (error) {
      this.showError('Error Getting Voyages (getVoyages())', error);
      this.log.error(error);
    }
  }

  async getMBLNumbers() {
    try {
      this.mblNumbers = await this.api.getMBLNumbers(this.selectedVoyage.Id); // this.Shipping.VesselId);
      this.ETA = this.selectedVoyage.ETA;
    } catch (error) {
      this.log.error(error);
    }
  }

  async setMBLNumber() {
    this.Shipping.MBL = this.selectedMBL.MBL;
  }

  async toggleEditMode() {
    try {
      if (!this.editMode) {
        if (!this.vessels) this.vessels = await this.api.getVesselNames();
        if (this.Shipping.VesselId) {
          for (var x = 0; x < this.vessels.length; x++) {
            if (this.vessels[x].Id == this.Shipping.VesselId) {
              this.Shipping.VesselName = this.vessels[x].Name;
              this.selectedVessel = this.vessels[x];
              break;
            }
          }

          await this.getVoyages(this.Shipping.VesselId);

          for (var x = 0; x < this.voyages.length; x++) {
            if (this.voyages[x].Id == this.Shipping.VoyageId) {
              this.selectedVoyage = this.voyages[x];
              break;
            }
          }

          if (!this.mblNumbers) this.mblNumbers = await this.api.getMBLNumbers(this.selectedVoyage.Id);

          for (var x = 0; x < this.mblNumbers.length; x++) {
            if (this.mblNumbers[x].MBL == this.Shipping.MBL) {
              this.selectedMBL = this.mblNumbers[x];
              break;
            }
          }

          this.portOfArrival = await this.api.getLocationById(this.Shipping.PortArrivalId);
          this.portOfDeparture = await this.api.getLocationById(this.Shipping.PortDepartureId);
          await this.FilterRoute(false);

          if (this.Shipping.RouteId) {
            const res = _.find(this.routes, (o: any) => { return o.routeId == this.Shipping.RouteId; });
            if (res) this.selectedRoute = res;
          }
          this.editMode = true;
        }
      }
    } catch (error) {
      this.showError('Error Togglling Edit Mode (toggleEditMode())', error);
      this.log.error(error);
    }
  }

  async onSelectRoute(event: any) {
    try {
      if (this.Shipping) {
        this.Shipping.RouteId = event.value.routeId;
        this.Shipping.Route = event.value.route;
      }
      this.routeEdited = true;
      this.routeChanged = false;
    } catch (error) {
      this.showError('Error Selecting Route', error);
      this.log.error(error);
    }
  }

  async createNewRoute() {
    this.router.navigate(['/route-builder', 'newfile']);
  }

  async onSelectDepartureCity(event: any) {
    this.portOfDeparture = event;
    var location = event.Location.split(",", 1);
    this.portOfDeparture.Location = location[0];
    await this.FilterRoute(true);
  }

  async onSelectArrivalCity(event: any) {
    this.portOfArrival = event;
    var location = event.Location.split(",", 1);
    this.portOfArrival.Location = location[0];
    await this.FilterRoute(true);
  }

  async FilterRoute(edited: boolean) {
    if (this.portOfDeparture && this.portOfArrival) {
      this.routes = await this.api2.getFilteredRoutes(this.portOfDeparture.Location, this.portOfArrival.Location);
      this.routeEdited = edited;
      if (edited) {
        this.routeChanged = true;
        if (this.routes.length > 0) {
          this.messageService.add({ severity: 'warn', summary: '', detail: 'Please Update the Route' });
        } else {
          this.messageService.add({ severity: 'warn', summary: '', detail: 'Please Add a new  Route' });
        }
      }
    }
  }

  async cancelEdit() {
    this.showSaveSpinner = false;
    if (this.editMode) {
      this.editMode = false;
    }
  }

  async getVessels() {
    try {
      this.vessels = await this.api.getShippingCompanyVessels(this.selectedShippingCompany);
      this.selectedVessel = this.Shipping.VesselId;
    } catch (error) {
      this.showError('Error Getting Vessels (getVessels())', error);
      this.log.error(error);
    }
  }

  async getVesselVoyages() {
    try {
      this.voyages = await this.api.getVesselVoyages(this.selectedVessel);
      this.selectedVoyage = this.Shipping.VoyageId;
    } catch (error) {
      this.showError('Error Getting Vessel Voyages (getVesselVoyages())', error);
      this.log.error(error);
    }
  }

  formatDates() {
    if (this.Shipping.ETD) {
      const etd = DateTime.fromISO(this.Shipping.ETD).toFormat('yyyy/MM/dd');
      this.ETD = etd;
      this.Shipping.ETD = new Date(this.Shipping.ETD);
    }

    if (this.Shipping.ETA) {
      const eta = DateTime.fromISO(this.Shipping.ETA).toFormat('yyyy/MM/dd');
      this.ETA = eta;
      this.Shipping.ETA = new Date(this.Shipping.ETA);
    }

    if (this.Shipping.SOB) {
      const sob = DateTime.fromISO(this.Shipping.SOB).toFormat('yyyy/MM/dd');
      this.SOB = sob;
      this.Shipping.SOB = new Date(this.Shipping.SOB);
    }

    if (this.Shipping.BOEDate) {
      const boeDate = DateTime.fromISO(this.Shipping.BOEDate).toFormat('yyyy/MM/dd');
      this.BOEDate = boeDate;
      this.Shipping.BOEDate = new Date(this.Shipping.BOEDate);
    }

    if (this.Shipping.MBL && this.Shipping.MBL == '#N/A') {
      this.Shipping.MBL = '';
    }
  }

  async update(ConfirmChange) {
    this.showSaveSpinner = true;
    try {
      const payload = {
        FileId: Number(this.FileId),
        VoyageId: this.selectedVoyage.Id,
        TrackingNo: this.Shipping.TrackingNo,
        PortOfArrivalId: this.portOfArrival.Id,
        PortOfDepartureId: this.portOfDeparture.Id,
        MBL: this.Shipping.MBL,
        ETD: moment(this.Shipping.ETD).format('YYYY/MM/DD'),
        SOB: moment(this.Shipping.SOB).format('YYYY/MM/DD'),
        BOENumber: this.Shipping.BOENumber,
        BOEDate: this.Shipping.BOEDate ? moment(this.Shipping.BOEDate).format('YYYY/MM/DD') : null,
        RouteId: this.selectedRoute.routeId,
        ConfirmChange: ConfirmChange
      };

      const retval = await this.api.updateShippingDetails(payload);
      if (!retval) {
        this.showSaveSpinner = false;
        this.messageService.add({ severity: 'error', summary: '', detail: 'An error has occured - No response' });
      } else if (retval.errorCode == 100) {
        //showerror message for route cannot be updated do you want to force update
        //Kuben would like to block changing the route now
        this.messageService.add({ severity: 'error', summary: '', detail: retval.errorMessage });
      } else if (retval.errorCode != 0) {
        this.showSaveSpinner = false;
        this.messageService.add({ severity: 'error', summary: '', detail: 'Could not Update Shipping Details -' + retval.errorMessage });
      } else {
        //Success
        if (this.routeEdited) {
          await this.addNote('Route Changed from:  ' + this.StartRoute + '  to:  ' + this.selectedRoute.route);
          this.routeChanged = false;
          this.routeEdited = false;
        }
        this.Shipping = await this.api.getShippingInfo(this.FileId);
        this.StartRouteId = this.Shipping.RouteId;
        this.StartRoute = this.Shipping.Route;
        this.formatDates();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Shipping details updated' });
        this.editMode = false;
      }
    } catch (error) {
      this.showError('Error Updating Shipping details (update())', error);
      this.log.error(error);
    }
    this.showSaveSpinner = false;
  }

  async addNote(noteData: any) {
    try {
      noteData = noteData.split('&').join('and');
      await this.api.saveNoteDataForFile(noteData, this.FileId, 9);//9 is notetype for route changed
    } catch (error) {
      this.showError('Error Saving Note', error);
      this.log.error(error);
    }
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: File Summary Shipping Info<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: File Summary Shipping Info';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, this.FileId, -1);
      },
      reject: () => { }
    });
  }
}