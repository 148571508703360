import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilePreferenceApiService } from '../../../services/api/file-preference-api.service';
import { MessageService, Table } from 'primeng';
import { FilePreferenceHistory } from '../../../interfaces/file.preference.interface';
import * as moment from 'moment';
import { Dropdown } from '../../../interfaces/global.interfaces';
import { FilePreferenceType } from '../../../enums';

@Component({
  selector: 'file-preferences-history',
  templateUrl: './file-preferences-history.component.html',
  styleUrls: ['./file-preferences-history.component.scss']
})
export class FilePreferencesHistoryComponent implements OnInit {

  @ViewChild('dt') table: Table;
  @Input() fileId: number = 0;
  @Output() public onLoaded: EventEmitter<void> = new EventEmitter();
  public filePreferenceHistory: FilePreferenceHistory[] = [];
  public filePreferenceTypes: Dropdown[] = [];
  public showSpinner: boolean = false;

  constructor(
    private filePreferenceApiService: FilePreferenceApiService,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    this.showSpinner = true;
    await this.getFilePreferenceTypes();
    await this.getFilePreferenceHistory();
    this.showSpinner = false;
    this.onLoaded.emit();
  }

  private async getFilePreferenceTypes(): Promise<void> {
    try {
      const retval = await this.filePreferenceApiService.GetFilePreferenceTypes();
      if (!retval) this.toastMessage('error', 'Failed to get file preference types. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.filePreferenceTypes = retval.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get file preference types. Please contact support.', '');
    }
  }

  private async getFilePreferenceHistory(): Promise<void> {
    try {
      const retval = await this.filePreferenceApiService.GetFilePreferenceHistory(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to get file preference history. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.filePreferenceHistory = retval.result;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get file(s). Please contact support', '');
    }
  }
  public filterFilePreference(filePreferenceType: FilePreferenceType) {
    const filePreference = this.filePreferenceTypes.find(f => f.value === filePreferenceType);
    this.table.filter(filePreference?.label, 'filePreference', 'contains');
  }
  public onFilterDateSelect(date: any) {
    this.table.filter(this.formatDate(date).toString(), 'insertDate', 'contains');
  }
  public formatDate(date: Date) { return moment(date).format('DD/MM/yy'); }
  public formatInsertDate(date: string) { return moment(date, 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD HH:mm a'); }
  private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
