import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';

@Injectable()
export class SearchService {

    private subject = new Subject<any>();
    
    sendSearch(message: string) {
        this.subject.next({ text: message });
    }
    
    clearSearch() {
        this.subject.next();
    }
}
