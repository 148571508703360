import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { ConfigService } from '../../../services/config.service';
import { LogBase } from '../../../services/logger.service';
import * as _ from 'lodash';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { TableColumns } from '../../../interfaces/global.interfaces';

export interface VesselCompanyName {
  Id: number;
  Company: string;
  LegalEntityId: number;
  ShippingLines: any[];
  selectedshippingLine: any;
}

export interface ShippingLinesName {
  value: number;
  label: string;
  selectedShippingLines: VesselCompanyName;
}

@Component({
  selector: 'app-shipping-lines',
  templateUrl: './shipping-lines.component.html',
  styleUrls: ['./shipping-lines.component.css'],
  providers: [ConfirmationService]
})
export class ShippingLinesComponent implements OnInit {
  name = 'Manage Shipping Lines';
  msgs: any[];

  showGrid: boolean = true;
  displayDialog: boolean = false;
  showChecked: boolean = true;
  autoWidth: boolean = true;
  itemCols: TableColumns[] = [
    { field: 'Company', header: 'Short Name' },
    { field: 'LegalEntityId', header: 'Shipping Company' }
  ];

  newShippingLine: string;

  showLoadingSpinner: boolean = false;
  showDeleteShipplinLine: boolean = false;
  showReassignVessels: boolean = false;

  showAddShippingCompany: boolean = false;

  shippingLines: ShippingLinesName[];
  vesselCompany: VesselCompanyName[];

  selectedReassignToShippingLine: any;

  selectedVesselCompany: VesselCompanyName;
  selectedNewShippingLine: any = [];

  selectedShippingLine: any | null = null;
  shippingLineVessels: any[];

  shippingLineName: string;

  resizable: boolean = true;

  constructor(
    private api: ApiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private log: LogBase,
    private titleService: Title,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS - Shipping Lines");
    // this.selectedShippingLine.VesselCount = 0;
    // this.selectedShippingLine.Company = '';
  }

  async ngOnInit() {
    this.showLoadingSpinner = true;
    this.shippingLines = await this.api.getShippingLinesEntity();
    this.vesselCompany = await this.api.getVesselCompany();
    this.showLoadingSpinner = false;
    this.addDropdownsAndSetDefault();
  }

  addDropdownsAndSetDefault = () => {
    this.vesselCompany.forEach(item => {
      item.ShippingLines = this.shippingLines;
      var ret = _.find(item.ShippingLines, function (o) { return o.value == item.LegalEntityId; });
      if (ret) {
        item.selectedshippingLine = ret.value;
      }
    });
  };

  async AddShippingLine() {
    this.showAddShippingCompany = false;
    this.showLoadingSpinner = true;
    try {
      const retval = await this.api.addShippingCompany(this.shippingLineName, this.selectedNewShippingLine.value);
      if (retval > 0) {
        this.vesselCompany = await this.api.getVesselCompany();
        this.addDropdownsAndSetDefault();
        this.messageService.add({ severity: 'info', summary: 'Added', detail: 'Shipping Company Added' });
      } else {
        this.messageService.add({ severity: 'eror', summary: 'Error', detail: 'Shipping Compnay Already Exists' });
      }
    } catch (error) {
      this.showError('Error Adding New Shipping Company', error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  async ShowVessels(rowData: any) {
    this.selectedShippingLine = rowData;
    this.shippingLineVessels = await this.api.getShippingLineVessels(rowData.Id);
    this.scrollToBottom();
  }

  async DeleteShippingLine(rowData: any) {
    this.selectedShippingLine = rowData;
    if (rowData.VesselCount == 0) {
      this.showDeleteShipplinLine = true;
    } else {
      this.showReassignVessels = true;
    }
  }

  async ReassignVesselsAndDelete() {
    this.showLoadingSpinner = true;
    var retval = await this.api.reassignShippingCompanyVessels(this.selectedShippingLine.Id, this.selectedReassignToShippingLine.value);
    if (retval) {
      await this.deleteShippingCompany();
    } else {
      this.messageService.add({ severity: 'eror', summary: 'Error', detail: 'Vessels could not be reassigned' });
    }
    this.showLoadingSpinner = false;
  }

  async deleteShippingCompany() {
    this.showLoadingSpinner = true;
    try {
      const retval = await this.api.deleteShippingCompany(this.selectedShippingLine.Id);
      if (retval) {
        this.vesselCompany = await this.api.getVesselCompany();
        this.addDropdownsAndSetDefault();
        this.showDeleteShipplinLine = false;
        this.messageService.add({ severity: 'info', summary: 'Deleted', detail: 'Shipping Company Deleted' });
      } else {
        this.messageService.add({ severity: 'eror', summary: 'Error', detail: 'Shipping Company Not Deleted' });
      }
    } catch (error) {
      this.showError('Error Deleting Shipping Company', error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  async onSelectedShippingLine(rowData: any) {
    try {
      await this.api.changeLegalEntityCompanyTypeId(rowData.Id, rowData.selectedshippingLine);
      this.vesselCompany = await this.api.getVesselCompany();
      this.addDropdownsAndSetDefault();
      this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'Shipping Company assigned' });
    } catch (error) {
      this.showError('Error Assigning Legal Entity Company Type ID (onSelectedVesselCompany())', error);
      this.log.error(error);
    }
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: ' + this.name + '<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: Manage Shipping Lines';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      }
    });
  }

  public scrollToBottom() {
    setTimeout(() =>
      window.scroll({
        top: document.body.scrollHeight - window.innerHeight,
        behavior: "smooth",
      }), 105
    );
  }
}