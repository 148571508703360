import { Injectable } from '@angular/core';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";
import { HttpResponse } from '../../interfaces/result-interfaces';
import { Groups } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class TaskApiService {

  private serverUrl = `${this.config.getServerUrl()}api/tasks/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/tasks/GetAndFilterAllFilesWithAssignedUsers
  // Controller Name: TaskController.cs
  // Procs Name: pr_GetAndFilterAllFilesWithAssignedUsers
  public async getAndFilterAllFilesWithAssignedUsers(legalEntityId?: number, groupId?: Groups): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetAndFilterAllFilesWithAssignedUsers?LegalEntityId=${legalEntityId}&GroupId=${groupId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async resetRouteForFile(fileId: number, resetReason: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}ResetRouteForFile?fileId=${fileId}&reason=${resetReason}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async closeFile(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}closeFile?FileId=${fileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async openFile(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}openFile?FileId=${fileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}