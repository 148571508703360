<div class="ui-g-12 center" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && fileDetails" class="ui-g card card-w-title">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 file-number-header">
      <h3 *ngIf="fileDetails.clearingStatusId == clearingStatusType.HANDOVER">Handover Details For </h3>
      <h3 *ngIf="fileDetails.clearingStatusId == clearingStatusType.PARTIAL_HANDOVER">Partial Handover Details For </h3>
      <file-number [fileNumber]="fileDetails.dbnNumber"></file-number>
    </div>
  </div>
  <div class="ui-g-12">
    <p-panel [style]="{'height':'222px'}">
      <p-header>
        Client Information
      </p-header>
      <div class="ui-g-4">
        <div class="client-information">
          <strong>Name</strong>
          <label>
            {{ fileDetails.name || 'N/A' }}
          </label>
          <strong>Last Name</strong>
          <label>
            {{ fileDetails.lastName || 'N/A' }}
          </label>
          <strong>Email</strong>
          <label>
            {{ fileDetails.email || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="client-information">
          <strong>Address</strong>
          <label>
            {{ fileDetails.address1 || 'N/A' }}
            <a *ngIf="!fileDetails.address1">
              (Address 1)
            </a>
          </label>
          <label>
            {{ fileDetails.address2 || 'N/A' }}
            <a *ngIf="!fileDetails.address2">
              (Address 2)
            </a>
          </label>
          <label>
            {{ fileDetails.address3 || 'N/A' }}
            <a *ngIf="!fileDetails.address3">
              (Address 3)
            </a>
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="client-information">
          <strong>City</strong>
          <label>
            {{ fileDetails.city || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="margin-top-5px">
      <app-notes [fileId]="fileId"></app-notes>
    </div>

    <div class="margin-top-5px">
      <p-panel class="ui-g-6 ui-g-nopad" [style]="{'height':'250px'}">
        <p-header>
          Vehicle Details
        </p-header>

        <div class="ui-g-4">
          <div class="client-information">
            <strong>Make & Model</strong>
            <label>
              {{ fileDetails.makeModel || 'N/A' }}
            </label>

            <strong>Chassis #</strong>
            <label>
              {{ fileDetails.chassisNo || 'N/A' }}
            </label>
          </div>
        </div>

        <div class="ui-g-4">
          <div class="client-information">
            <strong>Length</strong>
            <label>
              {{ fileDetails.length || 'N/A' }} cm
            </label>

            <strong>Height</strong>
            <label>
              {{ fileDetails.height || 'N/A' }} cm
            </label>

            <strong>Width</strong>
            <label>
              {{ fileDetails.width || 'N/A' }} cm
            </label>
          </div>
        </div>

        <div class="ui-g-4">
          <div class="client-information">
            <strong>Weight</strong>
            <label>
              {{ fileDetails.weight || 'N/A' }} kg
            </label>
          </div>
        </div>
      </p-panel>

      <p-panel class="ui-g-6 ui-g-nopad" [style]="{'height':'250px', 'margin-left': '5px'}">
        <p-header>
          Clearing Agent Details
        </p-header>
        <div class="ui-g">
          <div class="ui-g-4">
            <strong>Clearing Agent:</strong>
          </div>
          <div class="ui-g-8">
            <p-dropdown [options]="clearingAgents" [style]="{'width':'100%'}" [autoDisplayFirst]="false"
              optionValue="value" optionLabel="label" [(ngModel)]="selectedClearingAgent" (onChange)="getStaff()"
              [filter]="true">
            </p-dropdown>
          </div>
          <div class="ui-g-4">
            <strong>Communicate With:</strong>
          </div>
          <div class="ui-g-8">
            <p-multiSelect [options]="contacts" [(ngModel)]="selectedContacts" multiple="multiple" checkbox="checkbox"
              [disabled]="!selectedClearingAgent" [style]="{'width':'100%'}" optionLabel="label">
            </p-multiSelect>
          </div>
          <button class="width-100" *ngIf="!clearingAgentSpinner" pRipple pButton type="button"
            label="Assign Clearing Agent" [disabled]="!selectedContacts || !selectedClearingAgent"
            (click)="addUpdateClearingAgent()"></button>
          <button *ngIf="clearingAgentSpinner" pRipple class="p-button-info width-100" pButton type="button">
            <i class="pi pi-spin pi-spinner font-size-2em"></i>
          </button>
        </div>
      </p-panel>
    </div>
  </div>
</div>