
import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { LogBase } from '../logger.service';
import { WebApi2Service } from '../web.api2.service';
import { HttpResponse } from '../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class FilterApiService {
  
  private serverUrl = `${this.config.getServerUrl()}api/filter/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/filter/FilterFilesByFileIds
  // Controller Name: FilterController.cs
  // Proc Name: pr_FilterFilesByFileIds
  public async FilterFilesByFileIds(fileIds: string, clearingStatusId: string, fileStatus: string, ruleSet: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}FilterFilesByFileIds?fileIds=${fileIds}&clearingStatusId=${clearingStatusId}&fileStatus=${fileStatus}&ruleSetId=${ruleSet}`; 
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/FilterFilesByMasterRouteIdAndOrRouteId
  // Controller Name: FilterController.cs
  // Proc Name: pr_FilterFilesByMasterRouteIdAndOrRouteId
  public async FilterFilesByMasterRouteIdAndOrRouteId(masterRouteId: number, clearingStatusId: string, fileStatus: string, ruleSetId: number, routeLegId?: number, typeId?: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}FilterFilesByMasterRouteIdAndOrRouteId?masterRouteId=${masterRouteId}&clearingStatusId=${clearingStatusId}&routeLegId=${routeLegId}&typeId=${typeId}&fileStatus=${fileStatus}&RuleSetId=${ruleSetId}`
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/FilterFilesByVesselIdAndOrVoyageIdAndOrMBL
  // Controller Name: FilterController.cs
  // Proc Name: pr_FilterFilesByVesselIdAndOrVoyageIdAndOrMBL
  public async FilterFilesByVesselIdAndOrVoyageIdAndOrMBL(vesselId: number, clearingStatusId: string, fileStatus: string, ruleSetId: number, voyageId?: number, mbl?: string[]): Promise<HttpResponse | null> {
    try {
      const json: any = {
        vesselId: vesselId,
        clearingStatusId: clearingStatusId,
        fileStatus: fileStatus,
        voyageId: voyageId,
        mbl: mbl,
        ruleSetId: ruleSetId
      }
      const url = `${this.serverUrl}FilterFilesByVesselIdAndOrVoyageIdAndOrMBL`;
      return await this.webApi.post(null, url, json);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/FilterFilesByStoragePoint
  // Controller Name: FilterController.cs
  // Proc Name: pr_FilterFilesByStoragePoint
  public async FilterFilesByStoragePoint(storagePointId: number, clearingStatusId: string, fileStatus: string, ruleSetId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}FilterFilesByStoragePoint?storagePointId=${storagePointId}&clearingStatusId=${clearingStatusId}&fileStatus=${fileStatus}&RuleSetId=${ruleSetId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/FilterGetClearingStatusTypes
  // Controller Name: FilterController.cs
  // Proc Name: pr_FilterGetClearingStatusTypes
  public async FilterGetClearingStatusTypes(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}FilterGetClearingStatusTypes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/FilterFilesByCurrentLocation
  // Controller Name: FilterController.cs
  // Proc Name: pr_FilterFilesByCurrentLocation
  public async FilterFilesByCurrentLocation(locationId: number, clearingStatusId: string, fileStatus: string, ruleSetId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}FilterFilesByCurrentLocation?LocationId=${locationId}&clearingStatusId=${clearingStatusId}&fileStatus=${fileStatus}&RuleSetId=${ruleSetId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}
