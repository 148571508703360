import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng';
import * as _ from 'lodash';
import { LogBase } from '../../../../services/logger.service';
import { FinanceUserTaskApiService } from '../../../../services/api/user-tasks/finance-user-task-api.service';
import { FinanceSummary, AllocatePaymentPost, Currency } from '../../../../interfaces/finance-interfaces';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FinanceHelperService } from '../../../../services/finance-helper.service';
import { CurrencyApiService } from '../../../../services/api/finance/currency-api.service';

@Component({
  selector: 'app-allocate-payment',
  templateUrl: './allocate-payment.component.html',
  styleUrls: ['./allocate-payment.component.scss']
})
export class AllocatePaymentComponent implements OnInit {

  fileId: number = 0;
  selectedInvoiceIndex: number = 0;
  allocatePayment: FinanceSummary | null = null;
  currencies: Currency[] = [];
  selectedCurrency: Currency | undefined;
  allocatePaymentPost: AllocatePaymentPost = { fileId: 0, paymentAmount: null, paymentDate: null, bankAccount: '', currencyId: 0 };

  invoiceNumber: string = '';
  currencyCode: string = '';
  currencySymbol: string = '';
  showFail: boolean = false;
  showCurrencySymbol: boolean = false;
  showSpinner: boolean = false;
  postSpinner: boolean = false;
  viewInvoiceLineItem: boolean = false;

  constructor(
    private financeUserTaskApi: FinanceUserTaskApiService,
    private log: LogBase,
    private route: ActivatedRoute,
    private currencyApi: CurrencyApiService,
    private router: Router,
    private messageService: MessageService,
    public financeHelper: FinanceHelperService
  ) { }

  async ngOnInit() {
    this.getRouteParams();
    if (this.fileId == 0) { this.toastMessage('error', 'Could not find invoices with that file id.', ''); return; }
    this.allocatePaymentPost.fileId = this.fileId;
    this.showSpinner = true;
    await this.getAllocatePaymentUserTask();
    await this.getCurrencies();
    this.checkIfThereAreResults();
    this.showSpinner = false;
  }

  private async getAllocatePaymentUserTask() {
    try {
      const retval = await this.financeUserTaskApi.GetAllocatePaymentUserTask(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to get invoices for file. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.allocatePayment = retval.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  private async getCurrencies() {
    try {
      const retval = await this.currencyApi.getLocalCurrencies();
      if (!retval) this.toastMessage('error', 'Failed to get currencies. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.currencies = retval.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  public async postAllocatePayment() {
    this.postSpinner = true;
    try {
      const retval = await this.financeUserTaskApi.AllocatePaymentUserTask(this.allocatePaymentPost);
      if (!retval) this.toastMessage('error', 'Failed to allocate payment for file. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
      }
    } catch (err) {
      this.log.error(err);
    }
    this.postSpinner = false;
  }

  public viewLineItems(rowIndex: number) {
    this.selectedInvoiceIndex = rowIndex;
    this.invoiceNumber = `I/${this.allocatePayment.invoices[rowIndex].invoiceId.toString().padStart(7, '0')}`;
    this.viewInvoiceLineItem = true;
  }

  public onPaymentAllocateValidate() {
    if (!Number(this.allocatePaymentPost.paymentAmount)) this.allocatePaymentPost.paymentAmount = 0;
    else if (this.allocatePaymentPost.paymentAmount > 1000000) this.allocatePaymentPost.paymentAmount = 1000000;
    else if (this.allocatePaymentPost.paymentAmount < 0) this.allocatePaymentPost.paymentAmount = -this.allocatePaymentPost.paymentAmount;
  }

  public disableAllocatePayment() {
    if (!this.allocatePaymentPost.paymentAmount || !this.allocatePaymentPost.paymentDate || !this.allocatePaymentPost.bankAccount || !this.selectedCurrency) return true;
    else if (this.postSpinner) return true;
    return false;
  }
  private checkIfThereAreResults() {
    if (!this.allocatePayment.fileStatus || !this.allocatePayment.legalEntity || this.allocatePayment.invoices.length == 0) this.showFail = true;
  }
  public onCurrencySelect() { this.allocatePaymentPost.currencyId = this.selectedCurrency.id; }
  public formatDate(date) { return moment(date).format('yy/MM/DD'); }
  public formatAmountPaid(amountPaid: number): string { return Math.abs(amountPaid).toFixed(2); }
  public onDatePaidDateSelect() { this.allocatePaymentPost.paymentDate = moment(this.allocatePaymentPost.paymentDate).format('yy/MM/DD'); }
  private getRouteParams() { var fileId = this.route.snapshot.paramMap.get('fileId'); this.fileId = Number(fileId) || 0; }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}