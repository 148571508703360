<div class="ui-g" *ngIf="componentViewType == 0">
    <div class="ui-g-4">
        <p-dropdown [options]="locationTypes" [(ngModel)]="selectedlocationtype" placeholder="Location Type"
            appendTo="body" optionLabel="description" (onChange)="onLocationTypeChange()" [disabled]="DisableDropDown"
            [filter]="true" [style]="{'min-width':'100%', 'width': '100%'}">
        </p-dropdown>
    </div>
    <div class="ui-g-8 padding-top-12px">
        <p-autoComplete [(ngModel)]="selectedlocation" [ngModelOptions]="{standalone: true}" appendTo="body"
            (onClear)="clearLocations()" placeholder="{{LocationLabel}}" [style]="{'width':'100%'}" minLength="2"
            [inputStyle]="{'width':'100%'}" field="Location" [suggestions]="locations" (completeMethod)="search($event)"
            (onSelect)="SetSelectedLocation($event)" [disabled]="checkDisable()">
        </p-autoComplete>
    </div>
</div>

<div class="ui-g" *ngIf="componentViewType > 0 && !ShowFloatLabel">
    <p-autoComplete [ngModelOptions]="{standalone: true}" placeholder="{{LocationLabel}}" [style]="{'width':'100%'}"
        (onClear)="clearLocation()" [inputStyle]="{'width':'100%'}" [(ngModel)]="selectedlocation" field="Location"
        [suggestions]="locations" (completeMethod)="search($event)" (onSelect)="setComponentViewTypeLocation($event)"
        [disabled]="checkDisable()">
    </p-autoComplete>
</div>

<div class="ui-g" *ngIf="componentViewType > 0 && ShowFloatLabel">
    <span class="ui-g-12 ui-float-label">
        <p-autoComplete [ngModelOptions]="{standalone: true}" [style]="{'width':'100%'}" (onClear)="clearLocation()"
            [inputStyle]="{'width':'100%'}" [(ngModel)]="selectedlocation" field="Location" [suggestions]="locations"
            (completeMethod)="search($event)" (onSelect)="setComponentViewTypeLocation($event)"
            [disabled]="checkDisable()">
        </p-autoComplete>
        <label class="pad-left" for="float-input">{{FloatLabel}}</label>
    </span>
</div>