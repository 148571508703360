<p-panel header="FILTER :" [style]="{ height: 'auto' }">
  <p-tabView [activeIndex]="data.selectedTabIndex" (onChange)="onTabSelect($event.index)">
    <p-tabPanel header="File Ids">
      <div class="ui-g" *ngIf="!showSpinnerForTab">
        <div class="ui-g-12">
          <h4>By File Id(s)</h4>
          <div class="ui-g-6">
            <span class="ui-float-label padding-top-4px">
              <p-chips [(ngModel)]="data.fileIds" separator="," (onAdd)="onAddFileId($event)" [allowDuplicate]="false"
                [style]="{'width': '100%'}">
              </p-chips>
            </span>
          </div>
          <div class="ui-g-3" *ngIf="ruleSetId == 0">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.clearingTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" " [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3" *ngIf="ruleSetId == 1">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [resetFilterOnHide]="true" [disabled]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel="AVECS"
                [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.openTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedFileStatus" optionLabel="text" defaultLabel="Open" [filter]="true"
                [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">File Status</label>
            </span>
          </div>
          <div class="ui-g-12">
            <button pRipple pButton [disabled]="!data.fileIds || data.fileIds.length == 0" label="Clear All"
              (click)="data.fileIds = null" class="width-100 "></button>
            <button pRipple pButton icon="fas fa-filter"
              [disabled]="!data.fileIds || data.fileIds.length == 0 || data.selectedClearingStatusId.length == 0 || data.selectedFileStatus.length == 0"
              label="Filter File Id(s)" (click)="filterFiles('filterFilesByFileIds');"
              class="width-100 margin-top-1em"></button>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Shipping">
      <div class="ui-g" *ngIf="!showSpinnerForTab">
        <div class="ui-g-12">
          <h4>By Vessel/Voyage/MBL</h4>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-dropdown [options]="data.vessels" optionLabel="name" [filter]="true" [(ngModel)]="data.selectedVessel"
                [style]="{'width': '100%'}" [showClear]="true" [autoDisplayFirst]="false" (onChange)="data.getVoyage()">
              </p-dropdown>
              <label class="pad-left" for="float-input">Vessel</label>
            </span>
          </div>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-dropdown [options]="data.voyages" optionLabel="name" [filter]="true" [(ngModel)]="data.selectedVoyage"
                [style]="{'width': '100%'}" [showClear]="true" [autoDisplayFirst]="false"
                (onChange)="data.getAssignedMBLNumbers()" [disabled]="!data.selectedVessel">
              </p-dropdown>
              <label class="pad-left" for="float-input">Voyage</label>
            </span>
          </div>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.assignedMBLNumbers" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedMBLNumbers" optionLabel="MBL" [disabled]="!data.selectedVoyage"
                defaultLabel=" " [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">MBL</label>
            </span>
          </div>
          <div class="ui-g-3" *ngIf="ruleSetId == 0">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.clearingTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" " [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3" *ngIf="ruleSetId == 1">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [resetFilterOnHide]="true" [disabled]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel="AVECS"
                [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.openTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedFileStatus" optionLabel="text" defaultLabel="Open" [filter]="true"
                [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">File Status</label>
            </span>
          </div>
          <div class="ui-g-12">
            <button pRipple pButton
              [disabled]="!data.selectedVessel || data.selectedClearingStatusId.length == 0 || data.selectedFileStatus.length == 0"
              label="Filter Vessel" icon="fas fa-filter" (click)="filterFiles('filterFilesByShipping');"
              class="width-100"></button>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Routes">
      <div class="ui-g" *ngIf="!showSpinnerForTab">
        <div class="ui-g-12">
          <h4>By Routes</h4>
          <div class="ui-g-12">
            <span class="ui-g-12 ui-float-label">
              <p-dropdown [options]="data.masterRoutes" [filter]="true" [(ngModel)]="data.selectedMasterRoute"
                optionLabel="route" [style]="{'width': '100%'}" [autoDisplayFirst]="false" [showClear]="true"
                (onChange)="data.getMasterRouteLegs()">
              </p-dropdown>
              <label class="pad-left" for="float-input">Master Route</label>
            </span>
          </div>
          <div class="ui-g-6 margin-top-1em">
            <span class="ui-g-12 ui-float-label">
              <p-dropdown [options]="data.masterRouteLegs" [filter]="true" [(ngModel)]="data.selectedRouteLeg"
                optionLabel="route" [style]="{'width': '100%'}" [disabled]="!data.selectedMasterRoute"
                [autoDisplayFirst]="false" [showClear]="true">
              </p-dropdown>
              <label class="pad-left" for="float-input">Route Leg</label>
            </span>
          </div>
          <div class="ui-g-3 margin-top-1em" *ngIf="ruleSetId == 0">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.clearingTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" " [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3 margin-top-1em" *ngIf="ruleSetId == 1">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [resetFilterOnHide]="true" [disabled]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel="AVECS"
                [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3 margin-top-1em">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.openTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedFileStatus" optionLabel="text" defaultLabel="Open" [filter]="true"
                [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">File Status</label>
            </span>
          </div>
          <div class="ui-g-12">
            <button pRipple pButton
              [disabled]="!data.selectedMasterRoute || data.selectedClearingStatusId.length == 0 || data.selectedFileStatus.length == 0"
              label="Filter Route" icon="fas fa-filter" (click)="filterFiles('filterFilesByRoute')"
              class="width-100"></button>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Storage Point">
      <div class="ui-g" *ngIf="!showSpinnerForTab">
        <div class="ui-g-12">
          <h4>By Storage Point</h4>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-dropdown [options]="data.storagePoints" [(ngModel)]="data.selectedStoragePoint" optionLabel="label"
                [autoDisplayFirst]="false" [style]="{'width':'100%'}" [filter]="true" [showClear]="true">
              </p-dropdown>
              <label class="pad-left" for="float-input">Storage Point</label>
            </span>
          </div>
          <div class="ui-g-3" *ngIf="ruleSetId == 0">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.clearingTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" " [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3" *ngIf="ruleSetId == 1">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" AVECS"
                [filter]="true" [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-3">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [resetFilterOnHide]="true" [(ngModel)]="data.selectedFileStatus"
                optionLabel="text" defaultLabel="Open" [filter]="true" [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">File Status</label>
            </span>
          </div>
          <div class="ui-g-12">
            <button pRipple pButton
              [disabled]="!data.selectedStoragePoint || data.selectedClearingStatusId.length == 0 || data.selectedFileStatus.length == 0"
              label="Filter Storage Point" icon="fas fa-filter" (click)="filterFiles('filterFilesByStoragePoint')"
              class="width-100"></button>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Current Location">
      <div class="ui-g" *ngIf="!showSpinnerForTab">
        <div class="ui-g-12">
          <h4>By Current Location</h4>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-dropdown [options]="data.locationTypes" [filter]="true" [(ngModel)]="data.selectedLocationType"
                optionLabel="description" [style]="{'width': '100%'}" [showClear]="true" [autoDisplayFirst]="false"
                (onChange)="data.setLocationType()">
              </p-dropdown>
              <label class="pad-left" for="float-input">Location type</label>
            </span>
          </div>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-autoComplete [ngModelOptions]="{standalone: true}" [style]="{'width':'100%', 'padding-top': '7px'}"
                [inputStyle]="{'width':'100%'}" [(ngModel)]="data.selectedLocation" field="Location"
                [suggestions]="data.locations" (completeMethod)="data.searchLocations($event)"
                [disabled]="!data.selectedLocationType">
              </p-autoComplete>
              <label class="pad-left" for="float-input">{{data.locationLabel}}</label>
            </span>
          </div>
          <!-- <div class="ui-g-6"> -->
          <!-- Leaving a gap here so it looks nicer -->
          <!-- </div> -->
          <div class="ui-g-6" *ngIf="ruleSetId == 0">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.clearingTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" " [filter]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-6" *ngIf="ruleSetId == 1">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedClearingStatusId" optionLabel="clearingType" defaultLabel=" AVECS"
                [filter]="true" [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">Clearing Type</label>
            </span>
          </div>
          <div class="ui-g-6">
            <span class="ui-g-12 ui-float-label">
              <p-multiSelect inputId="multiselect" [options]="data.openTypes" [resetFilterOnHide]="true"
                [(ngModel)]="data.selectedFileStatus" optionLabel="text" defaultLabel="Open" [filter]="true"
                [disabled]="true">
              </p-multiSelect>
              <label class="pad-left" for="float-input">File Status</label>
            </span>
          </div>
          <div class="ui-g-12">
            <button pRipple pButton
              [disabled]="!data.selectedLocation?.Id || data.selectedClearingStatusId.length == 0 || data.selectedFileStatus.length == 0"
              label="Filter By Current Location" icon="fas fa-filter"
              (click)="filterFiles('filterFilesByCurrentLocation');" class="width-100"></button>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <div class="overlay" *ngIf="showSpinnerForTab || data.waitForResults"> </div>
  <div class="progress-spinner" *ngIf="showSpinnerForTab">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
    </p-progressSpinner>
  </div>
  <div class="progress-bar" *ngIf="data.waitForResults">
    <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
  </div>
</p-panel>

<div class="ui-g-12" *ngIf="renderTable">
  <h2>All Files Filtered By {{data.filteredFilesBy}}</h2>
  <p-table #dt [value]="data.filteredFiles" dataKey="dbnNumber" [(selection)]="data.selectedFiles" [rows]="50"
    [resizableColumns]="true" [responsive]="true" [columns]="cols" [showCurrentPageReport]="true" [customSort]="true"
    [rowsPerPageOptions]="[10,25,50]" [paginator]="true" class="paginator" (sortFunction)="sortFilteredTable($event)"
    [globalFilterFields]="['dbnNumber','currentlyAt', 'vesselName', 'voyageNumber', 'route', 'boderPostDutiesPaid']"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left selected-files">
          <label>Selected {{data.selectedFiles ? data.selectedFiles.length : 0}} of
            {{dt ? dt.totalRecords : 0}} files</label>
        </span>
        <span class="p-input-icon-left p-ml-auto">
          <i style="color: white;" class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword" style="color: #fff !important;" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr class="table-labels text-center">
        <th pSortableColumn="selectedFiles" width="80px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          <p-sortIcon field="selectedFiles"></p-sortIcon>
        </th>
        <th *ngFor="let col of columns" width="{{ col.width }}" pSortableColumn="{{ col.field }}">
          {{ col.header }}
          <p-sortIcon field="{{ col.field }}"> </p-sortIcon>
        </th>
        <th width="80px">Actions</th>
      </tr>
      <tr class="table-labels">
        <th></th>
        <th>
          <input [(ngModel)]="c1" pInputText class="width-100" type="text"
            (input)="dt.filter($event.target.value, 'dbnNumber', 'contains')">
        </th>
        <th>
          <input [(ngModel)]="c2" pInputText class="width-100" type="text"
            (input)="dt.filter($event.target.value, 'cargoDescription', 'contains')">
        </th>
        <th>
          <input [(ngModel)]="c3" pInputText class="width-100" type="text"
            (input)="dt.filter($event.target.value, 'vesselName', 'contains')">
        </th>
        <th>
          <input [(ngModel)]="c4" pInputText class="width-100" type="text"
            (input)="dt.filter($event.target.value, 'voyageNumber', 'contains')">
        </th>
        <th>
          <p-multiSelect [options]="data.uniqueMasterRoutes" defaultLabel=" " appendTo="body"
            (onChange)="dt.filter($event.value, 'route', 'in')"></p-multiSelect>
        </th>
        <th>
          <p-multiSelect [options]="data.uniqueCurrentlyAt" [(ngModel)]="data.SelectedUniqueCurrentlyAt"
            defaultLabel=" " appendTo="body" (onChange)="dt.filter($event.value, 'currentlyAt', 'in'); ">
          </p-multiSelect>
        </th>
        <th>
          <p-dropdown [style]="{'width': '100%'}" [options]="[{label: 'Yes'}, {label: 'No'}]" [autoDisplayFirst]="false"
            [showClear]="true" appendTo="body" optionLabel="label" optionValue="label"
            (onChange)="filterColumn($event, 'borderDutiesPaid', 'contains')">
          </p-dropdown>
        </th>
        <th>
          <p-dropdown [style]="{'width': '100%'}" [options]="[{label: 'Yes'}, {label: 'No'}]" [autoDisplayFirst]="false"
            [showClear]="true" appendTo="body" optionLabel="label" optionValue="label"
            (onChange)="filterColumn($event, 'borderDutiesUploaded', 'contains')">
          </p-dropdown>
        </th>
        <th>
          <p-dropdown [style]="{'width': '100%'}" [options]="[{label: 'CIF'}, {label: 'NON CIF'}]"
            [autoDisplayFirst]="false" [showClear]="true" appendTo="body" optionLabel="label" optionValue="label"
            (onChange)="filterColumn($event, 'cif', 'equals')">
          </p-dropdown>
        </th>
        <th>
          <input [(ngModel)]="c5" pInputText class="width-100" type="text"
            (input)="dt.filter($event.target.value, 'amountDue', 'contains')">
        </th>
        <th>
          <p-dropdown [style]="{'width': '100%'}" class="borderDropdown"
            [options]="[{label: 'Open'}, {label: 'Closed'}]" [autoDisplayFirst]="false" [showClear]="true"
            appendTo="body" optionLabel="label" optionValue="label"
            (onChange)="filterColumn($event, 'fileStatus', 'equals')">
          </p-dropdown>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr [pSelectableRow]="item">
        <td width="80px">
          <p-tableCheckbox [value]="item"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" width="{{ col.width }}">
          {{ item[col.field] }}
        </td>
        <td style="text-align: center;">
          <button pRipple class="p-button-info" pButton type="button" (click)="viewFile(item.fileId)"
            pTooltip="View {{item.dbnNumber}}" tooltipPosition="left" icon="far fa-folder"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td>No files found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Dynamic Dialog Table that can be used for files that failed or even warnings  -->
<p-dialog *ngIf="data.failedFiles && data.failedFiles.length > 0" [(visible)]="data.showErrorDialog" [modal]="true"
  (onHide)="onFailedFilesClose()" [draggable]="false" [style]="{width: '60vw', height: 'auto'}">
  <p-header>
    {{data.errorDialogTitle}}
  </p-header>

  <div class="ui-g-12">
    <p-table #dt [value]="data.failedFiles" dataKey="dbnNumber" [rows]="100" [autoLayout]="true"
      [showCurrentPageReport]="true" [globalFilterFields]="['dbnNumber','failReason']" [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search" style="color: #fff !important;"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="table-labels text-center">
          <th>
            DBN Number
          </th>
          <th>
            Fail Reason
          </th>
          <th>
            Warning
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="table-labels"
          [ngStyle]="{'background-color': item.isFailure == 1 ? '#ff5b5b' : (item.isFailure == 2 ? '#3abfff' : white) }">
          <td>{{item.dbnNumber}}</td>
          <td>{{item.failReason}}</td>
          <td>{{item.warning}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>No files found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="ui-g-12">
    <label>*Please note, the successful file(s) have been unselected and only the failed file(s) are
      still selected.</label>
  </div>
</p-dialog>