import { LogBase } from './../../../../services/logger.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { WhatsappUserTaskApiService } from '../../../../services/api/user-tasks/whatsapp-user-task-api.service';
import { WhatsAppTalkToAgentGeneral } from '../../../../interfaces/user-tasks/whatsapp-user-tasks.interfaces';

@Component({
  selector: 'app-whatsapp-talk-to-agent-general',
  templateUrl: './whatsapp-talk-to-agent-general.component.html',
  styleUrls: ['./whatsapp-talk-to-agent-general.component.scss']
})
export class WhatsappTalkToAgentGeneralComponent implements OnInit {

  chatId: number = 0;
  userTask: WhatsAppTalkToAgentGeneral;

  showSpinner: boolean = false;
  postSpinner: boolean = false;
  showDialog: boolean = false;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private log: LogBase, 
    private messageService: MessageService,
    private whatsappUserTaskApiService: WhatsappUserTaskApiService
    ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.showSpinner = true;
      this.chatId = params['chatId'];
      if (!this.chatId) {
        this.toastMessage('error', 'Failed to get the file. Please contact support.', '');
      } else {
        await this.getUserTask();
      }
      this.showSpinner = false;
    });
  }

  private async getUserTask(): Promise<void> {
    try {
      const response = await this.whatsappUserTaskApiService.GetWhatsAppTalkToAgentGeneralUserTask(this.chatId);
      if (!response) {
        this.toastMessage('error', 'Failed to set up page. Please contact support.', '');
      } else if (response.errorCode != 0) {
        this.toastMessage('error', response.errorMessage, '');
      } else {
        this.userTask = response.result;
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Completly failed to set up page. Please contact support.', '');
    }
  }

  public async completeUserTask() {
    this.postSpinner = true;
    try {
      const response = await this.whatsappUserTaskApiService.DoWhatsAppTalkToAgentGeneralUserTask(this.chatId, this.userTask.userTaskId);
      if (!response) {
        this.toastMessage('error', 'Failed to complete user task. Please contact support.', '');
      } else if (response.errorCode != 0) {
        this.toastMessage('error', response.errorMessage, '');
      } else {
        this.toastMessage('success', response.errorMessage, '');
        setTimeout(() => {
          this.router.navigate(['/task-list']);
        }, 1200);
      }
    } catch (err) {
      this.log.error(err);
    }
    this.postSpinner = false;
  }

  public goToWhatsapp() {
    window.open('/WhatsApp');
  }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }) }
}
