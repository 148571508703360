import { Component, Input } from '@angular/core';

@Component({
    selector: 'bracket-text',
    templateUrl: './brackets.component.html',
    styleUrls: ['./brackets.component.scss']
})
export class BracketsComponent {
    @Input('text') text: string = "";
    public getStringBlocks(str: string): any[] {
        if (!str) return [{ value: str, block: false }];
        let newArr: any[] = [];
        let blocks = str.toString().match(/\[([^\[\]])*\]/g);
        if (!blocks) return [{ value: str, block: false }];
        let lastIndex = 0;
        let lastLength = 0;
        for (let i = 0; i < blocks.length; i++) {
            let thisIndex = str.indexOf(blocks[i]);
            let thisLength = blocks[i].length;
            let strToAdd = str.slice(lastIndex + lastLength, thisIndex).trim();
            if (strToAdd.length > 0) newArr.push({ value: strToAdd, block: false });
            newArr.push({ value: blocks[i].replace(/[\[\]]/g, '').trim(), block: true });
            lastIndex = thisIndex;
            lastLength = thisLength;
        }
        newArr.push({ value: str.slice(lastIndex + lastLength).trim(), block: false });
        return newArr;
    }
    public getStringBrackets(str: string): any[] {
        if (!str) return [{ value: str, block: false }];
        if (str.toString().match(/.*\.(.){2,4}/g)) return [{ value: str, block: false }];
        let newArr: any[] = [];
        let brackets = str.toString().match(/\(([^\(\)])*\)/g);
        if (!brackets) return [{ value: str, block: false }];
        let lastIndex = 0;
        let lastLength = 0;
        for (let i = 0; i < brackets.length; i++) {
            let thisIndex = str.indexOf(brackets[i]);
            let thisLength = brackets[i].length;
            let strToAdd = str.slice(lastIndex + lastLength, thisIndex).trim();
            if (strToAdd.length > 0) newArr.push({ value: strToAdd, block: false });
            newArr.push({ value: brackets[i].replace(/[\(\)]/g, '').trim(), block: true });
            lastIndex = thisIndex;
            lastLength = thisLength;
        }
        newArr.push({ value: str.slice(lastIndex + lastLength).trim(), block: false });
        return newArr;
    }
}