<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<button pRipple class="p-button-info" pTooltip="Refresh" tooltipPosition="left" pButton type="button"
  style="float: right;" icon="fas fa-sync" (click)="refresh()"></button>

<p-tabView *ngIf="fileNumber && !showSpinner && chatMessages && chatMessages.length > 0">
  <p-tabPanel header="Messages" leftIcon="fas fa-comment">

    <p-card header="Filter Chat Dates">
      <div class="ui-g">
        <div class="ui-g-4">
          <label>Select a chat date</label>
        </div>
        <div class="ui-g-8">
          <p-dropdown [options]="chatMessageDates" [(ngModel)]="selectedDate" placeholder="Select a Date"
            optionLabel="date" (onChange)="getChatMessagesByDate()"></p-dropdown>
        </div>
      </div>

      <br>

      <div class="ui-g-12">
        <label class="ui-card-title">Chat Messages</label>
      </div>

      <div *ngFor="let m of selectedChatMessages; let i = index">
        <div class="header-chat" *ngIf="selectedDate.date == 'All' && i == 0">
          <label>{{formatDate(m.insertDate)}} - ChatId: {{m.chatId}} - Product Number: {{m.productNumber}}</label>
        </div>

        <div class="header-chat"
          *ngIf="selectedDate.date == 'All' && i != 0 && selectedChatMessages[i - 1].chatId != m.chatId">
          <label>{{formatDate(m.insertDate)}} - ChatId: {{m.chatId}}</label>
        </div>

        <div class="header-chat" *ngIf=" selectedDate.date !='All' && i == 0">
          <label>ChatId: {{m.chatId}}</label>
        </div>

        <div class="header-chat" *ngIf=" selectedDate.date !='All' && i != 0 && selectedChatMessages[i - 1].chatId
        !=m.chatId">
          <label>ChatId: {{m.chatId}}</label>
        </div>


        <div class="message" [ngClass]="{ 'inbound': m.direction == 0, 'outbound': m.direction == 1 }">
          <div class="message-box" [ngStyle]="{'color': m.undeliveredDate || m.error ? 'red' : 'black'}">
            <strong>{{ m.direction == 1 ? m.avecsUser : m.whatsappUser }}</strong>
            <pre>{{ m.body }}
          </pre>
            <div class="message-info">
              <span class="timedate">
                {{ formatTime(m.sendDate) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </p-tabPanel>

  <p-tabPanel *ngIf="chatMedia " header="Media" leftIcon="fas fa-file">
    <p-table [value]="chatMedia" [autoLayout]="true" [rows]="50" [paginator]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>
            File
          </th>
          <th>
            <b>Actions</b>
          </th>
        </tr>
      </ng-template>
      <ng-template let-rowData let-columns="columns" pTemplate="body">
        <tr>
          <td>
            {{rowData.filename}}
          </td>
          <td>
            <button pRipple class="p-button-info" pButton (click)="viewWAMediaPreview(rowData)" type="button"
              icon="fas fa-file" (onHide)="closeViewer()" pTooltip="View File" tooltipPosition="left"></button>
            <button pRipple class="p-button-info" type="button" (click)="downloadMedia(rowData)" pButton
              icon="fas fa-file-download" pTooltip="Download File" tooltipPosition="left"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No media found for {{fileNumber}}.</td>
        </tr>
      </ng-template>
    </p-table>
  </p-tabPanel>
</p-tabView>

<!-- Preview Chat media -->
<p-dialog header="Preview" [dismissableMask]="true" [positionTop]="60" *ngIf="viewMedia"
  [contentStyle]="{width: '800px', height: '550px'}" [responsive]="true" [draggable]="false"
  (onHide)="closeWAMediaPreview()" [(visible)]="viewMedia">
  <app-image-viewer class="imgView" [zoomInButton]="false" [showPDFOnlyOption]="false" [showOptions]="false"
    [fullscreen]="false" [zoomOutButton]="false" [rotate]="false" [images]="mediaToView" [idContainer]="'idOnHTML'"
    [loadOnInit]="true">
  </app-image-viewer>
</p-dialog>

<div *ngIf="fileNumber && !showSpinner && chatMessages && chatMessages.length == 0">
  <label>
    No Chat History for this file
  </label>
</div>