import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { TaskItem, } from "../../../interfaces/global.interfaces";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { LogBase } from "./../../../services/logger.service";
import { ConfirmationService } from "primeng";
import { MessageService } from "primeng/api";
import { ConfigService } from "../../../services/config.service";
import * as _ from "lodash";
import { Title } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { UserTaskApiService } from "../../../services/api/user-tasks/user-tasks-api.service";
import { UserTask } from "../../../interfaces/user-tasks/user-tasks.interfaces";
import { Groups } from "../../../enums";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { FileApiService } from "../../../services/api/file-api.service";

@Component({
  selector: "app-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
  providers: [ConfirmationService, DatePipe],
})
export class SearchResultComponent implements OnInit {
  user: string;
  searchQuery: any = "";
  fileResults: any = [];
  showQR: boolean[] = [];
  userTasks: UserTask[] = [];
  isAdmin: boolean = false;
  vCardInfo: any = {};
  showSpinner: boolean = true;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private log: LogBase,
    private userTaskApi: UserTaskApiService,
    private fileApiService: FileApiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private config: ConfigService,
    private titleService: Title,
    private datePipe: DatePipe,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Search Results");
  }

  async ngOnInit() {
    this.showSpinner = true;
    try {
      this.user = this.authService.getUsername;
      this.isAdmin = this.authService.canEdit(Groups.SYSTEM_ADMIN);
      this.getURLParams();
    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
      this.showSpinner = false;
    }
  }

  getURLParams() {
    try {
      this.route.queryParams.subscribe((params) => {
        this.searchQuery = params.query;
        if (this.searchQuery) {
          this.searchFilesAndTasks(this.searchQuery);
        }
        else {
          this.showSpinner = false;
        }
      });
    } catch (error) {
      this.log.error(error);
      this.showSpinner = false;
    }
  }

  public async searchFilesAndTasks(query: string) {
    this.showSpinner = true;
    try {
      //handle file search
      const fileResults = await this.api.getFilesByQuery(query);
      if (!fileResults) this.toastMessage('error', 'Failed to search. Please contact support.', '');
      else if (fileResults.errorCode === -2) this.toastMessage('warn', fileResults.errorMessage, '');
      else if (fileResults.errorCode !== 0) this.toastMessage('error', fileResults.errorMessage, '');
      else {
        this.fileResults = fileResults.result;
        for (const type of this.fileResults) {
          type.vCardData = await this.setupVCard(type.ClientId);
          type.ETA = this.datePipe.transform(type.ETA, "dd-MM-yyyy");
        }

        //handle user tasks
        let fileId: number = +query;
        if (query.includes('/')) fileId = +query.split('/')[1];

        const userTaskResults = await this.userTaskApi.GetUserTaskByFileId(fileId);
        if (!userTaskResults) this.toastMessage('error', 'Failed to get user tasks. Please contact support.', '');
        else if (userTaskResults.errorCode !== 0) this.toastMessage('error', userTaskResults.errorMessage, '');
        else this.userTasks = userTaskResults.result;
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to search. Please contact support.', '');
    }
    this.showSpinner = false;
  }

  fileType(number) {
    let prefix = number.split('/')[0];
    return isNaN(prefix) ? number.split('/')[0].toLowerCase() : "dbn";
  }

  async formatDate(taskItems: TaskItem[]) {
    for (var x = 0; x < taskItems.length; x++) {
      var item: TaskItem = taskItems[x];
      item.DisplayDate = this.datePipe.transform(item.Due, "dd/MM/yyyy");
      if (item.ETA) {
        item.DisplayETA = this.datePipe.transform(item.ETA, "dd/MM/yyyy");
      }
      let due = moment(item.Due).unix();
      let today = moment().unix();
      let diff = due - today;
      if (diff > 0) {
        item.Overdue = false;
      } else {
        item.Overdue = true;
      }
    }
  }

  async setupVCard(contactId: any) {
    this.vCardInfo = await this.api.getVCardInfo(contactId);
    const name = this.vCardInfo.Name || "",
      surname = this.vCardInfo.LastName || "",
      title = this.vCardInfo.Title || "",
      email = this.vCardInfo.Email || "",
      cell1 = this.vCardInfo.Cellphone || "",
      tel1 = this.vCardInfo.Telephone || "",
      addr1 = this.vCardInfo.Address1 || "",
      addr2 = this.vCardInfo.Address2 || "",
      city = this.vCardInfo.CityName || "",
      country = this.vCardInfo.Country || "";

    //sadly there has to be no spaces so the format of this return has to stay like this
    return `
            BEGIN:VCARD
            VERSION:3.0
            N:${surname};${name}
            FN:${name} ${surname}
            TITLE:${title}
            TEL;TYPE=CELL:${cell1}
            TEL;TYPE=VOICE,HOME,PREF:${tel1}
            ADR;TYPE=WORK:;;${addr1};${addr2};${city};;${country}
            EMAIL:${email}
            END:VCARD`;
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message:
        friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: Search Result <br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";
        var subject: string =
          "NovaDT Error Message - Component:  Search Result";
        var res = this.api.sendFreeTextEmail(
          this.config.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          -1,
          -1
        );
      },
      reject: () => { },
    });
  }

  consigneeRoute(file: any) {
    this.router.navigate([`/manage-legal-entity/${file.ClientId}/${file.LegalEntityTypeId}`]);
  }

  viewUserTask(userTask: UserTask) {
    let route = _.find(this.router.config, (x) => { return x.data?.userTaskTypes?.includes(userTask.userTaskTypeId); });
    let roles = route['data']['roles'];
    var userPermissions = this.authService.getEditPermissions();

    const found = userPermissions.some(r => roles.includes(r.id));
    if (found || roles.includes(Groups.ALL)) {
      window.open(userTask.route);
    }
    else {
      this.toastMessage('error', 'Permission Error', 'You do not have permission to do this task');
    }
  }

  formatETADate(date: Date) { return moment(date).format("DD/MM/YYYY"); }
  openFile(id: any) { this.searchQuery = ""; this.router.navigate(["/file-view"], { queryParams: { query: id } }); }
  toastMessage(severity, summary, detail) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
