import { LegalEntityApiService } from './../../../../services/api/legal-entity-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoiceApiService } from '../../../../services/api/finance/invoice-api.service';
import { LineItem, LineItemDescriptionOptions, Invoice, Currency, StorageInvoiceWarehoues } from '../../../../interfaces/finance-interfaces';
import { LogBase } from '../../../../services/logger.service';
import { ApiService } from '../../../../services/api.service';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng';
import { splitCamelCase as splitCamelCaseImport } from '../../../on-demand/any-view-table/dynamic-table/dynamic-table.component';
import * as _ from 'lodash';
import { CurrencyApiService } from '../../../../services/api/finance/currency-api.service';
import * as moment from 'moment';
import { FinanceHelperService } from '../../../../services/finance-helper.service';
import { InvoiceType } from '../../../../enums';
import { CargoItems } from '../../../../interfaces/filter-search-interfaces';
import { Dropdown } from '../../../../interfaces/global.interfaces';

@Component({
  selector: 'app-storage-form',
  templateUrl: './storage-form.component.html',
  styleUrls: ['./storage-form.component.scss']
})
export class StorageFormComponent implements OnInit {

   //inputs and outputs
  @Input() fileId: number = 0;
  @Input() defaultPayload: Invoice = null;
  @Input() postSpinner: boolean = false;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  //constants
  loadIcon: string = 'fas fa-sync';

  //currencies
  selectedCurrency: Currency;
  currencyRates: any;
  currencies: Currency[];

  //bank account
  bankAccounts: Dropdown[];
  selectedBankAccountOne: Dropdown;
  selectedBankAccountTwo: Dropdown;

  //invoice
  selectLegalEntityTo: Dropdown;
  selectedInvoicee: Dropdown;
  invoicees: Dropdown[];
  discountAmount: number = 0;
  effectiveDate: Date;
  vatRate: number = 0;
  cargoItems: CargoItems[] = [];
  lineItemDescriptionOptions: any[] = [];
  payload: Invoice = null;
  selectedLineItemDescriptions: any = { 0: this.getNewBlankLineItemDescription };
  lineItems: LineItem[];
  destination: string;
  warehouse: StorageInvoiceWarehoues[];
  warehouseCount: number;

  //component
  emailInvoicee: boolean = true;
  fileExists: boolean = false;
  showSpinner: boolean = false;
  showFileCargo: boolean = false;
  includeVAT: boolean = false;
  showCurrencySymbol: boolean = true;

  constructor(
    private invoiceApi: InvoiceApiService,
    private currencyApi: CurrencyApiService,
    private legalEntityApi: LegalEntityApiService,
    private api: ApiService,
    private log: LogBase,
    private messageService: MessageService,
    public titleService: Title,
    public financeHelper: FinanceHelperService
  ) { }

  get getPayload(): Invoice { return this.payload; }
  get getDefaultPayload(): Invoice { return this.defaultPayload; }
  get getNewBlankLineItem(): LineItem {
    return {
      amount: 0,
      netAmount: null,
      costingMatrixId: null,
      costingMatrixTypeId: null,
      description: '',
      pastelCode: '',
      quantity: 1
    }
  }
  get getIncludingVAT(): number { return (1 + (this.vatRate / 100)) }
  get getNewBlankLineItemDescription(): LineItemDescriptionOptions { return { label: '', value: '', costingMatrixId: null, costingMatrixTypeId: null }; }
  get getDiscount(): number { return this.discountAmount || 0.00; }
  get getVatAmount(): number { return this.includeVAT ? +((this.getInvoiceTotalExcludingVat) * (this.vatRate / 100)).toFixed(2) : 0.00 }
  get getInvoiceTotalExcludingVat(): number {
    let amount = _.sumBy(this.getPayload.lineItems, (o) => {
      return o.amount * o.quantity
    });
    return amount - this.getDiscount;
  }
  get getInvoiceTotalIncludingVat(): number { return this.getInvoiceTotalExcludingVat + this.getVatAmount }
  get getInvoiceModel(): Invoice {
    const invoice: Invoice = {
      accountId: 1,
      invoiceNumber: null,
      clearingAgentId: 0,
      clearingStatusId: 1,
      dbnNumber: '',
      genericId: null,
      genericTypeId: 5,
      totalExcVat: null,
      totalAmount: null,
      vatAmount: 0,
      vatRate: 0,
      discountAmount: 0,
      discountRate: 0,
      bankAccountOneId: null,
      bankAccountTwoId: null,
      currencyId: null,
      currencySymbol: null,
      legalEntityToId: null,
      effectiveDate: null,
      lineItems: [],
      removedLineItems: [],
      invoiceTypeId: 0,
      invoiceId: 0,
      currencyCode: '',
      date: undefined,
      invoicee: '',
      invoiceType: '',
      invoiceStatusId: 0,
      documentId: null,
      invoiceStatus: ''
    };
    return invoice;
  }
  set setDiscountAmount(value: number) {
    if (!value) this.discountAmount = 0;
    else if (value < 0) {
      this.discountAmount = -value;
    }
    else if (value >= this.getInvoiceTotalExcludingVat) {
      this.toastMessage('warn', 'Discount amount MUST be less than invoice total', '');
      this.discountAmount = 0;
    }
    else {
      this.discountAmount = value;
    }
  }
  set setBankAccountOne(value: number) { this.getPayload.bankAccountOneId = value; }
  set setBankAccountTwo(value: number) { this.getPayload.bankAccountTwoId = value; }

  async ngOnInit() {
    try {
      this.showSpinner = true;
      this.payload = this.getInvoiceModel;
      this.getPayload.genericId = this.getPayload.genericId || this.fileId;
      await this.getInvoiceSetupData();
      if (this.fileExists) {
        await this.getVATRate();
        await this.getCurrencyRates();
        await this.getCurrencies();
        await this.getCargoItems();
      }
      this.showSpinner = false;
    } catch (err) {
      console.log(err, 'ngOninit');
    }
  }

  async getInvoiceSetupData() {
    try {
      let retval = await this.invoiceApi.GetInvoiceSetUpData(this.fileId, InvoiceType.STORAGE);
      if (retval) {
        if (retval.errorCode == 0) {
          this.getPayload.dbnNumber = retval.result.dbnNumber;
          this.getPayload.clearingStatusId = retval.result.clearingStatusId;
          this.getPayload.clearingAgentId = retval.result.clearingAgentId;
          if (this.defaultPayload) {
            this.defaultPayload.clearingAgentId = retval.result.clearingAgentId;
            this.defaultPayload.removedLineItems = [];
          }
          this.bankAccounts = retval.result.bankAccounts;
          this.invoicees = retval.result.invoicees;
          this.currencies = retval.result.currencies;
          this.getPayload.currencyId = this.currencies[1].id;
          this.getPayload.currencySymbol = this.currencies[1].symbol;
          this.selectedCurrency = this.currencies[1];
          this.destination = retval.result.destination;
          this.warehouse = retval.result.fileWarehouses;
          this.fileExists = true;
          this.warehouseCount = retval.result.fileWarehouses.length;
          if (this.warehouseCount == 1) {
            await this.GetStorageLineItems(this.warehouse[0].warehouseId);
          } 
        } else {
          this.toastMessage('error', retval.errorMessage, '');
        }
      }
      else {
        this.toastMessage('error', 'Failed to set up page. Please contact support.', '');
      }
    }
    catch (err) {
      this.log.error(err);
      console.log(err)
    }
  }

  async getVATRate() {
    try {
      let retval = await this.api.getVATRate();
      if (retval) {
        this.vatRate = +retval;
      }
    } catch (err) {
      this.log.error(err)
    }
  }

  async getCargoItems() {
    try {
      let retval = await this.api.getFileCargo(this.fileId);
      if (retval) {
        for (var item of retval.Vehicles) {
          this.cargoItems.push(item)
        }
        for (var item of retval.ContainerItems) {
          this.cargoItems.push(item)
        }
      }
      else {
        this.toastMessage('error', 'Failed to view cargo item details', '');
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to view cargo item details', '');
      this.log.error(err)
    }
  }

  async GetStorageLineItems(WarehouseLocationId: number) {
    try {
      const retval = await this.invoiceApi.GetStorageLineItems(WarehouseLocationId);
      if (!retval) this.toastMessage('error', 'Failed to get storage line items. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.lineItems = retval.result.lineItems;
        this.lineItemDescriptionOptions = this.lineItems.map(r => { return { label: r.description, value: r.description }; });
        this.payload.invoiceTypeId = retval.result.invoiceTypeId;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async getCurrencies() {
    try {
      let retval = await this.currencyApi.getLocalCurrencies();
      if (retval) {
        this.currencies = retval.result;
        this.getPayload.currencyId = this.currencies[1].id;
        this.getPayload.currencySymbol = this.currencies[1].symbol;
        this.selectedCurrency = this.currencies[1];
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async getCurrencyRates() {
    try {
      let retval = await this.api.getInvoiceExchangeRates();
      if (retval) {
        this.currencyRates = retval.result;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async getLegalEntityDetails(selectedInvoicee: Dropdown) {
    try {
      let retval = await this.legalEntityApi.getLegalEntityById(selectedInvoicee.value);
      if (retval) {
        this.selectedInvoicee = {
          label: retval.name,
          value: retval.id
        }
        this.getPayload.legalEntityToId = selectedInvoicee.value;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  addLineItem() {
    if (this.getPayload.lineItems.length == this.lineItems.length) {
      this.toastMessage('warn', '', 'Maximum amount of line items.');
      return;
    }
    this.getPayload.lineItems.push(this.getNewBlankLineItem);
    this.selectedLineItemDescriptions[this.getPayload.lineItems.length - 1] = this.getNewBlankLineItemDescription;
  }

  onInvoiceItemAdd(value: string, rowIndex: number) {
    if (this.getPayload.lineItems.find(x => x.description == value)) {
      this.toastMessage('warn', 'Line item already exists.', '');
      this.getPayload.lineItems[rowIndex] = this.getNewBlankLineItem;
      this.selectedLineItemDescriptions[rowIndex] = this.getNewBlankLineItemDescription;
    }
    else {
      let lineItem = this.lineItems.find(x => x.description == value);
      this.selectedLineItemDescriptions[rowIndex] = {
        label: value,
        value: value,
        costingMatrixId: lineItem.costingMatrixId,
        costingMatrixTypeId: lineItem.costingMatrixTypeId
      };
      this.setLineItem(rowIndex, lineItem);
      if (this.getPayload.lineItems.length == 1) this.setDiscountAmount = this.discountAmount;
    }
  }

  setLineItem(rowIndex: number, lineItem: LineItem) {
    let netAmount = lineItem.amount;
    if (this.includeVAT) {
      netAmount = +((lineItem.amount * lineItem.quantity) * (this.getIncludingVAT)).toFixed(2);
    }

    this.getPayload.lineItems[rowIndex] =
    {
      id: this.getPayload.lineItems[rowIndex].id,
      amount: lineItem.amount,
      netAmount: netAmount,
      costingMatrixId: lineItem.costingMatrixId,
      costingMatrixTypeId: lineItem.costingMatrixTypeId,
      description: lineItem.description,
      pastelCode: lineItem.pastelCode,
      quantity: lineItem.quantity
    };
  }

  onInvoiceItemRemove(value: LineItem) {
    let idx = this.getPayload.lineItems.findIndex(x => x.description == value.description)
    if (idx != null || idx != -1) {

      this.getPayload.lineItems.splice(idx, 1);

      for (var keystring of Object.keys(this.selectedLineItemDescriptions)) {
        var rowIndex = +keystring
        if (rowIndex >= idx) {
          this.selectedLineItemDescriptions[rowIndex] = this.selectedLineItemDescriptions[rowIndex + 1] || this.getNewBlankLineItemDescription;
        }
      }
    }
  }

  //submitting creation of invoice
  submit() {
    if (this.checkLineItems()) return;
    this.setInvoiceAmounts();
    if (!this.getPayload.lineItems || this.getPayload.lineItems.length == 0) return;
    let payload = { invoice: this.getPayload, emailInvoicee: this.emailInvoicee }
    this.onSubmit.emit(payload);
  }

  //remove line items that don't have a net amount
  checkLineItems() {
    let lineItemsArr = [... this.getPayload.lineItems];
    for (let index = 0; index < lineItemsArr.length; index++) {
      const item = lineItemsArr[index];
      if (!(item.netAmount > 0) || !item.description || item.description == '') {
        this.onInvoiceItemRemove(item);
        lineItemsArr.splice(index, 1);
      }
    }

    const dupeLineItems = lineItemsArr.filter((lineItem, i, arr) => arr.findIndex(t => t.description.trim() === lineItem.description.trim()) === i);
    if (dupeLineItems.length != this.getPayload.lineItems.length) {
      this.toastMessage('warn', 'There are duplicate line items', '');
      return true;
    }
    return false;
  }

  setInvoiceAmounts() {
    this.getPayload.totalExcVat = this.getInvoiceTotalExcludingVat;
    this.getPayload.totalAmount = this.getInvoiceTotalIncludingVat;
    this.getPayload.vatAmount = this.getVatAmount;
    this.getPayload.discountAmount = this.getDiscount;
  }

  onVATSelect() {
    try {
      this.includeVAT = !this.includeVAT;
      if (this.includeVAT) {
        this.getPayload.vatRate = this.vatRate;
        this.getPayload.lineItems?.filter(x => x.netAmount = +((x.amount * x.quantity) * (this.getIncludingVAT)).toFixed(2));
      }
      else {
        this.getPayload.vatRate = 0;
        this.getPayload.lineItems?.filter(x => x.netAmount = +(x.amount * x.quantity).toFixed(2));
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  updateLineItems() {
    if (this.getPayload.lineItems.length == 0) return
    _.forEach(this.getPayload.lineItems, (payloadLineItem: LineItem) => {
      let lineItem = _.find(this.lineItems, (o: LineItem) => { return o.description == payloadLineItem.description; });
      if (!lineItem || !lineItem.amount) return

      payloadLineItem.amount = lineItem.amount;
      var index = _.findIndex(this.getPayload.lineItems, (k: LineItem) => { return k.description == payloadLineItem.description; });
      this.setLineItem(index, payloadLineItem);
    })
  }

  async onCurrencySelect(event: Currency) {
    this.getPayload.currencyId = event.id;
    this.getPayload.currencySymbol = event.symbol;
    this.selectedCurrency = event;
    // await this.getLineItems();
    this.updateLineItems();
  }

  setLineItemNetAmount(rowIndex: number) {
    let lineItem = this.getPayload.lineItems[rowIndex];
    if (!lineItem.amount) lineItem.amount = 0;
    else if (lineItem.amount < 0) lineItem.amount = -lineItem.amount
    else if (lineItem.amount > 1000000) lineItem.amount = 1000000;

    lineItem.quantity = lineItem.quantity < 0 ? -lineItem.quantity : lineItem.quantity; // if the line item quantity is negative make it postive
    !lineItem.quantity ? lineItem.quantity = 1 : lineItem.quantity;
    lineItem.netAmount = lineItem.amount * lineItem.quantity;
    if (this.includeVAT) lineItem.netAmount = this.getIncludingVAT * lineItem.netAmount
  }

  onEffectiveDateSelect() { this.getPayload.effectiveDate = moment(this.effectiveDate).format('yy/MM/DD'); }
  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
  onEmailInvoicee(checked: boolean) { this.emailInvoicee = checked; }
  canCreateInvoice() {
    if (!this.getPayload.bankAccountOneId || !this.getPayload.bankAccountTwoId || !this.getPayload.currencyId || !this.selectedInvoicee || !this.getPayload.effectiveDate
      || this.getPayload.lineItems.length == 0 || this.getInvoiceTotalExcludingVat == 0) {
      return true
    }
    return false;
  }
}


