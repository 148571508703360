import { Injectable } from '@angular/core';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class CurrencyApiService {

  private serverUrl = `${this.config.getServerUrl()}api/currency/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  //Endpoint: api/currency/getLocalCurrencies
  //Controller: CurrencyController.cs
  //Proc Name: pr_GetCurrencies
  public async getLocalCurrencies(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getLocalCurrencies`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/currency/getVATRate
  //Controller: CurrencyController.cs
  //Proc Name: None
  async getVATRate(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getVATRate`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
  }
}