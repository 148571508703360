<p-tabView *ngIf="inspectionData && inspectionData.length > 0">
  <p-tabPanel *ngFor='let inspection of inspectionData' header={{inspection.template}} leftIcon="fas fa-car">
    <p-tabView>
      <p-tabPanel header="Details">
        <div class="ui-g">
          <div class="ui-g-6">
            <p-fieldset>
              <p-header>Details:</p-header>
              <div class="ui-g">
                <div class="ui-g-4">
                  InspectionWorkorderId:
                </div>
                <div class="ui-g-8">
                  {{inspection.inspectionWorkOrderId}}
                </div>
                <div class="ui-g-4">
                  Completed By:
                </div>
                <div class="ui-g-8">
                  {{inspection.assignedUsername}}
                </div>
                <div class="ui-g-4">
                  Started Date:
                </div>
                <div class="ui-g-8">
                  {{formatDate(inspection.startDate)}}
                </div>
                <div class="ui-g-4">
                  Complete Date:
                </div>
                <div class="ui-g-8">
                  {{formatDate(inspection.completeDate)}}
                </div>
                <div class="ui-g-4">
                  Upload Date:
                </div>
                <div class="ui-g-8">
                  {{formatDate(inspection.uploadedDate)}}
                </div>
                <div class="ui-g-4">
                  Sync Date:
                </div>
                <div class="ui-g-8">
                  {{formatDate(inspection.syncedDate)}}
                </div>
                <div class="ui-g-4">
                  Route Update:
                </div>
                <div class="ui-g-8">
                  {{inspection.locationData}}
                </div>
              </div>
            </p-fieldset>
          </div>
          <div class="ui-g-6" *ngIf="inspection.ashTray != null">
            <p-fieldset>
              <p-header>Checks:</p-header>
              <div class="ui-g">
                <div class="ui-g-3">
                  Alloy Wheels:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.wheels == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.wheels == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Ash Tray:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.ashTray == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.ashTray == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Battery:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.battery == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.battery == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  CD Changer:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.cdChanger == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.cdChanger == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  CD Player:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.cdPlayer == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.cdPlayer == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Cigarette Lighter:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.cigaretteLighter == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.cigaretteLighter == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  DVD Screen:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.dvdScreen == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.dvdScreen == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Mats:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.mats == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.mats == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Mirror Outside:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.mirrorOutside == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.mirrorOutside == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Navigation DVD Screen:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.navigationDVDScreen == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.navigationDVDScreen == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Radio:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.radio == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.radio == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Tools:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.tools == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.tools == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Tyres:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.tyres == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.tyres == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Wheel Caps:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.wheelCaps == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.wheelCaps == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Windows:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.windows == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.windows == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Wipers Back:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.wipersBack == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.wipersBack == 'Unseen'"></i>
                </div>
                <div class="ui-g-3">
                  Wipers Front:
                </div>
                <div class="ui-g-3">
                  <i class="fas fa-check" *ngIf="inspection.wipersFront == 'Seen'"></i>
                  <i class="fas fa-times" *ngIf="inspection.wipersFront == 'Unseen'"></i>
                </div>
              </div>
            </p-fieldset>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Images">
        <div *ngIf="inspection.images && inspection.images.length > 0" class="ui-g-12">
          <p-fieldset>
            <p-header>Images:</p-header>
            <div class="ui-g">
              <div class="ui-g-3" *ngFor="let image of inspection.images">
                {{image.displayText}}
                <img style='height: 100%; width: 100%; object-fit: contain' src="{{image.b64String}}">
              </div>
            </div>
          </p-fieldset>
        </div>
        <p *ngIf="!inspection.images || inspection.images.length == 0">No Inspection Images</p>
      </p-tabPanel>
      <p-tabPanel header="Damage">
        <div *ngIf="inspection.damageImages && inspection.damageImages.length > 0" class="ui-g-12">
          <p-fieldset>
            <p-header>Damage Images:</p-header>
            <div class="ui-g">
              <div class="ui-g-3" *ngFor="let image of inspection.damageImages">
                {{image.displayText}}
                <img style='height: 100%; width: 100%; object-fit: contain'
                  [src]="this.domSanitizer.bypassSecurityTrustUrl(image.b64String)">
              </div>
            </div>
          </p-fieldset>
        </div>
        <p *ngIf="!inspection.damageImages || inspection.damageImages.length == 0">No Damage</p>
      </p-tabPanel>
      <p-tabPanel header="Video">
        <ng-container *ngIf="inspection.videos.length > 0; else noVideos">
          <!-- Content to display when videos exist -->
          <div class="video-container" *ngFor="let videoObject of inspection.videos">
            <div class="displayText">
              <label>{{videoObject.displayText}}</label>
            </div>
            <video controls width="600" height="400" *ngIf="videoObject.video">
              <source [src]="videoObject.video" [type]="videoObject.docMimeType">
            </video>
            <p-button class="download-video" label="Download Video"
              (onClick)="downloadFile(videoObject.fullPath, videoObject.docMimeType, videoObject.displayText)">
            </p-button>
          </div>
        </ng-container>

        <ng-template #noVideos>
          <!-- Content to display when no videos exist -->
          <p>There are no videos available.</p>
        </ng-template>

      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>
</p-tabView>
<p *ngIf="!inspectionData || inspectionData.length == 0">No inspection data found</p>