import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";

@Injectable({
  providedIn: 'root'
})
export class SmsApiService {

  constructor(
    private config: ConfigService,
    private router: Router,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  async getFileSMSData(fileId: any) {
    try {
      const url = this.config.getServerUrl() + "api/sms/getFileSMSData?FileId=" + fileId;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getSMSDataByLegalEntityId(legalEntityId: any) {
    try {
      const url = this.config.getServerUrl() + "api/sms/getSMSDataByLegalEntityId?LegalEntityId=" + legalEntityId;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

}