import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CriteriaResponse } from '../../../interfaces/result-interfaces';

@Component({
  selector: 'app-any-view-table',
  templateUrl: './any-view-table.component.html',
  styleUrls: ['./any-view-table.component.scss']
})
export class AnyViewTableComponent {
  showSpinner: boolean = false;
  showTable: boolean = false;
  title: string | null = null;
  data: any[] = [];
  selection: any[] = [];
  constructor(private messageService: MessageService) { }
  public onSubmit() {
    this.showSpinner = true;
    this.showTable = false;
  }
  public onResult(event: CriteriaResponse) {
    this.showSpinner = false;

    if (!event || !event?.result) this.toastMessage('error', `Failed to get view for ${event.template.name}. Please contact support`, '');
    else if (event.result.errorCode !== 0) this.toastMessage('error', event.result.errorMessage, '');
    else {
      if (this.data) this.showTable = true;
      if (!event.result.result) {
        this.data = [];
        return;
      }
      this.title = event.template.name;
      this.data = event.result.result;
    }
  }
  public onClear() { this.showTable = false; }
  private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}