import { Component, OnInit } from '@angular/core';
import { AmountOutstandingArrearNotice, Dropdown, TableColumns } from '../../interfaces/global.interfaces';
import { Api2Service } from '../../services/api2.service';
import { ExcelService } from '../../services/excel.service';
import { MessageService } from 'primeng';
import * as _ from 'lodash';

@Component({
  selector: 'outstanding-amount-arrear-notices',
  templateUrl: './outstanding-amount-arrear-notices.component.html',
  styleUrls: ['./outstanding-amount-arrear-notices.component.scss']
})
export class OutstandingAmountArrearNoticesComponent implements OnInit {

  files: AmountOutstandingArrearNotice[];
  notes: string;
  display: boolean = false;
  displayPosition: boolean;
  position: string;
  editNote: number = 1;
  fileId: number;
  currLocation: any[];
  constructor(
    private api: Api2Service,
    private excel: ExcelService,
     private messageService: MessageService
  ) { }

  async ngOnInit() {
    await this.getOutstandingAmountsAndArrearNotice();
  }

   public async getOutstandingAmountsAndArrearNotice() {
    let result = await this.api.getOutstandingAmountsAndArrearNotice();
    if (result) {
      // Ensure the result is a flat array
      this.files = Array.isArray(result) ? result : [];
      const uniqueLoc = _.uniqBy(this.files, 'currentLocation');
      this.currLocation = uniqueLoc.map(x => { return { label: x.currentLocation, value: x.currentLocation}; });
    } else {
      console.log("Failed to fetch data");
    }
  }

  public async generateExcel() { await this.excel.generateGenericExcel(this.files, 'Amount Outstanding And Arrear Notices'); }

  showPositionDialog(position: string, notes, dbnNumber: string) {
    this.position = position;
    this.displayPosition = true;
    this.notes = notes;
    let split = dbnNumber.split("/");  // Split the string by "/"
    this.fileId = parseInt(split[1], 10);
  }

  editNotesToggle() {
    this.editNote = 2;
  }
  editNotesSaveChanges() {
    try {
      this.api.UpdateNotesForTypeComment(this.fileId, this.notes);
      this.toastMessage('success', 'Notes updated Successfully', '');
      this.displayPosition = false;
      this.getOutstandingAmountsAndArrearNotice();
      
    }
    catch (err) {
      this.toastMessage('error', 'Failed to update', '');
    }
  }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
  
}

