<p-tabView>
  <p-tabPanel header="Collection Planning">
    <collection-planning>
    </collection-planning>
  </p-tabPanel>
  <p-tabPanel header="Dispatch Planning">
    <dispatch-planning>
    </dispatch-planning>
  </p-tabPanel>
  <p-tabPanel header="Pre-Planning Delivery">
    <app-preplanning (refreshLoad)="handleRefresh()">
    </app-preplanning>
  </p-tabPanel>
</p-tabView>