import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { LogBase } from '../../services/logger.service';
import { MenuItem } from 'primeng/api';
import { ConfigService } from '../../services/config.service'
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../services/api/auth/authentication.service';

@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ConfirmationService]
})
export class DisplayContactComponent implements OnInit {

  contacts: any = [];
  selectedContact: any;
  companies: any = [];

  displayDialog: boolean;
  msgs: any[];
  showSpinner: boolean = true;

  displayQRCode: boolean = false;
  vCardData: string;
  vCardInfo: any = {};

  menuItems: MenuItem[] = [
    { label: 'Company', icon: 'far fa-building', routerLink: ['/manage-legal-entity/add/2'] },
    { label: 'Person', icon: 'fas fa-user-tie', routerLink: ['/manage-legal-entity/add/1'] }
  ];

  constructor(
    private api: ApiService,
    private router: Router,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private config: ConfigService,
    private titleService: Title,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Contact");
  }

  async ngOnInit() {
    try {
      this.contacts = await this.api.getLegalEntitiesDisplay();
      if (this.contacts.length >= 0) {
        this.showSpinner = false;
      }
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
  }

  routeToLegalEntityEdit(id: number, legalEntityTypeId: number) {
    this.router.navigate(['/manage-legal-entity/', id, legalEntityTypeId]);
  }

  async setupVCard(contactid: any) {
    this.displayQRCode = true;
    this.vCardInfo = await this.api.getVCardInfo(contactid);

    let name = this.vCardInfo.Name || '',
      middlename = this.vCardInfo.MiddleNames || '',
      surname = this.vCardInfo.LastName || '',
      title = this.vCardInfo.Title || '',
      org = '',
      url = '',
      email = this.vCardInfo.Email || '',
      tel1 = this.vCardInfo.Cellphone || '',
      tel2 = this.vCardInfo.Telephone || '',
      addr1 = this.vCardInfo.Address1 || '',
      addr2 = this.vCardInfo.Address2 || '',
      postalcode = this.vCardInfo.PostalCode || '',
      city = this.vCardInfo.CityName || '',
      country = this.vCardInfo.Country || '';

    this.vCardData =
      `BEGIN:VCARD
    VERSION:3.0
    N:${surname};${name}
    FN:${surname} ${name}
    TITLE:${title}
    TEL;TYPE=VOICE,WORK,PREF:${tel1}
    TEL;TYPE=VOICE,HOME,PREF:${tel2}
    ADR;TYPE=WORK,PREF:;;${addr1};${addr2};${city};${postalcode};${country}
    LABEL;TYPE=WORK,PREF:${addr1}\n${addr2}\, ${city} ${postalcode}\n${country}
    ORG:${org}
    URL:${url}
    EMAIL:${email}
    END:VCARD
    `
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name:  Display Contact<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component:  Display Contact';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      }
    });
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
