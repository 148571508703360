<p-progressBar mode="indeterminate" *ngIf="showSpinner"></p-progressBar>

<div class="ui-g-12" *ngIf="!showSpinner && (!fileResults || fileResults.length == 0)">
  <h1>No Files Found</h1>
</div>

<div class="ui-g" *ngIf="!showSpinner && fileResults && fileResults.length !== 0">
  <div class="ui-g-12">
    <div class="card file-card" *ngFor="let file of fileResults; let i = index">
      <div class="qr-tag" (click)="showQR[i] = !showQR[i]">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
      </div>
      <div class="details" (click)="openFile(file.Id)">
        <div class="info">
          <div class="section">
            <file-number [fileNumber]="file.DBNNumber"></file-number>
            <label data-label="Foreign Reference">{{file.ForeignReference}}</label>
            <label data-label="Cargo Type">{{file.CargoType}}</label>
            <label data-label="Opened By">{{file.CreatedBy}}</label>
            <label data-label="Owner">{{file.FileOwner}}</label>
            <label data-label="Clearing Agent">{{file.ClearingAgent || '-'}}</label>
          </div>
          <div class="section">
            <h3>Consignee</h3>
            <label (click)="consigneeRoute(file)" pTooltip="View consignee details" class="click" data-label="Name">
              {{file.ClientName || "-"}}
            </label>
            <label data-label="Email" pTooltip="Email Consignee">
              <a (click)="$event.stopPropagation()" href="mailto:{{file.ClientEmail}}" target="_blank">
                {{file.ClientEmail || "-"}}
              </a>
            </label>
            <label data-label="Cellphone 1">{{file.Cellphone1 || "-"}}</label>
            <label data-label="Telephone 1">{{file.Telephone1 || "-"}}</label>
            <label data-label="Country">{{file.Country || "-"}}</label>
          </div>
          <div class="section">
            <h3>Shipping</h3>
            <label data-label="Vessel">{{ file.Vessel || '-' }}</label>
            <label data-label="Voyage #">{{ file.VoyageNumber || '-' }}</label>
            <label data-label="ETA">{{file.ETA}}</label>
          </div>
          <div class="section">
            <h3>Repo Status</h3>
            <label data-label="Duties Doc Received">{{ file.DutiesReceivedDate }}</label>
            <label data-label="Duties Send To Border">{{ file.DutiesSentDate }}</label>
            <label data-label="SARS Docs Sent To Client">{{file.SARSDocsSentDate}}</label>
            <label data-label="Duties Confirmed">{{file.DutiesConfirmedDate}}</label>
          </div>
          <div class="section">
            <h3>Finance</h3>
            <label data-label="Invoice">{{ file.Invoice }}</label>
            <label *ngFor="let balance of file.BalanceDue" [attr.data-label]="'Balance Due In ' +
              balance.CurrencyCode">{{ balance.AmountOutStanding.toFixed(2) || '0.00' }}</label>
          </div>
          <div class="section">
            <h3>Location</h3>
            <label data-label="Last Leg Completed">{{ file.Destination }}</label>
            <label *ngIf="file.LastDestination" data-label="Destination">{{ file.LastDestination }}</label>
            <label data-label="Current Location">{{ file.CurrentLocation }}</label>
            <label data-label="Next Location">{{ file.NextLocation }}</label>
          </div>
        </div>
      </div>
      <div class="layer-2">
        <div class="bordered">
          {{file.Route}}
        </div>
      </div>
      <div class="layer-2">
        <div class="bordered">
          <bracket-text *ngFor="let cd of file.CargoDescription" [text]="cd.Description"></bracket-text>
        </div>
      </div>
      <div class="panel">
        <div class="contents" [ngClass]="showQR[i] ? 'shown' : 'hidden'">
          <h3>Consignee QR Code</h3>
          <qr-code (click)="$event.stopPropagation()" [value]="file.vCardData" [size]="160"></qr-code>
        </div>
      </div>
    </div>

    <div *ngIf="!showSpinner && fileResults.length !== 0">
      <div class="ui-g card card-w-title">
        <div class="ui-g-12 ui-g-nopad" *ngIf="userTasks.length > 0">
          <h2>User Tasks:</h2>
          <ng-container>
            <div *ngFor="let userTask of userTasks">
              <div class="ui-g-10">
                <strong>{{ userTask.userTaskName }}:</strong><br>
                <label>{{ userTask.vesselName }} - {{ userTask.voyageNumber }} - ETA: {{ formatETADate(userTask.eta)
                }}</label>
                <label class="label-font"> Assigned to: <strong>{{ userTask.assignedUser }}</strong></label>
              </div>
              <div class="ui-g-2">
                <button pRipple class="p-button-info" pButton type="button" label="View Task"
                  (click)="viewUserTask(userTask)"></button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="ui-g-12" *ngIf="userTasks.length === 0">
          <h2>No User Tasks Found</h2>
        </div>
      </div>
    </div>
  </div>
</div>