import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { WhatsappApiService } from '../../../../services/api/whatsapp-api.service';
import { LogBase } from '../../../../services/logger.service';
import { Title } from '@angular/platform-browser';
import { WAUnverifiedDocument } from '../../../../interfaces/whatsapp.interface';
import { ApiService } from '../../../../services/api.service';
import * as moment from 'moment';
import { DocumentApiService } from '../../../../services/api/document-api.service';
import { Dropdown } from '../../../../interfaces/global.interfaces';
import { WhatsappUserTaskApiService } from '../../../../services/api/user-tasks/whatsapp-user-task-api.service';
import { DocumentCategory, DocumentStatusType, NoteType } from '../../../../enums';

@Component({
  selector: 'app-whatsapp-check-document',
  templateUrl: './whatsapp-check-document.component.html',
  styleUrls: ['./whatsapp-check-document.component.scss']
})
export class WhatsappCheckDocumentComponent implements OnInit {

  public fileId: number = 0;
  public showSpinner: boolean = false;
  public showDocumentCategoryDialog: boolean = false;
  public documentCategorySpinner: boolean = false;
  public documentViewer: boolean = false;
  public documents: WAUnverifiedDocument[] = [];
  public images: any[] = [];
  public selectedDocument: WAUnverifiedDocument;
  public documentCategories: Dropdown[];
  public selectedDocumentCategory: Dropdown;
  public loadIcon: string = 'fas fa-sync';

  constructor(
    private route: ActivatedRoute, 
    private apiService: ApiService,
    private whatsappApiSerive: WhatsappApiService,
    private whatsappUserTaskApiService: WhatsappUserTaskApiService,
    public documentApiService: DocumentApiService,
    private messageService: MessageService,
    private log: LogBase,
    private titleService: Title,
  ) {
    this.titleService.setTitle('WhatsApp Check Documents');
   }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.showSpinner = true;
      this.fileId = params['fileId'];
      if (!this.fileId) {
        this.toastMessage('error', 'Failed to get the file. Please contact support.', '');
      } else {
        await this.getUnverifedDocuments();
        await this.getDocumentCategory();
      }
      this.showSpinner = false;
    });
  }

  public async refreshDocuments() {
    this.loadIcon = 'fas fa-sync fa-spin'
    this.showSpinner = true;
    await this.getUnverifedDocuments();
    this.showSpinner = false;
    this.loadIcon = 'fas fa-sync'
  }

  private async getUnverifedDocuments() {
    try {
      const documents = await this.whatsappApiSerive.WAGetUnverifiedDocuments(this.fileId);
      if (!documents) this.toastMessage('error', 'Failed to get unverifed documents. Please contact support.', '');
      else if (documents.errorCode != 0) this.toastMessage('error', documents.errorMessage, '');
      else this.documents = documents.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  public async approveDocument(document: WAUnverifiedDocument) {
    document.spinner = true;
    try {
      const resp = await this.documentApiService.approveDocument(document.documentId, this.fileId)
      if (!resp) this.toastMessage('error', 'Failed to approve document. Please contact support', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.toastMessage('success', resp.errorMessage, '');
        this.removeDocument(document.documentId);
        await this.completeUserTask(document.documentCategoryId, DocumentStatusType.VERIFIED);
      }
    } catch (err) {
      this.log.error(err);
    }
    document.spinner = false;
  }

  public async rejectDocument(document: WAUnverifiedDocument) {
    document.spinner = true;
    try {
      const resp = await this.documentApiService.declineDocument(document.documentId)
      if (!resp) this.toastMessage('error', 'Failed to reject document. Please contact support', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.toastMessage('success', resp.errorMessage, '');
        this.removeDocument(document.documentId);
        await this.completeUserTask(document.documentCategoryId, DocumentStatusType.DECLINED);
      }
    } catch (err) {
      this.log.error(err);
    }
    document.spinner = false;
  }

  public async getDocumentCategory() {
    try {
      const documentCategoies = await this.documentApiService.getDocumentCategory();
      if (!documentCategoies) this.toastMessage('error', 'Failed to get document categories', '');
      else if (documentCategoies.errorCode != 0) this.toastMessage('error', documentCategoies.errorMessage, '');
      else this.documentCategories = documentCategoies.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  public async downloadDocumentforView() {
    try {
      const image = await this.apiService.viewClientDocument(this.selectedDocument.documentId);
      if (!image) this.toastMessage('error', 'Failed to preview document. Please contact support', '');
      else this.images = [image];
    }
    catch (err) {
      this.log.error(err);
    }
  }

  public async updateDocumentCategory() {
    this.documentCategorySpinner = true;
    try {
      const resp = await this.documentApiService.updateDocumentCategory(this.selectedDocument.documentId, this.selectedDocumentCategory.value);
      if (!resp) this.toastMessage('error', 'Failed to update document category. Please contact support', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.toastMessage('success', resp.errorMessage, '');
        this.selectedDocument.documentCategoryId = this.selectedDocumentCategory.value;
        this.selectedDocument.documentCategory = this.selectedDocumentCategory.label;
        this.selectedDocument = null;
        this.selectedDocumentCategory = null;
        this.showDocumentCategoryDialog = false;
      }
    } catch (err) {
      this.log.error(err);
    }
    this.documentCategorySpinner = false;
  }

  private async completeUserTask(documentCategoryId: DocumentCategory, documentStatus: DocumentStatusType) {
    try {
      const resp = await this.whatsappUserTaskApiService.CompleteWhatsappCheckDocumentUserTask(this.fileId, documentCategoryId);
      if (!resp) this.toastMessage('error', 'Failed to complete user task. Please contact support', '');
      else if (resp.errorCode != 0 && resp.errorCode != 1) this.toastMessage('error', resp.errorMessage, '');
      else if (resp.errorCode == 1) {
        this.toastMessage('success', resp.errorMessage, '');

        console.log(documentStatus, 'documentStatus', documentCategoryId, 'documentCategoryId')
        //if they verified the document and it is the border duties document
        if (documentStatus == DocumentStatusType.VERIFIED && documentCategoryId == DocumentCategory.BORDER_POST_DUTIES) {
          const resp = await this.apiService.saveNoteDataForFile('BORDER DUTIES UPLOADED', this.fileId, NoteType.BORDER_DUTIES_UPLOADED);
        }
      }
    } catch (err) {
      this.log.error(err);      
    }
  }

  showWhatsKindOfDocumentDialog(document: WAUnverifiedDocument) {
    this.selectedDocument = document;
    this.selectedDocumentCategory = null;
    this.showDocumentCategoryDialog = true;
  }

  public displayImageViewerDialogue(document: WAUnverifiedDocument) {
    this.selectedDocument = document;
    this.downloadDocumentforView();
    this.documentViewer = true;
  }

  public formatDate(date: string) { return moment(date, 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD HH:mm'); }
  private removeDocument(documentId: number) {
    const documentIndex = this.documents.findIndex(document => document.documentId === documentId);
    this.documents.splice(documentIndex, 1);
  }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}