import { NoteItem } from './../../../interfaces/global.interfaces';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { LogBase } from '../../../services/logger.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../../services/config.service'
import { ActivatedRoute } from '@angular/router';
import { FileApiService } from '../../../services/api/file-api.service';
import { Title } from "@angular/platform-browser";
import * as _ from "lodash";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [ConfirmationService]
})

export class NotesComponent implements OnInit {

  @Output() onLoaded = new EventEmitter();
  @Input() fileId: string;
  NotesFiles: boolean;
  noteTypes: any;
  selectedNoteType: NoteItem;
  notes: NoteItem[];
  displayNote: boolean = false;
  noteTypeSelected: boolean = true;
  noteData: any;

  loadIcon: string = 'fas fa-sync';

  constructor(
    private api: ApiService,
    private fileApi: FileApiService,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private titleService: Title,
    private messageService: MessageService,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Notes");
  }

  async ngOnInit() {
    await this.getNotesForFile();
    this.onLoaded.emit();
  }

  setupNote() {
    if (!this.selectedNoteType) return
    let noteType = _.find(this.noteTypes, (o: any) => { return o.value == this.selectedNoteType });
    if (noteType.value != 1) this.noteData = noteType.description + ' ';
    this.noteTypeSelected = false;
  }

  async addNote() {
    await this.getNoteTypes();
    this.displayNote = true;
  }

  async saveNote() {
    try {
      this.noteData = this.noteData.split('&').join('and');
      let retval = await this.api.saveNoteDataForFile(this.noteData, this.fileId, this.selectedNoteType);
      if (retval) {
        if (retval.errorCode == 0) {
          this.toastMessage('success', retval.errorMessage, '')
          await this.getNotesForFile();
          this.noteData = undefined;
          this.displayNote = false;
        }
        else {
          this.toastMessage('error', retval.errorMessage, '')
        }
      }
      else {
        this.toastMessage('error', 'Failed to add note. Please contact support.', '')
      }
    } catch (error) {
      this.showError('Error Saving Note', error);
      this.log.error(error);
    }
  }

  ResetDialogValues() {
    this.selectedNoteType = undefined;
    this.noteData = '';
    this.noteTypeSelected = true;
  }

  async getNoteTypes() {
    try {
      this.noteTypes = await this.api.getNoteTypes()
    } catch (error) {
      this.showError('Error Getting Notes Types', error);
      this.log.error(error);
    }
  }

  async getNotesForFile() {
    this.loadIcon = 'fas fa-sync fa-spin'
    try {
      this.notes = await this.fileApi.getNotesByFileId(this.fileId)
    } catch (error) {
      this.showError('Error Getting Notes For File', error);
      this.log.error(error);
    }
    this.loadIcon = 'fas fa-sync'
  }

  toastMessage(severity, summary, detail) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail
    });
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: File Summary Notes<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: File Summary Notes';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, this.fileId, -1);
      }
    });
  }
}
