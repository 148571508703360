import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../../../services/api.service';
import { ConfigService } from './../../../services/config.service';
import { LogBase } from '../../../services/logger.service';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: 'app-manage-vehicles',
  templateUrl: './manage-vehicles.component.html',
  styleUrls: ['./manage-vehicles.component.css'],
  providers: [ConfirmationService]
})
export class ManageVehiclesComponent implements OnInit {

  name = 'Manage Vehicles';
  msgs: any[];

  Manufacturers: any;
  selectedManufacturer: any;

  Models: any;
  selectedModel: any;
  newModel: any;

  newManufacturer: any;


  showGrid: boolean = false;

  modelDialog: boolean = false;
  manufacturerDialog: boolean = false;

  state: any;
  showBackBtn: boolean = true;

  showLoadingSpinner: boolean = false;

  constructor(
    private api: ApiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private log: LogBase,
    private titleService: Title,
    private authService: AuthenticationService
  ) { this.titleService.setTitle("AVECS Manage Vehicles"); }

  async ngOnInit() {
    try {
      this.getURLParams();
      this.Manufacturers = await this.api.getVehicleMakes();
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
  }

  getURLParams() {
    try {
      this.route.paramMap.subscribe(params => this.state = params.get('state'));
    } catch (error) {
      this.log.error(error);
    }
  }

  async getModels() {
    try {
      this.showLoadingSpinner = true;
      this.Models = await this.api.getVehicleModels(this.selectedManufacturer.Id);
      this.showGrid = true;
      this.showLoadingSpinner = false;
    } catch (error) {
      this.showError('Error Getting Vehicle Models)', error);
      this.log.error(error);
    }
  }

  async SaveNewModel() {
    this.showLoadingSpinner = true;
    const retval = await this.api.addVehicleModel(this.newModel, this.selectedManufacturer.Id);
    if (!retval) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error Saving New Vehicle Model' });
    else {
      await this.getModels();
      this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'New Vehicle Model Saved.' });
      this.Manufacturers = await this.api.getVehicleMakes();
      this.newModel = null;
    }
    this.modelDialog = false;
    this.showLoadingSpinner = false;
  }

  async SaveNewManufacturer() {
    this.showLoadingSpinner = true;
    const retval = await this.api.addVehicleManufacturer(this.newManufacturer);
    if (!retval) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding Manufacturer' });
    else {
      await this.getModels();
      this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'New Manufacturer added.' });
      this.Manufacturers = await this.api.getVehicleMakes();
      this.newManufacturer = null;
    }
    this.manufacturerDialog = false;
    this.showLoadingSpinner = false;
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: ' + this.name + '<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: Manage Network Providers';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      }
    });
  }
}