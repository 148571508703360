<div class="ui-g card card-w-title">
  <div class="p-col-12">
    <h1>Border Posts</h1>
  </div>
  <div class="ui-g-12">
    <p-table #dt [columns]="itemCols" [paginator]="true" [rows]="50" [value]="borderPosts" [autoLayout]="true"
      [loading]="showLoadingSpinner" editMode="row" dataKey="Id" [scrollable]="true" scrollHeight="350px"
      [globalFilterFields]="['BorderPost', 'ExitBorderPost', 'DefaultCIFDestination']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add a border post"
              (click)="clearBorderPost()" type="button" style="float: right; margin-right: 15px"
              icon="fas fa-plus"></button>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search border posts" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let itemcol of itemCols">
            {{itemcol.header}}
          </th>
          <th>
            Actions
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing">
        <tr [pEditableRow]="rowData">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="rowData.name" required class="width-100" maxlength="50">
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.name}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="rowData.exitBorderPost" required class="width-100"
                  maxlength="50">
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.exitBorderPost}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="rowData.defaultCIFDestination" required class="width-100"
                  maxlength="50">
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.defaultCIFDestination}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [options]="borderPostStatus" [(ngModel)]="rowData.borderPostStatusId" [filter]="true"
                  appendTo="body" [autoDisplayFirst]="false" [style]="{width: '100%'}">
                </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.borderPostStatus}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fas fa-pencil-alt"
              class="ui-button-info" pTooltip="Edit border post"></button>
            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fas fa-check" class="ui-button-success"
              style="margin-right: .5em" (click)="updateBorderPost(rowData)" pTooltip="Update border post"></button>
            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fas fa-times"
              class="ui-button-danger" pTooltip="Cancel Edit"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Add Border Post" modal="true" [(visible)]="displayDialog" [style]="{'width': '50vw'}">
  <div class="ui-g">
    <div class="ui-g-4">
      <label>Border Post :</label>
    </div>
    <div class="ui-g-8">
      <input class="width-100" maxlength="50" type="text" pInputText [(ngModel)]="newborderpost" />
    </div>
    <div class="ui-g-4">
      <label>Exit Border Post :</label>
    </div>
    <div class="ui-g-8">
      <input class="width-100" maxlength="50" type="text" pInputText [(ngModel)]="newexitborderpost" />
    </div>
    <div class="ui-g-4">
      <label>Default CIF Destination :</label>
    </div>
    <div class="ui-g-8">
      <input class="width-100" maxlength="50" type="text" pInputText [(ngModel)]="newdefaultCIFdestination" />
    </div>
    <div class="ui-g-4">
      <label>Duties :</label>
    </div>
    <div class="ui-g-8">
      <p-dropdown [options]="borderPostStatus" [(ngModel)]="selectedBorderPostStatus" [filter]="true" appendTo="body"
        [autoDisplayFirst]="false" [style]="{width: '100%'}">
      </p-dropdown>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!showSaveSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="saveBorderPost()" label="Save" [disabled]="!newborderpost || !newexitborderpost || !newdefaultCIFdestination || selectedBorderPostStatus == null
      || selectedBorderPostStatus == undefined"></button>
    <button *ngIf="showSaveSpinner" pRipple class="submit p-button-info width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>