import { CheckBorderPostComponent } from './components/user-tasks/file-owner/check-border-post/check-border-post.component';
import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { DisplayContactComponent } from "./components/contacts/contact.component";
import { ManageLegalEntityComponent } from "./components/contacts/manage-legal-entity/manage-legal-entity.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { TaskListComponent } from "./components/task-list/task-list.component";
import { SearchResultComponent } from "./components/search/search-result/search-result.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthManager } from "./services/api/auth/auth.manager";
import { CommunicatorComponent } from "./components/communicator/communicator.component";
import { ManageVesselsComponent } from "./components/reference/manage-vessels/manage-vessels.component";
import { BulkUpdaterComponent } from "./components/bulk-updater/bulk-updater.component";
import { DispatchManagerComponent } from "./components/dispatch-manager/dispatch-manager.component";
import { RouteBuilderComponent } from './components/routes/route-builder/route-builder.component';
import { ContainersComponent } from "./components/reference/containers/containers.component";
import { ContainerItemsComponent } from "./components/reference/containers/container-items.component";

// Reference Tables
import { ManageColorsComponent } from "./components/reference/colors/colors.component";
import { ManageInvoiceItemsComponent } from "./components/reference/manage-invoice-items/manage-invoice-items.component";
import { ManageBorderPostComponent } from "./components/reference/border-post/border-post.component";
import { ManageDocumentCategoryComponent } from "./components/reference/document-category/document-category.component";
import { ShippingLinesComponent } from "./components/reference/shipping-lines/shipping-lines.component";
import { OpenFileComponent } from "./components/file-open/file-open.component";
import { ManageVehiclesComponent } from "./components/reference/manage-vehicles/manage-vehicles.component";
import { FileViewComponent } from "./components/file/file.component";
import { FileAssignmentComponent } from "./components/reference/file-assignment/file-assignment.component";
import { MasterBillComponent } from "./components/reference/manage-masterbill/masterbill.component";

// Whatsapp
import { WhatsappManagerComponent } from './components/whatsapp/whatsapp-manager/whatsapp-manager.component';
import { WhatsappHistoryComponent } from "./components/whatsapp/whatsapp-history/whatsapp-history.component";
import { WhatsappDashboardComponent } from "./components/whatsapp/whatsapp-dashboard/whatsapp-dashboard.component";
import { ShipArrivalsComponent } from "./summary/ship-arrivals/ship-arrivals.component";
import { MonthlyCarMovementComponent } from "./summary/monthly-car-movement/monthly-car-movement.component";
import { DeliveryStatsComponent } from "./summary/delivery-stats/delivery-stats.component";
import { CarMakeAndTypeStatsComponent } from "./summary/car-make-and-type-stats/car-make-and-type-stats.component";
import { RouteStatusUpdaterComponent } from "./components/routes/route-status-updater/route-status-updater.component";
import { AnyReportComponent } from "./components/on-demand/any-report/any-report.component";
import { AnyJobComponent } from "./components/on-demand/any-job/any-job.component";
import { AnyViewTableComponent } from "./components/on-demand/any-view-table/any-view-table.component";

//iframes
import { SaexComponent } from "./iframes/saex/saex.component";
import { AvecsFinanceComponent } from "./iframes/avecs-finance/avecs-finance.component";
import { WhatsappComponent } from "./iframes/whatsapp/whatsapp.component";
import { AllocatePaymentComponent } from "./components/user-tasks/finance/allocate-payment/allocate-payment.component";
import { FinanceSummaryComponent } from "./components/finance/finance-summary/finance-summary.component";
import { AddClearingAgentComponent } from './components/user-tasks/shared/add-clearing-agent/add-clearing-agent.component';
import { BfjCifComponent } from './components/finance/bfj-cif/bfj-cif.component';
import { UserTaskType } from "./enums";
import { VesselBoardComponent } from "./summary/vessel-board/vessel-board.component";
import { Groups } from "./enums";
import { StorageInvoiceComponent } from "./components/finance/storage-invoice/storage-invoice.component";
import { WhatsappTalkToAgentGeneralComponent } from './components/user-tasks/whatsapp/whatsapp-talk-to-agent-general/whatsapp-talk-to-agent-general.component';
import { WhatsappTalkToAgentForAFileComponent } from './components/user-tasks/file-owner/whatsapp-talk-to-agent-for-a-file/whatsapp-talk-to-agent-for-a-file.component';
import { UserTaskDashboardComponent } from './summary/user-task-dashboard/user-task-dashboard.component';
import { CarsInDurbanComponent } from './summary/cargo-tracker/cars-in-durban.component';
import { InvoiceCreateComponent } from './components/finance/invoice/invoice-create/invoice-create.component';
import { InvoiceEditComponent } from './components/finance/invoice/invoice-edit/invoice-edit.component';
import { AdhocUserTaskComponent } from './components/user-tasks/adhoc/adhoc-user-task/adhoc-user-task.component';
import { SummaryDashboardComponent } from './summary-dashboard/summary-dashboard.component';
import { WhatsappCheckDocumentComponent } from './components/user-tasks/whatsapp/whatsapp-check-document/whatsapp-check-document.component';
import { FixStorageInvoiceIssuesComponent } from './components/finance/storage-invoice/fix-storage-invoice-issues/fix-storage-invoice-issues.component';
import { MorningPageComponent } from './summary/morning-page/morning-page.component';

import { ApproveRejectAdminTransactionComponent } from './components/user-tasks/finance/approve-reject-admin-transaction/approve-reject-admin-transaction.component';
import { ManageFilePreferencesComponent } from './components/manage-file-preferences/manage-file-preferences.component';
import { InspectionRouteUpdateFailComponent } from './components/user-tasks/file-owner/inspection-route-update-fail/inspection-route-update-fail.component';
import { OutstandingAmountArrearNoticesComponent } from './summary/outstanding-amount-arrear-notices/outstanding-amount-arrear-notices.component';

export const routes: Routes = [
  {
    path: "saex",
    component: SaexComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "WhatsApp",
    component: WhatsappComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "avecs-finance",
    component: AvecsFinanceComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "task-list",
    component: TaskListComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "",
    component: TaskListComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "display-contact",
    component: DisplayContactComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "file-upload",
    component: FileUploadComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "containers",
    component: ContainersComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "containers/:state",
    component: ContainersComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "container-items",
    component: ContainerItemsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "container-items/:id",
    component: ContainerItemsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "masterbills",
    component: MasterBillComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "file-view",
    component: FileViewComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "dispatch-manager",
    component: DispatchManagerComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.DISPATCH, Groups.SYSTEM_ADMIN] }
  },
  {
    path: "file-view/:updateData",
    component: FileViewComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "search-result",
    component: SearchResultComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "communicator",
    component: CommunicatorComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "bulk-updater",
    component: BulkUpdaterComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "open-file",
    component: OpenFileComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "vessel-management",
    component: ManageVesselsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "manage-invoice-items",
    component: ManageInvoiceItemsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS_ADMIN, Groups.SYSTEM_ADMIN] }
  },
  {
    path: "colors",
    component: ManageColorsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "border-post",
    component: ManageBorderPostComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "document-category",
    component: ManageDocumentCategoryComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "shipping-lines",
    component: ShippingLinesComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "manage-vehicles",
    component: ManageVehiclesComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "manage-vehicles/:state",
    component: ManageVehiclesComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "route-builder",
    component: RouteBuilderComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "route-builder/:state",
    component: RouteBuilderComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "whatsapp-dashboard",
    component: WhatsappDashboardComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "whatsapp-manager",
    component: WhatsappManagerComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "whatsapp-history",
    component: WhatsappHistoryComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "file-assignment",
    component: FileAssignmentComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ROUND_ROBIN_ADMIN] },
  },
  {
    path: "manage-legal-entity/:id/:legalEntityTypeId",
    component: ManageLegalEntityComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "manage-legal-entity/add/:legalEntityTypeId",
    component: ManageLegalEntityComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "summary-dashboard",
    component: SummaryDashboardComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "monthly-car-movement",
    component: MonthlyCarMovementComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "ship-arrivals",
    component: ShipArrivalsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "vessel-board",
    component: VesselBoardComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "cars-in-durban",
    component: CarsInDurbanComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "delivery-stats",
    component: DeliveryStatsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "car-make-and-type-stats",
    component: CarMakeAndTypeStatsComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "user-task-dashboard",
    component: UserTaskDashboardComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "morning-page",
    component: MorningPageComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
    {
    path: "outstanding-amount-arrear-notices",
    component: OutstandingAmountArrearNoticesComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS, Groups.ACCOUNTS_ADMIN] }
  },
  {
    path: "route-status-updater",
    component: RouteStatusUpdaterComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "reports",
    component: AnyReportComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "jobs",
    component: AnyJobComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "views",
    component: AnyViewTableComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL] }
  },
  {
    path: "storage/invoice/:fileId",
    component: StorageInvoiceComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS, Groups.SYSTEM_ADMIN] }
  },
  {
    path: "invoice/create/:adhoc/:fileId",
    component: InvoiceCreateComponent,
    canActivate: [AuthManager],
    data: {
      roles: [Groups.ACCOUNTS, Groups.SYSTEM_ADMIN],
      userTaskTypes: [UserTaskType.CREATE_AVECS_INVOICE, UserTaskType.CRAETE_HANDOVER_INVOICE, UserTaskType.CREATE_PARTIAL_HANDOVER_INVOICE]
    }
  },
  {
    path: "invoice/edit/:adhoc/:invoiceId",
    component: InvoiceEditComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS, Groups.SYSTEM_ADMIN] }
  },
  {
    path: "adhoc-user-task/:userTaskId",
    component: AdhocUserTaskComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.ADHOC_USER_TASK_FOR_A_FILE] }
  },
  {
    path: "adhoc-user-task-general/:userTaskId",
    component: AdhocUserTaskComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.ADHOC_USER_TASK_GENERAL] }
  },
  {
    path: "allocate-payment/:fileId",
    component: AllocatePaymentComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS, Groups.SYSTEM_ADMIN], userTaskTypes: [UserTaskType.ALLOCATE_PAYMENT] }
  },
  {
    path: "approve-reject-admin-transaction/:fileId",
    component: ApproveRejectAdminTransactionComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS_ADMIN], userTaskTypes: [UserTaskType.APPROVE_REJECT_ADMIN_TRANSACTION] }
  },
  {
    path: "finance-sumamry/:fileId",
    component: FinanceSummaryComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS, Groups.SYSTEM_ADMIN] }
  },
  {
    path: "add-clearing-agent/:fileId",
    component: AddClearingAgentComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.HANDOVER, Groups.CARGO], userTaskTypes: [UserTaskType.ADD_HANDOVER_AGENT, UserTaskType.ADD_PARTIAL_HANDOVER_AGENT] }
  },
  {
    path: "check-border-post-from-auto-responder/:fileId",
    component: CheckBorderPostComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.CHECK_BORDER_POST_FROM_AUTO_RESPONDER] }
  },
  {
    path: "inspection-route-update-fail/:fileId",
    component: InspectionRouteUpdateFailComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.INSPECTION_ROUTE_UPDATE_FAIL] }
  },
  {
    path: "whatsapp-check-border-post-accept-terms/:fileId",
    component: CheckBorderPostComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.CHECK_BORDER_POST_ACCEPT_TERMS_TEMPLATE] }
  },
  {
    path: "whatsapp-talk-to-agent-for-a-file/:fileId",
    component: WhatsappTalkToAgentForAFileComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.WHATSAPP_TALK_TO_AGENT_FOR_A_FILE] }
  },
  {
    path: "whatsapp-talk-to-agent-general/:chatId",
    component: WhatsappTalkToAgentGeneralComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ALL], userTaskTypes: [UserTaskType.WHATSAPP_TALK_TO_AGENT_GENERAL] }
  },
  {
    path: "whatsapp-check-document/:fileId",
    component: WhatsappCheckDocumentComponent,
    canActivate: [AuthManager],
    data: {
      roles: [Groups.WHATSAPP_AGENT, Groups.SYSTEM_ADMIN],
      userTaskTypes: [
        UserTaskType.WHATSAPP_CHECK_PROOF_OF_PAYMENT_DOCUMENT,
        UserTaskType.BORDER_POST_DUTIES,
        UserTaskType.WHATSAPP_CHECK_PASSPORT_ID_DOCUMENT,
        UserTaskType.WHATSAPP_CHECK_OTHER_DOCUMENT
      ]
    }
  },
  {
    path: "bfj-cif",
    component: BfjCifComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS_ADMIN] }
  },
  {
    path: "fix-storage-invoice-issues/:fileId",
    component: FixStorageInvoiceIssuesComponent,
    canActivate: [AuthManager],
    data: { roles: [Groups.ACCOUNTS], userTaskTypes: [UserTaskType.FIX_STORAGE_INVOICE_AUTO_GEN] }
  },
  {
    path: "manage-files-preferences",
    component: ManageFilePreferencesComponent
  }
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);