import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Groups } from '../../../enums';
import { Permission, UserDetails } from '../../../interfaces/authentication.interface';
import { LegalEntity } from '../../../interfaces/legal-entity-interfaces';
import { HttpResponse } from '../../../interfaces/result-interfaces';
import { LogBase } from '../../logger.service';
import { AuthenticationApiService } from './authentication.api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private isAuthenticated: boolean = false;
  private user: UserDetails;
  private legalEntityDetails: LegalEntity;

  constructor(
    private log: LogBase,
    private authApi: AuthenticationApiService,
    private router: Router
  ) { }

  public isAuthValid(): boolean { return this.isAuthenticated; }
  public isAuthInvalid(): boolean { return !this.isAuthenticated; }
  public clearUser() { this.user = undefined; }
  public get getUser(): UserDetails | undefined { return this.user; }
  private set setUser(userInformation: UserDetails) { this.user = userInformation; }

  private async getLegalEntityDetails(): Promise<LegalEntity> {
    if (this.legalEntityDetails) return this.legalEntityDetails;
    const legalEntityDetails = await this.authApi.getLegalEntityById(this.getSessionToken, this.getUserId, this.getLegalEntityId);
    if (legalEntityDetails && legalEntityDetails?.errorCode == 0) { this.legalEntityDetails = legalEntityDetails.result; return legalEntityDetails.result; }
    return undefined;
  }

  public async getFullName(): Promise<string> {
    const legalEntityDetails = await this.getLegalEntityDetails();
    if (!legalEntityDetails) return null;
    else return `${legalEntityDetails.name} ${legalEntityDetails.middleNames ? `${legalEntityDetails.middleNames} ` : ''}${legalEntityDetails.lastName}`;
  }

  public getEditPermissions(): Permission[] {
    if (this.user && this.user?.editPermission) return this.user.editPermission;
    else if (sessionStorage.getItem('editPermission')) return JSON.parse(sessionStorage.getItem('editPermission')) as Array<Permission>;
    else return undefined;
  }

  public getViewPermissions(): Permission[] {
    if (this.user && this.user?.viewPermission) return this.user.viewPermission;
    else if (sessionStorage.getItem('editPermission')) return JSON.parse(sessionStorage.getItem('viewPermission')) as Array<Permission>;
    else return undefined;
  }

  private invalidateAuth(): void {
    this.isAuthenticated = false;
    this.user = undefined;
    this.legalEntityDetails = undefined;
  }

  private validateAuth(): void { this.isAuthenticated = true; }

  private clearSessionStorage(): void { sessionStorage.clear(); }

  public get getSessionToken(): string {
    if (this.user && this.user?.token) return this.user.token;
    else if (sessionStorage.getItem('token')) return sessionStorage.getItem('token');
    else return undefined;
  }

  public get getUserId(): number {
    if (this.user && this.user?.userId) return this.user.userId;
    else if (sessionStorage.getItem('userId')) return +sessionStorage.getItem('userId');
    else return undefined;
  }

  public get getLegalEntityId(): number {
    if (this.user && this.user?.legalEntityId) return this.user.legalEntityId;
    else if (sessionStorage.getItem('legalEntityId')) return +sessionStorage.getItem('legalEntityId');
    else return undefined;
  }

  public get getUsername(): string {
    if (this.user && this.user?.username) return this.user.username;
    else if (sessionStorage.getItem('username')) return sessionStorage.getItem('username');
    else return undefined;
  }

  public get getLegalEntityIdForApiHeaders(): string {
    if (this.user && this.user?.legalEntityId) return this.user.legalEntityId.toString();
    else if (sessionStorage.getItem('legalEntityId')) return sessionStorage.getItem('legalEntityId');
    else return undefined;
  }

  private setupSessionStorage(userInformation: UserDetails): void {
    sessionStorage.setItem('userId', userInformation.userId.toString());
    sessionStorage.setItem('legalEntityId', userInformation.legalEntityId.toString());
    sessionStorage.setItem('username', userInformation.username);
    sessionStorage.setItem('token', userInformation.token);
    sessionStorage.setItem('tokenExpiry', userInformation.tokenExpiry.toString());
    sessionStorage.setItem('editPermission', JSON.stringify(userInformation.editPermission));
    sessionStorage.setItem('viewPermission', JSON.stringify(userInformation.viewPermission));
  }

  private retrieveSessionStorage() {
    this.setUser = {
      userId: +sessionStorage.userId,
      legalEntityId: +sessionStorage.legalEntityId,
      username: sessionStorage.username,
      token: sessionStorage.token,
      tokenExpiry: sessionStorage.tokenExpiry,
      editPermission: JSON.parse(sessionStorage.editPermission) as Permission[],
      viewPermission: JSON.parse(sessionStorage.viewPermission) as Permission[]
    };
    return this.getUser;
  }

  private convertToUserDetails(response: HttpResponse): UserDetails {
    return {
      userId: response.userId,
      legalEntityId: response.legalEntityId,
      token: response.token,
      tokenExpiry: response.tokenExpires,
      username: response.userName,
      editPermission: response.editPermission,
      viewPermission: response.viewPermission
    };
  }

  public isTokenValid(): boolean {
    if (this.user && this.user.token) return true;
    else {
      let user = this.retrieveSessionStorage();
      if (user && user.token) return true;
      else return false;
    }
  }

  public logout() {
    this.clearSessionStorage();
    this.invalidateAuth();
    this.clearUser();
    this.router.navigate(['/login']);
  }

  public async validateSession(): Promise<UserDetails> {
    try {
      if (this.getSessionToken && this.getLegalEntityId) {
        let validation = await this.authApi.validateSession(this.getSessionToken, this.getLegalEntityId);
        if (validation && validation.errorCode == 0) {
          this.validateAuth();
          this.setupSessionStorage(this.convertToUserDetails(validation));
          this.setUser = this.convertToUserDetails(validation);
          return this.getUser;
        }
      }
    } catch (error) {
      this.log.error(error);
    }
    this.clearSessionStorage();
    this.invalidateAuth();
    this.router.navigate(['/login']);
    return undefined;
  }

  public async authenticateLogin(username: string, password: string): Promise<UserDetails> {
    try {
      let userLogin = await this.authApi.authenticate(username, password);
      if (userLogin && userLogin.errorCode == 0) {
        this.validateAuth();
        this.setupSessionStorage(this.convertToUserDetails(userLogin));
        this.setUser = this.convertToUserDetails(userLogin);
        return this.getUser;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Permissions checks

  canEdit(permission: Groups): boolean {
    let found = false;
    this.user.editPermission.forEach(perm => {
      if (perm.id == permission) found = true;
    });
    return found;
  }

  canView(permission: Groups): boolean {
    let found = false;
    this.user.viewPermission.forEach(perm => {
      if (perm.id == permission) found = true;
    });
    return found;
  }
}
