import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WhatsappProductService } from './whatsapp-product.service';

@Component({
  selector: 'whatsapp-product',
  templateUrl: './whatsapp-product.component.html',
  styleUrls: ['./whatsapp-product.component.scss']
})
export class WhatsappProductComponent implements OnInit {

  @Output() onProductSelect: EventEmitter<void> = new EventEmitter();

  constructor(public whatsappProductService: WhatsappProductService) { }

  async ngOnInit() {
    await this.whatsappProductService.getWAProducts();
    this.onProductSelect.emit();
  }

  productSelect(event: any) {
    this.whatsappProductService.selectedProduct = event.value
    this.onProductSelect.emit();
  }
}