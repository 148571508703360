import { Injectable } from "@angular/core";
import "rxjs/add/operator/toPromise";
import { ConfigService } from "./config.service";
import { LogBase } from "./logger.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { WebApiService } from "./web.api.service";
import { WebApi2Service } from "./web.api2.service";
import { HttpResponse, RouteLegPersonAssignment } from "../interfaces/result-interfaces";
import { AuthenticationService } from "./api/auth/authentication.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ApiService {

  user: string;

  constructor(
    private config: ConfigService,
    private router: Router,
    private log: LogBase,
    private webApi: WebApiService,
    private webApi2: WebApi2Service,
    private authService: AuthenticationService,
    private http: HttpClient
  ) { }

  async ngOnInit() { this.user = this.authService.getUsername; }

  async getLegalEntitiesDisplay() {
    try {
      var url = this.config.getServerUrl();
      const contacts = await this.webApi.post(this.user, url + "api/LegalEntity/GetLegalEntities", null);
      if (contacts.errorCode == 0) {
        return contacts.result;
      } else if (contacts.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getTitles() {
    try {
      var url = this.config.getServerUrl() + "api/LegalEntity/GetTitles";
      var Titles = await this.webApi.post(this.user, url, null);
      if (Titles.errorCode == 0) {
        return Titles.result;
      } else if (Titles.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getDocumentUploadChoices() {
    try {
      var url = this.config.getServerUrl();
      const uploadChoices = await this.webApi.post(this.user, url + "api/Documents/getDocumentUploadChoices", null);
      return uploadChoices;
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetFileEmailAdresses(id) {
    try {
      var url = this.config.getServerUrl() + "api/Files/GetFileEmailAdresses?FileId=" + id;
      const emails = await this.webApi.post(this.user, url, null);
      if (emails.errorCode == 0) {
        return emails.result;
      } else if (emails.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async getFilesByQuery(query: string) {
    try {
      const url = `${this.config.getServerUrl()}api/Files/SearchFiles?search=${query}`;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getFileById(fileId: any) {
    try {
      var url = this.config.getServerUrl() + "api/Files/GetFileDetailsById?FileId=" + fileId;
      var retval = await this.webApi.post(this.user, url, null);
      var file = retval.result;
      if (file != undefined) {
        return file;
      } else {
        return undefined;
      }
    } catch (error) { }
  }

  async getFileConsigneeDetailsById(id) {
    try {
      var url = this.config.getServerUrl() + "api/Files/getFileConsigneeDetailsById?ConsigneeLegalEntityId=" + id;
      var Consignee = await this.webApi.post(this.user, url, null);
      if (Consignee.errorCode == 0) {
        return Consignee.result;
      } else if (Consignee.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getFileCargo(id) {
    try {
      var url = this.config.getServerUrl() + "api/Files/GetFileCargo?FileId=" + id;
      var Cargo = await this.webApi.post(this.user, url, null);
      if (Cargo.errorCode == 0) {
        return Cargo.result;
      } else if (Cargo.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getEmailFromChoices() {
    try {
      var url = this.config.getServerUrl() + "api/Emails/GetEmailFromChoices";
      var res = await this.webApi.post(this.user, url, null);
      if (res != undefined) {
        return res.result;
      } else {
        return false;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchPerson(search: any): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/SearchPerson?search=" + search;
      return await this.webApi2.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchMBL(search: any) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/SearchMBL?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async MBLExists(search: any) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/MBLExists?MBLNumber=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async ContainerExists(search: any) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/ContainerExists?ContainerNumber=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchForContainer(search: any) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/SearchForContainer?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVehicleMakes() {
    try {
      const url = this.config.getServerUrl() + "api/transport/getVehicleMakes";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVehicleModels(makeId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/getVehicleModels?MakeId=" + makeId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getContainerItems(id: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/getContainerItems?Id=" + id;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getContainerSeals(id: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/getContainerSeals?Id=" + id;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getISOContainerGroups() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getISOContainerGroups";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getContainers() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getContainers";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addNewContainer(ContainerNumber: string, SelectedISOCode: number) {
    try {
      const url = this.config.getServerUrl() + "api/Files/addNewContainer?ContainerNumber=" + ContainerNumber + "&ISOContainerGroupId=" + SelectedISOCode;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getActualBorderPosts() {
    try {
      const url = this.config.getServerUrl() + "api/transport/getActualBorderPosts";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVATRate() {
    try {
      const url = this.config.getServerUrl() + "api/finance/getVATRate";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getNotesByLegalEntityId(contactId: any) {
    try {
      const url = this.config.getServerUrl() + "api/Files/getNotesByLegalEntityId?LegalEntityId=" + contactId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async saveNoteForLegalEntity(note, LegalEntityId, selectedNoteType) {
    try {
      const url = this.config.getServerUrl() + "api/Files/saveNoteForLegalEntity?Note=" + note + "&LegalEntityId=" + LegalEntityId + "&NoteTypeId=" + selectedNoteType;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getNoteTypes() {
    try {
      const url = this.config.getServerUrl() + "api/Files/getNoteTypes";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async saveNoteDataForFile(note, fileId, selectedNoteType) {
    try {
      let payload: any = {
        Note: note,
        FileId: fileId,
        SelectedNoteTypeId: selectedNoteType
      };
      const url = this.config.getServerUrl() + "api/Files/saveNoteForFile";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      }
      else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getInspectionDetailsByFileId(fileId) {
    try {
      const url = this.config.getServerUrl() + "api/inspection/getInspectionDetailsByFileId?FileId=" + fileId;
      const retval = await this.webApi2.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getInspectionFileByFilePath(filepath, mimetype) {
    try {
      const token = this.authService.getSessionToken;
      const legalEntityId = this.authService.getLegalEntityIdForApiHeaders;
      const url = this.config.getServerUrl() + "api/inspection/getInspectionFileByPath?filepath=" + filepath + "&mimetype=" + mimetype;
      return this.http.get(url, {
        responseType: 'arraybuffer', headers: { "Token": token, "Id": legalEntityId }
      }).toPromise();
    }
    catch (e) {
      this.log.error(e);
    }
  }

  async getUsernameByLegalEntityId(LegalEntityId) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/getUsernameByLegalEntityId?LegalEntityId=" + LegalEntityId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorVessels() {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getVessels";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorVoyages(historical: boolean, vesselId: number) {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getVoyages?Historical=" + historical + "&VesselId=" + vesselId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorListItems() {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getCommunicatorListItems";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorListItemsByVessel(payload) {
    try {
      var variables = JSON.stringify(payload);
      const url = this.config.getServerUrl() + "api/communicator/getCommunicatorListItemsByVessel";
      const retval = await this.webApi.post(this.user, url, variables);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorListItemsByDateRange(payload: any) {
    try {
      var variables = JSON.stringify(payload);
      const url = this.config.getServerUrl() + "api/communicator/getCommunicatorListItemsByDateRange";
      const retval = await this.webApi.post(this.user, url, variables);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorCountries() {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getCommunicatorCountries";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorCarriers() {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getCommunicatorCarriers";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCommunicatorSuppliers() {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getCommunicatorSuppliers";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async sendEmail(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/communicator/sendEmail";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return retval;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async sendTemplatedEmail(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/communicator/sendTemplatedEmail";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getFuelTypes() {
    try {
      const url = this.config.getServerUrl() + "api/transport/getFuelTypes";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getTransmissionTypes() {
    try {
      const url = this.config.getServerUrl() + "api/transport/getTransmissionTypes";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVesselNames() {
    try {
      const url = this.config.getServerUrl() + "api/transport/getVesselNames";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVoyagesByVesselId(id: any) {
    try {
      const url = this.config.getServerUrl() + "api/transport/getVoyageNumbers?Id=" + id;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVoyagesHistoryByVoyageId(voyageId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/getVoyagesHistoryByVoyageId?VoyageId=" + voyageId;
      const retval = await this.webApi2.post(this.user, url, null);
      if (!retval) {
        return { errorCode: 2, errorMessage: "Failed to get response from server" };
      }
      else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVesselInfo(vesselId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/getVesselInfo?VesselId=" + vesselId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async insertNewVehicleModel(payload) {
    try {
      var variables = JSON.stringify(payload);
      const url = this.config.getServerUrl() + "api/transport/insertNewVehicleModel";
      const retval = await this.webApi.post(this.user, url, variables);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async sendFreeTextEmail(toEmail, fromEmail, body, subject, fileId, contactId) {
    try {
      var payload = {
        body: body,
        subject: subject,
        fromEmail: fromEmail,
        toEmail: toEmail,
        fileId: fileId,
        legalEntityId: contactId,
      };
      var variables = JSON.stringify(payload);
      const url = this.config.getServerUrl() + "api/Emails/sendFreeTextEmail";
      const retval = await this.webApi.post(this.user, url, variables);
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode == 15) {
        return false;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkReferenceNumber(refNo, SupplierId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/checkReferenceNumber?RefNo=" + refNo + '&SupplierId=' + SupplierId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkChassisNo(chassisNo) {
    try {
      const url = this.config.getServerUrl() + "api/transport/checkChassisNo?chassisNo=" + chassisNo;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getShippingInfo(id) {
    try {
      var url = this.config.getServerUrl() + "api/Files/getFileShippingInfo?FileId=" + id;
      const ShippingInfo = await this.webApi.post(this.user, url, null);
      if (ShippingInfo.errorCode == 0) {
        return ShippingInfo.result;
      } else if (ShippingInfo.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAllFileDocuments(id) {
    try {
      var url = this.config.getServerUrl() + "api/Files/GetAllInternalFileDocuments?FileId=" + id;
      const Documents = await this.webApi.post(this.user, url, null);
      if (Documents.errorCode == 0) {
        return Documents.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getColors() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getColors";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addVoyage(payload) {
    try {
      const url = this.config.getServerUrl() + "api/transport/addVoyage";
      const retval = await this.webApi2.post(this.user, url, payload);
      if (!retval) {
        return { errorCode: 2, errorMessage: "Failed to get response from server" };
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async updateVoyage(payload) {
    try {
      const url = this.config.getServerUrl() + "api/transport/updateVoyage";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBulkUpdaterListItemsByVessel(payload) {
    try {
      const url = this.config.getServerUrl() + "api/bulkUpdater/getBulkUpdaterListItemsByVessel";
      return await this.webApi.post(this.user, url, JSON.stringify(payload));
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCargoByVoyageId(VoyageId: number) {
    try {
      const url = this.config.getServerUrl() + "api/Files/getCargoByVoyageId?VoyageId=" + VoyageId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getMBLNumbers(voyageId) {
    try {
      const url = this.config.getServerUrl() + "api/Files/getMBLNumbers?VoyageId=" + voyageId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBulkUpdaterMBLNumbers(historicalVoyages, voyageId) {
    try {
      let data = {
        VoyageId: voyageId,
        HistoricalVoyages: historicalVoyages,
      };
      let payload = JSON.stringify(data);
      const url = this.config.getServerUrl() + "api/bulkUpdater/getBulkUpdaterMBLNumbers";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async bulkUpdateFilesETA(payload) {
    try {
      const url = this.config.getServerUrl() + "api/bulkUpdater/bulkUpdateFilesETA";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addDocumentCategory(documentCategory: any, path: any, show: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/addDocumentCategory?DocumentCategory=" + documentCategory + "&Path=" + path + "&Show=" + show;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCountries() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getCountries";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async updateShippingDetails(shippingPayload: any) {
    try {
      var url = this.config.getServerUrl() + "api/Files/updateShippingDetails";
      var playload = JSON.stringify(shippingPayload);
      const retval = await this.webApi2.post(this.user, url, playload);
      if (retval && retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      }
      else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBorderAgents() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getBorderAgents";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addVehicleModel(Name: any, ManufacturerId: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/addVehicleModel?ManufacturerId=" + ManufacturerId + "&Name=" + Name;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addVehicleManufacturer(Name: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/addVehicleManufacturer?Name=" + Name;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCourierCompanies() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getCourierCompanies";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAgents() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getAgents";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getDocumentSources() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getDocumentSources";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async requestANFFromShippingLine(payload) {
    try {
      const url = this.config.getServerUrl() + "api/bulkUpdater/requestANFFromShippingLine";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getShippingCompanyVessels(shippingCompanyid: any) {
    try {
      var url = this.config.getServerUrl() + "api/reference/getShippingCompanyVessels?shippingCompanyid=" + shippingCompanyid;
      const ShippingInfo = await this.webApi.post(this.user, url, null);
      if (ShippingInfo.errorCode == 0) {
        return ShippingInfo.result;
      } else if (ShippingInfo.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVesselVoyages(vesselId: any) {
    try {
      var url = this.config.getServerUrl() + "api/reference/getVesselVoyages?VesselId=" + vesselId;
      const ShippingInfo = await this.webApi.post(this.user, url, null);
      if (ShippingInfo.errorCode == 0) {
        return ShippingInfo.result;
      } else if (ShippingInfo.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  createHandOverLetter = async (payload: any, sendEmail: boolean) => {
    try {
      const url = this.config.getServerUrl() + "api/Files/createHandOverLetter?SendEmail=" + sendEmail;
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  createArrivalNotification = async (payload: any, sendEmail: boolean) => {
    try {
      const url = this.config.getServerUrl() + "api/Files/createArrivalNotification?SendEmail=" + sendEmail;
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  createWindowSheet = async (payload) => {
    try {
      const url = this.config.getServerUrl() + "api/Files/createWindowSheet";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  createDeliveryReleaseOrder = async (payload, sendEmail: boolean) => {
    try {
      const url = this.config.getServerUrl() + "api/Files/createDeliveryReleaseOrder?SendEmail=" + sendEmail;
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  async getShippingLinesEntity() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getShippingLinesEntity";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  createLetterOfAuthorityToClearVehicle = async (payload) => {
    try {
      const url = this.config.getServerUrl() + "api/Files/createLetterOfAuthorityToClearVehicle";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  async getVesselCompany() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getVesselCompany";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async changeLegalEntityCompanyTypeId(id: any, LegalEntityId: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/changeLegalEntityCompanyTypeId?Id=" + id + "&LegalEntityId=" + LegalEntityId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getShippingLineVessels(Id: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/getShippingLineVessels?ShippingLineId=" + Id;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async deleteShippingCompany(Id: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/deleteShippingCompany?ShippingLineId=" + Id;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async reassignShippingCompanyVessels(FromId: any, ToId: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/reassignShippingCompanyVessels?OldShippingLineId=" + FromId + "&NewShippingLineId" + ToId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addShippingCompany(shippingLineName: any, ShippingLineLegalEntityId: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/addShippingCompany?ShippingLineName=" + shippingLineName + "&ShippingLineLegalEntityId=" + ShippingLineLegalEntityId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async generateHBLMultiItem(fileId: any, version: any, EmailOriginal: any) {
    try {
      const url = this.config.getServerUrl() + "api/Files/generateHBLMultiItem?FileId=" + fileId + "&HBLDocumentVersion=" + version + "&EmailOriginal=" + EmailOriginal;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async generateHBL(fileId: any, version: any, EmailOriginal: any) {
    try {
      const url = this.config.getServerUrl() + "api/Files/generateHBL?FileId=" + fileId + "&HBLDocumentVersion=" + version + "&EmailOriginal=" + EmailOriginal;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchDescriptions(query: string) {
    try {
      const url = this.config.getServerUrl() + "api/reference/searchDescriptions?Query=" + query;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async createFile(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/Files/createFile";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return -1;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async generateFileCoverVehicle(payload) {
    try {
      const url = this.config.getServerUrl() + "api/Files/generateFileCoverVehicle";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  async generateFileCoverContainer(payload) {
    try {
      const url = this.config.getServerUrl() + "api/Files/generateFileCoverContainer";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async updateContainerItem(payload) {
    try {
      var variables = JSON.stringify(payload);
      var url = this.config.getServerUrl() + "api/Files/updateContainerItem";
      let roro = await this.webApi.post(this.user, url, variables);
      if (roro.errorCode == 0) {
        return roro.result;
      } else if (roro.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getRegions(selectedCountry: any) {
    try {
      const url = this.config.getServerUrl() + "api/reference/getRegions?selectedCountry=" + selectedCountry;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getVCardInfo(legalEntityId: number) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/getVCardInfo?LegalEntityId=" + legalEntityId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getUserFilePermissions(legalEntityId: number) {
    try {
      const url = this.config.getServerUrl() + "api/Files/getUserFilePermissions?LegalEntityId=" + legalEntityId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async deletePermission(UserPermissionId: number) {
    try {
      const url = this.config.getServerUrl() + "api/Files/deletePermission?UserPermissionId=" + UserPermissionId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async updateUserFilePermission(UserPermissionId: any, Active: any) {
    try {
      const url = this.config.getServerUrl() + "api/Files/updateUserFilePermission?UserPermissionId=" + UserPermissionId + "&Active=" + Active;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getSuppliers() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getSuppliers";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getNewlyCreatedPersonById(legalEntityId: any) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/GetNewlyCreatedPersonById?LegalEntityId=" + legalEntityId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
  async createNewVesselService(vesselCompanyId, vesselName) {
    try {
      var payload = {
        vesselCompanyId: vesselCompanyId,
        vesselName: vesselName,
      };
      var vesselPayload = JSON.stringify(payload);
      const url = this.config.getServerUrl() + "api/reference/createVessel";
      const retval = await this.webApi.post(this.user, url, vesselPayload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async editVoyage(voyageId, VoyageName, voyageETA, assignee) {
    try {
      var payload = {
        voyageId: voyageId,
        VoyageName: VoyageName,
        voyageETA: voyageETA,
        Assignee: assignee,
      };
      var editVoyagePayload = JSON.stringify(payload);
      const url = this.config.getServerUrl() + "api/reference/editVoyage";
      const retval = await this.webApi.post(this.user, url, editVoyagePayload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async viewClientDocument(documentId) {
    try {
      const url = this.config.getServerUrl() + "api/Documents/DTViewDocument?documentId=" + documentId;
      let res: any = await this.webApi.post(this.user, url, "");
      if (res) {
        return res.result;
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async assignFileToAgent(selectedAgent: any, fileId: any) {
    try {
      var url = this.config.getServerUrl() + "api/LegalEntity/assignFileToAgent?LegalEntityId=" + selectedAgent + "&FileId=" + fileId;
      var retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async convertFileClearingStatus(ClearingStatusId: number, fileId: number) {
    try {
      var url = this.config.getServerUrl() + "api/tasks/convertFileClearingStatus?ClearingStatusId=" + ClearingStatusId + "&FileId=" + fileId;
      var retval = await this.webApi.post(this.user, url, null);
      if (retval && retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      }
      else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async restartFileWorkflow(ClearingStatusId: number, fileId: number) {
    try {
      var url = this.config.getServerUrl() + "api/tasks/RestartFileWorkflow?ClearingStatusId=" + ClearingStatusId + "&FileId=" + fileId;
      var retval = await this.webApi.post(this.user, url, null);
      if (retval && retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      }
      else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAllVesselCompany() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getAllVesselCompanies";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getInvoiceExchangeRates(): Promise<HttpResponse | null> {
    try {
      const url = this.config.getServerUrl() + "api/currency/getInvoiceExchangeRates";
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getWebsiteOTPLink(legalEntityId) {
    try {
      const url = this.config.getServerUrl() + "api/auth/createWebsiteOTP?LegalEntityId=" + legalEntityId;
      const retval = await this.webApi2.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getWebsiteOTPForDeeplink(legalEntityId): Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/auth/otp?legalEntityId=${legalEntityId}`;
      const retval = await this.webApi2.get(this.user, url);
      if (retval.errorCode == 0) {
        return retval;
      } else if (retval.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkIfVesselHasVoyagesWithCargo(vesselId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/checkIfVesselHasVoyagesWithCargo?VesselId=" + vesselId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async deleteVessel(vesselId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/deleteVessel?VesselId=" + vesselId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkIfVoyageHasCargo(VoyageId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/checkIfVoyageHasCargo?VoyageId=" + VoyageId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async deleteVoyage(VoyageId) {
    try {
      const url = this.config.getServerUrl() + "api/transport/deleteVoyage?VoyageId=" + VoyageId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async reassignVoyage(selectedVoyageId: any, selectedReassignVoyageId: any) {
    try {
      const url = this.config.getServerUrl() + "api/transport/reassignVoyage?SelectedVoyageId=" + selectedVoyageId + "&SelectedReassignVoyageId=" + selectedReassignVoyageId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchPlace(search) {
    try {
      const url = this.config.getServerUrl() + "api/locations/SearchPlace?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async locationssearchPort(search) {
    try {
      const url = this.config.getServerUrl() + "api/locations/SearchPort?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchBorder(search) {
    try {
      const url = this.config.getServerUrl() + "api/locations/SearchBorder?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchWarehouse(search) {
    try {
      const url = this.config.getServerUrl() + "api/locations/SearchWarehouse?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchAddress(search) {
    try {
      const url = this.config.getServerUrl() + "api/locations/SearchAddress?search=" + search;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addLocation(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/Locations/AddLocation";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getLocationById(locationId) {
    try {
      const url = this.config.getServerUrl() + "api/locations/GetLocationById?locationId=" + locationId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAssignedVoyages() {
    try {
      var url = this.config.getServerUrl() + "api/transport/getAssignedVoyages";
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode === 0) {
        return response.result;
      } else if (response.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getVoyageAssignees() {
    try {
      const url = this.config.getServerUrl() + "api/reference/getVoyageAssignees";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getActiveUserFiles(RoundRobinUserId: any, GroupId: any) {
    try {
      const url = this.config.getServerUrl() + "api/tasks/GetAndFilterAllFilesWithAssignedUsers?RoundRobinUserId=" + RoundRobinUserId + "&GroupId=" + GroupId;
      const retval = await this.webApi2.post(this.user, url, null);
      if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
      return null;
    }
  }

  async getTemplateList(TemplateTypeId: number) {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getTemplateList?TemplateTypeId=" + TemplateTypeId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getTemplateContent(Id: any) {
    try {
      const url = this.config.getServerUrl() + "api/communicator/getTemplateContent?Id=" + Id;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCurrentVoyages() {
    try {
      const url = this.config.getServerUrl() + "api/transport/getCurrentVoyages";
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkCurrentAppVersion(version: any) {
    try {
      const url = this.config.getServerUrl() + "api/system/checkCurrentAppVersion?Version=" + version;
      const retval = await this.webApi.post(this.user, url, null);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkForCommunicatorEmailRequirements(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/communicator/checkForCommunicatorEmailRequirements";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getOnDemandTemplates(onDemandTypeId) {
    try {
      const url = this.config.getServerUrl() + "api/ondemand/getOnDemandTemplates?onDemandTypeId=" + onDemandTypeId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCriteria(onDemandTemplateId) {
    try {
      const url = this.config.getServerUrl() + "api/ondemand/getCriteriaForOnDemandTemplate?onDemandTemplateId=" + onDemandTemplateId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getOnDemandWithPayload(payload, endpoint) {
    try {
      // dynamic endpoint
      let url = this.config.getServerUrl() + "api/" + endpoint;
      // dynamic parameters
      let keys = Object.keys(payload);
      // start params
      if (keys.length > 0) { url += "?"; }
      // add each
      keys.forEach(key => { url += (key + "=" + payload[key] + "&"); });
      // remove last extra "&" if there are keys
      if (keys.length > 0) { url = url.substring(0, url.length - 1); };
      // now do normal Http Get
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
    return [];
  }

  async getDropdownParamsForOnDemandCriteria(onDemandCriteriaId) {
    try {
      const url = this.config.getServerUrl() + "api/ondemand/getDropdownParamsForCriteria?onDemandCriteriaId=" + onDemandCriteriaId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async generateStandardExcelSheet(filename, columns, data) {
    try {
      let payload = {
        filename: filename,
        columns: columns,
        data: data
      };
      const url = this.config.getNodeUrl() + "generateStandardExcel";
      let retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      retval = JSON.parse(retval);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async generateCustomExcelSheet(filename, process, data) {
    try {
      let packageToSend = {
        process: process,
        payload: {
          data: data,
          filename: filename
        }
      };
      const url = this.config.getNodeUrl() + "generateCustomExcel";
      let retval = await this.webApi.post(this.user, url, JSON.stringify(packageToSend));
      retval = JSON.parse(retval);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getDocumentCategoryIdByPath(path: any) {
    try {
      const url = this.config.getServerUrl() + "api/Documents/getDocumentCategoryIdByPath?Path=" + path;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else
        if (retval.errorCode == -2) {
          this.router.navigate(["/login"]);
        } else {
          return undefined;
        }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getFileTasksComplete(fileId) {
    try {
      var url = this.config.getServerUrl() + "api/files/GetFileTasks?fileId=" + fileId;
      var response = await this.webApi2.get(this.user, url);
      if (response.errorCode === 0) {
        return response.result;
      } else if (response.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getCargoByMasterBill(masterbill: string) {
    try {
      const url =
        this.config.getServerUrl() +
        "api/Files/getCargoByMasterBill?Masterbill=" +
        masterbill;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addNewMasterbill(masterbill: string, voyageid: number) {
    try {
      const url =
        this.config.getServerUrl() +
        "api/Files/addNewMasterbill?Masterbill=" +
        masterbill +
        "&VoyageId=" +
        voyageid;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getRouteLegTransporterInformation(fileId: number): Promise<RouteLegPersonAssignment[]> {
    try {
      const url = this.config.getServerUrl() + 'api/files/transporterpersonleg?fileId=' + fileId;
      const retval = await this.webApi2.get(null, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(['/login']);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}
