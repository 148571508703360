import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Title } from "@angular/platform-browser";
import { DispatchPlanningComponent } from './dispatch-planning/dispatch-planning.component';

@Component({
  selector: 'dispatch-manager',
  templateUrl: './dispatch-manager.component.html',
  styleUrls: ['./dispatch-manager.component.css']
})

export class DispatchManagerComponent implements OnInit {

  @ViewChild(DispatchPlanningComponent) dispatchPlanning: DispatchPlanningComponent;

  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle("Dispatch Manager");
  }

  async ngOnInit() { }

  handleRefresh() {
    this.dispatchPlanning.getCurrentDispatchLoads();
  }
}