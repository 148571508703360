<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Manage Files Preferences</h1>
  </div>

  <div class="ui-g-12">
    <app-filter-search [ruleSetId]="ruleSet"></app-filter-search>
  </div>

  <div class="ui-g-12 height-5em">
    <button pButton
      [disabled]="!filterDataService.selectedFiles || filterDataService.selectedFiles.length == 0 || filterDataService.waitForResults"
      label="Manage Files" class="width-100 margin-top-1em height-100" (click)="toggleDisplay()"></button>
  </div>

  <div class="ui-g-12" *ngIf="display && selectedFileIds && !filterDataService.waitForResults">
    <file-preferences [fileIds]="selectedFileIds"></file-preferences>
  </div>
</div>