<div class="progress-spinner" *ngIf="spinnerLoad">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h2 class="page-header">WhatsApp Dashboard</h2>
    <div class="ui-g-12">
      <whatsapp-product (onProductSelect)="refresh()"></whatsapp-product>
    </div>
  </div>
  <div class="ui-g-12">
    <p-tabView>
      <p-tabPanel header="Room Statistics">
        <div class="drawer-container">
          <div class="ui-g-9 not-drawer">
            <p-table [loading]="roomStatsSpinner" #dt [value]="allChats" dataKey="id" [rows]="50"
              [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" class="paginator"
              [autoLayout]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [globalFilterFields]="['client', 'avecsUser', 'refNo', 'roomName', 'timeDiff']">
              <ng-template pTemplate="caption">
                <div class="p-d-flex table-header">
                  <span class="p-input-icon-left">
                    <button pRipple class="p-button-info" pTooltip="Refresh" tooltipPosition="left" pButton
                      type="button" [icon]="roomStatsSpinner ? 'fas fa-sync fa-spin' : 'fas fa-sync'"
                      (click)="getWAAllRoomChats()"></button>
                  </span>
                  <span class="p-input-icon-left p-ml-auto">
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                      placeholder="Search" style="color: #fff;" />
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr class="table-labels">
                  <th>Phone User</th>
                  <th>Avecs User</th>
                  <th>Created Date</th>
                  <th>Expiry Date</th>
                  <th>Reference No.</th>
                  <th>Room Name</th>
                  <th>Time from Inbound - Outbound</th>
                  <th>Actions</th>
                </tr>
                <tr class="table-labels">
                  <th>
                    <input pInputText class="width-100" type="text"
                      (input)="dt.filter($event.target.value, 'client', 'startsWith')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="dt.filter($event.target.value, 'avecsUser', 'startsWith')">
                  </th>
                  <th>
                    <p-calendar (onSelect)="onFilterDateSelect('table', $event, 'createdDate')" class="width-100"
                      (onClearClick)="dt.filter('', 'createdDate', 'contains')" [showButtonBar]="true"
                      [readonlyInput]="true" dateFormat="dd/mm/yy" appendTo="body">
                    </p-calendar>
                  </th>
                  <th>
                    <p-calendar (onSelect)="onFilterDateSelect('table', $event, 'expiryDate')" class="width-100"
                      (onClearClick)="dt.filter('', 'expiryDate', 'contains')" [showButtonBar]="true"
                      [readonlyInput]="true" dateFormat="dd/mm/yy" appendTo="body">
                    </p-calendar>
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="dt.filter($event.target.value, 'refNo', 'contains')">
                  </th>
                  <th>
                    <p-dropdown [options]="rooms" (onChange)="dt.filter($event.value, 'roomName', 'equals')"
                      [filter]="true" [autoDisplayFirst]="false" styleClass="p-column-filter" [showClear]="true"
                      class="filterDropdown" appendTo="body">
                    </p-dropdown>
                  </th>
                  <th>
                    <p-dropdown [options]="inbound" (onChange)="dt.filter($event.value, 'direction', 'equals')"
                      [filter]="true" [autoDisplayFirst]="false" styleClass="p-column-filter" [showClear]="true"
                      class="filterDropdown" appendTo="body">
                      <ng-template let-option pTemplate="item">
                        <span>&#123;&#123;option.label&#125;&#125;</span>
                      </ng-template>
                    </p-dropdown>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-room>
                <tr class="table-labels">
                  <td>{{room.client}}</td>
                  <td>{{room.avecsUser}}</td>
                  <td>{{room.createdDate}}</td>
                  <td>{{room.expiryDate}}</td>
                  <td>{{room.refNo}}</td>
                  <td>{{room.roomName}}</td>
                  <td>{{room.timeDiff ? room.timeDiff : 'No reply from client'}}</td>
                  <td>
                    <button pButton type="button" (click)="getChatMessages(room.chatId)" icon="far fa-eye"
                      pTooltip="View Messages" tooltipPosition="left"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td>No room statistics found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="drawer ui-g-3">
            <p-card header="Room Counts" styleClass="p-card-shadow">
              <div class="row totalCalls">
                <div class="ui-g ">
                  <div class="rooms">Total chats:</div>
                  <div class="room-totals">{{ roomCountTotal }}</div>
                </div>
              </div>
              <div class="ui-g lobbies" *ngFor="let rc of roomCounts">
                <div [style.color]="rc.rgb" class="rooms room-underline">{{ rc.roomName }}:
                </div>
                <div [style.color]="rc.rgb" class="room-totals">{{ rc.count }}</div>
              </div>
            </p-card>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Voyage Templates">
        <div class="ui-g">
          <p-table [loading]="voyageTemplatesSpinner" class="ui-g-12" #vt [value]="voyageTemplates" [rows]="50"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [autoLayout]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['templateName', 'vesselName', 'voyageNumber', 'templateCount']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex table-header">
                <span class="p-input-icon-left">
                  <button pRipple class="p-button-info " pTooltip="Refresh" tooltipPosition="left" pButton type="button"
                    [icon]="voyageTemplatesSpinner ? 'fas fa-sync fa-spin' : 'fas fa-sync'"
                    (click)="getWAVoyageTemplates()"></button>
                </span>
                <span class="p-input-icon-left p-ml-auto">
                  <input pInputText type="text" (input)="vt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search" style="color: #fff;" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr class="table-labels">
                <th>Template Count</th>
                <th>Template Name</th>
                <th>Vessel Name</th>
                <th>Voyage Number</th>
                <th>Actions</th>
              </tr>
              <tr class="table-labels">
                <th>
                  <input pInputText class="width-100" type="text"
                    (input)="vt.filter($event.target.value, 'templateCount', 'contains')">
                </th>
                <th>
                  <input pInputText type="text" class="width-100"
                    (input)="vt.filter($event.target.value, 'templateName', 'contains')">
                </th>
                <th>
                  <input pInputText type="text" class="width-100"
                    (input)="vt.filter($event.target.value, 'vesselName', 'contains')">
                </th>
                <th>
                  <input pInputText type="text" class="width-100"
                    (input)="vt.filter($event.target.value, 'voyageNumber', 'contains')">
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-temp>
              <tr class="table-labels">
                <td>{{temp.templateCount}}</td>
                <td>{{temp.templateName}}</td>
                <td>{{temp.vesselName}}</td>
                <td>{{temp.voyageNumber}}</td>
                <td>
                  <button pButton type="button" (click)="getWAVoyageTemplateDetails(temp)" icon="far fa-eye"
                    pTooltip="View Template Details" tooltipPosition="left"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class="ui-g-12" *ngIf="voyageDetails && voyageDetails.length > 0">
            <p-table #vd [value]="voyageDetails" [rows]="50" [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [globalFilterFields]="['number', 'fileNumber', 'clientName', 'body', 'sendDate']">
              <ng-template pTemplate="caption">
                <div class="p-d-flex table-header">
                  <span class="p-input-icon-left">
                    <label>Broadcasted {{ selectedVoyageTemplate.templateName}} Template for
                      {{ selectedVoyageTemplate.vesselName }} -
                      {{selectedVoyageTemplate.voyageNumber}}
                    </label>
                  </span>
                  <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="vd.filterGlobal($event.target.value, 'contains')"
                      placeholder="Search" style="color: #fff;" />
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr class="table-labels">
                  <th>File Number</th>
                  <th>Client Name</th>
                  <th>Client Number</th>
                  <th>Message</th>
                  <th>Last Sent Date</th>
                </tr>
                <tr>
                  <th>
                    <input pInputText class="width-100" type="text"
                      (input)="vd.filter($event.target.value, 'fileNumber', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="vd.filter($event.target.value, 'clientName', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="vd.filter($event.target.value, 'number', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="vd.filter($event.target.value, 'body', 'contains')">
                  </th>
                  <th>
                    <p-calendar (onSelect)="onFilterDateSelect('tableBroadCastTemplate', $event, 'sendDate')"
                      class="width-100" (onClearClick)="vd.filter('', 'sendDate', 'contains')" [showButtonBar]="true"
                      [readonlyInput]="true" dateFormat="dd/mm/yy" appendTo="body">
                    </p-calendar>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-temp>
                <tr class="table-labels">
                  <td>{{temp.fileNumber}}</td>
                  <td>{{temp.clientName}}</td>
                  <td>{{temp.number}}</td>
                  <td>{{temp.body}}</td>
                  <td>{{temp.sendDate}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Auto Responder Stats">
        <div class="ui-g">
          <div class="ui-g-8">
            <h3>Request History</h3>
            <p-table [loading]="autoResponderStatsSpinner" #ar [value]="autoResponderStats?.requests" [rows]="50"
              [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [autoLayout]="true"
              [scrollable]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [globalFilterFields]="['autoResponderOption', 'dbnNumber', 'fileOwner', 'whatsAppUserName', 'whatsAppUserNumber']">
              <ng-template pTemplate="caption">
                <div class="p-d-flex table-header">
                  <span class="p-input-icon-left">
                    <button pRipple class="p-button-info " pTooltip="Refresh" tooltipPosition="left" pButton
                      type="button" [icon]="autoResponderStatsSpinner ? 'fas fa-sync fa-spin' : 'fas fa-sync'"
                      (click)="getWAAutoResponderStats()"></button>
                  </span>
                  <span class="p-input-icon-left p-ml-auto">
                    <input pInputText type="text" (input)="ar.filterGlobal($event.target.value, 'contains')"
                      placeholder="Search" style="color: #fff;" />
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr class="table-labels">
                  <th pSortableColumn="dbnNumber">DBN Number
                    <p-sortIcon field="dbnNumber"></p-sortIcon>
                  </th>
                  <th pSortableColumn="fileOwner">File Owner
                    <p-sortIcon field="fileOwner"></p-sortIcon>
                  </th>
                  <th pSortableColumn="autoResponderOption">Auto Responder Option
                    <p-sortIcon field="autoResponderOption"></p-sortIcon>
                  </th>
                  <th pSortableColumn="requestAmount">Request Amount
                    <p-sortIcon field="requestAmount"></p-sortIcon>
                  </th>
                  <th pSortableColumn="whatsAppUserName">WhatsApp User Name
                    <p-sortIcon field="whatsAppUserName"></p-sortIcon>
                  </th>
                  <th pSortableColumn="whatsAppUserNumber">WhatsApp User Number
                    <p-sortIcon field="whatsAppUserNumber"></p-sortIcon>
                  </th>
                  <th>Actions</th>
                </tr>
                <tr class="table-labels">
                  <th>
                    <input pInputText class="width-100" type="text"
                      (input)="ar.filter($event.target.value, 'dbnNumber', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="ar.filter($event.target.value, 'fileOwner', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="ar.filter($event.target.value, 'autoResponderOption', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="ar.filter($event.target.value, 'requestAmount', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="ar.filter($event.target.value, 'whatsAppUserName', 'contains')">
                  </th>
                  <th>
                    <input pInputText type="text" class="width-100"
                      (input)="ar.filter($event.target.value, 'whatsAppUserNumber', 'contains')">
                  </th>
                  <!-- Actions -->
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-temp>
                <tr class="table-labels">
                  <td>
                    <file-number [fileNumber]="temp.dbnNumber"></file-number>
                  </td>
                  <td>{{temp.fileOwner}}</td>
                  <td>{{temp.autoResponderOption}}</td>
                  <td>{{temp.requestAmount}}</td>
                  <td>{{temp.whatsAppUserName}}</td>
                  <td>{{temp.whatsAppUserNumber}}</td>
                  <td>
                    <button pButton type="button" (click)="viewFile(temp.fileId)" icon="far fa-folder"
                      pTooltip="View {{ temp.dbnNumber }}" tooltipPosition="left"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td>No request history found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-g-4">
            <h3>Request History Breakdown</h3>
            <p-table [loading]="autoResponderStatsSpinner" #br [value]="autoResponderStats?.breakDowns"
              [autoLayout]="true" [scrollable]="true" [globalFilterFields]="['autoResponderOption', 'requestAmount']"
              dataKey="autoResponderOptionId">
              <ng-template pTemplate="caption">
                <div class="p-d-flex table-header">
                  <label>{{ totalAutoResponderRequests }} Requests</label>
                  <span class="p-input-icon-left p-ml-auto">
                    <input pInputText type="text" (input)="br.filterGlobal($event.target.value, 'contains')"
                      placeholder="Search" style="color: #fff;" />
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr class="table-labels">
                  <th pSortableColumn="autoResponderOption">Auto Responder Option
                    <p-sortIcon field="autoResponderOption"></p-sortIcon>
                  </th>
                  <th pSortableColumn="requestAmount">Request Amount
                    <p-sortIcon field="requestAmount"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-temp>
                <tr class="table-labels">
                  <td>{{temp.autoResponderOption}}</td>
                  <td>{{temp.requestAmount}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td>No request history breakdown found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog header="{{ chatMessages[0].whatsappUser }}'s Chat Messages" [dismissableMask]="true" [positionTop]="60"
  *ngIf="viewChatMessages" [contentStyle]="{width: '850px', height: '550px'}" [responsive]="true" [draggable]="false"
  (onHide)="viewChatMessages = false;" [(visible)]="viewChatMessages" [modal]="true">
  <div *ngFor="let m of chatMessages; let i = index">
    <div class="message" [ngClass]="{ 'inbound': m.direction == 0, 'outbound': m.direction == 1 }">
      <div class="message-box" [ngStyle]="{'color': m.undeliveredDate || m.error ? 'red' : 'black'}">
        <strong>{{ m.direction == 1 ? m.avecsUser : m.whatsappUser }}</strong>
        <pre>{{ m.body }}</pre>
        <div class="message-info">
          <span class="timedate">
            {{ formatMessageTime(m.sendDate) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</p-dialog>