import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { LogBase } from '../../services/logger.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../services/config.service'
import { Api2Service } from '../../services/api2.service';
import { ILocationComponent, LocationType, RouteLocation } from '../../interfaces/global.interfaces';
import { AuthenticationService } from '../../services/api/auth/authentication.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [ConfirmationService, DatePipe, MessageService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationComponent),
      multi: true
    }
  ]
})

export class LocationComponent implements OnInit, ControlValueAccessor {

  @Input() LocationTypeId: number;
  @Input() ShowFloatLabel: boolean = false;
  @Input() FloatLabel: string = '';
  @Input() Required: boolean = false;
  @Input() DisableDropDown: boolean = false;
  @Input('ngModel')
  set ngModel(ngModel: any) {
    this._ngModel = ngModel;
    if (ngModel == null) {
      this.selectedlocationtype = null;
      this.selectedlocation = null;
      this.LocationTypeId = 0;
      this.setLocationType();
    }
    else {
      //for setting the start route leg for the add master route in the route builder
      this.selectedlocationtype = this.locationTypes[this.ngModel.LocationTypeId - 1];
      this.setLocationType();
    }
    this.cd.detectChanges();
  }

  @Output() OnSelectLocation = new EventEmitter<any>();
  @Output() OnClearLocation = new EventEmitter<any>();
  @Output() ngModelChange = new EventEmitter<any>();

  private _ngModel
  get ngModel(): any {
    return this._ngModel;
  }

  disabled: boolean = false;
  setup: boolean = true;
  componentViewType: number = 0;

  LocationLabel = "Select Location";

  msgs: any[];
  showSpinner: boolean = true;

  locations: ILocationComponent[];
  selectedlocation: ILocationComponent;

  locationTypes: LocationType[] = [
    { id: 1, description: "Port" },
    { id: 2, description: "Border" },
    { id: 3, description: "Place" },
    { id: 4, description: "Warehouse" },
    { id: 5, description: "Address" },
    { id: 6, description: "Country" },
  ];
  selectedlocationtype: LocationType;

  constructor(
    private api: ApiService,
    private api2: Api2Service,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private config: ConfigService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService
  ) { }

  async writeValue(value: RouteLocation): Promise<void> {
    if (value) {
      this.selectedlocation = value
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  async ngOnInit() {
    try {
      // if (this.LocationTypeId == undefined) {
      //   this.LocationTypeId = 0;
      // }
      this.componentViewType = this.LocationTypeId;
      await this.getLocationTypes();
      await this.setLocationType();
    } catch (error) {
      this.showError('Error in ngOnitit', error);
      this.log.error(error);
    }
  }

  async getLocationTypes() {
    let retval = await this.api2.getLocationTypes();
    if (retval) {
      this.locationTypes = retval
    }
  }

  onLocationTypeChange() {
    this.selectedlocation = null;
    this.setLocationType();
  }

  async setLocationType() {
    try {
      // If the values have been previously set
      if (this.selectedlocationtype) {
        this.LocationTypeId = this.selectedlocationtype.id;
      }

      switch (this.LocationTypeId) {
        case 0:
          this.LocationLabel = 'Select Location' + (this.Required == true ? ' (*Required)' : '');
          break;
        case 1:
          this.LocationLabel = 'Select Port' + (this.Required == true ? ' (*Required)' : '');
          break;
        case 2:
          this.LocationLabel = 'Select Border' + (this.Required == true ? ' (*Required)' : '');
          break;
        case 3:
          this.LocationLabel = 'Select Place' + (this.Required == true ? ' (*Required)' : '');
          break;
        case 4:
          this.LocationLabel = 'Select Storage Point' + (this.Required == true ? ' (*Required)' : '');
          break;
        case 5:
          this.LocationLabel = 'Select Address' + (this.Required == true ? ' (*Required)' : '');
          break;
      }
    } catch (error) {
      this.showError('Error Setting Selected Location ', error);
      this.log.error(error);
    }
  }

  SetSelectedLocation(event: ILocationComponent) {
    try {
      this.selectedlocation = event;
      this.selectedlocation.LocationType = this.selectedlocationtype.description;
      this.ngModelChange.emit(this.selectedlocation);
      this.OnSelectLocation.emit(this.selectedlocation);
      this.setLocationType();
    } catch (error) {
      this.showError('Error Setting Selected Location ', error);
      this.log.error(error);
    }
  }

  async setComponentViewTypeLocation(event) {
    try {
      this.selectedlocation = event;
      this.ngModelChange.emit(this.selectedlocation);
      this.OnSelectLocation.emit(this.selectedlocation);
    } catch (error) {
      this.showError('Error Setting Selected Location ', error);
      this.log.error(error);
    }
  }

  async search(event: any) {
    try {
      switch (this.LocationTypeId) {
        case 1: //Port
          this.locations = await this.api.locationssearchPort(event.query);
          break;
        case 2: //Border
          this.locations = await this.api.searchBorder(event.query);
          break;
        case 3: //Place
          this.locations = await this.api.searchPlace(event.query);
          break;
        case 4: //Warehouse (Storage Point)
          this.locations = await this.api.searchWarehouse(event.query);
          break;
        case 5: //Address
          this.locations = await this.api.searchAddress(event.query);
          break;
      }
    } catch (error) {
      this.showError('Error getting Location Data', error);
      this.log.error(error);
    }
  }

  checkDisable() {
    if (this.LocationTypeId != 0 && !this.DisableDropDown) {
      return false
    } else if (this.DisableDropDown) {
      return true;
    } else if (!this.selectedlocationtype) {
      return true;
    }
    return false;
  }

  public clearLocations() {
    // this.setLocationType();
    this.selectedlocation = null;
    this.OnClearLocation.emit(null);
    this.cd.detectChanges();
  }

  clearLocation() {
    this.selectedlocation = null;
    this.OnClearLocation.emit(null)
    this.cd.detectChanges();
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = "Locations";
        var userName = this.authService.getUsername
        var date = new Date();
        var emailBody: string = 'Component Name: Locations<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: Locations';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      }
    });
  }
}