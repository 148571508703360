<summary-dashboard></summary-dashboard>
<div class="overlay" *ngIf="showSpinner">
  <p-progressSpinner class="content" [style]="{ width: '50px', height: '50px' }" strokeWidth="8" fill="#EEEEEE"
    animationDuration="2s"></p-progressSpinner>
</div>

<div class="ui-grid">
  <div class="ui-grid-col-8">
    <p-card class="calendar-class">
      <div class="Legend">
        <div class="ColoursLegend">
          <p>Low: RE Files &lt;= 10 </p>
          <div class="Dots">
            <div class="Green"></div>
          </div>
          <p>Medium: RE Files &gt; 10 and &lt; 50</p>
          <div class="Dots">
            <div class="Orange"></div>
          </div>
          <p>High: RE Files &gt;= 50</p>
          <div class="Dots">
            <div class="Red"></div>
          </div>
        </div>
      </div>
      <div class="ui-g dateHeader">
        <div class="ui-g-2 dateSelectInput" pTooltip="Enter a start date to define the date range"
          tooltipPosition="top">
          <span class="ui-float-label">
            <p-calendar [style]="{'width':'100%'}" [(ngModel)]="BeginDate" showButtonBar="true" dateFormat="yy/mm/dd"
              yearRange="2015:2030" [readonlyInput]="true" [yearNavigator]="true"></p-calendar>
            <label for="float-input">Start Date</label>
          </span>
        </div>
        <div class="ui-g-2 dateSelectInput" pTooltip="Enter an end date to close the date range" tooltipPosition="top">
          <span class="ui-float-label">
            <p-calendar [style]="{'width':'100%'}" class="dateSelectStart" [(ngModel)]="EndDate" showButtonBar="true"
              dateFormat="yy/mm/dd" yearRange="2015:2030" (onSelect)="SelectEndDate()" [readonlyInput]="true"
              [yearNavigator]="true"></p-calendar>
            <label for="float-input">End Date</label>
          </span>
        </div>
        <div class="ui-g-3 dateSelectInput">
          <button pButton type="button" label="Search" class="ui-button-rounded width100" [disabled]="!BeginDate"
            (click)="getVesselArrivalWithFiles()" style="color: coral; margin-top: -6px"></button>
        </div>
      </div>
      <div class="fileCounts" *ngIf="events">
        <div class="ui-g-1 dbn" style="width: 9.3%">
          <p>Total DBN: {{getFileCountForCurrentMonth().totalDBN}}</p>
        </div>
        <div class="ui-g-1 dbn">
          <p>Total RE: {{getFileCountForCurrentMonth().totalRE}}</p>
        </div>
        <div class="ui-g-1 dbn">
          <p>Total PH: {{getFileCountForCurrentMonth().totalPH}}</p>
        </div>
      </div>
      <div class="ui-g-18">
        <p-fullCalendar #fc [events]="events" [options]="options">
        </p-fullCalendar>
      </div>
    </p-card>
  </div>

  <div class="ui-grid-col-3 selectedVesselInfo">
    <p-card header="Select a Vessel Event to see stats:">
      <div class="legendInfo">
        Vessel:
      </div>
      <div class="vesselResult">
        {{eventVesselName ? eventVesselName : '-'}}
      </div>
      <br>
      <div class="legendInfo">
        Voyage Num:
      </div>
      <div class="vesselResult">
        {{eventVoyageNumber}}
      </div>
      <br>
      <div class="">
        AVECS: {{eventCLCount}}
      </div>
      <div class="">
        RE: {{eventRECount}}
      </div>
      <div class="">
        PH: {{eventPHCount}}
      </div>
      <br>
      <div class="globalSearchInfo">
        Note: Selecting a vessel event BEFORE adding a note will automatically fill in the vessel name and voyage
        number. Selecting a vessel event
        will also filter the Vessel Name in the table below.
      </div>
    </p-card>
  </div>

  <div class="ui-grid-col-3 vehicleCount">
    <p-card header="Number of Vehicles per Vessel/Voyage" [style]="{'height': '100%', 'overflow-y': 'scroll'}">
      <p-table [value]="vehicleVessel" styleClass="p-datatable-sm" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th>Vessel</th>
            <th>Voyage Number</th>
            <th>No of Vehicles</th>
            <th>ETA</th>
            <th>Difference between ETA</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td>{{product.vesselName}}</td>
            <td>{{product.voyageNumber}}</td>
            <td>{{product.noOfVehicles}}</td>
            <td>{{product.eta}}</td>
            <td>{{product.dayDifferenceSinceLastETA}}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>
</div>

<div class="bottomTable">
  <p-table #dt *ngIf="VesselNotes" [value]="VesselNotes" dataKey="id" editMode="row" [paginator]="true" [rows]="25"
    [showCurrentPageReport]="true" [globalFilterFields]="['name','vesselName','transhipedVessel','remarks', 'notes' ]"
    [autoLayout]="true" [resizableColumns]="true">
    <ng-template pTemplate="caption">
      <div>
        <button pButton class="p-button-refresh" type="button" (click)="refreshNotes()" icon="pi pi-refresh"
          pTooltip="Refresh table"></button>
      </div>
      <div style="text-align:left">
        <button pButton type="button" class="ui-button-info-AddRow" [label]="'Add Note'" (click)="addNote()"></button>
      </div>
      <div style="text-align: right">
        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
        <input class="globalFilterInput" type="text" pInputText size="50" placeholder="Global Filter"
          (input)="dt.filterGlobal($event.target.value, 'contains'); cd.detectChanges()" style="width:auto"
          [(ngModel)]="eventVesselName">
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="vesselName">Vessel
          <p-sortIcon [field]="vessel" ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
        <th pSortableColumn="voyageNumber">Voyage Number
          <p-sortIcon [field]="voyage" ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
        <th pSortableColumn="notes">Notes/Remarks
          <p-sortIcon [field]="notes" ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
        <th pSortableColumn="name">Created By
          <p-sortIcon [field]="createdBy" ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
        <th pSortableColumn="created">Created
          <p-sortIcon [field]="created" ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order" dateFormat="yy/mm/dd"></p-sortIcon>
        </th>
        <th pSortableColumn="transhipedVessel">Transhipped Vessel
          <p-sortIcon [field]="transhipedVessel" ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-noteItem let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="noteItem">
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown [options]="vesselNames" [(ngModel)]="noteItem.vessel" optionLabel="vesselName" [filter]="true"
                [showClear]="true" placeholder="Select a Vessel" (onChange)="getVoyageNumbers($event)">
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output" cd.detectChanges()>
              {{noteItem.vesselName}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown [options]="voyageNumber" [(ngModel)]="noteItem.voyage" optionLabel="Name" [filter]="true"
                [showClear]="true" placeholder="Select a Voyage" (onChange)="getSelectedVoygeNumber()">
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{noteItem.voyageNumber}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="noteItem.notes" maxlength="250">
            </ng-template>
            <ng-template pTemplate="output">
              {{noteItem.notes}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{noteItem.name}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{formatDate(noteItem.created)}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="noteItem.transhippedVessel">
            </ng-template>
            <ng-template pTemplate="output">
              {{noteItem.transhippedVessel}}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog header="Add Note" [style]="{width: '50vw'}" [dismissableMask]="true" [(visible)]="displayNote"
    [resizable]="false" [responsive]="true" showEffect="fade" [modal]="true">
    <div *ngIf="vesselNames" class="ui-g">
      <div class="ui-g-4">
        Select Vessel:
      </div>
      <div class="ui-g-8">
        <p-dropdown [style]="{'width':'100%'}" placeholder="Select a Vessel" class="full-width" appendTo="body"
          [options]="vesselNames" [(ngModel)]="SelectedVessel" (onChange)="getVoyageNumbers()" [filter]="true"
          optionLabel="vesselName" [showClear]="true"></p-dropdown>
      </div>
      <div class="ui-g-4">
        Select Voyage Number:
      </div>
      <div class="ui-g-8">
        <p-dropdown [style]="{'width':'100%'}" placeholder="Select a Voyage Number" class="full-width" appendTo="body"
          [options]="voyageNumber" [(ngModel)]="SelectedVoyage" [filter]="true" optionLabel="Name" [showClear]="true"
          [disabled]="!SelectedVessel">
        </p-dropdown>
      </div>
      <div class="ui-g-4">
        Select Transhipped Vessel:
      </div>
      <div class="ui-g-8">
        <p-dropdown [style]="{'width':'100%'}" placeholder="Select a Vessel" class="full-width" appendTo="body"
          [options]="vesselNames" [(ngModel)]="SelectedTranshipVessel" [filter]="true" optionLabel="vesselName"
          [showClear]="true"></p-dropdown>
      </div>
      <div class="ui-g-12 centerInDiv">
        <textarea pInputTextarea class="note-text" [disabled]="noteTypeSelected" maxlength="500"
          [(ngModel)]="noteData"></textarea>
      </div>
      <div class="ui-g-12 centerInDiv">
        <button pRipple class="p-button-info width-100"
          [disabled]="!this.noteData || !this.SelectedVessel || !this.SelectedVoyage" label="Save" type="button"
          (click)="updateVesselNotes()" pButton icon="fas fa-plus"></button>
      </div>
    </div>
  </p-dialog>
</div>