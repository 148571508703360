import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpResponse } from "../../../interfaces/result-interfaces";
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationApiService {

  constructor(private config: ConfigService, private http: HttpClient, private log: LogBase) { }

  public async authenticate(username: string, password: string): Promise<HttpResponse | null> {
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      let apiUrl = `${this.config.getServerUrl()}api/auth/authenticate?username=${username}&password=${password}`;
      return await this.http.post<HttpResponse>(apiUrl, null, { 'headers': headers }).toPromise();
    } catch (error) {
      this.log.error(`Login Failed for Username: ${username}`);
    }
    return null;
  }

  public async validateSession(sessionToken: string, userId: number): Promise<HttpResponse | null> {
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Id', userId.toString()).set('Token', sessionToken);
      let apiUrl = `${this.config.getServerUrl()}api/auth/validate`;
      return await this.http.get<HttpResponse>(apiUrl, { 'headers': headers }).toPromise();
    } catch (error) {
      this.log.error(`Validate session failed for Token: ${sessionToken}`);
    }
    return null;
  }

  async getLegalEntityById(sessionToken: string, userId: number, legalEntityId: number): Promise<HttpResponse | null> {
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Id', userId.toString()).set('Token', sessionToken);
      let apiUrl = `${this.config.getServerUrl()}api/LegalEntity/GetLegalEntityById?LegalEntityId=${legalEntityId}`;
      return await this.http.post<HttpResponse>(apiUrl, null, { 'headers': headers }).toPromise();
    } catch (error) {
      this.log.error(`Getting Legal Entity by ID: ${legalEntityId}`);
    }
    return null;
  }
}