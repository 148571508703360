<p-confirmDialog header="Confirmation"></p-confirmDialog>
<p-progressSpinner *ngIf="showSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"
  animationDuration="2s"></p-progressSpinner>


<div class="center-progress-spinner" *ngIf="showSpinnerForAction">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div class="ui-g-12 card card-w-title" *ngIf="!showSpinner">
  <div class="ui-g-12">
    <h2>Route Builder</h2>
  </div>
  <div class="ui-g-12" *ngIf="showBackButton">
    <button pRipple class="p-button-info back-button" (click)="goBack()" pButton type="button" label=" < Back"></button>
  </div>
  <p-tabView [activeIndex]="selectedTabIndex">
    <p-tabPanel header="Route List">
      <div class="ui-g-12 customTable">
        <p-table #dt1 [columns]="cols" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [autoLayout]="true"
          [pageLinks]="5" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="50"
          [value]="routes" sortMode="single" dataKey="id" styleClass="customTable" emptyMessage="No routes found">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
              <th>
                Action
              </th>
            </tr>
            <tr>
              <th>
                <input type="text" pInputText (input)="dt1.filter($event.target.value, 'loadLocationName', 'contains')">
              </th>
              <th>
                <input type="text" pInputText
                  (input)="dt1.filter($event.target.value, 'unLoadLocationName', 'contains')">
              </th>
              <th>
              </th>
              <th>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-route let-rowIndex="rowIndex" let-expanded="expanded">
            <tr *ngIf="route.parentRouteId === 0" [ngStyle]="{'color': route.active == 0 ? 'red' : 'black'}">
              <td>
                <button type="button" pButton pRipple [pRowToggler]="route"
                  class="p-button-text p-button-rounded p-button-plain p-mr-2"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                <span class="margin-left">{{route.loadLocationName}}</span>
              </td>
              <td>
                {{route.unLoadLocationName}}
              </td>
              <td>
                {{route.routeTypeDescription}}
              </td>
              <td>
                <button pButton type="button" label="{{checkRouteActiveState(route)}}"
                  (click)="setActiveState(route)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-route>
            <tr *ngFor="let a of routes; let index = index" [ngStyle]="{'color': route.active == 0 ? 'red' : 'black'}">
              <ng-container *ngIf="route.id == a.parentRouteId">
                <td>
                  {{a.loadLocationName}}
                </td>
                <td>
                  {{a.unLoadLocationName}}
                </td>
                <td>
                  {{a.routeTypeDescription}}
                </td>
                <td>

                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Add Master Route">
      <h4>Select Master Route</h4>
      <div class="ui-g">
        <div class="ui-g-5">
          <label>Start Master Route</label>
        </div>
        <div class="ui-g-7">
          <app-location [LocationTypeId]="0" [(ngModel)]="startMasterRouteLocation"
            [DisableDropDown]="disableMasterRoutes" (OnClearLocation)="clearMasterStartRoute()"
            (OnSelectLocation)="onSelectMasterRouteStart($event)">
          </app-location>
        </div>
        <div class="ui-g-5">
          <label>End Master Route</label>
        </div>
        <div class="ui-g-7">
          <app-location [LocationTypeId]="0" [(ngModel)]="endMasterRouteLocation"
            [DisableDropDown]="disableMasterRoutes" (OnClearLocation)="clearMasterEndRoute()"
            (OnSelectLocation)="onSelectMasterRouteEnd($event)">
          </app-location>
        </div>
      </div>

      <div class="ui-g">
        <h4>Add Route Leg</h4>
        <div class="ui-g-12">
          <div class="ui-g">
            <div class="ui-g-5">
              <label>Start Route Leg</label>
            </div>
            <div class="ui-g-7">
              <app-location [LocationTypeId]="startMasterRouteLeg ? startMasterRouteLeg.LocationTypeId : 0"
                [(ngModel)]="startMasterRouteLeg" [DisableDropDown]="true" (OnClearLocation)="clearStartRouteLeg()"
                (OnSelectLocation)="onSelectStartMasterRouteLeg($event)">
              </app-location>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-5">
              <label>End Route Leg</label>
            </div>
            <div class="ui-g-7">
              <app-location [LocationTypeId]="0" [(ngModel)]="endMasterRouteLeg" (OnClearLocation)="clearEndRouteLeg()"
                (OnSelectLocation)="onSelectEndMasterRouteLeg($event)">
              </app-location>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-5">
              <label>Route Type</label>
            </div>
            <div class="ui-g-7">
              <div class="ui-g-4">
                <p-dropdown [options]="routeTypes" placeholder="Select a route type" optionLabel="description"
                  [style]="{'width':'100%'}" [(ngModel)]="routeLegType">
                </p-dropdown>
              </div>
            </div>
          </div>

          <div class="ui-g-12">
            <button pButton [disabled]="disableAddRouteLegForMasterRoute()" type="button" class=" width-100"
              label="Add Route Leg" (click)="addRouteLeg()"></button>
          </div>
          <div class="ui-g-12">
            <button pButton [disabled]="!routeLegs || routeLegs.length == 0" type="button" class=" width-100"
              label="Clear Route" (click)="clearBuiltRoute()"></button>
          </div>
        </div>
      </div>

      <div class="ui-g" *ngIf="routeLegs && routeLegs.length > 0">
        <h4>Current Route</h4>
        <p class="ui-g-12">{{ displayRouteLegs(routeLegs) }}</p>
      </div>

      <div class="ui-g" *ngIf="routeLegs && routeLegs.length > 0">
        <h4>Route Legs</h4>
        <p-table [value]="routeLegs" [autoLayout]="true" class="ui-g-12">
          <ng-template pTemplate="header">
            <tr>
              <th>#</th>
              <th>Load Location Name</th>
              <th>Load Location Type</th>
              <th>Unload Location Name</th>
              <th>Unload Location Type</th>
              <th>Route Type</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-index="rowIndex">
            <tr>
              <td>
                <label>{{index + 1}}</label>
              </td>
              <td>
                {{rowData.loadLocationName}}
              </td>
              <td>
                {{rowData.loadLocationType}}
              </td>
              <td>
                {{rowData.unLoadLocationName}}
              </td>
              <td>
                {{rowData.unloadLocationType}}
              </td>
              <td>
                {{rowData.routeType}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="ui-g-12" *ngIf="routeLegs && routeLegs.length > 0">
        <button *ngIf="!waitingResponse" pButton type="button" label="Create Route" class="width-100 margin-bottom"
          [disabled]="disableCreateRoute()" (click)="createMasterRoute()"></button>
        <button *ngIf="waitingResponse" pButton type="button" label="" class="width-100 margin-bottom"
          [disabled]="true">
          <i class="pi pi-spin pi-spinner font-size-2em"></i>
        </button>
      </div>

    </p-tabPanel>
    <p-tabPanel header="Add/Update Route Leg">
      <div class="ui-g">
        <h4>Search Master Route</h4>
        <div class="ui-g-12">
          <p-autoComplete [inputStyle]="{'width':'100%'}" [style]="{'width':'100%'}" [suggestions]="masterRoutes"
            [(ngModel)]="searchedMasterRoute" placeholder="Master Route" field="label" [dropdown]="true"
            (onClear)="selectedMaster = null" (completeMethod)="searchMasterRoutes($event)"
            (onSelect)="SelectMasterRoute($event)" minLength="2" delay="100">
          </p-autoComplete>
        </div>

        <div class="ui-g-12">
          <h4>Route Leg</h4>
          <div class="ui-g-12">
            <div class="ui-g-5">
              <label>Start Route Leg</label>
            </div>
            <div class="ui-g-7">
              <app-location [LocationTypeId]="0" [(ngModel)]="startRouteLeg"
                [DisableDropDown]="selectedMaster ? false : true" (OnSelectLocation)="onSelectStartRouteLeg($event)">
              </app-location>
            </div>
            <div class="ui-g-5">
              <label>End Route Leg</label>
            </div>
            <div class="ui-g-7">
              <app-location [LocationTypeId]="0" [(ngModel)]="endRouteLeg"
                [DisableDropDown]="selectedMaster ? false : true" (OnSelectLocation)="onSelectEndRouteLeg($event)">
              </app-location>
            </div>

            <div class="ui-g-5">
              <label>Route Type</label>
            </div>
            <div class="ui-g-7">
              <div class="ui-g-4">
                <p-dropdown [options]="routeTypes" placeholder="Select a route type" optionLabel="description"
                  [disabled]="selectedMaster ? false : true" [style]="{'width':'100%'}" [(ngModel)]="routeLegType">
                </p-dropdown>
              </div>
            </div>

            <div class="ui-g-12 add-existing-route">
              <button pButton class="width-100" [disabled]="disableAddRouteLeg()" type="button" label="Add Route Leg"
                (click)="upSertRouteLegToExistingMasterRoute(1)"></button>
              <button pButton class="width-100 margin-top-1em" type="button" label="Clear Updated Route"
                [disabled]="!selectedMaster || waitingResults" (click)="clearUpdatedRoute()"></button>
            </div>
          </div>
          <div class="ui-g-12" *ngIf="selectedMaster">
            <h4>Current Route:</h4>
            <label>{{ selectedMaster.label }}</label>
          </div>
          <div class="ui-g-12" *ngIf="selectedMasterRoute && selectedMasterRoute.routeLegs.length > 0">
            <h4>Updated Route</h4>
            <p>{{ displayRouteLegs(selectedMasterRoute.routeLegs) }}</p>
          </div>
          <div *ngIf="selectedMasterRoute && selectedMasterRoute.routeLegs.length > 0"
            class="ui-g-12 master-route-legs">
            <h4>Route Legs</h4>
            <p-table [value]="selectedMasterRoute.routeLegs" [autoLayout]="true" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>#</th>
                  <th>Load Location Name</th>
                  <th>Load Location Type</th>
                  <th>Unload Location Name</th>
                  <th>Unload Location Type</th>
                  <th>Route Type</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                <!-- Don't display the rows that are going to be deleted -->
                <tr *ngIf="rowData.actionType != 3">
                  <td>
                    {{ rowData.routeOrder }}
                  </td>
                  <td>
                    {{ rowData.loadLocationName }}
                  </td>
                  <td>
                    {{ rowData.loadLocationType }}
                  </td>
                  <td>
                    {{ rowData.unLoadLocationName }}
                  </td>
                  <td>
                    {{ rowData.unloadLocationType }}
                  </td>
                  <td>
                    {{ rowData.routeTypeDescription }}
                  </td>
                  <td>
                    <button pButton type="button" icon="fas fa-pencil-alt" class="ui-button-info"
                      (click)="editRouteLeg(rowData)"
                      *ngIf="index != 0 && index != selectedMasterRoute.routeLegs.length - 1"
                      pTooltip="Edit Route Leg"></button>
                    <button pTooltip="Remove Route Leg" pButton type="button" icon="pi pi-times" tooltipPosition="left"
                      *ngIf="index != 0 && index != selectedMasterRoute.routeLegs.length - 1"
                      class="ui-button-info remove-route-leg" (click)="removeLegFromMasterRoute(rowData.id)"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <div class="ui-g margin-top-1em">
              <button *ngIf="!waitingResults" pButton type="button" label="Confirm Master Route Order" class="width-100"
                (click)="confirmMasterRouteOrder()"></button>
              <button *ngIf="waitingResults" pButton type="button" label="" class="width-100" [disabled]="true">
                <i class="pi pi-spin pi-spinner font-size-2em"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Add Location">
      <div>
        <h4>Select A Location Type</h4>
        <div class="p-grid width-100">
          <div class="p-col-3 padding-left-3em">
            <p-radioButton name="locationOption" value="Port" label="Port" (onClick)="loadAddLocationForm(1)">
            </p-radioButton>
          </div>
          <div class="p-col-3 padding-left-3em">
            <p-radioButton name="locationOption" value="BorderPost" label="Border Post"
              (onClick)="loadAddLocationForm(2)">
            </p-radioButton>
          </div>
          <div class="p-col-3 padding-left-3em">
            <p-radioButton name="locationOption" value="Place" label="Place" (onClick)="loadAddLocationForm(3)">
            </p-radioButton>
          </div>
          <div class="p-col-3 padding-left-3em">
            <p-radioButton name="locationOption" value="Warehouse" label="Warehouse" (onClick)="loadAddLocationForm(4)">
            </p-radioButton>
          </div>
        </div>

        <div class="p-grid width-100 padding-top-1em" *ngIf="locationForm">
          <p-fieldset legend="{{selectedOption}} Fields" class="ui-g-12">
            <form [formGroup]="locationForm" (ngSubmit)="onLocationSubmit(locationForm.value)" class="ui-g-12">
              <div *ngIf="selectedOption == 'Port' && locationForm">
                <div class="ui-g-3"><label>Port Name</label></div>
                <div class="ui-g-3"><input type="text" pInputText formControlName="portName" minLength="2"></div>
                <div class="ui-g-3"><label>Country</label></div>
                <p-dropdown [options]="countries" placeholder="Select a Country" formControlName="portCountry"
                  optionLabel="label" [filter]="true">
                </p-dropdown>
              </div>

              <div *ngIf="selectedOption == 'Border Post'">
                <div class="ui-g-3"><label>Border Post Name</label></div>
                <div class="ui-g-3"><input type="text" pInputText formControlName="borderPost" minLength="2"></div>
                <div class="ui-g-3"><label>Exit Border Post</label></div>
                <div class="ui-g-3"><input type="text" pInputText formControlName="exitBorderPost" maxlength="3"
                    class="exitBorderPost"></div>
                <div class="ui-g-3"><label>Default CIF Destination</label></div>
                <div class="ui-g-3"><input type="text" pInputText formControlName="defaultCIFDestination" minLength="2">
                </div>
              </div>

              <div *ngIf="selectedOption == 'Place'">
                <div class="ui-g-3"><label>Country</label></div>
                <div class="ui-g-3">
                  <p-dropdown [options]="countries" placeholder="Select a Country" formControlName="placeCountry"
                    (onChange)="getRegions()" optionLabel="label" [filter]="true">
                  </p-dropdown>
                </div>
                <div class="ui-g-3"><label>Region</label></div>
                <div class="ui-g-3">
                  <p-dropdown [options]="regions" placeholder="Select a Region" formControlName="placeRegion"
                    (onChange)="regionSelect()" optionLabel="label" [filter]="true" [disabled]="!selectedCountry">
                  </p-dropdown>
                </div>
                <div class="ui-g-3"><label>City</label></div>
                <div class="ui-g-3">
                  <input formControlName="placeCity" placeholder="Enter a new City Name" type="text" pInputText>
                </div>
              </div>

              <div *ngIf="selectedOption == 'Warehouse'">
                <div class="ui-g-3"><label>Warehouse Name</label></div>
                <div class="ui-g-3">
                  <input type="text" pInputText minLength="2" formControlName="warehouse" />
                </div>
              </div>

              <div class="ui-g-12">
                <button pButton type="button" label="Add {{selectedOption}}" class=" width-100"
                  (click)="onLocationSubmit(locationForm.value)" [disabled]="!locationForm.valid"></button>
              </div>
            </form>
          </p-fieldset>

        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog *ngIf="filesThatUserMasterRoute && filesThatUserMasterRoute.length > 0" [resizable]="false"
  [responsive]="true" showEffect="fade" [modal]="true" header="These file(s) use the master route"
  [(visible)]="displayErrorDialog" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <h4>Error:</h4>
    <label class="errorMessage">
      {{errorMessage}}
    </label>
  </div>

  <div class="ui-g-12" *ngIf="selectedMaster && selectedMaster.label">
    <h4>Current Route:</h4>
    <label *ngIf="selectedMaster">{{ selectedMaster.label }}</label>
    <label class="no-selected-master-route" *ngIf="!selectedMaster">No selected route</label>
  </div>

  <div class="ui-g-12" *ngIf="selectedRouteNotation">
    <h4>Selected Route:</h4>
    <label>{{ selectedRouteNotation }}</label>
  </div>

  <div class="ui-g-12" *ngIf="selectedMasterRoute && selectedMasterRoute.routeLegs">
    <h4>Updated Route:</h4>
    <label>{{ displayRouteLegs(selectedMasterRoute.routeLegs) }}</label>
  </div>

  <div class="ui-g-12">
    <p-table #dt [autoLayout]="true" [value]="filesThatUserMasterRoute" [scrollable]="true" scrollHeight="400px"
      dataKey="dbnNumber" [globalFilterFields]="['dbnNumber']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th pSortableColumn="dbnNumber">
            DBN Number
            <p-sortIcon field="dbnNumber"></p-sortIcon>
          </th>

          <th pSortableColumn="currentlyAt">
            Currently At
            <p-sortIcon field="currentlyAt"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td>{{row.dbnNumber}}</td>
          <td>{{row.currentlyAt}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<p-dialog [resizable]="false" [responsive]="true" showEffect="fade" [modal]="true" (onHide)="clearRouteLeg()"
  header="Edit Route Leg" [(visible)]="displayDialog" [style]="{width: '60vw'}">
  <div class="ui-g-12">
    <div class="ui-g-5">
      <label>Start Route Leg</label>
    </div>
    <div class="ui-g-7">
      <app-location [LocationTypeId]="0" [(ngModel)]="startRouteLeg" [DisableDropDown]="selectedMaster ? false : true"
        (OnSelectLocation)="onSelectStartRouteLeg($event)">
      </app-location>
    </div>
    <div class="ui-g-5">
      <label>End Route Leg</label>
    </div>
    <div class="ui-g-7">
      <app-location [LocationTypeId]="0" [(ngModel)]="endRouteLeg" [DisableDropDown]="selectedMaster ? false : true"
        (OnSelectLocation)="onSelectEndRouteLeg($event)">
      </app-location>
    </div>

    <div class="ui-g-5">
      <label>Route Type</label>
    </div>
    <div class="ui-g-7">
      <div class="ui-g-4">
        <p-dropdown [options]="routeTypes" placeholder="Select a route type" optionLabel="description"
          [disabled]="selectedMaster ? false : true" [style]="{'width':'100%'}" [(ngModel)]="routeLegType">
        </p-dropdown>
      </div>
    </div>

    <div class="ui-g-12 add-existing-route">
      <button pButton class="width-100" [disabled]="disableAddRouteLeg()" type="button" label="Edit Route Leg"
        appendTo="body" (click)="upSertRouteLegToExistingMasterRoute(2)"></button>
    </div>
  </div>
</p-dialog>