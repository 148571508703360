<div class="overlay" *ngIf="showSpinner">
  <p-progressSpinner class="content" [style]="{ width: '50px', height: '50px' }" strokeWidth="8" fill="#EEEEEE"
    animationDuration="2s"></p-progressSpinner>
</div>

<div class="overlay" *ngIf="waitForUserTasks"></div>

<div class="ui-g card card-w-title ui-g-nopad">
  <div class="ui-g-12">
    <p-tabView>
      <p-tabPanel header="My Task List">
        <div class="ui-g">
          <div class="ui-g-12 my-tasks">
            <button pRipple class="p-button-info" pTooltip="Refresh Task list" tooltipPosition="left" pButton
              type="button" icon="fas fa-sync" (click)="refreshList()"></button>
          </div>
          <div class="ui-g-12">
            <h3 class="user-task-header" *ngIf="!userTaskSummary || userTaskSummary.length == 0">No tasks found.</h3>
            <p-accordion (onOpen)="onUserTaskSelect($event.index)">
              <p-accordionTab *ngFor="let summary of userTaskSummary;"
                header="{{ summary.userTaskName }} - ({{ summary.count }} tasks)">
                <p-progressSpinner *ngIf="waitForUserTasks" [style]="{ width: '50px', height: '50px' }" strokeWidth="8"
                  fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
                <div class="user-tasks-table">
                  <dynamic-table-basic class="ui-g" *ngIf="!waitForUserTasks && summary.userTasks"
                    [data]="summary.userTasks" [title]="" (onAny)="showCompleteTasksPopup($event)">
                  </dynamic-table-basic>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="My Voyages">
        <div class="ui-g">
          <div class="ui-g-12">
            <div class="ui-g-2">
              <b>My Voyages:</b>
            </div>
            <div class="ui-g-4">
              <p-dropdown [options]="voyages" (onChange)="getVoyageUserTaskSummary()" [(ngModel)]="selectedVoyage"
                [autoDisplayFirst]="false" placeholder="Select a Vessel" [style]="{ width: '400px' }" [filter]="true"
                [showClear]="true">
              </p-dropdown>
            </div>
            <div class="ui-g-6">
              <button pRipple class="p-button-info" pTooltip="Refresh Voyage List" tooltipPosition="left" pButton
                type="button" style="float: right" icon="fas fa-sync" (click)="refreshList()"></button>
            </div>
          </div>
          <div class="ui-g-12">
            <h3 class="user-task-header"
              *ngIf="!showSpinner && (!voyageUserTaskSummary || voyageUserTaskSummary.length == 0)">
              No tasks
              found.
            </h3>
          </div>
          <div class="ui-g-12">
            <p-accordion (onOpen)="getVoyageUserTasks($event.index)">
              <p-accordionTab *ngFor="let voyageUserTask of voyageUserTaskSummary; let i = index;"
                header="{{ voyageUserTask.userTaskName }} - ({{ voyageUserTask.count }} tasks)">
                <p-progressSpinner *ngIf="waitForVoyageUserTasks" [style]="{ width: '50px', height: '50px' }"
                  strokeWidth="8" fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
                <div class="user-tasks-table">
                  <dynamic-table-basic class="ui-g" *ngIf="!waitForVoyageUserTasks && voyageUserTask.userTasks"
                    [data]="voyageUserTask.userTasks" [title]="" (onAny)="showCompleteTasksPopup($event)">
                  </dynamic-table-basic>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog *ngIf="selectedTaskDetails" [(visible)]="showCompleteTasks" [modal]="true" [draggable]="false"
  [style]="{width: '85%', height: '70vh'}">
  <p-header>
    File Tasks - {{selectedTask.genericId}}
  </p-header>

  <div class="ui-g-12">
    <p-fieldset legend="Consignee Information">
      <span class="ui-g-3 ui-float-label">
        <input class="full-width" id="float-input" pInputText [value]="selectedTaskDetails.fullName" disabled>
        <label class="pad-left" for="float-input">Full Name</label>
      </span>
      <span class="ui-g-3 ui-float-label">
        <input class="full-width" id="float-input" pInputText [value]="selectedTaskDetails.contactNo" disabled>
        <label class="pad-left" for="float-input">Contact Number</label>
      </span>
      <span class="ui-g-3 ui-float-label">
        <input class="full-width" id="float-input" pInputText [value]="selectedTaskDetails.email" disabled>
        <label class="pad-left" for="float-input">Email</label>
      </span>
      <div class="ui-g-3">
        <button pRipple class="p-button-info" pTooltip="Edit" tooltipPosition="bottom" pButton type="button"
          (click)="legalEnityRoute(selectedTaskDetails.legalEntityId, selectedTaskDetails.legalEntityTypeId)"
          icon="far fa-edit"></button>
      </div>
    </p-fieldset>
    <p-fieldset legend="Route Information">
      <span class="ui-g-9 ui-float-label">
        <input class="full-width" id="float-input" pInputText [value]="selectedTaskDetails.route" disabled>
        <label class="pad-left" for="float-input">Route</label>
      </span>
      <div class="ui-g-3">
        <button pRipple class="p-button-info" pTooltip="Edit" tooltipPosition="bottom" pButton type="button"
          icon="far fa-edit" (click)="openFile(selectedTask.genericId)"></button>
      </div>
    </p-fieldset>
    <p-fieldset legend="Cargo Information">
      <span class="ui-g-9 ui-float-label">
        <input class="full-width" id="float-input" pInputText [value]="selectedTaskDetails.cargoValue" disabled>
        <label class="pad-left" for="float-input">Cargo Value</label>
      </span>
      <div class="ui-g-3">
        <button pRipple class="p-button-info" pTooltip="Edit" tooltipPosition="bottom" pButton type="button"
          icon="far fa-edit" (click)="openFile(selectedTask.genericId)"></button>
      </div>
    </p-fieldset>

    <p-fieldset legend="Tasks">
      <p-table #dt [autoLayout]="true" [rows]="100" [value]="selectedTaskGroup">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>
              Task Name
            </th>
            <th>
              Completed
            </th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-userTask>
          <tr>
            <td>{{ userTask.taskName }}</td>
            <td>{{ userTask.completed == 1 ? "Yes" : "No" }}</td>
            <td class="display-flex">
              <button *ngIf="userTask.completed != 1 && !userTask.showSpinner" pRipple class="p-button-info"
                pTooltip="Complete" tooltipPosition="bottom" pButton type="button" icon="fa fa-check"
                (click)="completeFileOwnerUserTaskById(userTask)"></button>
              <p *ngIf="userTask.completed == 1">Completed</p>
              <p-progressSpinner *ngIf="userTask.showSpinner" class="p-button-info"
                [style]="{ width: '2.143em', height: '2.143em' }" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
              </p-progressSpinner>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-fieldset>
  </div>
</p-dialog>