<!-- <p-progressSpinner *ngIf="showSpinner"></p-progressSpinner> -->
<div class="ui-g-12">
  <p-dropdown pDropDown [options]="templates" [(ngModel)]="selectedTemplate" optionLabel="name" [filter]="true"
    (onChange)="_onTemplateSelect($event)" styleClass="fulldropdown" placeholder="Select a {{type}}"
    autoDisplayFirst='false'>
  </p-dropdown>
</div>
<div *ngIf="showForm">
  <form [formGroup]="form" (ngSubmit)="_onSubmit()" class="ui-g-12">
    <ng-container *ngIf="showCriteria">
      <p-card header="Parameters">
        <div class="range-container">
          <!-- FOR EACH CRITERIA -->
          <ng-container *ngFor="let eachCriteria of criteria">
            <!-- Is it hidden? -->
            <div [style.height]=" eachCriteria.hidden == 1 ? '0' : '' "
              [style.overflow]=" eachCriteria.hidden == 1 ? 'auto' : 'visible' ">
              <!-- What component is it? -->
              <ng-container *ngIf="eachCriteria.criteriaTypeId == criteriaType.AUTOCOMPLETE">
                <div class="ui-g-6"><label>{{eachCriteria.label}}</label></div>
                <div class="ui-g-6">
                  <p-autoComplete pAutocomplete formControlName="{{ eachCriteria.variableName }}"
                    [(ngModel)]="payload[eachCriteria.variableName].value"
                    [suggestions]="payload[eachCriteria.variableName].searchOptions"
                    (completeMethod)="search(eachCriteria.variableName, $event)"
                    placeholder="{{eachCriteria.required ? '*Required' : '*Not Required' }}" completeOnFocus="true"
                    field="label" selectId="value" styleClass="fulldropdown" selectId="value" autoDisplayFirst='false'>
                  </p-autoComplete>
                </div>
              </ng-container>

              <ng-container *ngIf="eachCriteria.criteriaTypeId == criteriaType.DROPDOWN">
                <div class="ui-g-6"><label>{{eachCriteria.label}}</label></div>
                <div class="ui-g-6">
                  <p-dropdown pDropDown [options]="payload[eachCriteria.variableName].options" [filter]="true"
                    [(ngModel)]="payload[eachCriteria.variableName].value"
                    formControlName="{{ eachCriteria.variableName }}" optionLabel="label" selectId="value"
                    styleClass="fulldropdown" placeholder="{{eachCriteria.required ? '*Required' : '*Not Required' }}"
                    autoDisplayFirst='false'></p-dropdown>
                </div>
              </ng-container>

              <ng-container *ngIf="eachCriteria.criteriaTypeId == criteriaType.INPUTNUMBER">
                <div class="ui-g-6"><label>{{eachCriteria.label}}</label></div>
                <div class="ui-g-6"><input type="number" pInputNumber
                    [(ngModel)]="payload[eachCriteria.variableName].value"
                    formControlName="{{ eachCriteria.variableName }}"
                    placeholder="{{eachCriteria.required ? '*Required' : '*Not Required' }}">
                </div>
              </ng-container>

              <ng-container *ngIf="eachCriteria.criteriaTypeId == criteriaType.INPUTTEXT">
                <div class="ui-g-6"><label>{{eachCriteria.label}}</label></div>
                <div class="ui-g-6"><input type="text" pInputText [(ngModel)]="payload[eachCriteria.variableName].value"
                    formControlName="{{ eachCriteria.variableName }}"
                    placeholder="{{eachCriteria.required ? '*Required' : '*Not Required' }}">
                </div>
              </ng-container>

              <ng-container *ngIf="eachCriteria.criteriaTypeId == criteriaType.DATEPICKER">
                <div class="ui-g-6"><label>{{eachCriteria.label}}</label></div>
                <div class="ui-g-6">
                  <p-calendar [(ngModel)]="payload[eachCriteria.variableName].value"
                    formControlName="{{ eachCriteria.variableName }}" [readonlyInput]="true" styleClass="custom-full"
                    inputStyleClass="custom-full"
                    placeholder="{{eachCriteria.required ? '*Required' : '*Not Required' }}" dateFormat="yy/mm/dd">
                  </p-calendar>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </p-card>
    </ng-container>
    <!-- BUTTON AT BOTTOM -->
    <br>
    <button *ngIf="!showSpinner" class="width-100" pButton [label]="selectedTemplate.name" pButton type="submit"
      label="Save" [disabled]="form.invalid"></button>
    <button *ngIf="showSpinner" pRipple class="p-button-info width-100" pButton type="button" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </form>
</div>