import { HttpResponse } from '../../interfaces/result-interfaces';
import { Injectable } from '@angular/core';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";

@Injectable({
  providedIn: 'root'
})
export class RoutesApiService {

  private serverUrl = this.config.getServerUrl();

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service) { }

  public async searchMasterRoutes(search: string) : Promise<HttpResponse> {
    try {
      const url = `${this.serverUrl}api/route/SearchMasterRoutes?search=${search}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/route/routes
  // Controller Name: RoutesController.cs
  // Proc Name: pr_GetCurrentRoutes 
  public async getActiveRoutes() : Promise<HttpResponse>  {
    try {
      const url = `${this.serverUrl}api/route/routes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}
