<summary-dashboard></summary-dashboard>


<div class="landingBackground">
  <div class="Text">
    <h2 class="Headings">| OPERATION |</h2>
    <p>1. Eta. Ro/ro or container updates. Have they been done daily?</p>
    <p>2. Task lists - are they up to date?</p>
    <p>3. Any outstanding SARS issues regarding entries, fines, Customer stop-detain.</p>
    <p>4. List and follow up on outstanding tasks such as: Fines, application, any additional outstanding tasks.</p>
    <p>5. <mark>Have all entries been recieved by the warehouse?</mark></p>
    <p>6. <mark>How many warehouse VOC's have been done?</mark></p>
    <p>7. Balance in NPA Account?</p>
    <p>8. Balance in SAPO Account?</p>
    <p>9. How many export documents are required for the current date?</p>
    <p>10. Number of files outstanding for vessels arriving within 10 working days?</p>
    <p>11. Documents to split - Number of units</p>
    <p>12. Field Runs - Number of places</p>
    <p>13. Receipt of the ANFs (arrival notice) from the shipping company, how many received & how many outstanding (per
      vessel).</p>
    <br>
    <h2 class="Headings">| WAREHOUSE |</h2>
    <p>1. Are the Last ship inspections done?</p>
    <p>2. Have all the T896 been uploaded on to DT?</p>
    <p>3. Which vehicles have missing items that is on the T896?</p>
    <ol>
      <li>3a. Has Beforward or the supplier been contacted?</li>
      <li>3b. Has the consignee been contacted?</li>
    </ol>
    <p>4. Has the warehouse stocktake been done?</p>
    <ol>
      <li>4a. When was it done?</li>
      <li>4b. Does the physical count match DT?</li>
    </ol>
    <p>5. Are there issues with the app?</p>
    <p>6. Are there issues with DT?</p>
    <p>7. Have you reported issues to IT?</p>
    <p>8. Are the warehouse books updated?</p>
    <p>9. Are all goods received/ dispatched notes in order and up-to-date?</p>
  </div>
  <div class="Text">
    <h2 class="Headings">| DISPATCH/ACCOUNTS |</h2>
    <p>1. When is the next load?</p>
    <p>2. What cars are paid but have not been dispatched?</p>
    <p>3. Truck resources?</p>
    <p>4. Are all cars in the right warehouse?</p>
    <p>5. What cars were damaged during delivery?</p>
    <p>6. Are we paying claims that have been resolved?</p>
    <p>7. Are there any SAPO/NPA payments needed and how much?</p>
    <p>8. Are there any late units?</p>
    <p>9. Are there landing orders being passed today?</p>
    <p>10. Advise on PH vehicles for handover agents that were not collected and need additional storage charged.</p>
    <br>
    <h2 class="Headings">| CLAIMS |</h2>
    <p>1. Damaged /cars to be fixed.</p>
    <p>2. Follow up update.</p>
    <br>
    <h2 class="Headings">| GENERAL |</h2>
    <p>2. IT issues. Have they been resolved and how do they affect the workflow?</p>
  </div>

</div>