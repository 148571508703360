import { Injectable } from '@angular/core';
import { LogBase } from "./logger.service";
import { ApiService } from "./api.service";
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class ExcelService {

	constructor(
		public log: LogBase,
		public api: ApiService,) { }

	async generateGenericExcel(data: any[], filename: string) {
		try {
			// check for credible data
			if (!data) {
				throw "Data is NULL or undefined";
			}
			else if (data.length == 0) {
				throw "Data is empty";
			}
			// check extension
			var extension = filename.substring(filename.length - 5);
			if (extension != ".xlsx") {
				filename += ".xlsx";
			}
			// set headers for generation
			let excelHeaders = [];
			let headers = Object.keys(data[0]);
			// add object for payload
			headers.forEach(i => {
				let head = { header: i, key: i };
				excelHeaders.push(head);
			});
			// get bit response from instant generation
			let bit = await this.api.generateStandardExcelSheet(filename, excelHeaders, data);
			// save file
			if (bit != undefined) {
				this.save(filename, bit, false);
			}
			else {
				this.log.error("failed to generate excel sheet", '', "excel.service.generateGenericExcel()");
			}
		}
		catch (err) {
			this.log.error("failed to generate excel document", err, "excel.service.generateGenericExcel()");
		}
	}

	async generateCustomExcel(data: any, process: string, filename: string) {
		try {
			// check extension
			var extension = filename.substr(filename.length - 5);
			if (extension != ".xlsx") {
				throw "Invalid file name / extension";
			}
			// get bit response from instant generation
			let bit = await this.api.generateCustomExcelSheet(filename, process, data);
			// save file
			if (bit != undefined) {
				this.save(filename, bit, false);
			}
			else {
				this.log.error("failed to generate excel sheet", '', "excel.service.generateCustomExcel()");
			}

		}
		catch (err) {
			this.log.error("failed to generate excel document", err, "excel.service.generateCustomExcel()");
		}
	}

	private save(name, data, isBinary) {
		if (isBinary) {
			var bytes = new Array(data.length);
			for (var i = 0; i < data.length; i++) {
				bytes[i] = data.charCodeAt(i);
			}
			data = new Uint8Array(bytes);
		}

		var blob = this.base64ToBlob(data);//new Blob([data], {type: type});
		let objectURL = window.URL.createObjectURL(blob);
		let anchor = document.createElement('a');

		anchor.href = objectURL;
		anchor.download = name;
		anchor.click();

		URL.revokeObjectURL(objectURL);
	}

	private base64ToBlob(b64Data, sliceSize = 512) {
		let byteCharacters = atob(b64Data); //data.file there
		let byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			let slice = byteCharacters.slice(offset, offset + sliceSize);

			let byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	}
}
