import { Component, OnInit } from "@angular/core";
import { AppComponent } from "./app.component";
import { Router } from "@angular/router";
import { SearchService } from "./services/rxjs.service";
import { ConfigService } from "./services/config.service";
import { environment as env } from './../environments/environment';

@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.html",
  styleUrls: ["./app.topbar.scss"],
  providers: []
})
export class AppTopbarComponent implements OnInit {
  searchQuery: string;
  public environment = env;

  constructor(
    public app: AppComponent,
    private router: Router,
    private searchsvc: SearchService,
    private config: ConfigService
  ) { }

  async ngOnInit() { }

  search() {
    if (this.searchQuery) {
      this.router.navigate(["/search-result"], {
        queryParams: { query: this.searchQuery },
      });
    }
  }

  sendMessage(qry: string): void {
    this.searchsvc.sendSearch(qry);
  }

  clearSearch(): void {
    this.searchsvc.clearSearch();
  }

  async openFile() {
    await this.clearStorage();
    this.router.navigate(["open-file"]);
  }

  async clearStorage() {
    await localStorage.removeItem("pageStateRules");
    await localStorage.removeItem("fileItemsPayload");
    await localStorage.removeItem("fileType");
    await localStorage.removeItem("savedFileItems");
    await localStorage.removeItem("shipmentDetails");
    await localStorage.removeItem("singleFileCreateClientId");
    await localStorage.removeItem("singleFileCreateContactId");
  }

  importCSV() {
    this.router.navigate(["/file-upload"]);
  }

  manageVessels() {
    this.router.navigate(["/vessel-management"]);
  }

  manageColors() {
    this.router.navigate(["/colors"]);
  }
  manageMBL() {
    this.router.navigate(["/masterbills"]);
  }

  manageBorderPost() {
    this.router.navigate(["/border-post"]);
  }

  manageDocumentCategory() {
    this.router.navigate(["/document-category"]);
  }

  manageShippingLines() {
    this.router.navigate(["/shipping-lines"]);
  }

  manageContainers() {
    this.router.navigate(["/containers"]);
  }

  manageInvoiceLineItemDescription() {
    this.router.navigate(["/manage-invoice-items"]);
  }

  manageVehicles() {
    this.router.navigate(["/manage-vehicles"]);
  }

  goToRouteBuilder() {
    this.router.navigate(["/route-builder"]);
  }

  assignFiles() {
    this.router.navigate(["/file-assignment"]);
  }
}
