import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { LogBase } from '../../../services/logger.service';
import { ConfirmationService } from 'primeng';
import { ConfigService } from '../../../services/config.service'
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

import * as moment from 'moment'
import { InspectionData } from '../../../interfaces/inspection.interface';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss'],
  providers: [ConfirmationService]
})

export class InspectionComponent implements OnInit {
  @Output() onLoaded = new EventEmitter();

  @Input()
  fileId: string;
  inspectionData: InspectionData[];
  inspectionFile;
  isVideoPlaying: boolean = false;
  filePath;
  mimeType;

  constructor(
    private api: ApiService,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private titleService: Title,
    private domSanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) {
    this.titleService.setTitle("AVECS Inspection");
  }

  async ngOnInit() {
    try {
      await this.loadInspectionData();
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
    this.onLoaded.emit();
  }


  handleClick() {
    console.log("Download me");
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: File Summary View - Inspection<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: File Summary View - Inspection';
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, this.fileId, -1);
      }
    });
  }

  async loadInspectionData() {
    try {
      this.inspectionData = await this.api.getInspectionDetailsByFileId(this.fileId);
      // console.log(this.inspectionData);
      await this.fetchInspectionVideoFiles();
    } catch (error) {
      this.showError(
        "Error Loading Inspection Data (loadInspectionData())",
        error
      );
      this.log.error(error);
    }
  }

  async fetchInspectionVideoFiles() {
    try {
      if (!this.inspectionData) return;
      for (let inspection of this.inspectionData) {
        if (inspection.videos.length > 0) {
          for (let video of inspection.videos) {
            const videoFile = await this.api.getInspectionFileByFilePath(video.fullPath, video.docMimeType);
            const videoBlob = new Blob([videoFile], { type: 'arraybuffer' });
            video.video = this.getSafeVideoUrl(URL.createObjectURL(videoBlob));
          }
        }
      }
    }
    catch (error) {
      this.showError(
        "Error getting videos for inspection. Please contact support (fetchInspectionVideoFiles()))",
        error
      );
      this.log.error(error);
    }
  }

  async downloadFile(fullPath: string, docMimeType: string, displayText: string) {
    try {
      const videoFile = await this.api.getInspectionFileByFilePath(fullPath, docMimeType);

      // Assuming 'videoFile' is a binary video data (e.g., ArrayBuffer)
      const videoBlob = new Blob([videoFile], { type: docMimeType });

      // Create a Blob URL for the video
      const videoUrl = URL.createObjectURL(videoBlob);

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = videoUrl;
      downloadLink.download = displayText;

      // Append the download link to the document body
      document.body.appendChild(downloadLink);

      // Simulate a click event to trigger the download
      downloadLink.click();

      // Clean up by removing the download link
      document.body.removeChild(downloadLink);

      // Clean up by revoking the Blob URL
      URL.revokeObjectURL(videoUrl);
    }
    catch (error) {
      this.showError(
        "Error downloading video. Please contact support (downloadFile())",
        error
      );
      this.log.error(error);
    }
  }
  formatDate(value) {
    return moment(value).format('HH:mm:ss DD/MM/YYYY');
  }

  // Define a function to sanitize the video URL
  getSafeVideoUrl(fileContents: string): SafeResourceUrl {
    const url = this.sanitizer.bypassSecurityTrustResourceUrl(fileContents);
    return url;
  }
}
