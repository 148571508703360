
<p-fieldset legend="Consignee Details">

  <div class="ui-g">
    <p-fieldset class="ui-g-12" legend="Consignee">
      <div class="ui-g-12">
        <div class="ui-g-12">
          <span class="ui-g-6 ui-float-label">
            <p-autoComplete class="full-width" id="float-input" [suggestions]="people"
              (completeMethod)="searchPerson($event)" [minLength]="3" field="person" [multiple]="false"
              [forceSelection]="true" [(ngModel)]="consigneeSearchDetails" (onSelect)="getConsigneeDetails($event)"
              delay="1000">
            </p-autoComplete>
            <label class="pad-left" for="float-input">Consignee</label>
          </span>
          <div class="ui-g-1">
            <button pRipple pButton class="p-button-info" *ngIf="!showConsigneeDetails" icon="fa fa-plus-circle"
              (click)="createNewContact()"></button>
            <button pRipple pButton class="p-button-info" *ngIf="showConsigneeDetails" icon="fa fa-edit"
              (click)="editConsignee()"></button>
          </div>
        </div>
        <div *ngIf="showConsigneeDetails">
          <div class="ui-g-12">
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="consigneeDetails.FirstName? consigneeDetails.FirstName : 'N/A'" disabled>
              <label class="pad-left" for="float-input">First Name</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="consigneeDetails.LastName? consigneeDetails.LastName : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Last Name</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="consigneeDetails.Email? consigneeDetails.Email : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Email Address</label>
            </span>
          </div>
          <div class="ui-g-12">
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="consigneeDetails.Cellphone? consigneeDetails.Cellphone : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Cellphone Number</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="consigneeDetails.Address? consigneeDetails.Address : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Address</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="consigneeDetails.City? consigneeDetails.City : 'N/A'" disabled>
              <label class="pad-left" for="float-input">City</label>
            </span>
          </div>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset class="ui-g-12" legend="Notify Party">
      <div class="ui-g-12">
        <div class="ui-g-12">
          <span class="ui-g-6 ui-float-label">
            <p-autoComplete class="full-width" id="float-input" [suggestions]="people"
              (completeMethod)="searchPerson($event)" [minLength]="3" field="person" [multiple]="false"
              [forceSelection]="true" [(ngModel)]="contactSearchDetails" (onSelect)="getContactsDetails($event)"
              delay="1000">
            </p-autoComplete>
            <label class="pad-left" for="float-input">Notify Party</label>
          </span>
          <div class="ui-g-1">
            <button pRipple pButton class="p-button-info" *ngIf="!showContactDetails" icon="fa fa-plus-circle"
              (click)="createNewContact()">
            </button>
            <button pRipple pButton class="p-button-info" *ngIf="showContactDetails" icon="fa fa-edit"
              (click)="editConsignee()"></button>
          </div>
        </div>
        <div *ngIf="showContactDetails && contactDetails && contactDetails.FirstName">
          <div class="ui-g-12">
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="contactDetails.FirstName? contactDetails.FirstName : 'N/A'" disabled>
              <label class="pad-left" for="float-input">First Name</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="contactDetails.LastName? contactDetails.LastName : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Last Name</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="contactDetails.Email? contactDetails.Email : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Email Address</label>
            </span>
          </div>
          <div class="ui-g-12">
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="contactDetails.Cellphone? contactDetails.Cellphone : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Cellphone Number</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="contactDetails.Address? contactDetails.Address : 'N/A'" disabled>
              <label class="pad-left" for="float-input">Address</label>
            </span>
            <span class="ui-g-4 ui-float-label">
              <input class="full-width" id="float-input" pInputText
                [value]="contactDetails.City? contactDetails.City : 'N/A'" disabled>
              <label class="pad-left" for="float-input">City</label>
            </span>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="p-col-12 p-lg-4 p-md-4">
    <button pRipple pButton class="p-button-info" icon="fas fa-save" iconPos="right" label="Save & Back"
      (click)="Back()"></button>
    <button pRipple pButton class="p-button-info" icon="fas fa-save" iconPos="right" label="Save & Next"
      (click)="Next()" [disabled]="!consigneeId">
    </button>
  </div>
</p-fieldset>