<div class="ui-g">
    <div class="ui-g-12">
        <p-table #no [autoLayout]="true" [rows]="30" [paginator]="true" [value]="notes"
            [globalFilterFields]="['CreatedBy', 'Note']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex table-header">
                    <span class="p-input-icon-left">
                        <button pRipple class="p-button-info ui-button-success" pButton type="button"
                            pTooltip="Refresh Notes" [icon]="loadIcon" (click)="refreshList()"></button>
                        <button pRipple class="p-button-info ui-button-success margin-left-5px" type="button"
                            (click)="addNote()" pButton pTooltip="Add Note" icon="fas fa-plus"></button>
                    </span>
                    <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="no.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search note(s)" style="color: #fff;" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pSortableColumn="CreatedBy">
                        Created By
                        <p-sortIcon field="CreatedBy"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Created">
                        Created
                        <p-sortIcon field="Created"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Note">
                        Note
                        <p-sortIcon field="Note"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-note>
                <tr>
                    <td>{{note.CreatedBy}}</td>
                    <td>{{note.Created}}</td>
                    <td>{{note.Note}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Add Note" [style]="{width: '50vw'}" [dismissableMask]="true" [(visible)]="displayNote"
    [responsive]="true" showEffect="fade" [modal]="true" (onHide)="ResetDialogValues()">
    <div class="ui-g">
        <div class="ui-g-2">
            Select Note Type:
        </div>
        <div class="ui-g-10">
            <p-dropdown [style]="{'width':'50%'}" placeholder="Select a Note Type" appendTo="body" class="full-width"
                [options]="noteTypes" [(ngModel)]="selectedNoteType" (onChange)="setupNote()" [filter]="true"
                [showClear]="true"></p-dropdown>
        </div>
        <div class="ui-g-12 centerInDiv">
            <textarea pInputTextarea autoResize="autoResize" style="width: 100%; height: 150px;"
                [disabled]="noteTypeSelected" [(ngModel)]="noteData"></textarea>
        </div>
        <div class="ui-g-12 centerInDiv">
            <button pRipple class="p-button-info" [disabled]="disableSaveNote()" label="Save" type="button"
                (click)="saveNote()" pButton icon="fas fa-plus"></button>
        </div>
    </div>
</p-dialog>