import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinanceHelperService {

  constructor() { }

  public formatAmountWithCurrencySymbol(amount: number, currencyCode: string): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const formattedAmount = formatter.format(Math.abs(amount));
    return amount < 0 ? `-${currencyCode} ${formattedAmount}` : `${currencyCode} ${formattedAmount}`;
  }
}
