<div *ngIf="showSpinner" style="position: absolute; top: 50%; left: 50%; translate: -50% -50%;">
  <p-progressSpinner [style]="{width: '70px', height: '70px'}" strokeWidth="6" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>
<div *ngIf="!showSpinner" class="ui-g-12">
  <div class="card page">
    <div class="header">
      <div class="chips">
        <!-- Status Chips Update to chips when we upgrade to PrimeNG > 11 -->
        <div *ngIf="fileCancelledLabel" class="chip" style="background: green;">{{fileCancelledLabel}}</div>
        <!-- if it's not CIF and there is no amount owed can't accept terms -->

        <!-- TODO -->
        <div *ngIf="!file.CIF && !isAmountOwed" class="chip" [ngStyle]='{"background-color": checkTerms()}'
          pTooltip="{{termsToolTip}}">
          {{termsAndConditions}}
        </div>
        <!-- if it's not CIF and there is amount owed then can accept terms -->
        <div *ngIf="!file.CIF && isAmountOwed" class="chip" [ngStyle]='{"background-color": checkTerms()}'
          pTooltip="{{termsToolTip}}" (click)="showAcceptTermsDialog()" data-click="">
          {{termsAndConditions}}
        </div>
        <!-- if it's CIF don't allow to accept terms -->
        <div *ngIf="file.CIF" class="chip" [ngStyle]='{"background-color": checkTerms()}' pTooltip="{{termsToolTip}}">
          {{termsAndConditions}}
          <i class="pi pi pi-pencil"></i>
        </div>
        <div class="chip" [ngStyle]='{"background-color": checkFilesApproved()}' pTooltip="{{approvedToolTip}}">
          {{approvedToolTip}}
        </div>
        <div class="chip" [ngStyle]='{"background-color": checkIfPaid()}' pTooltip="{{paidTooltip}}">
          {{paidLabel}}
        </div>
        <div class="chip" [ngStyle]='{"background-color": "#4197fa"}' pTooltip="The file is {{cifLabel()}}">
          {{cifLabel()}}
        </div>
      </div>
      <div class="button-group">
        <p-splitButton #ua id="generate" [disabled]="readOnly" [styleClass]="!readOnly || 'disabled'"
          [menuStyle]="{'width':'400px'}" label="Documents" (onClick)="ua.onDropdownButtonClick(null)" icon="pi pi-gear"
          [model]="items" class="drop-down-button-left-margin"></p-splitButton>
        <p-splitButton #ub id="generate" [menuStyle]="{'width':'400px'}" label="Actions"
          (onClick)="ub.onDropdownButtonClick(null)" icon="pi pi-gear" [model]="actions"
          class="drop-down-button-left-margin margin-left-2px"></p-splitButton>
      </div>
    </div>
    <div class="file-info">
      <div class="file-summary">
        <div class="section bordered">
          <div class="group">
            <file-number [fileNumber]="file.DBNNumber"></file-number>
            <label data-label="Foreign Reference">{{ file.ForeignReference || '-' }}</label>
            <label data-label="Owner">{{ file.FileOwner }}</label>
            <label data-label="Opened By">{{ file.OpenedBy }}</label>
            <label (click)="showClient()" pTooltip="View supplier details" data-label="Supplier" data-hover="">
              <a>{{file.ClientName}} {{file.ClientMiddleNames}} {{file.ClientLastName}}</a>
            </label>
            <label (click)="(file.AgentName || file.AgentMiddleNames || file.AgentLastName) || showAgent()"
              data-label="Agent/Dealer">
              <a *ngIf="file.AgentName || file.AgentMiddleNames || file.AgentLastName" data-hover=""
                pTooltip="View agent/dealer details">
                {{file.AgentName}} {{file.AgentMiddleNames}} {{file.AgentLastName}}
              </a>
              <ng-container *ngIf="!file.AgentName && !file.AgentMiddleNames && !file.AgentLastName">-</ng-container>
            </label>
            <label data-label="Clearing Agent">{{file.ClearingAgent || '-'}}</label>
          </div>
          <div class="group">
            <h3>Consignee</h3>
            <label (click)="consigneeRoute()" class="click" data-label="Name"
              pTooltip="View consignee details">{{file.ConsigneeName}} {{file.ConsigneeMiddleNames}}
              {{file.ConsigneeLastName}}</label>
            <label data-label="Email" pTooltip="Email Consignee">
              <a (click)="$event.stopPropagation()" href="mailto:{{file.ClientEmail}}"
                target="_blank">{{file.ConsigneeEmail || file.ConsigneeEmail2 || '-'}}</a>
            </label>
            <label data-label="Cellphone 1">{{file.ConsigneeCellphone || '-'}}</label>
            <label data-label="Telephone 1">{{file.ConsigneeTelephone || '-'}}</label>
            <label data-label="Country">{{file.ConsigneeCountry || '-'}}</label>
          </div>
          <div class="group shortcut" data-goto="Go to Documents Tab" (click)="shortcut(4)">
            <h3>Document Status<i class="fa fa-external-link"></i></h3>
            <label data-label="Duties Doc Received">{{ file.DutiesReceivedDate || '-' }}</label>
            <label data-label="Duties Send To Border">{{ file.DutiesSentDate || '-' }}</label>
            <label data-label="Duties Confirmed">{{ file.DutiesConfirmedDate || '-' }}</label>
            <label data-label="SARS Docs Sent To Client">{{ file.SARSDocsSentDate || '-' }}</label>
          </div>
          <div class="group shortcut" data-goto="Go to Finance Tab" (click)="shortcut(7)">
            <h3>Finance<i class="fa fa-external-link"></i></h3>
            <label data-label="Invoice/s">{{ file.Invoice || '-' }}</label>
            <label *ngFor="let balance of fileOutStandingAmounts"
              [attr.data-label]="'Balance Due In ' + balance.currencyCode">{{ balance.amountOutStanding.toFixed(2) || '0.00' }}</label>
          </div>
        </div>
        <div class="section bordered">
          <div class="group">
            <h3>Tasks</h3>
            <ng-container *ngIf="!fileTasks.supported">
              <label style="font-style: italic;padding: 0;">Display not supported for older files</label>
            </ng-container>
            <ng-container *ngIf="fileTasks.supported">
              <label data-label="Check SMS">{{ fileTasks.tasks?.checkSMS ? "Complete" : "Not Complete" }}</label>
              <label data-label="Check Email">{{ fileTasks.tasks?.checkEmail ? "Complete" : "Not Complete" }}</label>
              <label data-label="Check Route">{{ fileTasks.tasks?.checkRoute ? "Complete" : "Not Complete" }}</label>
              <label
                data-label="Check Cargo Value">{{ fileTasks.tasks?.checkCargoValue ? "Complete" : "Not Complete" }}</label>
            </ng-container>
          </div>
          <div class="group shortcut" data-goto="Go to Shipping Tab" (click)="shortcut(2)">
            <h3>Shipping<i class="fa fa-external-link"></i></h3>
            <label data-label="Voyage">{{ file.VoyageNumber || '-' }}</label>
            <label data-label="Vessel">{{ file.VesselName || '-' }}</label>
            <label data-label="ETA">{{ file.ETA | date: "yyyy-MM-dd" }}</label>
          </div>
          <div class="group">
            <h3>Location</h3>
            <label data-label="Last Leg Completed">{{ file.Destination }}</label>
            <label data-label="Current Location">{{ file.CurrentLocation}}</label>
            <label data-label="Destination">{{ file.LastDestination }}</label>
          </div>
          <div class="group">
            <h3><br></h3>
            <label data-label="Next Location">{{ file.NextLocation }}</label>
          </div>
        </div>
      </div>

      <div>
        <div class="bordered hover-editable" ngClass="{{readOnly || fileEditMode ? 'edit-on' : 'edit-off'}}"
          (click)="readOnly || fileEditMode || setFileEdit($event)" id="parent-click" style="z-index: 99;">
          <i class="hover-icon fa fa-edit"></i>
          <i style="z-index: 999;" *ngIf="fileInformationSpinner" class="fa fa-spinner spin"></i>
          <h3 *ngIf="!fileEditMode" class="edit-header">
            File Information
            <!-- <i class="fa fa-edit"></i> -->
          </h3>
          <h3 *ngIf="fileEditMode" class="edit">
            Editing File Information
            <div>
              <label (click)="!fileInformationSpinner ? updateFileInfo() : null;">
                <i class="fa fa-check"></i>
                Save</label>
              <label id="cancel-click" (click)="!fileInformationSpinner ? setFileEdit($event) : null;"><i
                  class="fa fa-times"></i>Cancel</label>
            </div>
          </h3>
          <div class="p-grid" *ngIf="!fileEditMode">
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [value]="file.FileDocumentSource || '-'" disabled>
              <label class="pad-left" for="float-input">File Source</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [value]="file.BorderAgent|| '-'" disabled>
              <label class="pad-left" for="float-input">Border Agent</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [value]="file.Courier|| '-'" disabled>
              <label class="pad-left" for="float-input">Courier Company</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [value]="file.TrackingNumber|| '-'" disabled>
              <label class="pad-left" for="float-input">Tracking Number</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [value]="file.CIF? 'CIF' : 'Non-CIF'" disabled>
              <label class="pad-left" for="float-input">File Type</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [value]="file.CIFDestination|| '-'" disabled>
              <label class="pad-left" for="float-input">CIF Destination</label>
            </span>
          </div>

          <div class="p-grid" *ngIf="fileEditMode">
            <span class="ui-g-6 ui-float-label">
              <p-dropdown class="full-width" id="float-input" [options]="fileDocumentSources"
                [disabled]="fileInformationSpinner" [style]="{'width': '100%'}" [autoDisplayFirst]="false"
                [(ngModel)]="form.FileDocumentSourceId">
              </p-dropdown>
              <label class="pad-left" for="float-input">File Document Source</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <p-dropdown class="full-width" id="float-input" [options]="borderAgents" [filter]="true" filterBy="label"
                [disabled]="fileInformationSpinner" [autoDisplayFirst]="false" [style]="{'width': '100%'}"
                [(ngModel)]="form.BorderAgentId" [showClear]="true">
              </p-dropdown>
              <label class="pad-left" for="float-input">Border Agent</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <p-dropdown class="full-width" id="float-input" [options]="courierCompanies" [style]="{'width': '100%'}"
                [disabled]="fileInformationSpinner" [autoDisplayFirst]="false" [(ngModel)]="form.CourierId">
              </p-dropdown>
              <label class="pad-left" for="float-input">Courier Company</label>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [(ngModel)]="form.TrackingNumber"
                [disabled]="fileInformationSpinner">
              <label class="pad-left" for="float-input">Tracking Number</label>
            </span>
            <span class="ui-g-6 ui-float-label" style="display: flex;">
              <input class="full-width ui-g-9" id="float-input" pInputText [value]="form.CIF? 'CIF' : 'Non-CIF'"
                disabled>
              <label class="pad-left" for="float-input">File Type</label>
              <p-inputSwitch [(ngModel)]="form.CIF" [disabled]="fileInformationSpinner">
              </p-inputSwitch>
            </span>
            <span class="ui-g-6 ui-float-label">
              <input class="full-width" id="float-input" pInputText [(ngModel)]="form.CIFDestination"
                [disabled]="fileInformationSpinner">
              <label class="pad-left" for="float-input">CIF Destination</label>
            </span>
          </div>
        </div>
        <div class="route-leg-container">
          <h3 class="route-leg-header">Route Leg Assignment and Drivers</h3>
          <leg-assignment [fileId]="fileId"></leg-assignment>
        </div>
      </div>
    </div>
    <div class="cargo-summary">
      <div class="bordered">
        <bracket-text *ngFor="let cd of file.CargoDescription" [text]="cd.Description"></bracket-text>
      </div>
    </div>
    <p-tabView (onChange)="handleChange($event);" [activeIndex]="activeIndex">
      <p-tabPanel header="Notes" leftIcon="fas fa-book-open" tooltip="Notes">
        <app-notes *ngIf="is(0)" (onLoaded)="scroll()" [fileId]="fileId"></app-notes>
      </p-tabPanel>

      <p-tabPanel header="Cargo" leftIcon="fas fa-car" tooltip="Cargo">
        <app-cargo *ngIf="is(1)" (onLoaded)="scroll()" [FileId]="fileId"></app-cargo>
      </p-tabPanel>

      <p-tabPanel header="Shipping Info" leftIcon="fas fa-ship" tooltip="Shipping Info">
        <app-shipping-info *ngIf="is(2)" (onLoaded)="scroll()" [FileId]="fileId">
        </app-shipping-info>
      </p-tabPanel>

      <p-tabPanel header="Clearing Agent" leftIcon="fas fa-truck-moving" tooltip="Clearing Agent">
        <h2
          *ngIf="file.ClearingStatusId != clearingStatus.HANDOVER && file.ClearingStatusId != clearingStatus.PARTIAL_HANDOVER">
          File is not a Handover or Partial Handover.
        </h2>
        <app-add-clearing-agent
          *ngIf="file.ClearingStatusId == clearingStatus.HANDOVER || file.ClearingStatusId == clearingStatus.PARTIAL_HANDOVER && is(3)"
          (onLoaded)="scroll()" (onClearingAgentChange)="getFile()" [fileId]="fileId">
        </app-add-clearing-agent>
      </p-tabPanel>

      <p-tabPanel header="Documents" leftIcon="fas fa-passport" tooltip="Documents">
        <app-document-history *ngIf="is(4)" (onLoaded)="scroll()" [DBNum]="fileId"
          (OnTermsInfoChanged)="onTermsInfoChanged($event)">
        </app-document-history>
      </p-tabPanel>

      <p-tabPanel header="Correspondence" leftIcon="fas fa-envelope" tooltip="Emails / SMS / WhatsApp">
        <app-correspondence *ngIf="is(5)" (onLoaded)="scroll()" [fileId]="fileId"></app-correspondence>
      </p-tabPanel>

      <p-tabPanel header="Inspection" leftIcon="fas fa-clipboard-list" tooltip="Inspection">
        <app-inspection *ngIf="is(6)" (onLoaded)="scroll()" [fileId]="fileId"></app-inspection>
      </p-tabPanel>

      <p-tabPanel header="Finance" leftIcon="fas fa-file-invoice-dollar" tooltip="Finance">
        <app-finance-summary *ngIf="is(7)" [fileId]="fileId" (onLoaded)="scroll(); checkBalance();"
          (onInvoiceCancel)="refreshFinance()" [refresh]="refreshFinanceTab"
          (changeRefresh)="refreshFinanceTab = $event">
        </app-finance-summary>
      </p-tabPanel>

      <p-tabPanel header="History" leftIcon="fas fa-history" tooltip="History">
        <p-tabView>
          <p-tabPanel header="Preference History" leftIcon="fas fa-folder" tooltip="Preference History">
            <file-preferences-history *ngIf="is(8)" [fileId]="fileId" (onLoaded)="scroll()">
            </file-preferences-history>
          </p-tabPanel>
        </p-tabView>
      </p-tabPanel>

      <p-tabPanel header="Preferences" leftIcon="fas fa-folder" tooltip="Preferences">
        <file-preferences *ngIf="is(9)" [fileIds]="[fileId]"></file-preferences>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog header="Open File" modal="true" [(visible)]="fileOpen" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Are you sure you want to open this file?</label><br />
    <label>This process is NOT reversable!</label>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="openFile()" label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="cancelConversion()" label="No"></button>
  </p-footer>
</p-dialog>
<p-dialog header="{{convertFileDialogText}}" modal="true" [(visible)]="convertFile" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Are you sure you want to convert this file?</label><br />
    <label>This action will cancel ALL workflow tasks, cancel ALL invoices, and convert the file!</label><br />
    <label>This process is NOT reversable!</label>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="convertFileClearingStatus()"
      label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="cancelConversion()" label="No"></button>
  </p-footer>
</p-dialog>


<p-dialog header="WARNING" modal="true" [(visible)]="closeFileDialog" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Are you sure you want to Close this file?</label><br />
    <label>This action will cancel ALL workflow tasks, and close the file!</label><br />
    <label>This process is NOT reversable!</label>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeFile()" label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeCancelFile()" label="No"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Restart Workflow" modal="true" [(visible)]="closeRestartDialog" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Are you sure you want to Restart the Workflow for this file?</label><br />
    <label>This action will cancel ALL workflow tasks, cancel ALL invoices, and restart the workflow for this
      file!</label><br />
    <label>This process is NOT reversable!</label>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="RestartWorkflow()" label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeRestartWorkflow()" label="No"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Reset Route" modal="true" [(visible)]="resetRouteDialog" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Are you sure you want to Reset the Route for this file?</label><br />
    <label>This process is NOT reversable!</label><br />
    <label>Please state the reason for reseting the route:</label><br />
    <textarea pInputTextarea class="note-text" maxlength="500" [(ngModel)]="resetReason"></textarea>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" [disabled]="resetReason == ''" (click)="resetRoute()"
      label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" label="No" (click)="routeReset()"></button>
  </p-footer>
</p-dialog>

<p-dialog header="CIF Status Changed. Restart Workflow" modal="true" [(visible)]="closeRestartCIFDialog"
  [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>You have changed the CIF value. This requires a new Workflow. Are you sure you want to Restart the Workflow
      for this file?</label><br />
    <label>This action will cancel ALL workflow tasks, cancel ALL invoices, and restart the workflow for this
      file!</label><br />
    <label>This process is NOT reversable!</label>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="RestartWorkflow()" label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeRestartWorkflow()" label="No"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Print HBL (RORO)" modal="true" [(visible)]="HBLDialog" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Select version(s) to Print: </label>
  </div>
  <div class="ui-g-5">
    <label>Email original to Supplier</label>
  </div>
  <div class="ui-g-7">
    <p-checkbox [(ngModel)]="EmailOriginal" binary="true"></p-checkbox>
  </div>
  <div class="ui-g-5">
    <label>HBL versions</label>
  </div>
  <div class="ui-g-7">
    <p-multiSelect [options]="HBLversions" [(ngModel)]="selectedHBLversions" optionLabel="label"
      defaultLabel="Select HBL  version(s)" [style]="{'position': 'fixed', 'overflow': 'visible', 'z-index': '999'}">
    </p-multiSelect>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeHBL()" label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="HBLDialog = false" label="No"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Print HBL (Other)" modal="true" [(visible)]="HBLMultiItemDialog" [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>Select version(s) to Print: </label>
  </div>
  <div class="ui-g-5">
    <label>Email original to Supplier</label>
  </div>
  <div class="ui-g-7">
    <p-checkbox [(ngModel)]="EmailOriginal" binary="true"></p-checkbox>
  </div>
  <div class="ui-g-5">
    <label>HBL versions</label>
  </div>
  <div class="ui-g-7">
    <p-multiSelect [options]="HBLversions" [(ngModel)]="selectedHBLversions" optionLabel="label"
      defaultLabel="Select HBL  version(s)" [style]="{'position': 'fixed', 'overflow': 'visible', 'z-index': '999'}">
    </p-multiSelect>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeMultiLineHBL()" label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="HBLMultiItemDialog = false"
      label="No"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Assign File to Dealer/Agent" modal="true" [(visible)]="AssignToDealerialog" [style]="{width: '30vw'}">
  <div class="ui-g-12">
    <label>Select an Agent/Dealer to assign this file to</label>
  </div>
  <div class="ui-g-5">
    <label>Agent/Dealers</label>
  </div>
  <div class="ui-g-7">
    <p-dropdown appendTo="body" [options]="agents" [filter]="true" [(ngModel)]="selectedAgent" placeholder="Select..."
      [style]="{'width':'200px'}" itemSize="10">
    </p-dropdown>
  </div>

  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="closeAssignFileToAgent()"
      label="Assign"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="AssignToDealerialog = false"
      label="Cancel"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Terms & Conditions" modal="true" [(visible)]="acceptTermsDialog" [style]="{width: '30vw'}"
  (onHide)="acceptTermsDialog = false">
  <div class="ui-g-12">
    <label><strong>Accept or Decline on clients behalf</strong></label>
  </div>

  <p-footer style="display: flex;">
    <button pRipple class="p-button-info width-100" pButton type="button" (click)="AcceptTermsAndConditions()"
      label="Accept"></button>
    <button pRipple class="p-button-info width-100" pButton type="button" (click)="DeclineTermsAndConditions()"
      label="Decline"></button>
  </p-footer>
</p-dialog>

<p-dialog header="No Clearing Agent for File" modal="true" [(visible)]="showClearingAgentWarning"
  [style]="{width: '50vw'}">
  <div class="ui-g-12">
    <label>You are attempting to generate a Handover letter for a file with no Clearing Agent assigned</label><br />
    <label>Please assign a Clearing Agent before continuing</label><br />
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="showClearingAgentWarning = false"
      label="Okay"></button>

  </p-footer>
</p-dialog>

<p-dialog header="" [(visible)]="deepLinkDialog">
  <p>Click link to copy</p>
  <div class="deep-link">
    <p (click)="copyDeepLink()">{{ deepLink }} <i class="pi pi-copy"></i></p>
  </div>
</p-dialog>

<p-dialog header="Storage Invoice" modal="true" [(visible)]="storageInvoiceDialog" [style]="{width: '420px'}"
  (onHide)="storageInvoiceDialog = false">
  <div class="ui-g-12">
    <label>Please select a date you want storage to charge up to</label>
  </div>
  <div class="ui-g-12">
    <span class="ui-float-label">
      <p-calendar [(ngModel)]="storageInvoiceDate" dateFormat="yy/mm/dd" appendTo="body" [readonlyInput]="true">
      </p-calendar>
      <label class="float-label" for="float-input">Storage Date *</label>
    </span>
  </div>
  <p-footer>
    <button *ngIf="!actionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="CreateManualStorageInvoice()" label="Charge Storage"></button>
    <button *ngIf="actionSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>