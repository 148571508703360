<div class="ui-g">
  <p-table [loading]="showSpinner" [value]="filePreferenceHistory" dataKey="dbnNumber" #dt [autoLayout]="true"
    [scrollable]="true" scrollHeight="400px" [rows]="50" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['dbnNumber', 'filePreference', 'auditUserName']">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search"
            style="color: #fff;" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="preference">
          Preference
          <p-sortIcon field="preference"></p-sortIcon>
        </th>
        <th pSortableColumn="insertDate">
          Date
          <p-sortIcon field="insertDate"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'preference', 'contains')"
            class="width-100">
        </th>
        <th>
          <p-calendar (onSelect)="onFilterDateSelect($event)" class="width-100"
            (onClearClick)="dt.filter('', 'insertDate', 'contains')" [showButtonBar]="true" [readonlyInput]="true"
            dateFormat="dd/mm/yy" appendTo="body">
          </p-calendar>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData.preference }}</td>
        <td>{{ formatInsertDate(rowData.insertDate) }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td>No file preferences found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>