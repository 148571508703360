 <p-table [columns]="loadTableColumns" [value]="loads" dataKey="dispatchLoadPlanId" [rows]="25" [lazy]="true">
   <ng-template pTemplate="caption">
     Delivery Load Planning
   </ng-template>
   <ng-template pTemplate="header" let-columns>
     <tr>
       <th>Show Cargo</th>
       <th *ngFor="let col of columns">
         {{col.header}}
       </th>
     </tr>
   </ng-template>
   <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
     <tr>
       <td>
         <a [pRowToggler]="rowData">
           <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
             style="font-weight: 800; font-size: 26px;"></i>
         </a>
       </td>
       <td *ngFor="let col of columns" [ngClass]="{cssClass: expression}">
         {{ rowData[col.field] }}
       </td>
     </tr>
   </ng-template>
   <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
     <tr>
       <td colspan="12">
         <div class="ui-g-12">
           <cargo-table [loadId]="rowData.dispatchLoadPlanId" [rowData]="rowData" [loadName]="rowData.name"
             [loadType]="loadTypes.DISPATCH" (refreshLoad)="getCurrentDispatchLoads()" [statusData]="statusData"
             [managerType]="2">
           </cargo-table>
         </div>
       </td>
     </tr>
   </ng-template>
 </p-table>