<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Items in Container {{this.id}}</h1>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-12 centerSpinner" *ngIf="showLoadingSpinner">
      <p-progressSpinner id="p-spinner" [style]="{ width: '30px', height: '30px' }" strokeWidth="6" fill="#EEEEEE"
        animationDuration="2s"></p-progressSpinner>
    </div>
  </div>
  <div class="ui-g-12">
    <p-table #dt [value]="containerItems" [pageLinks]="3" [rows]="75" [paginator]="true"
      [(selection)]="selectedContainer" [autoLayout]="true" [globalFilterFields]="['DBNNumber', 'ForeignReference',
    'Description']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search container items" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th><b>DBNNumber</b></th>
          <th><b>Cargo Type</b></th>
          <th><b>Reference</b></th>
          <th><b>Qty</b></th>
          <th><b>Description</b></th>
          <th><b>L/W/H</b></th>
          <th><b>Weight</b></th>
          <th><b>Actions</b></th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-container>
        <tr>
          <td>{{container.DBNNumber}}</td>
          <td>{{container.CargoType}}</td>
          <td>{{container.ForeignReference}}</td>
          <td>{{container.Quantity}}</td>
          <td>{{container.Description}}</td>
          <td>{{container.Length}}/{{container.Width}}/{{container.Height}}</td>
          <td>{{container.Weight}}</td>
          <td>
            <button pRipple pButton class="p-button-info" icon="far fa-folder" (click)="openFile(container.FileId)"
              pTooltip="View file" tooltipPosition="bottom">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>