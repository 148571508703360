
<div class="ui-g-12">

  <p-fieldset legend="Item {{ i + 1 }} - {{ displayItemLabel(form) }} "
    *ngFor="let form of fileItemsForms; let i = index">

    <div [formGroup]="form.form" *ngIf="form.formType == 1" (ngSubmit)="navNext()">
      <div class="ui-g-12" *ngIf="setupOption == 1">
        <span class="ui-g-5 ui-float-label">
          <p-autoComplete class="full-width" id="float-input" [(ngModel)]="form.selectedContainer"
            formControlName="containerId" [suggestions]="containerList" (completeMethod)="searchContainers($event)"
            field="ContainerNumber" [dropdown]="true" required>
          </p-autoComplete>
          <label class="pad-left" for="float-input">Container Number *</label>

        </span>
        <div class="ui-g-1" *ngIf="form.formType == 1">
          <button pRipple class="p-button-info float-right" pButton type="button" icon="fa fa-plus"
            (click)="addContainer()"></button>
        </div>
        <span class="ui-g-6 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="containerSealNo" />
          <label class="pad-left" for="float-input">Container Seal Number</label>
        </span>

      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="vehicleMakes"
            formControlName="vehicleMake" optionLabel="Name" [filter]="true"
            (onChange)="getVehicleModels($event, form)">
          </p-dropdown>
          <label class="pad-left" for="float-input">Make *</label>
        </span>
        <span class="ui-g-2 ui-float-label">
          <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="form.models"
            formControlName="vehicleModel" optionLabel="Name" [filter]="true">
          </p-dropdown>
          <label class="pad-left" for="float-input">Model *</label>
        </span>

        <div class="ui-g-1">
          <button pRipple class="p-button-info float-right" pButton type="button" icon="fa fa-plus" (click)="addModel()"
            [disabled]="disableVehicleModelButton(form)"></button>
        </div>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="modelCode" />
          <label class="pad-left" for="float-input">Model Code</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="grade" />
          <label class="pad-left" for="float-input">Grade</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="years"
            formControlName="year" field="selectedYear" optionLabel="selectedYear" [filter]="true">
          </p-dropdown>
          <label class="pad-left" for="float-input">Year *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <p-calendar class="full-width" id="float-input" formControlName="month" view="month" dataType="string"
            dateFormat="m">
          </p-calendar>
          <label class="pad-left" for="float-input">Month</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="chassisNo" />
          <label class="pad-left" for="float-input">Chassis Number *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="engineNo" />
          <label class="pad-left" for="float-input">Engine Number</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="mileage" />
          <label class="pad-left" for="float-input">Mileage *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="colours" [filter]="true"
            formControlName="colour" optionLabel="Color" [showClear]="true"></p-dropdown>
          <label class="pad-left" for="float-input">Colour *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="transmissionTypes"
            formControlName="transmission" optionLabel="Name" [showClear]="true"></p-dropdown>
          <label class="pad-left" for="float-input">Transmission</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="fuelTypes"
            formControlName="fuel" optionLabel="Name" [showClear]="true"></p-dropdown>
          <label class="pad-left" for="float-input">Fuel Type</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" pTooltip="Maximum 14M"
            tooltipPosition="left" tooltipEvent="focus" pInputText formControlName="length" />
          <label class="pad-left" for="float-input">Length (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" pTooltip="Maximum 14M"
            tooltipPosition="left" tooltipEvent="focus" pInputText formControlName="width" />
          <label class="pad-left" for="float-input">Width (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="height" />
          <label class="pad-left" for="float-input">Height (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="weight" />
          <label class="pad-left" for="float-input">Weight (Kilograms) *</label>
        </span>
      </div>
      <div class="ui-g-5">
        <div class="ui-g-4">
          <button pRipple pButton class="p-button-info" icon="fa fa-times-circle" iconPos="right" label="Remove"
            (click)="RemoveItem(form)"></button>
        </div>
        <div class="ui-g-4" *ngIf="form.showSaveButton">
          <button pRipple pButton class="p-button-info" icon="fa fa-save" iconPos="right" label="Save"
            (click)="saveItem(form)" [disabled]="!form.form.valid"></button>
        </div>
        <div class="ui-g-4" *ngIf="!form.showSaveButton">
          <button pRipple pButton class="p-button-info" icon="fa fa-edit" iconPos="right" label="Edit"
            (click)="editItem(form)"></button>
        </div>
      </div>
    </div>

    <div *ngIf="form.formType == 2" [formGroup]="form.form" id="{{ form.Id }}">
      <div class="ui-g-12">
        <span class="ui-g-5 ui-float-label" *ngIf="setupOption == 1">
          <p-autoComplete class="full-width" id="float-input" [(ngModel)]="form.selectedContainer"
            [suggestions]="containerList" (completeMethod)="searchContainers($event)" field="ContainerNumber"
            [dropdown]="true" formControlName="containerId">
          </p-autoComplete>
          <label class="pad-left" for="float-input">Countainer Number *</label>
        </span>
        <div class="ui-g-1">
          <button pRipple class="p-button-info" pButton type="button" icon="fa fa-plus"
            (click)="addContainer()"></button>
        </div>
        <span class="ui-g-6 ui-float-label" *ngIf="setupOption == 1">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="containerSealNo" />
          <label class="pad-left" for="float-input">Countainer Seal Number</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="engineNo" />
          <label class="pad-left" for="float-input">Engine Number *</label>
        </span>
        <span class="ui-g-9 ui-float-label">
          <p-autoComplete class="full-width" id="float-input" delay="1000" [suggestions]="descriptionOptions"
            (completeMethod)="searchDescriptions($event)" [minLength]="1" field="Name" [multiple]="false"
            [forceSelection]="false" formControlName="description" [dropdown]="true">
          </p-autoComplete>
          <label class="pad-left" for="float-input">Description *</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" pTooltip="Max 14m"
            tooltipPosition="left" tooltipEvent="focus" formControlName="length" />
          <label class="pad-left" for="float-input">Length (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="width" />
          <label class="pad-left" for="float-input">Width (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="height" />
          <label class="pad-left" for="float-input">Height (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="weight" />
          <label class="pad-left" for="float-input">Weight (Kilograms) *</label>
        </span>
      </div>

      <div class="ui-g-5">
        <div class="ui-g-4">
          <button pRipple pButton class="p-button-info" icon="fa fa-times-circle" iconPos="right" label="Remove"
            (click)="RemoveItem(form)"></button>
        </div>
        <div class="ui-g-4" *ngIf="form.showSaveButton">
          <button pRipple pButton class="p-button-info" icon="fa fa-save" iconPos="right" label="Save"
            (click)="saveItem(form)" [disabled]="!form.form.valid"></button>
        </div>
        <div class="ui-g-4" *ngIf="!form.showSaveButton">
          <button pRipple pButton class="p-button-info" icon="fa fa-edit" iconPos="right" label="Edit"
            (click)="editItem(form)"></button>
        </div>
      </div>
    </div>


    <div *ngIf="form.formType > 2" [formGroup]="form.form" id="{{ form.Id }}">
      <div class="ui-g-12">
        <span class="ui-g-5 ui-float-label" *ngIf="setupOption == 1">
          <p-autoComplete class="full-width" id="float-input" [(ngModel)]="form.selectedContainer"
            [suggestions]="containerList" (completeMethod)="searchContainers($event)" field="ContainerNumber"
            [dropdown]="true" formControlName="containerId">
          </p-autoComplete>
          <label class="pad-left" for="float-input">Countainer Number *</label>
        </span>
        <div class="ui-g-1">
          <button pRipple class="p-button-info" pButton type="button" icon="fa fa-plus"
            (click)="addContainer()"></button>
        </div>
        <span class="ui-g-6 ui-float-label" *ngIf="setupOption == 1">
          <input class="full-width" id="float-input" type="text" pInputText formControlName="containerSealNo" />
          <label class="pad-left" for="float-input">Countainer Seal Number</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="int"
            formControlName="quantity" />
          <label class="pad-left" for="float-input">Quantity *</label>
        </span>
        <span class="ui-g-9 ui-float-label">
          <p-autoComplete class="full-width" id="float-input" delay="1000" [suggestions]="descriptionOptions"
            (completeMethod)="searchDescriptions($event)" [minLength]="1" field="Name" [multiple]="false"
            [forceSelection]="false" formControlName="description" [dropdown]="true">
          </p-autoComplete>
          <label class="pad-left" for="float-input">Description *</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" pTooltip="Max 14m"
            tooltipPosition="left" tooltipEvent="focus" formControlName="length" />
          <label class="pad-left" for="float-input">Length (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="width" />
          <label class="pad-left" for="float-input">Width (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="height" />
          <label class="pad-left" for="float-input">Height (Meters) *</label>
        </span>
        <span class="ui-g-3 ui-float-label">
          <input class="full-width" id="float-input" type="text" pInputText pKeyFilter="num" formControlName="weight" />
          <label class="pad-left" for="float-input">Weight (Kilograms) *</label>
        </span>
      </div>

      <div class="ui-g-5">
        <div class="ui-g-4">
          <button pRipple pButton class="p-button-info" icon="fa fa-times-circle" iconPos="right" label="Remove"
            (click)="RemoveItem(form)"></button>
        </div>
        <div class="ui-g-4" *ngIf="form.showSaveButton">
          <button pRipple pButton class="p-button-info" icon="fa fa-save" iconPos="right" label="Save"
            (click)="saveItem(form)" [disabled]="!form.form.valid"></button>
        </div>
        <div class="ui-g-4" *ngIf="!form.showSaveButton">
          <button pRipple pButton class="p-button-info" icon="fa fa-edit" iconPos="right" label="Edit"
            (click)="editItem(form)"></button>
        </div>
      </div>
    </div>

  </p-fieldset>

  <p-fieldset legend="Add Item" *ngIf="isVehicle()">
    <div class="ui-g">
      <div class="ui-g-12 centerInDiv">
        <p-splitButton #ub (onClick)="ub.onDropdownButtonClick(null)" label="Select Item" icon="fas fa-plus"
          [model]="fileItemTypes"></p-splitButton>
      </div>
    </div>
  </p-fieldset>
</div>

<div class="p-col-12 p-lg-4 p-md-4">
  <button pRipple pButton class="p-button-info" icon="fas fa-save" iconPos="right" label="Save & Back" (click)="Back()">
  </button>
</div>