<div class="ui-g card card-w-title">
  <div class="ui-g-2">
    <h1>Master Bills</h1>
  </div>
  <div class="ui-g-12">
    <p-panel header="FILTER :" [style]="{ height: '150px' }">
      <div class="ui-g">
        <div class="ui-g-4">
          <p-dropdown [options]="vessels" [(ngModel)]="selectedVessel" placeholder="Select a Vessel*" optionLabel="name"
            (onChange)="getVoyages()" [filter]="true" [style]="{ width: '100%'}"></p-dropdown>
        </div>
        <div class="ui-g-4">
          <p-dropdown [options]="voyages" optionLabel="name" placeholder="Select a Voyage" [(ngModel)]="selectedVoyage"
            (onChange)="getMBLNumbers()" [filter]="true" [disabled]="!selectedVessel" [style]="{ width: '100%'}">
          </p-dropdown>
        </div>
        <div class="ui-g-4">
          <p-dropdown [options]="mblNumbers" optionLabel="MBL" [(ngModel)]="selectedMBLNumbers"
            placeholder="Select MBL Numbers" [filter]="true" [disabled]="!selectedVoyage" [style]="{ width: '100%'}">
          </p-dropdown>
        </div>
        <div class="ui-g-6">
          <button *ngIf="!showLoadingSpinner" pRipple class="p-button-info width-100" pButton
            [disabled]="!selectedVessel || !selectedVoyage || !selectedMBLNumbers" label="Filter" icon="fas fa-filter"
            (click)="filterByVessel()"></button>
          <button *ngIf="showLoadingSpinner" pRipple class="submit p-button-info width-100" pButton type="button"
            [disabled]="true">
            <i class="pi pi-spin pi-spinner font-size-2em"></i>
          </button>
        </div>
        <div class="ui-g-6">
          <button pRipple pButton class="p-button-info width-100" [disabled]="hideFilterButton" label="Add Masterbill"
            icon="fas fa-filter" (click)="addMasterbillDialogVisible = true"></button>
        </div>
      </div>
    </p-panel>
  </div>

  <div class="ui-g-12" *ngIf="files?.length !== 0">
    <p-table [loading]="showLoadingSpinner" #dt [value]="files" [rows]="30" [paginator]="true"
      [rowsPerPageOptions]="[10,25,50,75]" [autoLayout]="true"
      [globalFilterFields]="['DBNNumber', 'ForeignReference', 'CargoType', 'Consignee', 'VesselName', 'VoyageNumber', 'MBL']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>DBNNumber</th>
          <th>Foreign Ref</th>
          <th>Cargo Type</th>
          <th>Consignee</th>
          <th>Vessel</th>
          <th>Voyage</th>
          <th>ETA</th>
          <th>MBL</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file>
        <tr>
          <td>{{file.DBNNumber}}</td>
          <td>{{file.ForeignReference}}</td>
          <td>{{file.CargoType}}</td>
          <td>{{file.Consignee}}</td>
          <td>{{file.VesselName}}</td>
          <td>{{file.VoyageNumber}}</td>
          <td>{{file.ETA}}</td>
          <td>{{file.MBL}}</td>
          <td>
            <button pRipple pButton class="p-button-info" icon="fas fa-folder" (click)="openFile(file.Id)"
              pTooltip="View file" tooltipPosition="bottom">
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>No file(s) found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Add Master Bill:" modal="true" [(visible)]="addMasterbillDialogVisible" [style]="{ width: '50vw'}">
  <div class="ui-g">
    <div class="ui-g-3">
      <label>MBL Number: </label>
    </div>
    <div class="ui-g-9">
      <input type="text" class="width-100" pInputText [(ngModel)]="MBLNumber" />
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info width-100" pButton type="button" [disabled]="!MBLNumber"
      (click)="addMasterbill()" label="Add"></button>
  </p-footer>
</p-dialog>