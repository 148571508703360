import { AuthenticationService } from './../../../services/api/auth/authentication.service';
import { Currency, FileTransactionIntents, FinanceSummary, Invoice, StatementItems } from '../../../interfaces/finance-interfaces';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InvoiceApiService } from '../../../services/api/finance/invoice-api.service';
import { MessageService } from 'primeng';
import { LogBase } from '../../../services/logger.service';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { FinanceHelperService } from '../../../services/finance-helper.service';
import { ConfigService } from '../../../services/config.service';
import { HttpClient } from '@angular/common/http';
import { Groups, TransactionIntentStatus, TransactionType } from '../../../enums';
import { CurrencyApiService } from '../../../services/api/finance/currency-api.service';
import { TransactionIntentApiService } from '../../../services/api/finance/transaction-intent.api.service';
import { Dropdown } from '../../../interfaces/global.interfaces';
import { DocumentApiService } from '../../../services/api/document-api.service';

@Component({
  selector: 'app-finance-summary',
  templateUrl: './finance-summary.component.html',
  styleUrls: ['./finance-summary.component.scss']
})
export class FinanceSummaryComponent implements OnInit, OnChanges {

  @Input() public fileId: number = 0;
  @Input() public refresh: boolean = false;
  @Output() public onLoaded: EventEmitter<void> = new EventEmitter();
  @Output() public onInvoiceCancel: EventEmitter<void> = new EventEmitter();
  @Output() public changeRefresh: EventEmitter<boolean> = new EventEmitter();

  public financeSummary: FinanceSummary;
  public fileTransactionIntents: FileTransactionIntents;
  public statementLineItems: StatementItems[];
  public selectedInvoiceIndex: number = 0;
  public selectedCurrency: Currency;
  public invoiceNumber: string;
  public invoiceId: number;
  public currencies: Currency[];
  public paymentAmount: number;
  public showCurrencySymbol: boolean = false;
  public paymentDate: string;
  public reasonForTransaction: string;
  public transactionIntentTypes: Dropdown[] = [];
  public selectedTransactionType: TransactionType | null = null;
  public financeUser: boolean = this.authService.canEdit(Groups.ACCOUNTS);

  public showSpinner: boolean = false;
  public showTransactionDialog: boolean = false;
  public addingAdminTransaction: boolean = false;
  public viewInvoiceLineItem: boolean = false;
  public cancelInvoiceDialog: boolean = false;
  public cancelInvoiceSpinner: boolean = false;
  public isAccounts: boolean = false;

  constructor(
    private invoiceApiService: InvoiceApiService,
    public documentApiService: DocumentApiService,
    private currencyApi: CurrencyApiService,
    private messageService: MessageService,
    public financeHelper: FinanceHelperService,
    private authService: AuthenticationService,
    private transactionIntentApiService: TransactionIntentApiService,
    private log: LogBase,
    private titleService: Title,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  async ngOnInit() {
    await this.refreshScreen();
  }

  async ngOnChanges() {
    if (this.refresh) {
      await this.refreshScreen();
      this.changeRefresh.emit(false);
    }
  }

  private async refreshScreen() {
    this.showSpinner = true;
    await this.getFinanceSummary();
    await this.getAdminTransactionTypes();
    await this.getCurrencies();
    this.onLoaded.emit();
    this.showSpinner = false;
    this.titleService.setTitle('AVECS Finance Summary');
    this.isAccounts = this.authService.canEdit(Groups.ACCOUNTS);
  }

  private async getFinanceSummary(): Promise<void> {
    try {
      const retval = await this.invoiceApiService.GetFinanceSummary(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to get the finance summary. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.financeSummary = retval.result.invoiceSummary;
        this.statementLineItems = retval.result.statementLineItems;
        this.fileTransactionIntents = retval.result.fileTransactionIntents;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  public async getCurrencies() {
    try {
      const retval = await this.currencyApi.getLocalCurrencies();
      if (!retval) this.toastMessage('error', 'Failed to get currencies. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.currencies = retval.result;
    } catch (err) {
      this.log.error(err);
    }
  }

  public displayCancelInvoiceDialog(invoice: Invoice) {
    this.cancelInvoiceDialog = true;
    this.invoiceNumber = invoice.invoiceNumber;
    this.invoiceId = invoice.invoiceId;
  }

  public async cancelInvoice() {
    this.cancelInvoiceSpinner = true;
    try {
      const retval = await this.invoiceApiService.CancelInvoice(this.fileId, this.invoiceId);
      if (!retval) this.toastMessage('error', 'Failed to cancel invoice. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        await this.getFinanceSummary();
        this.onInvoiceCancel.emit();
        this.cancelInvoiceDialog = false;
      }
    }
    catch (err) {
      this.toastMessage('error', 'Failed to cancel invoice. Something went wrong. Please contact support.', '');
    }
    this.cancelInvoiceSpinner = false;
  }

  public async getAdminTransactionTypes() {
    try {
      const retval = await this.transactionIntentApiService.GetAdminTransactionTypes();
      if (!retval) this.toastMessage('error', 'Failed to get transaction intent types. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.transactionIntentTypes = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get admin transaction types. Something went wrong. Please contact support.', '');
    }
  }

  public async addAdminTransaction() {
    this.addingAdminTransaction = true;
    try {
      this.paymentDate = moment(this.paymentDate).format('yy/MM/DD');
      const retval = await this.transactionIntentApiService.AddAdminTransaction(this.fileId, this.paymentAmount, this.selectedCurrency.id, this.paymentDate, this.reasonForTransaction, this.selectedTransactionType);
      if (!retval) this.toastMessage('error', 'Failed to add admin transaction. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        this.showTransactionDialog = false;
        this.selectedCurrency = null;
        this.paymentAmount = null;
        this.paymentDate = null;
        this.reasonForTransaction = null;
        this.selectedTransactionType = null;
        await this.refreshScreen();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to add admin transaction. Something went wrong. Please contact support.', '');
    }
    this.addingAdminTransaction = false;
  }

  public viewLineItems(invoiceId: number) {
    this.selectedInvoiceIndex = this.financeSummary.invoices.findIndex(x => x.invoiceId === invoiceId);
    this.invoiceNumber = this.financeSummary.invoices[this.selectedInvoiceIndex].invoiceNumber;
    this.viewInvoiceLineItem = true;
  }

  public setTransactionTypeColour(transactionTypeId: TransactionType) {
    switch (transactionTypeId) {
      case TransactionType.PAYMENT:
        return 'payment';

      case TransactionType.INVOICE_ITEM:
        return 'item';

      case TransactionType.DISCOUNT:
        return 'discount';

      case TransactionType.REFUND:
      case TransactionType.INVOICE_ITEM_CANCELLED:
      case TransactionType.INVOICE_CANCELLED_DISCOUNT:
        return 'cancelled';

      case TransactionType.INVOICE_ITEM_UPDATE:
        return 'update';

      case TransactionType.INVOICE_UPDATE_DISCOUNT:
        return 'discount-update';

      case TransactionType.ADMIN_POSITIVE:
        return 'admin-positive';

      case TransactionType.ADMIN_NEGATIVE:
        return 'admin-negative';

      default:
        return 'inactive';
    }
  }

  public setTransactionIntentStatusColour(transactionIntentStatusId: TransactionIntentStatus) {
    switch (transactionIntentStatusId) {
      case TransactionIntentStatus.NOT_APPROVED_OR_DECLINED:
        return 'inactive';

      case TransactionIntentStatus.APPROVED:
        return 'approved';

      case TransactionIntentStatus.REJECTED:
        return 'rejected';

      case TransactionIntentStatus.ARCHIVED:
        return 'archived';

      default:
        return 'inactive';
    }
  }

  public onPaymentAllocateValidate() {
    if (!Number(this.paymentAmount)) this.paymentAmount = 0;
    else if (this.paymentAmount > 1000000) this.paymentAmount = 1000000;
    else if (this.paymentAmount < 0) this.paymentAmount = -this.paymentAmount;
  }

  public editInvoice(invoice: Invoice) {
    if (invoice.invoiceType.toLowerCase().trim().includes('adhoc')) {
      window.open(`invoice/edit/1/${invoice.invoiceId}`);
    } else {
      window.open(`invoice/edit/0/${invoice.invoiceId}`);
    }
  }
  public formatAmountPaid(amountPaid: number): string { return Math.abs(amountPaid).toFixed(2); }
  public formatDate(date: Date) { return moment(date).format('YYYY/MM/DD h:mm a'); }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}