import { Component, OnInit } from '@angular/core';
import { LogBase } from "../../../services/logger.service";
import { ApiService } from "../../../services/api.service";
import { ExcelService } from '../../../services/excel.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
	selector: 'app-any-report',
	templateUrl: './any-report.component.html',
	styleUrls: ['./any-report.component.css'],
	providers: []
})
export class AnyReportComponent implements OnInit {

	showSpinner: boolean = false;
	readonly templateTypeId: number = 1;
	constructor(
		public api: ApiService,
		private messageService: MessageService,
		private excel: ExcelService,
		public log: LogBase,
	) { }

	async ngOnInit() { }

	async onSubmit(event) {
		this.showSpinner = true;
		try {
			if (event.result == undefined) {
				this.log.error("an error occured", '', "any-report.onSubmit");
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Encountered a problem' });
				this.showSpinner = false;
				return;
			}
			else if (event.result.length == 0) {
				this.log.error("No Results for report. Cannot create excel file with 0 rows", "", "any-report.onSubmit");
				this.messageService.add({ severity: 'warn', summary: 'No Results', detail: 'Report did not generate' });
			}
			else {
				let fileName = this.fileNameSanceParams(event.template.name, event.template.extension);
				switch (this.templateTypeId) {
					case 1:
						this.excel.generateGenericExcel(event.result, fileName);
						break;

					case 2:
						this.excel.generateCustomExcel(event.result, event.template.url, fileName);
						break;

					default:
						this.log.error("Unhandled Report Type", "No function for report template id:" + event.template.id, "any-report.component.ts");
						break;
				}
			}
		}
		catch (err) {
			this.log.error("failed to get excel report", err, "any-report.getExcelReport()");
		}
		this.showSpinner = false;
	}

	private fileNameSanceParams(name, extension): string {
		const dt = moment(new Date()).format("YYYY-MM-DD");
		return `${name}_${dt}.${extension}`;
	}
}