import { ChangeDetectorRef, Component } from '@angular/core';
import { FilterDataService } from '../filter-search/filter-search-data.service';

@Component({
  selector: 'app-manage-file-preferences',
  templateUrl: './manage-file-preferences.component.html',
  styleUrls: ['./manage-file-preferences.component.scss']
})
export class ManageFilePreferencesComponent {

  public ruleSet: number = 0;
  public display: boolean = false;

  public get selectedFileIds(): number[] | null {
    const fileIds: number[] | null = this.filterDataService?.selectedFiles?.map(f => f.fileId);
    if (!fileIds) this.display = false;
    return fileIds;
  }

  constructor(
    public filterDataService: FilterDataService,
    private cd: ChangeDetectorRef
  ) { }

  public toggleDisplay() {
    if (this.display) {
      this.display = false;
      this.cd.detectChanges();
      this.display = true;
    } else this.display = true;
  }
}