import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DispatchCollecionLoads, DispatchPlanType, Dropdown, LoadType, LocationType, TableColumns } from '../../../interfaces/global.interfaces';
import { Api2Service } from '../../../services/api2.service';
import * as moment from 'moment';
import { MessageService } from 'primeng';

@Component({
  selector: 'collection-planning',
  templateUrl: './collection-planning.component.html',
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  styleUrls: ['./collection-planning.component.scss']
})
export class CollectionPlanningComponent implements OnInit {

  public loads: DispatchCollecionLoads[];
  public loadTableColumns: TableColumns[] = [
    { field: 'name', header: 'Load Name' },
    { field: 'dateCreated', header: 'Created Date' },
    { field: 'estimatedDeparture', header: 'ETD' },
    { field: 'pickupLocation', header: 'Pickup Location' },
    { field: 'dropoffLocation', header: 'Dropoff Location' },
    { field: 'numberOfCargoOnLoad', header: '# Of Cargo On Load' },
    { field: 'loadCargoValue', header: 'Cargo Value' },
    { field: 'cargoLoadAssignmentStatus', header: 'Status' },
    { field: 'driverName', header: 'Driver Name' },
    { field: 'assignedDate', header: 'Date Assigned	' },
    { field: 'assignedDate', header: 'Date Assigned	' }
  ];
  public planAssignmentType: DispatchPlanType[];
  public locationTypes: LocationType[];
  public locationsPickUp: LocationType[];
  public locationsDropOff: LocationType[];
  public transporters: Dropdown[] = [];

  public createLoadCollection: boolean = false;

  public get loadTypes(): typeof LoadType { return LoadType; }

  public statusData: { cargoAssignmentStatusId: number, cargoAssignmentStatus: string; }[] = [];

  public collectionLoad = {
    loadName: "",
    driverName: "",
    dispatchPlanType: null,
    ETD: null,
    pickupLocationType: null,
    pickupLocation: null,
    dropoffLocationType: null,
    dropoffLocation: null,
    transporter: null
  };

  constructor(
    private api2: Api2Service,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    await this.GetTransporters();
    await this.getCurrentDispatchLoads();
    await this.GetDispatchPlanStatusForAssignment();
  }

  private async GetDispatchPlanStatusForAssignment() {
    try {
      const retval = await this.api2.GetDispatchPlanStatusForAssignment();
      if (!retval) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get dispatch plan status for assignment. Please contact support'
        });
        return;
      }
      this.planAssignmentType = retval;
    }
    catch (ex) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to get dispatch plan status for assignment. Please contact support'
      });
    }
  }

  public async createLoad() {
    this.locationTypes = await this.api2.getDispatchPlannerLocationTypes();
    this.createLoadCollection = true;
  }

  public async onCollectionPickUpLocationType() {
    if (this.collectionLoad.pickupLocationType) {
      this.locationsPickUp = await this.api2.GetLocationFromLocationId(this.collectionLoad.pickupLocationType.id);
    }
  }

  public async onCollectionDropoffLocationType() {
    if (this.collectionLoad.dropoffLocationType) {
      this.locationsDropOff = await this.api2.GetLocationFromLocationId(this.collectionLoad.dropoffLocationType.id);
    }
  }

  public async createNewCollectionLoad() {
    try {
      const payload = {
        "LoadName": this.collectionLoad.loadName,
        "DriverName": this.collectionLoad.driverName,
        "DispatchPlanType": this.collectionLoad.dispatchPlanType.id,
        "ETD": moment(this.collectionLoad.ETD).format('YYYY/MM/DD, HH:mm:ss'),
        "PickupLocationId": this.collectionLoad.pickupLocation.id,
        "DropoffLocationId": this.collectionLoad.dropoffLocation.id,
        "TransporterId": this.collectionLoad.transporter.value
      };

      const retval = await this.api2.CreateNewCollectionLoad(payload);
      if (!retval) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to create collection  load. Please contact support'
        });
        return;
      }

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Load Created' });

      this.collectionLoad = {
        loadName: "",
        driverName: "",
        dispatchPlanType: null,
        ETD: null,
        pickupLocationType: null,
        pickupLocation: null,
        dropoffLocationType: null,
        dropoffLocation: null,
        transporter: null
      };
      await this.getCurrentDispatchLoads();
      this.createLoadCollection = false;
    } catch (ex) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create collection  load. Please contact support'
      });
    }
  }

  public async getCurrentDispatchLoads() {
    this.loads = [];
    try {
      const loadsCollection = await this.api2.GetCollectionLoads();
      if (loadsCollection) {
        this.loads = loadsCollection;
        this.statusData = this.loads.map(load => ({ cargoAssignmentStatusId: load.cargoLoadAssignmentStatusId, cargoAssignmentStatus: load.cargoLoadAssignmentStatus }));
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get collection load plans. Please contact support'
        });
      }
    } catch (ex) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to get collection loads. Please contact support'
      });
    }
  }

  private async GetTransporters() {
    try {
      const retval = await this.api2.getActiveTransporters();
      if (retval) {
        this.transporters = retval;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get transporters'
        });
      }
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to get transporters. Please contact support'
      });
    }
  }
}