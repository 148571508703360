import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ApiService } from "./../../services/api.service";
import { ConfigService } from "./../../services/config.service";
import { LogBase } from "../../services/logger.service";
import { MenuItem } from "primeng/api";
import { ConfirmationService } from "primeng";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from "../../services/api/auth/authentication.service";

@Component({
  selector: "app-communicator",
  templateUrl: "./communicator.component.html",
  styleUrls: ["./communicator.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService],
})
export class CommunicatorComponent implements OnInit {
  public type: string = "component";
  public disabled: boolean = false;

  msgs: any[];
  display: boolean = false;
  showSpinner: boolean = false;

  displayQRCode: boolean = false;
  vCardData: string;
  vCardInfo: any = {};


  historical: boolean = false;
  sendingEmail: boolean = false;

  vessels: any[];
  voyages: any[];
  selectedVessel: any;
  allVessels: any[];

  selectedVoyage: any;
  selectedActiveVoyage: any;

  emailTemplates: any[];
  selecteEmailTemplate: any;
  selecteEmailTemplateContent: any;

  tabindex: number = 0;

  fromDate: Date;
  toDate: Date;

  files: any[];
  cols: any[];
  selectedFiles: any = [];

  paymentReceived: boolean = false;
  paymentOutstanding: boolean = false;
  cIFClients: boolean = false;
  passportReceived: boolean = false;
  passportNotReceived: boolean = false;
  hideFunctionButtons: boolean = true;

  displaySMS: boolean = false;
  SMSText: string = "";

  displayMessage: boolean = false;
  MessageText: string = "";

  EmailAdresses: any[];
  emailFrom: any = "";
  displayEmail: boolean = false;
  EmailText: string = "";
  EmailSubject: string;
  emailReadOnly: boolean = false;

  countries: any[];
  selectedCountry: any;

  carriers: any[];
  selectedCarrier: any;

  suppliers: any[];
  selectedSupplier: any;

  fileType: number = 0;

  filterMenuitems: MenuItem[];


  disableFilterButton: boolean = true;

  constructor(
    private log: LogBase,
    private api: ApiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router,
    private titleService: Title,
    private authenticationService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Communicator");
  }

  async ngOnInit() {
    try {
      this.setUpEmailAddressOptions();
      this.setUpFilterOptions();
      this.setUpInitialCols();

      await this.getCommunicatorVessels();
      await this.getCurrentVoyages();
      await this.getCommunicatorCountries();
      await this.getCommunicatorCarriers();
      await this.getCommunicatorSuppliers();
      await this.getEmailTemplateList()
    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
    }
  }


  setTabIndex(e) {
    this.tabindex = e.index;
    this.selectedActiveVoyage = undefined;
    this.selectedVessel = undefined;
    this.selectedVoyage = undefined;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.disableFilterButton = true;
  }



  async setupVCard(contactid: any) {

    this.displayQRCode = true;
    this.vCardInfo = await this.api.getVCardInfo(contactid);
    let name = this.vCardInfo.Name || '',
      middlename = this.vCardInfo.MiddleNames || '',
      surname = this.vCardInfo.LastName || '',
      title = this.vCardInfo.Title || '',
      org = '',
      url = '',
      email = this.vCardInfo.Email || '',
      tel1 = this.vCardInfo.Cellphone || '',
      tel2 = this.vCardInfo.Telephone || '',
      addr1 = this.vCardInfo.Address1 || '',
      addr2 = this.vCardInfo.Address2 || '',
      postalcode = this.vCardInfo.PostalCode || '',
      city = this.vCardInfo.CityName || '',
      country = this.vCardInfo.Country || '';

    this.vCardData = `BEGIN:VCARD
VERSION:3.0
N:${surname};${name}
FN:${surname} ${name}
TITLE:${title}
TEL;TYPE=VOICE,WORK,PREF:${tel1}
TEL;TYPE=VOICE,HOME,PREF:${tel2}
ADR;TYPE=WORK,PREF:;;${addr1};${addr2};${city};${postalcode};${country}
LABEL;TYPE=WORK,PREF:${addr1}\n${addr2}\, ${city} ${postalcode}\n${country}
ORG:${org}
URL:${url}
EMAIL:${email}
END:VCARD
`
  }


  getListItemsByVessel = async () => {
    try {
      this.files = await this.api.getCommunicatorListItems();
      if (this.files.length > 0) {
        this.hideFunctionButtons = false;
      }
    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
    }
  };

  getListItems = async () => {
    try {
      this.files = await this.api.getCommunicatorListItems();
      if (this.files.length > 0) {
        this.hideFunctionButtons = false;
      }
    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
    }
  };

  checkIfValid() {
    if (this.selectedFiles) {
      if (this.selectedFiles.length > 0) return false;
      else return true;
    }
  };

  checkIfExportAllValid() {
    if (this.files && this.files.length > 0) return false;
    else return true;
  };

  disableSendMessageButton() {
    if (this.MessageText.length > 0) {
      return false;
    } else return true;
  };

  disableSendSMSButton() {
    if (this.SMSText.length > 0) {
      return false;
    } else return true;
  };

  disableSendEmailButton() {

    if (this.sendingEmail) return true;
    if (this.emailFrom.name && this.EmailText && this.EmailSubject.length > 0) {
      return false;
    } else return true;

  };

  onRowSelect = (selectedFiles: any) => {
    if (selectedFiles != null) {
      this.hideFunctionButtons = false;
    }
  };

  onRowUnselect = (event: any) => {
    if (event != null) {
      this.hideFunctionButtons = true;
    }
  };

  onHeaderCheckboxToggle = (event: any) => {
    this.hideFunctionButtons = false;
  };



  async filter() {

    switch (this.tabindex) {
      case 0:
        await this.filterByVoyage();
        break;
      case 1:
        await this.filterByVessel();
        break;
      case 2:
        await this.filterByDates();
        break;

    }

  }

  async filterByVoyage() {
    try {
      this.showSpinner = true;
      var selectedCountry = -1;
      var selectedCarrier = -1;
      var selectedSupplier = -1;

      if (this.selectedCountry) selectedCountry = this.selectedCountry.code;

      if (this.selectedCarrier) selectedCarrier = this.selectedCarrier.code;

      if (this.selectedSupplier) selectedSupplier = this.selectedSupplier.code;

      const payload = {
        voyageId: this.selectedActiveVoyage.value,
        paymentReceived: this.paymentReceived,
        paymentOutstanding: this.paymentOutstanding,
        cIFClients: this.cIFClients,
        passportReceived: this.passportReceived,
        passportNotReceived: this.passportNotReceived,
        selectedCountry: selectedCountry,
        selectedCarrier: selectedCarrier,
        selectedSupplier: selectedSupplier,
        fileType: this.fileType,
      };

      this.files = await this.api.getCommunicatorListItemsByVessel(payload);
      await this.sortDimensions();

    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);

    }
    this.showSpinner = false;
  }

  filterByVessel = async () => {
    try {
      this.showSpinner = true;
      var selectedCountry = -1;
      var selectedCarrier = -1;
      var selectedSupplier = -1;

      if (this.selectedCountry) selectedCountry = this.selectedCountry.code;

      if (this.selectedCarrier) selectedCarrier = this.selectedCarrier.code;

      if (this.selectedSupplier) selectedSupplier = this.selectedSupplier.code;

      const payload = {
        voyageId: this.selectedVoyage.id,
        paymentReceived: this.paymentReceived,
        paymentOutstanding: this.paymentOutstanding,
        cIFClients: this.cIFClients,
        passportReceived: this.passportReceived,
        passportNotReceived: this.passportNotReceived,
        selectedCountry: selectedCountry,
        selectedCarrier: selectedCarrier,
        selectedSupplier: selectedSupplier,
        fileType: this.fileType,
      };

      this.files = await this.api.getCommunicatorListItemsByVessel(payload);

      await this.sortDimensions();

    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
    }
    this.showSpinner = false;

  };

  filterByDates = async () => {
    try {
      this.showSpinner = true;

      let fromDay = this.fromDate; //..getDate();
      let toDay = this.toDate; //.getDate();

      var selectedCountry = -1;
      var selectedCarrier = -1;
      var selectedSupplier = -1;

      if (this.selectedCountry) selectedCountry = this.selectedCountry.code;

      if (this.selectedCarrier) selectedCarrier = this.selectedCarrier.code;

      if (this.selectedSupplier) selectedSupplier = this.selectedSupplier.code;

      const payload = {
        from: fromDay,
        to: toDay,
        paymentReceived: this.paymentReceived,
        paymentOutstanding: this.paymentOutstanding,
        cIFClients: this.cIFClients,
        passportReceived: this.passportReceived,
        passportNotReceived: this.passportNotReceived,
        selectedCountry: selectedCountry,
        selectedCarrier: selectedCarrier,
        selectedSupplier: selectedSupplier,
        fileType: this.fileType,
      };

      this.files = await this.api.getCommunicatorListItemsByDateRange(payload);
      await this.sortDimensions();

    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
    }
    this.showSpinner = false;

  };

  async sortDimensions() {
    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        let x = this.files[i].height;
        this.files[i].height = x / 100;

        let y = this.files[i].vehicleLength;
        this.files[i].vehicleLength = y / 100;

        let z = this.files[i].width;
        this.files[i].width = z / 100;
      }
    }
  }

  async sendEmail() {
    try {
      this.showSpinner = true;
      this.sendingEmail = true;

      if (this.selectedFiles) {
        this.EmailText = this.EmailText.split('&nbsp;').join('  ');
        if (!this.selecteEmailTemplate) {
          const distinctFiles = this.selectedFiles.filter(
            (file, i, arr) => arr.findIndex(t => t.fileId === file.fileId) === i
          );

          let selectedFileIds = this.getFileIdsFromSelectedFiles(distinctFiles);
          var subject = this.EmailSubject;
          this.EmailText = this.EmailText.split('&').join('and');

          const payload = {
            FileIds: selectedFileIds,
            Message: this.EmailText,
            Subject: this.EmailSubject,
            From: this.emailFrom.code,
          };

          let retval = await this.api.sendEmail(payload);
          if (retval.result) {
            this.messageService.add(
              {
                severity: "success",
                summary: "Emails Queued",
                detail: retval.errorMessage,
              });
          } else {
            this.messageService.add(
              {
                severity: "error",
                summary: "Could not send email(s)",
                detail: retval.errorMessage,
              });
          }
        } else {
          // Template Email
          const distinctFiles = this.selectedFiles.filter(
            (file, i, arr) => arr.findIndex(t => t.fileId === file.fileId) === i
          );

          let selectedFileIds = this.getFileIdsFromSelectedFiles(distinctFiles);
          const templateRequirements = {
            TemplateId: this.selecteEmailTemplate.Id,
            FileIds: selectedFileIds,
          };

          var retval = await this.api.checkForCommunicatorEmailRequirements(templateRequirements);

          if (retval.ErrorCount == 0) {

            const payload = {
              FileIds: selectedFileIds,
              TemplateId: this.selecteEmailTemplate.Id,
              Subject: this.EmailSubject,
              From: this.emailFrom.code,
            };

            let r = await this.api.sendTemplatedEmail(payload);
            if (r) {
              this.messageService.add(
                {
                  severity: "success",
                  summary: "Emails Sent",
                  detail: retval.Message,
                });
            }

          } else {
            this.messageService.add(
              {
                severity: "error",
                summary: "Could not send email(s)",
                detail: retval.Message,
              });
          }

        }

        await this.clearEmailSettings();
        this.displayEmail = false;

      }
    } catch (error) {
      this.showError("An error sending Emails", error);
      this.log.error(error);
    }

    this.showSpinner = false;
    this.sendingEmail = false;

  };

  /**
   * Utility Functions
   */

  getFileIdsFromSelectedFiles(files: any) {
    let selectedFileIds = [];
    for (var x = 0; x < files.length; x++) {
      let item = files[x];
      selectedFileIds.push({ FileId: item.fileId });
    }
    return selectedFileIds;
  };

  /**
   * onChange methods
   */

  async getVoyages() {
    try {
      this.selectedVoyage = undefined;
      this.voyages = await this.api.getCommunicatorVoyages(
        true,
        this.selectedVessel.id
      );
    } catch (error) {
      this.log.error(error);
    }
  };


  async getCurrentVoyages() {
    try {
      var retval = await this.api.getCurrentVoyages();
      if (retval.length > 0) {
        this.vessels = retval;
        this.selectedVoyage = this.vessels[0].value;
      }
    } catch (error) {
      this.showError('Error Getting Vessels (getVessels())', error);
      this.log.error(error);
    }
  }

  /**
   * On load methods, setup data. Dropdowns etc.
   */

  getCommunicatorVessels = async () => {
    try {
      var res = await this.api.getCommunicatorVessels();
      if (res) {
        this.allVessels = res;
      }
    } catch (error) {
      this.showError("An error occurred", error);
      this.log.error(error);
    }
  };

  getCommunicatorCountries = async () => {
    try {
      this.countries = await this.api.getCommunicatorCountries();
    } catch (error) {
      this.log.error(error);
    }
    // this.log.info(this.countries);
  };


  getCommunicatorCarriers = async () => {
    try {
      this.carriers = await this.api.getCommunicatorCarriers();
    } catch (error) {
      this.log.error(error);
    }
    // this.log.info(this.carriers);
  };

  getCommunicatorSuppliers = async () => {
    try {
      this.suppliers = await this.api.getCommunicatorSuppliers();
    } catch (error) {
      this.log.error(error);
    }
    // this.log.info(this.suppliers);
  };


  async getEmailTemplateList() {
    try {
      this.emailTemplates = await this.api.getTemplateList(1);
    } catch (error) {
      this.log.error(error);
    }
    this.log.info(this.emailTemplates);
  };


  async getTemplateContent() {
    try {
      this.selecteEmailTemplateContent = await this.api.getTemplateContent(this.selecteEmailTemplate.Id);
      this.EmailText = this.selecteEmailTemplateContent;
      this.EmailSubject = this.selecteEmailTemplate.Subject;
      this.emailReadOnly = true;

      this.disableSendEmailButton();

    } catch (error) {
      this.log.error(error);
    }
    this.log.info(this.selecteEmailTemplateContent);


  }

  async clearTemplate() {

    this.selecteEmailTemplate = undefined;
    this.EmailSubject = '';
    this.emailReadOnly = false;
    this.EmailText = '';
  }

  /**
   * Columns, filters and dialog code
   * */

  showSMSDialog() {
    this.SMSText = "";
    this.displaySMS = true;
  };

  hideSMSDialog() {
    this.SMSText = "";
    this.displaySMS = false;
  };

  showMessageDialog() {
    this.MessageText = "";
    this.displayMessage = true;
  };

  hideMessageDialog() {
    this.MessageText = "";
    this.displayMessage = false;
  };

  async showEmailDialog() {

    await this.clearEmailSettings();

  };

  async clearEmailSettings() {


    this.selecteEmailTemplate = undefined;
    this.EmailSubject = '';
    this.emailReadOnly = false;
    this.EmailText = '';
    this.displayEmail = true;
    this.emailReadOnly = false;

  }

  hideEmailDialog() {
    this.EmailText = "";
    this.displayEmail = false;
  };

  setUpEmailAddressOptions() {
    this.EmailAdresses = [
      { name: "noreply@avecs.co.za", code: "noreply@avecs.co.za" },
      { name: "info@avecs.co.za", code: "info@avecs.co.za" },
      { name: "accounts@avecs.co.za", code: "accounts@avecs.co.za" },
      { name: "dispatch@avecs.co.za", code: "dispatch@avecs.co.za" },
    ];
  };

  setUpFilterOptions() {
    this.filterMenuitems = [
      {
        label: "Export Templates",
        items: [
          {
            label: "Reset ...",
            icon: "fas fa-redo-alt",
            command: () => {
              this.setUpInitialCols();
            },
          },
          {
            label: "Dispatch",
            icon: "fas fa-truck-moving",
            command: () => {
              this.DispatchColumns();
            },
          },
          {
            label: "Accounts",
            icon: "fas fa-file-invoice-dollar",
            command: () => {
              this.AccountColumns();
            },
          },
          {
            label: "Clearing",
            icon: "fas fa-file-invoice-dollar",
            command: () => {
              this.ClearingColumns();
            },
          },
          {
            label: "HBL Upload",
            icon: "fas fa-file-invoice-dollar",
            command: () => {
              this.HBLColumns();
            },
          },
        ],
      },
    ];
  };

  DispatchColumns() {
    this.cols = [
      { field: "dBNNumber", header: "DBNNumber", width: "150px" },
      { field: "foreignReference", header: "ForeignRef #", width: "150px" },
      { field: "consignee", header: "Consignee", width: "300px" },
      { field: "country", header: "Country", width: "150px" },
      { field: "email", header: "Email", width: "250px" },
      { field: "description", header: "Description", width: "800px" },
      { field: "borderPost", header: "Border Post", width: "200px" },
      { field: "borderAgent", header: "Border Agent", width: "200px" },
      { field: "vesselName", header: "Vessel Name", width: "250px" },
      { field: "voyageNumber", header: "Voyage #", width: "120px" },
      { field: "bOLNumber", header: "BOL #", width: "100px" },
      { field: "eTA", header: "ETA", width: "100px" },
      { field: "sOB", header: "SOB", width: "100px" },
      { field: "mBL", header: "MBL", width: "150px" },
      { field: "carrier", header: "Carrier", width: "150px" },
      { field: "supplier", header: "Supplier", width: "250px" },
      {
        field: "totalPaymentsReceived",
        header: "Total Payments Received",
        width: "200px",
      },
      { field: "acquittalBOENum", header: "Acquittal BOE #", width: "200px" },
      { field: "bOEDate", header: "BOE Date", width: "200px" },
      { field: "cIF", header: "CIF", width: "75px" },
    ];
  };

  AccountColumns() {
    this.cols = [
      { field: "dBNNumber", header: "DBNNumber", width: "150px" },
      { field: "foreignReference", header: "ForeignRef #", width: "150px" },
      { field: "consignee", header: "Consignee", width: "300px" },
      { field: "country", header: "Country", width: "150px" },
      { field: "email", header: "Email", width: "250px" },
      { field: "cIF", header: "CIF", width: "75px" },
      { field: "description", header: "Description", width: "800px" },
      { field: "borderPost", header: "Border Post", width: "200px" },
      { field: "borderAgent", header: "Border Agent", width: "200px" },
      { field: "vesselName", header: "Vessel Name", width: "250px" },
      { field: "voyageNumber", header: "Voyage #", width: "120px" },
      { field: "supplier", header: "Supplier", width: "250px" },
      { field: "currency", header: "Currency", width: "100px" },
      { field: "invoiceAmount", header: "Invoice Amount", width: "150px" },
      { field: "invoiceDate", header: "Invoice Date", width: "175px" },
      { field: "lastPaymentDate", header: "Last Payment Date", width: "175px" },
      {
        field: "totalPaymentsReceived",
        header: "Total Payments Received",
        width: "200px",
      },
      {
        field: "balanceOutstanding",
        header: "Balance Outstanding",
        width: "200px",
      },
    ];
  };

  ClearingColumns() {
    this.cols = [
      { field: "dBNNumber", header: "DBNNumber", width: "150px" },
      { field: "foreignReference", header: "ForeignRef #", width: "150px" },
      { field: "consignee", header: "Consignee", width: "300px" },
      { field: "country", header: "Country", width: "150px" },
      { field: "description", header: "Description", width: "800px" },
      { field: "borderPost", header: "Border Post", width: "200px" },
      { field: "borderAgent", header: "Border Agent", width: "200px" },
      { field: "vesselName", header: "Vessel Name", width: "250px" },
      { field: "voyageNumber", header: "Voyage #", width: "120px" },
      { field: "eTA", header: "ETA", width: "100px" },
      { field: "sOB", header: "SOB", width: "100px" },
      { field: "mBL", header: "MBL", width: "150px" },
      { field: "acquittalBOENum", header: "Acquittal BOE #", width: "200px" },
    ];
  };

  HBLColumns() {
    this.cols = [
      { field: "dBNNumber", header: "DBNNumber", width: "150px" },
      { field: "foreignReference", header: "ForeignRef #", width: "150px" },
      { field: "consignee", header: "Consignee", width: "300px" },
      { field: "country", header: "Country", width: "150px" },
      { field: "email", header: "Email", width: "250px" },
      { field: "cIF", header: "CIF", width: "75px" },
      { field: "description", header: "Description", width: "800px" },
      { field: "borderPost", header: "Border Post", width: "200px" },
      { field: "borderAgent", header: "Border Agent", width: "200px" },
      { field: "vesselName", header: "Vessel Name", width: "250px" },
      { field: "voyageNumber", header: "Voyage #", width: "120px" },
      { field: "bOLNumber", header: "BOL #", width: "100px" },
      { field: "eTA", header: "ETA", width: "100px" },
      { field: "sOB", header: "SOB", width: "100px" },
      { field: "mBL", header: "MBL", width: "150px" },
      { field: "portOfLoad", header: "Port Of Load", width: "175px" },
      { field: "supplier", header: "Supplier", width: "250px" },
    ];
  };

  setUpInitialCols() {
    this.cols = [
      { field: "dBNNumber", header: "DBNNumber", width: "150px" },
      { field: "foreignReference", header: "ForeignRef #", width: "150px" },
      { field: "consignee", header: "Consignee", width: "300px" },
      { field: "email", header: "Email", width: "250px" },
      { field: "cellphone", header: "Cellphone", width: "150px" },
      { field: "documentStatus", header: "Document Status", width: "200px" },
      { field: "iDorPassportReceived", header: "ID/Passport Received", width: "200px" },
      { field: "cIF", header: "CIF", width: "75px" },
      { field: "description", header: "Description", width: "800px" },
      { field: "borderPost", header: "Border Post", width: "200px" },
      { field: "borderAgent", header: "Border Agent", width: "200px" },
      { field: "vesselName", header: "Vessel Name", width: "250px" },
      { field: "voyageNumber", header: "Voyage #", width: "120px" },
      { field: "bOLNumber", header: "BOL #", width: "100px" },
      { field: "eTA", header: "ETA", width: "100px" },
      { field: "sOB", header: "SOB", width: "100px" },
      { field: "mBL", header: "MBL", width: "150px" },
      { field: "portOfLoad", header: "Port Of Load", width: "175px" },
      { field: "carrier", header: "Carrier", width: "150px" },
      { field: "manifestNumber", header: "Manifest Number", width: "190px" },
      { field: "manifestDate", header: "Manifest Date", width: "175px" },
      { field: "collectionFromPortDate", header: "Port Collection Date", width: "190px" },
      { field: "dispatchDate", header: "Dispatch Date", width: "175px" },
      { field: "courier", header: "Courier", width: "250px" },
      { field: "dateCourierReceived", header: "Date Courier Received", width: "190px" },
      { field: "trackingNumber", header: "Tracking #", width: "150px" },
      { field: "supplier", header: "Supplier", width: "250px" },
      { field: "country", header: "Country", width: "150px" },
      { field: "created", header: "Created", width: "100px" },
      { field: "warehouse", header: "Warehouse", width: "150px" },
      { field: "currency", header: "Currency", width: "120px" },
      { field: "invoiceAmount", header: "Invoice Amount", width: "150px" },
      { field: "invoiceDate", header: "Invoice Date", width: "175px" },
      { field: "lastPaymentDate", header: "Last Payment Date", width: "175px" },
      { field: "totalPaymentsReceived", header: "Total Payments Received", width: "220px" },
      { field: "balanceOutstanding", header: "Outstanding Balance", width: "200px" },
      { field: "acquittalBOENum", header: "Acquittal BOE #", width: "200px" },
      { field: "bOEDate", header: "BOE Date", width: "200px" },
    ];
  };

  /**
   * Error Handler
   */
  showError = async (friendlyMessage: any, errorMessage: any) => {
    this.confirmationService.confirm({
      message: friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authenticationService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: Communicator<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";

        var subject: string =
          "NovaDT Error Message - Component: Communicator";
        var res = this.api.sendFreeTextEmail(
          this.configService.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          -1,
          -1
        );
      },
      reject: () => { },
    });
  };

  disableSendSMS() {
    if (this.SMSText) return false;
    else return true;
  };

  doubleClickFile(data: any) {
    this.router.navigate([]).then(result => {
      window.open(`file-view?query=${data.fileId}`, '_blank')
    });
  }
}