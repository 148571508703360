<p-progressSpinner *ngIf="showSpinner" [style]="{ width: '50px', height: '50px' }" strokeWidth="8" fill="#EEEEEE"
  animationDuration="2s"></p-progressSpinner>

<p-tabView *ngIf="!showSpinner">
  <p-tabPanel header="Sent Emails" leftIcon="far fa-envelope-open">
    <div class="ui-g-12 ">
      <p-table #dt [value]="emails" [rows]="50" [paginator]="true" selectionmode="single" [scrollable]="true"
        [rowsPerPageOptions]="[10,25,50]" [paginator]="true" scrollHeight="400px" [responsive]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['from', 'fromUser', 'toEmail', 'subject', 'status']" [columns]="cols">
        <ng-template pTemplate="caption">
          <div class="p-d-flex table-header">
            <span class="p-input-icon-left">
              <button pRipple class="p-button-info ui-button-success" pButton type="button" pTooltip="Refresh Emails"
                [icon]="loadIcon" (click)="refreshList()"></button>
            </span>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search email(s)" style="color: #fff;" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}" width="{{ col.width }}">
              {{ col.header }}
              <p-sortIcon field="{{ col.field }}"> </p-sortIcon>
            </th>
            <th width="100px">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-email let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" width="{{col.width }}">
              {{email[col.field]}}
            </td>
            <td width="100px">
              <button pRipple pButton class="p-button-info" icon="far fa-eye" pTooltip="View Email"
                tooltipPosition="left" (click)="viewEmail(email)">
              </button>
              <button *ngIf="legalEntityId" pRipple pButton class="p-button-info margin-left-5px" tooltipPosition="left"
                pTooltip="Cant resend email from this screen" icon="fas fa-redo" [disabled]="true">
              </button>
              <button *ngIf="!legalEntityId" pRipple pButton class="p-button-info margin-left-5px"
                tooltipPosition="left" pTooltip="Resend Email" icon="fas fa-redo" (click)="showResendDialog(email)">
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td>No email(s) found.</td>
          </tr>
        </ng-template>
      </p-table>
      <p-dialog header="{{ EmailHeader }}" [contentStyle]="{ height: '450px' }"
        [style]="{ 'margin-left': '80px', 'margin-right': '80px' }" [(visible)]="displayDialog" [responsive]="true"
        showEffect="fade" [modal]="true" [dismissableMask]="true" [draggable]="false">
        <div [innerHtml]="emailHtml"></div>
      </p-dialog>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Send Email" leftIcon="far fa-envelope" [disabled]="legalEntityId">
    <div class="ui-g-12">
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown [options]="EmailFromChoices" [autoDisplayFirst]="false" [(ngModel)]="selectedChoice"
            [style]="{'width':'100%'}" [filter]="true" optionLabel="Label" class="width-100" appendTo="body">
          </p-dropdown>
          <label class="float-label" for="float-input">From *</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <input type="text" pInputText [(ngModel)]="subject" class="width-100" />
          <label class="float-label" for="float-input">Subject *</label>
        </span>
      </div>
      <div class="ui-g-12">
        <p-editor [(ngModel)]="emailBody" [style]="{ height: '320px' }">
          <p-header>
            <span class="ql-formats">
              <button class="ql-bold" aria-label="Bold"></button>
              <button class="ql-italic" aria-label="Italic"></button>
              <button class="ql-underline" aria-label="Underline"></button>
            </span>
          </p-header>
        </p-editor>
      </div>
      <div class="ui-g-12">
        <button *ngIf="!sendEmailSpinner" pRipple class="p-button-info width-100" pButton
          [disabled]="!selectedChoice || !subject || !emailBody" (click)="sendFreeTextEmail()" type="button"
          label="Send" icon="ui-icon-send"></button>
        <button *ngIf="sendEmailSpinner" pRipple class="submit p-button-info width-100" pButton type="button"
          [disabled]="true">
          <i class="pi pi-spin pi-spinner font-size-2em"></i>
        </button>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Sent SMS's" leftIcon="far fa-comment-alt">
    <div class="ui-g-12">
      <p-table #sm [value]="smsData" [autoLayout]="true" [rows]="50" [paginator]="true" selectionmode="single"
        scrollHeight="400px" [scrollable]="true" [globalFilterFields]="['to', 'message', 'status']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex table-header">
            <span class="p-input-icon-left">
              <button pRipple class="p-button-info ui-button-success" pButton type="button" pTooltip="Refresh SMS'"
                [icon]="loadIcon" (click)="refreshList()"></button>
            </span>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="sm.filterGlobal($event.target.value, 'contains')"
                placeholder="Search sms" style="color: #fff;" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>To</th>
            <th>Message</th>
            <th>Status</th>
            <th>DateSent</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sms>
          <tr>
            <td>{{sms.to}}</td>
            <td>{{sms.message}}</td>
            <td>{{sms.status}}</td>
            <td>{{sms.dateSent}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td>No SMS(es) found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Whatsapp" leftIcon="fas fa-comments" tooltip="Whatsapp">
    <app-whatsapp-chats (onLoaded)="onLoaded.emit()" [fileNumber]="fileId">
    </app-whatsapp-chats>
  </p-tabPanel>
</p-tabView>

<p-dialog header="Resend Email" modal="true" [(visible)]="showResendEmailDialog" [style]="{width: '550px'}">
  <div class="ui-g-12">
    <label>Are you sure you want to resend {{ emailSubject }} ?</label>
  </div>
  <div class="ui-g-12 actions">
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info" pButton type="button" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info" pButton type="button" (click)="resendEmail()"
      label="Yes"></button>
    <button pRipple class="p-button-info" pButton type="button" (click)="showResendEmailDialog = false;"
      [disabled]="showActionSpinner" label="No"></button>
  </div>
</p-dialog>