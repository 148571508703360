import { Component, Input, OnInit } from '@angular/core';
import { RouteLegPersonAssignment } from '../../../interfaces/result-interfaces';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'leg-assignment',
  templateUrl: './legassignment.component.html',
  styleUrls: ['./legassignment.component.scss']
})
export class LegassignmentComponent implements OnInit {
  @Input('fileId') fileId!: number;

  routeLegAssignments: RouteLegPersonAssignment[] = [];

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  async fetch() {
    this.routeLegAssignments = await this.api.getRouteLegTransporterInformation(this.fileId);
  }

}
