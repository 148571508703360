import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { LogBase } from '../../../services/logger.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../../services/config.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { CurrencyApiService } from '../../../services/api/finance/currency-api.service';
import { DateTime } from 'luxon';
import { Currency } from '../../../interfaces/finance-interfaces';
import { FileApiService } from '../../../services/api/file-api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cargo',
  templateUrl: './cargo.component.html',
  providers: [ConfirmationService]
})
export class CargoComponent implements OnInit {

  @Output() onLoaded = new EventEmitter();

  @Input()
  FileId: any;
  userId: any;
  Cargo: any;
  activeCargo: any;
  showSpinner: boolean = false;
  fileClosed: boolean = false;
  saveSpinner: boolean = false;
  fileCancelled: boolean = false;
  descriptionOptions: any;
  selectedCurrency: Currency;
  currencies: Currency[];
  CurrencyDate: DateTime;

  constructor(
    private api: ApiService,
    private log: LogBase,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private titleService: Title,
    private authService: AuthenticationService,
    private currencyApi: CurrencyApiService,
    private fileApiService: FileApiService

  ) { this.titleService.setTitle("AVECS Cargo"); }

  async ngOnInit() {
    this.showSpinner = true;
    try {
      this.userId = this.authService.getLegalEntityId;
      await this.cargoSetup();
      await this.checkIfFileClosed();
      await this.getCurrencies();
    } catch (error) {
      this.showError('Error Initializing Component (ngOnInit())', error);
      this.log.error(error);
    }
    this.onLoaded.emit();
    this.showSpinner = false;
  }

  async cargoSetup() {
    try {
      this.Cargo = await this.api.getFileCargo(this.FileId);
      if (this.Cargo) {
        for (var x = 0; x < this.Cargo.Vehicles.length; x++) {
          var item = this.Cargo.Vehicles[x];
          item.Length = item.Length / 100;
          item.Height = item.Height / 100;
          item.Width = item.Width / 100;
          item.NewMonth = new Date('20' + item.Year + '-' + item.MonthId + -'01');
          item.editMode = false;
          item.showSaveSpinner = false;
        }

        for (var x = 0; x < this.Cargo.ContainerItems.length; x++) {
          var item = this.Cargo.ContainerItems[x];
          item.Length = item.Length / 100;
          item.Height = item.Height / 100;
          item.Width = item.Width / 100;
          item.editMode = false;
          item.showSaveSpinner = false;
        }
      }
    } catch (error) {
      this.showError('Error Cargo Setup (cargoSetup())', error);
      this.log.error(error);
    }
  }

  async checkIfFileClosed() {
    try {
      let fileClosed = false;
      if (this.Cargo.Vehicles.length > 0) {

        for (var x = 0; x < this.Cargo.Vehicles.length; x++) {
          if (this.Cargo.Vehicles[x].Cancelled == true) {
            fileClosed = true;
            break;
          }
          if (this.Cargo.Vehicles[x].Closed == true) {
            fileClosed = true;
            break;
          }
        }
      } else if (this.Cargo.ContainerItems.length > 0) {
        for (var x = 0; x < this.Cargo.ContainerItems.length; x++) {
          if (this.Cargo.ContainerItems[x].Cancelled == true) {
            fileClosed = true;
            break;
          }
          if (this.Cargo.ContainerItems[x].Closed == true) {
            fileClosed = true;
            break;
          }
        }
      }
      this.fileClosed = fileClosed;
    } catch (error) {
      this.showError('Error Cargo Setup (checkIfFileClosed())', error);
      this.log.error(error);
    }
  }

  async toggleEditMode(cargo) {
    try {
      this.createForm(cargo);
      await this.setVehicleMakesAndPrepopulateWithCurrentMake(cargo);
      await this.setVehicleModelsAndPrepopulateWithCurrentModel(cargo);
      await this.getColoursAndSetDefault(cargo);
      await this.getFuelTypesAndSetDefault(cargo);
      await this.getTransmissionTypesAndSetDefault(cargo);
      if (!cargo.editMode) {
        cargo.editMode = true;
      } else {
        cargo.editMode = false;
      }
    } catch (error) {

      this.showError('Error Toggling Edit Mode (toggleEditMode())', error);
      this.log.error(error);
    }
  }

  cancelEdit(cargo) {
    if (cargo.editMode) {
      cargo.editMode = false;
    }
  }

  async checkValidMonth(cargo) {

    try {
      if (cargo.CargoDetailsForm.value.Month instanceof Date) cargo.Month = cargo.MonthId;
      else cargo.Month = cargo.CargoDetailsForm.value.Month;
      return cargo;

    } catch (error) {
      this.log.error(error);
    }

  }

  async createContainerForm(cargo: any) {
    cargo.CargoDetailsForm = this.fb.group({
      ChassisNo: [cargo.ChassisNo, Validators.required],
      ContainerNo: [cargo.ContainerNumber],
      ContainerSealNo: [cargo.ContainerSealNumber],
      Quantity: [cargo.Quantity],
      Description: [{ Name: cargo.Description }],
      EngineNo: [cargo.EngineNo],
      ForeignReference: [cargo.ForeignReference],
      Height: [cargo.Height, Validators.required],
      Length: [cargo.Length, [Validators.required, Validators.max(14)]],
      Weight: [cargo.Weight, Validators.required],
      Width: [cargo.Width, Validators.required],
      CargoValue: [cargo?.CargoValue],
      SelectedCurrency: [cargo?.CurrencyId]
    });
  }


  async updateContainerItems(cargo: any) {
    try {
      cargo.showSaveSpinner = true;
      var payload = {
        ForeignReference: cargo.CargoDetailsForm.value.ForeignReference,
        ChassisNo: cargo.CargoDetailsForm.value.ChassisNo,
        Quantity: cargo.CargoDetailsForm.value.Quantity,
        Description: cargo.CargoDetailsForm.value.Description.Name,
        EngineNo: cargo.CargoDetailsForm.value.EngineNo,
        Height: cargo.CargoDetailsForm.value.Height,
        Length: cargo.CargoDetailsForm.value.Length,
        Weight: cargo.CargoDetailsForm.value.Weight,
        Width: cargo.CargoDetailsForm.value.Width,
        FileId: this.FileId,
        UserId: this.userId,
        ContainerNo: cargo.CargoDetailsForm.value.ContainerNo,
        ContainerSealNo: cargo.CargoDetailsForm.value.ContainerSealNo,
        FileRowItemId: cargo.FileRowItemId,
      };
      var res = await this.api.updateContainerItem(payload);
      if (res) {
        await this.cargoSetup();
        cargo.showSaveSpinner = false;
        this.messageService.add({ severity: 'success', summary: '', detail: 'Cargo details updated' });
      } else {
        cargo.showSaveSpinner = false;
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error with updating the cargo details' });
      }
      cargo.editMode = false;
    } catch (error) {
      this.log.error(error);
    }
  }

  async update(cargo) {
    this.saveSpinner = true;
    try {

      cargo.showSaveSpinner = true;
      var updatedCargo = await this.checkValidMonth(cargo);

      let fueltype = -1;
      if (updatedCargo.CargoDetailsForm.value.FuelType) fueltype = updatedCargo.CargoDetailsForm.value.FuelType.Id;
      let transmissiontype = -1;
      if (updatedCargo.CargoDetailsForm.value.TransmissionType.Id) transmissiontype = updatedCargo.CargoDetailsForm.value.TransmissionType.Id;

      var payload = {
        FileRowItemId: updatedCargo.FileRowItemId,
        ChassisNo: updatedCargo.CargoDetailsForm.value.ChassisNo,
        Colour: updatedCargo.CargoDetailsForm.value.Colour.Id,
        EngineNo: updatedCargo.CargoDetailsForm.value.EngineNo,
        FuelType: fueltype,
        Grade: updatedCargo.CargoDetailsForm.value.Grade,
        Height: updatedCargo.CargoDetailsForm.value.Height,
        Length: updatedCargo.CargoDetailsForm.value.Length,
        Manufacturer: updatedCargo.CargoDetailsForm.value.Manufacturer.Id,
        Mileage: updatedCargo.CargoDetailsForm.value.Mileage,
        Model: updatedCargo.CargoDetailsForm.value.Model.Id,
        ModelCode: updatedCargo.CargoDetailsForm.value.ModelCode,
        Month: updatedCargo.CargoDetailsForm.value.Month,
        TransmissionType: transmissiontype,
        Weight: updatedCargo.CargoDetailsForm.value.Weight,
        Width: updatedCargo.CargoDetailsForm.value.Width,
        Year: updatedCargo.CargoDetailsForm.value.Year,
        FileId: this.FileId,
        UserId: this.userId,
        ContainerNo: updatedCargo.CargoDetailsForm.value.ContainerNo,
        ContainerSealNo: updatedCargo.CargoDetailsForm.value.ContainerSealNo,
        CargoValue: updatedCargo.CargoDetailsForm.value.CargoValue,
        CurrencyId: updatedCargo.CargoDetailsForm.value.SelectedCurrency.id,
        CargoValueExchangeRateDate: moment(updatedCargo.CargoDetailsForm.value.CargoValueExchangeRateDate).format('YYYY/MM/DD hh:mm:ss')
      };

      const res = await this.fileApiService.updateCargoItem(payload);
      if (!res) this.messageService.add({ severity: 'error', summary: '', detail: 'Error with updating the cargo details' });
      else if (res.errorCode !== 0) this.messageService.add({ severity: 'error', summary: '', detail: res.errorMessage });
      else {
        this.messageService.add({ severity: 'success', summary: '', detail: res.errorMessage });
        await this.cargoSetup();
      }
      cargo.showSaveSpinner = false;
      cargo.editMode = false;
    } catch (error) {
      this.showError('Error Updating Cargo Details (update())', error);
      this.log.error(error);
    }
    this.saveSpinner = false;
  }

  createForm(cargo) {

    let fueltype = -1;
    if (cargo.FuelType) fueltype = cargo.FuelType;
    let transmissiontype = -1;
    if (cargo.TransmissionType) transmissiontype = cargo.TransmissionType;

    const currency: Currency | null = this.currencies.find(c => c.id == cargo?.CurrencyId);
    const date: Date | null = cargo?.CargoValueExchangeRateDate ? new Date(cargo.CargoValueExchangeRateDate) : null;

    cargo.CargoDetailsForm = this.fb.group({
      FileRowItemId: [cargo.FileRowItemId, Validators.required],
      Manufacturer: [cargo.Manufacturer, Validators.required],
      Model: [cargo.Model, Validators.required],
      ModelCode: [cargo.ModelCode],
      Year: [cargo.Year],
      Month: [cargo.MonthId, Validators.required],
      Grade: [cargo.Grade],
      ChassisNo: [cargo.ChassisNo, Validators.required],
      Mileage: [cargo.Mileage],
      Colour: [cargo.Colour, Validators.required],
      FuelType: [fueltype],
      TransmissionType: [transmissiontype],
      Length: [cargo.Length, [Validators.required, Validators.max(14)]],
      Width: [cargo.Width, Validators.required],
      Height: [cargo.Height, Validators.required],
      Weight: [cargo.Weight, Validators.required],
      EngineNo: [cargo.EngineNo],
      ContainerNo: [cargo.ContainerNumber],
      ContainerSealNo: [cargo.ContainerSealNumber],
      CargoValue: [cargo?.CargoValue, [Validators.required, Validators.min(1)]],
      SelectedCurrency: [currency, Validators.required],
      CargoValueExchangeRateDate: [date, Validators.required]
    });
  }

  async setVehicleMakesAndPrepopulateWithCurrentMake(cargo) {
    try {
      cargo.vehicleMakes = await this.api.getVehicleMakes();
      for (var x = 0; x < cargo.vehicleMakes.length; x++) {
        var vehicle = cargo.vehicleMakes[x];
        if (vehicle.Name === cargo.Manufacturer) {
          cargo.CargoDetailsForm.get('Manufacturer').setValue(vehicle);
        }
      }
    } catch (error) {

      this.showError('Error Setting Vehicle Makes And Prepopulate With Current Make (setVehicleMakesAndPrepopulateWithCurrentMake())',
        error);
      this.log.error(error);
    }
  }

  async setVehicleModelsAndPrepopulateWithCurrentModel(cargo) {
    try {
      if (cargo.CargoDetailsForm.value && cargo.CargoDetailsForm.value.Manufacturer) {
        cargo.vehicleModels = await this.api.getVehicleModels(cargo.CargoDetailsForm.value.Manufacturer.Id);
        if (cargo.vehicleModels) {
          for (var x = 0; x < cargo.vehicleModels.length; x++) {
            var model = cargo.vehicleModels[x];
            if (model.Name === cargo.Model) {
              cargo.CargoDetailsForm.get('Model').setValue(model);
            }
          }
        }
      }
    } catch (error) {

      this.showError('Error Setting Vehicle Models And Prepopulate With Current Model (setVehicleModelsAndPrepopulateWithCurrentModel())',
        error);
      this.log.error(error);
    }
  }

  async getVehicleModels(cargo) {
    try {
      if (cargo.CargoDetailsForm.value && cargo.CargoDetailsForm.value.Manufacturer) {
        cargo.vehicleModels = await this.api.getVehicleModels(cargo.CargoDetailsForm.value.Manufacturer.Id);
        if (cargo.vehicleModels) {
          cargo.CargoDetailsForm.get('Model').setValue(cargo.vehicleModels[0]);
        }
      }
    } catch (error) {

      this.showError('Error Getting Vehicle Models (getVehicleModels())', error);
      this.log.error(error);
    }
  }

  addVehicleModel(cargo) {
    cargo.newModel = true;
  }

  disableVehicleModelButton(cargo) {
    var data = cargo.CargoDetailsForm.get('Manufacturer').value;
    if (data) {
      return false;
    } else {
      return true;
    }
  }

  async getColoursAndSetDefault(cargo) {
    try {
      cargo.colours = await this.api.getColors();
      for (var x = 0; x < cargo.colours.length; x++) {
        var colour = cargo.colours[x];
        if (colour.Color === cargo.Color) {
          cargo.CargoDetailsForm.get('Colour').setValue(colour);
        }
      }
    } catch (error) {

      this.showError('Error Getting Colours And Set Default (getColoursAndSetDefault())', error);
      this.log.error(error);
    }
  }

  async getFuelTypesAndSetDefault(cargo) {
    try {
      cargo.fuelTypes = await this.api.getFuelTypes();
      if (cargo.FuelType) {
        for (var x = 0; x < cargo.fuelTypes.length; x++) {
          var fuelType = cargo.fuelTypes[x];
          if (fuelType.Name === cargo.FuelType) {
            cargo.CargoDetailsForm.get('FuelType').setValue(fuelType);
          }
        }
      } else {
        cargo.CargoDetailsForm.get('FuelType').setValue(cargo.fuelTypes[4]);
      }
    } catch (error) {

      this.showError('Error Getting Fuel Types And Set Default (getFuelTypesAndSetDefault())', error);
      this.log.error(error);
    }
  }

  async getTransmissionTypesAndSetDefault(cargo) {
    try {
      cargo.transmissionTypes = await this.api.getTransmissionTypes();

      if (cargo.TransmissionType) {
        for (var x = 0; x < cargo.transmissionTypes.length; x++) {
          var transmissionType = cargo.transmissionTypes[x];
          if (transmissionType.Name === cargo.TransmissionType) {
            cargo.CargoDetailsForm.get('TransmissionType').setValue(transmissionType);
          }
        }
      } else {
        cargo.CargoDetailsForm.get('TransmissionType').setValue(cargo.transmissionTypes[2]);
      }
    } catch (error) {

      this.showError('Error Getting Transmission Types And Set Default (getTransmissionTypesAndSetDefault())', error);
      this.log.error(error);
    }
  }

  async checkChassisNo(cargo) {
    try {
      var chassisNo = cargo.CargoDetailsForm.get('ChassisNo').value;
      if (chassisNo) {
        cargo.notEmptyChassisNo = true;
        var res = await this.api.checkChassisNo(chassisNo);
        if (res) {
          cargo.validChassisNo = true;
        } else {
          cargo.validChassisNo = false;
        }
      } else {
        cargo.validChassisNo = false;
        cargo.notEmptyChassisNo = false;
      }
    } catch (error) {

      this.showError('Error Checking Chassis No (checkChassisNo())', error);
      this.log.error(error);
    }
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: Cargo Component<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';

        var subject: string = 'NovaDT Error Message - Component: Cargo Component';
        var res = this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      },
      reject: () => {

      }
    });
  }

  async toggleEditModeContainerItem(cargo: any) {
    try {
      this.createContainerForm(cargo);
      if (!cargo.editMode) {
        cargo.editMode = true;
      } else {
        cargo.editMode = false;
      }
    } catch (error) {
      this.log.error(error);
    }
  }


  searchDescriptions = async (event) => {
    try {
      this.descriptionOptions = await this.api.searchDescriptions(event.query);
    } catch (err) {
      this.log.error(err);
    }
  };

  async getCurrencies() {
    try {
      const retval = await this.currencyApi.getLocalCurrencies();
      if (!retval) this.toastMessage('error', 'Failed to get currencies. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.currencies = retval.result;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}