<div class="ui-g-12 progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="showFail" class="ui-g card card-w-title">
  <h3 style="color: red">Error: There are no invoices for the file.</h3>
</div>

<div *ngIf="!showSpinner && !showFail" class="ui-g card card-w-title">
  <div class="ui-g-12 header">
    <h3>Allocate Payment for</h3>
    <file-number [fileNumber]="allocatePayment.fileStatus.dbnNumber"></file-number>
  </div>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'222px'}">
      <p-header>
        Client Information
      </p-header>
      <div class="ui-g-4">
        <div class="client-information">
          <strong>Name</strong>
          <label>
            {{ allocatePayment.legalEntity.name || 'N/A' }}
          </label>
          <strong>Last Name</strong>
          <label>
            {{ allocatePayment.legalEntity.lastName || 'N/A' }}
          </label>
          <strong>Email</strong>
          <label>
            {{ allocatePayment.legalEntity.email || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="client-information">
          <strong>Address 1</strong>
          <label>
            {{ allocatePayment.legalEntity.address1 || 'N/A' }}
            <a *ngIf="!allocatePayment.legalEntity.address1">
              (Address 1)
            </a>
          </label>
          <label>
            {{ allocatePayment.legalEntity.address2 || 'N/A' }}
            <a *ngIf="!allocatePayment.legalEntity.address2">
              (Address 2)
            </a>
          </label>
          <label>
            {{ allocatePayment.legalEntity.address3 || 'N/A' }}
            <a *ngIf="!allocatePayment.legalEntity.address3">
              (Address 3)
            </a>
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="client-information">
          <strong>City</strong>
          <label>
            {{ allocatePayment.legalEntity.city || 'N/A' }}
          </label>
          <strong>VAT Number</strong>
          <label>
            {{ allocatePayment.legalEntity.vatNumber || 'N/A' }}
          </label>
          <strong>Pastel Code</strong>
          <label>
            {{ allocatePayment.legalEntity.pastelCode || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>
  </div>

  <p-table #ab class="ui-g-12" [value]="allocatePayment.invoices" [scrollable]="true" scrollHeight="400px"
    [autoLayout]="true"
    [globalFilterFields]="['invoiceId','date','invoiceType', 'invoicee', 'discountAmount','vatAmount', 'totalAmount', 'invoiceStatus']">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left invoice-total">
          <label>Total Invoice(s) {{ allocatePayment.invoices.length }}</label>
        </span>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="ab.filterGlobal($event.target.value, 'contains')"
            placeholder="Search invoice(s)" style="color: #fff;" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>
          #
        </th>
        <th>
          DATE
        </th>
        <th>
          TYPE
        </th>
        <th>
          INVOICEE
        </th>
        <th>
          AUDIT USER
        </th>
        <th>
          DISCOUNT
        </th>
        <th>
          VAT
        </th>
        <th>
          EXC VAT
        </th>
        <th>
          INC VAT
        </th>
        <th>
          STATUS
        </th>
        <th>
          ACTIONS
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td>
          I/{{allocatePayment.invoices[rowIndex].invoiceId.toString().padStart(7, '0') }}
        </td>
        <td>{{ formatDate(rowData.date) }}</td>
        <td>
          {{ rowData.invoiceType}}
        </td>
        <td>
          {{ rowData.invoicee}}
        </td>
        <td>
          {{ rowData.auditUser}}
        </td>
        <td>
          {{  financeHelper.formatAmountWithCurrencySymbol(rowData.discountAmount, allocatePayment.invoices[rowIndex].currencyCode, allocatePayment.invoices[rowIndex].currencyId) }}
        </td>
        <td>
          {{  financeHelper.formatAmountWithCurrencySymbol(rowData.vatAmount, allocatePayment.invoices[rowIndex].currencyCode, allocatePayment.invoices[rowIndex].currencyId) }}
        </td>
        <td>
          {{  financeHelper.formatAmountWithCurrencySymbol((rowData.totalAmount-rowData.vatAmount), allocatePayment.invoices[rowIndex].currencyCode, allocatePayment.invoices[rowIndex].currencyId) }}
        </td>
        <td>
          {{  financeHelper.formatAmountWithCurrencySymbol(rowData.totalAmount, allocatePayment.invoices[rowIndex].currencyCode, allocatePayment.invoices[rowIndex].currencyId) }}
        </td>
        <td class="status">
          <label class="{{rowData.invoiceStatus.toLowerCase()}}">{{ rowData.invoiceStatus.toUpperCase() }}</label>
        </td>
        <td>
          <button pRipple pButton class="p-button-info" type="button" icon="fas fa-book" pTooltip="View line items"
            (click)="viewLineItems(rowIndex)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td>No invoice(s) found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-card class="ui-g-12">
    <div class="summary">
      <div class="group">
        <i class="fas fa-receipt"></i>
        <h2>Total Owed</h2>
        <div *ngFor="let balance of allocatePayment.outstandingBalance">
          <label>
            {{ balance.currencyCode }}
          </label>
          <label>
            {{ balance.amountOwed.toFixed(2) }}
          </label>
        </div>
      </div>
      <div class="group">
        <i class="fas fa-file-invoice-dollar"></i>
        <h2>Paid</h2>
        <div *ngFor="let balance of allocatePayment.outstandingBalance">
          <label>
            {{ balance.currencyCode }}
          </label>
          <label>
            {{ formatAmountPaid(balance.amountPaid) }}
          </label>
        </div>
      </div>
      <div class="group">
        <i class="fas fa-solid fa-file-invoice"></i>
        <h2>Outstanding</h2>
        <div *ngFor="let balance of allocatePayment.outstandingBalance">
          <label>
            {{ balance.currencyCode }}
          </label>
          <label>
            {{ balance.amountOutStanding.toFixed(2) }}
          </label>
        </div>
      </div>
    </div>
  </p-card>

  <div class="ui-g-12">
    <div class="ui-g-6">
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-calendar [inputStyle]="{'width':'100%'}" [style]="{'width':'100%'}" [readonlyInput]="true" appendTo="body"
            [showIcon]="false" dateFormat="yy/mm/dd" [showIcon]="true" required (onSelect)="onDatePaidDateSelect()"
            [(ngModel)]="allocatePaymentPost.paymentDate"></p-calendar>
          <label class="float-label" for="float-input">Date Paid *</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown [options]="currencies" [autoDisplayFirst]="false" [style]="{'width':'100%'}" [filter]="true"
            [(ngModel)]="selectedCurrency" optionLabel="currency" (onChange)="onCurrencySelect()">
          </p-dropdown>
          <label class="float-label" for="float-input">Currency *</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <input class="width-100" type="text" pInputText [(ngModel)]="allocatePaymentPost.paymentAmount" required
            (keyup)="onPaymentAllocateValidate()" />
          <label class="float-label" for="float-input">Payment *</label>
        </span>
      </div>
    </div>
    <div class="ui-g-6 ui-g-nopad padding-top-15px">
      <span class="ui-g-12 ui-g-nopad ui-float-label">
        <textarea class="payment-reference" required pInputTextarea
          [(ngModel)]="allocatePaymentPost.bankAccount"></textarea>
        <label class="float-label" for="float-input">Bank Account *</label>
      </span>
    </div>
  </div>
  <div class="ui-g-12">
    <button *ngIf="!postSpinner" pRipple class="p-button-info width-100" pButton type="button"
      [disabled]="disableAllocatePayment()" (click)="postAllocatePayment()" label="Allocate Payment"></button>
    <button *ngIf="postSpinner" pButton type="button" label="" class="width-100" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</div>

<p-dialog *ngIf="viewInvoiceLineItem" [(visible)]="viewInvoiceLineItem" (onHide)="viewInvoiceLineItem = false"
  header="{{ invoiceNumber }} Line Item(s)" modal="true" [style]="{'width': '65%', 'height': 'auto'}">
  <p-table #dt [value]="allocatePayment.invoices[selectedInvoiceIndex].lineItems">
    <ng-template pTemplate="header">
      <tr class="table-column-header">
        <th colspan="3">
          DESCRIPTION
        </th>
        <th colspan="2">
          PASTEL CODE
        </th>
        <th colspan="1">
          QTY
        </th>
        <th colspan="2">
          UNIT AMOUNT
        </th>
        <th colspan="2">
          NET AMOUNT
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td colspan="3">{{ rowData.description}}</td>
        <td colspan="2">{{ rowData.pastelCode}}</td>
        <td colspan="1">{{ rowData.quantity}}</td>
        <td colspan="2">
          {{ 
              financeHelper.formatAmountWithCurrencySymbol(rowData.amount, allocatePayment.invoices[selectedInvoiceIndex].currencyCode, allocatePayment.invoices[selectedInvoiceIndex].currencyId)
            }}
        </td>
        <td colspan="2">
          {{ 
              financeHelper.formatAmountWithCurrencySymbol(rowData.netAmount, allocatePayment.invoices[selectedInvoiceIndex].currencyCode, allocatePayment.invoices[selectedInvoiceIndex].currencyId)
            }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>