import { Injectable } from '@angular/core';
import { HttpResponse } from '../../../interfaces/result-interfaces';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";

@Injectable({
  providedIn: 'root'
})

export class SharedUserTaskApiService {

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  public async AssignClearingAgent(payload: any): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + "api/userTask/shared/assignClearingAgent";
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }
}
