import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { ConfirmationService } from "primeng";
import { MessageService } from "primeng/api";
import { LogBase } from "../../../services/logger.service";
import { ConfigService } from "../../../services/config.service";
import { ActivatedRoute } from "@angular/router";
import { Validators, FormControl, FormGroup, FormBuilder } from "@angular/forms";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { ReferenceApiService } from "../../../services/api/reference-api.service";
import { Groups } from "../../../enums";
import { TableColumns } from "../../../interfaces/global.interfaces";

export interface Voyage {
  Id: number;
  Name: string;
  VesselCompanyName: string;
  VoyageNumber: string;
  ETA: string;
  CargoItemCount: Number;
  AssignedTo: string;
  AssignedToId: any;
}

@Component({
  selector: "app-manage-vessels",
  templateUrl: "./manage-vessels.component.html",
  styleUrls: ["./manage-vessels.component.css"],
  providers: [ConfirmationService],
})
export class ManageVesselsComponent implements OnInit {
  showLoadingSpinner: boolean = false;
  cols: TableColumns[] = [
    { field: "DBNNumber", header: "DBNNumber" },
    { field: "ForeignReference", header: "Foreign Reference" },
    { field: "Consignee", header: "Consignee" },
    { field: "Quantity", header: "QTY" },
    { field: "Description", header: "Description" },
  ];
  voyages: any;
  newVoyage: boolean = false;
  voyageNumber = null;
  voyageETA = null;
  voyageHistory = [];
  assignedToId = null;
  voyageId = null;
  // DIALOGS
  displayDialog: boolean;
  editVoyageDialogue: boolean = false;
  deleteVesselDialogue: boolean = false;
  reassignVoyagesDialog: boolean = false;
  continueReassignVoyagesProcess: boolean = false;
  deleteSpinner: boolean = false;
  deleteVoyageDialogue: boolean = false;
  displayReassignVoyageModal: boolean = false;
  deleteVesselWarningDialogue: boolean = false;
  displayCargo: boolean = false;
  editVoyageForm: FormGroup;
  vesselNames: any;
  vesselInfo: any;
  newVessel: any[];
  vesselCompanys: any[];
  voyageCargo: any[];
  selectedVessel: any;
  selectedVoyage: Voyage = {
    Id: 0,
    Name: "",
    VesselCompanyName: "",
    VoyageNumber: "",
    ETA: "",
    CargoItemCount: 0,
    AssignedTo: "",
    AssignedToId: 0,
  };
  voyageAssignees: any[];
  selectedVoyageAssignee: any;
  selectedShippingLine: any = null;
  VesselName: string;
  VesseId: string;
  VesselCompanyId: any;
  reassignVoyages: any[];
  selectedReassignVessel: any;
  selectedReassignVoyage: any;
  itemCols: TableColumns[] = [
    { field: "VesselName", header: "Vessel" },
    { field: "VesselCompanyName", header: "Vessel Company" },
    { field: "VoyageNumber", header: "Voyage" },
    { field: "ETA", header: "ETA" },
    { field: "CargoItemCount", header: "Cargo Item(s)" },
    { field: "AssignedTo", header: "Assigned To" },
  ];
  vesselCols: TableColumns[] = [{ field: "VesselName", header: "Vessels" }];
  constructor(
    public api: ApiService,
    private confirmationService: ConfirmationService,
    private referenceApiService: ReferenceApiService,
    private messageService: MessageService,
    private log: LogBase,
    private route: ActivatedRoute,
    private config: ConfigService,
    public formbuilder: FormBuilder,
    private titleService: Title,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Manage Vessels");
  }

  async ngOnInit() {
    try {
      this.vesselNames = await this.api.getVesselNames();
      this.vesselCompanys = await this.api.getAllVesselCompany();
      await this.getRoundRobinUsersByGroupId();
    } catch (error) {
      this.showError("Error Initializing Component (ngOnInit())", error);
      this.log.error(error);
    }
  }

  private async getRoundRobinUsersByGroupId() {
    try {
      const retval = await this.referenceApiService.getRoundRobinUsersByGroupId(Groups.VESSEL_OWNERS);
      if (!retval) this.toastMessage('error', 'Failed to get round robin users. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.voyageAssignees = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get round robin users. Please contact support.', '');
    }
  }

  async viewCargoForVoyage(selectedVoyage: any) {
    this.showLoadingSpinner = true;
    try {
      if (selectedVoyage) {
        this.selectedVoyage = selectedVoyage;
        this.voyageCargo = await this.api.getCargoByVoyageId(this.selectedVoyage.Id);
        this.displayCargo = true;
      }
    } catch (error) {
      this.showError("Error Getting Cargo Info for voyage (viewCargoForVoyage())", error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  async deleteVoyage(selectedVoyage: any) {
    try {
      if (selectedVoyage) {
        this.selectedVoyage = selectedVoyage;
        this.deleteVoyageDialogue = true;
      }
    } catch (error) {
      this.showError("Error Getting Vessel Info (getVesselInfo())", error);
      this.log.error(error);
    }
  }

  async continueDeleteVoyage() {
    this.deleteSpinner = true;
    const cargoExists: boolean = await this.api.checkIfVoyageHasCargo(this.selectedVoyage.Id);
    if (!cargoExists) {
      const voyageDeleted = await this.api.deleteVoyage(this.selectedVoyage.Id);
      if (!voyageDeleted) {
        this.toastMessage('error', 'Voyage Deletion', 'Voyage failed to delete');
      } else {
        await this.getVoyages();
        this.toastMessage('success', 'Voyage Deletion', 'Voyage deleted');
        this.deleteVoyageDialogue = false;
      }
    } else {
      this.deleteVoyageDialogue = false;
      // If there are voyages, then show the reassign modal
      this.displayReassignVoyageModal = true;
    }
    this.deleteSpinner = false;
  }

  async hideReassignDialog() {
    this.displayReassignVoyageModal = false;
  }

  async deleteVessel(selectedVessel: any) {
    try {
      if (selectedVessel) {
        this.selectedVessel = selectedVessel;
        this.deleteVesselDialogue = true;
      }
    } catch (error) {
      this.showError("Error Getting Vessel Info (getVesselInfo())", error);
      this.log.error(error);
    }
  }

  closeVoyagesExistWarning() {
    this.deleteVesselWarningDialogue = false;
  }

  async continueDeleteVessel() {
    this.deleteSpinner = true;
    console.log(this.selectedVessel);
    // Check if there are voyages
    const voyagesExist: boolean = await this.api.checkIfVesselHasVoyagesWithCargo(this.selectedVessel.Id);
    if (voyagesExist == false) {
      const deleted: boolean = await this.api.deleteVessel(this.selectedVessel.Id);
      // Message
      if (deleted) {
        this.vesselNames = await this.api.getVesselNames();
        this.messageService.add({ severity: "success", summary: "Vessel Deleted", detail: "Vessel Deleted" });
      } else {
        this.messageService.add({ severity: "error", summary: "Vessel Deletion", detail: "Vessel not Deleted" });
        this.deleteVesselWarningDialogue = true;
      }
    } else {
      this.reassignVoyagesDialog = true;
    }
    this.deleteVesselDialogue = false;
    this.deleteSpinner = false;
  }

  async reassignVoyage() {
    // Prevent the cancel button from being clicked
    this.continueReassignVoyagesProcess = true;
    this.reassignVoyagesDialog = false;

    let reassigned = await this.api.reassignVoyage(this.selectedVoyage.Id, this.selectedReassignVoyage.Id);
    if (reassigned) {
      this.messageService.add({ severity: "success", summary: "Cargo Reassignment", detail: "Cargo Reassigned to another Voyage" });
      this.displayReassignVoyageModal = false;
    } else {
      this.messageService.add({ severity: "error", summary: "Cargo Reassignment", detail: "Error. Cargo not Reassigned to another Voyage" });
    }
    await this.getVoyages();
  }
  formatDate(date) {
    return moment(date).format('YYYY/MM/DD HH:mm');
  }

  async displayEditVoyageDialogue(rowData) {
    this.showLoadingSpinner = true;
    let resp = await this.api.getVoyagesHistoryByVoyageId(rowData.Id);
    if (resp && resp.errorCode == 0) {
      this.voyageHistory = resp.result;
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Could not get voyage history",
        detail: resp.errorMessage,
      });
    }
    this.showLoadingSpinner = false;
    this.voyageETA = rowData.ETA;
    this.assignedToId = rowData.AssignedToId;
    this.voyageNumber = rowData.Name;
    this.voyageId = rowData.Id;
    if (rowData.AssignedToId == 0) {
      this.selectedVoyageAssignee = null;
    } else {
      this.selectedVoyageAssignee = { label: rowData.AssignedTo, value: rowData.AssignedToId };
    }
    this.editVoyageDialogue = true;
  }

  async addVessel() {
    this.displayDialog = false;
    try {
      var retval = await this.api.createNewVesselService(this.selectedShippingLine.Id, this.VesselName);

      if (retval.Result != -1) {
        this.vesselNames = await this.api.getVesselNames();

        this.messageService.add({
          severity: "info",
          summary: "Added",
          detail: "Vessel Added",
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Vessel Already Exists",
        });
      }
    } catch (err) {
    }
  }

  async generateNewEditVoyageForm() {
    var eta = this.selectedVoyage.ETA.split(" ")[0];
    var voyageETAFormatted = moment(eta).format("YYYY/MM/DD");
    this.editVoyageForm = this.formbuilder.group({
      voyageName: new FormControl(
        this.selectedVoyage.VoyageNumber,
        Validators.required
      ),
      voyageETA: new FormControl(voyageETAFormatted, Validators.required),
      assignee: new FormControl(
        this.selectedVoyage.AssignedToId,
        Validators.required
      ),
    });
  }

  async getVesselInfo(selectedVessel) {
    try {
      if (selectedVessel) {
        this.showLoadingSpinner = true;
        this.vesselInfo = await this.api.getVesselInfo(selectedVessel.Id);
        await this.getVoyages();
        this.showLoadingSpinner = false;
      }
    } catch (error) {
      this.showError("Error Getting Vessel Info (getVesselInfo())", error);
      this.log.error(error);
    }
  }

  async getReassignVoyages() {
    try {
      this.reassignVoyages = await this.api.getVoyagesByVesselId(this.selectedReassignVessel.Id);
      for (var x = 0; x < this.reassignVoyages.length; x++) {
        if (this.reassignVoyages[x].ETA) {
          var eta = this.reassignVoyages[x].ETA.split(" ")[0];
          this.reassignVoyages[x].ETA = moment(eta).format("YYYY/MM/DD");
        } else {
          this.reassignVoyages[x].ETA = "";
        }
      }
    } catch (error) {
      this.showError("Error Getting Voyages (getVoyages())", error);
      this.log.error(error);
    }
  }

  async getVoyages() {
    this.showLoadingSpinner = true;
    try {
      this.voyages = await this.api.getVoyagesByVesselId(this.vesselInfo.VesselId);
      for (var x = 0; x < this.voyages.length; x++) {
        if (this.voyages[x].ETA) {
          var eta = this.voyages[x].ETA.split(" ")[0];
          this.voyages[x].ETA = moment(eta).format("YYYY/MM/DD");
        } else {
          this.voyages[x].ETA = "";
        }
      }
    } catch (error) {
      this.showError("Error Getting Voyages (getVoyages())", error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  async addVoyage() {
    this.newVoyage = true;
    this.voyageNumber = null;
    this.voyageETA = null;
  }

  async saveNewVoyage() {
    this.showLoadingSpinner = true;
    try {
      this.displayDialog = false;

      let payload = {
        VoyageNumber: this.voyageNumber,
        ETA: this.voyageETA,
        VesselId: this.vesselInfo.VesselId,
      };
      let res = await this.api.addVoyage(payload);
      if (res.errorCode != 0) {
        this.messageService.add({
          severity: "error",
          summary: "",
          detail: res.errorMessage,
        });
      } else {
        await this.getVoyages();
        this.cancel();

        this.messageService.add({
          severity: "info",
          summary: "Saved",
          detail: "New Voyage saved",
        });
      }
    } catch (error) {
      this.showError("Error Saving New Voyage (saveNewVoyage())", error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  async updateVoyage() {
    try {
      var assignee = 0;
      if (this.selectedVoyageAssignee) {
        assignee = this.selectedVoyageAssignee.value;
      }
      let payload = {
        VoyageId: this.voyageId,
        VoyageNumber: this.voyageNumber,
        ETA: this.voyageETA,
        VesselId: this.vesselInfo.VesselId,
        AssignTo: assignee
      };
      let res = await this.api.updateVoyage(payload);
      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "",
          detail: "Failed to update Voyage",
        });
      } else {
        await this.getVoyages();
        this.cancel();

        this.messageService.add({
          severity: "info",
          summary: "Updated",
          detail: "Voyage Updated",
        });
        this.editVoyageDialogue = false;
      }
    } catch (error) {
      this.showError("Error Updating Voyage (updateVoyage())", error);
      this.log.error(error);
    }
  }

  async addVesselDialogue() {
    this.displayDialog = true;
    this.VesselName = "";
    this.selectedShippingLine = null;
    this.newVessel = this.vesselCompanys;
  }

  cancel() {
    this.newVoyage = false;
    this.reassignVoyagesDialog = false;
    this.deleteVoyageDialogue = false;
    this.deleteVesselDialogue = false;
    this.voyageNumber = null;
    this.voyageETA = null;
    this.voyageId = null;
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message:
        friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: Manage Vessels<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";

        var subject: string =
          "NovaDT Error Message - Component: Manage Vessels";
        var res = this.api.sendFreeTextEmail(
          this.config.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          -1,
          -1
        );
      },
      reject: () => { },
    });
  }

  async editVoyage(value: any) {
    try {
      this.editVoyageDialogue = false;
      await this.api.editVoyage(this.selectedVoyage.Id, value.voyageName, value.voyageETA, value.assignee);
      await this.getVoyages();
    } catch (err) {

    }
  }
  disabled() {
    if (this.voyageETA == null || this.voyageNumber == null || this.voyageNumber == "") {
      return true;
    }
    else {
      return false;
    }
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
