<summary-dashboard></summary-dashboard>
<div class="ui-g-12 card card-w-title">
  <div class="ui-g-12">
    <p-table #t [value]="files" [rows]="50" [autoLayout]="true" [paginator]="true" [rowsPerPageOptions]="[10,25,50]"
      currentPageReportTemplate="Showing {first} to
  {last} of {totalRecords} entries" [showCurrentPageReport]="true" [resizableColumns]="true" [tableStyle]="{ width:
  'max-content'}">
      <ng-template pTemplate="caption">
        Outstanding Amounts & Arrear Notices
        <p-button label="Export Excel" (onClick)="generateExcel()" class="exportButton"></p-button>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Edit Note</th>
          <th style="font-weight: bold;" pSortableColumn="dbnNumber">
            DBN Number
            <p-sortIcon field="dbnNumber"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="supplier">
            Supplier
            <p-sortIcon field="supplier"> </p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="note">
            Notes
            <p-sortIcon field="note"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="cif">
            CIF
            <p-sortIcon field="cif"> </p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="symbol">
            Symbol
            <p-sortIcon field="symbol"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="amt">
            Amount
            <p-sortIcon field="amt"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="consigneeFullName">
            Consignee Name
            <p-sortIcon field="consigneeFullName"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="email">
            Email
            <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="currentLocation">
            Current Location
            <p-sortIcon field="currentLocation"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="unloadDate">
            Unload Date
            <p-sortIcon field="unloadDate"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="daysAtLocation">
            Days At Location
            <p-sortIcon field="daysAtLocation"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="flood">
            Flood
            <p-sortIcon field="flood"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="chassisNo">
            Chassis
            <p-sortIcon field="chassisNo"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="manufacturer">
            Manufacturer
            <p-sortIcon field="manufacturer"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="model">
            Model
            <p-sortIcon field="model"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="vesselName">
            Vessel Name
            <p-sortIcon field="vesselName"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="voyageNumber">
            Voage Number
            <p-sortIcon field="voyageNumber"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="eta">
            ETA
            <p-sortIcon field="eta"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="fullRoute">
            Full Route
            <p-sortIcon field="fullRoute"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="inspectedOrNot">
            Inspected
            <p-sortIcon field="inspectedOrNot"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="dutiesUploadDate">
            Duties Upload Date
            <p-sortIcon field="dutiesUploadDate"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="dutiesPaid">
            Duties Paid
            <p-sortIcon field="dutiesPaid"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="t896">
            T896
            <p-sortIcon field="dutiesPaid"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="arrearNoticeDateOne">
            Arrear Notice Date - 1
            <p-sortIcon field="arrearNoticeDateOne"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="arrearNoticeDateTwo">
            Arrear Notice Date - 2
            <p-sortIcon field="arrearNoticeDateTwo"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="letterOfDemandDate">
            Letter of Demand Date
            <p-sortIcon field="letterOfDemandDate"></p-sortIcon>
          </th>
          <th style="font-weight: bold;" pSortableColumn="finalNoticeDate">
            Final Notice Date
            <p-sortIcon field="finalNoticeDate"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th colspan="1"></th>
          <th colspan="1">
            <input class="width-30" pInputText type="text"
              (input)="t.filter($event.target.value, 'dbnNumber', 'contains')">
          </th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1">
            <p-multiSelect [options]="currLocation" placeholder="All" appendTo="body"
              (onChange)="t.filter($event.value, 'currentLocation', 'in')">
            </p-multiSelect>
          </th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
          <th colspan="1"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file>
        <tr>
          <td><button class="note-extend" type="button"
              (click)="showPositionDialog('bottom', file.note, file.dbnNumber)" pTooltip="View notes"
              tooltipPosition="left" pButton icon="pi pi-pencil"></button></td>
          <td>{{file.dbnNumber}}</td>
          <td>{{file.supplier}}</td>
          <td>{{file.note}}</td>
          <td>{{file.cif}}</td>
          <td>{{file.symbol}}</td>
          <td>{{file.amt}}</td>
          <td>{{file.consigneeFullName}}</td>
          <td>{{file.email}}</td>
          <td>{{file.currentLocation}}</td>
          <td>{{file.unloadDate}}</td>
          <td>{{file.daysAtLocation}}</td>
          <td>{{file.flood}}</td>
          <td>{{file.chassisNo}}</td>
          <td>{{file.manufacturer }}</td>
          <td>{{file.model}}</td>
          <td>{{file.vesselName}}</td>
          <td>{{file.voyageNumber}}</td>
          <td>{{file.eta}}</td>
          <td>{{file.fullRoute}}</td>
          <td>{{file.inspectedOrNot}}</td>
          <td>{{file.dutiesUploadDate}}</td>
          <td>{{file.dutiesPaid}}</td>
          <td>{{file.t896}}</td>
          <td>{{file.arrearNoticeDateOne}}</td>
          <td>{{file.arrearNoticeDateTwo}}</td>
          <td>{{file.letterOfDemandDate}}</td>
          <td>{{file.finalNoticeDate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Notes Extended" [(visible)]="displayPosition" [modal]="true" [style]="{width: '50vw'}"
  [maximizable]="true" [draggable]="false" [resizable]="false">
  <div class="ui-g">
    <div class="ui-g-12 centerInDiv">
      <textarea [disabled]="editNote == 1" pInputTextarea class="note-text large-textarea" maxlength="500"
        [(ngModel)]="notes"></textarea>
    </div>
    <button pButton *ngIf="editNote == 1" (click)="editNotesToggle()" label="Edit Note"></button>
    <button pButton *ngIf="editNote == 2" (click)="editNotesSaveChanges()" label="Save Changes"></button>
  </div>
</p-dialog>