import { Injectable } from '@angular/core';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';
@Injectable({
  providedIn: 'root'
})
export class FinanceUserTaskApiService {

  private serverUrl = `${this.config.getServerUrl()}api/finance/usertasks/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  //Endpoint: api/finance/usertasks/GetAllocatePaymentUserTask
  //Controller: FinanceUserTaskController.cs
  //Proc Name: Multiple procs
  public async GetAllocatePaymentUserTask(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetAllocatePaymentUserTask?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/finance/usertasks/AllocatePayment
  //Controller: FinanceUserTaskController.cs
  //Proc Name: Multiple procs
  public async AllocatePaymentUserTask(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}AllocatePayment`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/finance/usertasks/GetApproveRejectAdminTransactionUserTask
  //Controller: FinanceUserTaskController.cs
  //Proc Name: pr_getTransactionIntentUserTaskByFileId
  public async GetApproveRejectAdminTransactionUserTask(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetApproveRejectAdminTransactionUserTask?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/finance/usertasks/RejectAdminTransactionUserTask
  //Controller: FinanceUserTaskController.cs
  //Proc Name: Multiple procs
  public async RejectAdminTransactionUserTask(fileId: number, rejectReason: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}RejectAdminTransactionUserTask?fileId=${fileId}&rejectReason=${rejectReason}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/finance/usertasks/ApproveAdminTransactionUserTask
  //Controller: FinanceUserTaskController.cs
  //Proc Name: Multiple procs
  public async ApproveAdminTransactionUserTask(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}ApproveAdminTransactionUserTask?fileId=${fileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/finance/usertasks/getStorageAutoGenFailedTaskDetails
  //Controller: FinanceUserTaskController.cs
  //Proc Name: Multiple procs
  public async getStorageAutoGenFailReasons(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getStorageAutoGenFailedTaskDetails?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}