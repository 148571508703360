import { RoutesApiService } from './../../../services/api/routes-api.service';
import { Component, OnInit, ViewEncapsulation, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Api2Service } from '../../../services/api2.service';
import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { LogBase } from '../../../services/logger.service';
import { ConfigService } from '../../../services/config.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { CurrentRoutes, Dropdown, ShipmentDetails } from '../../../interfaces/global.interfaces';
import { DateTime } from "luxon";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { ReferenceApiService } from '../../../services/api/reference-api.service';

@Component({
  selector: 'app-shipping-details',
  templateUrl: './shipping-details.component.html',
  styleUrls: ['./shipping-details.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})

export class ShippingDetailsComponent implements OnInit {

  // Other
  @Input() activeIndex: number;
  @Output() indexEventFunction = new EventEmitter<any>();

  taskname = 'Shipping Details';

  // Form
  shipmentDetailsForm: any;

  // Dropdowns
  vesselNames: any;
  voyagesTranship: any;

  courierCompanies: any;
  suppliers: any;

  // Other Contact
  people: any;

  // Payload
  shipmentDetailsPayload: ShipmentDetails;
  savedBorderAgent: any;

  fileTypeItems: any[] = [
    {
      Name: 'RORO CIF',
      value: 1
    },
    {
      Name: 'RORO NON CIF',
      value: 2
    },
    {
      Name: 'RORO Handover',
      value: 3
    },
    {
      Name: 'FCL Quotation',
      value: 4
    },
    {
      Name: 'LCL Quotation',
      value: 5
    },
    {
      Name: 'FCL CIF',
      value: 6
    },
    {
      Name: 'LCL CIF',
      value: 7
    },
    {
      Name: 'LCL SADECOM',
      value: 8
    }
  ];

  fileDocumentSources: Dropdown[] = [];
  loadingSpinner: boolean = true;

  vesselName: string = '';
  voyageNo: string = '';
  displayETDDate: string = ''; //Date = new Date;

  MBL: any;
  selectedMBL: any;

  routes: CurrentRoutes[];
  selectedRoute: any;
  enableRouting: boolean = false;

  portOfDeparture: string = '';
  portOfArrival: string = '';

  borderAgents: any[];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private api2: Api2Service,
    private referenceApiService: ReferenceApiService,
    private routesApiService: RoutesApiService,
    private config: ConfigService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private log: LogBase,
    private titleService: Title,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS Shipping-Details");
  }

  async ngOnInit() {
    this.loadingSpinner = true;
    try {
      this.vesselNames = await this.api.getVesselNames(); //Id, Name
      this.fileDocumentSources = await this.referenceApiService.getDocumentSources();
      this.courierCompanies = await this.api.getCourierCompanies(); //value,label
      this.suppliers = await this.api.getSuppliers(); //Id, Name
      await this.getActiveRoutes();
      await this.getBoarderAgents();
      await this.checkIfProgress();
    } catch (error) {
      this.showError('An error has occured', error);
      this.log.error(error);
    }
    this.loadingSpinner = false;
  }

  async getActiveRoutes() {
    try {
      let retval = await this.routesApiService.getActiveRoutes();
      if (!retval) {
        this.messageService.add({ severity: 'error', summary: 'Failed to get active routes. Please contact support.', detail: '' });
      } else if (retval.errorCode != 0) {
        this.messageService.add({ severity: 'error', summary: retval.errorMessage, detail: '' });
      } else {
        this.routes = retval.result;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async getBoarderAgents() {
    try {
      let retval = await this.api.getBorderAgents();
      if (retval) {
        this.borderAgents = retval;
      } else {
        this.borderAgents = [];
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async setFileType(event: any) {
    try {
      let fileType = JSON.stringify(event.value);
      localStorage.setItem('fileType', fileType);
    } catch (error) {
      this.showError('An error has occured', error);
    }
  }

  async checkIfProgress() {
    let retval = await JSON.parse(localStorage.getItem('shipmentDetails'));
    if (retval) {
      this.shipmentDetailsPayload = retval;
    }
    await this.createForm();
  }

  async createForm() {
    try {
      this.shipmentDetailsForm = this.fb.group({
        fileDocumentSource: ['', Validators.required],
        filetype: ['', Validators.required],
        supplier: ['', Validators.required],
        referenceNo: ['', Validators.required],
        mblNo: ['', Validators.required],
        portOfDeparture: ['', Validators.required],
        portOfArrival: ['', Validators.required],
        etd: [{
          value: '',
          disabled: false
        }, Validators.required],
        sob: [{
          value: '',
          disabled: false
        }, Validators.required],
        vesselName: [{ value: this.vesselName, disabled: true }],
        voyageNo: [{ value: this.voyageNo, disabled: true }],
        eta: [{ value: this.displayETDDate, disabled: true }],
        vesselNameTranship: [''],
        voyageNoTranship: [''],
        courierCompany: [''],
        courierOriginalReceivedDate: [''],
        trackingNo: [''],
        agent: [''],
        route: [{
          value: '',
          disabled: false
        }, Validators.required]
      });

      if (this.shipmentDetailsPayload) {
        if (this.shipmentDetailsPayload.fileDocumentSourceId) this.getFileDocumentSource(this.shipmentDetailsPayload.fileDocumentSourceId);
        if (this.shipmentDetailsPayload.filetype) this.getFileType(this.shipmentDetailsPayload.filetype);
        if (this.shipmentDetailsPayload.SupplierId) await this.getSupplier(this.shipmentDetailsPayload.SupplierId);
        if (this.shipmentDetailsPayload.referenceNo) this.shipmentDetailsForm.get('referenceNo').setValue(this.shipmentDetailsPayload.referenceNo);
        if (this.shipmentDetailsPayload.MblNo) await this.searchMBLForForm(this.shipmentDetailsPayload.MblNo);
        if (this.MBL) {
          if (this.MBL[0]) this.shipmentDetailsForm.get('mblNo').setValue(this.MBL[0]);
          if (this.MBL[0]) this.selectedMBL = this.MBL[0];
          if (this.MBL[0].VesselName) this.vesselName = this.MBL[0].VesselName;
          if (this.MBL[0].VoyageNumber) this.voyageNo = this.MBL[0].VoyageNumber;
          if (this.MBL[0].MBL) this.displayETDDate = DateTime.fromISO(this.MBL[0].ETA).toFormat('yyyy/MM/dd');
        }

        if (this.shipmentDetailsPayload.PortOfDeparture) {
          this.shipmentDetailsForm.get('portOfDeparture').setValue(this.shipmentDetailsPayload.PortOfDeparture);
          var location = this.shipmentDetailsPayload.PortOfDeparture.Location.split(",", 1);
          this.portOfDeparture = location[0];
        }
        if (this.shipmentDetailsPayload.PortOfArrival) {
          this.shipmentDetailsForm.get('portOfArrival').setValue(this.shipmentDetailsPayload.PortOfArrival);
          var location = this.shipmentDetailsPayload.PortOfArrival.Location.split(",", 1);
          this.portOfArrival = location[0];
        }

        if (this.shipmentDetailsPayload.VesselNameTranshipId) this.getTranshipVessel(this.shipmentDetailsPayload.VesselNameTranshipId);
        if (this.shipmentDetailsPayload.VesselNameTranshipId) await this.getTranshipVoyage(this.shipmentDetailsPayload.VesselNameTranshipId, this.shipmentDetailsPayload.VoyageNoTranshipId);
        if (this.shipmentDetailsPayload.Etd) this.shipmentDetailsForm.get('etd').setValue(new Date(this.shipmentDetailsPayload.Etd));
        if (this.shipmentDetailsPayload.Sob) this.shipmentDetailsForm.get('sob').setValue(new Date(this.shipmentDetailsPayload.Sob));
        if (this.shipmentDetailsPayload.CourierCompanyId) await this.getCourierCompany(this.shipmentDetailsPayload.CourierCompanyId);
        if (this.shipmentDetailsPayload.CourierOriginalReceivedDate) this.shipmentDetailsForm.get('courierOriginalReceivedDate').setValue(this.shipmentDetailsPayload.CourierOriginalReceivedDate);
        if (this.shipmentDetailsPayload.TrackingNo) this.shipmentDetailsForm.get('trackingNo').setValue(this.shipmentDetailsPayload.TrackingNo);
        if (this.shipmentDetailsPayload.AgentId) await this.getBorderAgent(this.shipmentDetailsPayload.AgentId);
        this.shipmentDetailsForm.get('vesselName').setValue(this.vesselName);
        this.shipmentDetailsForm.get('voyageNo').setValue(this.voyageNo);
        this.shipmentDetailsForm.get('eta').setValue(this.displayETDDate);
        if (this.shipmentDetailsPayload.Route) {
          await this.FilterRoute();
          await this.setRoute(this.shipmentDetailsPayload.Route);
        }
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  getFileDocumentSource(fileDocumentSource: any) {
    console.log(fileDocumentSource, this.fileDocumentSources);
    const res = _.find(this.fileDocumentSources, (o: any) => {
      return o.value == fileDocumentSource;
    });
    if (res) this.shipmentDetailsForm.get('fileDocumentSource').setValue(res);
  }

  getFileType(filetype: any) {
    if (filetype) {
      let res = _.find(this.fileTypeItems, (o: any) => {
        return o.value == filetype;
      });
      if (res)
        this.shipmentDetailsForm.get('filetype').setValue(res);
    }
  }

  async getCourierCompany(Id: any) {
    if (Id) {
      let res = _.find(this.courierCompanies, (o: any) => {
        return o.value == Id;
      });
      if (res)
        this.shipmentDetailsForm.get('courierCompany').setValue(res);
    }
  }

  async setRoute(route: any) {
    if (route) {
      this.shipmentDetailsForm.get('route').setValue(route);
    }
  }

  async getBorderAgent(Id: any) {
    if (Id) {
      let res = _.find(this.borderAgents, (o: any) => {
        return o.value == Id;
      });
      if (res)
        this.shipmentDetailsForm.get('agent').setValue(res);
    }
  }

  async getSupplier(supplierId: any) {
    if (supplierId) {
      let res = _.find(this.suppliers, (o: any) => {
        return o.Id == supplierId;
      });
      if (res)
        this.shipmentDetailsForm.get('supplier').setValue(res);
    }
  }

  async onSelectDepartureCity(event: any) {
    this.shipmentDetailsForm.get('portOfDeparture').setValue(event);
    var location = event.Location.split(",", 1);
    this.portOfDeparture = location[0];
    await this.FilterRoute();
  }

  async onSelectArrivalCity(event: any) {
    this.shipmentDetailsForm.get('portOfArrival').setValue(event);
    var location = event.Location.split(",", 1);
    this.portOfArrival = location[0];
    await this.FilterRoute();
  }

  async onSelectRoute(event: any) {
    try {
      this.shipmentDetailsForm.get('route').setValue(event.value);
      if (this.shipmentDetailsPayload) {
        this.shipmentDetailsPayload.Route = event.value;
      }
    } catch (error) {
      this.showError('Error Selecting Route', error);
      this.log.error(error);
    }
  }

  async createNewRoute() {
    try {
      let payload = this.shipmentDetailsForm.value;
      await this.buildPayload(payload);
      await this.saveToLocalStorageAndEmitEvent();
      this.router.navigate(['/route-builder', 'newfile']);
    } catch (error) {
      this.log.error(error);
    }
  }

  async FilterRoute() {
    if (this.portOfDeparture.length > 0 && this.portOfArrival.length > 0) {
      this.routes = await this.api2.getFilteredRoutes(this.portOfDeparture, this.portOfArrival);
      if (this.routes.length == 0) { }
    }
  }

  getTranshipVessel(id: any) {
    if (id) {
      let res = _.find(this.vesselNames, (o: any) => {
        return o.Id == id;
      });
      if (res)
        this.shipmentDetailsForm.get('vesselNameTranship').setValue(res);
    }
  }

  async getTranshipVoyage(vesselId, VoyageId) {
    if (vesselId) {
      this.voyagesTranship = await this.api.getVoyagesByVesselId(vesselId);
      if (this.voyagesTranship) {
        let res = _.find(this.voyagesTranship, (o: any) => {
          return o.Id === VoyageId;
        });
        this.shipmentDetailsForm.get('voyageNoTranship').setValue(res);
      }
    }
  }

  async buildPayload(payload: any) {
    try {
      this.shipmentDetailsPayload = {} as ShipmentDetails;
      if (payload.fileDocumentSource.value) this.shipmentDetailsPayload.fileDocumentSourceId = payload.fileDocumentSource.value;
      if (payload.filetype.value) this.shipmentDetailsPayload.filetype = payload.filetype.value;
      if (payload.supplier.Id) this.shipmentDetailsPayload.SupplierId = payload.supplier.Id;
      if (payload.referenceNo) this.shipmentDetailsPayload.referenceNo = payload.referenceNo;

      if (payload.mblNo) {
        if (payload.mblNo.MBL.ETA == '0001-01-01T00:00:00') {
          payload.mblNo.MBL = '';
        }
        this.shipmentDetailsPayload.MblNo = payload.mblNo.MBL;
      }

      if (payload.vesselNameTranship.Id) this.shipmentDetailsPayload.VesselNameTranshipId = payload.vesselNameTranship.Id;
      if (payload.voyageNoTranship.Id) this.shipmentDetailsPayload.VoyageNoTranshipId = payload.voyageNoTranship.Id;
      if (payload.portOfArrival) this.shipmentDetailsPayload.PortOfArrival = payload.portOfArrival;
      if (payload.portOfDeparture) this.shipmentDetailsPayload.PortOfDeparture = payload.portOfDeparture;
      if (payload.etd) this.shipmentDetailsPayload.Etd = payload.etd;
      if (payload.sob) this.shipmentDetailsPayload.Sob = payload.sob;
      if (payload.courierCompany.value) this.shipmentDetailsPayload.CourierCompanyId = payload.courierCompany.value;
      if (payload.courierOriginalReceivedDate) this.shipmentDetailsPayload.CourierOriginalReceivedDate = payload.courierOriginalReceivedDate;
      if (payload.trackingNo) this.shipmentDetailsPayload.TrackingNo = payload.trackingNo;
      if (payload.agent.value) this.shipmentDetailsPayload.AgentId = payload.agent.value;
      if (payload.route.routeId) this.shipmentDetailsPayload.Route = payload.route;
      if (payload.vesselName) this.shipmentDetailsPayload.VesselName;
      if (payload.voyageNo) this.shipmentDetailsPayload.VoyageNumber;
      if (payload.eta) this.shipmentDetailsPayload.ETA;
    } catch (error) {
      this.showError('Error Building Payload))', error);
      this.log.error(error);
    }
  }

  async getVoyageDetails(event: any) {
    try {
      this.vesselName = event.VesselName;
      this.voyageNo = event.VoyageNumber;
      this.shipmentDetailsForm.get('vesselName').setValue(this.vesselName);
      this.shipmentDetailsForm.get('voyageNo').setValue(this.voyageNo);

      // Check for Null Date
      if (event.ETA == '0001-01-01T00:00:00') {
        this.displayETDDate = '';
      } else {
        this.displayETDDate = DateTime.fromISO(event.ETA).toFormat('yyyy/MM/dd');
      }
      this.shipmentDetailsForm.get('eta').setValue(this.displayETDDate);
    } catch (error) {
      this.log.error(error);
    }
  }

  async searchMBLForForm(event: any) {
    try {
      var res = await this.api.searchMBL(event);
      if (res.ETA == '0001-01-01T00:00:00') {
        res = '';
      }
      this.MBL = res;
    } catch (err) {
      this.log.error(err);
    }
  }

  async searchMBL(event: any) {
    try {
      var res = await this.api.searchMBL(event.query);
      this.MBL = res;
    } catch (err) {
      this.log.error(err);
    }
  }

  async onAgentComplete(event: any) {
    this.savedBorderAgent = event.value;
  }

  searchPerson = async (event: any) => {
    try {
      var res = await this.api.searchPerson(event.query);
      this.people = res;
    } catch (error) {
      this.showError('Error Searching Person (searchPerson())', error);
      this.log.error(error);
    }
  };

  async Next() {
    let payload = this.shipmentDetailsForm.value;
    var mblexists = await this.api.MBLExists(payload.mblNo.MBL);
    if (mblexists) {
      if (await this.checkReferenceNo()) {
        this.activeIndex = 1;
        await this.buildPayload(payload);
        await this.saveToLocalStorageAndEmitEvent();
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Supplier Reference Already Exists',
          detail: ''
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'The MBL Number Does not Exist in the Database',
        detail: 'The MBL Number Does not Exist in the Database'
      });
    }
  }

  async checkReferenceNo() {
    try {
      let payload = this.shipmentDetailsForm.value;
      if (payload.referenceNo) {
        var res = await this.api.checkReferenceNumber(payload.referenceNo, payload.supplier.Id);
        if (res) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  async saveToLocalStorageAndEmitEvent() {
    switch (this.shipmentDetailsPayload.filetype) {
      case 1:
      case 6:
      case 7:
        this.shipmentDetailsPayload.Cif = true;
        break;
      default:
        this.shipmentDetailsPayload.Cif = false;
        break;
    }
    localStorage.setItem('shipmentDetails', JSON.stringify(this.shipmentDetailsPayload));
    localStorage.setItem('pageStateRules', JSON.stringify({
      activeIndex: this.activeIndex
    }));
    this.indexEventFunction.emit({
      action: 'index changed',
      params: this.activeIndex
    });
  }

  validForm() {
    if (this.shipmentDetailsForm.valid) {
      if (this.vesselName) {
        return true;
      }
    }
    return false;
  }

  getVoyageNumbersTranship = async () => {
    try {
      if (this.shipmentDetailsForm.value && this.shipmentDetailsForm.value.vesselNameTranship) {
        this.voyagesTranship = await this.api.getVoyagesByVesselId(this.shipmentDetailsForm.value.vesselNameTranship.Id);
      }
    } catch (err) {
      this.log.error(err);
    }
  };

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: ' + this.taskname + '<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: ' + this.taskname;
        this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, -1, -1);
      }
    });
  }
}