import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { LogBase } from "../../../services/logger.service";
import { ConfirmationService } from "primeng";
import { MessageService } from 'primeng/api';
import * as _ from "lodash";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-masterbill",
  templateUrl: "./masterbill.component.html",
  styleUrls: ["./masterbill.component.css"],
  providers: [ConfirmationService]

})
export class MasterBillComponent implements OnInit {

  files: any[] | null = null;

  vessels: any[] | null = null;
  selectedVessel: any | null = null;
  voyages: any[] | null = null;
  selectedVoyage: any | null = null;

  mblNumbers: any[] | null = null;
  selectedMBLNumbers: any | null = null;
  MBLNumber: string;

  historical: boolean = false;

  addMasterbillDialogVisible: boolean = false;
  addButtonDisabled: boolean = true;
  hideFilterButton: boolean = true;
  hideExportListButton: boolean = true;

  showLoadingSpinner: boolean = false;

  constructor(
    private api: ApiService,
    private log: LogBase,
    private messageService: MessageService,
    private titleService: Title,
  ) { this.titleService.setTitle("AVECS Master Bills"); }

  async ngOnInit() {
    await this.getVessels();
  }

  async getVessels() {
    try {
      this.hideFilterButton = true;
      this.hideExportListButton = true;
      this.files = [];
      this.vessels = await this.api.getCommunicatorVessels();
    } catch (error) {
      this.toastMessage('error', 'Failed to get vessels. Please contact support', '');
      this.log.error(error);
    }
  }

  async getVoyages() {
    try {
      this.selectedVoyage = null;
      this.voyages = await this.api.getCommunicatorVoyages(this.historical, this.selectedVessel.id);
      this.mblNumbers = [];
      this.hideFilterButton = true;
      this.hideExportListButton = true;
      this.files = [];
    } catch (error) {
      this.toastMessage('error', 'Failed to get voyages. Please contact support', '');
      this.log.error(error);
    }
  }

  async getMBLNumbers() {
    try {
      this.selectedMBLNumbers = null;
      this.mblNumbers = await this.api.getMBLNumbers(this.selectedVoyage.id);
      this.hideFilterButton = false;
      this.hideExportListButton = true;
    } catch (error) {
      this.toastMessage('error', 'Failed to get mbl. Please contact support', '');
      this.log.error(error);
    }
  }

  async filterByVessel() {
    this.showLoadingSpinner = true;
    try {
      this.files = await this.api.getCargoByMasterBill(this.selectedMBLNumbers.MBL);
      if (this.files.length <= 0) {
        this.toastMessage('info', 'No files found.', '');
        this.showLoadingSpinner = false;
        return;
      }

      this.sortDimensions();
    } catch (error) {
      this.toastMessage('error', 'Failed to filter vessel. Please contact support', '');
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  private sortDimensions() {
    this.hideExportListButton = false;

    for (let i = 0; i < this.files.length; i++) {
      let x = this.files[i].height;
      this.files[i].height = x / 100;

      let y = this.files[i].vehicleLength;
      this.files[i].vehicleLength = y / 100;

      let z = this.files[i].width;
      this.files[i].width = z / 100;
    }
  }

  async addMasterbill() {
    this.showLoadingSpinner = true;
    try {
      const success = await this.api.addNewMasterbill(this.MBLNumber, this.selectedVoyage.id);
      if (!success) {
        this.toastMessage('error', 'Masterbill alread exists for this vessel voyage', '');
      } else {
        this.mblNumbers = await this.api.getMBLNumbers(this.selectedVoyage.id);
        this.toastMessage('success', 'Masterbill Added', 'Masterbill  was sucessfully added');
        this.addMasterbillDialogVisible = false;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to add master bill. Please contact support', '');
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  public openFile(fileId: number) { window.open(`file-view?query=${fileId}`); }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}