<div class="ui-g-12 center" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && userTask && routes && routes.length > 0" class="ui-g card card-w-title">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 file-number-header">
      <h3>Check Border Post For </h3>
      <file-number [fileNumber]="userTask.dbnNumber"></file-number>
    </div>
  </div>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'200px'}">
      <p-header>
        Client Information
      </p-header>

      <div class="ui-g-4">
        <div class="information">
          <strong>First Name</strong>
          <label>
            {{ userTask.legalEntityFirstName || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Last Name</strong>
          <label>
            {{ userTask.legalEntityLastName || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Cell Phone</strong>
          <label>
            {{ userTask.legalEntityCellPhone || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-8">
        <div class="information">
          <strong>Route</strong>
          <label>
            {{ userTask.route || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Currently At</strong>
          <label>
            {{ userTask.currentlyAt || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="ui-g-12 user-task-text">
      <label>Border Post has been requested to change from whatsapp user. Requested border post:
        <strong>{{ userTask.borderPostToBe || 'N/A' }}</strong></label>
    </div>

    <div class="ui-g-12">
      <div class="information">
        <strong>Routes</strong>
        <p-dropdown [options]="routes" optionLabel="route" [style]="{'width':'100%'}" [filter]="true"
          [autoDisplayFirst]="false" [(ngModel)]="selectedRoute">
        </p-dropdown>
      </div>
    </div>
  </div>

  <div class="ui-g-12">
    <button pRipple pButton [disabled]="!selectedRoute" label="Change Route" (click)="changeRoute()"
      *ngIf="!postSpinner" class="width-100"></button>

    <button *ngIf="postSpinner" pRipple class="p-button-info submit width-100" pButton type="button" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</div>