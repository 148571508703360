import { LogBase } from './../../../services/logger.service';
import { WATemplateMessages, WAUnmatchedRefNo, WATemplate, Dropdown } from '../../../interfaces/global.interfaces';
import { WhatsappApiService } from '../../../services/api/whatsapp-api.service';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Table } from 'primeng/table';
import * as moment from 'moment';
import { MessageService } from 'primeng';
import { WhatsappProductService } from '../whatsapp-product/whatsapp-product.service';

@Component({
  selector: 'app-whatsapp-history',
  templateUrl: './whatsapp-history.component.html',
  styleUrls: ['./whatsapp-history.component.scss'],
})
export class WhatsappHistoryComponent {

  @ViewChild('dt') table: Table;
  @ViewChild('un') tableUn: Table;

  allTemplateMessages: WATemplateMessages[] = [];
  allTemplates: WATemplate[] = [];
  unmatchedReference: WAUnmatchedRefNo[] = [];
  unmatchedEditRow: WAUnmatchedRefNo;
  unmatchedRefLoading: boolean = false;
  unmatchedRefLoadingIcon: string = 'fas fa-sync';
  templateMessagesLoading: boolean = false;

  chatStatus: Dropdown[] = [
    { label: 'Open', value: 1 },
    { label: 'Timed Out', value: 2 },
    { label: 'Closed from a system user', value: 3 },
    { label: 'Closed from auto responder by whatsapp user', value: 4 },
    { label: 'Closed by the system', value: 5 },
    { label: 'Open but requested agent', value: 6 },
  ];

  constructor(
    private whatsappService: WhatsappApiService,
    private log: LogBase,
    private messageService: MessageService,
    public whatsappProductService: WhatsappProductService,
    private titleService: Title,
    private cd: ChangeDetectorRef
  ) {
    this.titleService.setTitle('WhatsApp History');
  }

  public async refresh() {
    this.templateMessagesLoading = true;
    this.unmatchedRefLoading = true;
    this.getAllTemplateMessages();
    this.getUnmatchedReferenceNumbers();
    await this.getTemplates();
  }

  public async getAllTemplateMessages() {
    this.templateMessagesLoading = true;
    try {
      this.allTemplateMessages = [];
      const resp = await this.whatsappService.WAGetAllTemplateMessages(this.whatsappProductService.selectedProduct.id);
      if (!resp) this.toastMessage('error', 'Failed to get whatsapp template messages. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.allTemplateMessages = resp.result;
    } catch (error) {
      this.log.error(error);
    }
    this.templateMessagesLoading = false;
  }

  private async getTemplates() {
    try {
      const resp = await this.whatsappService.WAGetAllTemplates(this.whatsappProductService.selectedProduct.id);
      if (!resp) this.toastMessage('error', 'Failed to get whatsapp templates. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.allTemplates = resp.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  public async getUnmatchedReferenceNumbers() {
    this.unmatchedRefLoading = true;
    try {
      this.unmatchedReference = [];
      const resp = await this.whatsappService.WAGetUnmatchedRefNumbers(this.whatsappProductService.selectedProduct.id);
      if (!resp) this.toastMessage('error', 'Failed to get whatsapp unmatched ref numbers. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.unmatchedReference = resp.result;
    } catch (error) {
      this.log.error(error);
    }
    this.unmatchedRefLoading = false;

  }

  public async updateChatRef(chat: WAUnmatchedRefNo) {
    try {
      const payload = {
        ChatId: chat.id,
        RefNo: chat.refNo
      };

      const resp = await this.whatsappService.WAUpdateChatRefNo(payload);
      if (!resp) {
        this.toastMessage('error', 'Failed to update chat reference number. Please contact support.', '');
        this.resetUnmatchRow(chat.id);
      } else if (resp.errorCode != 0) {
        this.toastMessage('error', resp.errorMessage, '');
        this.resetUnmatchRow(chat.id);
      }else {
        this.toastMessage('success', resp.errorMessage, '');
        await this.getUnmatchedReferenceNumbers();
      } 
    } catch (err) {
      this.log.error(err)
    }
  }

  public filterTemplates(event: any) {
    if (event.value) {
      this.table.filter(event.value.txt, 'txt', 'equals');
    } else {
      this.table.filter('', 'txt', 'any');
    }
  }

  public onFilterDate(table: string, date: any, propertyName: string) {
    this[table].filter(this.formatDate(date).toString(), propertyName, 'contains');
  }
  public editUnmatchedRow(row: WAUnmatchedRefNo) {
    this.unmatchedEditRow = { ...row };  
  }
  public resetUnmatchRow(chatId: number) {
    console.log(this.unmatchedEditRow, chatId, this.unmatchedReference)
    const chatIndex = this.unmatchedReference.findIndex(c => c.id == chatId);
    console.log(chatIndex)
    this.unmatchedReference[chatIndex] = { ...this.unmatchedEditRow };
    this.cd.detectChanges();
  }
  public formatDate(date: string) { return moment(new Date(date)).format('DD/MM/yy'); }
  public toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }) }
}