<div class="cargo">
  <div class="cargo-table-section">
    <div class="table">
      <p-table [columns]="loadCargoColumns" [value]="loadCargo" [lazy]="true" [loading]="loading">
        <ng-template pTemplate="caption">
          Cargo for Load: {{ loadName }}
          <button *ngIf="managerType == 1" pButton type="button" icon="pi pi-plus" label="Add Cargo"
            class="caption-add-cargo-button" (click)="addCargoAction()" [disabled]="checkIfLoadAddDisabled()"
            [pTooltip]="checkIfLoadAddDisabled() ? 'Load not in planning' : ''">
          </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ngStyle]="{'width': col.field == 'dbnNumber' ? '100px' : ''}">
              {{ col.header }}
            </th>
            <th style="width: 80px;">
              Actions
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
            <td class="action-button">
              <button *ngIf="managerType == 1" pButton icon="pi pi-trash"
                style="margin: 0 auto; display: block; background-color: tomato;" pTooltip="Remove cargo"
                tooltipPosition="left" (click)="removeCargoItem(rowData)"
                [disabled]="checkIfLoadAddDisabled()"></button>
              <button pTooltip="View {{ rowData.dbnNumber}}" tooltipPosition="left" pButton type="button"
                icon="far fa-folder" (click)="viewFile(rowData.dbnNumber)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-confirmDialog header="Remove from load" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
    <div class="information">
      <p-panel #editLoadPanel *ngIf="managerType == 1">
        <p-header class="prime-header">
          <span>Load Information</span>
          <button pButton [icon]="cargoInfoEdit ? 'pi pi-times' : 'pi pi-pencil'"
            [label]="cargoInfoEdit ? 'Cancel' : 'Edit'" (click)="toggleEdit()" [disabled]="checkIfLoadAddDisabled()"
            [pTooltip]="checkIfLoadAddDisabled() ? 'Load not in planning' : ''">
          </button>
        </p-header>
        <span>
          <label class="pad-left" for="float-input">Load Name</label>
          <input class="edit-input" type="text" pInputText [(ngModel)]="rowData.name" [disabled]="!cargoInfoEdit"
            [pTooltip]="checkIfLoadAddDisabled() ? 'Load not in planning' : ''">
        </span>
        <span>
          <label class="pad-left" for="float-input">Driver Name</label>
          <input class="edit-input" type="text" pInputText [(ngModel)]="rowData.driverName" [disabled]="!cargoInfoEdit"
            [pTooltip]="checkIfLoadAddDisabled() ? 'Load not in planning' : ''">
        </span>
        <span>
          <label class="pad-left" for="float-input">Estimated Departure</label>
          <p-calendar class="dateStyle" [(ngModel)]="selectedDate" [showTime]="true" inputId="time" appendTo="body"
            [style]="{'width': '100%'}" [disabled]="!cargoInfoEdit"
            [pTooltip]="checkIfLoadAddDisabled() ? 'Load not in planning' : ''">
          </p-calendar>
        </span>
        <span>
          <label class="pad-left" for="float-input">Transporter</label>
          <p-dropdown [options]="transporters" [(ngModel)]="selectedTransporter" optionLabel="label"
            [autoDisplayFirst]="false" [style]="{'width':'100%'}" [filter]="true" [showClear]="true" appendTo="body"
            [disabled]="!cargoInfoEdit">
          </p-dropdown>
        </span>
        <button pButton label="Save" *ngIf="cargoInfoEdit" (click)="editLoad()" [disabled]="checkIfLoadAddDisabled()"
          [pTooltip]="checkIfLoadAddDisabled() ? 'Load not in planning' : ''"></button>
      </p-panel>

      <p-panel #editCargoAssignment>
        <p-header class="prime-header">
          <span>Load Status</span>
          <button pButton [icon]="cargoLoadStatusEdit ? 'pi pi-times' : 'pi pi-pencil'"
            [disabled]="rowData.dispatchLoadStatusId === 1"
            [pTooltip]="rowData.dispatchLoadStatusId === 1 ? 'Cannot edit status because load is still in planning' : ''"
            [label]="cargoLoadStatusEdit ? 'Cancel' : 'Edit'"
            (click)="cargoLoadStatusEdit = !cargoLoadStatusEdit"></button>
        </p-header>
        <div class="ui-g-12">
          <span class="ui-float-label ui-g-12" *ngIf="managerType != 1">
            <p-dropdown *ngIf="rowData.cargoLoadAssignmentStatusId == 3 || rowData.cargoLoadAssignmentStatusId == 7"
              [autoDisplayFirst]="false" [options]="transit" optionLabel="status" optionValue="id" [showClear]="true"
              appendTo="body" [(ngModel)]="editedStatus" [style]="{'width': '100%'}" [disabled]="!cargoLoadStatusEdit">
            </p-dropdown>
            <label class="pad-left" for="float-input">Edit Load Status</label>
          </span>
          <span class="ui-float-label ui-g-12" *ngIf="managerType == 1">
            <p-dropdown [autoDisplayFirst]="false" [options]="assignStatus" optionLabel="status" optionValue="id"
              [showClear]="true" appendTo="body" ks [(ngModel)]="editedStatus" [style]="{'width': '100%'}"
              [disabled]="!cargoLoadStatusEdit">
            </p-dropdown>
            <label class="pad-left" for="float-input">Edit Load Status</label>
          </span>
        </div>
        <button pButton label="Change Status" (click)="ediCargoAssignmentStatus()"
          [disabled]="!cargoLoadStatusEdit"></button>
      </p-panel>

      <button *ngIf="managerType == 1" pButton
        [label]="selectedTransporter ? 'Reassign Transporter' : 'Assign Transporter'"
        (click)="assignLoadToTransporter()"></button>

      <button pButton icon="pi pi-trash" label="Delete Load" style="background-color: #ff6347"
        (click)="deleteLoad()"></button>
    </div>
  </div>
  <div class="cargo-search" *ngIf="addCargo">
    <h4>Search for Cargo to add to load: {{ loadName }}</h4>
    <app-filter-search *ngIf="loadType == 1" [ruleSetId]="0"></app-filter-search>
    <app-filter-search *ngIf="loadType == 2" [ruleSetId]="1"></app-filter-search>
    <button pButton type="button" icon="pi pi-plus" label="Add Selected Files" class="add-cargo-button"
      [disabled]="!filterDataService.selectedFiles || filterDataService.selectedFiles.length === 0"
      (click)="handleAddToLoad()"></button>
  </div>
</div>

<file-issues-pop-up [showDialog]="showDialog" [files]="filesWithIssues" [dialogHeader]="dialogHeader"
  [footer]="dialogFooter" (closeDialog)="showDialog = $event">
</file-issues-pop-up>