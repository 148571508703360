<div class="ui-g-12 card card-w-title">
  <div class="ui-g-2">
    <h1>Bulk Updater</h1>
  </div>
  <div class="ui-g-10 buttons">
    <p-splitButton #ub (onClick)="ub.onDropdownButtonClick(null)" class="paddedButtons" label="Options"
      icon="pi pi-bars" [model]="optionsItems" [menuStyle]="{ width: '400px' }"></p-splitButton>
  </div>
  <div class="ui-g-12">
    <p-panel header="FILTER :" [style]="{ height: '120px' }">
      <div class="ui-g-12">
        <div class="ui-g-9">
          <div class="ui-g-3">
            <label>Vessel/Voyage/MBL:</label>
          </div>
          <div class="ui-g-3">
            <p-dropdown [options]="vessels" [filter]="true" [(ngModel)]="selectedVessel" placeholder="Select a Vessel*"
              optionLabel="name" (onChange)="getVoyage(); updateOptionItems();" [autoDisplayFirst]="false">
            </p-dropdown>
          </div>
          <div class="ui-g-3">
            <p-dropdown [options]="voyages" [filter]="true" optionLabel="name" placeholder="Select a Voyage*"
              [autoDisplayFirst]="false" [(ngModel)]="selectedVoyage" (onChange)="getAssignedMBLNumbers();
              updateOptionItems();" [disabled]="!selectedVessel" [autoDisplayFirst]="false">
            </p-dropdown>
          </div>
          <div class="ui-g-3">
            <p-multiSelect [options]="assignedMBLNumbers" [(ngModel)]="selectedMBLNumbers" optionLabel="MBL"
              defaultLabel="Select MBL Number/s" [disabled]="!selectedVoyage">
            </p-multiSelect>
          </div>

        </div>
        <div class="ui-g-3">
          <button *ngIf="!showLoadingSpinner" style="width: 100%;" pRipple pButton [disabled]="!selectedVoyage"
            label="Filter" icon="fas fa-filter" (click)="filterByVessel()" class='paddedButtons'></button>

          <button *ngIf="showLoadingSpinner" style="width: 100%;" pRipple pButton disabled="true" class='paddedButtons'>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </button>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-12">
    <p-table #dt [loading]="showLoadingSpinner" [value]="files" [(selection)]="selectedFiles" dataKey="fileId"
      [showCurrentPageReport]="true" [scrollable]="true" [rows]="1000" scrollHeight="400px"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
      [globalFilterFields]="['dBNNumber','foreignReference','consignee','borderPost', 'portOfLoad', 'fileOwner']"
      [columns]="cols">
      <ng-template pTemplate="caption">
        <div class="p-d-flex" style="align-items: center;">
          Selected {{ selectedFiles ? selectedFiles.length : 0 }} of
          {{ files ? files.length : 0 }} files
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th width="25px">
            <p-tableHeaderCheckbox (click)="updateOptionItems()"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns" width="{{ col.width }}" pSortableColumn="{{ col.field }}">
            {{ col.header }}
            <p-sortIcon field="{{ col.field }}"> </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td width="25px">
            <p-tableCheckbox (click)="updateOptionItems()" [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" width="{{ col.width }}">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No file(s) found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Request ANF from Shipping Line" modal="true" [(visible)]="displayANFRequest"
  (onHide)="hideANDRequestDialog()" [style]="{width: '400px'}">
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="requestANFFromShippingLine()" label="Yes"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Update Files ETA" modal="true" [(visible)]="displayUpdateETAModal" width="500" height="500"
  (onHide)="cancelUpdateFilesETA()">
  <div class="ui-g-12">
    <span class="ui-g-12 ui-float-label">
      <p-calendar [(ngModel)]="newFilesETA" dataType="string" dateFormat="yy/mm/dd" [readonlyInput]="true"
        appendTo="body"></p-calendar>
      <label class="float-label" for="float-input">ETA *</label>
    </span>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="updateFilesETA()" [disabled]="!newFilesETA" label="Save"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Update SOB Date" modal="true" [(visible)]="displaySOBDateModal" width="500" height="500"
  (onHide)="cancelSOBDateModal()">
  <div class="ui-g-12">
    <span class="ui-g-12 ui-float-label">
      <p-calendar [(ngModel)]="SOBDate" dataType="string" dateFormat="yy/mm/dd" appendTo="body" [readonlyInput]="true">
      </p-calendar>
      <label class="float-label" for="float-input">SOB Date *</label>
    </span>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="updateFilesSOB()" [disabled]="!SOBDate" label="Save"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Update MBL Number & Send HBL's" modal="true" [(visible)]="displayMBLNumberModal"
  (onHide)="cancelMBLNumberModal()" [style]="{width: '650px'}">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>Update the MBL / Generate HBLs and email them to the Supplier</label>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-8 ui-float-label">
        <p-dropdown [filter]="true" [options]="mblNumbers" optionLabel="MBL" [(ngModel)]="MBLNumber" appendTo="body"
          [autoDisplayFirst]="false">
        </p-dropdown>
        <label class="float-label" for="float-input">MBL *</label>
      </span>
      <div class="ui-g-4">
        <button pRipple pButton class="p-button-info width-100" label="Add MBL Number" icon="fas fa-filter"
          (click)="addMBLNumber()"></button>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-3">
        <label>Send HBL Emails</label>
      </div>
      <div class="ui-g-9">
        <p-checkbox [(ngModel)]="sendHBLEmails" binary="false"></p-checkbox>
      </div>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="updateMBLNumber()" [disabled]="!MBLNumber" label="Save"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Transship {{ selectedFiles?.length }} file(s) from {{ selectedVessel?.name }} to" modal="true"
  (onHide)="hideTransShipmentDialog()" [(visible)]="displayTransShipmentModal" [style]="{width: '600px'}">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="vessels" [(ngModel)]="selectedTransShipVessel" [filter]="true" appendTo="body"
          [autoDisplayFirst]="false" optionLabel="name" [disabled]="showActionSpinner" class="width-100"
          (onChange)="getTransShipVoyages()">
        </p-dropdown>
        <label class="float-label" for="float-input">Vessel *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="transshipVoyages" optionLabel="name"
          [disabled]="!selectedTransShipVessel || showActionSpinner" [filter]="true" class="width-100" appendTo="body"
          [autoDisplayFirst]="false" [(ngModel)]="selectedTransShipVoyage"></p-dropdown>
        <label class="float-label" for="float-input">Voyage *</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="updateTransShipment()" label="Save"
      [disabled]="!selectedTransShipVessel || !selectedTransShipVoyage"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Update Courier Details" modal="true" [(visible)]="displayCourierReceivedModal"
  (onHide)="cancelcourierReceivedModal()" [style]="{width: '550px'}">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="courierCompanies" [filter]="true" [(ngModel)]="courierCompany" optionLabel="label"
          [autoDisplayFirst]="false" appendTo="body">
        </p-dropdown>
        <label class="float-label" for="float-input">Courier Company *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-calendar [(ngModel)]="courierReceivedDate" dataType="string" dateFormat="yy/mm/dd" appendTo="body"
          [readonlyInput]="true">
        </p-calendar>
        <label class="float-label" for="float-input">Date *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="trackingNumber" class="width-100" />
        <label class="float-label" for="float-input">Tracking Number *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-5">
        <label>Set Original Docs Received?</label>
      </div>
      <div class="ui-g-7">
        <p-checkbox [(ngModel)]="originalDocsReceived" binary="false"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-5">
        <label>Send Notification Emails</label>
      </div>
      <div class="ui-g-7">
        <p-checkbox [(ngModel)]="sendTrackingNumberEmails" binary="false"></p-checkbox>
      </div>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100"
      [disabled]="!courierReceivedDate || !trackingNumber || !courierCompany" pButton type="button"
      (click)="updateCourierReceived()" label="Update"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Assign Border Agent" modal="true" [(visible)]="displayAssignBorderAgent"
  [style]="{width: '30vw', height: '20vh'}">
  <div class="ui-g-12">
    <span class="ui-g-12 ui-float-label">
      <p-dropdown class="full-width" id="float-input" [options]="borderAgents" [(ngModel)]="selectedBorderAgent"
        [autoDisplayFirst]="false" [filter]="true" appendTo="body">
      </p-dropdown>
      <label class="float-label" for="float-input">Border Agent *</label>
    </span>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="assignBorderAgent()" label="Save" [disabled]="!selectedBorderAgent"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Bulk Add Note" [style]="{width: '50vw'}" [dismissableMask]="true" [(visible)]="displayNote"
  [responsive]="true" showEffect="fade" [modal]="true" (onHide)="ResetDialogValues()">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown class="full-width" [options]="noteTypes" [(ngModel)]="selectedNoteType" (onChange)="setupNote()"
          [autoDisplayFirst]="false" appendTo="body" [filter]="true" [showClear]="true"></p-dropdown>
        <label class="float-label" for="float-input">Note Type *</label>
      </span>
    </div>
    <div class="ui-g-12 centerInDiv">
      <textarea pInputTextarea autoResize="autoResize" class="width-100" style="height: 150px; resize: none;"
        [disabled]="!selectedNoteType" [(ngModel)]="noteData"></textarea>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!showActionSpinner" pRipple class="p-button-info width-100"
      [disabled]="!noteData || !selectedNoteType" label="Save" type="button" (click)="bulkSaveNote()" pButton
      icon="fas fa-plus"></button>
    <button *ngIf="showActionSpinner" pRipple class="submit p-button-info width-100" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<file-issues-pop-up [showDialog]="showFilesWithIssuesDialog" [files]="filesWithIssues" [dialogHeader]="dialogHeader"
  [footer]="dialogFooter" (closeDialog)="showFilesWithIssuesDialog = $event">
</file-issues-pop-up>