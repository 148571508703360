import { Component, OnInit } from '@angular/core';

import { version } from '../../../../package.json';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public version: string = version;
  public year: number = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}
