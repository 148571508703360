import { Injectable } from '@angular/core';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";
import { HttpResponse } from '../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class EmailApiService {

  private serverUrl = `${this.config.getServerUrl()}api/Emails/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  public async sendFreeTextEmail(toEmail: string, fromEmail: string, body: string, subject: string, fileId: number, contactId: number): Promise<HttpResponse | null> {
    try {
      const payload = {
        body: body,
        subject: subject,
        fromEmail: fromEmail,
        toEmail: toEmail,
        fileId: fileId,
        legalEntityId: contactId,
      } as any;
      const url = `${this.serverUrl}sendFreeTextEmail`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async resendEmail(emailQueueId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}resendEmail?emailQueueId=${emailQueueId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}