import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { LogBase } from './../../../services/logger.service';
import { Title } from "@angular/platform-browser";
import { Dropdown, TableColumns } from '../../../interfaces/global.interfaces';
import { ReferenceApiService } from '../../../services/api/reference-api.service';

@Component({
  selector: 'app-document-category',
  templateUrl: './document-category.component.html',
  styleUrls: ['./document-category.component.css'],
  providers: [ConfirmationService]
})

export class ManageDocumentCategoryComponent implements OnInit {

  itemCols: TableColumns[] = [
    { field: 'documentCategory', header: 'Document Category' },
    { field: 'path', header: 'Folder Name' },
    { field: 'show', header: 'Status' },
  ];

  showSaveSpinner: boolean = false;
  displayDialog: boolean = false;
  documentCategory: any = [];
  showLoadingSpinner: boolean = false;

  newdocumentcategory: string = '';
  newpath: string = '';
  showChecked: boolean = true;

  documentStatusOptions: Dropdown[] = [
    {
      value: 1,
      label: 'Active'
    },
    {
      value: 0,
      label: 'Disabled'
    }
  ];

  constructor(
    public api: ApiService,
    private referenceApiService: ReferenceApiService,
    private messageService: MessageService,
    private log: LogBase,
    private titleService: Title,
  ) {
    this.titleService.setTitle("AVECS Document Category");
  }

  async ngOnInit() {
    this.showLoadingSpinner = true;
    await this.getDocumentCategories();
    this.showLoadingSpinner = false;
  }

  async getDocumentCategories() {
    try {
      const res = await this.referenceApiService.getDocumentCategory();
      if (!res) this.toastMessage('error', 'Failed to get document categories. Please contact support.', '');
      else if (res.errorCode !== 0) this.toastMessage('error', res.errorMessage, '');
      else this.documentCategory = res.result;
    } catch (error) {
      this.log.error(error);
      this.toastMessage('error', 'Failed to get document categories. Please contact support.', '');
    }
  };

  async updateDocumentCategory(rowData: any) {
    try {
      const retval = await this.referenceApiService.updateDocumentCategory(rowData.id, rowData.documentCategory);
      if (!retval) this.toastMessage('error', 'Failed to update document category. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.toastMessage('success', retval.errorMessage, '');
    } catch (error) {
      this.log.error(error);
    }
  }

  addDocumentCategory() {
    try {
      this.clearAddDocumentCategoryFields();
      this.displayDialog = true;
    } catch (error) {
      this.log.error(error);
    }
  }

  clearAddDocumentCategoryFields() {
    this.newdocumentcategory = '';
    this.newpath = '';
    this.showChecked = true;
  }

  cancelDocumentCategory() {
    try {
      this.clearAddDocumentCategoryFields();
      this.displayDialog = false;
    } catch (error) {
      this.log.error(error);
    }
  }

  async saveDocumentCategory() {
    try {
      this.showLoadingSpinner = true;
      const retval = await this.api.addDocumentCategory(this.newdocumentcategory, this.newpath, true);

      if (retval == true) {
        this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'New Document Category Saved.' });
        this.displayDialog = false;
        this.clearAddDocumentCategoryFields();
        await this.getDocumentCategories();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error Saving New Document Category.' });
        this.displayDialog = false;
      }
      this.showLoadingSpinner = false;
    } catch (error) {
      this.log.error(error);
    }
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}