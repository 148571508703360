import { Injectable } from '@angular/core';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";
import { HttpResponse } from '../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class BulkUpdaterApiService {

  private serverUrl = `${this.config.getServerUrl()}api/bulkUpdater/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  public async bulkUpdateFilesETA(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}bulkUpdateFilesETA`;
      return this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async bulkUpdateMBL(payload: any, sendHBLEmails: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}bulkUpdateMBL?sendHBLEmails=${sendHBLEmails}`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async bulkUpdateFilesCourierReceivedInfo(payload: any, sendTrackingNumberEmails: boolean, originalDocsReceived: boolean): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}BulkUpdateFilesCourierReceivedInfo?SendTrackingNumberEmails=${sendTrackingNumberEmails}&OriginalDocsReceived=${originalDocsReceived}`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async bulkUpdateBorderAgent(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}bulkUpdateBorderAgent`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async bulkUpdateAddFileNotes(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}BulkUpdateAddFileNotes`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  public async bulkUpdateTransShipVoyage(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}bulkUpdateTransShipVoyage`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async bulkUpdateFilesSOB(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}bulkUpdateFilesSOB`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }
}