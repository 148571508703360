<summary-dashboard></summary-dashboard>

<div class="ui-g-12 card card-w-title">
  <div class="ui-g-12">
    <p-panel [style]="{'height':'100px'}">
      <p-header>
        Filter
      </p-header>
      <div class="ui-g-1">
        <label>Start Date:</label>
      </div>
      <div class="ui-g-2">
        <p-calendar [(ngModel)]="StartDate" view="month" dateFormat="yy/mm/dd" [readonlyInput]="true">
        </p-calendar>
      </div>
      <div class="ui-g-1">
        <label>End Date:</label>
      </div>
      <div class="ui-g-2">
        <p-calendar [(ngModel)]="EndDate" view="month" dateFormat="yy/mm/dd" [readonlyInput]="true">
        </p-calendar>
      </div>
      <div class="ui-g-2">
        <button *ngIf="!showSpinner" pButton type="button" label="Search" class="p-button-info width-100"
          (click)="getMonthlyCarMovementStats()" style="color: coral;"></button>
        <button *ngIf="showSpinner" [disabled]="true" pRipple class="p-button-info width-100" pButton type="button">
          <i class="pi pi-spin pi-spinner font-size-2em"></i>
        </button>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-12">
    <p-table [loading]="showSpinner" [value]=" monthlyStats" [autoLayout]="true" [resizableColumns]="true">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <label>Monthly Files Stats</label>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="col" style="border: 1px solid lightgrey;">File Type</th>
          <th *ngFor="let item of monthlyStats[0]?.files">{{ item.yearSelected }} {{ item.monthName }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr *ngIf="item.statName == 'CL'">
          <th class="col" style="border: 1px solid lightgrey;">
            {{item.statName}}</th>
          <td *ngFor="let stat of monthlyStats[0]?.files">{{ stat.amount }}</td>
        </tr>
        <tr *ngIf="item.statName == 'RE'">
          <th class="col" style="border: 1px solid lightgrey;">
            {{item.statName}}</th>
          <td *ngFor="let stat of monthlyStats[1]?.files">{{ stat.amount }}</td>
        </tr>
        <tr *ngIf="item.statName == 'PH'">
          <th class="col" style="border: 1px solid lightgrey;">
            {{item.statName}}</th>
          <td *ngFor="let stat of monthlyStats[2]?.files">{{ stat.amount }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g-12" *ngIf="!showSpinner">
    <p-panel class="ui-g-12">
      <p-header>
        Visual Representation
      </p-header>
      <p-chart #chart type="bar" [data]="data" [height]="350"></p-chart>
    </p-panel>
  </div>
</div>