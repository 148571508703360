<div class="ui-g-12 center" *ngIf="showSpinner || !contactDetailsForm || !contactForm">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>
<div *ngIf="!showSpinner && contactForm" class="ui-g card card-w-title">
  <div class="ui-g-12">
    <div class="header">
      <h1>{{checkLegalEntityTypeForHeader()}}</h1>
      <p-splitButton *ngIf="contactId" #ub (onClick)="ub.onDropdownButtonClick(null)" id="generate"
        class="drop-down-button-left-margin" [menuStyleClass]="menuItems" label="Options" icon="pi pi-gear"
        [model]="setupOptions()"></p-splitButton>
    </div>
    <p-tabView>
      <p-tabPanel
        *ngIf="legalEntityTypeId == legalEntityType.COMPANY && contactId && contactDetailsForm && contactDetailsForm.value"
        header="Staff Members" leftIcon="fas fa-users">
        <div class="ui-g-12">
          <p-table #dt [value]="companyStaffMembers" [rows]="30" [paginator]="true" [autoLayout]="true"
            selectionmode="single" [globalFilterFields]="['fullName', 'role', 'email', 'telephone', 'cellphone']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left">
                  <button pRipple class="p-button-info ui-button-success" type="button"
                    (click)="addStaffMemberDialog = true;" pButton pTooltip="Add Staff Member"
                    icon="fas fa-plus"></button>
                </span>
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search contact(s)" style="color: #fff;" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="fullName">
                  Full Name
                  <p-sortIcon field="fullName"></p-sortIcon>
                </th>
                <th pSortableColumn="role">
                  Role
                  <p-sortIcon field="role"></p-sortIcon>
                </th>
                <th pSortableColumn="email">
                  Email
                  <p-sortIcon field="email"></p-sortIcon>
                </th>
                <th pSortableColumn="cellphone">
                  Cellphone
                  <p-sortIcon field="cellphone"></p-sortIcon>
                </th>
                <th>Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contact>
              <tr>
                <td>{{contact.fullName}}</td>
                <td>{{contact.role}}</td>
                <td>{{contact.email}}</td>
                <td>{{contact.cellphone}}</td>
                <td>
                  <button pRipple pButton class="p-button-info" pTooltip="View contact" icon="far fa-address-card"
                    tooltipPosition="left" (click)="viewContact(contact.legalEntityId)">
                  </button>
                  <button pRipple pButton class="p-button-info margin-left-5px" pTooltip="Edit staff member roles"
                    icon="far fa-edit" tooltipPosition="left" (click)="editStaffMember(contact)">
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>

      <p-tabPanel [disabled]="hideDetails" header="Details" leftIcon="far fa-address-card">
        <form *ngIf="contactForm" [formGroup]="contactDetailsForm" (ngSubmit)="onSubmit(contactDetailsForm.value)">
          <p-fieldset *ngIf="legalEntityTypeId == 1" legend="Person Information" class="ui-g-12">
            <div class="ui-g-12">
              <span class="ui-g-4 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="name" />
                <label class="pad-left" for="float-input">First Name *</label>
              </span>
              <span class="ui-g-4 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="middleNames" />
                <label class="pad-left" for="float-input">Middle Name(s)</label>
              </span>
              <span class="ui-g-4 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="lastName" />
                <label class="pad-left" for="float-input">Last Name *</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-4 ui-float-label">
                <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="titles"
                  formControlName="titleId">
                </p-dropdown>
                <label class="pad-left" for="float-input">Title *</label>
              </span>
              <div class="ui-g-3">
                <label class="full-width boldLabel">VIP</label>
              </div>
              <div class="ui-g-1">
                <p-checkbox formControlName="vip" binary="true"></p-checkbox>
              </div>
            </div>
          </p-fieldset>
          <p-fieldset *ngIf="legalEntityTypeId == 2" legend="Company Information" class="ui-g-12">
            <div class="ui-g-12">
              <span class="ui-g-3 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="name" />
                <label class="pad-left" for="float-input">Name *</label>
              </span>
              <span class="ui-g-3 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText
                  formControlName="registrationNumber" />
                <label class="pad-left" for="float-input">Registration Number</label>
              </span>
              <span class="ui-g-3 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="vatNumber" />
                <label class="pad-left" for="float-input">Vat Number</label>
              </span>
              <span class="ui-g-3 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="customsCode" />
                <label class="pad-left" for="float-input">Customs Code</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-3 ui-float-label">
                <p-multiSelect class="full-width" id="float-input" [filter]="true" [options]="companyTypes"
                  optionLabel="companyType" defaultLabel=" " formControlName="companyTypes">
                </p-multiSelect>
                <label class="pad-left" for="float-input">Company Types *</label>
              </span>
              <div class="ui-g-2">
                <label class="full-width boldLabel">VIP</label>
              </div>
              <div class="ui-g-1">
                <p-checkbox formControlName="vip" binary="true"></p-checkbox>
              </div>
            </div>
          </p-fieldset>
          <p-fieldset legend="Physical Address" class="ui-g-6">
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="address1" />
                <label class="pad-left" for="float-input">Address 1 *</label>
              </span>
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="city" />
                <label class="pad-left" for="float-input">City *</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="address2" />
                <label class="pad-left" for="float-input">Address 2 *</label>
              </span>
              <span class="ui-g-6 ui-float-label">
                <p-dropdown class="full-width" id="float-input" [autoDisplayFirst]="false" [options]="countries"
                  formControlName="countryId" [filter]="true">
                </p-dropdown>
                <label class="pad-left" for="float-input">Country *</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="address3" />
                <label class="pad-left" for="float-input">Address 3</label>
              </span>
            </div>
          </p-fieldset>
          <p-fieldset legend="Postal Address" class="ui-g-6">
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="postalAddress1" />
                <label class="pad-left" for="float-input">Postal Address 1</label>
              </span>
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" max="10" pInputText
                  formControlName="postalCode" />
                <label class="pad-left" for="float-input">Postal Code</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="postalAddress2" />
                <label class="pad-left" for="float-input">Postal Address 2</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="postalAddress3" />
                <label class="pad-left" for="float-input">Postal Address 3</label>
              </span>
              <span class="ui-g-6 ui-float-label standardHeight">
                <div class="ui-g-10 standardHeight">
                  <label class="full-width boldLabel">Same as physical</label>
                </div>
                <div class="ui-g-2">
                  <p-checkbox formControlName="sameAsPhysical" (click)="setPostalSameAsPhysical()" binary="true">
                  </p-checkbox>
                </div>
              </span>
            </div>
          </p-fieldset>
          <p-fieldset legend="Contact Information" class="ui-g-12">
            <div class="ui-g-12 cell-numbers">
              <div class="ui-g-6 ui-g-nopad">
                <span class="ui-g-5 ui-float-label">
                  <p-dropdown [filter]="true" [options]="countryCodes" appendTo="body"
                    formControlName="cellPhoneDialingCode" [autoDisplayFirst]="false" optionLabel="display"
                    [showClear]="true" (onChange)="onDialingCodeSelect('cellPhone', 'cellPhoneDialingCode')" [style]="{
                    'padding-bottom': '30px', 'background-image': setDialingCodeColour('cellPhone',
                    'cellPhoneDialingCode')}">
                  </p-dropdown>
                  <label class="pad-left" for="float-input">Dialing Code 1</label>
                </span>
                <div class="ui-g-7 dialing-code">
                  <div class="ui-g-1">
                    <label
                      [ngStyle]="{ 'color': showSaveSpinner ? 'lightgrey' : 'black'}">{{ getDialingCode('cellPhoneDialingCode') }}</label>
                  </div>
                  <span class="ui-g-11 ui-float-label">
                    <p-inputMask id="float-input" formControlName="cellPhone" pInputText tooltipPosition="left"
                      [pTooltip]="!contactDetailsForm.value.cellPhoneDialingCode ? 'Please select a dialing code 1' : ''"
                      [mask]="cellPhoneMask" [autoClear]="false" class="cell-number" slotChar=""
                      [style]="{width: '100%', background: 'none'}"></p-inputMask>
                    <label class="pad-left" for="float-input">Cell Phone 1</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-6 ui-g-nopad">
                <span class="ui-g-5 ui-float-label">
                  <p-dropdown [filter]="true" [options]="countryCodes" appendTo="body"
                    formControlName="cellPhone2DialingCode" [autoDisplayFirst]="false" optionLabel="display"
                    [showClear]="true" (onChange)="onDialingCodeSelect('cellPhone2', 'cellPhone2DialingCode')" [style]="{'padding-bottom': '30px', 'background-image': setDialingCodeColour('cellPhone2',
                    'cellPhone2DialingCode')}">
                  </p-dropdown>
                  <label class="pad-left" for="float-input">Dialing Code 2</label>
                </span>
                <div class="ui-g-7 dialing-code">
                  <div class="ui-g-1">
                    <label
                      [ngStyle]="{ 'color': showSaveSpinner ? 'lightgrey' : 'black'}">{{ getDialingCode('cellPhone2DialingCode') }}</label>
                  </div>
                  <span class="ui-g-11 ui-float-label">
                    <p-inputMask id="float-input" formControlName="cellPhone2" pInputText tooltipPosition="left"
                      [pTooltip]="!contactDetailsForm.value.cellPhone2DialingCode ? 'Please select a dialing code 2' : ''"
                      [mask]="cellPhoneMask" [autoClear]="false" class="cell-number" slotChar=""
                      [style]="{width:'100%', background: 'none'}"></p-inputMask>
                    <label class="pad-left" for="float-input">Cell Phone 2</label>
                  </span>
                </div>
              </div>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="telephone1" />
                <label class="pad-left" for="float-input">Telephone 1</label>
              </span>
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="telephone2" />
                <label class="pad-left" for="float-input">Telephone 2</label>
              </span>
            </div>
            <div class="ui-g-12">
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="email" />
                <label class="pad-left" for="float-input">Email 1 *</label>
              </span>
              <span class="ui-g-6 ui-float-label">
                <input class="full-width" id="float-input" type="text" pInputText formControlName="email2" />
                <label class="pad-left" for="float-input">Email 2</label>
              </span>
            </div>
          </p-fieldset>
          <div class="ui-g-12 actions">
            <button pRipple class="p-button-info" class="width-100" *ngIf="!showSaveSpinner" pButton type="submit"
              label="Save" [disabled]="canSubmitContactDetailsForm()"></button>
            <button *ngIf="showSaveSpinner" pRipple class="p-button-info width-100" pButton type="button"
              [disabled]="true">
              <i class="pi pi-spin pi-spinner font-size-2em"></i>
            </button>
          </div>
        </form>
      </p-tabPanel>

      <p-tabPanel *ngIf="contactId" [disabled]="hideDetails" header="Notes" leftIcon="fas fa-book-open">
        <app-contact-notes [LegalEntityId]="contactId"></app-contact-notes>
      </p-tabPanel>

      <p-tabPanel *ngIf="legalEntityTypeId == legalEntityType.PERSON && contactId" header="Correspondence"
        leftIcon="far fa-comments">
        <app-correspondence [legalEntityId]="contactId"></app-correspondence>
      </p-tabPanel>

      <p-tabPanel *ngIf="legalEntityTypeId == legalEntityType.PERSON && contactId" header="Permission Access"
        [disabled]="!userAdmin" leftIcon="fas fa-key">
        <div class="ui-g-12">
          <p-tabView>
            <p-tabPanel header="File Permissions">
              <div class="ui-g-12">
                <p-table #fp [value]="filePermissions" [rows]="30" [paginator]="true" [autoLayout]="true"
                  [(selection)]="selectedPermission" [globalFilterFields]="['DBNNumber', 'Group']">
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <span class="p-input-icon-left">
                        <button pButton type="button" icon="fas fa-plus" class="p-button-info ui-button-success"
                          (click)="addNewPermission()" pTooltip="Add Permission"></button>
                      </span>
                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="fp.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search file(s)" style="color: #fff;" />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate=" caption">
                    <div class="p-d-flex p-ai-center p-jc-between">Permissions for Files
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th>DBNNumber</th>
                      <th>Permission</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-permission>
                    <tr>
                      <td>{{ permission.DBNNumber }}</td>
                      <td>{{ permission.Group }}</td>
                      <td>{{ permission.Active ? 'Yes' : 'No' }}</td>
                      <td>
                        <button pRipple class="p-button-info" type="button" (click)="editFilePermission(permission)"
                          pTooltip="Edit permission" pButton icon="far fa-edit"></button>
                        <button pRipple pButton class="p-button-info margin-left-5px" pTooltip="Delete Permission"
                          icon="fas fa-trash"
                          (click)="selectedPermission =  permission ; confirmDeletePermission = true">
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-tabPanel>
            <p-tabPanel header="System Access" [disabled]="!systemAdmin">
              <div *ngIf="systemAdmin && contactId">
                <form class="form-horizontal" [formGroup]="passwordForm" (ngSubmit)="saveUser($event)">
                  <p-fieldset [legend]="systemAccessHeader()">
                    <div class="ui-g-12">
                      <span class="ui-g-3 ui-float-label">
                        <input type="text" pInputText class="form-control full-width" id="username"
                          [formGroup]="passwordForm" formControlName="username">
                        <label class="pad-left" for="float-input">Username *</label>
                      </span>
                      <span class="ui-g-3 ui-float-label">
                        <input type="text" pInputText class="form-control full-width" id="password"
                          [formGroup]="passwordForm" formControlName="password">
                        <label class="pad-left" for="float-input">Password *</label>
                      </span>
                      <span class="ui-g-3 ui-float-label">
                        <input type="password" pInputText class="form-control full-width"
                          [pTooltip]="setPasswordMatchToolTip()" id="confirmPassword" [formGroup]="passwordForm"
                          formControlName="confirmPassword">
                        <label class="pad-left" for="float-input">Confirm Password *</label>
                      </span>
                      <div class="ui-g-3" *ngIf="disableUserName">
                        <p-checkbox id="systemAccess" binary="true" label="Active" formControlName="systemAccess"
                          value="systemAccess" [formGroup]="passwordForm" inputId="active"></p-checkbox>
                      </div>
                      <div class="ui-g-12">
                        <button *ngIf="!systemAccessSpinner" pRipple pButton
                          class="btn btn-primary p-button-info full-width" id="submit-userAccess" type="Save"
                          label="Save" [disabled]="!passwordForm.valid">
                        </button>
                        <button *ngIf="systemAccessSpinner" class="p-button-info full-width" type="button" pButton>
                          <i class="pi pi-spin pi-spinner font-size-2em"></i>
                        </button>
                      </div>
                    </div>
                  </p-fieldset>
                </form>
              </div>
              <p-fieldset legend="User Group Access" *ngIf="hasActiveUserAccount && contactId">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <p-pickList [source]="otherPermissions" [target]="permissions" sourceHeader="Available"
                      (onMoveToTarget)="saveUserPermissions($event.items)" [showSourceControls]="false"
                      [showTargetControls]="false" (onMoveToSource)="removeUserPermissions($event.items)"
                      targetHeader="Selected" [responsive]="true" dragdrop="true" dragdropScope="permission"
                      [sourceStyle]="{'height':'250px'}" [targetStyle]="{'height':'250px'}">
                      <ng-template let-permission pTemplate="item">
                        <div class="ui-helper-clearfix">
                          <div style="font-size:14px;float:right;margin:5px 5px 0 0">
                            {{ permission.group }}
                          </div>
                        </div>
                      </ng-template>
                    </p-pickList>
                  </div>
                </div>
              </p-fieldset>
            </p-tabPanel>
          </p-tabView>
        </div>
      </p-tabPanel>

      <p-tabPanel *ngIf="legalEntityTypeId == legalEntityType.PERSON && contactId" header="Files"
        leftIcon="fas fa-file">
        <div class="ui-g-12">
          <p-table #fi [value]="files" [rows]="15" [paginator]="true" [autoLayout]="true"
            [globalFilterFields]="['dbnNumber', 'foreignReference']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="fi.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search file(s)" style="color: #fff;" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="dbnNumber">
                  DBN Number
                  <p-sortIcon field="dbnNumber"></p-sortIcon>
                </th>
                <th pSortableColumn="foreignReference">
                  Reference
                  <p-sortIcon field="foreignReference"></p-sortIcon>
                </th>
                <th pSortableColumn="created">
                  Created
                  <p-sortIcon field="created"></p-sortIcon>
                </th>
                <th>Completed</th>
                <th>Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
              <tr>
                <td>{{ file.dbnNumber }}</td>
                <td>{{ file.foreignReference }}</td>
                <td>{{ file.created }}</td>
                <td>{{ file.complete ? 'Yes' : 'No' }}</td>
                <td><button pRipple class="p-button-info" type="button" (click)="goToFile(file)" pButton
                    pTooltip="View file" icon="fas fa-book"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No files found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<!-- Permissions -->

<p-dialog header="Edit Permission" [style]="{width: '30vw'}" [dismissableMask]="true" [draggable]="false"
  [resizable]="false" [responsive]="true" [(visible)]="editPermission" showEffect="fade" [modal]="true">
  <div class="ui-g">
    <div class="ui-g-3">
      <label>DBNNumber:</label>
    </div>
    <div class="ui-g-9">
      <label>{{selectedPermission.DBNNumber}}</label>
    </div>
    <div class="ui-g-3">
      <label>Active:</label>
    </div>
    <div class="ui-g-9">
      <p-checkbox [(ngModel)]="selectedPermission.Active" binary="true" [disabled]="permissionsSpinner"></p-checkbox>
    </div>
  </div>
  <div class="ui-g-12 permission-buttons">
    <button pRipple class="p-button-info width-50" label="Cancel" type="button" (click)="editPermission = false"
      [disabled]="permissionsSpinner" pButton></button>
    <button *ngIf="!permissionsSpinner" pRipple class="p-button-info width-50" label="Upate Permission" pButton
      type="button" (click)="updateUserFilePermission()"></button>
    <button *ngIf="permissionsSpinner" pRipple class="p-button-info width-50" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</p-dialog>

<p-dialog header="Add Permission" [style]="{width: '30vw'}" [draggable]="false" [dismissableMask]="true"
  [(visible)]="addPermission" [responsive]="true" showEffect="fade" [modal]="true">
  <div class="ui-g">
    <div class="ui-g-5">
      <label>FileId:</label>
    </div>
    <div class="ui-g-7">
      <input type="text" class="width-100" [(ngModel)]="addPermissionPayload.fileId" pInputText>
    </div>
    <div class="ui-g-5">
      <label>Group:</label>
    </div>
    <div class="ui-g-7">
      <p-dropdown [filter]="true" [options]="groups" appendTo="body" [(ngModel)]="addPermissionPayload.groupId"
        [autoDisplayFirst]="false"></p-dropdown>
    </div>

    <div class="ui-g-5">
      <label>Notify Contact about File Access?</label>
    </div>
    <div class="ui-g-7">
      <p-checkbox [(ngModel)]="addPermissionPayload.notifyContactAboutFile" binary="true"></p-checkbox>
    </div>

    <div class="ui-g-12 permission-buttons">
      <button pRipple class="p-button-info width-50" label="Cancel" type="button width-50"
        (click)="addPermission = false" pButton>
      </button>

      <button *ngIf="!permissionsSpinner" pRipple class="p-button-info width-50" label="Add Permission" type="button"
        (click)="addUserFilePermission()" pButton></button>

      <button *ngIf="permissionsSpinner" pRipple class="p-button-info width-50" pButton type="button">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Confirm?" [style]="{width: '25w'}" [draggable]="false" [dismissableMask]="true"
  [(visible)]="confirmDeletePermission" [responsive]="true" showEffect="fade" [modal]="true">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>Delete <strong>{{ selectedPermission.DBNNumber }}</strong> File Permission?</label>
    </div>
    <div class="ui-g-12 permission-buttons">
      <button pRipple class="p-button-info width-50" label="Cancel" type="button"
        (click)="confirmDeletePermission = false" [disabled]="permissionsSpinner" pButton></button>
      <button *ngIf="!permissionsSpinner" pRipple class="p-button-info width-50" label="Delete" type="button"
        (click)="deletePermission()" pButton></button>
      <button *ngIf="permissionsSpinner" class="p-button-info width-50" label="Delete" type="button" pButton>
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </div>
  </div>
</p-dialog>
<!-- Permissions -->


<!-- Edit Company -->
<p-dialog header="Add Staff Member" [style]="{width: '42vw'}" [dismissableMask]="false" [draggable]="false"
  [(visible)]="addStaffMemberDialog" [responsive]="true" showEffect="fade" [modal]="true">
  <div class="ui-g-12">

    <span class="ui-g-6 ui-float-label">
      <p-dropdown class="width-50" id="float-input" [filter]="true" [showClear]="true" appendTo="body"
        [options]="legalEntityRoles" [autoDisplayFirst]="false" [(ngModel)]="selectedRole" optionLabel="role">
      </p-dropdown>
      <label class="pad-left" for="float-input">Role</label>
    </span>

    <span class="ui-g-6 ui-float-label">
      <p-autoComplete [style]="{width: '100%'}" minLength="2" appendTo="body" [(ngModel)]="selectedAddStaffMember"
        [disabled]="!selectedRole" [suggestions]="people" (completeMethod)="searchPerson($event)" [minLength]="2"
        placeholder="Search People" field="person" [multiple]="false" delay="500" autoHighlight="true">
      </p-autoComplete>
    </span>

    <div class="ui-g-8">
      <label>Do you want to grant this user Portal Access?</label>
    </div>
    <div class="ui-g-4">
      <p-checkbox [(ngModel)]="grantPortalUserAccess" binary="true"></p-checkbox>
    </div>

    <div class="ui-g-12 permission-buttons">
      <button [disabled]="removeStaffMemberSpinner" pRipple class="p-button-info width-50" label="Close" type="button"
        (click)="addStaffMemberDialog = false" pButton></button>
      <button *ngIf="!removeStaffMemberSpinner" pRipple class="p-button-info width-50" label="Add Staff Member"
        [disabled]="!selectedRole || !selectedAddStaffMember" (click)="addStaffMember()" type="button" pButton></button>
      <button *ngIf="removeStaffMemberSpinner" pRipple class="p-button-info width-50" pButton type="button">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog *ngIf="selectedStaffMember" header="Edit Staff Member" [style]="{width: '25vw'}" [dismissableMask]="false"
  [draggable]="false" [(visible)]="staffMemberDialog" [responsive]="true" showEffect="fade" [modal]="true">
  <div class="ui-g-12">
    <span class="ui-g-12 ui-float-label margin-bottom-25px">
      <input type="text" [(ngModel)]="selectedStaffMember.fullName" [disabled]="true" class="full-width" pInputText>
      <label class="pad-left" for="float-input">Legal Entity Full Name</label>
    </span>

    <span class="ui-g-12 ui-float-label">
      <p-multiSelect id="float-input" [filter]="true" [(ngModel)]="selectedStaffMember.roles"
        [disabled]="removeStaffMemberSpinner" appendTo="body" optionLabel="role" [options]="legalEntityRoles"
        defaultLabel=" ">
      </p-multiSelect>
      <label class="pad-left" for="float-input">Roles *</label>
    </span>
  </div>

  <div class="ui-g-12 permission-buttons">
    <button *ngIf="!removeStaffMemberSpinner" pRipple class="p-button-info width-50" label="Remove Staff" type="button"
      [disabled]="editStaffMemberSpinner" (click)="removeStaffMember()" pButton icon="fas fa-times-circle"></button>
    <button *ngIf="removeStaffMemberSpinner" pRipple class="p-button-info width-50" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
    <button *ngIf="!editStaffMemberSpinner" pRipple class="p-button-info width-50" label="Edit Staff" type="button"
      (click)="editLegalEntityCompanyRoles()" pButton
      [disabled]="removeStaffMemberSpinner || !selectedStaffMember.roles || selectedStaffMember.roles.length == 0"
      icon="far fa-save"></button>
    <button *ngIf="editStaffMemberSpinner" pRipple class="p-button-info width-50" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</p-dialog>

<p-dialog header="Convert to Business" [style]="{width: '30vw'}" [dismissableMask]="false"
  [(visible)]="displayConvertCompanyDialog" [responsive]="true" [modal]="true" [draggable]="false">
  <div class="ui-g">
    <div class="ui-g-4">
      <label>Please Confirm Company Name: </label>
    </div>
    <div class="ui-g-8">
      <input type="text" class="width-100" pInputText [(ngModel)]="newCompanyName" />
    </div>

    <div class="ui-g-12 permission-buttons">
      <button *ngIf="!convertCompanySpinner" pRipple class="p-button-info width-50" label="Close" type="button"
        (click)="displayConvertCompanyDialog = false;" pButton icon="fas fa-times-circle"></button>

      <button *ngIf="!convertCompanySpinner" pRipple class="p-button-info width-50" label="Convert" type="button"
        (click)="ConvertToCompany()" pButton icon="far fa-save"></button>

      <button *ngIf="convertCompanySpinner" pRipple class="p-button-info width-50" label="Close" type="button"
        [disabled]="true" pButton icon="fas fa-times-circle"></button>
      <button *ngIf="convertCompanySpinner" pRipple class="p-button-info width-50" pButton type="button">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </div>
  </div>
</p-dialog>

<!-- Edit Company -->