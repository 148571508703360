<p-table #dt [autoLayout]="true" [value]="notes" [scrollable]="true" scrollHeight="400px" [rows]="50"
    [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['CreatedBy','Created','Note']">
    <ng-template pTemplate="caption">
        <div class="p-d-flex custom-note-buttons">
            <span class="p-input-icon-left create-item">
                <button pRipple class=" p-button-info ui-button-success " pButton type="button" pTooltip="Refresh Notes"
                    [icon]="loadIcon" (click)="getNotesForFile()"></button>
                <button pRipple class="p-button-info ui-button-success margin-left-5px " type="button"
                    pTooltip="Add Note" (click)="addNote()" pButton icon="fas fa-plus"></button>
            </span>
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search keyword" style="color: #fff;" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="CreatedBy" width="15%">
                CreatedBy
                <p-sortIcon field="CreatedBy"></p-sortIcon>
            </th>
            <th pSortableColumn="Created" width="20%">
                Created
                <p-sortIcon field="Created"></p-sortIcon>
            </th>
            <th pSortableColumn="Note" width="65%">
                Note
                <p-sortIcon field="Note"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-note>
        <tr>
            <td width="15%">{{note.createdBy}}</td>
            <td width="20%">{{note.created}}</td>
            <td width="65%" class="note">{{note.note}}</td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Add Note" [style]="{width: '50vw'}" [(visible)]="displayNote" [resizable]="false" [responsive]="true"
    showEffect="fade" [modal]="true" (onHide)="ResetDialogValues()">
    <div *ngIf="noteTypes" class="ui-g">
        <div class="ui-g-4">
            Select Note Type:
        </div>
        <div class="ui-g-8">
            <p-dropdown [style]="{'width':'100%'}" placeholder="Select a Note Type" class="full-width" appendTo="body"
                [options]="noteTypes" [(ngModel)]="selectedNoteType" (onChange)="setupNote()" [filter]="true"
                [showClear]="true"></p-dropdown>
        </div>
        <div class="ui-g-12 centerInDiv">
            <textarea pInputTextarea autoResize="autoResize" class="note-text" [disabled]="noteTypeSelected"
                maxlength="500" [(ngModel)]="noteData"></textarea>
        </div>
        <div class="ui-g-12 centerInDiv">
            <button pRipple class="p-button-info" [disabled]="!this.noteData || !this.selectedNoteType" label="Save"
                type="button" (click)="saveNote()" pButton icon="fas fa-plus"></button>
        </div>
    </div>
</p-dialog>