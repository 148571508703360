<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div class="ui-g card card-w-title" *ngIf="!showSpinner">
  <div class="ui-g-6">
    <h1>Whatsapp Manager</h1>
  </div>
  <div class="ui-g-6">
    <button pRipple class="p-button-info" pTooltip="Refresh" tooltipPosition="left" pButton type="button"
      style="float: right;" icon="fas fa-sync" (click)="refreshList()"></button>
  </div>

  <div class="ui-g-12">
    <app-filter-search [ruleSetId]="0"></app-filter-search>
  </div>

  <div class="ui-g-12">
    <button (click)="selectATemplate()" class="broadcast-button" pButton type="button"
      [disabled]="!this.filterDataService.selectedFiles || this.filterDataService.selectedFiles?.length == 0"
      label="Select a Template"></button>
  </div>
</div>

<p-dialog *ngIf="showDialog" [(visible)]="showDialog" [modal]="true" [draggable]="false"
  [style]="{width: '55vw', 'height': 'auto'}">
  <p-header>
    Select a Template
  </p-header>

  <!-- WA Template Params -->
  <form #f="ngForm" class="ui-g-12">

    <span class="ui-g-12 ui-float-label">
      <p-dropdown [options]="waTemplates" optionLabel="txt" [(ngModel)]="selectedWATemplate" name="selectedWATemplate"
        [autoDisplayFirst]="false" appendTo="body" (onChange)="getWATemplateParams()" [style]="{'width':'400px'}"
        [filter]="true">
      </p-dropdown>

      <label class="float-label" for="float-input">Template</label>
    </span>

    <div class="ui-g-12 template-spinner" *ngIf="!waTemplateParams">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"
        animationDuration="2s">
      </p-progressSpinner>
    </div>

    <ng-container *ngIf="waTemplateParams && waTemplateParams.length >= 0 ">
      <span class="ui-g-12 template">
        <h2>{{ waTemplateParams.length >= 1 ? 'Replace the Hash Tag Field(s)' : 'Template Message' }}</h2>
        <h4>{{ selectedWATemplate.body }}</h4>
        <p>*Please note, that #fileId# and #car# hash tag values will be grabbed from
          the files you select.</p>
      </span>

      <div class="template-params" *ngFor="let p of waTemplateParams">
        <ng-container *ngIf="p.variableType == 'number' && p.variableName != 'fileId'">
          <label class="ui-g-2">{{ p.display }} *</label>
          <p-inputNumber class="ui-g-4" required name="{{ p.variableName }}" [(ngModel)]="p.value"></p-inputNumber>
        </ng-container>

        <ng-container *ngIf="p.variableType == 'string' && p.variableName != 'car'">
          <label class="ui-g-2">{{ p.display }} *</label>
          <input class="ui-g-4" required name="{{ p.variableName }}" type="text" pInputText [(ngModel)]="p.value" />
        </ng-container>

        <ng-container *ngIf="p.variableType == 'date'">
          <label class="ui-g-2">{{ p.display }} *</label>
          <p-calendar class="ui-g-4" appendTo="body" required readonly name="{{ p.variableName }}"
            [(ngModel)]="p.value"></p-calendar>
        </ng-container>
      </div>
      <div>
        <button *ngIf="!broadCastingTemplate" (click)="confirmBroadCast()" class="broadcast-button" pButton
          type="button" [disabled]="canBroadCastTemplate(f)" label="Broadcast"></button>
        <button *ngIf="broadCastingTemplate" [disabled]="true" pRipple class="p-button-info broadcast-button" pButton
          type="button">
          <i class="pi pi-spin pi-spinner font-size-2em"></i>
        </button>
      </div>
    </ng-container>
  </form>
</p-dialog>

<p-confirmDialog [style]="{width: '45%'}" icon="pi pi-exclamation-triangle"> </p-confirmDialog>