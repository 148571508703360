<p-progressSpinner *ngIf="showSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"
  animationDuration="2s"></p-progressSpinner>

<div *ngIf="!showSpinner && Cargo">
  <!-- This is to view data -->
  <div *ngFor="let cargo of Cargo.Vehicles">
    <p-fieldset legend="Vehicle">
      <div class="ui-g-12" *ngIf="!cargo.editMode">
        <div class="ui-g-4">
          <p-fieldset class="ui-g-12" legend="Details" [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Grade:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.Grade}}
              </div>
              <div class="ui-g-5">
                <strong>Year:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.Year}}
              </div>
              <div class="ui-g-5">
                <strong>Colour:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.Color}}
              </div>
              <div class="ui-g-5">
                <strong>Manufacturer:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.Manufacturer}}
              </div>
              <div class="ui-g-5">
                <strong>Model:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.Model}}
              </div>
              <div class="ui-g-5">
                <strong>Model Code:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.ModelCode}}
              </div>
              <div class="ui-g-5">
                <strong>Mileage:</strong> (km)
              </div>
              <div class="ui-g-7">
                {{cargo.Mileage}}
              </div>
              <div class="ui-g-5">
                <strong>Engine Number:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.EngineNo}}
              </div>
              <div class="ui-g-5">
                <strong>Chassis No:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.ChassisNo}}
              </div>
              <div class="ui-g-5">
                <strong>Fuel Type:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.FuelType}}
              </div>
              <div class="ui-g-5">
                <strong>Transmission:</strong>
              </div>
              <div class="ui-g-7">
                {{cargo.TransmissionType}}
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="ui-g-4">
          <p-fieldset class="ui-g-12" legend="Dimensions" [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Weight:</strong> (kg)
              </div>
              <div class="ui-g-7">
                {{cargo.Weight}}
              </div>
              <div class="ui-g-5">
                <strong>Length:</strong> (m)
              </div>
              <div class="ui-g-7">
                {{cargo.Length}}
              </div>
              <div class="ui-g-5">
                <strong>Height:</strong> (m)
              </div>
              <div class="ui-g-7">
                {{cargo.Height}}
              </div>
              <div class="ui-g-5">
                <strong>Width:</strong> (m)
              </div>
              <div class="ui-g-7">
                {{cargo.Width}}
              </div>
            </div>
          </p-fieldset>
          <p-fieldset class="ui-g-12" legend="Value" [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Value:</strong>
              </div>
              <div class="ui-g-7">
                R{{cargo.ZARAmount}}
              </div>
            </div>
          </p-fieldset>
        </div>
        <p-fieldset class="ui-g-12" legend="container" [style]="{'height':'auto'}"
          *ngIf="cargo?.ContainerSealNumber; else do_this">
          <div class="ui-g">
            <div class="ui-g-5">
              <strong>Container Number:</strong>
            </div>
            <div class="ui-g-7">
              {{cargo.ContainerNumber}}
            </div>
            <div class="ui-g-5">
              <strong>Container Seal Number:</strong>
            </div>
            <div class="ui-g-7">
              {{cargo.ContainerSealNumber}}
            </div>
            <div class="ui-g-5">
              <strong>Container Size:</strong>
            </div>
            <div class="ui-g-7">
              {{cargo.ContainerSize}}
            </div>
          </div>
        </p-fieldset>
        <div class="ui-g-12" *ngIf="!fileClosed">
          <button pRipple class="p-button-info" style="float: right;" pButton label="Edit" type="button"
            icon="far fa-edit" (click)="toggleEditMode(cargo)"></button>
        </div>
      </div>

      <!-- This is to edit data -->
      <div class="ui-g-12" *ngIf="cargo.editMode">
        <div class="ui-g" [formGroup]="cargo.CargoDetailsForm">
          <div class="ui-g-4">
            <p-fieldset class="ui-g-12" legend="Details" [style]="{'height':'auto'}">
              <div class="ui-g">
                <div class="ui-g-5">
                  <strong>Grade:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="text" class="width-100" pInputText formControlName="Grade">
                </div>
                <div class="ui-g-5">
                  <strong>Year: </strong>
                </div>
                <div class="ui-g-7">
                  <input type="number" class="width-100" pInputText formControlName="Year" placeholder="*Required">
                </div>
                <div class="ui-g-5">
                  <strong>Colour:</strong>
                </div>
                <div class="ui-g-7">
                  <p-dropdown [filter]="true" [style]="{'min-width': '100%'}" [options]="cargo.colours"
                    formControlName="Colour" optionLabel="Color" [autoDisplayFirst]="false" [showClear]="true">
                  </p-dropdown>
                </div>
                <div class="ui-g-5">
                  <strong>Manufacturer:</strong>
                </div>
                <div class="ui-g-7">
                  <p-dropdown [options]="cargo.vehicleMakes" formControlName="Manufacturer" optionLabel="Name"
                    [style]="{'min-width': '100%'}" [autoDisplayFirst]="false" [showClear]="true" [filter]="true"
                    (onChange)="getVehicleModels(cargo)">
                  </p-dropdown>
                </div>
                <div class="ui-g-5">
                  <strong>Model:</strong>
                </div>
                <div class="ui-g-7">
                  <p-dropdown [style]="{'min-width': '100%'}" [options]="cargo.vehicleModels" formControlName="Model"
                    [autoDisplayFirst]="false" optionLabel="Name" [showClear]="true" [filter]="true">
                  </p-dropdown>
                </div>
                <div class="ui-g-5">
                  <strong>Model Code:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="text" class="width-100" pInputText formControlName="ModelCode">
                </div>
                <div class="ui-g-5">
                  <strong>Mileage:</strong> (km)
                </div>
                <div class="ui-g-7">
                  <input type="text" class="width-100" pInputText formControlName="Mileage">
                </div>
                <div class="ui-g-5">
                  <strong>Engine Number:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="text" class="width-100" pInputText formControlName="EngineNo">
                </div>
                <div class="ui-g-5">
                  <strong>Chassis No:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="text" pInputText formControlName="ChassisNo" placeholder="*Required" class="width-100"
                    (keyup)="checkChassisNo(cargo)">
                  <p-message *ngIf="!cargo.validChassisNo && cargo.notEmptyChassisNo" severity="warn"
                    text="Already in use"></p-message>
                </div>
                <div class="ui-g-5">
                  <strong>Fuel Type:</strong>
                </div>
                <div class="ui-g-7">
                  <p-dropdown [options]="cargo.fuelTypes" formControlName="FuelType" optionLabel="Name"
                    [style]="{'min-width': '100%'}" [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
                </div>
                <div class="ui-g-5">
                  <strong>Transmission:</strong>
                </div>
                <div class="ui-g-7">
                  <p-dropdown [options]="cargo.transmissionTypes" formControlName="TransmissionType" optionLabel="Name"
                    [style]="{'min-width': '100%'}" [autoDisplayFirst]="false" [showClear]="true">
                  </p-dropdown>
                </div>
              </div>
            </p-fieldset>
          </div>
          <div class="ui-g-4">
            <p-fieldset class="ui-g-12" legend="Dimensions" [style]="{'height':'auto'}">
              <div class="ui-g">
                <div class="ui-g-5">
                  <strong>Weight:</strong> (kg)
                </div>
                <div class="ui-g-7">
                  <input type="number" pInputText formControlName="Weight" placeholder="*Required" class="width-100"
                    pattern="^(\d*)(\.\d{1,2})?$">
                </div>
                <div class="ui-g-5">
                  <strong>Length:</strong> (m)
                </div>
                <div class="ui-g-7">
                  <input type="number" pTooltip="Max 14m" tooltipPosition="left" tooltipEvent="focus" pInputText
                    class="width-100" formControlName="Length" placeholder="*Required" pattern="^(\d*)(\.\d{1,2})?$">
                </div>
                <div class="ui-g-5">
                  <strong>Height:</strong> (m)
                </div>
                <div class="ui-g-7">
                  <input type="number" pInputText formControlName="Height" placeholder="*Required" class="width-100"
                    pattern="^(\d*)(\.\d{1,2})?$">
                </div>
                <div class="ui-g-5">
                  <strong>Width:</strong> (m)
                </div>
                <div class="ui-g-7">
                  <input type="number" pInputText formControlName="Width" placeholder="*Required" class="width-100"
                    pattern="^(\d*)(\.\d{1,2})?$">
                </div>
              </div>
            </p-fieldset>
            <p-fieldset class="ui-g-12" legend="Value" [style]="{'height':'auto'}">
              <div class="ui-g">
                <div class="ui-g-5">
                  <strong>Value:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="number" pInputText formControlName="CargoValue" placeholder="*Required" min="1"
                    pattern="^(\d*)(\.\d{1,2})?$" class="width-100">
                </div>
                <div class="ui-g-5">
                  <strong>Currency:</strong>
                </div>
                <div class="ui-g-7">
                  <p-dropdown [options]="currencies" optionLabel="currency" formControlName="SelectedCurrency"
                    [autoDisplayFirst]="false" placeholder="*Required" [style]="{'min-width': '100%'}">
                  </p-dropdown>
                </div>
                <div class="ui-g-5">
                  <strong>Date For Exhange:</strong>
                </div>
                <div class="ui-g-7">
                  <p-calendar placeholder="Start Date" dateFormat="yy/mm/dd" placeholder="*Required"
                    [readonlyInput]="true" formControlName="CargoValueExchangeRateDate">
                  </p-calendar>
                </div>
              </div>
            </p-fieldset>
          </div>
          <div class="ui-g-4">
            <p-fieldset class="ui-g-12" legend="Container" [style]="{'height':'auto'}">
              <div class="ui-g">
                <div class="ui-g-5">
                  <strong>Container Number:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="text" pInputText formControlName="ContainerNo" class="width-100">
                </div>
                <div class="ui-g-5">
                  <strong>Container Seal Number:</strong>
                </div>
                <div class="ui-g-7">
                  <input type="text" pInputText formControlName="ContainerSealNo" class="width-100">
                </div>
                <div class="ui-g-5">
                  <strong>Container Size:</strong>
                </div>
                <div class="ui-g-7">
                  {{cargo.ContainerSize}}
                </div>
              </div>
            </p-fieldset>
          </div>
          <div class="ui-g-12">
            <button *ngIf="!saveSpinner" pRipple class="p-button-info" style="float: right; margin-left: 2px;" pButton
              type="button" pTooltip="Cancel edit" tooltipPosition="left" icon="fas fa-times"
              (click)="cancelEdit(cargo)"></button>
            <button *ngIf="!saveSpinner" pRipple class="p-button-info" style="float: right;"
              [pTooltip]="cargo.CargoDetailsForm.invalid ? 'Required fields are not filled in' : 'Save changes'"
              tooltipPosition="left" [disabled]="showSaveSpinner || cargo.CargoDetailsForm.invalid" pButton
              type="button" icon="fas fa-check" (click)="update(cargo)"></button>
            <button *ngIf="saveSpinner" pRipple style="float: right;" class=" p-button-info ui-button-success " pButton
              type="button" [disabled]="true" icon="fas fa-sync fa-spin"></button>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>

  <!-- ???????????-->
  <div *ngFor="let ContainerItems of Cargo.ContainerItems">
    <p-fieldset legend="Container Item - {{ContainerItems.CargoItemType}}">
      <div class="ui-g-12">
        <div class="ui-g" *ngIf="!ContainerItems.editMode">
          <p-fieldset class="ui-g-4" legend="Details" [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Foreign Reference:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.ForeignReference}}
              </div>
              <div class="ui-g-5">
                <strong>Quantity:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.Quantity}}
              </div>
              <div class="ui-g-5">
                <strong>Description:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.Description}}
              </div>
              <div class="ui-g-5" *ngIf="ContainerItems.CargoItemType == 'Engine'">
                <strong>Engine No:</strong>
              </div>
              <div class="ui-g-7" *ngIf="ContainerItems.CargoItemType == 'Engine'">
                {{ContainerItems.EngineNo}}
              </div>
              <div class="ui-g-5" *ngIf="ContainerItems.CargoItemType == 'Vehicle'">
                <strong>Chassis No:</strong>
              </div>
              <div class="ui-g-7" *ngIf="ContainerItems.CargoItemType == 'Vehicle'">
                {{ContainerItems.ChassisNo}}
              </div>
            </div>
          </p-fieldset>
          <p-fieldset class="ui-g-4" legend="Dimensions" [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Weight:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.Weight}} kg
              </div>
              <div class="ui-g-5">
                <strong>Length:</strong> (m)
              </div>
              <div class="ui-g-7">
                {{ContainerItems.Length}}
              </div>
              <div class="ui-g-5">
                <strong>Height:</strong> (m)
              </div>
              <div class="ui-g-7">
                {{ContainerItems.Height}}
              </div>
              <div class="ui-g-5">
                <strong>Width:</strong> (m)
              </div>
              <div class="ui-g-7">
                {{ContainerItems.Width}}
              </div>
            </div>
          </p-fieldset>
          <p-fieldset class="ui-g-4" *ngIf="ContainerItems.ContainerNumber !=''" legend="Container"
            [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Number:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.ContainerNumber}}
              </div>+
              <div class="ui-g-5">
                <strong>Seal Number:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.ContainerSealNumber}}
              </div>
              <div class="ui-g-5">
                <strong>Size:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.ContainerSize}}
              </div>
            </div>
          </p-fieldset>
          <div class="ui-g-12" *ngIf="!fileClosed">
            <button pRipple class="p-button-info" style="float: right;" pButton label="Edit" type="button"
              icon="far fa-edit" (click)="toggleEditModeContainerItem(ContainerItems)"></button>
          </div>
        </div>

        <div class="ui-g" *ngIf="ContainerItems.editMode" [formGroup]="ContainerItems.CargoDetailsForm">
          <p-fieldset class="ui-g-4" legend="Details" [style]="{'height':'auto'}">
            <div class="ui-g-12">
              <div class="ui-g-5">
                <strong>Foreign Reference:</strong>
              </div>
              <div class="ui-g-7">
                <input type="text" pInputText formControlName="ForeignReference" class="width-100">
              </div>
            </div>
            <div class="ui-g-12">
              <div class="ui-g-5">
                <strong>Quantity</strong>
              </div>
              <div class="ui-g-7">
                <input type="number" pInputText formControlName="Quantity" placeholder="*Required" class="width-100"
                  pattern="^(\d*)(\.\d{1,2})?$">
              </div>
            </div>
            <div class="ui-g-12">
              <div class="ui-g-5">
                <strong>Description: </strong>
              </div>
              <div class="ui-g-7">
                <p-autoComplete [suggestions]="descriptionOptions" (completeMethod)="searchDescriptions($event)"
                  [style]="{'min-width': '100%'}" [minLength]="1" field="Name" [multiple]="false"
                  [forceSelection]="false" formControlName="Description" placeholder="*Required">
                  <ng-template let-description pTemplate="item">
                    <div class="ui-helper-clearfix">
                      {{description.Name}}
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>
            <div class="ui-g-5" *ngIf="ContainerItems.CargoItemType == 'Engine'">
              <strong>Engine No:</strong>
            </div>
            <div class="ui-g-7" *ngIf="ContainerItems.CargoItemType == 'Engine'">
              <input type="text" pInputText formControlName="EngineNo" class="width-100">
            </div>
            <div class="ui-g-5" *ngIf="ContainerItems.CargoItemType == 'Vehicle'">
              <strong>Chassis No:</strong>
            </div>
            <div class="ui-g-7" *ngIf="ContainerItems.CargoItemType == 'Vehicle'">
              <input type="number" pInputText formControlName="ChassisNo" class="width-100">
            </div>
          </p-fieldset>
          <p-fieldset class="ui-g-4" legend="Dimensions" [style]="{'height':'auto'}">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Weight:</strong>
              </div>
              <div class="ui-g-7">
                <input type="number" pInputText formControlName="Weight" placeholder="*Required" class="width-100"
                  pattern="^(\d*)(\.\d{1,2})?$">kg
              </div>
              <div class="ui-g-5">
                <strong>Length:</strong>
              </div>
              <div class="ui-g-7">
                <input type="number" pTooltip="Max 14m" tooltipPosition="left" tooltipEvent="focus" pInputText
                  class="width-100" formControlName="Length" placeholder="*Required" pattern="^(\d*)(\.\d{1,2})?$">m
              </div>
              <div class="ui-g-5">
                <strong>Height:</strong>
              </div>
              <div class="ui-g-7">
                <input type="number" pInputText formControlName="Height" placeholder="*Required" class="width-100"
                  pattern="^(\d*)(\.\d{1,2})?$">m
              </div>
              <div class="ui-g-5">
                <strong>Width:</strong>
              </div>
              <div class="ui-g-7">
                <input type="number" pInputText formControlName="Width" placeholder="*Required" class="width-100"
                  pattern="^(\d*)(\.\d{1,2})?$">m
              </div>
            </div>
          </p-fieldset>
          <p-fieldset class="ui-g-4" *ngIf="ContainerItems.ContainerNumber !=''" legend="Container">
            <div class="ui-g">
              <div class="ui-g-5">
                <strong>Number:</strong>
              </div>
              <div class="ui-g-7">
                <input type="text" pInputText formControlName="ContainerNo" class="width-100">
              </div>
              <div class="ui-g-5">
                <strong>Seal Number:</strong>
              </div>
              <div class="ui-g-7">
                <input type="text" pInputText formControlName="ContainerSealNo" class="width-100">
              </div>
              <div class="ui-g-5">
                <strong>Size:</strong>
              </div>
              <div class="ui-g-7">
                {{ContainerItems.ContainerSize}}
              </div>
            </div>
          </p-fieldset>
          <div class="ui-g-12">
            <button pRipple class="p-button-info" style="float: right; margin-left: 2px;" pButton type="button"
              icon="fas fa-times" (click)="cancelEdit(ContainerItems)"></button>
            <button pRipple class="p-button-info" style="float: right;" [disabled]="showSaveSpinner" pButton
              type="button" icon="fas fa-check" (click)="updateContainerItems(ContainerItems)"></button>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>
</div>