import { Component, OnInit } from '@angular/core';
import { ApproveRejectTransactionUserTaskInfo } from '../../../../interfaces/user-tasks/finance-user-tasks.interface';
import { FinanceHelperService } from '../../../../services/finance-helper.service';
import { MessageService } from 'primeng';
import * as moment from 'moment';
import { FinanceUserTaskApiService } from '../../../../services/api/user-tasks/finance-user-task-api.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { LogBase } from '../../../../services/logger.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-approve-reject-admin-transaction',
  templateUrl: './approve-reject-admin-transaction.component.html',
  styleUrls: ['./approve-reject-admin-transaction.component.scss']
})
export class ApproveRejectAdminTransactionComponent implements OnInit {

  fileId: number = 0;
  userTask: ApproveRejectTransactionUserTaskInfo;
  showRejectDialog: boolean = false;
  rejectReason: string;

  showSpinner: boolean = false;
  approveSpinner: boolean = false;
  rejectSpinner: boolean = false;

  constructor(
    public financeHelper: FinanceHelperService,
    private financeUserTaskApiService: FinanceUserTaskApiService,
    private log: LogBase,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
  ) { }

  async ngOnInit() {
    this.getRouteParams();
    await this.GetApproveRejectAdminTransactionUserTask();
  }

  private async GetApproveRejectAdminTransactionUserTask() {
    this.showSpinner = true;
    try {
      const retval = await this.financeUserTaskApiService.GetApproveRejectAdminTransactionUserTask(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to get approve / reject admin transaction user task. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.userTask = retval.result;
    } catch (err) {
      this.log.error(err);
    }
    this.showSpinner = false;
  }

  public async rejectTransaction() {
    this.rejectSpinner = true;
    try {
      const retval = await this.financeUserTaskApiService.RejectAdminTransactionUserTask(this.fileId, this.rejectReason);
      if (!retval) this.toastMessage('error', 'Failed to reject transaction. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        this.router.navigate(['/task-list']);
      }
    } catch (err) {
      this.log.error(err);
    }
    this.rejectSpinner = false;
  }

  public async approveTransaction() {
    this.approveSpinner = true;
    try {
      const retval = await this.financeUserTaskApiService.ApproveAdminTransactionUserTask(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to approve transaction. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        this.router.navigate(['/task-list']);
      }
    } catch (err) {
      this.log.error(err);
    }
    this.approveSpinner = false;
  }

  private getRouteParams() { var fileId = this.route.snapshot.paramMap.get('fileId'); this.fileId = Number(fileId) || 0; }
  public formatDate(date: Date) { return moment(date).format('YYYY/MM/DD h:mm a'); }
  public toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}