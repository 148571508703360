import { HttpResponse } from "../../../interfaces/result-interfaces";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";

@Injectable({
  providedIn: "root",
})
export class FileOwnerUserTaskApiService {
  private serverUrl = `${this.config.getServerUrl()}api/fileowner/userTask/`;

  constructor(private config: ConfigService, private log: LogBase, private webApi: WebApi2Service) { }

  public async GetCheckBorderPostUserTask(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getCheckBorderPostUserTask?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async DoCheckBorderPostUserTask(fileId: number, routeId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}doCheckBorderPostUserTask?fileId=${fileId}&routeId=${routeId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async GetWhatsappTalkToAgentUserTask(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getWhatsappTalkToAgentUserTask?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async DoWhatsappTalkToAgentUserTask(fileId: number, userTaskId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}doWhatsappTalkToAgentUserTask?fileId=${fileId}&userTaskId=${userTaskId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async GetInspectionRouteUpdateFailUserTask(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getInspectionRouteUpdateFailUserTask?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async DoInspectionRouteUpdateFailUserTask(fileId: number, routeId: number, inspectionWorkorderId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}doInspectionRouteUpdateFailUserTask?fileId=${fileId}&routeId=${routeId}&inspectionWorkorderId=${inspectionWorkorderId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}
