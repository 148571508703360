import { Component, OnInit } from '@angular/core';
import { InvoiceApiService } from '../../../../services/api/finance/invoice-api.service';
import { Invoice, InvoiceRequest } from '../../../../interfaces/finance-interfaces';
import { LogBase } from '../../../../services/logger.service';
import { MessageService } from 'primeng';
import * as _ from 'lodash';
import { Router } from '@angular/router';
@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html'
})
export class InvoiceCreateComponent implements OnInit {

  //component
  fileId: number = 0;
  actionSpinner: boolean = false;
  isAdhoc: boolean;

  constructor(
    private router: Router,
    private invoiceApi: InvoiceApiService,
    private log: LogBase,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    /*
      0 : ""
      1 : "invoice"
      2 : "create"
      3: "0" - 0 = non adhoc, 1 = adhoc
      4 : "123126"
    */
    const params = this.router.url.split('/');
    this.fileId = +params[4];
    this.isAdhoc = +params[3] == 1;
  }

  //payload any will have the object of Invoice and the property of emailInvoicee
  async invoiceCreate(payload: any) {
    const emailInvoicee = payload.emailInvoicee;
    const invoice: Invoice = payload.invoice;
    this.actionSpinner = true;
    try {
      const payload: InvoiceRequest = { invoice: invoice, fileId: this.fileId };
      const retval = await this.invoiceApi.CreateInvoice(payload, emailInvoicee);
      if (!retval) this.toastMessage('error', `Failed to create invoice. Please contact support.`, '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', `Failed to create invoice. Please contact support.`, '');
    }
    this.actionSpinner = false;
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}