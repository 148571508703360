import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export let isDebugMode = environment.isDebugMode;

const noop = (): any => undefined;

export abstract class Logger {
  info: any;
  warning: any;
  error: any;
}

@Injectable()
export class LogBase implements Logger {
  info: any;
  warning: any;
  error: any;
}

@Injectable()
export class LogService implements LogBase {

  constructor() { }

  info(args: any) {
    if (isDebugMode) {
      const logFn: Function = (console)['info'] || console.log;
      logFn.apply(console, [args]);
    } else {
      return noop;
    }
  }

  warning(args: any) {
    if (isDebugMode) {
      const logFn: Function = (console)['warning'] || console.log;
      logFn.apply(console, [args]);
    } else {
      return noop;
    }
  }

  error(args: any) {
    if (isDebugMode) {
      const logFn: Function = (console)['error'] || console.log;
      logFn.apply(console, [args]);
    } else {
      return noop;
    }
  }
}
