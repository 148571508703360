<div class="ui-g-12 centerSpinner" *ngIf="showLoadingSpinner">
  <p-progressSpinner id="p-spinner" [style]="{ width: '50px', height: '50px' }" strokeWidth="6" fill="#EEEEEE"
    animationDuration="2s"></p-progressSpinner>
</div>

<div class="ui-g card card-w-title" *ngIf="!showLoadingSpinner">
  <div class="ui-g-12">
    <h1>Manage Invoice Defaults</h1>
  </div>
  <p-tabView>
    <p-tabPanel header="RORO Matrix" leftIcon="fas fa-book-open" [selected]="true">
      <div class="ui-g-12">
        <p-table #ab [value]="roroMatrix" dataKey="id" [columns]="roroMatrixColumns" [scrollable]="true"
          scrollHeight="400px" selectionMode="single" [autoLayout]="true" editMode="row">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="ab.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search keyword" style="color: #fff;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of roroMatrixColumns" pSortableColumn="{{ col.field }}">
                {{ col.header }}
                <p-sortIcon field="{{ col.field }}"> </p-sortIcon>
              </th>
              <th>
                Action
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>{{ rowData.borderPost }}</td>
              <td>{{ rowData.lengthStart }}</td>
              <td>{{ rowData.lengthEnd }}</td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="number" class="width-100" [(ngModel)]="rowData.clearingRate" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.clearingRate }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="number" class="width-100" [(ngModel)]="rowData.deliveryRate" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.deliveryRate }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input type="text" pInputText class="width-100" [(ngModel)]="rowData.tollRate" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.tollRate }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input type="number" pInputText class="width-100" [(ngModel)]="rowData.bankCharge" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.bankCharge }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fas fa-pencil-alt"
                  class="ui-button-info" pTooltip="Edit Row"></button>
                <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fas fa-check"
                  class="ui-button-success" style="margin-right: .5em" (click)="updateROROMatrix(rowData)"
                  pTooltip="Update Row"></button>
                <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fas fa-times"
                  class="ui-button-danger" pTooltip="Cancel Edit"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Standard Matrix" leftIcon="fas fa-car">
      <div class="ui-g-12">
        <p-table #sd [value]="standardMatrix" dataKey="id" [columns]="standardMatrixColumns" scrollHeight="400px"
          [scrollable]="true" selectionMode="single" [autoLayout]="true" editMode="row">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left create-item">
                <button class="p-button-info" pButton type="button" (click)="showStandardMatrixDialog = true"
                  icon="fas fa-plus" class="ui-button-success" pTooltip="Add Pastel Code / Description"></button>
              </span>
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="sd.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search keyword" style="color: #fff;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="pastelCode">Pastel Code<p-sortIcon field="pastelCode">
                </p-sortIcon>
              </th>
              <th pSortableColumn="description">Description <p-sortIcon field="description">
                </p-sortIcon>
              </th>
              <th>Amount</th>
              <th width="15%">
                Action
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                {{ rowData.pastelCode }}
              </td>
              <td>
                {{ rowData.description }}
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="number" class="width-100" [(ngModel)]="rowData.amount" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.amount }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td width="15%">
                <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fas fa-pencil-alt"
                  class="ui-button-info" pTooltip="Edit Row"></button>
                <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fas fa-check"
                  class="ui-button-success" style="margin-right: .5em" (click)="updateStandardMatrix(rowData)"
                  pTooltip="Update Row"></button>
                <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fas fa-times"
                  class="ui-button-danger" style="margin-right: .5em" pTooltip="Cancel Edit"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Partial Handover Matrix" leftIcon="fas fa-car">
      <div class="ui-g-12">
        <p-table #ju [value]="partialHandoverMatrix" dataKey="id" [columns]="partialHandoverMatrixColumns"
          scrollHeight="400px" [scrollable]="true" selectionMode="single" [autoLayout]="true" editMode="row">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left create-item">
                <button class="p-button-info" pButton type="button" (click)="showPartialHandoverMatrixDialog = true"
                  icon="fas fa-plus" class="ui-button-success" pTooltip="Add Partial Handover matrix"></button>
              </span>
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="ju.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search keyword" style="color: #fff;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of partialHandoverMatrixColumns" pSortableColumn="{{ col.field }}">
                {{ col.header }}
                <p-sortIcon field="{{ col.field }}"> </p-sortIcon>
              </th>
              <th>
                Action
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" tyle="width: 80%;" [(ngModel)]="rowData.pastelCode" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.pastelCode }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                {{ rowData.description }}
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="number" class="width-100" [(ngModel)]="rowData.amount" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.amount }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <button *ngIf="!editing && (rowData.id !=  6 && rowData.id != 7)" pButton type="button" pInitEditableRow
                  icon="fas fa-pencil-alt" class="ui-button-info" pTooltip="Edit Row"></button>
                <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fas fa-check"
                  class="ui-button-success" style="margin-right: .5em" (click)="updatePartialHandoverMatrix(rowData)"
                  pTooltip="Update Row"></button>
                <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fas fa-times"
                  class="ui-button-danger" style="margin-right: .5em" pTooltip="Cancel Edit"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Handover Matrix" leftIcon="fas fa-solid fa-ship">
      <div class="ui-g-12">
        <p-table dataKey="id" #we [value]="handoverMatrix" [columns]="handoverMatrixColumns" [scrollable]="true"
          scrollHeight="400px" selectionMode="single" [autoLayout]="true" editMode="row">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left create-item">
                <button class="p-button-info" pButton type="button" (click)="showHandoverMatrixDialog = true;"
                  icon="fas fa-plus" class="ui-button-success" pTooltip="Add Handover Invoice Line Item"></button>
              </span>
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="we.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search keyword" style="color: #fff;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of handoverMatrixColumns" pSortableColumn="{{ col.field }}">
                {{ col.header }}
                <p-sortIcon field="{{ col.field }}"> </p-sortIcon>
              </th>
              <th>
                Action
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>{{ rowData.name }}</td>
              <td>{{ rowData.pastelCode }}</td>
              <td>
                {{ rowData.description }}
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="number" class="width-100" [(ngModel)]="rowData.amount" min="0" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.amount }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <button *ngIf="!editing && rowData.pastelCode != 'LOC'" pButton type="button" pInitEditableRow
                  icon="fas fa-pencil-alt" class="ui-button-info" pTooltip="Edit Row"></button>
                <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fas fa-check"
                  class="ui-button-success" style="margin-right: .5em" (click)="updateHandoverMatrix(rowData)"
                  pTooltip="Update Row"></button>
                <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fas fa-times"
                  class="ui-button-danger" style="margin-right: .5em" pTooltip="Cancel Edit"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="CIF RORO Matrix for BFJ" leftIcon="fas fa-book-open">
      <div class="ui-g-12 ui-g-nopad cif-matrix">
        <button class="p-button-info new-cif-roro-matrix" pButton type="button"
          (click)="showNewROROCIFMatrixDialog = true; selectedBorderPost = undefined;" icon="fas fa-plus"
          pTooltip="Add New CIF RORO Matrix"></button>
        <div class="ui-g-12 ui-g-nopad">
          <p-accordion #accordion [activeIndex]="selectedBorderPostIndex"
            (onOpen)="selectedBorderPostIndex = $event.index">
            <p-accordionTab *ngFor="let matrix of roroCIFBorderPostMatrix" header="{{ matrix.borderPost }}">
              <p-table dataKey="id" #we [value]="matrix.matrix" [scrollable]="true" scrollHeight="400px"
                selectionMode="single" [autoLayout]="true" editMode="row">
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                    <span class="p-input-icon-left">
                      <button class="ui-button-success" pButton type="button" (click)="addROROCIFMatrix(matrix);"
                        icon="fas fa-plus" pTooltip="Add CIF RORO Matrix for {{ matrix.borderPost }}"></button>
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      Border Post
                    </th>
                    <th>
                      From Date
                    </th>
                    <th>
                      To Date
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Action(s)
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>{{ rowData.borderPost }}</td>
                    <td>{{ formatDate(rowData.fromDate) }}</td>
                    <td>{{ formatDate(rowData.toDate) }}</td>
                    <td>
                      <label class="status {{rowData.status.toLowerCase()}}">{{ rowData.status.toUpperCase() }}</label>
                    </td>
                    <td>
                      <button pButton
                        [icon]="rowData.statusId === generalStatus.ACTIVE ? 'fas fa-pencil-alt' : 'far fa-eye'"
                        [pTooltip]="rowData.statusId === generalStatus.ACTIVE ? 'Edit' : 'View'"
                        (click)="viewROROCIFMatrix(rowData)"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div class="ui-g-12" *ngIf="selectedROROCIFMatrix">
          <p-panel [style]="{'height':'105px', 'margin-bottom': '20px'}">
            <p-header>
              <label>{{ selectedROROCIFMatrix.borderPost }}</label>
            </p-header>
            <div class="ui-g">
              <div class="ui-g-4">
                <span class="ui-g-12 ui-float-label">
                  <p-calendar [(ngModel)]="selectedROROCIFMatrix.fromDate" dateFormat="yy/mm/dd" appendTo="body"
                    [yearNavigator]="true" view="month" yearRange="{{ toDateYearRange }}" [readonlyInput]="true">
                  </p-calendar>
                  <label class="float-label" for="float-input">From Date *</label>
                </span>
              </div>
              <div class="ui-g-4">
                <span class="ui-g-12 ui-float-label">
                  <p-calendar [(ngModel)]="selectedROROCIFMatrix.toDate" dateFormat="yy/mm/dd" appendTo="body"
                    [yearNavigator]="true" view="month" yearRange="{{ toDateYearRange }}" [readonlyInput]="true">
                  </p-calendar>
                  <label class="float-label" for="float-input">To Date *</label>
                </span>
              </div>
              <div class="ui-g-4">
                <span class="ui-g-12 ui-float-label">
                  <p-dropdown [options]="generalStatusOptions" [(ngModel)]="selectedROROCIFMatrix.statusId"
                    [filter]="true" [disabled]="!selectedROROCIFMatrix.id" appendTo="body" [autoDisplayFirst]="false"
                    [style]="{ width:'100%' }">
                  </p-dropdown>
                  <label class="float-label" for="float-input">Status *</label>
                </span>
              </div>
            </div>
          </p-panel>
          <p-table dataKey="id" #we [value]="selectedROROCIFMatrix.items" [columns]="roroCIFMatrixColumns"
            [scrollable]="true" scrollHeight="400px" selectionMode="single" [autoLayout]="true">
            <ng-template pTemplate="caption">
              <div class=" p-d-flex">
                <span class="p-input-icon-left">
                  <button pButton type="button" (click)="addNewCIFROROMatrixItem();" icon="fas fa-plus"
                    [disabled]="selectedROROCIFMatrix.statusId === generalStatus.INACTIVE" class="ui-button-success"
                    pTooltip="Add New CIF RORO Matrix Item"></button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>
                  Matrix Item
                </th>
                <th *ngFor="let col of roroCIFMatrixColumns">
                  {{ col.header }}
                </th>
                <th *ngIf="selectedROROCIFMatrix.statusId === generalStatus.ACTIVE">
                  Action(s)
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
              <tr *ngIf="selectedROROCIFMatrix.statusId === generalStatus.INACTIVE">
                <td>
                  <label>{{ ri + 1 }}</label>
                </td>
                <td>
                  <label>{{ rowData.startLength.toFixed(2) }}</label>
                </td>
                <td>
                  <label>{{ rowData.endLength.toFixed(2) }}</label>
                </td>
                <td>
                  <label>{{ rowData.costUSD }}</label>
                </td>
              </tr>
              <tr *ngIf="selectedROROCIFMatrix.statusId === generalStatus.ACTIVE">
                <td>
                  <label>{{ ri + 1 }}</label>
                </td>
                <td>
                  <label *ngIf="rowData.id !== 0">
                    {{ rowData.startLength.toFixed(2) }}
                  </label>
                  <input *ngIf="rowData.id === 0" pInputText type="number" class="width-100" onwheel="blur()"
                    [(ngModel)]="rowData.startLength" min="0" required />
                </td>
                <td>
                  <label *ngIf="rowData.id !== 0">
                    {{ rowData.endLength.toFixed(2) }}
                  </label>
                  <input *ngIf="rowData.id === 0" pInputText type="number" class="width-100" onwheel="blur()"
                    [(ngModel)]="rowData.endLength" min="0" required />
                </td>
                <td>
                  <input pInputText type="number" class="width-100" onwheel="blur()" [(ngModel)]="rowData.costUSD"
                    required min="0" />
                </td>
                <td>
                  <button pButton type="button" icon="pi pi-trash" (click)="updateROROCIFMatrixItem(ri)"
                    class="p-button-info" style="margin-right: .5em" pTooltip="Remove Item"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td>No matrix items.</td>
              </tr>
            </ng-template>
          </p-table>
          <div class="ui-g-12" *ngIf="roroCIFMatrixIssues">
            <label class="roro-cif-issues">{{ roroCIFMatrixIssues }}</label>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <button *ngIf="!actionSpinner && selectedROROCIFMatrix.statusId === generalStatus.ACTIVE" label="Save"
              [disabled]="roroCIFMatrixToolTip() ? true : false" [pTooltip]="roroCIFMatrixToolTip()"
              tooltipPosition="top" pButton type="button" class="width-100" (click)="upsertROROCIFMatrix()"></button>
            <button *ngIf="!actionSpinner && selectedROROCIFMatrix.statusId === generalStatus.INACTIVE" label="Save"
              tooltipPosition="top" [disabled]="roroCIFMatrixToolTip() ? true : false"
              [pTooltip]="roroCIFMatrixToolTip()" pButton type="button" class="width-100"
              (click)="upsertROROCIFMatrix()"></button>
            <button *ngIf="actionSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
              [disabled]="true">
              <i class="pi pi-spin pi-spinner font-size-2em"></i>
            </button>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog header="Add Standard Matrix" modal="true" [(visible)]="showStandardMatrixDialog"
  (onHide)="showStandardMatrixDialog = false" [style]="{ width: '450px', height: '310px' }">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="standardPastelCode" class="width-100" />
        <label class="float-label" for="float-input">Pastel Code *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="standardDescription" class="width-100" />
        <label class="float-label" for="float-input">Description *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="number" pInputText [(ngModel)]="standardLineAmount" class="width-100" min="0" />
        <label class="float-label" for="float-input">Amount in Rand *</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!actionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="AddStandardMatrix()"
      [disabled]="!standardPastelCode || !standardDescription || !standardLineAmount || standardLineAmount <= 0"
      label="Save">
    </button>
    <button *ngIf="actionSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Add Partial Handover Matrix" modal="true" [(visible)]="showPartialHandoverMatrixDialog"
  (onHide)="showPartialHandoverMatrixDialog = false" [style]="{ width: '450px', height: '310px' }">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="partialHandoverPastelCode" class="width-100" />
        <label class="float-label" for="float-input">Pastel Code *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="partialHandoverDescription" class="width-100" />
        <label class="float-label" for="float-input">Description *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="number" pInputText [(ngModel)]="partialHandoverAmount" class="width-100" min="0" />
        <label class="float-label" for="float-input">Amount in Rand *</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!actionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      (click)="addPartialHandoverMatrix()"
      [disabled]="!partialHandoverPastelCode || !partialHandoverDescription || !partialHandoverAmount"
      label="Save"></button>
    <button *ngIf="actionSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Add Handover Matrix" modal="true" [(visible)]="showHandoverMatrixDialog"
  (onHide)="showHandoverMatrixDialog = false" [style]="{ width: '450px', height: '360px' }">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="shippingLines" [(ngModel)]="selectedShippingLine" [filter]="true" appendTo="body"
          [autoDisplayFirst]="false" [style]="{ width:'100%' }">
        </p-dropdown>
        <label class="float-label" for="float-input">Shipping Line *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="pastelCode" class="width-100" />
        <label class="float-label" for="float-input">Pastel Code *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="text" pInputText [(ngModel)]="description" class="width-100" />
        <label class="float-label" for="float-input">Description *</label>
      </span>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <input type="number" pInputText [(ngModel)]="amount" class="width-100" min="0" />
        <label class="float-label" for="float-input">Amount in ZAR *</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="!actionSpinner" pRipple class="p-button-info width-100" pButton type="button"
      [disabled]="!selectedShippingLine || !pastelCode || !description || !amount" (click)="addHandoverMatrix()"
      label="Save">
    </button>
    <button *ngIf="actionSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
      [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Select Border Post for RORO CIF Matrix" modal="true" [(visible)]="showNewROROCIFMatrixDialog"
  (onHide)="showNewROROCIFMatrixDialog = false" [style]="{ width: '450px', height: '190px' }">
  <div class="ui-g">
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="borderPosts" [(ngModel)]="selectedBorderPost" [filter]="true" appendTo="body"
          optionLabel="name" [autoDisplayFirst]="false" [style]="{ width:'100%' }">
        </p-dropdown>
        <label class="float-label" for="float-input">Border Post *</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info width-100" pButton type="button" [disabled]="!selectedBorderPost"
      (click)="addBorderPostToCIFROROMatrix()" label="Save">
    </button>
  </p-footer>
</p-dialog>