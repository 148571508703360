import { Injectable } from '@angular/core';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class FinanceApiService {

  private serverUrl = `${this.config.getServerUrl()}api/finance/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  //Endpoint: api/currency/rptBFJCIFRates
  //Controller: FinancialController.cs
  //Proc Name: pr_rptBFJCIFRates
  public async getBFJCIFRates(fromDate: string, toDate: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}rptBFJCIFRates?fromDate=${fromDate}&toDate=${toDate}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}