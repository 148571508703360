<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Document Folders / File System</h1>
  </div>

  <div class="ui-g-12">
    <p-table #dt [paginator]="true" [loading]="showLoadingSpinner" [rows]="50" [columns]="itemCols" editMode="row"
      [scrollable]="true" scrollHeight="350px" dataKey="id" [value]="documentCategory" [autoLayout]="true"
      [globalFilterFields]="['DocumentCategory', 'Path']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <!-- <span class="p-input-icon-left">
            <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add Document Category"
              (click)="addDocumentCategory()" type="button" style="float: right; margin-right: 15px"
              icon="fas fa-plus"></button>
          </span> -->
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let itemcol of itemCols">
            {{itemcol.header}}
          </th>
          <!-- <th> 
            Actions
          </th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing">
        <tr [pEditableRow]="rowData">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText class="width-100" type="text" [(ngModel)]="rowData.documentCategory" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.documentCategory}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText class="width-100" type="text" [(ngModel)]="rowData.path" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.path}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [options]="documentStatusOptions" [(ngModel)]="rowData.show" [filter]="true" appendTo="body"
                  [autoDisplayFirst]="false" [style]="{width: '100%'}">
                </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.show ? 'Active' : 'Disabled'}}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- <td>
            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="fas fa-pencil-alt"
              class="ui-button-info" pTooltip="Edit document category"></button>
            <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="fas fa-check" class="ui-button-success"
              style="margin-right: .5em" (click)="updateDocumentCategory(rowData)"
              pTooltip="Update document category"></button>
            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="fas fa-times"
              class="ui-button-danger" pTooltip="Cancel Edit"></button>
          </td> -->
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Add Document Category" modal="true" [(visible)]="displayDialog">
  <div class="p-grid">
    <div class="p-col-12 p-lg-4 p-md-4 p-sm-6">
      <label>Document Category :</label>
    </div>
    <div class="p-col-12 p-lg-8 p-md-8 p-sm-6">
      <input type="text" class="width-100" pInputText [(ngModel)]="newdocumentcategory" maxlength="30" />
    </div>
    <div class="p-col-12 p-lg-4 p-md-4 p-sm-6">
      <label>Folder Name :</label>
    </div>
    <div class="p-col-12 p-lg-8 p-md-8 p-sm-6">
      <input type="text" class="width-100" pInputText [(ngModel)]="newpath" />
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info width-100" pButton type="button" (click)="saveDocumentCategory()" label="Save"
      [disabled]="!newdocumentcategory || !newpath"></button>
  </p-footer>
</p-dialog>