import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services/api/auth/authentication.service';

@Component({
  selector: '[app-submenu]',
  templateUrl: './app.submenu.component.html',
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*'
      })),
      state('hidden', style({
        height: '0px'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {
  @Input() item: MenuItem;
  @Input() root: boolean;
  @Input() visible: boolean;
  _reset: boolean;
  activeIndex: number;
  username: string;

  constructor(public app: AppComponent, public authService: AuthenticationService,
  private cd: ChangeDetectorRef) { }
  
  async ngAfterViewChecked() {
    if (this.username != this.authService.getUsername) {
      this.username = this.authService.getUsername;
      this.cd.detectChanges();
    }
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }
    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index) ? null : index;
    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }
    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }
    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }
      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = index;
    }
  }
  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

}
