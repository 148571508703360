import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service'

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css']
})
export class WhatsappComponent implements OnInit {

  url: string = "http://localhost:3000/host/test.html"
  urlSafe: SafeResourceUrl

  constructor(private sanitizer: DomSanitizer, config: ConfigService) {
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }
    this.url = config.WA;
  }

  ngOnInit() { this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url); }

  receiveMessage: any = (event: any) => {
    if (event.data && event.data.name && !event.data.name.startsWith('metamask')) {
    }
  }
}
