<div class="ui-g">
  <div class="ui-g-12 center" *ngIf="showSpinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
    </p-progressSpinner>
  </div>

  <div class="ui-g-12" *ngIf="!showSpinner && financeSummary && !(financeSummary.invoices.length > 0)">
    <h2>No invoice(s) for file.</h2>
  </div>

  <p-card class="ui-g-12"
    *ngIf="!showSpinner && financeSummary && financeSummary.invoices && financeSummary.invoices.length > 0">
    <div class="summary">
      <div class="group">
        <i class="fas fa-receipt"></i>
        <h2>Total Owed</h2>
        <div *ngFor="let balance of financeSummary.outstandingBalance">
          <label>
            {{ balance.currencyCode }}
          </label>
          <label>
            {{ balance.amountOwed.toFixed(2) }}
          </label>
        </div>
      </div>
      <div class="group">
        <i class="fas fa-file-invoice-dollar"></i>
        <h2>Paid</h2>
        <div *ngFor="let balance of financeSummary.outstandingBalance">
          <label>
            {{ balance.currencyCode }}
          </label>
          <label>
            {{ formatAmountPaid(balance.amountPaid) }}
          </label>
        </div>
      </div>
      <div class="group">
        <i class="fas fa-solid fa-file-invoice"></i>
        <h2>Outstanding</h2>
        <!-- <h2 ngClass="{{(financeSummary.outstandingBalance.amountOutStanding < 0) ? 'overpaid' : ''}}">Outstanding</h2> -->
        <div *ngFor="let balance of financeSummary.outstandingBalance">
          <label>
            {{ balance.currencyCode }}
          </label>
          <label>
            {{ balance.amountOutStanding.toFixed(2) }}
          </label>
        </div>
      </div>
    </div>
  </p-card>

  <div class="ui-g-12"
    *ngIf="!showSpinner && financeSummary && financeSummary.invoices && financeSummary.invoices.length > 0">
    <p-tabView>
      <p-tabPanel header="Invoice(s)">
        <p-table #dt [value]="financeSummary.invoices" [paginator]="true" [rows]="50" [autoLayout]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['invoiceNumber','date','invoiceType', 'invoicee', 'discountAmount','vatAmount', 'totalAmount', 'invoiceStatus']"
          [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex custom-note-buttons">
              <span class="p-input-icon-left invoice-total">
                <label>Total Invoice(s) {{ dt.totalRecords }}</label>
              </span>
              <span class="p-input-icon-left p-ml-auto">
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search Invoice(s)" style="color: #fff;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr class="table-column-header">
              <th>#</th>
              <th>Type</th>
              <th>Date</th>
              <th>Invoicee</th>
              <th>Audit User</th>
              <th>Discount</th>
              <th>Vat</th>
              <th>Total</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr #toggleRow>
              <td>{{ rowData.invoiceNumber }}</td>
              <td>{{ rowData.invoiceType }}</td>
              <td>{{ formatDate(rowData.date) }}</td>
              <td>{{ rowData.invoicee }}</td>
              <td>{{ rowData.auditUser }}</td>
              <td>
                {{ 
                financeHelper.formatAmountWithCurrencySymbol(rowData.discountAmount, rowData.currencyCode, rowData.currencyId)
                }}
              </td>
              <td>
                {{ 
                financeHelper.formatAmountWithCurrencySymbol(rowData.vatAmount, rowData.currencyCode, rowData.currencyId)
                }}
              </td>
              <td>
                {{ 
                financeHelper.formatAmountWithCurrencySymbol(rowData.totalAmount, rowData.currencyCode, rowData.currencyId)
                }}
              </td>
              <td class="status">
                <label class="{{rowData.invoiceStatus.toLowerCase()}}">{{ rowData.invoiceStatus.toUpperCase() }}</label>
              </td>
              <td>
                <button pRipple pButton class="p-button-info margin-right-2px" type="button" icon="fas fa-book"
                  pTooltip="View line items" (click)="viewLineItems(rowData.invoiceId)"></button>
                <button pRipple pButton class="p-button-info" type="button" icon="fa fa-download"
                  pTooltip="Download Invoice"
                  (click)="documentApiService.downloadDocument(rowData.documentId, fileId + '_Invoice_' + rowData.invoiceId + '.pdf')"></button>
                <button *ngIf="rowData.invoiceStatusId == 1 && isAccounts && rowData.invoiceType != 'Storage'" pRipple
                  pButton class="p-button-info margin-right-2px" type="button" icon="fa fa-edit" pTooltip="Edit Invoice"
                  (click)="editInvoice(rowData)"></button>
                <button *ngIf="rowData.invoiceStatusId == 1 && isAccounts" pRipple pButton class="p-button-info"
                  type="button" icon="far fa-times-circle" pTooltip="Cancel Invoice"
                  (click)="displayCancelInvoiceDialog(rowData)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No results found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <p-tabPanel header="Transaction(s)">
        <p-table #st [value]="statementLineItems" [paginator]="true" [rows]="50" [autoLayout]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['effectiveDate','transactionType','description', 'amount','invoiceNumber','invoiceType']"
          [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex custom-note-buttons">
              <span class="p-input-icon-left">
                <button *ngIf="financeUser" pRipple class="p-button-info" label="Add Admin Transaction" pButton
                  type="button" (click)="showTransactionDialog = true;"></button>
              </span>
              <span class="p-input-icon-left p-ml-auto">
                <input pInputText type="text" (input)="st.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search Transaction(s)" style="color: #fff;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr class="table-column-header">
              <th>#</th>
              <th>Invoice Type</th>
              <th>Date</th>
              <th class="transaction-type">Transaction Type</th>
              <th>Reference</th>
              <th class="amounts">Amount</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
              <td>{{ rowData.invoiceNumber }}</td>
              <td>{{ rowData.invoiceType }}</td>
              <td>{{ formatDate(rowData.effectiveDate) }}</td>
              <td class="status">
                <label ngClass="{{ setTransactionTypeColour(rowData.transactionTypeId)}}">
                  {{ rowData.transactionType.toUpperCase() }}
                </label>
              </td>
              <td>{{ rowData.description }}</td>
              <td class="amounts">
                {{ financeHelper.formatAmountWithCurrencySymbol(rowData.amount, rowData.currencyCode, rowData.currencyId) }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <p-tabPanel header="Transaction Intent(s)">
        <p-table #ti [value]="fileTransactionIntents" [paginator]="true" [rows]="50" [autoLayout]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['amount','transactionType','transactionIntentStatus','createdBy','paymentDate','actionedBy','rejectReason','transactionIntentReason']"
          [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex custom-note-buttons">
              <span class="p-input-icon-left invoice-total">
                <label>Total Transaction Intents(s) {{ ti.totalRecords }}</label>
              </span>
              <span class="p-input-icon-left p-ml-auto">
                <input pInputText type="text" (input)="ti.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search Transaction Intent(s)" style="color: #fff; margin-right: 10px;
                  width: 100%;" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr class="table-column-header">
              <th>Created By</th>
              <th>Created On</th>
              <th>Type</th>
              <th>Status</th>
              <th>Actioned By</th>
              <th>Intent Reason</th>
              <th>Reject Reason</th>
              <th>Effective Date</th>
              <th class="intent-amounts">Amount</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
              <td>{{ rowData.createdBy }}</td>
              <td>{{ formatDate(rowData.createdDate) }}</td>
              <td class="status">
                <label ngClass="{{ setTransactionTypeColour(rowData.transactionTypeId)}}">
                  {{ rowData.transactionType.toUpperCase() }}
                </label>
              </td>
              <td class="status">
                <label ngClass="{{ setTransactionIntentStatusColour(rowData.transactionIntentStatusId)}}">
                  {{ rowData.transactionIntentStatus.toUpperCase() }}
                </label>
              </td>
              <td>
                <label>{{ rowData.actionedBy || 'N/A' }}</label>
              </td>
              <td class="format-text">
                <label>{{ rowData.transactionIntentReason }}</label>
              </td>
              <td class="format-text">
                <label>{{ rowData.rejectReason || 'N/A' }}</label>
              </td>
              <td>{{ formatDate(rowData.effectiveDate) }}</td>
              <td class="intent-amounts">
                {{ financeHelper.formatAmountWithCurrencySymbol(rowData.amount, rowData.currencyCode, rowData.currencyId) }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No transaction intents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog *ngIf="viewInvoiceLineItem" [(visible)]="viewInvoiceLineItem" (onHide)="viewInvoiceLineItem = false"
  header="{{ invoiceNumber }} Line Item(s)" modal="true" [style]="{'width': '65%', 'height': 'auto'}">
  <p-table #dt [autoLayout]="true" [value]="financeSummary.invoices[selectedInvoiceIndex].lineItems">
    <ng-template pTemplate="header">
      <tr class="table-column-header">
        <th>Description</th>
        <th>Pastel Code</th>
        <th>Quantity</th>
        <th>Unit</th>
        <th>Net</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData.description}}</td>
        <td>{{ rowData.pastelCode}}</td>
        <td>{{ rowData.quantity}}</td>
        <td>
          {{ 
            financeHelper.formatAmountWithCurrencySymbol(rowData.amount, financeSummary.invoices[selectedInvoiceIndex].currencyCode, financeSummary.invoices[selectedInvoiceIndex].currencyId)
          }}
        </td>
        <td>
          {{ 
            financeHelper.formatAmountWithCurrencySymbol(rowData.netAmount, financeSummary.invoices[selectedInvoiceIndex].currencyCode, financeSummary.invoices[selectedInvoiceIndex].currencyId)
          }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">No invoice(s) found.</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-dialog [(visible)]="cancelInvoiceDialog" [draggable]="false" (onHide)="cancelInvoiceDialog = false"
  [closable]="false" [closeOnEscape]="false" header="Cancel Invoice Confirmation" modal="true"
  [style]="{'width': '35%', 'height': 'auto'}">
  <div class="cancel-invoice-dialog">
    <label>Are you sure you want to cancel invoice <strong>{{invoiceNumber}}</strong>?</label>
    <div class="cancel-invoice-dialog-buttons">
      <button [disabled]="cancelInvoiceSpinner" type="button" pButton icon="pi pi-times" label="No"
        (click)="cancelInvoiceDialog = false"></button>
      <button *ngIf="cancelInvoiceSpinner" pButton type="button" label="" [disabled]="true">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
      <button *ngIf="!cancelInvoiceSpinner" type="button" pButton icon="pi pi-check" label="Yes"
        (click)="cancelInvoice()"></button>
    </div>
  </div>
</p-dialog>

<p-dialog *ngIf="showTransactionDialog" [(visible)]="showTransactionDialog" [draggable]="false"
  (onHide)="showTransactionDialog = false" [closable]="false" modal="true" [style]="{'width': '30%', 'height': 'auto'}">
  <p-header>
    Add Admin Transaction
  </p-header>

  <div class="ui-g-12 dialog">
    <span class="ui-g-12 ui-float-label">
      <p-dropdown [options]="transactionIntentTypes" [autoDisplayFirst]="false" [style]="{'width':'100%'}"
        [(ngModel)]="selectedTransactionType">
      </p-dropdown>
      <label class="float-label" for="float-input">Transaction Type*</label>
    </span>

    <span class="ui-g-12 ui-float-label">
      <p-dropdown [options]="currencies" [autoDisplayFirst]="false" [style]="{'width':'100%'}"
        [(ngModel)]="selectedCurrency" optionLabel="currency" [disabled]="!selectedTransactionType">
      </p-dropdown>
      <label class="float-label" for="float-input">Currency*</label>
    </span>

    <span class="ui-g-12 ui-float-label">
      <label *ngIf="showCurrencySymbol">{{ selectedCurrency?.symbol }}</label>
      <input class="width-100 payment-amount" type="text" [disabled]="!selectedCurrency" pInputText
        [(ngModel)]="paymentAmount" required (click)="showCurrencySymbol = true" (keyup)="onPaymentAllocateValidate()"
        (focus)="paymentAmount ? showCurrencySymbol = true : showCurrencySymbol = false"
        (focusout)="paymentAmount ? showCurrencySymbol = true : showCurrencySymbol = false" />
      <label class="float-label" for="float-input">Amount*</label>
    </span>

    <span class="ui-g-12 ui-float-label">
      <p-calendar [(ngModel)]="paymentDate" class="width-100" appendTo="body" [readonlyInput]="true"
        dateFormat="yy/mm/dd" [disabled]="!selectedCurrency || !paymentAmount">
      </p-calendar>
      <label class="float-label" for="float-input">Date*</label>
    </span>

    <textarea [(ngModel)]="reasonForTransaction" [rows]="8" [cols]="30"
      [disabled]="!selectedCurrency || !paymentAmount || !selectedTransactionType" maxlength="280"
      placeholder="Please provide the reason for the admin transaction*" pInputTextarea></textarea>

    <button *ngIf="!addingAdminTransaction" pRipple pButton label="Add Admin Transaction"
      (click)="addAdminTransaction()" [disabled]="!paymentAmount || !selectedCurrency || !paymentDate || !reasonForTransaction ||
      !selectedTransactionType"></button>

    <button *ngIf="addingAdminTransaction" [disabled]="true" pRipple class="p-button-info" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>

    <button pRipple pButton label="Close" (click)="showTransactionDialog = false"
      [disabled]="addingAdminTransaction"></button>
  </div>
</p-dialog>