import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogBase } from '../services/logger.service';
import { HttpResponse } from '../interfaces/result-interfaces';
import { AuthenticationService } from './api/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WebApi2Service {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private log: LogBase
  ) { }

  async get(user: string, apiUrl: string) {
    let res: any;

    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Token", this.authenticationService.getSessionToken ?? '')
      .set("Id", this.authenticationService.getLegalEntityIdForApiHeaders ?? '');
    try {
      await this.http.get(apiUrl, { 'headers': headers }).toPromise()
        .then((response: Response) => {
          if (response) {
            res = response;
            if (res.errorCode != -2) {
              if (res.errorCode === 0 || res.errorCode === 15) {
              }
            } else {
              this.authenticationService.logout()
            }
          } else {
            res = false;
          }
        })
        .catch((error: Response) => {
          res = error.json();
        });
    } catch (e) {
      this.log.error(e);
      return e.json();
    }
    return res;
  }

  async post(user: string, apiUrl: string, variables: string) : Promise<HttpResponse> {
    let res: any;

    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Token", this.authenticationService.getSessionToken ?? '')
      .set("Id", this.authenticationService.getLegalEntityIdForApiHeaders ?? '');

    try {
      await this.http.post(apiUrl, variables, { 'headers': headers }).toPromise()
        .then((response: Response) => {
          if (response) {
            res = response;
            if (res.errorCode != -2) {
              if (res.errorCode === 0 || res.errorCode === 15) {
              }
            } else {
              this.authenticationService.logout()
            }
          } else {
            res = false;
          }
        })
        .catch((error: Response) => {
          res = error.json();
        });
    } catch (e) {
      this.log.error(e);
      return e.json();
    }
    return res;
  }
}