import { Component, OnInit } from '@angular/core';
import { Api2Service } from '../../services/api2.service';
import { LogBase } from '../../services/logger.service';
import { MessageService } from 'primeng';
import { VoyageCentric, FilesAtLocation, FilesNotArrivedAtFinalDestion, AccordionLocationData, TableColumns } from '../../interfaces/global.interfaces';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ClearingStatus, LocationTypes } from '../../enums';

@Component({
  selector: 'cars-in-durban',
  templateUrl: './cars-in-durban.component.html',
  styleUrls: ['./cars-in-durban.component.scss']
})
export class CarsInDurbanComponent implements OnInit {

  //Interfaces
  public files: VoyageCentric[] = [];
  public uniqueVessels: VoyageCentric[] = [];
  public locationFiles: FilesAtLocation[] = [];
  public undeliveredFiles: FilesNotArrivedAtFinalDestion[] = [];
  public filterWarehouse: FilesNotArrivedAtFinalDestion[] = [];
  public filterPort: FilesNotArrivedAtFinalDestion[] = [];
  public filterTransit: FilesNotArrivedAtFinalDestion[] = [];
  public uniqueWarehouse: AccordionLocationData[] = [];
  public uniquePort: AccordionLocationData[] = [];
  public uniqueTransit: AccordionLocationData[] = [];
  public bottomTable: FilesNotArrivedAtFinalDestion[] = [];

  //Variables
  public locName: string = '';
  public countOne: number = 0;
  public countTwo: number = 0;
  public countThree: number = 0;
  public countFour: number = 0;
  public bottomTableHeading: string = '';

  //Booleans
  public showSpinner: boolean = false;
  public display: boolean = false;

  public headers: TableColumns[] = [
    { field: "vesselName", header: "Vessel Name" },
    { field: "voyageNumber", header: "Voyage Number" },
    { field: "totalCount", header: "Total Count" },
    { field: "dbnCount", header: "DBN Count" },
    { field: "reCount", header: "RE Count" },
    { field: "phCount", header: "PH Count" },
    { field: "eta", header: "ETA" }
  ];

  constructor(
    private log: LogBase,
    private messageService: MessageService,
    private api: Api2Service,
  ) { }

  async ngOnInit() {
    this.showSpinner = true;
    await this.getFielsAtDurbanAfterETA();
    await this.getFilesNotDeliveredToFinalDestination();
    this.showSpinner = false;
  }

  //Voyage Centric
  private async getFielsAtDurbanAfterETA() {
    try {
      const result = await this.api.SummaryDashboardCargoTracker();
      if (!result) {
        this.toastMessage('error', 'Failed to get files at durban. Please contact support.', '');
        return;
      }

      this.files = result;
      for (let index = 0; index < this.files.length; index++) {
        this.files[index].eta = moment(this.files[index].eta).format('MM/DD/YYYY');
      }
      this.uniqueVessels = _.uniqWith(this.files, (x, y) => {
        return x.vesselId == y.vesselId && x.voyageId == y.voyageId;
      }); //removed if had duplicate voyage number
      //
      this.CountVesselVoyageClearingStatus();
    }
    catch (ex) {
      this.toastMessage('error', 'Failed to get files at durban. Please contact support.', '');
      this.log.error(ex);
    }
  }

  private CountVesselVoyageClearingStatus() {
    for (let index = 0; index < this.files.length; index++) { //loop over all files
      const file = this.files[index];
      const vesselIndex = this.uniqueVessels.findIndex(v => v.voyageId == file.voyageId && v.vesselId == file.vesselId);//give current vessel 
      if (file.clearingStatusId == ClearingStatus.AVECS) {
        if (!this.uniqueVessels[vesselIndex].dbnCount) this.uniqueVessels[vesselIndex].dbnCount = 0;
        this.uniqueVessels[vesselIndex].dbnCount += file.clearingStatusCount;
      }
      else if (file.clearingStatusId == ClearingStatus.HANDOVER) {
        if (!this.uniqueVessels[vesselIndex].reCount) this.uniqueVessels[vesselIndex].reCount = 0;
        this.uniqueVessels[vesselIndex].reCount += file.clearingStatusCount;
      }
      else if (file.clearingStatusId == ClearingStatus.PARTIAL_HANDOVER) {
        if (!this.uniqueVessels[vesselIndex].phCount) this.uniqueVessels[vesselIndex].phCount = 0;
        this.uniqueVessels[vesselIndex].phCount += file.clearingStatusCount;
      }
      if (!this.uniqueVessels[vesselIndex].totalCount) this.uniqueVessels[vesselIndex].totalCount = 0;
      this.uniqueVessels[vesselIndex].totalCount += file.clearingStatusCount;
    }
  }

  private async getFilesAtLocation(VoyageId: number, LocationId: number) {
    try {
      const locFiles = await this.api.GetFilesAtCurrentLocation(VoyageId, LocationId);
      if (!locFiles) {
        this.toastMessage('error', 'Failed to get files at current location. Please contact support.', '');
        return;
      }

      this.locationFiles = locFiles;
    }
    catch (ex) {
      this.toastMessage('error', 'Failed to get files at current location. Please contact support.', '');
      this.log.error(ex);
    }
  }

  //Location Centric
  private async getFilesNotDeliveredToFinalDestination() {
    try {
      const result = await this.api.getFilesNotDelivered();
      if (!result) {
        this.toastMessage('error', 'Failed to get files not delivered. Please contact support.', '');
        return;
      }
      this.undeliveredFiles = result;
      this.FilterLocationsUndeliveredFiles();
    }
    catch (er) {
      this.toastMessage('error', 'Failed to get files not delivered. Please contact support.', '');
      this.log.error(er);
    }
  }

  private FilterLocationsUndeliveredFiles() {
    //Filter the files at warehouses
    this.filterWarehouse = _.filter(this.undeliveredFiles, function (o) {
      return o.currentLocationTypeId == LocationTypes.STORAGE_POINT;
    });

    //Filter the files at ports
    this.filterPort = _.filter(this.undeliveredFiles, function (p) {
      return p.currentLocationTypeId == LocationTypes.PORT;
    });

    //Filter the in transit
    this.filterTransit = _.filter(this.undeliveredFiles, function (t) {
      return t.currentLocationTypeId == 0;
    });

    this.GetUniqueLocationsForAccordions();
    this.getCountOfFilesWithDaysSinceETA();
  }

  //get unique locations instead of 100 of the same to show in the accordion table
  private GetUniqueLocationsForAccordions() {
    //Unique Warehouses
    const uniqueWarehouse = _.uniqBy(this.filterWarehouse, 'currentLocationId'); //removed if had duplicate location id
    //Unique Ports
    const uniquePort = _.uniqBy(this.filterPort, 'currentLocationId'); //removed if had duplicate location id
    //Unique in transits
    const uniqueTransit = _.uniqBy(this.filterTransit, 'currentLocation');

    for (let index = 0; index < uniqueWarehouse.length; index++) {
      const element = uniqueWarehouse[index];
      this.uniqueWarehouse.push({
        currentLocationId: element.currentLocationId,
        currentLocationName: element.currentLocation,
        daysSinceETA: element.daysSinceETA,
        lessThanFortyFiveDays: 0,
        fourtyFiveDaysToThreeMonths: 0,
        threeToFourMonths: 0,
        overFiveMonths: 0
      });
    }
    for (let index = 0; index < uniquePort.length; index++) {
      const element = uniquePort[index];
      this.uniquePort.push({
        currentLocationId: element.currentLocationId,
        currentLocationName: element.currentLocation,
        daysSinceETA: element.daysSinceETA,
        lessThanFortyFiveDays: 0,
        fourtyFiveDaysToThreeMonths: 0,
        threeToFourMonths: 0,
        overFiveMonths: 0
      });
    }
    for (let index = 0; index < uniqueTransit.length; index++) {
      const element = uniqueTransit[index];
      this.uniqueTransit.push({
        currentLocationId: element.currentLocationId,
        currentLocationName: element.currentLocation,
        daysSinceETA: element.daysSinceETA,
        lessThanFortyFiveDays: 0,
        fourtyFiveDaysToThreeMonths: 0,
        threeToFourMonths: 0,
        overFiveMonths: 0
      });
    }
  }

  private getCountOfFilesWithDaysSinceETA() {
    // Get count of files per each ETA time passed for each warehouse 
    for (let index = 0; index < this.filterWarehouse.length; index++) {
      const element = this.filterWarehouse[index];
      const warehouseIndex = this.uniqueWarehouse.findIndex(w => w.currentLocationId == element.currentLocationId);//give current warehouse
      if (element.daysSinceETA <= 45) {
        this.countOne++;
        this.uniqueWarehouse[warehouseIndex].lessThanFortyFiveDays++; //+= this.countOne;
      }
      else if (element.daysSinceETA > 45 && element.daysSinceETA <= 91) {
        this.countTwo++;
        this.uniqueWarehouse[warehouseIndex].fourtyFiveDaysToThreeMonths++; //+= this.countTwo;
      }
      else if (element.daysSinceETA > 91 && element.daysSinceETA <= 122) {
        this.countThree++;
        this.uniqueWarehouse[warehouseIndex].threeToFourMonths++; //+= this.countThree;
      }
      else if (element.daysSinceETA > 122) {
        this.countFour++;
        this.uniqueWarehouse[warehouseIndex].overFiveMonths++; //+= this.countFour;
      }
    }

    // Get count of files per each ETA time passed for each port 
    for (let index = 0; index < this.filterPort.length; index++) {
      const element = this.filterPort[index];
      const portIndex = this.uniquePort.findIndex(p => p.currentLocationId == element.currentLocationId);//give current warehouse
      if (element.daysSinceETA <= 45) {
        this.countOne++;
        this.uniquePort[portIndex].lessThanFortyFiveDays++; //+= this.countOne;
      }
      else if (element.daysSinceETA > 45 && element.daysSinceETA <= 91) {
        this.countTwo++;
        this.uniquePort[portIndex].fourtyFiveDaysToThreeMonths++; //+= this.countTwo;
      }
      else if (element.daysSinceETA > 91 && element.daysSinceETA <= 122) {
        this.countThree++;
        this.uniquePort[portIndex].threeToFourMonths++; //+= this.countThree;
      }
      else if (element.daysSinceETA > 122) {
        this.countFour++;
        this.uniquePort[portIndex].overFiveMonths++; //+= this.countFour;
      }
    }
    for (let index = 0; index < this.filterTransit.length; index++) {
      const element = this.filterTransit[index];
      const transitIndex = this.uniqueTransit.findIndex(t => t.currentLocationName == element.currentLocation);//give current warehouse
      if (element.daysSinceETA <= 45) {
        this.countOne++;
        this.uniqueTransit[transitIndex].lessThanFortyFiveDays++; //+= this.countOne;
      }
      else if (element.daysSinceETA > 45 && element.daysSinceETA <= 91) {
        this.countTwo++;
        this.uniqueTransit[transitIndex].fourtyFiveDaysToThreeMonths++; //+= this.countTwo;
      }
      else if (element.daysSinceETA > 91 && element.daysSinceETA <= 122) {
        this.countThree++;
        this.uniqueTransit[transitIndex].threeToFourMonths++; //+= this.countThree;
      }
      else if (element.daysSinceETA > 122) {
        this.countFour++;
        this.uniqueTransit[transitIndex].overFiveMonths++; //+= this.countFour;
      }
    }
  }

  public getFileDetailsOnAccordionTableDataClick(currentLocation: string, ruleType?: ETARuleType) {
    this.bottomTable = this.undeliveredFiles.filter(f => f.currentLocation == currentLocation);
    this.bottomTableHeading = currentLocation;

    if (ruleType == ETARuleType.LESS_THAN_FORTY_FIVE_DAYS) {
      this.bottomTable = this.bottomTable.filter(f => f.daysSinceETA <= 45);
    } else if (ruleType == ETARuleType.FOURTY_FIVE_DAYS_TO_THREE_MONTHS) {
      this.bottomTable = this.bottomTable.filter(f => f.daysSinceETA > 45 && f.daysSinceETA <= 91);
    } else if (ruleType == ETARuleType.THREE_TO_FOUR_MONTHS) {
      this.bottomTable = this.bottomTable.filter(f => f.daysSinceETA > 91 && f.daysSinceETA <= 122);
    } else if (ruleType == ETARuleType.OVER_FIVE_MONTHS) {
      this.bottomTable = this.bottomTable.filter(f => f.daysSinceETA > 122);
    }

    console.log(this.bottomTable);
    if (this.bottomTable.length === 0) {
      this.toastMessage('info', `No files to view at ${currentLocation}`, '');
      return;
    }

    this.goToBottom();
  }

  //Action to view a file directly from the tables
  public viewFile(dbnNumber: string) {
    const fileId = dbnNumber.split('/')[1];
    window.open(`file-view?query=${fileId}`);
  }
  //Dialogs
  public showDialog(locationId: number, voyageId: number, locationName: string) {
    this.getFilesAtLocation(voyageId, locationId);
    this.locName = locationName;
    this.display = true;
  }

  private goToBottom() {
    setTimeout(() =>
      window.scroll({
        top: document.body.scrollHeight - window.innerHeight,
        behavior: "smooth",
      }), 105
    );
  }
  private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}

export enum ETARuleType {
  LESS_THAN_FORTY_FIVE_DAYS = 1,
  FOURTY_FIVE_DAYS_TO_THREE_MONTHS = 2,
  THREE_TO_FOUR_MONTHS = 3,
  OVER_FIVE_MONTHS = 4
}