import { Component } from '@angular/core';
import { MessageService } from 'primeng';
import { BFJCIFImportIssues, BFJCIFRates } from '../../../interfaces/finance-interfaces';
import { LogBase } from '../../../services/logger.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ExcelService } from '../../../services/excel.service';
import { FinanceApiService } from '../../../services/api/finance/finance-api.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { ConfigService } from '../../../services/config.service';
import { HttpResponse } from '../../../interfaces/result-interfaces';

@Component({
  selector: 'bfj-cif',
  templateUrl: './bfj-cif.component.html',
  styleUrls: ['./bfj-cif.component.scss']
})
export class BfjCifComponent {

  public rates: BFJCIFRates[] = [];
  public filterRatesExceptions: BFJCIFRates[] = null;
  public filterRates: BFJCIFRates[] | null = null;
  public bfjCIFImportIssues: BFJCIFImportIssues[] = [];

  public startDate: any;
  public endDate: any;
  public fromDate: any;
  public toDate: any;
  public keyword: string = "Exception";
  public uploadMimeTypes: string = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv';
  public apiUrl: string = `${this.config.getServerUrl()}api/finance/UploadBFJCIFRates`;
  public headers: HttpHeaders = new HttpHeaders()
    .set("Token", this.authService.getSessionToken ?? '')
    .set("Id", this.authService.getLegalEntityId.toString() ?? '');
  public errors: string = '';
  public showSpinner: boolean = false;
  public showUploadSpinner: boolean = false;

  constructor(
    private financeApiService: FinanceApiService,
    private config: ConfigService,
    private authService: AuthenticationService,
    private log: LogBase,
    private messageService: MessageService,
    private excelService: ExcelService) { }

  async getBFJCIFRates() {
    this.showSpinner = true;
    this.rates = [];
    try {
      this.fromDate = moment(this.startDate).format('yy/MM/DD');
      this.toDate = moment(this.endDate).format('yy/MM/DD');
      const retval = await this.financeApiService.getBFJCIFRates(this.fromDate, this.toDate);
      if (!retval) this.toastMessage('error', 'Failed to get bfj cif rates. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.rates = retval.result;
        this.filterRates = this.rates.filter(item =>
          typeof item.amount !== 'string' || !item.amount.includes(this.keyword),
        );
        this.filterRatesExceptions = this.rates.filter(item =>
          typeof item.amount === 'string' && item.amount.includes(this.keyword)
        );
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to bfj cif rates. Please contact suppport', '');
      this.log.error(err);
    }
    this.showSpinner = false;
  }

  public onError() {
    this.showUploadSpinner = false;
    this.toastMessage('error', 'Failed to upload bfj cif rates. Please contact support.', '');
    document.getElementById("upload").style.display = 'inherit';
    this.errors = '';
  }

  public onBeforeUpload() {
    this.errors = '';
    this.showUploadSpinner = true;
    document.getElementById("upload").style.display = 'none';
  }

  public async onUpload(event: any) {
    this.showUploadSpinner = false;
    try {
      document.getElementById("upload").style.display = 'inherit';
      const resp: HttpResponse = event.originalEvent.body;
      if (!resp) {
        this.toastMessage('error', 'Failed to upload bfj cif rates. Please contact support.', '');
        this.bfjCIFImportIssues = [];
      } else if (resp.errorCode !== 0) {
        this.bfjCIFImportIssues = [];
        this.toastMessage('error', resp.errorMessage, '');
        this.bfjCIFImportIssues = resp.result;
      } else {
        this.toastMessage('success', resp.errorMessage, '');
        this.bfjCIFImportIssues = [];
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to upload bfj cif rates. Please contact suppport', '');
      this.log.error(err);
      this.errors = '';
    }
  }

  //export excel sheet for bfh cif rates without exceptions
  public async generateExcel() { await this.excelService.generateGenericExcel(this.filterRates, `BFJ CIF - No Exceptions ${this.startDate} + ${this.fromDate}`); }
  //export excel sheet for bfh cif rates with exceptions
  public async generateExcelExceptions() { await this.excelService.generateGenericExcel(this.filterRatesExceptions, `BFJ CIF - Exceptions ${this.startDate} - ${this.endDate}`); }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
