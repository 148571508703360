import { RoutesApiService } from './../../../services/api/routes-api.service';
import { Location } from '@angular/common';
import { ConfigService } from '../../../services/config.service';
import { LogBase } from '../../../services/logger.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Region, Country, ILocationComponent, RouteLegs, MasterRoute, TableColumns, RouteList, RouteType, Dropdown, FilesThatUserRoute, RouteLegsList, SelectedMasterRoute } from '../../../interfaces/global.interfaces';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Api2Service } from '../../../services/api2.service';
import * as _ from 'lodash'

@Component({
  selector: 'app-route-builder',
  templateUrl: './route-builder.component.html',
  styleUrls: ['./route-builder.component.css'],
  providers: [ ConfirmationService]
})

export class RouteBuilderComponent implements OnInit {

  //forms
  locationForm: FormGroup;

  //route list
  routes: RouteList[] = [];
  cols: TableColumns[] = [
    { field: 'loadLocationName', header: 'Load Location Name' },
    { field: 'lnLoadLocationName', header: 'Unload Location Name' },
    { field: 'routeType', header: 'Route Type' },
  ]
  selectedRouteNotation: string = '';

  //master route
  masterRoute: MasterRoute = null
  routeLeg: RouteLegs = null;
  routeLegs: RouteLegs[] = []
  //search location component 
  startMasterRouteLocation: ILocationComponent = null;
  endMasterRouteLocation: ILocationComponent = null;
  selectedCity: any;
  routeTypes: RouteType[] = []
  startMasterRouteLeg: ILocationComponent = null
  startRouteLegLocationType: number = 0;
  endMasterRouteLeg: ILocationComponent = null
  routeLegType: RouteType = null;
  disableMasterRoutes: boolean = false;

  //add location
  selectedCountry: string;
  countryId: number;
  selectedRegion: string;
  regionId: number;
  selectedCityId: number;
  selectedOption: string = '';
  locationType: number
  countries: Country;
  regions: Region;

  //add route leg
  masterRoutes: Dropdown[] = [];
  selectedMaster: Dropdown = null;
  selectedMasterRoute: SelectedMasterRoute = null;
  startRouteLeg: ILocationComponent = null;
  endRouteLeg: ILocationComponent = null;
  selectedRouteLegIndex: number;
  searchedMasterRoute: Dropdown = null

  //for screen 
  showSpinner: boolean = false;
  state: any;
  showBackButton: boolean = false;
  selectedTabIndex: number = 0;
  spinnerIcon: string = '';
  waitingResponse: boolean = false;
  lastToastMessage: string = '';
  lastToastMessageDate: number = 0;
  filesThatUserMasterRoute: FilesThatUserRoute[];
  displayErrorDialog: boolean = false;
  displayDialog: boolean = false;
  waitingResults: boolean = false;
  showSpinnerForAction: boolean = false;
  errorMessage: string = '';

  constructor(
    private api: ApiService,
    private api2: Api2Service,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private log: LogBase,
    private config: ConfigService,
    private routesApiService: RoutesApiService,
    private messageService: MessageService,
    private _location: Location,
    private cd: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.lastToastMessageDate = Date.now();
    this.clearMasterRoute();
    this.clearAddLocation();
    this.clearRouteLeg();
    this.showSpinner = true;
    await this.getAllRoutes();
    await this.getRouteTypes();
    await this.getCountries();
    await this.getURLParams();
    this.checkState();
    this.showSpinner = false;
  }

  async getURLParams() {
    try {
      this.route.paramMap.subscribe(
        params => this.state = params.get('state')
      );
    } catch (error) {
      this.log.error(error);
    }
  }

  checkState() {
    if (this.state === 'newfile' || this.state === 'dispatch') {
      this.showBackButton = true;
      this.selectedTabIndex = 1
    }
  }

  //route list
  async getAllRoutes() {
    try {
      let res = await this.api2.getAllRoutes();
      if (!res) {
        this.toastMessage('error', 'Could not get all routes. Please contact support.')
      } else {
        this.routes = res;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get all routes. Please contact support.')
      this.log.error(error);
    }
  }

  //return button label depending if route master is active or not
  checkRouteActiveState(value) {
    if (value.active == 1) {
      return 'Deactivate';
    }
    else if (value.active == 0) {
      return 'Activate'
    }
  }

  //sets route to activated or deactivated 
  async setActiveState(value) {
    this.showSpinnerForAction = true;
    if (!await this.checkIfFilesAreUsingMasterRoute(value.id)) {
      this.errorMessage = "These file(s) use this master route and you cannot change the active state. You will have to reassign these file(s) to another route before adjusting this route's active status."
      let legs = _.filter(this.routes, (x) => {
        return x.parentRouteId == value.id
      })
      this.selectedRouteNotation = this.displayRouteLegs(legs)
      this.showSpinnerForAction = false;
      return;
    }
    
    //change the active state of the row to false
    let route = { ...value };
    value.active ? route.active = 0 : route.active = 1
    let resp = await this.api2.setMasterRouteActiveState(route);
    if (!resp) {
      this.toastMessage('error', 'Failed to update active state of master route');
    }
    else {
      await this.getAllRoutes();
      this.toastMessage('success', "Master route's active state has been updated")
    }
    this.showSpinnerForAction = false;
  }

  //add master route
  onSelectMasterRouteStart(event: ILocationComponent) {
    if (this.routeLegs && this.routeLegs.length == 0) {
      this.startMasterRouteLeg = event
      this.startRouteLegLocationType = this.startMasterRouteLeg.LocationTypeId
    }
    this.refresh();
  }

  onSelectMasterRouteEnd(event: ILocationComponent) {
    this.endMasterRouteLocation = event;
    this.refresh();
  }

  onSelectStartMasterRouteLeg(event: ILocationComponent) {
    this.startMasterRouteLeg = event
    this.refresh();
  }

  onSelectEndMasterRouteLeg(event: ILocationComponent) {
    this.endMasterRouteLeg = event
    this.refresh();
  }

  async getRouteTypes() {
    try {
      let res = await this.api2.getRouteTypes();
      if (!res) {
        this.toastMessage('error', 'Failed to get route types')
      } else {
        this.routeTypes = res;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to route types. Please contact support.')
      this.log.error(error);
    }
  }

  async addRouteLeg() {   
    this.routeLeg = {
      loadLocationName: this.startMasterRouteLeg.Location,
      loadLocationType: this.startMasterRouteLeg.LocationType,
      unLoadLocationName: this.endMasterRouteLeg.Location,
      unloadLocationType: this.endMasterRouteLeg.LocationType,
      loadLocationId: this.startMasterRouteLeg.Id,
      unloadLocationId: this.endMasterRouteLeg.Id,
      routeTypeId: this.routeLegType.id,
      routeType: this.routeLegType.description
    }

    //validate route before adding it to the route legs
    if (this.validateAddRouteLeg()) return;

    //disable the option to change master route if user has already added a route leg
    if (this.routeLegs && this.routeLegs.length == 0) {
      this.disableMasterRoutes = true;
      this.refresh();
    }

    this.routeLegs.push(this.routeLeg)
    this.setStartRouteLegToPreviousEndRouteLeg();
    this.toastMessage('success', 'Route leg added');
    this.refresh();
  }

  validateAddRouteLeg() {
    for (var item of this.routeLegs) {
      if (this.routeLeg.loadLocationId == item.loadLocationId && this.routeLeg.unloadLocationId == item.unloadLocationId) {
        this.toastMessage('error', 'Cannot add route leg with the same start and end route.');
        return true;
      }
      else if (this.routeLeg.loadLocationId == item.loadLocationId) {
        this.toastMessage('error', 'Start leg already exists.');
        return true;
      }
      else if (this.routeLeg.unloadLocationId == item.unloadLocationId) {
        this.toastMessage('error', 'End leg already exists.');
        return true;
      }
    }

    if (_.isEqual(this.startMasterRouteLocation, this.endMasterRouteLocation)) {
      this.toastMessage('error', 'Master route start and end cannot be the same.');
      return true;
    }
    //if the route type is sea and the start and end leg are not port, then route type is invalid
    else if (this.routeLegType.id == 1 && (this.startMasterRouteLeg.LocationTypeId != 1 || this.endMasterRouteLeg.LocationTypeId != 1)) {
      this.toastMessage('error', 'Invalid route type selected.')
      return true;
    }
    else if (_.isEqual(this.startMasterRouteLeg, this.endMasterRouteLeg)) {
      this.toastMessage('error', 'Cannot add route leg with the same start and end route.');
      return true
    }
    //if the last unload location leg of the route legs matches the end master route then disable the add route leg button
    else if (this.routeLegs.length >=1 && this.endMasterRouteLocation.Id == this.routeLegs[this.routeLegs.length - 1].unloadLocationId) {
      this.toastMessage('error', 'Your end master route leg is already added to the route.');
      return true;
    }
    return false;
  }

  setStartRouteLegToPreviousEndRouteLeg() {
    this.startMasterRouteLeg = { ... this.endMasterRouteLeg };
    this.startRouteLegLocationType = this.startMasterRouteLeg.LocationTypeId
    this.endMasterRouteLeg = null;
    this.routeLegType = null;
  }

  clearBuiltRoute() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to clear your route? This is permanent and will require you to rebuild your route.',
      accept: () => {
        this.clearMasterRoute()
      }
    });
  }

  clearEndRouteLeg() {
    this.endMasterRouteLeg.Id = null;
    this.endMasterRouteLeg.Location = null
  }

  async createMasterRoute() {
    this.masterRoute = {
      LoadLocationName: this.startMasterRouteLocation.Location,
      UnloadLocationName: this.endMasterRouteLocation.Location,
      LoadLocationId: this.startMasterRouteLocation.Id,
      UnloadLocationId: this.endMasterRouteLocation.Id,
      RouteLegs: this.routeLegs
    }

    this.spinnerIcon = 'pi pi-spin pi-spinner'
    this.waitingResponse = true;
    //check if route is valid
    if (!this.validateMasterRoute()) {
      this.waitingResponse = false;
      this.spinnerIcon = ''
      return;
    }
    //check if master route already exists
    else if (await this.checkIfRouteAlreadyExists()) {
      this.waitingResponse = false;
      this.spinnerIcon = ''
      return;
    }

    try {
      let resp = await this.api2.createMasterRoute(this.masterRoute);
      if (!resp) {
        this.toastMessage('error', 'Failed to add master route');
      }
      else {
        this.toastMessage('success', 'Master Route has been created');
        this.clearMasterRoute();
        this.refresh();
        this.getAllRoutes();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to create master route. Please contact support.')
      this.log.error(error);
    }
    this.waitingResponse = false;
    this.spinnerIcon = ''
  }

  async checkIfRouteAlreadyExists() {
    try {
      let resp = await this.api2.checkIfRouteAlreadyExists(this.displayRouteLegs(this.routeLegs));
      if (resp) {
        if (resp.errorCode == 0) {
          return false;
        }
        else if (resp.errorCode == 1) {
          this.toastMessage('error', resp.errorMessage);
          return true
        }
      }
      else {
        this.toastMessage('error', 'Failed to check if master route exists. Please contact support.');
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to check if master route exists. Please contact support.');
      this.log.error(error);
    }
    return true;
  }

  validateMasterRoute() {
    let lastLeg = this.routeLegs[this.routeLegs.length - 1]
    if (lastLeg.unloadLocationId != this.endMasterRouteLocation.Id) {
      this.toastMessage('error', 'The last leg does not match the last master route leg.');
      return false
    }
    else if (this.routeLegs.length >= 2) {
      let lastLeg = this.routeLegs[this.routeLegs.length - 1]
      let secondToLastLeg = this.routeLegs[this.routeLegs.length - 2];

      //if the second to last leg unload does not match the last leg load location then invalid
      if (secondToLastLeg.unloadLocationId != lastLeg.loadLocationId) {
        this.toastMessage('error', 'The second to last leg unload does not match the last leg load location.');
        return false;
      }
    }
    return true;
  }

  displayRouteLegs(routeLegs: any[]) {
    let description = "";

    let legs = routeLegs;
    if (routeLegs.find(x => x.actionType)) {
      legs = routeLegs.filter(x => x.actionType != 3);
    }

    for (let index = 0; index < legs.length; index++) {
      var element = legs[index].loadLocationName + ' --> ' 
      if (index == legs.length - 1)
        element += legs[index].unLoadLocationName
      description += element
    }
    return description
  }

  disableAddRouteLegForMasterRoute() {
    if ((!this.startMasterRouteLocation || !this.endMasterRouteLocation) || (!this.startMasterRouteLocation.Id || !this.endMasterRouteLocation.Id)) {
      return true
    }
    else if ((!this.startMasterRouteLeg || !this.endMasterRouteLeg) || (!this.startMasterRouteLeg.Id || !this.endMasterRouteLeg.Id)) {
      return true
    }
    else if (!this.routeLegType) {
      return true;
    }
    return false;
  }

  disableCreateRoute() {
    if (this.routeLegs.length >= 2) {
      let lastLeg = this.routeLegs[this.routeLegs.length - 1]
      //check if the first leg load location id matches the start master route load location id 
      if (this.routeLegs[0].loadLocationId != this.startMasterRouteLocation.Id) {
        return true;
      }
      //check if the last leg unload location id matches the end master route unload location id 
      else if (lastLeg.unloadLocationId != this.endMasterRouteLocation.Id) {
        return true
      }
    }
    //if there is only one leg in the route
    //check if the master route start and end match that one route leg
    //if it doesn't then disable the create master route button
    else if (this.routeLegs.length == 1) {
      let firstLeg = this.routeLegs[0]
      if (this.startMasterRouteLocation.Id != firstLeg.loadLocationId || this.endMasterRouteLocation.Id != firstLeg.unloadLocationId) {
        return true
      }
    }
    return false;
  }

  //add route leg
  async searchMasterRoutes(value) {
    try {
      let retval = await this.routesApiService.searchMasterRoutes(value.query);
      if (retval && retval.errorCode == 0) {
        if (retval.result.length > 0) {
          this.masterRoutes = retval.result;
        }
        else {
          this.masterRoutes = null;
          this.toastMessage('info', 'No master routes found.')
        }
      }
      else {
        this.toastMessage('error', 'Failed to get list of master routes. Please contact support.')
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of master routes. Please contact support.')
      this.log.error(error);
    }
  }

  async SelectMasterRoute(event) {
    this.selectedMaster = event;
    await this.getMasterRouteAndRouteLegs(this.selectedMaster.value);
  }

  onSelectStartRouteLeg(event: any) {
    this.startRouteLeg = event
    this.refresh();
  }

  onSelectEndRouteLeg(event: any) {
    this.endRouteLeg = event
    this.refresh();
  }

  async getMasterRouteAndRouteLegs(routeId: number) {
    try {
      let resp = await this.api2.getMasterRouteAndRouteLegs(routeId);
      if (!resp) {
        this.toastMessage('error', 'Failed to get master route legs')
      } else {
        this.selectedMasterRoute = resp;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get master route legs. Please contact support.')
      this.log.error(error);
    }
  }

  // addRouteLegToExistingMasterRoute
  //type is either update or add 
  async upSertRouteLegToExistingMasterRoute(type: number) {
    let payload: RouteLegsList = {
      id: 0,
      parentRouteId: this.selectedMaster.value,
      routeOrder: this.selectedMasterRoute.routeLegs.length + 1,
      routeTypeId: this.routeLegType.id,
      loadLocationTypeId: this.startRouteLeg.LocationTypeId,
      loadLocationId: this.startRouteLeg.Id,
      unloadLocationTypeId: this.endRouteLeg.LocationTypeId,
      unloadLocationId: this.endRouteLeg.Id,
      loadLocationName: this.startRouteLeg.Location,
      loadLocationType: this.startRouteLeg.LocationType,
      unLoadLocationName: this.endRouteLeg.Location,
      unloadLocationType: this.endRouteLeg.LocationType,
      routeTypeDescription: this.routeLegType.description,
      actionType: type
    }

    //validate route before adding or updating it to the existing route legs
    if(this.validateAddRouteLegToExistingMasterRoute(payload)) return

    //add 
    if (type == 1) {
      this.selectedRouteLegIndex = null;
      this.selectedMasterRoute.routeLegs.splice(this.selectedMasterRoute.routeLegs.length - 1, 0, payload)
      this.updateRouteLegToExistingMasterRoute();
      this.toastMessage('success', 'Route leg added');
      this.clearRouteLeg();
      }
    //update
    else if (type == 2) {
      this.updateRouteLegToExistingMasterRoute();
    }
  }

  updateRouteLegToExistingMasterRoute() {
    let idx = 0;
    if (!this.selectedRouteLegIndex) {
      idx = this.selectedMasterRoute.routeLegs.length - 2;
    }
    else {
      idx = this.selectedRouteLegIndex
    }

    //if they were editing the second to last leg
    if (this.selectedMasterRoute.routeLegs.length != 2 && (this.startRouteLeg && this.endRouteLeg)) {
      //update the route legs start location
      this.selectedMasterRoute.routeLegs[idx].loadLocationId = this.startRouteLeg.Id
      this.selectedMasterRoute.routeLegs[idx].loadLocationName = this.startRouteLeg.Location
      this.selectedMasterRoute.routeLegs[idx].loadLocationTypeId = this.startRouteLeg.LocationTypeId
      //update the route legs end location
      this.selectedMasterRoute.routeLegs[idx].unloadLocationId = this.endRouteLeg.Id
      this.selectedMasterRoute.routeLegs[idx].unLoadLocationName = this.endRouteLeg.Location
      this.selectedMasterRoute.routeLegs[idx].unloadLocationTypeId = this.endRouteLeg.LocationTypeId
      //we need to edit the last leg load location to be the second to last route leg unload
      this.selectedMasterRoute.routeLegs[idx + 1].loadLocationId = this.endRouteLeg.Id
      this.selectedMasterRoute.routeLegs[idx + 1].loadLocationName = this.endRouteLeg.Location
      this.selectedMasterRoute.routeLegs[idx + 1].loadLocationTypeId = this.endRouteLeg.LocationTypeId
      //we need to edit the last leg load location to be the second to last route leg unload
      this.selectedMasterRoute.routeLegs[idx - 1].unloadLocationId = this.startRouteLeg.Id
      this.selectedMasterRoute.routeLegs[idx - 1].unLoadLocationName = this.startRouteLeg.Location
      this.selectedMasterRoute.routeLegs[idx - 1].unloadLocationTypeId = this.startRouteLeg.LocationTypeId
    }
    else if (!this.startRouteLeg || !this.endRouteLeg) {
      let legs = this.selectedMasterRoute.routeLegs.filter(x => x.actionType != 3);
      for (let index = 1; index < legs.length; index++) {
        const element = legs[index];
        const prevLeg = legs[index - 1];
        element.loadLocationId = prevLeg.unloadLocationId;
        element.loadLocationName = prevLeg.unLoadLocationName;
        element.loadLocationTypeId = prevLeg.unloadLocationTypeId;
      }
    }
    else {
      //update the load location of the last leg to be the unload location of the first leg
      this.selectedMasterRoute[1].loadLocationId = this.selectedMasterRoute[0].unloadLocationId;
      this.selectedMasterRoute[1].loadLocationName = this.selectedMasterRoute[0].unLoadLocationName;
      this.selectedMasterRoute[1].loadLocationTypeId = this.selectedMasterRoute[0].unloadLocationTypeId;
    }

    if(this.selectedRouteLegIndex) this.toastMessage('success', 'Route Leg Updated')
    this.displayDialog = false;
    this.selectedRouteLegIndex = null;
    this.updateRouteOrder();
  }

  updateRouteOrder() {
    let legs = this.selectedMasterRoute.routeLegs.filter(x => x.actionType != 3);
    for (let index = 0; index < legs.length; index++) {
      legs[index].routeOrder = index + 1
      if(legs[index].actionType != 1) legs[index].actionType = 2
    }
  }

  async confirmMasterRouteOrder() {
    this.errorMessage = 'Since these file(s) use the master route that you are editing, you cannot change the route. Please create a new route instead.'
    //first check if there are any files that use this route before change the underlying route order and route legs
    if (!await this.checkIfFilesAreUsingMasterRoute(this.selectedMaster.value)) return;
    this.updateRouteOrder();
    await this.ModifyRouteLegs();
  }

  editRouteLeg(routeLeg: RouteLegsList) {
    this.selectedRouteLegIndex = this.selectedMasterRoute.routeLegs.indexOf(routeLeg);
    this.displayDialog = true;
    this.startRouteLeg = {
      Id: routeLeg.loadLocationId,
      LocationTypeId: routeLeg.loadLocationTypeId,
      LocationType: routeLeg.loadLocationType,
      Location: routeLeg.loadLocationName
    }
    this.endRouteLeg = {
      Id: routeLeg.unloadLocationId,
      LocationTypeId: routeLeg.unloadLocationTypeId,
      LocationType: routeLeg.unloadLocationType,
      Location: routeLeg.unLoadLocationName
    }
    this.routeLegType = {
      id: routeLeg.routeTypeId,
      description: routeLeg.routeTypeDescription
    }
  }

  async ModifyRouteLegs() {
    this.waitingResults = true;
     //update the master route - doing this because they can reorder the route legs so this will update the route order
     try {
      let resp = await this.api2.ModifyRouteLegs(this.selectedMasterRoute.routeLegs);
      if (!resp) {
        this.toastMessage('error', 'Failed to update master route order')
      } else {
        this.toastMessage('success', 'Master route order has been updated');
        this.clearAddRouteLeg();
        await this.getAllRoutes();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to confirm master route order. Please contact support.')
      this.log.error(error);
    }
    this.waitingResults = false;
  }

  async checkIfFilesAreUsingMasterRoute(routeId: number) {
    let retval = await this.api2.checkIfFilesAreUsingMasterRoute(routeId);
    if (retval) {
      if (retval.errorCode == 0 && retval.result && retval.result == 0) {
        this.displayErrorDialog = false;
        return true;
      }
      else if(retval.errorCode == 0 && retval.result && retval.result.length >= 0) {
        //there are files that use the route
        this.filesThatUserMasterRoute = retval.result;
        this.displayErrorDialog = true;
        return false;
      }
      else {
        this.toastMessage('error', 'Failed to check if there are files that use this master route. Please contact support.')
        return false;
      }
    }
    else {
      this.toastMessage('error', 'Failed to check if there are any files that use this master route. Please contact support.')
      return false;
    }
  }

  disableAddRouteLeg() {
    if ((!this.startRouteLeg || !this.endRouteLeg) || (!this.startRouteLeg.Id || !this.endRouteLeg.Id)) {
      return true
    }
    else if (!this.routeLegType) {
      return true;
    }
    return false;
  }

  removeLegFromMasterRoute(routeId: number) {
    if (this.selectedMasterRoute.routeLegs.length == 1) {
      this.toastMessage('warn', 'Cannot remove all legs to master route.')      
    }
    else {
      let idx = this.selectedMasterRoute.routeLegs.findIndex(x => x.id === routeId)
      if (idx != -1) {
        this.selectedMasterRoute.routeLegs[idx].actionType = 3
        // this.selectedMasterRoute.routeLegs.splice(idx, 1);
      }
      this.updateRouteLegToExistingMasterRoute();
    }
    this.updateRouteOrder();
  }

  validateAddRouteLegToExistingMasterRoute(routeLeg: RouteList) {
    for (var item of this.selectedMasterRoute.routeLegs) {
      if (routeLeg.loadLocationId == item.loadLocationId && routeLeg.unloadLocationId == item.unloadLocationId) {
        this.toastMessage('error', 'Cannot add route leg with the same start and end route.');
        return true;
      }
      // else if (routeLeg.loadLocationId == item.loadLocationId) {
      //   this.toastMessage('error', 'Start leg already exists.');
      //   return true;
      // }
      else if (routeLeg.unloadLocationId == item.unloadLocationId) {
        this.toastMessage('error', 'End leg already exists.');
        return true;
      }
    }

    if (_.isEqual(this.startRouteLeg, this.endRouteLeg)) {
      this.toastMessage('error', 'Master route start and end cannot be the same.');
      return true;
    }
    //if the route type is sea and the start and end leg are not port, then route type is invalid
    else if (this.routeLegType.id == 1 && (this.startRouteLeg.LocationTypeId != 1 || this.endRouteLeg.LocationTypeId != 1)) {
      this.toastMessage('error', 'Invalid route type selected.')
      return true;
    }
    return false;
  }

  //add location
  async loadAddLocationForm(locationType) {
    await this.clearAddLocation();
    switch (locationType) {
      case 1:
        this.locationForm = new FormGroup({
          portName: new FormControl('', [Validators.required, Validators.minLength(3)]),
          portCountry: new FormControl('', Validators.required)
        });
        this.selectedOption = 'Port';
        this.locationType = 1;
        break;

      case 2:
        this.locationForm = new FormGroup({
          borderPost: new FormControl('', [Validators.required, Validators.minLength(2)]),
          exitBorderPost: new FormControl('', [Validators.required, Validators.minLength(3)]),
          defaultCIFDestination: new FormControl('', [Validators.required, Validators.minLength(2)])
        });
        this.selectedOption = 'Border Post';
        this.locationType = 2;
        break;

      case 3:
        this.locationForm = new FormGroup({
          placeCountry: new FormControl('', Validators.required),
          placeRegion: new FormControl('', Validators.required),
          placeCity: new FormControl('', [Validators.required, Validators.minLength(2),])
        });
        this.locationForm.get('placeCity').disable()
        this.selectedOption = 'Place';
        this.locationType = 3;
        break;

      case 4:
        this.locationForm = new FormGroup({
          warehouse: new FormControl('', [Validators.required, Validators.minLength(2)])
        });
        this.selectedOption = 'Warehouse';
        this.locationType = 4;
        break;
    }
    await this.refresh();
  }

  async getCountries() {
    try {
      let retval = await this.api.getCountries();
      if (retval) {
        this.countries = retval;
      }
      else {
        this.toastMessage('error', 'Failed to get countries')
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get countries. Please contact support.')
      this.log.error(error);
    }
  }

  async getRegions() {
    try {
      this.selectedCountry = this.locationForm.value.placeCountry.label
      this.countryId = this.locationForm.value.placeCountry.value
      let res = await this.api.getRegions(this.countryId);
      if (!res) {
        this.toastMessage('error', 'Failed to get regions')
      } else {
        this.regions = res;
        this.refresh();
      }

    } catch (error) {
      this.toastMessage('error', 'Failed to get regions. Please contact support.')
      this.log.error(error);
    }
  }

  async regionSelect() {
    this.selectedRegion = this.locationForm.value.placeRegion.label
    this.regionId = this.locationForm.value.placeRegion.value
    this.locationForm.get('placeCity').enable()
    this.refresh();
  }

  async onLocationSubmit(location) {
    let payload = this.setPayload(location)
    try {
      let res = await this.api.addLocation(payload);
      if (!res) {
        this.toastMessage('error', 'Failed to add location')
      } else {
        if (res.ErrorCode == 0) {
          this.toastMessage('success', this.selectedOption + ' created');
          await this.loadAddLocationForm(this.locationType)
          this.clearAddLocation();
        } else if (res.ErrorCode == 1) {
          this.toastMessage('error', res.ErrorMessage)
        }
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to add location. Please contact support.')
      this.log.error(error);
    }
  }

  setPayload(location) {
    let payload

    switch (this.locationType) {
      case 1:
        payload = {
          PortName: location.portName,
          Country: location.portCountry.label,
          CountryId: location.portCountry.value,
          BorderPost: '',
          ExitBorderPost: '',
          DefaultCIFDestination: '',
          RegionId: 0,
          City: '',
          Warehouse: '',
          LocationTypeId: this.locationType
        }
        break;

      case 2:
        payload = {
          PortName: '',
          Country: '',
          CountryId: 0,
          BorderPost: location.borderPost,
          ExitBorderPost: location.exitBorderPost,
          DefaultCIFDestination: location.defaultCIFDestination,
          RegionId: 0,
          City: '',
          Warehouse: '',
          LocationTypeId: this.locationType
        }
        break;

      case 3:
        payload = {
          PortName: '',
          Country: location.placeCountry.label,
          CountryId: location.placeCountry.value,
          BorderPost: '',
          ExitBorderPost: '',
          DefaultCIFDestination: '',
          RegionId: this.regionId,
          City: location.placeCity,
          Warehouse: '',
          LocationTypeId: this.locationType
        }
        break;

      case 4:
        payload = {
          PortName: '',
          Country: '',
          CountryId: 0,
          BorderPost: '',
          ExitBorderPost: '',
          DefaultCIFDestination: '',
          RegionId: 0,
          City: '',
          Warehouse: location.warehouse,
          LocationTypeId: this.locationType
        }
        break
    }
    return payload
  }

  //check if duplicate route leg
  checkDuplicate(value, array) {
    var found = false;
    for (let index = 0; index < array.length; index++) {
      //there is already a route leg with those details
      if ((value.LoadLocationName == array[index].LoadLocationName) && (value.UnLoadLocationName == array[index].UnLoadLocationName)) {
        found = true;
      }
    }
    return found;
  }

  //detecting changes
  refresh() {
    this.cd.detectChanges();
  }

  //clearing 
  clearMasterRoute() {
    this.startMasterRouteLocation = null;
    this.endMasterRouteLocation = null;
    this.startMasterRouteLeg = null;
    this.endMasterRouteLeg = null;
    this.masterRoute = null;
    this.routeLegs = [];
    this.routeLegType = null;
    this.disableMasterRoutes = false;
    this.startRouteLegLocationType = 0;
  }

  clearRouteLeg() {
    this.startRouteLeg = null;
    this.endRouteLeg = null;
    this.routeLegType = null;
    this.refresh();
  }

  clearAddRouteLeg() {
    this.startRouteLeg = null;
    this.endRouteLeg = null;
    this.selectedMasterRoute = null;
    this.displayErrorDialog = false;
    this.filesThatUserMasterRoute = [];
    this.selectedMaster = null;
    this.routeLegType = null;
    this.searchedMasterRoute = null;
    this.refresh();
  }

  async clearUpdatedRoute() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to clear your updated route? This is permanent and will require you to make changes to your route again.',
      accept: async() => {
        this.startRouteLeg = null;
        this.endRouteLeg = null;
        this.routeLegType = null;
        await this.getMasterRouteAndRouteLegs(this.selectedMaster.value);
      }
    });
  }
  
  clearMasterStartRoute() {
    if (this.startMasterRouteLocation) {
      this.startMasterRouteLocation.Id = null
      this.startMasterRouteLocation.Location = null  
    }
    this.startMasterRouteLeg.Id = null
    this.startMasterRouteLeg.Location = null
    this.refresh();
  }

  clearMasterEndRoute() {
    if (this.endMasterRouteLocation) {
      this.endMasterRouteLocation.Id = null;
      this.endMasterRouteLocation.Location = null
    }
    this.refresh();
  }

  clearStartRouteLeg() {
    this.startMasterRouteLeg = null;
    this.refresh();
  }

  clearAddLocation() {
    this.locationForm = null;
    this.selectedCityId = null
    this.selectedCountry = null;
    this.selectedRegion = null;
    this.selectedCity = null;
    this.selectedOption = null;
  }

  toastMessage(severity, summary, detail?) {
    if (this.lastToastMessage != summary || this.lastToastMessageDate+2500 < Date.now()) {
      this.messageService.add({ severity: severity, summary: summary, detail: detail });
      this.lastToastMessage = summary;
      this.lastToastMessageDate = Date.now();
    }
  }

  goBack() {
    this._location.back();
  }
}
