<p-table #dt [value]="data" [columns]="fields" [autoLayout]="true" [scrollable]="true" dataKey="id"
    styleClass="ui-g-12 p-datatable-gridlines" [rowHover]="true" [rows]="50" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
    [globalFilterFields]="globalFilters" [reorderableColumns]="true" [resizableColumns]="true">
    <ng-template pTemplate="caption">
        <div class="ui-table-globalfilter-container">
            <h2>{{title}}</h2>
            <div class="table-bar-actions">
                <p-selectButton [options]="[{name: 'Row Color', code: ''},{name: 'No Color', code: '-'}]"
                    [(ngModel)]="rowColor" optionLabel="name" optionValue="code"></p-selectButton>
                <p-button label="Export Excel" (onClick)="generateExcel()"></p-button>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Global Search" />
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <ng-container *ngFor="let field of fields" [ngSwitch]="field.type">
                <col *ngSwitchDefault [ngStyle]="{'width': field.width}">
                <col *ngSwitchCase="type.DATE" [ngStyle]="{'width': field.width, 'min-width': '180px'}">
            </ng-container>
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <ng-container *ngFor="let field of fields">
                <th [pSortableColumn]="field.key" style="font-weight: bold;" *ngIf="field.type != type.BUTTON"
                    pReorderableColumn>
                    {{field.header}}<p-sortIcon [field]="field.key">
                    </p-sortIcon>
                </th>
                <th style="font-weight: bold;" *ngIf="field.type == type.BUTTON">
                    {{field.header}}
                </th>
            </ng-container>
        </tr>
        <tr>
            <th *ngFor="let field of fields">
                <ng-container>
                    <input *ngIf="!field.fewResults?.length && field.type != type.BUTTON" pInputText type="text"
                        (input)="dt.filter($event.target.value, field.key, 'contains')"
                        placeholder="{{field.type == type.DATE? 'Search: eg 2023/08/01': 'Search'}}"
                        class="ui-column-filter">
                    <p-multiSelect *ngIf="field.fewResults?.length && field.type != type.BUTTON"
                        [options]="field.fewResults" placeholder="All" appendTo="body"
                        (onChange)="dt.filter($event.value, field.key, 'in')">
                        <ng-template let-option pTemplate=" item">
                            <span *ngIf="isEmpty(option.value)" class="badge status-null">NULL</span>
                            <bracket-text *ngIf="!isEmpty(option.value)" [text]="option.label"></bracket-text>
                        </ng-template>
                    </p-multiSelect>
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-columns="fields">
        <tr class="{{record.color}}{{rowColor.code}}">
            <td *ngFor="let field of fields">
                <span *ngIf="isEmpty(record[field.key]) && field.type != type.BUTTON"
                    class="badge status-null">NULL</span>
                <ng-container *ngIf="!isEmpty(record[field.key])" [ngSwitch]="field.type">
                    <!-- TEXT -->
                    <bracket-text *ngSwitchCase="type.TEXT" [text]="record[field.key]"></bracket-text>
                    <!-- BOOLEAN -->
                    <span *ngSwitchCase="type.BOOL"
                        class="badge status-{{record[field.key].toString() | lowercase}}">{{record[field.key]}}</span>
                    <!-- DATETIME -->
                    <div *ngSwitchCase="type.DATE">
                        <span *ngIf="record[field.key]" class="date">{{record[field.key] | date:'mediumDate'}}</span>
                        <span *ngIf="!isDateOnly(record[field.key])">{{record[field.key] | date:'shortTime'}}</span>
                    </div>
                    <!-- NUMBER -->
                    <span *ngSwitchCase="type.NUMBER">{{record[field.key]}}</span>
                    <!-- DBN -->
                    <span *ngSwitchCase="type.DBN"
                        class="badge status-{{typeStyle(record[field.key])}}">{{record[field.key]}}</span>
                </ng-container>
                <!-- ACTIONS -->
                <div class="actions">
                    <button *ngIf="field.type == type.BUTTON" class="" pTooltip="View {{getDBNNumber(record)}}"
                        tooltipPosition="left" pButton type="button" icon="far fa-folder"
                        (click)="viewFile(record)"></button>
                    <button *ngIf="field.type == type.BUTTON && record?.consigneeLegalEntityId" class=""
                        pTooltip="View Consignee" tooltipPosition="left" pButton type="button" icon="far fa-folder"
                        (click)="viewFile(record)"></button>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align:left">No results found.</td>
        </tr>
    </ng-template>
</p-table>