import { Injectable } from '@angular/core';
import { FilterApiService } from '../../services/api/filter-api.service';
import { ClearingStatusTypes, OpenTypes, FilterDropdown, FailedFiles, Files, MBL, Vessel, Voyage, LocationType, Location } from '../../interfaces/filter-search-interfaces';
import { Dropdown } from '../../interfaces/global.interfaces';
import { MessageService } from 'primeng';
import { LogBase } from '../../services/logger.service';
import { ApiService } from '../../services/api.service';
import { Api2Service } from '../../services/api2.service';
import * as _ from 'lodash';
import { LocationTypes } from '../../enums';

@Injectable()
export class FilterDataService {

  /* #Service - Start*/
  filteredFilesBy: string = '';
  uniqueCurrentlyAt: FilterDropdown[];
  uniqueMasterRoutes: FilterDropdown[];
  selectedTabIndex: number = 0;
  filteredFiles: Files[] = [];
  selectedFiles: Files[] = null;
  selectedClearingStatusId: ClearingStatusTypes[] = [];
  SelectedUniqueCurrentlyAt: FilterDropdown;
  errorDialogTitle: string = '';
  failedFiles: FailedFiles[] = null;
  clearingTypes: ClearingStatusTypes[] = null;
  openTypes: OpenTypes[] = [
    {
      value: 0,
      text: 'Open'
    },
    {
      value: 1,
      text: 'Closed'
    }
  ];
  selectedFileStatus: OpenTypes[] = [{
    value: 0,
    text: 'Open'
  }];
  waitForResults: boolean = false;
  showErrorDialog: boolean = false;
  /* #Service - End*/


  /* #File Ids - Start */
  fileIds: any;
  /* #File Ids - End */


  /* #Shipping - Start */
  vessels: any[];
  voyages: any[];
  selectedVoyage: Voyage = null;
  selectedVessel: Vessel = null;
  selectedMBLNumbers: MBL[] = null;
  assignedMBLNumbers: MBL[] = null;
  /* #Shipping - End */


  /* #Routes - Start */
  masterRoutes: any[];
  masterRouteLegs: any[];
  selectedMasterRoute: any = null;
  selectedRouteLeg: any = null;
  /* #Routes - End */


  /* #Storage Point - Start */
  storagePoints: Dropdown[] = [];
  selectedStoragePoint: Dropdown = null;
  /* #Storage Point - End */


  /* #Current Location - Start */
  locationTypes: any[];
  locations: any[];
  selectedLocationType: LocationType = null;
  selectedLocation: Location = null;
  locationLabel: string = 'Select a Location type';
  /* #Current Location - End */


  constructor(
    private filterApiService: FilterApiService,
    private messageService: MessageService,
    private log: LogBase,
    private api: ApiService,
    private api2: Api2Service
  ) { }

  /* #File Ids - Start */
  async filterFilesByFileIds(ruleSetId: number): Promise<void> {
    this.waitForResults = true;
    try {
      const clearingStatusId = this.selectedClearingStatusId.map(e => e.id).toString();
      const fileStatus = this.selectedFileStatus.map(e => e.value).toString();
      const fileIdsArr = this.fileIds.join(",");
      const retval = await this.filterApiService.FilterFilesByFileIds(fileIdsArr, clearingStatusId, fileStatus, ruleSetId);
      if (!retval) this.toastMessage('error', 'Failed to filter files by file id. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.filteredFiles = retval.result;
        this.filteredFilesBy = 'File Ids';
        this.checkIfNoFilesFound();
        this.showUniqueFilter();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get files by file ids', '');
      this.log.error(err);
    }
    this.waitForResults = false;
  }
  /* #File Ids - End */


  /* #Shipping - Start */
  async getVessels(): Promise<void> {
    try {
      const retval = await this.api.getCommunicatorVessels();
      if (!retval) this.toastMessage('error', 'Failed to get vessels', '');
      else {
        this.vessels = retval;
        this.selectedVoyage = null;
        this.selectedMBLNumbers = null;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to list of vessels', '');
      this.log.error(error);
    }
  }

  async getVoyage(): Promise<void> {
    if (!this.selectedVessel) {
      this.selectedVoyage = null;
      this.selectedMBLNumbers = null;
      return;
    }
    try {
      const retval = await this.api.getCommunicatorVoyages(true, this.selectedVessel.id);
      if (retval) {
        this.voyages = retval;
        this.selectedVoyage = null;
        this.selectedMBLNumbers = null;
      } else {
        this.toastMessage('error', 'Failed to get voyages', '');
      }
      this.selectedMBLNumbers = [];
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of voyages', '');
      this.log.error(error);
    }
  }

  async getAssignedMBLNumbers(): Promise<void> {
    if (!this.selectedVoyage) {
      this.selectedMBLNumbers = null;
      return;
    }
    try {
      let retval = await this.api.getBulkUpdaterMBLNumbers(true, this.selectedVoyage.id);
      if (retval) {
        this.assignedMBLNumbers = retval;
        this.selectedMBLNumbers = null;
      }
      else {
        this.toastMessage('error', 'Failed to get MBL numbers', '');
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of MBL numbers', '');
      this.log.error(error);
    }
  }

  async filterFilesByShipping(ruleSetId: number): Promise<void> {
    this.waitForResults = true;
    try {
      var voyageId = this.selectedVoyage ? this.selectedVoyage.id : null;
      let mbl = null;

      if (this.selectedMBLNumbers && this.selectedMBLNumbers.length != 0) {
        mbl = [];
        for (var item of this.selectedMBLNumbers) {
          mbl.push(item.MBL);
        }
      }
      const clearingStatusId = this.selectedClearingStatusId.map(e => e.id).toString();
      const fileStatus = this.selectedFileStatus.map(e => e.value).toString();
      const retval = await this.filterApiService.FilterFilesByVesselIdAndOrVoyageIdAndOrMBL(this.selectedVessel.id, clearingStatusId, fileStatus, ruleSetId, voyageId, mbl);
      if (!retval) this.toastMessage('error', 'Failed to filter files by shipping. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.filteredFiles = retval.result;
        this.filteredFilesBy = 'Shipping';
        this.checkIfNoFilesFound();
        this.showUniqueFilter();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get files by vessel', '');
      this.log.error(err);
    }
    this.waitForResults = false;
  }
  /* #Shipping - End */


  /* #Route - Start */
  async getFileMasterRoutes(): Promise<void> {
    try {
      let retval = await this.api2.GetFileMasterRoutes();
      if (retval) {
        this.masterRoutes = retval;
      }
      else {
        this.toastMessage('error', 'Failed to get file master routes', '');
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of file master routes', '');
      this.log.error(error);
    }
  }

  async getMasterRouteLegs(): Promise<void> {
    if (!this.selectedMasterRoute) return;
    try {
      let retval = await this.api2.GetFileMasterRouteLegs(this.selectedMasterRoute.routeId);
      if (retval) {
        this.masterRouteLegs = retval;
        this.selectedRouteLeg = null;
      }
      else {
        this.toastMessage('error', 'Failed to get file master route legs', '');
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of file master route legs', '');
      this.log.error(error);
    }
  }

  async filterFilesByRoute(ruleSetId: number): Promise<void> {
    this.waitForResults = true;
    try {
      const routeLegId = this.selectedRouteLeg ? this.selectedRouteLeg.routeId : null;
      const typeId = this.selectedRouteLeg ? this.selectedRouteLeg.typeId : null;
      const clearingStatusId = this.selectedClearingStatusId.map(e => e.id).toString();
      const fileStatus = this.selectedFileStatus.map(e => e.value).toString();
      const retval = await this.filterApiService.FilterFilesByMasterRouteIdAndOrRouteId(this.selectedMasterRoute.routeId, clearingStatusId, fileStatus, ruleSetId, routeLegId, typeId);
      if (!retval) this.toastMessage('error', 'Failed to filter files by route. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.filteredFiles = retval.result;
        this.filteredFilesBy = 'Routes';
        this.checkIfNoFilesFound();
        this.showUniqueFilter();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get files by route', '');
      this.log.error(err);
    }
    this.waitForResults = false;
  }
  /* #Route - End */


  /* #Storage Point - Start */
  async getStoragePoints(): Promise<void> {
    try {
      let retval = await this.api2.getWarehouses();
      if (retval) {
        this.storagePoints = retval;
      }
      else {
        this.toastMessage('error', 'Failed to get storage points', '');
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get storage points', '');
      this.log.error(err);
    }
  }

  async filterFilesByStoragePoint(ruleSetId: number): Promise<void> {
    this.waitForResults = true;
    try {
      const clearingStatusId = this.selectedClearingStatusId.map(e => e.id).toString();
      const fileStatus = this.selectedFileStatus.map(e => e.value).toString();
      const retval = await this.filterApiService.FilterFilesByStoragePoint(+this.selectedStoragePoint.value, clearingStatusId, fileStatus, ruleSetId);
      if (!retval) this.toastMessage('error', 'Failed to filter files by storage point. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.filteredFiles = retval.result;
        this.filteredFilesBy = 'Storage Point';
        this.checkIfNoFilesFound();
        this.showUniqueFilter();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get files by storage points', '');
      this.log.error(err);
    }
    this.waitForResults = false;
  }
  /* #Storage Point - End */


  /* #Current Location - Start */
  async filterFilesByCurrentLocation(ruleSetId: number): Promise<void> {
    this.waitForResults = true;
    try {
      const clearingStatusId = this.selectedClearingStatusId.map(e => e.id).toString();
      const fileStatus = this.selectedFileStatus.map(e => e.value).toString();
      const retval = await this.filterApiService.FilterFilesByCurrentLocation(this.selectedLocation.Id, clearingStatusId, fileStatus, ruleSetId);
      if (!retval) this.toastMessage('error', 'Failed to filter files by current location. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.filteredFiles = retval.result;
        this.filteredFilesBy = 'Current Location';
        this.checkIfNoFilesFound();
        this.showUniqueFilter();
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get files by current location', '');
      this.log.error(err);
    }
    this.waitForResults = false;
  }

  async getLocationTypes(): Promise<void> {
    try {
      let retval = await this.api2.getLocationTypes();
      if (retval) {
        this.locationTypes = retval;
        this.selectedLocation = null;
      } else {
        this.toastMessage('error', 'Failed to get location types', '');
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of location types', '');
      this.log.error(error);
    }
  }

  async searchLocations(event: any) {
    try {
      switch (this.selectedLocationType.id) {
        case LocationTypes.PORT:
          this.locations = await this.api.locationssearchPort(event.query);
          break;

        case LocationTypes.BORDER:
          this.locations = await this.api.searchBorder(event.query);
          break;

        case LocationTypes.PLACE:
          this.locations = await this.api.searchPlace(event.query);
          break;

        case LocationTypes.STORAGE_POINT:
          this.locations = await this.api.searchWarehouse(event.query);
          break;

        case LocationTypes.ADDRESS:
          this.locations = await this.api.searchAddress(event.query);
          break;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get list of locations', '');
      this.log.error(error);
    }
  }

  async setLocationType() {
    try {
      this.locations = null;
      this.selectedLocation = null;
      switch (this.selectedLocationType.id) {
        case 0:
          this.locationLabel = 'Search for Locations';
          break;
        case 1:
          this.locationLabel = 'Search for Ports';
          break;
        case 2:
          this.locationLabel = 'Search for Borders';
          break;
        case 3:
          this.locationLabel = 'Search for Places';
          break;
        case 4:
          this.locationLabel = 'Search for Storage Points';
          break;
        case 5:
          this.locationLabel = 'Search for Addresses';
          break;
      }
    } catch (error) {
      this.toastMessage('error', 'Error Setting Selected Location', '');
      this.log.error(error);
    }
  }
  /* #Current Location - End */


  /* #Service - Start*/
  async filterGetClearingStatusTypes(): Promise<void> {
    this.clearingTypes = [];
    try {
      const retval = await this.filterApiService.FilterGetClearingStatusTypes();
      if (!retval) this.toastMessage('error', 'Failed to get clearing status types. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.clearingTypes = retval.result;
        this.clearingTypes = _.orderBy(this.clearingTypes, ['id'], ['asc']);
        //Default all clearing statuses as selected
        this.selectedClearingStatusId = retval.result;
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get clearing status types', '');
      this.log.error(err);
    }
  }

  public async refreshData(ruleSetId: number): Promise<void> {
    // this.clearSelectedFiles = false;
    switch (this.selectedTabIndex) {
      //File Ids
      case 0:
        await this.filterFilesByFileIds(ruleSetId);
        break;

      //Shipping
      case 1:
        await this.filterFilesByShipping(ruleSetId);
        break;

      //Routes
      case 2:
        await this.filterFilesByRoute(ruleSetId);
        break;
    }
  }

  async showUniqueFilter() {
    this.uniqueCurrentlyAt = [];
    this.uniqueMasterRoutes = [];
    this.SelectedUniqueCurrentlyAt = null;
    var uniqueCurrentlyAt = _.uniqBy(this.filteredFiles, 'currentlyAt');
    var uniqueMasterRoutes = _.uniqBy(this.filteredFiles, 'route');

    for (var item of uniqueCurrentlyAt) {
      this.uniqueCurrentlyAt.push({ label: item.currentlyAt, value: item.currentlyAt });
    }

    for (var item of uniqueMasterRoutes) {
      this.uniqueMasterRoutes.push({ label: item.route, value: item.route });
    }
  }

  public updateSelectedFiles() {
    if (this.failedFiles == null || this.failedFiles.length == 0) {
      this.selectedFiles = [];
      return;
    }
    this.selectedFiles = [];
    const failedFiles = this.failedFiles.filter(x => x.isFailure == 1);
    for (let index = 0; index < failedFiles.length; index++) {
      const file = this.filteredFiles.find(x => x.dbnNumber == failedFiles[index].dbnNumber && failedFiles[index].isFailure == 1);
      if (file) this.selectedFiles.push(file);
    }
  }

  private checkIfNoFilesFound(): void {
    if (this.filteredFiles.length == 0) this.toastMessage('warn', 'No files found', '');
    else this.selectedFiles = null;
  }

  public clearAll(): void { this.filteredFiles = null; this.selectedFiles = null; }
  private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
  /* #Service - End*/
}
