import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { LogBase } from './../../../services/logger.service';
import { Title } from "@angular/platform-browser";
import { BorderPost, Dropdown, TableColumns } from '../../../interfaces/global.interfaces';
import { ReferenceApiService } from '../../../services/api/reference-api.service';

@Component({
  selector: 'app-border-post',
  templateUrl: './border-post.component.html',
  styleUrls: ['./border-post.component.css'],
  providers: [ConfirmationService]
})
export class ManageBorderPostComponent implements OnInit {

  public itemCols: TableColumns[] = [
    { field: 'borderPost', header: 'Border Post' },
    { field: 'exitBorderPost', header: 'Exit Border Post' },
    { field: 'defaultCIFDestination', header: 'Default CIF Destination' },
    { field: 'borderPostStatus', header: 'Border Post Status' }
  ];

  public showSaveSpinner: boolean = false;
  public displayDialog: boolean = false;
  public borderPosts: BorderPost[];
  public backupBorderPost: BorderPost[];
  public borderPostStatus: Dropdown[] = [];
  public selectedBorderPostStatus: Dropdown | null = null;
  public showLoadingSpinner: boolean = false;

  public newborderpost: string;
  public newexitborderpost: string;
  public newdefaultCIFdestination: string;

  constructor(
    public api: ApiService,
    private messageService: MessageService,
    private referenceApiService: ReferenceApiService,
    private log: LogBase,
    private titleService: Title
  ) {
    this.titleService.setTitle("AVECS Border-Post");
  }

  async ngOnInit() { await this.load(); }

  private async load() {
    try {
      this.showLoadingSpinner = true;
      await this.getBorderPosts();
      await this.getBorderPostStatus();
      this.showLoadingSpinner = false;
    } catch (error) {
      this.log.error(error);
    }
  }

  private async getBorderPosts() {
    try {
      const retval = await this.referenceApiService.getBorderPosts();
      if (!retval) this.toastMessage('error', 'Failed to get border posts. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.borderPosts = retval.result;
        this.backupBorderPost = retval.result;
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get border post status. Please contact support.', '');
      this.log.error(err);
    }
  }

  private async getBorderPostStatus() {
    try {
      const retval = await this.referenceApiService.getBorderPostStatus();
      if (!retval) this.toastMessage('error', 'Failed to get border post status. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.borderPostStatus = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get border post status. Please contact support.', '');
      this.log.error(err);
    }
  }

  public async updateBorderPost(borderPost: BorderPost) {
    try {
      const retval = await this.referenceApiService.updateBorderPost(borderPost.id, borderPost.name, borderPost.defaultCIFDestination, borderPost.exitBorderPost, borderPost.borderPostStatusId);
      if (!retval) this.toastMessage('error', 'Failed to update border post. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        const x = this.borderPostStatus.find(s => s.value === borderPost.borderPostStatusId);
        borderPost.borderPostStatus = x.label;
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to update border post. Please contact support', '');
      this.log.error(err);
    }
  }

  clearBorderPost() {
    this.newborderpost = '';
    this.newexitborderpost = '';
    this.newdefaultCIFdestination = '';
    this.displayDialog = true;
  }

  async saveBorderPost() {
    this.showSaveSpinner = true;
    try {
      const borderPost: string = this.newborderpost.trim();
      const exitBorderPost: string = this.newexitborderpost.trim();
      const defaultCIFDestination: string = this.newdefaultCIFdestination.trim();
      const retval = await this.referenceApiService.addBorderPost(borderPost, exitBorderPost, defaultCIFDestination, this.selectedBorderPostStatus.value);
      if (!retval) this.toastMessage('error', 'Error', 'Failed to create border post. Please contact support');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', 'Success', 'Border post saved successfully');
        this.displayDialog = false;
        await this.load();
      }
    } catch (error) {
      this.toastMessage('error', 'Error', 'Failed to create border post. Please contact support');
      this.log.error(error);
    }
    this.showSaveSpinner = false;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
