<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && !adhocUserTask" class="ui-g card card-w-title">
  <h3 style="color: red">Error: There was an error displaying the user task. Please contact support.</h3>
</div>

<div class="ui-g card card-w-title" *ngIf="!showSpinner && adhocUserTask">
  <ng-container *ngIf="adhocUserTask.userTaskTypeId === userTaskType.ADHOC_USER_TASK_FOR_A_FILE">
    <div class="ui-g-12 file-number-header">
      <h3>{{ adhocUserTask.userTaskHeading }} </h3>
      <file-number [fileNumber]="adhocUserTask.dbnNumber"></file-number>
    </div>
  </ng-container>

  <ng-container *ngIf="adhocUserTask.userTaskTypeId === userTaskType.ADHOC_USER_TASK_GENERAL">
    <div class="ui-g-12">
      <h3>{{ adhocUserTask.userTaskHeading }}</h3>
    </div>
  </ng-container>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'120px'}">
      <p-header>
        User Task Information
      </p-header>

      <div class="ui-g-4">
        <div class="information">
          <strong>Assigned To</strong>
          <label>
            {{ adhocUserTask.assignedToLegalEntity || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Assigned By</strong>
          <label>
            {{ adhocUserTask.assignedByLegalEntity || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Created</strong>
          <label>
            {{ adhocUserTask.createdDate || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="ui-g-12 user-task-text">
      <h4>User Task Description:</h4>
      <label class="user-task-description">{{ adhocUserTask.userTaskDescription }}</label>
    </div>
  </div>

  <div class="ui-g-12 actions">
    <button *ngIf="!markUserTaskAsComplete && adhocUserTask.userTaskTypeId === userTaskType.ADHOC_USER_TASK_FOR_A_FILE"
      pRipple pButton label="View File" (click)="viewFile(adhocUserTask.genericId)" class="width-100"></button>
    <button *ngIf="!markUserTaskAsComplete" pRipple pButton label="Complete UserTask" (click)="updateUserTask()"
      class="width-100"></button>
    <button *ngIf="markUserTaskAsComplete" [disabled]="true" pRipple class="p-button-info width-100" pButton
      type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
  </div>
</div>