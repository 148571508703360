import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigService implements OnInit {

  url: any;
  nodeUrl: any;
  public supportEmail: any;
  public timeZoneAdjustment: number;
  public SAEX: string;
  public WA: string;
  public AvecsFinance: string;
  public AvecsDeepLink: string;

  constructor() {
    this.url = environment.url;
    this.nodeUrl = environment.nodeUrl;
    this.supportEmail = environment.supportEmail;
    this.timeZoneAdjustment = environment.timeZoneAdjustment;
    this.SAEX = environment.SAEX;
    this.WA = environment.WA;
    this.AvecsDeepLink = environment.AvecsDeepLink;
    this.AvecsFinance = environment.AvecsFinance;
  }

  async ngOnInit() { }

  getServerUrl() { return this.url; }

  getNodeUrl() { return this.nodeUrl; }
}
