import { Component } from '@angular/core';
import { LogBase } from "../../../services/logger.service";
import { ApiService } from "../../../services/api.service";
import { MessageService } from 'primeng/api';
import { FilesWithIssues } from '../../../interfaces/global.interfaces';
import { CriteriaResponse } from '../../../interfaces/result-interfaces';
import { FileIssueType, OnDemandTemplate } from '../../../enums';

@Component({
	selector: 'app-any-job',
	templateUrl: './any-job.component.html',
	styleUrls: ['./any-job.component.css'],
	providers: []
})
export class AnyJobComponent {

	public dialogHeader: string = '';
	public filesWithIssues: FilesWithIssues[] | null = null;
	public showDialog: boolean = false;

	constructor(
		private messageService: MessageService,
		public api: ApiService,
		public log: LogBase) { }

	async onSubmit(response: CriteriaResponse) {
		if (!response || !response?.result) this.toastMessage('error', 'Failed to perform job. Please contact support', '');
		else if (response.result.errorCode !== 0) this.toastMessage('error', response.result.errorMessage, '');
		else if (response.success) {
			switch (response.template.id) {
				case OnDemandTemplate.SEND_DOCUMENTS_OF_CATEGORY_FOR_VOYAGE_TO_WAREHOUSE:
					this.handleSendDocumentCategoryForVoyageToWarehouse(response);
					return;

				case OnDemandTemplate.SEND_DOCUMENTS_OF_CATEGORY_FOR_VOYAGE_TO_CONSIGNEE:
					this.handleSendDocumentCategoryForVoyageToWarehouse(response);
					return;
			}

			this.toastMessage('success', 'Success', `${response.template.name} Complete`);
		}
	}

	private handleSendDocumentCategoryForVoyageToWarehouse(response: CriteriaResponse) {
		if (response.result?.result?.length !== 0 && Array.isArray(response.result?.result)) {
			this.filesWithIssues = response.result.result;
			this.dialogHeader = 'Reported files';
			this.showDialog = true;

			const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
			if (onlyErrors) {
				this.toastMessage('error', 'Error', `All files on voyage have errors`);
			} else {
				this.toastMessage('success', 'Success', `${response.template.name} Complete`);
			}
		} else {
			this.toastMessage('info', 'Info', `There are no files to email out.`);
		}
	}

	private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}