import { Injectable } from '@angular/core';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";
import { HttpResponse } from '../../interfaces/result-interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {

  private serverUrl = `${this.config.getServerUrl()}api/Documents/`;

  constructor(
    private config: ConfigService,
    private authenticationService: AuthenticationService,
    private log: LogBase,
    private webApi: WebApi2Service,
    private http: HttpClient
  ) { }

  public async generateTermsAndConditionsDocument(fileId: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GenerateTermsAndConditionsPDF?FileId=${fileId}`;
      return this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }


  // Endpoint: api/Documents/declineDocument
  // Controller Name: DocumentsController.cs
  // Proc Name: pr_DeclineDocument
  public async declineDocument(docId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}declineDocument?docId=${docId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  // Endpoint: api/Documents/approveDocument
  // Controller Name: DocumentsController.cs
  // Proc Name: pr_ApproveDocumentForFile
  public async approveDocument(documentId: any, fileId: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}approveDocument?documentId=${documentId}&fileId=${fileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/Documents/getDocumentCategory
  // Controller Name: DocumentsController.cs
  // Proc Name: pr_ApproveDocumentForFile
  public async getDocumentCategory(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getDocumentCategory`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  public async updateDocumentCategory(documentId: number, documentCategoryId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}updateDocumentCategory?documentId=${documentId}&documentCategoryId=${documentCategoryId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/Documents/DownloadDocument
  // Controller Name: DocumentsController.cs
  //here we will download a document
  //we make an api call to download the file
  //it will also open up in the users browser
  public async downloadDocument(documentId: number, documentName: string): Promise<void> {
    try {
      const url = `${this.serverUrl}DownloadDocument?documentId=${documentId}`;

      const headers = new HttpHeaders()
        .set("content-type", "application/json")
        .set("Token", this.authenticationService.getSessionToken ?? '')
        .set("Id", this.authenticationService.getLegalEntityIdForApiHeaders ?? '');

      const arrayBuffer = await this.http.get(url, { 'headers': headers, responseType: 'arraybuffer' }).toPromise();

      const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
      //create a url for the blob to be used in the download link
      const blobUrl = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      //set the link's href to the blob's url
      downloadLink.href = blobUrl;
      //set the download attribute using the document name
      downloadLink.download = documentName;

      //append the download link to the documents body
      document.body.appendChild(downloadLink);
      //automatically click on the download link so it opens up in a new tab for the user
      downloadLink.click();
      //remove the download link from the document's body to clean up
      document.body.removeChild(downloadLink);
    } catch (e) {
      this.log.error(e);
    }
  }
}
