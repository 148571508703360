
<div class="background">
    <p-dialog [(visible)]="display" width="300" [responsive]="true" [draggable]="false" [modal]="true"
        [closable]="false" [closeOnEscape]="false">
        <div class="card-reveal">
            <img src="../../../assets/images/NovaDTLogo.png" style="width: 260px">
            <form [formGroup]="loginForm" (keyup.enter)="login()">
                <div class="input-field">
                    <h3>Username</h3>
                    <input pInputText type="text" id="user_name" size="30" class="validate" formControlName="username">
                </div>
                <div class="input-field">
                    <h3>Password</h3>
                    <input pInputText type="password" id="password" size="30" class="validate" formControlName="password">
                </div>
                <br />
                <button pRipple pButton class="p-button-info" *ngIf="!loginSpinner" type="button"
                    [disabled]="loginForm.invalid" icon="fas fa-sign-in-alt" (click)="login()" label="Login" style="width: 100%"></button>
                <p-progressSpinner *ngIf="loginSpinner" [style]="{width: '30px', height: '30px'}" strokeWidth="4"
                    fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
            </form>
        </div>
    </p-dialog>
    <p-dialog header="NovaDT - Login" [(visible)]="update" width="300" [responsive]="true" [draggable]="false"
        [modal]="true" [closable]="true" [closeOnEscape]="false">
        <div class="card-reveal">
            <label>Your version of NovaDT is out of date. Please press CTRL+F5 to get the latest version</label>
        </div>
    </p-dialog>
</div>
