<div class="ui-g-12 actions" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && !userTask" class="ui-g card card-w-title">
  <h3 style="color: red">Error: There is no Approve / Reject Admin Transaction user task.</h3>
</div>

<div class="ui-g card card-w-title" *ngIf="!showSpinner && userTask">
  <div class="ui-g-12 header">
    <h3>Approve / Reject Admin Transaction For </h3>
    <file-number [fileNumber]="userTask.dbnNumber"></file-number>
  </div>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'200px'}">
      <p-header>
        User Task Information
      </p-header>

      <div class="ui-g-3">
        <div class="information">
          <strong>Created By</strong>
          <label>
            {{ userTask.createdBy || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-3">
        <div class="information">
          <strong>Created Date</strong>
          <label>
            {{ formatDate(userTask.createdDate) || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-3">
        <div class="information">
          <strong>Transaction Payment Date</strong>
          <label>
            {{ formatDate(userTask.paymentDate) || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-3">
        <div class="information">
          <strong>Transaction Amount</strong>
          <label>
            {{ 
              financeHelper.formatAmountWithCurrencySymbol(userTask.amount, userTask.currencyCode, userTask.currencyId) || 'N/A'
            }}
          </label>
        </div>
      </div>

      <div class="ui-g-12">
        <div class="information">
          <strong>Transaction Reason</strong>
          <label>
            {{ userTask.transactionReason || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="actions">
      <button pRipple pButton [disabled]="approveSpinner" label="Reject Transaction" (click)="showRejectDialog = true"
        *ngIf="!rejectSpinner" class="width-100"></button>

      <button *ngIf="rejectSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
        [disabled]="true">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>

      <button pRipple pButton [disabled]="rejectSpinner" label="Approve Transaction" (click)="approveTransaction()"
        *ngIf="!approveSpinner" class="width-100"></button>

      <button *ngIf="approveSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
        [disabled]="true">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </div>
  </div>
</div>

<p-dialog *ngIf="showRejectDialog" [(visible)]="showRejectDialog" (onHide)="showRejectDialog = false"
  header="Rejecting Admin Transaction" modal="true" [style]="{'width': '35%', 'height': 'auto'}">
  <div class="ui-g-12 dialog">
    <textarea [(ngModel)]="rejectReason" [rows]="8" [cols]="30" maxlength="280"
      placeholder="Please provide the reason for the rejection*" pInputTextarea></textarea>

    <button *ngIf="!rejectSpinner" pRipple pButton label="Reject Transaction" (click)="rejectTransaction()"
      [disabled]="!rejectReason"></button>

    <button *ngIf="rejectSpinner" [disabled]="true" pRipple class="p-button-info" pButton type="button">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>

    <button pRipple pButton label="Close" (click)="showRejectDialog = false" [disabled]="rejectSpinner"></button>
  </div>
</p-dialog>