import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { LogBase } from './../../../services/logger.service';
import { Title } from "@angular/platform-browser";
import { TableColumns } from '../../../interfaces/global.interfaces';
import { ReferenceApiService } from '../../../services/api/reference-api.service';

interface ColorName {
  Id: number;
  Color: string;
}

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.css'],
  providers: [ConfirmationService]
})

export class ManageColorsComponent implements OnInit {

  itemCols: TableColumns[] = [
    { field: 'Color', header: 'Color' }
  ];

  showSaveSpinner: boolean = false;
  displayDialog: boolean = false;
  colors: ColorName[] | null = null;
  showLoadingSpinner: boolean = false;
  displayUdateColorDialogue: boolean = false;
  newcolor: string | null = null;

  constructor(
    public api: ApiService,
    private messageService: MessageService,
    private referenceApiService: ReferenceApiService,
    private log: LogBase,
    private titleService: Title,
  ) {
    this.titleService.setTitle("AVECS Colors");
  }

  async ngOnInit() {
    await this.getColors();
  }

  private async getColors() {
    this.showLoadingSpinner = true;
    try {
      const colors = await this.api.getColors();
      if (!colors) this.toastMessage('error', 'Failed to get colors. Please contact support.', '');
      else this.colors = colors;
    } catch (error) {
      this.toastMessage('error', 'Failed to get colors. Please contact support.', '');
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  public async updateColour(colors: ColorName) {
    this.showSaveSpinner = true;
    try {
      const retval = await this.referenceApiService.updateColor(colors.Id, colors.Color);
      if (!retval) this.toastMessage('error', 'Failed updating color. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.toastMessage('success', retval.errorMessage, '');
    } catch (error) {
      this.toastMessage('error', 'Failed updating color. Please contact support.', '');
      this.log.error(error);
    }
    this.showSaveSpinner = false;
  }

  public async saveColor() {
    this.showSaveSpinner = true;
    try {
      const retval = await this.referenceApiService.addColor(this.newcolor);
      if (!retval) this.toastMessage('error', 'Failed add color. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        this.colors.push({
          Id: retval.result,
          Color: this.newcolor
        });
        this.displayDialog = false;
      }
    } catch (error) {
      this.log.error(error);
      this.toastMessage('error', 'Failed add color. Please contact support.', '');
    }
    this.showSaveSpinner = false;
  }

  addColor() {
    this.newcolor = null;
    this.displayDialog = true;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}