<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Shipping Lines</h1>
  </div>
  <div class="ui-g-12">
    <p-table [loading]="showLoadingSpinner" #dt [rows]="50" [autoLayout]="true" [columns]="itemCols" [scrollable]="true"
      scrollHeight="350px" [value]="vesselCompany" [paginator]="true" [globalFilterFields]="['Company', 'VesselCompanyName', 'Name',
          'AssignedTo']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add Shipping Company"
              (click)="showAddShippingCompany=true" type="button" style="float: right; margin-right: 15px"
              icon="fas fa-plus"></button>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search shipping lines" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Short Name</th>
          <th>Shipping Company</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td [ngStyle]="{'background-color' : (rowData.LegalEntityId == -1 || rowData.LegalEntityId
                        == 0 ) ? '#FFC1CD' : 'transparent' }">
            {{rowData.Company}}</td>
          <td pEditableColumn>
            <p-dropdown [options]="rowData.ShippingLines" [(ngModel)]="rowData.selectedshippingLine" [filter]="true"
              (onChange)="onSelectedShippingLine(rowData)" placeholder="Select a Shipping Line"
              [style]="{width:'100%'}">
            </p-dropdown>
          </td>
          <td>
            <button pRipple pButton class="p-button-info" [disabled]="rowData.VesselCount == 0" icon="fas fa-ship"
              (click)="ShowVessels(rowData)" pTooltip="Show vessels" tooltipPosition="left">
            </button>
            <button pRipple pButton class="p-button-info" icon="far fa-address-card"
              (click)="DeleteShippingLine(rowData)" pTooltip="Delete shipping line" tooltipPosition="left">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g" *ngIf="selectedShippingLine">
    <div class="ui-g-12">
      <h2>{{ selectedShippingLine.Company }} Line Vessels</h2>
    </div>
    <p-table class="ui-g-12" #ve [value]="shippingLineVessels" [scrollable]="true" [rows]="50" [paginator]="true"
      scrollHeight="350px" [paginator]="true" [globalFilterFields]="['VesselName']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="ve.filterGlobal($event.target.value, 'contains')"
              placeholder="Search vessels" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Vessel Name</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.VesselName}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Delete" modal="true" [(visible)]="showDeleteShipplinLine" [style]="{height: '200px',width:'450px'}">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>Are you sure you want to delete this Shipping Line? (The company has No vessels assigned to
        it)</label>
    </div>
    <div class="ui-g-12">
      <button pRipple class="p-button-info width-100" pButton type="button" (click)="deleteShippingCompany()"
        label="Delete"></button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Reassign Vessels" modal="true" [(visible)]="showReassignVessels"
  [style]="{height: '220px',width:'450px'}">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>The selected Shippling Line has {{selectedShippingLine?.VesselCount}} vessels assigned to it. Please
        select a Shipping Line to re-assign these vessels to:</label>
    </div>
    <div class="ui-g-3">
      <label>Shipping Line</label>
    </div>
    <div class="ui-g-9">
      <p-dropdown [options]="shippingLines" [(ngModel)]="selectedReassignToShippingLine" [filter]="true" appendTo="body"
        placeholder="Select a Shipping line" optionLabel="label" [style]="{width:'100%'}"></p-dropdown>
    </div>
    <div class="ui-g-12">
      <button pRipple class="p-button-info width-100" pButton type="button" (click)="ReassignVesselsAndDelete()"
        label="Delete"></button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Add Shipping Company" modal="true" [(visible)]="showAddShippingCompany" [style]="{width: '50vw'}"
  class="add-shipping">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>Please ensure that you have added The Shipping Line as a Contact, and set the Company Type to
        'Shipping Line'</label>
    </div>
    <div class="ui-g-3">
      <label>Shipping Line</label>
    </div>
    <div class="ui-g-9">
      <input pInputText [(ngModel)]="shippingLineName" />
    </div>
    <div class="ui-g-3">
      <label>Link to Contact</label>
    </div>
    <div class="ui-g-9">
      <p-dropdown [options]="shippingLines" [(ngModel)]="selectedNewShippingLine" [filter]="true"
        placeholder="Select a Shipping line" optionLabel="label"></p-dropdown>
    </div>
    <div class="ui-g-12">
      <button pRipple class="p-button-info" pButton type="button" (click)="AddShippingLine()" label="Add"></button>
      <button pRipple class="p-button-info" pButton type="button" (click)="showAddShippingCompany = false"
        label="Cancel"></button>
    </div>
  </div>
</p-dialog>