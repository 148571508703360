import { Injectable } from "@angular/core";
import { HttpResponse } from "../../interfaces/result-interfaces";
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";

@Injectable({
  providedIn: 'root'
})
export class WhatsappApiService {

  private serverUrl: string = `${this.config.getServerUrl()}api/whatsapp/`

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }


  // Endpoint: api/whatsapp/WAGetChatMedia
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetChatMedia
  public async WAGetChatMedia(refNo: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}WAGetChatMedia?refNo=${refNo}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetChatMessagesByRefNo
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetChatMessagesByRefNo
  public async WAGetChatMessagesByRefNo(refNo: string): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetChatMessagesByRefNo?refNo=${refNo}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetChatMessagesByChatId
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetChatMessagesByChatId
  public async WAGetChatMessagesByChatId(chatId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetChatMessagesByChatId?chatId=${chatId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetAllTemplates
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetAllTemplates
  public async WAGetAllTemplates(productId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetAllTemplates?productId=${productId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetTemplateParams
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetTemplateParams
  public async WAGetTemplateParams(templateId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetTemplateParams?templateId=${templateId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WABroadcastTemplate
  // Controller Name: WhatsappController.cs
  // Proc Name: none - whatsapp deals with the sending of the template
  public async WABroadcastTemplate(wABroadcastTemplate: any): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WABroadcastTemplate`;
      this.webApi.post(null, url, wABroadcastTemplate);
      return {
        errorCode: 0,
        errorMessage: 'Broadcasting template has been queued'
      }
    } catch (error) {
      this.log.error(error)
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetAllTemplateMessages
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetAllTemplateMessages
  public async WAGetAllTemplateMessages(productId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetAllTemplateMessages?productId=${productId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetUnmatchedRefNumber
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetUnmatchedRefNumber
  public async WAGetUnmatchedRefNumbers(productId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetUnmatchedRefNumber?productId=${productId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WADeclineTandC
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WADeclineTermsAndConditions
  public async WADeclineTermsAndConditions(FileId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WADeclineTandC?FileId=${FileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error)
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAupdateChatRefNumber
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAUpdateChatRefNumber
  public async WAUpdateChatRefNo(payload: any): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAupdateChatRefNumber`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error)
    }
  }

  // Endpoint: api/whatsapp/WAGetAllRoomChats
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetAllRoomChats
  public async WAGetAllRoomChats(productId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetAllRoomChats?productId=${productId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetVoyageTemplates
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetVoyageTemplates
  public async WAGetVoyageTemplates(productId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetVoyageTemplates?productId=${productId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetVoyageTemplateDetails
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetVoyageTemplateDetails
  public async WAGetVoyageTemplateDetails(vesselId: number, voyageId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetVoyageTemplateDetails?VesselId=${vesselId}&voyageId=${voyageId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetProducts
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetProducts
  public async WAGetProducts(): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetProducts`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
  
  // Endpoint: api/whatsapp/WAGetAutoResponderStats
  // Controller Name: WhatsappController.cs
  // Proc Name: Multiple procs
  public async WAGetAutoResponderStats(productId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetAutoResponderStats?productId=${productId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/whatsapp/WAGetUnverifiedDocuments
  // Controller Name: WhatsappController.cs
  // Proc Name: pr_WAGetUnverifiedDocuments
  public async WAGetUnverifiedDocuments(fileId: number): Promise<HttpResponse | null>  {
    try {
      const url = `${this.serverUrl}WAGetUnverifiedDocuments?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}