import { Component, OnInit } from "@angular/core";
import { TaskItem, TaskInstance, TableColumns } from "../../../interfaces/global.interfaces";
import { Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { LogBase } from "../../../services/logger.service";
import { ConfirmationService } from "primeng";
import { ConfigService } from "../../../services/config.service";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: "app-container-items",
  templateUrl: "./container-items.component.html",
  styleUrls: ["./container-items.component.css"],
})
export class ContainerItemsComponent implements OnInit {
  taskname = "ContainerItems";
  assignee = "files";

  task: TaskItem;
  msgs: any[];
  taskInstance: TaskInstance;

  taskId: string;

  file: any;
  containerItems: any = [];
  containerSeals: any = [];

  selectedSeal: any;
  selectedContainer: any;

  sizes: any = [];

  cols: TableColumns[] = [
    { field: "DBNNumber", header: "DBNNumber" },
    { field: "CargoType", header: "Cargo Type" },
    { field: "ForeignReference", header: "Reference" },
    { field: "Description", header: "Description" },
  ];

  id: any;
  showBackBtn: boolean = false;

  mblNumbers: any = [];
  selectedContainerNumbers: any = [];
  ContainerNumber: string;
  ContainerSize: any;
  SelectedISOCode: any;

  historical: boolean = false;
  canSaveDisabled: boolean = true;

  addContainerDialogVisible: boolean = false;
  addContainerWarningDialogVisible: boolean = false;
  addButtonDisabled: boolean = true;
  hideFilterButton: boolean = true;
  hideExportListButton: boolean = true;

  showLoadingSpinner: boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private titleService: Title,
  ) { this.titleService.setTitle("AVECS Container-Items"); }

  async ngOnInit() {
    await this.getURLParams();
    this.containerSeals = await this.api.getContainerSeals(this.id);
    this.containerItems = await this.api.getContainerItems(this.id);
    this.log.info("Init");
  }

  getURLParams() {
    try {
      this.route.queryParamMap.subscribe(
        params => this.id = params.get('id')
      );
      this.log.info(this.id);
    } catch (error) {
      this.log.error(error);
    }
  }

  openFile(id) {
    this.router.navigate(["/file-view"], { queryParams: { query: id } });
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message:
        friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: " + this.taskname + "<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";
        var subject: string =
          "NovaDT Error Message - Component: " + this.taskname;
        var res = this.api.sendFreeTextEmail(
          this.config.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          this.task.Reference,
          -1
        );
      },
      reject: () => { },
    });
  }
}
