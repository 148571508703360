import { Component, OnInit } from '@angular/core';
import { UserTaskApiService } from '../../../../services/api/user-tasks/user-tasks-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LogBase } from '../../../../services/logger.service';
import { AdhocUserTask } from '../../../../interfaces/user-tasks/adhoc-user-tasks.interface';
import { UserTaskStatus, UserTaskType } from '../../../../enums';

@Component({
  selector: 'app-adhoc-user-task',
  templateUrl: './adhoc-user-task.component.html',
  styleUrls: ['./adhoc-user-task.component.scss']
})
export class AdhocUserTaskComponent implements OnInit {

  userTaskId: number = 0;
  adhocUserTask: AdhocUserTask | null = null;

  showSpinner: boolean = false;
  markUserTaskAsComplete: boolean = false;

  public get userTaskType(): typeof UserTaskType { return UserTaskType; }

  constructor(
    private messageService: MessageService,
    private log: LogBase,
    private router: Router,
    private userTaskApiService: UserTaskApiService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.showSpinner = true;
    this.userTaskId = this.route.snapshot.params['userTaskId'];
    if (this.userTaskId) await this.getAdhocUserTask();
    this.showSpinner = false;
  }

  public async getAdhocUserTask() {
    try {
      const retval = await this.userTaskApiService.GetAdhocUserTask(this.userTaskId);
      if (!retval) this.toastMessage('error', 'Failed to get adhoc user task. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.adhocUserTask = retval.result;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to get adhoc user task. Something went wrong our side. Please contact support.', '');
    }
  }

  public async updateUserTask() {
    try {
      this.markUserTaskAsComplete = true;
      const resp = await this.userTaskApiService.UpdateUserTask(this.userTaskId, UserTaskStatus.COMPLETED);
      if (!resp) this.toastMessage('error', `Failed to complete adhoc user task. Please contact support.`, '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.toastMessage('success', resp.errorMessage, '');
        setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
      }
      this.markUserTaskAsComplete = false;
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', `Failed to complete adhoc user task. Something went wrong our side. Please contact support.`, '');
    }
  }

  public viewFile(fileId: number) { window.open(`file-view?query=${fileId}`); }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}