import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../config.service';
import { LogBase } from '../logger.service';
import { WebApi2Service } from '../web.api2.service';
import { HttpResponse } from '../../interfaces/result-interfaces';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  constructor(
    private router: Router, 
    private webApi: WebApi2Service, 
    private log: LogBase,
    private config: ConfigService
  ) { }

  /*
   *  POST: api/LegalEntity/notifyPortalUserAboutFilePermissionChange
   *  SERVICE: NotifyPortalUserAboutFilePermissionChange(legalEntityId) @ LegalEntityController
   *  PROC: pr_GetLegalEntityById | pr_GetUserFilePermissions | pr_GetPortalUser
   */
  async notifyPortalUserAboutFilePermissionChange(legalEntityId: any) {
    try {
      const url = this.config.getServerUrl() + "api/LegalEntity/notifyPortalUserAboutFilePermissionChange?legalEntityId=" + legalEntityId;
      var retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  /*
   * POST: api/LegalEntity/hasPortalLogin
   * SERVICE: HasPortalLogin(legalEntityId) @ LegalEntityController
   * PROC: pr_HasPortalLogin
   */
  async hasPortalLogin(legalEntityId: number) {
    try {
      var url = this.config.getServerUrl() + "api/LegalEntity/hasPortalLogin?legalEntityId=" + legalEntityId;
      const companypersonid = await this.webApi.post(null, url, null);
      if (companypersonid.errorCode == 0) {
        return companypersonid.result;
      } else if (companypersonid.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  /*
   * POST: api/LegalEntity/addPortalUser
   * SERVICE: HasPortalLogin(legalEntityId) @ LegalEntityController
   * PROC: pr_HasPortalLogin
   * NOTE: If adding a user without a file make sure to add 0 as permissionId and 0 as fileId
   */
  async addPortalUser(legalEntityId: number, permissionId: number, fileId: number): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + `api/LegalEntity/addPortalUser?legalEntityId=${legalEntityId}&fileId=${fileId}&permissionId=${permissionId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  /*
   * POST: api/LegalEntity/CheckPortalUserExists
   * SERVICE: CheckPortalUserExists(legalEntityId) @ LegalEntityController
   * PROC: pr_CheckPortalUserExists
   */
  async checkPortalUserExists(legalEntityId: number) : Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + "api/LegalEntity/CheckPortalUserExists?legalEntityId=" + legalEntityId;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }
}
