<div class="ui-g card-w-title">
  <div class="ui-g-12">
    <button class="createLoad" pButton type="button" icon="pi pi-plus" label="Create a new Load"
      (click)="createLoad()"></button>
    <p-card header="Filter Files by Borderpost" [style]="{width: '360px'}" styleClass="ui-card-shadow">
      <div class="ui-g filter-card">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="borderPosts"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label, value.description"
            [showClear]="true" [(ngModel)]="selectedBorderPost" name="dropOffLocation" appendTo="body">
          </p-dropdown>
          <label for="float-input">Select a Borderpost *</label>
        </span>
        <button *ngIf="!borderPostSpinner" [disabled]="!selectedBorderPost" pButton type="button" label="Filter"
          icon="pi pi-check" class="width-100" (click)="FilterFiles()"></button>
        <button *ngIf="borderPostSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
          [disabled]="true">
          <i class="pi pi-spin pi-spinner font-size-2em"></i>
        </button>
      </div>
    </p-card>
  </div>
  <div class="ui-g-12">
    <hr>
  </div>
  <div class="ui-g-12">
    <p-card
      [header]="selectedBorderPost ? 'Select Load Plans for ' + selectedBorderPost.description : 'Select Load Plans'"
      [style]="{width: '360px'}" styleClass="ui-card-shadow">
      <div class="ui-g filter-card">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown [options]="filteredLoads" [(ngModel)]="selectedLoadPlan" optionLabel="name" [showClear]="true"
            [autoDisplayFirst]="false" [disabled]="!selectedBorderPost">
          </p-dropdown>
          <label for="float-input">Select a Load</label>
        </span>
        <div class="ui-g-12 load-plan-actions">
          <button [disabled]="!selectedLoadPlan" pButton type="button" label="Select" icon="pi pi-check"
            class="width-100" (click)="recallPrePlanCargoItems()"></button>
          <button [disabled]="!selectedLoadPlan" pButton type="button" label="Promote to Delivery" class="width-100"
            (click)="PromotePrePlanLoadToDeliveryLoad()" icon="pi pi-check-circle"></button>
        </div>
      </div>
    </p-card>
  </div>

  <div class="files-table ui-g-12">
    <p-table #dtR [value]="filesReady" dataKey="dbnNumber" editMode="row" [paginator]="true" [rows]="10"
      [loading]="borderPostSpinner" [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
      [globalFilterFields]="['dbnNumber','currentLocation', 'route','finalDestination', 'cargoDescription', 'borderDutiesUploaded', 'paidDate']"
      [resizableColumns]="true" [autoLayout]="true" [(selection)]="selectedFiles">
      <ng-template pTemplate="caption">
        <button class="action-button" pButton type="button" label="Add To Load"
          [disabled]="!selectedFiles || selectedFiles.length === 0" (click)="AddToDeliveryPrePlan()"></button>
        <h2 style="justify-content: center; display: flex; margin: 0px 0px -35px 0px;" *ngIf="selectedBorderPost">
          SHOWING FILES FOR
          {{ selectedBorderPost.description }}</h2>
        <h2 style="justify-content: center; display: flex; margin: 0px 0px -35px 0px;" *ngIf="!selectedBorderPost">
          No
          border post selected
        </h2>
        <div class="p-d-flex custom-note-buttons">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search" style="color: white;"></i>
            <input pInputText type="text" (input)="dtR.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th pSortableColumn="dbnNumber">DBN Number <p-sortIcon field="dbnNumber"></p-sortIcon>
          </th>
          <th pSortableColumn="cargoDescription">Cargo Description <p-sortIcon field="cargoDescription">
            </p-sortIcon>
          </th>
          <th pSortableColumn="route">Route<p-sortIcon field="route"></p-sortIcon>
          </th>
          <th pSortableColumn="currentLocation">Current Location <p-sortIcon field="currentLocation"></p-sortIcon>
          </th>
          <th pSortableColumn="cargoValue">Cargo Value <p-sortIcon field="cargoValue"></p-sortIcon>
          </th>
          <th pSortableColumn="daysSinceReady">Days Ready <p-sortIcon field="daysSinceReady"></p-sortIcon>
          </th>
          <th pSortableColumn="loadName">On Load <p-sortIcon field="loadName"></p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'dbnNumber', 'contains')"
              placeholder="DBN Number" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'cargoDescription', 'contains')"
              placeholder="Cargo Desc" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'route', 'includes')"
              placeholder="Route" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'currentLocation', 'contains')"
              placeholder="Location" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'cargoValue', 'contains')"
              placeholder="Value" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'daysSinceReady', 'contains')"
              placeholder="Days" class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text" (input)="dtR.filter($event.target.value, 'loadName', 'contains')"
              placeholder="Load Name" class="p-column-filter">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData" [ngClass]="{ 'disabled': rowData.dispatchCargoLoadExists == 1}">
          <td>
            <p-tableCheckbox [value]="rowData"
              [disabled]="rowData.dispatchCargoLoadExists == 1 || rowData.zarAmount == 0"
              title="Please ensure this file has a cargo value"></p-tableCheckbox>
          </td>
          <td>
            {{rowData.dbnNumber}}
          </td>
          <td>
            {{rowData.cargoDescription}}
          </td>
          <td>
            {{rowData.route}}
          </td>
          <td>
            {{rowData.currentLocation}}
          </td>
          <td>
            R{{rowData.zarAmount}}
          </td>
          <td>
            {{rowData.daysSinceReady}}
          </td>
          <td>
            {{rowData.loadName}}
          </td>
          <td><button pTooltip="View {{ rowData.dbnNumber}}" tooltipPosition="left" pButton type="button"
              icon="far fa-folder" (click)="viewFile(rowData.dbnNumber)"></button></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="load-table ui-g-12" *ngIf="showLoadCargoTable && loadInfo">
    <div class="row">
      <div id="box" class="load-info">
        <p>{{ loadInfo.loadName }}</p>
        <p>{{ loadInfo.driverName }}</p>
        <p>{{ loadInfoTransporter.label }}</p>
        <p>{{formatDate(loadInfo.estimatedDeparture)}}</p>
        <p>{{ loadInfo.trailerInfo }}</p>
        <p>{{ loadInfo.truckInfo }}</p>
        <p style="border: none;">|</p>
        <button [disabled]="selectedLoadPlan == null" pButton type="button" label="Edit" icon="pi pi-pencil"
          style="margin-top: 13px; height: 32px;" (click)="editLoadDialog()"></button>
      </div>
    </div>
    <p-table #dtR [columns]="loadCargoColumns" [value]="loadCargo" class="ui-g-12" dataKey="dbnNumber" editMode="row"
      [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
      [globalFilterFields]="['dbnNumber','currentLocation', 'route','finalDestination', 'cargoDescription', 'borderDutiesUploaded', 'paidDate']"
      [resizableColumns]="true" [autoLayout]="true" [(selection)]="selectedFiles">
      <ng-template pTemplate="caption">
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="{'width': col.field == 'dbnNumber' ? '100px' : ''}">
            {{ col.header }}
          </th>
          <th style="width: 80px;">
            Actions
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
          <td class="action-button"><button pButton icon="pi pi-trash"
              style="margin: 0 auto; display: block; background-color: tomato;"
              (click)="removeCargoItem(rowData)"></button>
            <button pTooltip="View {{ rowData.dbnNumber}}" tooltipPosition="left" pButton type="button"
              icon="far fa-folder" (click)="viewFile(rowData.dbnNumber)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- DIALOGS -->
<!-- Dialog for Create Load -->
<p-dialog header="Create New Delivery Load" [(visible)]="showDialogCreate" [style]="{width: '30vw'}">
  <form #f2="ngForm" (ngSubmit)="createNewPrePlanLoad()">
    <div class="content">
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <input required class="width-100" id="float-input" type="text" pInputText [(ngModel)]="deliveryLoad.loadName"
            name="loadName" />
          <label for="float-input">Load Name*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-calendar required [(ngModel)]="deliveryLoad.ETD" name="ETD" [showTime]="true" [showButtonBar]="true"
            [readonlyInput]="true" inputId="time" appendTo="body">
          </p-calendar>
          <label for="float-input" [style]="{'padding-top': '5px'}">Estimated
            Departure*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="locationTypes"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label,value.description"
            [showClear]="true" (onChange)="getLocationType()" [(ngModel)]="this.deliveryLoad.dropoffLocationType"
            name="dropoffLocationType" appendTo="body">
          </p-dropdown>
          <label for="float-input">Destination Type*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="locations"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label, value.description"
            [showClear]="true" [(ngModel)]="this.deliveryLoad.dropOffLocation" name="dropOffLocation" appendTo="body">
          </p-dropdown>
          <label for="float-input">Destination*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <input required class="width-100" id="float-input" type="text" pInputText
            [(ngModel)]="deliveryLoad.driverName" name="driverName" />
          <label for="float-input">Drivers Name*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required [autoDisplayFirst]="false" [options]="transporters" optionLabel="label"
            optionValue="value" type="text" [(ngModel)]="selectedTransporter" name="transporterId"
            [style]="{'width':'100%'}" [filter]="true" [showClear]="true" appendTo="body">
          </p-dropdown>
          <label for="float-input">Transporter*</label>
        </span>
      </div>
    </div>
  </form>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" class="width-100" label="Create New Load"
      (click)="createNewPrePlanLoad()" [disabled]="!f2.valid"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Select Load For File Adding" [(visible)]="addReadyFilesToLoad" [style]="{width: '35vw'}">
  <div class="ui-g-12">
    <span class="ui-g-12 ui-float-label">
      <label class="pad-left" for="float-input">Loads</label>
    </span>
  </div>
  <div class="assign-load">
    <button pButton type="button" label="Add Files To Load" class="ui-button-rounded"></button>
  </div>
</p-dialog>

<p-dialog header="Edit Load Information" [(visible)]="editLoadInfo" [style]="{width: '35vw'}" *ngIf="loadInfo">
  <div>
    <p-panel>
      <p-header class="prime-header">
        <span>Load Information</span>
      </p-header>
      <div class="form-group">
        <div class="form-item">
          <label class="pad-left">Load Name</label>
          <input class="edit-input" type="text" pInputText [(ngModel)]="editedLoads.editedLoadName">
        </div>
        <div class="form-item">
          <label class="pad-left">Driver Name</label>
          <input class="edit-input" type="text" pInputText [(ngModel)]="editedLoads.editedDriverName">
        </div>
        <div class="form-item">
          <label class="pad-left" for="float-input">Estimated Departure</label>
          <p-calendar class="dateStyle" [(ngModel)]="loadInfo.estimatedDeparture" [showTime]="true" inputId="time"
            appendTo="body" [style]="{'width': '100%'}">
          </p-calendar>
        </div>
        <div class="form-item">
          <label class="pad-left">Transporter</label>
          <p-dropdown [options]="transporters" [(ngModel)]="editedLoadInfoTransporter" optionLabel="label"
            optionValue="value" [autoDisplayFirst]="false" [style]="{'width':'100%'}" [filter]="true" [showClear]="true"
            appendTo="body">
          </p-dropdown>
        </div>
        <div class="form-item">
          <label class="pad-left">Trailer Info</label>
          <input class="edit-input" type="text" pInputText [(ngModel)]="editedLoads.editedTrailer">
        </div>
        <div class="form-item">
          <label class="pad-left">Truck Info</label>
          <input class="edit-input" type="text" pInputText [(ngModel)]="editedLoads.editedTruck">
        </div>
        <div class="button-span">
          <button pButton label="Save" class="width-100" (click)="editLoad()"></button>
        </div>
      </div>
    </p-panel>
  </div>
</p-dialog>