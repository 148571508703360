import { Component, OnInit } from "@angular/core";
import { TaskItem, TaskInstance } from "../../../interfaces/global.interfaces";
import { Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { LogBase } from "../../../services/logger.service";
import { ConfirmationService } from "primeng";
import { MessageService } from 'primeng/api';
import { ConfigService } from "../../../services/config.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import * as _ from "lodash";
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: "app-containers",
  templateUrl: "./containers.component.html",
  styleUrls: ["./containers.component.css"],
})
export class ContainersComponent implements OnInit {
  taskname = "Containers";
  assignee = "files";

  task: TaskItem;
  msgs: any[];
  taskInstance: TaskInstance;

  taskId: string;

  file: any;
  containers: any = [];

  cols: any = [];
  sizes: any = [];

  state: any;
  showBackBtn: boolean = false;

  selectedContainer: any;

  mblNumbers: any = [];
  selectedContainerNumbers: any = [];
  ContainerNumber: string;
  ContainerSize: any;
  SelectedISOCode: any;

  historical: boolean = false;
  canSaveDisabled: boolean = true;

  ISOContainerGroups: any = [];

  addContainerDialogVisible: boolean = false;
  addContainerWarningDialogVisible: boolean = false;
  addButtonDisabled: boolean = true;
  hideFilterButton: boolean = true;
  hideExportListButton: boolean = true;

  showLoadingSpinner: boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private log: LogBase,
    private authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private _location: Location,
    private titleService: Title,
  ) {
    this.titleService.setTitle("AVECS Containers");
  }

  async ngOnInit() {
    await this.getURLParams();
    this.checkState();
    this.ISOContainerGroups = await this.api.getISOContainerGroups();
    this.containers = await this.api.getContainers();
    this.log.info("Init");
  }

  getURLParams() {
    try {
      this.route.paramMap.subscribe(
        params => this.state = params.get('state')
      );
    } catch (error) {
      this.log.error(error);
    }
  }

  checkState() {
    if (this.state === 'add') {
      this.showBackBtn = true;
    }
  }

  goBack() {
    this._location.back();
  }

  viewContainer(id) {
    this.router.navigate(["/container-items"], { queryParams: { id: id } });
  }

  async showContainerWarningDialog() {
    this.addContainerWarningDialogVisible = true;
  }

  async hideContainerWarningDialog() {
    this.addContainerWarningDialogVisible = false;
    this.addContainerDialogVisible = true;
  }

  async showContainerDialog() {

    this.ContainerNumber = undefined;
    this.ContainerSize = undefined;
    this.SelectedISOCode = undefined;
    this.canSaveDisabled = true;
    this.addContainerDialogVisible = true;
  }

  async hideContainerDialog() {
    this.addContainerDialogVisible = false;
  }

  async addContainer() {
    this.addContainerDialogVisible = false;
    this.showLoadingSpinner = true;
    try {
      let success = await this.api.addNewContainer(this.ContainerNumber, this.SelectedISOCode.Id);
      if (success) {
        this.containers = await this.api.getContainers();
        this.messageService.add({ severity: "info", summary: "Container Added", detail: "Container " + this.ContainerNumber + " was added" });
        this.ContainerNumber = null;
        this.SelectedISOCode = null;
      } else {
        this.showContainerWarningDialog();
      }
    } catch (error) {
      this.messageService.add({ severity: "error", summary: "Error Adding Master Bill", detail: "Container " + this.ContainerNumber + " could not be added." });
      this.showError("Error Adding Master Bill", error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message:
        friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: " + this.taskname + "<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";
        var subject: string =
          "NovaDT Error Message - Component: " + this.taskname;
        var res = this.api.sendFreeTextEmail(
          this.config.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          this.task.Reference,
          -1
        );
      },
      reject: () => { },
    });
  }
}
