<div class="ui-g-5"></div>
<div class="ui-g-7">
    <p-progressSpinner *ngIf="showSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"
        animationDuration="2s"></p-progressSpinner>
</div>

<div *ngIf="!showSpinner && !fileExists" class="ui-g card card-w-title">
    <h3 style="color: red">Error: File does not exist.</h3>
</div>

<div *ngIf="!showSpinner && fileExists" class="ui-g card card-w-title">
    <div class="ui-g-12 display-flex">
        <div class="file-number-header">
            <h3>Create Storage Invoice for </h3>
            <file-number [fileNumber]="getPayload.dbnNumber"></file-number>
        </div>
        <div class="display-flex">
            <h3>Exchange Rates</h3>
            <label *ngFor="let rate of currencyRates" class="exchange-rates">
                <strong>{{rate.Currency}} :</strong> {{rate.Rate}}
            </label>
        </div>
    </div>

    <div class="ui-g-4">
        <p-panel [style]="{'height':'420px'}">
            <p-header>
                Invoice To
            </p-header>
            <div class="ui-g">
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label">
                        <p-dropdown [options]="invoicees" optionLabel="label" [filter]="true" [autoDisplayFirst]="false"
                            [(ngModel)]="selectLegalEntityTo" (onChange)="getLegalEntityDetails($event.value)"
                            [style]="{'width':'100%'}">
                        </p-dropdown>
                        <label class="float-label" for="float-input">Invoicee *</label>
                    </span>
                </div>
                <div *ngIf="selectedInvoicee" class="invoice-to">
                    <strong>Email</strong>
                    <label>
                        {{ selectedInvoicee.Email || 'N/A' }}
                    </label>
                    <strong>Address</strong>
                    <label>
                        {{ selectedInvoicee.Address1 || 'N/A' }}
                        <a *ngIf="!selectedInvoicee.Address1">
                            (Address 1)
                        </a>
                    </label>
                    <label>
                        {{ selectedInvoicee.Address2 || 'N/A' }}
                        <a *ngIf="!selectedInvoicee.Address2">
                            (Address 2)
                        </a>
                    </label>
                    <label>
                        {{ selectedInvoicee.Address3 || 'N/A' }}
                        <a *ngIf="!selectedInvoicee.Address3">
                            (Address 3)
                        </a>
                    </label>
                    <strong>City</strong>
                    <label>
                        {{ selectedInvoicee.City || 'N/A' }}
                    </label>
                </div>
            </div>
        </p-panel>
    </div>

    <div class="ui-g-8">
        <p-panel [style]="{'height':'420px'}">
            <p-header>
                Invoice Options
            </p-header>
            <div class="ui-g">
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label" *ngIf="warehouseCount === 1">
                        <input readonly min="0" pInputText value="{{warehouse[0].warehouse}}" class="width-100" />
                        <label class="float-label" for="float-input">Warehouse</label>
                    </span>
                    <span class="ui-g-12 ui-float-label" *ngIf="warehouseCount > 1">
                        <p-dropdown [options]="warehouse" optionLabel="warehouse" [style]="{'width':'100%'}"
                            [autoDisplayFirst]="false" (onChange)="GetStorageLineItems($event.value.warehouseId)">
                        </p-dropdown>
                        <label class="float-label" for="float-input">Warehouse</label>
                    </span>
                </div>
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label">
                        <p-dropdown [options]="currencies" [autoDisplayFirst]="false" [style]="{'width':'100%'}"
                            [(ngModel)]="selectedCurrency" optionLabel="currency"
                            (onChange)="onCurrencySelect($event.value)">
                        </p-dropdown>
                        <label class="float-label" for="float-input">Currency *</label>
                    </span>
                </div>
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label">
                        <p-dropdown [options]="bankAccounts" [autoDisplayFirst]="false"
                            (onChange)="setBankAccountOne = $event.value" [(ngModel)]="getPayload.bankAccountOneId"
                            [filter]="true" [style]="{'width':'100%'}">
                        </p-dropdown>
                        <label class="float-label" for="float-input">Bank Acc #1 *</label>
                    </span>
                </div>
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label">
                        <p-dropdown [options]="bankAccounts" [autoDisplayFirst]="false"
                            (onChange)="setBankAccountTwo = $event.value" [(ngModel)]="getPayload.bankAccountTwoId"
                            [filter]="true" [style]="{'width':'100%'}">
                        </p-dropdown>
                        <label class="float-label" for="float-input">Bank Acc #2 *</label>
                    </span>
                </div>
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label">
                        <p-calendar [(ngModel)]="effectiveDate" class="width-100" [readonlyInput]="true"
                            (onSelect)="onEffectiveDateSelect()" dateFormat="yy/mm/dd">
                        </p-calendar>
                        <label class="float-label" for="float-input">Effective Date *</label>
                    </span>
                </div>
                <div class="ui-g-12">
                    <span class="ui-g-12 ui-float-label discount-amount">
                        <label *ngIf="showCurrencySymbol">{{ selectedCurrency.symbol }}</label>
                        <input pTooltip="Discount cannot be more than total Invoice" min="1" onwheel="blur()"
                            (keyup)="setDiscountAmount = discountAmount" class="width-100" type="number" pInputText
                            [(ngModel)]="discountAmount" required />
                        <label class="float-label" for="float-input">Discount Amount</label>
                    </span>
                </div>
                <div class="ui-g-12 display-flex">
                    <strong (click)=" onVATSelect();" class="padding-left-8px cursor-pointer">Include VAT</strong>
                    <input class="check-box" [checked]="includeVAT" type="checkbox" (change)="onVATSelect()" />
                    <strong class="cursor-pointer"
                        (click)="type == 'Quote' ? return : emailInvoicee = !emailInvoicee">Email
                        Invoicee</strong>
                    <input class="check-box" [disabled]="type == 'Quote'" [checked]="emailInvoicee" type="checkbox" />
                    <button (click)="showFileCargo = !showFileCargo" label="View Cargo" pRipple class="p-button-info"
                        pButton type="button" icon="fas fa-truck"></button>
                </div>
            </div>
        </p-panel>
    </div>

    <div class="ui-g-12">
        <p-panel>
            <p-header>
                <strong *ngIf="getPayload.invoiceNumber">{{ getPayload.invoiceNumber }} </strong>
                <label>Invoice Line Items</label>
            </p-header>
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table [value]="getPayload.lineItems" editMode="row">
                        <ng-template pTemplate="header">
                            <tr class="table-column-header">
                                <th colspan="3">
                                    DESCRIPTION
                                </th>
                                <th colspan="2">
                                    PASTEL CODE
                                </th>
                                <th colspan="1">
                                    QTY
                                </th>
                                <th colspan="2">
                                    UNIT AMOUNT
                                </th>
                                <th colspan="2">
                                    NET AMOUNT
                                </th>
                                <th colspan="2"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
                            <tr *ngIf="!rowData.description.toLowerCase().includes('discount')">
                                <td colspan="3">
                                    <p-dropdown [options]="lineItemDescriptionOptions" required [filter]="true"
                                        (onChange)="onInvoiceItemAdd($event.value, rowIndex);"
                                        [(ngModel)]="selectedLineItemDescriptions[rowIndex].label"
                                        [autoDisplayFirst]="false" [style]="{'width':'100%'}">
                                    </p-dropdown>
                                </td>
                                <td colspan="2">{{ rowData.pastelCode}}</td>
                                <td colspan="1">
                                    <input onwheel="blur()" class="width-100" min="1" max="20" type="number" pInputText
                                        [(ngModel)]="rowData.quantity"
                                        [disabled]="!selectedLineItemDescriptions[rowIndex].label"
                                        (keyup)="setLineItemNetAmount(rowIndex)" required>
                                </td>
                                <td class="amount" colspan="2"
                                    [ngStyle]="{'color' : (!selectedLineItemDescriptions[rowIndex].label ? 'grey' : 'black')}">
                                    <label>{{ selectedCurrency.symbol }}</label>
                                    <input onwheel="blur()" min="1" (keyup)="setLineItemNetAmount(rowIndex)"
                                        class="width-100" type="number" pInputText [(ngModel)]="rowData.amount"
                                        [disabled]="!selectedLineItemDescriptions[rowIndex].label" required>
                                </td>
                                <td colspan="2"
                                    [ngStyle]="{'color' : (!selectedLineItemDescriptions[rowIndex].label ? 'grey' : 'black')}">
                                    {{
                  financeHelper.formatAmountWithCurrencySymbol(rowData.netAmount, selectedCurrency.code,
                  selectedCurrency.id)
                  }}
                                </td>
                                <td colspan="2" class="remove-item-action">
                                    <i pTooltip="Remove Line Item" tooltipPosition="left"
                                        (click)="onInvoiceItemRemove(rowData)" class="pi pi-times remove-line-item"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="bottom-section">
                        <label class="add-line-item" (click)="addLineItem();">
                            <i class="fas fa-solid fa-plus"></i>
                            Invoice Line Item
                        </label>
                        <div class="ui-g-4 invoice-total-amounts">
                            <div class="ui-g-6">
                                <label>Total Exc VAT</label>
                                <label>VAT ({{ vatRate }}%)</label>
                                <label>Discount</label>
                                <label>TOTAL</label>
                            </div>
                            <div class="ui-g-6" *ngIf="selectedCurrency">
                                <label>{{ financeHelper.formatAmountWithCurrencySymbol(getInvoiceTotalExcludingVat,
                  selectedCurrency.code, selectedCurrency.id) }}</label>
                                <label>{{ financeHelper.formatAmountWithCurrencySymbol(getVatAmount, selectedCurrency.code,
                  selectedCurrency.id) }}</label>
                                <label>{{ financeHelper.formatAmountWithCurrencySymbol(getDiscount, selectedCurrency.code,
                  selectedCurrency.id) }}</label>
                                <label>{{ financeHelper.formatAmountWithCurrencySymbol(getInvoiceTotalIncludingVat,
                  selectedCurrency.code,
                  selectedCurrency.id) }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
        <div class="actions">
            <button *ngIf="!postSpinner" pRipple class="submit width-100" (click)="submit()"
                [disabled]="canCreateInvoice()" pButton type="button" label="Create Invoice"></button>
            <button *ngIf="postSpinner" pRipple class="p-button-info submit width-100" pButton type="button"
                [disabled]="true">
                <i class="pi pi-spin pi-spinner font-size-2em"></i>
            </button>
        </div>
    </div>
</div>

<p-dialog [(visible)]="showFileCargo" [modal]="true" [draggable]="false" [style]="{width: '80vw', 'height': 'auto'}">
    <p-header>
        {{ fileId }} Cargo Item(s)
    </p-header>

    <p-table #dt *ngIf="cargoItems" [value]="cargoItems" dataKey="DBNNumber" [rows]="100" [autoLayout]="true"
        [showCurrentPageReport]="true" [globalFilterFields]="['DBNNumber','Description']"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search keyword" style="color: #fff;" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr class="table-labels text-center">
                <th>
                    Cargo Item Type
                </th>
                <th>
                    Quantity
                </th>
                <th>
                    Description
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr class="table-labels">
                <td>{{item.CargoItemType}}</td>
                <td>{{item.Quantity}}</td>
                <td>{{item.Description}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td>No cargo items found.</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>