import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileIssueType } from '../../enums';
import { FilesWithIssues } from '../../interfaces/global.interfaces';

@Component({
  selector: 'file-issues-pop-up',
  templateUrl: './file-issues-pop-up.component.html',
  styleUrls: ['./file-issues-pop-up.component.scss']
})
export class FileIssuesPopUpComponent {

  @Input() public files: FilesWithIssues[] = [];
  @Input() public dialogHeader: string = '';
  @Input() public footer: string = '';
  @Input() public showDialog: boolean = true;
  @Output() public closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  public get fileIssueType(): typeof FileIssueType { return FileIssueType; }
}