<!-- Dynamic Dialog Table that can be used for files that failed or even warnings  -->
<p-dialog [modal]="true" [draggable]="false" [style]="{width: '60vw', height: 'auto'}" [(visible)]="showDialog"
  [closeOnEscape]="false" (onHide)="closeDialog.emit(false)">
  <p-header>
    {{dialogHeader}}
  </p-header>

  <div class="ui-g-12">
    <p-table #dt [value]="files" dataKey="dbnNumber" [rows]="50" [autoLayout]="true" [showCurrentPageReport]="true"
      [globalFilterFields]="['dbnNumber','message']" [paginator]="true" [scrollable]="true" scrollHeight="400px"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search" style="color: #fff !important;"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="table-labels text-center">
          <th>
            DBN Number
          </th>
          <th>
            Message
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="table-labels" [ngStyle]="{
          'background-color': item.fileIssueType == fileIssueType.SUCCESS ? '#87e826' :
          item.fileIssueType == fileIssueType.ERROR ? '#ff5b5b' :
          item.fileIssueType == fileIssueType.WARNING ? '#3abfff' :
          item.fileIssueType == fileIssueType.INFO ? '#fffc29a6' :
          'white' }">
          <td>{{item.dbnNumber}}</td>
          <td>{{item.message}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>No files found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="ui-g-12">
    <label>{{footer}}</label>
  </div>
</p-dialog>