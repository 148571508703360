<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h2 class="page-header">WhatsApp History</h2>
    <div class="ui-g-12">
      <whatsapp-product (onProductSelect)="refresh()"></whatsapp-product>
    </div>
  </div>
  <div class="ui-g-12">
    <p-tabView>
      <p-tabPanel header="All Template Messages">
        <div class="ui-g">
          <p-table [loading]="templateMessagesLoading" class="ui-g-12" #dt [value]="allTemplateMessages" dataKey="id"
            [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
            [autoLayout]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['txt', 'refNo', 'fullName', 'number', 'sentDate', 'undeliveredDate', 'chatStatus']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex table-header">
                <span class="p-input-icon-left">
                  <button pRipple class="p-button-info" pTooltip="Refresh" tooltipPosition="left" pButton type="button"
                    [icon]="templateMessagesLoading ? 'fas fa-sync fa-spin' : 'fas fa-sync'"
                    (click)="getAllTemplateMessages()"></button>
                </span>
                <span class="p-input-icon-left p-ml-auto">
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search" style="color: #fff;" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Template Name</th>
                <th>Reference Number</th>
                <th>Full Name</th>
                <th>Cell Phone</th>
                <th>Message Fail Reason</th>
                <th>Sent Date</th>
                <th>Undelivered Date</th>
                <th>Chat Status</th>
                <th>Chat Expiry Date</th>
                <th>Sent By User</th>
              </tr>
              <tr>
                <th>
                  <p-dropdown [options]="allTemplates" (onChange)="filterTemplates($event)" styleClass="p-column-filter"
                    optionLabel="txt" dataKey="txt" [showClear]="true" [autoDisplayFirst]="false" appendTo="body">
                  </p-dropdown>
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'refNo', 'contains')">
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'fullName', 'contains')">
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'number', 'contains')">
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'error', 'contains')">
                </th>
                <th>
                  <p-calendar (onSelect)="onFilterDate('table', $event, 'sentDate')"
                    (onClearClick)="dt.filter('', 'sentDate', 'contains')" [showButtonBar]="true" [readonlyInput]="true"
                    dateFormat="dd/mm/yy" appendTo="body">
                  </p-calendar>
                </th>
                <th>
                  <p-calendar (onSelect)="onFilterDate('table', $event, 'undeliveredDate')"
                    (onClearClick)="dt.filter('', 'undeliveredDate', 'contains')" [showButtonBar]="true"
                    [readonlyInput]="true" dateFormat="dd/mm/yy" appendTo="body">
                  </p-calendar>
                </th>
                <th>
                  <p-dropdown [options]="chatStatus" (onChange)="dt.filter($event.value, 'chatStatusId', 'equals')"
                    appendTo="body" styleClass="p-column-filter" [showClear]="true" [autoDisplayFirst]="false"
                    [filter]="true">
                  </p-dropdown>
                </th>
                <th>
                  <p-calendar (onSelect)="onFilterDate('table', $event, 'chatExpiryDate')"
                    (onClearClick)="dt.filter('', 'chatExpiryDate', 'contains')" [showButtonBar]="true"
                    [readonlyInput]="true" dateFormat="dd/mm/yy" appendTo="body">
                  </p-calendar>
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'sentByUser', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-message>
              <tr [ngClass]="{'failed': message.undeliveredDate, 'succeed': !message.undeliveredDate} ">
                <td>{{ message.templateName }}</td>
                <td>{{ message.refNo }}</td>
                <td>{{ message.fullName }}</td>
                <td>{{ message.number }}</td>
                <td>{{ message.error }}</td>
                <td>{{ message.sentDate }}</td>
                <td>{{ message.undeliveredDate }}</td>
                <td>{{ message.chatStatus }}</td>
                <td>{{ message.chatExpiryDate }}</td>
                <td>{{ message.sentByUser }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td>No template messages found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Unmatched Reference Numbers">
        <div class="ui-g">
          <p-table [loading]="unmatchedRefLoading" class="ui-g-12" [value]="unmatchedReference" #un dataKey="id"
            [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
            [autoLayout]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            editMode="row" [globalFilterFields]="['refNo', 'fullName', 'number', 'createdDate', 'chatStatus']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex table-header">
                <span class="p-input-icon-left">
                  <button pRipple class="p-button-info" pTooltip="Refresh" tooltipPosition="left" pButton type="button"
                    [icon]="unmatchedRefLoading ? 'fas fa-sync fa-spin' : 'fas fa-sync'"
                    (click)="getUnmatchedReferenceNumbers()"></button>
                </span>
                <span class="p-input-icon-left p-ml-auto">
                  <input pInputText type="text" (input)="un.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search" style="color: #fff;" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Reference Number</th>
                <th>Full Name</th>
                <th>Cellphone</th>
                <th>Created Date</th>
                <th>Chat Status</th>
                <th>Actions</th>
              </tr>
              <tr>
                <th>
                  <input pInputText class="filterRef" type="text"
                    (input)="un.filter($event.target.value, 'refNo', 'contains')">
                </th>
                <th>
                  <input pInputText class="filterRef" type="text"
                    (input)="un.filter($event.target.value, 'fullName', 'contains')">
                </th>
                <th>
                  <input pInputText class="filterRef" type="text"
                    (input)="un.filter($event.target.value, 'number', 'contains')">
                </th>
                <th>
                  <p-calendar (onSelect)="onFilterDate('tableUn', $event, 'createdDate')" appendTo="body"
                    (onClearClick)="un.filter('', 'createdDate', 'contains')" [showButtonBar]="true"
                    [readonlyInput]="true" dateFormat="dd/mm/yy">
                  </p-calendar>
                </th>
                <th>
                  <p-dropdown [options]="chatStatus" [autoDisplayFirst]="false" [showClear]="true" appendTo="body"
                    [filter]="true" (onChange)="un.filter($event.value, 'chatStatusId', 'equals')"
                    styleClass="p-column-filter">
                  </p-dropdown>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
              <tr [pEditableRow]="rowData">
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="rowData.refNo" required>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{rowData.refNo}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td>{{rowData.fullName}}</td>
                <td>{{rowData.number}}</td>
                <td>{{rowData.createdDate}}</td>
                <td class="activeNo">{{rowData.chatStatus}}</td>
                <td>
                  <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil"
                    pTooltip="Edit reference number" tooltipPosition="left" class="p-button-info"
                    (click)="editUnmatchedRow(rowData)">
                  </button>
                  <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check"
                    class="ui-button-success" style="margin-right: .5em" (click)="updateChatRef(rowData)">
                  </button>
                  <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times"
                    class="ui-button-danger" (click)="resetUnmatchRow(rowData.id);">
                  </button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td>No unmatched reference numbers found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>