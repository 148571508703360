import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ExcelService } from '../../../../services/excel.service';
import { field } from '../../../../interfaces/global.interfaces';
import { Type } from '../../../../enums';
import * as moment from 'moment';
@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnChanges {
  @Input('data') public getData: any[] = [];
  @Input('title') public title: string = "Dynamic Table";
  public rowColor = { name: "Row Color", code: "" };
  public data: any[] = [];
  public fields: field[] = [];
  public globalFilters: string[] = [];
  public typeStyle = typeStyle;
  constructor(private excel: ExcelService) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.getData.currentValue == changes.getData.previousValue) return;
    this.globalFilters = [];
    this.fields = [];
    this.data = [];
    if (this.getData.length === 0) return;
    // set global filters
    this.globalFilters = Object.keys(this.getData[0]);
    // set fields
    this.fields = this.globalFilters.map(fieldName => {
      return {
        key: fieldName,
        header: splitCamelCase(fieldName),
        type: derriveField(fieldName, this.getData.map(o => o[fieldName])),
        identical: this.getData.every(o => o[fieldName] === this.getData[0][fieldName]),
        fewResults: distinct(this.getData.map(o => o[fieldName])),
        width: maxWidthOfColumnInfo(fieldName, this.getData)
      };
    });
    const filters = this.globalFilters.map(x => x.toLocaleLowerCase());
    //only if there is dbnnumber property then we will add the actions column
    if (filters.includes('dbnnumber')) {
      this.fields.push({
        header: 'Actions',
        identical: true,
        key: 'actions',
        type: Type.BUTTON,
        width: '130px'
      });
    }
    // set data
    const data = this.getData.map(i => {
      const dbnField = this.fields.find(f => f.type === Type.DBN);
      if (dbnField) {
        i.color = typeStyle(i[dbnField.key]);
      }
      return i;
    });
    this.data = [...data];
    this.fields.forEach(f => {
      for (let i = 0; i < this.data.length; i++) {
        const v = this.getData[i];
        this.data[i][f.key] = isEmpty(v[f.key]) ? NULL : v[f.key];
      }
    });
  }
  public viewFile(row: any) {
    let fileId: string = row[Object.keys(row).find(key => key.toLowerCase() === 'dbnnumber')];
    fileId = fileId.split('/')[1];
    window.open(`file-view?query=${fileId}`);
  }
  public isDateOnly(str: string) { return (new Date(str).toLocaleTimeString().match('12:00:00 AM')); }
  public getDBNNumber(row: any): string { return row[Object.keys(row).find(key => key.toLowerCase() === 'dbnnumber')] ?? 'File'; }
  public isEmpty(val): boolean { return isEmpty(val); }
  public async generateExcel() { await this.excel.generateGenericExcel(this.getData, this.title); }
  public get type(): typeof Type { return Type; }
}
export function maxWidthOfColumnInfo(fieldKey, data): string {
  const mod = 5.4;
  const base = 65;
  const cap = 0.2;
  // gets the longest string for a given column and passes it through a formula to get a px value for the col width
  let valueArray: number[] = data.map(d => pixelWidthOfString(d[fieldKey], cap));
  valueArray.push(pixelWidthOfString(fieldKey, cap));
  // listen.. this is the best formula I could think of because primeng doesn't supported horizontal scrolling AND auto resize
  let val = Math.max(...valueArray) * mod + (base + 12 * 2);
  return `${val}px`;
}
export function pixelWidthOfString(value: string, cap: number) {
  let toReturn = 0;
  if (!value) return toReturn;
  const splitArr = value.toString().split('\n');

  const longestStringInSplitArr = splitArr.sort((a, b) => b.length - a.length)[0];
  toReturn = longestStringInSplitArr.length;

  for (let i = 0; i < longestStringInSplitArr.length; i++) {
    if (longestStringInSplitArr[i] === longestStringInSplitArr[i].toUpperCase()) {
      toReturn += cap;
    }
  }
  return toReturn;
}
export function typeStyle(str: string) {
  if (!(str?.length > 7 && str?.length < 11)) return 'invalid';
  let prefix = str.slice(0, 2).toLowerCase();
  if (['ph', 're'].includes(prefix)) return prefix;
  else return 'db';
}
export function splitCamelCase(text) {
  if (text === 'dbnNumber') return 'DBN Number';
  else if (text.length === 3) return text.toUpperCase();
  let split: string[] = text.match(/[A-Z]?[a-z]+|[A-Z]+(?![a-z])/g);
  if (split == null) return text;

  let toReturn = split.map((s, index) => index === 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s).join(' ');

  // let toReturn = "";
  // split.forEach(s => toReturn += `${s} `);
  return toReturn.trim();
  // return toReturn.slice(0, -1);
}
export function distinct(values) {
  let maximumOfOptions = 20;
  let x = values.filter((value, index, self) => self.indexOf(value) === index);
  if (x.length < values.length && x.length < maximumOfOptions) {
    // create array of label - values
    let newArr = x.map(value => {
      let label = new String(value).toString();
      if (label.match(/[0-9]{4}([/-][0-9][0-9]){2}/g)) {
        label = label.replace('00:00:00', '').replace('T', ' ').trim();
      }
      if (label === "null") { value = NULL; }
      return { label, value };
    });
    return newArr.sort((a, b) => a.label < b.label);
  }
  else return [];
}
export function derriveField(fieldName: string, values: string[]): Type {
  values = values.filter(v => !isEmpty(v));
  values = values.map(v => v.toString());
  if (values.length == 0) return Type.TEXT;
  //this is scuffy wuffy because we saying if the value is 0 then we still say it's a dbn number
  //but the scss makes the backgroud colour invalid (yellowish)
  const isDBN = values.every(v => /..[/][0-9]{5}/.test(v) || v == '0');
  if (isDBN) return Type.DBN;
  //Number
  const isTypeNumber = values.every(v => !Number.isNaN(+v)); // If its a number, will return true
  if (isTypeNumber) return Type.NUMBER;
  // date
  let isDate = fieldName.includes("Date") || fieldName.includes("ETD") || fieldName.includes("ETA") || fieldName.includes("VesselETA");

  if (!isDate) isDate = values.every(v => isValidDate(v));
  if (isDate) return Type.DATE;
  // bool
  const isBool = values.every(v => typeof (v) === 'boolean' || ["true", "false"].includes(v.toLowerCase()));
  if (isBool) return Type.BOOL;

  return Type.TEXT;
}
export function isEmpty(val): boolean { return val === NULL || val === null || val === undefined; }

export const NULL = "*NULL*";

function isValidDate(dateString) {
  return !isNaN(Date.parse(dateString));
}