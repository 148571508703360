<div class="ui-g-12 card card-w-title" *ngIf="!roundRobinAdminUser">
  <h1 style="color: red;">You do not have permission to view this page.</h1>
</div>

<div class="ui-g-12 card card-w-title" *ngIf="roundRobinAdminUser">
  <div class="ui-g-12">
    <h1>Round Robin Management</h1>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-6">
      <div class="ui-g-12">
        <label>
          <strong>Filter by:</strong>
        </label>
      </div>
      <div class="ui-g-4">
        <label>User:</label>
      </div>
      <div class="ui-g-8">
        <p-dropdown [disabled]="showLoadingSpinner || reassignSpinner" [options]="allUsers"
          [(ngModel)]="selectedRoundRobinLegalEntityId" placeholder="Select a user" [filter]="true" [showClear]="true">
        </p-dropdown>
      </div>
      <div class="ui-g-4">
        <label>Group:</label>
      </div>
      <div class="ui-g-8">
        <p-dropdown [disabled]="showLoadingSpinner || reassignSpinner" [options]="groups" [(ngModel)]="selectedGroup"
          placeholder="Select a group" [filter]="true" [showClear]="true"></p-dropdown>
      </div>
      <div class="ui-g-12">
        <button [disabled]="showLoadingSpinner || reassignSpinner" pRipple class="p-button-info width-100" pButton
          type="button" (click)="refresh()" label="Filter"></button>
      </div>
    </div>
    <div class="ui-g-6">
      <div class="ui-g-12">
        <label>
          <strong>Reassign Files:</strong>
        </label>
      </div>
      <div class="ui-g-4">
        <label>Type:</label>
      </div>
      <div class="ui-g-8">
        <p-dropdown [disabled]="showLoadingSpinner || reassignSpinner" [options]="assignToTypes"
          [(ngModel)]="selectedAssignToType" placeholder="Select a user type" [filter]="true" [showClear]="true"
          (onChange)="selectedAssignTo = null" optionLabel="label">
        </p-dropdown>
      </div>

      <!-- no assign to type selected -->
      <ng-container *ngIf="!selectedAssignToType">
        <div class="ui-g-4">
          <label>User Type:</label>
        </div>
        <div class="ui-g-8">
          <p-dropdown [disabled]="true">
          </p-dropdown>
        </div>
      </ng-container>

      <!-- Individual -->
      <ng-container *ngIf="selectedAssignToType && selectedAssignToType.value === assignToType.INDIVIDUAL">
        <div class="ui-g-4">
          <label>User:</label>
        </div>
        <div class="ui-g-8">
          <p-dropdown [disabled]="showLoadingSpinner || reassignSpinner" [options]="activeUsers"
            [(ngModel)]="selectedAssignTo" placeholder="Select a User" [filter]="true" [showClear]="true"
            optionLabel="label">
          </p-dropdown>
        </div>
      </ng-container>

      <!-- Group -->
      <ng-container *ngIf="selectedAssignToType && selectedAssignToType.value === assignToType.GROUP">
        <div class="ui-g-4">
          <label>Group:</label>
        </div>
        <div class="ui-g-8">
          <p-dropdown [disabled]="showLoadingSpinner || reassignSpinner" [options]="groups"
            [(ngModel)]="selectedAssignTo" placeholder="Select a group" [filter]="true" [showClear]="true"
            optionLabel="label">
          </p-dropdown>
        </div>
      </ng-container>

      <div class="ui-g-12">
        <button *ngIf="!reassignSpinner" pRipple class="p-button-info width-100" pButton type="button"
          (click)="reassignFiles()" label="Reassign"
          [disabled]="!selectedAssignTo || selectedFiles?.length == 0"></button>
        <button *ngIf="reassignSpinner" [disabled]="true" pRipple class="p-button-info width-100 reassign-spinner"
          pButton type="button">
          <i class="pi pi-spin pi-spinner"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="ui-g-12">
    <p-table class="ui-g-12" #dt [value]="files" [scrollable]="true" [paginator]="true" [rows]="50"
      [loading]="showLoadingSpinner" [(selection)]="selectedFiles" dataKey="roundRobinId" [autoLayout]="true"
      [globalFilterFields]="['dbnNumber','assignedGroup', 'assignedTo']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left selected-files">
            <label>Selected {{selectedFiles ? selectedFiles.length : 0}} of
              {{dt ? dt.totalRecords : 0}} files</label>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th width="50px">
            <p-tableHeaderCheckbox [disabled]="showLoadingSpinner || reassignSpinner"></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="dbnNumber">
            DBNNumber
            <p-sortIcon field="dbnNumber"> </p-sortIcon>
          </th>
          <th pSortableColumn="assignedGroup">
            Current Assigned Group
            <p-sortIcon field="assignedGroup"> </p-sortIcon>
          </th>
          <th pSortableColumn="assignedTo">
            Assigned To
            <p-sortIcon field="assignedTo"> </p-sortIcon>
          </th>
          <th>
            Actions
          </th>
        </tr>
        <tr class="table-labels">
          <th>
            <!-- Checkbox  -->
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'dbnNumber', 'contains')">
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'assignedGroup', 'contains')">
          </th>
          <th>
            <input pInputText type="text" (input)="dt.filter($event.target.value, 'assignedTo', 'contains')">
          </th>
          <th>
            <!-- Actions -->
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td width="50px">
            <p-tableCheckbox [value]="rowData" [disabled]="showLoadingSpinner || reassignSpinner"></p-tableCheckbox>
          </td>
          <td>
            {{ rowData.dbnNumber }}
          </td>
          <td>
            {{ rowData.assignedGroup }}
          </td>
          <td>
            {{ rowData.assignedTo }}
          </td>
          <td>
            <button class="margin-left-5px" pTooltip="View {{rowData.dbnNumber}}" tooltipPosition="left" pButton
              type="button" icon="far fa-folder" (click)="viewFile(rowData.fileId)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>No files found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<file-issues-pop-up [showDialog]="showDialog" [files]="filesWithIssues" [dialogHeader]="dialogHeader"
  [footer]="dialogFooter" (closeDialog)="showDialog = $event">
</file-issues-pop-up>