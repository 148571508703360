import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { LogBase } from '../../services/logger.service';
import { ApiService } from '../../services/api.service';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '../../services/api/auth/authentication.service';

@Component({
  selector: 'app-file-open',
  templateUrl: './file-open.component.html',
  styleUrls: ['./file-open.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})

export class OpenFileComponent implements OnInit {
  @Input() activeIndex: number;
  @Input() canCreateFile: boolean = false;
  @Input() indexEventFunction: number;

  CreateFile: boolean = false;

  index: number = 0;
  items: MenuItem[];
  pageState: any;
  showSpinner = false;

  fileId: number;

  openDisplayType: string;

  shipmentDetails: any;
  fileItemsPayload: any;
  consigneeId: number;
  notifyId: number;

  validReferenceNo: boolean = false;
  displayCancel: boolean = false;

  constructor(
    private router: Router,
    private log: LogBase,
    private api: ApiService,
    private messageService: MessageService,
    private titleService: Title,
    private authService: AuthenticationService
  ) {
    this.titleService.setTitle("AVECS File Open");
  }

  async ngOnInit() {
    this.setUpWizardSteps();
    this.checkIndex();
    this.openDisplayType = 'Open New File';
  }

  setUpWizardSteps() {
    this.items = [{
      label: 'Shipping Details',
      command: () => {
        this.index = 0;
        localStorage.setItem('pageStateRules', JSON.stringify({ activeIndex: this.index }));
      }
    },
    {
      label: 'Consignee Details',
      command: () => {
        this.index = 1;
        localStorage.setItem('pageStateRules', JSON.stringify({ activeIndex: this.index }));
      }
    },
    {
      label: 'Cargo',
      command: () => {
        this.index = 2;
        localStorage.setItem('pageStateRules', JSON.stringify({ activeIndex: this.index }));
      }
    }
    ];
  }

  async checkIndex() {
    try {
      this.pageState = await localStorage.getItem('pageStateRules');
      if (this.pageState) {
        let indexState = JSON.parse(this.pageState);
        this.index = indexState.activeIndex
      }
      else {
        localStorage.setItem('pageStateRules', JSON.stringify({ activeIndex: 0 }));
        let indexState = { activeIndex: 0 };
        this.index = indexState.activeIndex

      }
    } catch (err) {
      this.log.error(err);
    }
  }

  SetCreateFileStatus(event: boolean) {
    this.CreateFile = event;
  }

  updateIndexEvent = (event: any) => {
    this.index = event.params;
  }

  async clearStorageAndGoToTaskList() {
    try {
      this.clearStorage()
      this.router.navigate(["/search-result"], {
        queryParams: { query: this.fileId },
      });
    } catch (err) {
      this.log.error(err);
    }
  }

  clearStorage() {
    localStorage.removeItem('pageStateRules');
    localStorage.removeItem('fileItemsPayload');
    localStorage.removeItem('fileType');
    localStorage.removeItem('savedFileItems');
    localStorage.removeItem('shipmentDetails');
    localStorage.removeItem("singleFileCreateClientId");
    localStorage.removeItem("singleFileCreateContactId");
  }

  async createFile() {
    try {
      this.showSpinner = true;
      this.shipmentDetails = await JSON.parse(localStorage.getItem('shipmentDetails'));
      this.shipmentDetails.RouteId = this.shipmentDetails.Route.routeId;
      this.shipmentDetails.PortOfArrivalId = this.shipmentDetails.PortOfArrival.Id;
      this.shipmentDetails.PortOfDepartureId = this.shipmentDetails.PortOfDeparture.Id;
      this.consigneeId = Number(localStorage.getItem("singleFileCreateClientId"));
      this.notifyId = Number(localStorage.getItem("singleFileCreateContactId"));
      this.fileItemsPayload = await JSON.parse(localStorage.getItem('fileItemsPayload'));

      var filepayload = [];
      this.fileItemsPayload.forEach(element => {
        filepayload.push(element.formData)
      });

      let valid = await this.checkValidFile()
      switch (valid) {
        case 5:
          this.messageService.add({ severity: 'error', summary: 'The Reference Number Already Exists', detail: 'The Reference Number Already Exists in the System. Please check it.' });
          this.showSpinner = false;
          break;
        case 1:
          this.messageService.add({ severity: 'error', summary: 'Missing File Information', detail: 'Missing Shipping Detail. Please fill out and try again' });
          this.showSpinner = false;
          break;
        case 2:
          this.messageService.add({ severity: 'error', summary: 'Missing File Information', detail: 'Missing Consignee Details. Please fill out and try again' });
          this.showSpinner = false;
          break;
        case 3:
          this.messageService.add({ severity: 'error', summary: 'Missing File Information', detail: 'Missing File Items. Please fill out and try again' });
          this.showSpinner = false;
          break;
        case 4:

          let fileItems = this.sortFileItemsPayload(filepayload);
          let createdBy = this.authService.getLegalEntityId;
          let fileType = this.shipmentDetails.filetype;

          this.shipmentDetails.RouteId = this.shipmentDetails.Route.routeId;
          this.shipmentDetails.PortOfArrivalId = this.shipmentDetails.PortOfArrival.Id;
          this.shipmentDetails.PortOfDepartureId = this.shipmentDetails.PortOfDeparture.Id;

          let payload = {
            shipmentDetails: this.shipmentDetails,
            consigneeDetails: {
              consigneeId: this.consigneeId
            },
            otherContactDetails: {
              notifyId: this.notifyId
            },
            fileItems: fileItems,
            createdBy: createdBy,
            fileType: fileType
          }
          
          this.fileId = await this.api.createFile(payload);
          if (this.fileId > 0) {
            this.messageService.add({ severity: 'success', summary: '', detail: 'File Created and registered on the system' });
            setTimeout(() => { this.clearStorageAndGoToTaskList(); }, 2000);
          } else {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Failed to create file. Please try again  or contact an admin.' });
          }
          this.showSpinner = false;
          break;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async checkValidFile() {
    if (!this.shipmentDetails) {
      return 1;
    }

    if (!this.consigneeId) {
      return 2;
    }

    if (!this.fileItemsPayload) {
      return 3;
    }
    return 4;
  }

  sortFileItemsPayload = (fileItems: any) => {
    var newFileItems = []
    for (var x = 0; x < fileItems.length; x++) {
      let item = fileItems[x];
      switch (item.type) {
        case 1:
          var newVehicleItem = {
            Id: item.Id,
            chassisNo: item.chassisNo,
            colourId: item.colourId,
            containerId: item.containerId,
            containerSealNo: item.containerSealNo,
            engineNo: item.engineNo,
            fuelId: item.fuelId ? item.fuelId : -1,
            grade: item.grade,
            height: item.height * 100,
            isoContainerCodeId: item.isoContainerCodeId ? item.isoContainerCodeId : -1,
            length: item.length * 100,
            mileage: item.mileage == "" ? 0 : item.mileage,
            modelCode: item.modelCode,
            month: item.month,
            referenceNo: item.referenceNo,
            transmissionId: item.transmissionId ? item.transmissionId : -1,
            lineItemType: item.type,
            vehicleMakeId: item.vehicleMakeId,
            vehicleModelId: item.vehicleModelId,
            weight: item.weight,
            width: item.width * 100,
            year: item.year,
            quantity: 1,
            description: ""
          }
          newFileItems.push(newVehicleItem)
          break;
        case 2:
          var newEngineItem = {
            Id: item.Id,
            containerId: item.containerId,
            containerSealNo: item.containerSealNo,
            description: item.descriptionString,
            engineNo: item.engineNo,
            height: item.height * 100,
            isoContainerCodeId: item.isoContainerCode ? item.isoContainerCode.Id : -1,
            length: item.length * 100,
            lineItemType: item.type,
            weight: item.weight,
            width: item.width * 100,
            chassisNo: "",
            colourId: -1,
            fuelId: -1,
            grade: "",
            mileage: 0,
            modelCode: "",
            month: -1,
            referenceNo: "",
            transmissionId: -1,
            vehicleMakeId: -1,
            vehicleModelId: -1,
            year: -1,
            quantity: 1
          }
          newFileItems.push(newEngineItem)
          break;
        case 3: // part
          var newPartItem = {
            Id: item.Id,
            containerId: item.containerId,
            containerSealNo: item.containerSealNo,
            description: item.descriptionString,
            height: item.height * 100,
            isoContainerCodeId: item.isoContainerCode ? item.isoContainerCode.Id : -1,
            length: item.length * 100,
            quantity: item.quantity,
            lineItemType: item.type,
            weight: item.weight,
            width: item.width * 100,
            chassisNo: "",
            colourId: -1,
            engineNo: "",
            fuelId: -1,
            grade: "",
            mileage: 0,
            modelCode: "",
            month: -1,
            referenceNo: "",
            transmissionId: -1,
            vehicleMakeId: -1,
            vehicleModelId: -1,
            year: -1
          }
          newFileItems.push(newPartItem)
          break;
        case 4:
          var newOtherItem = {
            Id: item.Id,
            containerId: item.containerId,
            containerSealNo: item.containerSealNo,
            description: item.descriptionString,
            height: item.height * 100,
            isoContainerCodeId: item.isoContainerCode ? item.isoContainerCode.Id : -1,
            length: item.length * 100,
            quantity: item.quantity,
            lineItemType: item.type,
            weight: item.weight,
            width: item.width * 100,
            chassisNo: "",
            colourId: -1,
            engineNo: "",
            fuelId: -1,
            grade: "",
            mileage: 0,
            modelCode: "",
            month: -1,
            referenceNo: "",
            transmissionId: -1,
            vehicleMakeId: -1,
            vehicleModelId: -1,
            year: -1
          }
          newFileItems.push(newOtherItem)
          break;
      }
    }
    return newFileItems
  }
}
