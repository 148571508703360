<p-table #dt [value]="data" [columns]="fields" [scrollable]="true" dataKey="id" styleClass="p-datatable-gridlines"
  [rowHover]="true" [showCurrentPageReport]="true" [paginator]="paginator" [rowsPerPageOptions]="[10,25,50]" [rows]="50"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0" scrollHeight="400px"
  [globalFilterFields]="globalFilters" [reorderableColumns]="true" [resizableColumns]="true">
  <ng-template pTemplate="caption">
    <div class="ui-table-globalfilter-container">
      <div class="heading">
        <button *ngIf="!exportingExcel && export" pRipple class="p-button-info" label="Export Excel"
          tooltipPosition="left" pButton type="button" icon="fas fa-file-download" (click)="generateExcel()"></button>
        <button *ngIf="exportingExcel" pRipple class=" p-button-info" pButton type="button" pTooltip="Refresh Notes"
          icon="fas fa-sync fa-spin" (click)="getNotesForFile()"></button>
        <h2>{{title}}</h2>
      </div>
      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
        placeholder="Global Search" />
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let field of fields" [ngSwitch]="field.type">
        <col *ngSwitchDefault [ngStyle]="{'width': field.width}">
        <col *ngSwitchCase="3" [ngStyle]="{'width': field.width, 'min-width': '180px'}">
      </ng-container>
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let field of fields">
        <th [pSortableColumn]="field.key" style="font-weight: bold;" *ngIf="field.type != 6" pReorderableColumn>
          {{field.header}}<p-sortIcon [field]="field.key">
          </p-sortIcon>
        </th>
        <th style="font-weight: bold;" *ngIf="field.type == 6">
          {{field.header}}
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-record let-columns="fields">
    <tr>
      <td *ngFor="let field of fields">
        <span *ngIf="isEmpty(record[field.key]) && field.type != 6" class="badge status-null">NULL</span>
        <ng-container *ngIf="!isEmpty(record[field.key])" [ngSwitch]="field.type">
          <!-- TEXT -->
          <bracket-text *ngSwitchCase="1" [text]="record[field.key]"></bracket-text>
          <!-- DATETIME -->
          <div *ngSwitchCase="3">
            <span *ngIf="record[field.key]" class="date">{{record[field.key] | date:'mediumDate'}}</span>
            <span *ngIf="!isDateOnly(record[field.key])">{{record[field.key] | date:'shortTime'}}</span>
          </div>
          <!-- NUMBER -->
          <span *ngSwitchCase="4">{{record[field.key]}}</span>
          <!-- DBN -->
          <span *ngSwitchCase="5" class="badge status-{{typeStyle(record[field.key])}}">{{record[field.key]}}</span>
        </ng-container>

        <ng-container *ngIf="field.type == 6">
          <!-- View File  -->
          <button *ngIf="record?.dbnNumber" class="margin-left-5px" pTooltip="View {{record?.dbnNumber}}"
            tooltipPosition="left" pButton type="button" icon="far fa-folder"
            (click)="viewFile(record?.dbnNumber)"></button>
          <!-- View Person -->
          <button *ngIf="record?.consigneeLegalEntityId && record?.legalEntityTypeId === 1" class="margin-left-5px"
            pTooltip="View Person" tooltipPosition="left" pButton type="button" icon="fas fa-user-tie"
            (click)="viewConsignee(record?.consigneeLegalEntityId, record?.legalEntityTypeId)"></button>
          <!-- View Company -->
          <button *ngIf="record?.consigneeLegalEntityId && record?.legalEntityTypeId === 2" class="margin-left-5px"
            pTooltip="View Company" tooltipPosition="left" pButton type="button" icon="far fa-building"
            (click)="viewConsignee(record?.consigneeLegalEntityId, record?.legalEntityTypeId)"></button>
          <!-- Check sms, route, email (File Owner Tasks) -->
          <button *ngIf="record?.workFlowTaskId === 3 && !record.route" pRipple class="p-button-info margin-left-5px"
            pTooltip="Complete tasks" tooltipPosition="left" pButton type="button" icon="far fa-edit"
            (click)="viewFileOwnerTasks(record)"></button>
          <!-- View Task -->
          <button *ngIf="record?.route" pRipple class="p-button-info margin-left-5px" pTooltip="View Task"
            tooltipPosition="left" pButton type="button" icon="far fa-edit" (click)="viewTask(record?.route)"></button>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8" style="text-align:left">No results found.</td>
    </tr>
  </ng-template>
</p-table>