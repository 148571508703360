import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { LogBase } from '../../../services/logger.service';
import { ConfirmationService } from 'primeng';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../../services/config.service'
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import * as _ from "lodash";
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: 'app-contact-notes',
  templateUrl: './contact.notes.component.html',
  styleUrls: ['./contact.notes.component.scss'],
  providers: [ConfirmationService]
})

export class ContactNotesComponent implements OnInit {

  @Input() LegalEntityId: string;
  NotesFiles: boolean;
  noteTypes: any;
  selectedNoteType: any;
  notes: any;
  displayNote: boolean = false;
  noteTypeSelected: boolean = true;
  noteData: any;
  loadIcon: string = 'fas fa-sync';

  msgs: any[] = [];

  constructor(
    private api: ApiService,
    private log: LogBase,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private titleService: Title,
    private authService: AuthenticationService

  ) {
    this.titleService.setTitle("AVECS Notes");
  }

  async ngOnInit() {
    try {
      await this.getNoteTypes();
      await this.getNotesForLegalEntity();
    } catch (error) {
      this.showError('There was an error', error);
      this.log.error(error);
    }
  }

  setupNote() {

    let noteType = _.find(this.noteTypes, (o: any) => { return o.value == this.selectedNoteType });
    this.noteData = noteType.description + ' ';
    this.noteTypeSelected = false;
  }

  addNote() {
    this.displayNote = true;
  }

  async saveNote() {
    try {
      this.noteData = this.noteData.split('&').join('and');

      await this.api.saveNoteForLegalEntity(this.noteData, this.LegalEntityId, this.selectedNoteType);
      await this.getNotesForLegalEntity();
      this.noteData = undefined;
      this.displayNote = false;
    } catch (error) {
      this.showError('Error Saving Note', error);
      this.log.error(error);
    }
  }

  ResetDialogValues() {

    this.selectedNoteType = undefined;
    this.noteData = '';
    this.noteTypeSelected = true;
  }

  async refreshList() {
    await this.getNotesForLegalEntity();
  }

  disableSaveNote() {
    if (!this.noteData)
      return true
    return false
  }

  async getNoteTypes() {
    try {
      this.noteTypes = await this.api.getNoteTypes()
    } catch (error) {
      this.showError('Error Getting Notes Types', error);
      this.log.error(error);
    }
  }


  async getNotesForLegalEntity() {
    this.loadIcon = 'fas fa-sync fa-spin'
    try {
      this.notes = await this.api.getNotesByLegalEntityId(this.LegalEntityId);
    } catch (error) {
      this.showError(
        "Error Getting Notes For Contact (getNotesForContact())",
        error
      );
      this.log.error(error);
    }
    this.loadIcon = 'fas fa-sync'
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + '. Do you want to email this error to Support?',
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = 'Component Name: File Summary Notes<br/>';
        emailBody += 'Component: ' + componentName + '<br/>';
        emailBody += 'User: ' + userName + '<br/>';
        emailBody += 'Date: ' + date + '<br/>';
        emailBody += 'Error: ' + friendlyMessage + '<br/>';
        emailBody += 'ErrorMessage: ' + errorMessage + '<br/>';
        var subject: string = 'NovaDT Error Message - Component: File Summary Notes';
        var res = this.api.sendFreeTextEmail(this.config.supportEmail, 'system@avecs.co.za', emailBody, subject, this.LegalEntityId, -1);
      },
      reject: () => {
      }
    });
  }
}