<div class="ui-g-12 center" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && userTask" class="ui-g card card-w-title">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 file-number-header">
      <h3>WhatsApp Talk To Agent General </h3>
    </div>
  </div>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'180px'}">
      <p-header>
        WhatsApp User Information
      </p-header>

      <div class="ui-g-4">
        <div class="information">
          <strong>WhatsApp User Name</strong>
          <label>
            {{ userTask.whatsAppUserName || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>WhatsApp Cell Phone</strong>
          <label>
            {{ userTask.whatsAppUserCellPhone || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>WhatsApp Chat Ref</strong>
          <label>
            {{ userTask.chatRefNo || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>WhatsApp Product Number</strong>
          <label>
            {{ userTask.productNumber }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="ui-g-12 user-task-text">
      <label>The WhatsApp user has requested to talk to an agent. The WhatsApp user could not be verified
        because either we couldn't match their cell phone to any legal entity or we did find a match but they have no
        files assigned to their legal entity.
        Please go log into WhatsApp and find their chat.
      </label>
    </div>

    <div class="actions ui-g-12">
      <button pRipple pButton label="Mark user task as completed and close WhatsApp Chat" [disabled]="postSpinner"
        (click)="showDialog=true"></button>
      <button pRipple pButton label="Go to WhatsApp" (click)="goToWhatsapp()" [disabled]="postSpinner"></button>
    </div>
  </div>
</div>

<p-dialog header="Confirmation" [dismissableMask]="true" [positionTop]="60" *ngIf="showDialog"
  [contentStyle]="{width: '650px', height: '90px'}" [responsive]="true" [draggable]="false"
  (onHide)="showDialog = false;" [(visible)]="showDialog" [modal]="true">
  <label>Are you sure you want to mark the user task as completed and close the WhatsApp chat?</label>
  <div class="actions ui-g-12">
    <button *ngIf="!postSpinner" pRipple pButton label="Yes" (click)="completeUserTask()"></button>
    <button *ngIf="postSpinner" pRipple class="p-button-info submit" pButton type="button" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
    <button pRipple class="p-button-info submit" pButton type="button" [disabled]="postSpinner" label="No">
    </button>
  </div>
</p-dialog>