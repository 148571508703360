import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../services/api/auth/authentication.service";
import { LogBase } from "../../services/logger.service";
import { ApiService } from "../../services/api.service";
import { ConfigService } from "../../services/config.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { Title } from "@angular/platform-browser";
import * as _ from "lodash";
import { FilesWithIssues, TableColumns } from "../../interfaces/global.interfaces";
import { BulkUpdaterApiService } from "../../services/api/bulk-updater-api.service";
import { FileIssueType } from "../../enums";

@Component({
  selector: "app-bulk-updater",
  templateUrl: "./bulk-updater.component.html",
  styleUrls: ["./bulk-updater.component.scss"],
  providers: [ConfirmationService],
})
export class BulkUpdaterComponent implements OnInit {

  emailAddresses: any = [] = [
    { name: "noreply@avecs.co.za", code: "noreply@avecs.co.za" },
    { name: "info@avecs.co.za", code: "info@avecs.co.za" },
    { name: "accounts@avecs.co.za", code: "accounts@avecs.co.za" },
    { name: "dispatch@avecs.co.za", code: "dispatch@avecs.co.za" },
  ];
  cols: TableColumns[] = [
    { field: "dBNNumber", header: "DBNNumber", width: "150px" },
    { field: "fileOwner", header: "File Owner", width: "150px" },
    { field: "foreignReference", header: "ForeignRef #", width: "130px" },
    { field: "consignee", header: "Consignee", width: "250px" },
    { field: "email", header: "Email", width: "250px" },
    { field: "cellphone", header: "Cellphone", width: "150px" },
    { field: "iDorPassportReceived", header: "ID/Passport Received", width: "200px" },
    { field: "cIF", header: "CIF", width: "75px" },
    { field: "description", header: "Description", width: "800px" },
    { field: "borderPost", header: "Border Post", width: "200px" },
    { field: "borderAgent", header: "Border Agent", width: "200px" },
    { field: "vesselName", header: "Vessel Name", width: "250px" },
    { field: "voyageNumber", header: "Voyage #", width: "110px" },
    { field: "bOLNumber", header: "BOL #", width: "100px" },
    { field: "eTA", header: "ETA", width: "120px" },
    { field: "sOB", header: "SOB", width: "120px" },
    { field: "mBL", header: "MBL", width: "150px" },
    { field: "portOfLoad", header: "Port Of Load", width: "175px" },
    { field: "carrier", header: "Carrier", width: "150px" },
    { field: "manifestNumber", header: "Manifest Number", width: "160px" },
    { field: "manifestDate", header: "Manifest Date", width: "175px" },
    { field: "collectionFromPortDate", header: "Port Collection Date", width: "185px" },
    { field: "dispatchDate", header: "Dispatch Date", width: "175px" },
    { field: "courier", header: "Courier", width: "250px" },
    { field: "dateCourierReceived", header: "Date Courier Received", width: "195px" },
    { field: "trackingNumber", header: "Tracking #", width: "150px" },
    { field: "supplier", header: "Supplier", width: "250px" },
    { field: "country", header: "Country", width: "150px" },
    { field: "created", header: "Created", width: "100px" },
    { field: "warehouse", header: "Warehouse", width: "150px" },
    { field: "currency", header: "Currency", width: "110px" },
    { field: "invoiceAmount", header: "Invoice Amount", width: "150px" },
    { field: "invoiceDate", header: "Invoice Date", width: "175px" },
    { field: "lastPaymentDate", header: "Last Payment Date", width: "175px" },
    { field: "totalPaymentsReceived", header: "Total Payments Received", width: "220px" },
    { field: "balanceOutstanding", header: "Outstanding Balance", width: "200px" },
    { field: "acquittalBOENum", header: "Acquittal BOE #", width: "200px" }
  ];
  showLoadingSpinner: boolean = false;

  vessels: any = [];
  voyages: any = [];
  transshipVoyages: any = [];

  assignedMBLNumbers: any = [];
  mblNumbers: any = [];

  selectedVessel: any;
  selectedVoyage: any;
  selectedMBLNumbers: any = [];

  selectedTransShipVessel: any;
  selectedTransShipVoyage: any;

  files: any;

  fileType: number = 0;
  hideOptionsButton: boolean = true;
  public showActionSpinner: boolean = false;
  public showFilesWithIssuesDialog: boolean = false;
  public filesWithIssues: FilesWithIssues[] = [];
  public dialogHeader: string = '';
  public dialogFooter: string = '';
  public selectedFiles: any = [];

  public optionsItems: any = [] = [
    {
      label: "Update ETA",
      icon: "pi pi-pencil",
      command: () => {
        this.openUpdateETAModel();
      },
      disabled: true
    },
    {
      label: "Request ANF From Shipping Line",
      icon: "pi pi-save",
      command: () => this.displayANFRequest = true,
      disabled: true
    },
    {
      label: "Update SOB Date",
      icon: "pi pi-save",
      command: () => this.displaySOBDateModal = true,
      disabled: true
    },
    {
      label: "Update MBL Number",
      icon: "pi pi-save",
      command: async () => {
        await this.getMBLNumbers();
        this.displayMBLNumberModal = true;
      },
      disabled: true
    },
    {
      label: "Update Courier Received Info",
      icon: "pi pi-save",
      command: () => this.displayCourierReceivedModal = true,
      disabled: true
    },
    {
      label: "Transshipment",
      icon: "pi pi-save",
      command: () => this.displayTransShipmentModal = true,
      disabled: true
    },
    {
      label: "Assign Border Agent",
      icon: "pi pi-save",
      command: () => this.displayAssignBorderAgent = true,
      disabled: true
    },
    {
      label: "Bulk Add Notes to Files",
      icon: "pi pi-save",
      command: () => this.displayNote = true,
      disabled: true
    }
  ];
  exportItems: any = [];
  courierCompanies: any = [];

  displayUpdateETAModal: boolean = false;

  displaySOBDateModal: boolean = false;
  displayCourierReceivedModal: boolean = false;
  displayMBLNumberModal: boolean = false;
  displayANFRequest: boolean = false;
  displayTransShipmentModal: boolean = false;
  displayAssignBorderAgent: boolean = false;
  SOBDate: any = undefined;
  courierReceivedDate: any = undefined;
  MBLNumber: any;
  trackingNumber: any = undefined;
  courierCompany: any = undefined;
  newFilesETA: any = undefined;
  newFilesCourierDate: any = undefined;
  borderAgents: any = [];
  selectedBorderAgent: any;
  allVoyages: boolean = false;
  sendTrackingNumberEmails: boolean = false;
  originalDocsReceived: boolean = false;
  sendHBLEmails: boolean = false;
  noteTypes: any;
  selectedNoteType: any;
  noteData: any;
  displayNote: boolean = false;
  noteTypeSelected: boolean = true;
  constructor(
    private authenticationService: AuthenticationService,
    public log: LogBase,
    public api: ApiService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private bulkUpdaterApiService: BulkUpdaterApiService,
    private titleService: Title
  ) { this.titleService.setTitle("AVECS Bulk-Updater"); }

  async ngOnInit() {
    try {
      this.courierCompanies = await this.api.getCourierCompanies();
      await this.getBoarderAgents();
      await this.getVessels();
      await this.getNoteTypes();
    } catch (error) {
      this.log.error(error);
    }
  }

  updateOptionItems() {
    if (this.selectedFiles.length > 0) {
      this.optionsItems[0].disabled = false;
      this.optionsItems[2].disabled = false;
      this.optionsItems[3].disabled = false;
      this.optionsItems[4].disabled = false;
      this.optionsItems[5].disabled = false;
      this.optionsItems[6].disabled = false;
      this.optionsItems[7].disabled = false;
    } else {
      this.optionsItems[0].disabled = true;
      this.optionsItems[2].disabled = true;
      this.optionsItems[3].disabled = true;
      this.optionsItems[4].disabled = true;
      this.optionsItems[5].disabled = true;
      this.optionsItems[6].disabled = true;
      this.optionsItems[7].disabled = true;
    }

    if (this.selectedVoyage) {
      this.optionsItems[1].disabled = false;
    } else {
      this.optionsItems[1].disabled = true;
    }
  }

  async getBoarderAgents() {
    try {
      const retval = await this.api.getBorderAgents();
      if (retval) {
        this.borderAgents = retval;
      } else {
        this.borderAgents = [];
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  cancelSOBDateModal() {
    this.displaySOBDateModal = false;
    this.SOBDate = undefined;
  }

  cancelcourierReceivedModal() {
    this.displayCourierReceivedModal = false;
    this.courierReceivedDate = undefined;
    this.courierCompany = undefined;
    this.trackingNumber = undefined;
    this.sendTrackingNumberEmails = false;
    this.originalDocsReceived = false;
  }

  cancelMBLNumberModal() {
    this.displayMBLNumberModal = false;
    this.MBLNumber = undefined;
  }

  async getNoteTypes() {
    try {
      this.noteTypes = await this.api.getNoteTypes();
    } catch (error) {
      this.showError("Error Getting Notes Types", error);
      this.log.error(error);
    }
  }

  ResetDialogValues() {
    this.selectedNoteType = undefined;
    this.noteData = "";
    this.noteTypeSelected = true;
  }

  setupNote() {
    let noteType = _.find(this.noteTypes, (o: any) => {
      return o.value == this.selectedNoteType;
    });
    this.noteData = noteType.description + " ";
    this.noteTypeSelected = false;
  }

  async getVessels() {
    try {
      this.vessels = await this.api.getCommunicatorVessels();
    } catch (error) {
      this.showError("Error Getting Vessels", error);
      this.log.error(error);
    }
  }

  async getVoyage() {
    try {
      this.selectedVoyage = null;
      this.voyages = await this.api.getCommunicatorVoyages(true, this.selectedVessel.id);
      this.selectedMBLNumbers = [];
      this.files = [];
    } catch (error) {
      this.showError("Error Getting Voyages", error);
      this.log.error(error);
    }
  }

  async getTransShipVoyages() {
    try {
      this.selectedTransShipVoyage = null;
      const transshipVoyages = await this.api.getCommunicatorVoyages(true, this.selectedTransShipVessel.id);
      const current = new Date();
      const dateCompare = new Date(new Date().setDate(current.getDate() - 28));
      //remove voyages older than 28 days
      this.transshipVoyages = transshipVoyages.filter(t => new Date(t.eta) > dateCompare);
    } catch (error) {
      this.showError("Error Getting Transship Voyages", error);
      this.log.error(error);
    }
  }

  async getMBLNumbers() {
    try {
      this.mblNumbers = await this.api.getMBLNumbers(this.selectedVoyage.id);
    } catch (error) {
      this.showError("Error Getting MBL Numbers", error);
      this.log.error(error);
    }
  }

  async getAssignedMBLNumbers() {
    try {
      this.assignedMBLNumbers = await this.api.getBulkUpdaterMBLNumbers(true, this.selectedVoyage.id);
    } catch (error) {
      this.showError("Error Getting MBL Numbers", error);
      this.log.error(error);
    }
  }

  async filterByVessel() {
    this.showLoadingSpinner = true;
    try {
      this.selectedFiles = [];
      this.files = [];
      const payload = {
        VesselId: this.selectedVessel.id,
        VoyageId: this.selectedVoyage.id,
        HistoricalVoyages: true,
        MBLNumbers: this.selectedMBLNumbers ? this.selectedMBLNumbers : [],
      };
      const resp = await this.api.getBulkUpdaterListItemsByVessel(payload);
      if (!resp) this.toastMessage('error', 'Failed to get files by vessel. Please contact support', '');
      else if (resp.errorCode !== 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.files = resp.result;
        if (resp.result?.length == 0) this.toastMessage('warn', 'No files found', '');
      }
    } catch (error) {
      this.showError("Error Filtering By Vessel", error);
      this.log.error(error);
    }
    this.showLoadingSpinner = false;
  }

  addMBLNumber() {
    this.router.navigate(["/masterbills"]);
  }

  openUpdateETAModel() {
    this.displayUpdateETAModal = true;
  }

  public async assignBorderAgent() {
    this.showActionSpinner = true;
    try {
      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        BorderAgentId: this.selectedBorderAgent,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateBorderAgent(payload);
      if (!resp) this.messageService.add({ severity: "error", summary: "Border Agent not Updated", detail: "There was an error updating the Border Agent" });
      else if (resp.errorCode !== 0) this.messageService.add({ severity: "error", summary: "Failed to update Border Agent", detail: resp.errorMessage });
      else {
        this.selectedBorderAgent = null;
        this.displayAssignBorderAgent = false;
        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for Border Agent Update';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
      }
    } catch (error) {
      this.showError("Error Updating Files ETA", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public async updateFilesETA() {
    this.showActionSpinner = true;
    try {
      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        NewETA: this.newFilesETA,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateFilesETA(payload);
      if (!resp) this.messageService.add({ severity: "error", summary: "ETA Update Failed", detail: "Please contact support." });
      else if (resp.errorCode !== 0) this.messageService.add({ severity: "error", summary: "ETA Update Failed", detail: resp.errorMessage });
      else {
        this.messageService.add({ severity: "success", summary: "ETA Updated", detail: resp.errorMessage });
        this.newFilesETA = null;
        this.displayUpdateETAModal = false;
        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for ETA Update';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
      }
    } catch (error) {
      this.showError("Error Updating Files ETA", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public async updateFilesSOB() {
    this.showActionSpinner = true;
    try {
      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        NewDate: this.SOBDate,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateFilesSOB(payload);
      if (!resp) this.toastMessage("error", "SOB Update Failed", "There was an error updating the SOB. Please contact support");
      else if (resp.errorCode !== 0) this.toastMessage("error", "SOB Update Failed", resp.errorMessage);
      else {
        this.displaySOBDateModal = false;
        this.SOBDate = null;
        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for SOB Update';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
      }
    } catch (error) {
      this.showError("Error Updating Files SOB", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public async updateCourierReceived() {
    this.showActionSpinner = true;
    try {
      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        NewDate: this.courierReceivedDate,
        TrackingNumber: this.trackingNumber,
        CourierCompany: this.courierCompany.value,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateFilesCourierReceivedInfo(payload, this.sendTrackingNumberEmails, this.originalDocsReceived);
      if (!resp) this.messageService.add({ severity: "error", summary: "Courier Received Info Update Failed", detail: "There was an error updating the Courier Received Info." });
      else if (resp.errorCode !== 0) this.messageService.add({ severity: "error", summary: "Courier Received Info Update Failed", detail: resp.errorMessage });
      else {
        this.displayCourierReceivedModal = false;
        this.newFilesCourierDate = null;
        this.courierReceivedDate = undefined;
        this.courierCompany = undefined;
        this.trackingNumber = undefined;
        this.sendTrackingNumberEmails = false;
        this.originalDocsReceived = false;

        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for Courier Details Update';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
      }
    } catch (error) {
      this.showError("Error Updating Files Courier Received Date", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public async updateMBLNumber() {
    this.showActionSpinner = true;
    try {
      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        Value: this.MBLNumber.MBL,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateMBL(payload, this.sendHBLEmails);
      if (!resp) this.messageService.add({ severity: "error", summary: "MBL Update Failed", detail: "There was an error updating the MBL." });
      else if (resp.errorCode !== 0) this.messageService.add({ severity: "error", summary: "MBL Update Failed", detail: resp.errorMessage });
      else {
        this.newFilesCourierDate = null;
        this.displayMBLNumberModal = false;
        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for MBL Update';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
      }
    } catch (error) {
      this.showError("Error Updating MBL", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public async bulkSaveNote() {
    this.showActionSpinner = true;
    try {
      this.noteData = this.noteData.split("&").join("and"); // Cleanup  HTML
      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        Note: this.noteData,
        NoteTypeId: this.selectedNoteType,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateAddFileNotes(payload);
      if (!resp) this.messageService.add({ severity: "error", summary: "Failed to bulk add notes", detail: "There was an error adding Notes to Files. Please contact support" });
      else if (resp.errorCode !== 0) this.messageService.add({ severity: "error", summary: "Failed to bulk add notes", detail: resp.errorMessage });
      else {
        this.noteData = undefined;
        this.displayNote = false;
        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for Bulk Add Notes';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
      }
    } catch (error) {
      this.showError("Error Updating MBL", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  private getFileIdsFromSelectedFiles() {
    let selectedFileIds = [];
    const distinctFiles = this.selectedFiles.filter((file, i, arr) => arr.findIndex((t) => t.fileId === file.fileId) === i);
    for (var x = 0; x < distinctFiles.length; x++) {
      let item = distinctFiles[x];
      selectedFileIds.push({ FileId: item.fileId });
    }
    return selectedFileIds;
  }

  public cancelUpdateFilesETA() {
    this.displayUpdateETAModal = false;
    this.newFilesETA = "";
  }

  public hideANDRequestDialog() {
    this.displayANFRequest = false;
  }

  public hideTransShipmentDialog() {
    this.displayTransShipmentModal = false;
  }

  public async updateTransShipment() {
    this.showActionSpinner = true;
    try {
      if (this.selectedFiles?.length === 0) {
        this.toastMessage('info', 'No Selected Files', 'Please select at least one file before capturing Transshipment info');
        this.showActionSpinner = false;
        return;
      } else if (this.selectedTransShipVessel.id === this.selectedVessel.id && this.selectedTransShipVoyage.id === this.selectedVoyage.id) {
        this.toastMessage('warn', "Can't transship files to the same vessel / voyage", '');
        this.showActionSpinner = false;
        return;
      }

      const payload = {
        SelectedFileIds: this.getFileIdsFromSelectedFiles(),
        Voyage: this.selectedTransShipVoyage.id,
      };
      const resp = await this.bulkUpdaterApiService.bulkUpdateTransShipVoyage(payload);
      if (!resp) this.toastMessage('error', "TransShipment", 'Failed to bulk update TransShipment information');
      else if (resp.errorCode !== 0) this.toastMessage('error', 'Transshipment', resp.errorMessage);
      else {
        this.filesWithIssues = resp.result;
        const onlyErrors = this.filesWithIssues.every(file => file.fileIssueType === FileIssueType.ERROR);
        if (onlyErrors) {
          this.toastMessage('error', 'Error', `All files on voyage have errors`);
        } else {
          this.toastMessage('success', 'Success', resp.errorMessage);
        }
        this.dialogHeader = 'Files for Transshipment';
        this.dialogFooter = '';
        this.showFilesWithIssuesDialog = true;
        this.displayTransShipmentModal = false;
      }
    } catch (error) {
      this.showError("Error Capturing Cargo Dues Info", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  public async requestANFFromShippingLine() {
    this.showActionSpinner = true;
    try {
      const payload = { VoyageId: this.selectedVoyage.id };
      const r = await this.api.requestANFFromShippingLine(payload);
      if (r) {
        this.displayANFRequest = false;
        this.messageService.add({ severity: "success", summary: "ANF", detail: "ANF Document request successful" });
      } else {
        this.messageService.add({ severity: "error", summary: "Landing Order Info", detail: "Failed to request ANF document from shipping line" });
      }
    } catch (error) {
      this.showError("Error Requesting ANF From Shipping Line", error);
      this.log.error(error);
    }
    this.showActionSpinner = false;
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message: friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authenticationService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: Bulk updater" + "<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";

        var subject: string = "NovaDT Error Message - Component: Bulk updater";
        var res = this.api.sendFreeTextEmail(this.config.supportEmail, "system@avecs.co.za", emailBody, subject, -1, -1);
      },
      reject: () => { },
    });
  }

  private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
