<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div class="ui-g" *ngIf="!showSpinner">
  <div class="ui-g-12" *ngIf="files">
    <div class="ui-g-12">
      <h2>Manage File Preferences</h2>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12 ui-float-label">
        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" defaultLabel=" " optionLabel="header"
          appendTo="body" (onChange)="onFilePreferenceTypeSelect()">
        </p-multiSelect>
        <label class="float-label" for="float-input">File Preferences *</label>
      </span>
    </div>
    <div class="ui-g-12" *ngIf="selectedColumns && selectedColumns.length !== 0">
      <p-table #dt [loading]="showSpinner" [columns]="selectedColumns" [value]="files" dataKey="dbnNumber" [rows]="25"
        [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['dbnNumber']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i style="color: #fff;" class="pi pi-search"></i>
              <input [(ngModel)]="filePreferenceSearch" pInputText type="text"
                (input)="dt.filterGlobal($event.target.value, 'contains'); onFilePreferenceTypeSelect()"
                placeholder="Search" style="color: #fff;" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th width="80px">
              <p-checkbox [(ngModel)]="allFilesSelected" (onChange)="onAllSelect($event.checked)" binary="true"
                [disabled]="!isAccountsAdmin">
              </p-checkbox>
            </th>
            <th>
              DBN Number
            </th>
            <th *ngFor="let col of columns">
              <p-checkbox [(ngModel)]="col.enabled" [disabled]="!isAccountsAdmin"
                (onChange)="onColumnAllSelect($event.checked, col.filePreferenceTypeId)" binary="true">
              </p-checkbox>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td width="80px">
              <p-checkbox [(ngModel)]="rowData.enabled" (onChange)="onRowAllSelect($event.checked, rowData.fileId)"
                [disabled]="!isAccountsAdmin" binary="true">
              </p-checkbox>
            </td>
            <td>
              {{ rowData.dbnNumber }}
            </td>
            <td *ngFor="let col of columns">
              <ng-container *ngFor="let preference of rowData.preferences">
                <div *ngIf="preference.filePreferenceTypeId === col.filePreferenceTypeId">
                  <p-checkbox [(ngModel)]="preference.enabled" binary="true" (onChange)="OnFilePreferenceSelect(rowData.fileId,
                    col.filePreferenceTypeId)" [disabled]="!isAccountsAdmin" [pTooltip]="
                    !isAccountsAdmin
                    ? 'You are not accounts admin. Cannot change ' + col.header
                    : rowData.dbnNumber + ' has ' + col.header + ' ' + (preference.enabled ? 'on' : 'off')
                    " tooltipPosition="left">
                  </p-checkbox>
                </div>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="ui-g-12">
      <button *ngIf="!saveSpinner" pButton label="Save" class="width-100 margin-top-1em height-100"
        [disabled]="selectedColumns.length === 0 || !isAccountsAdmin" (click)="saveFilesPreferences()"></button>
      <button *ngIf="saveSpinner" class="width-100 margin-top-1em height-100" pButton type="button" [disabled]="true">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </div>
  </div>
</div>