<p-dialog header="Create New Collection Load" [(visible)]="createLoadCollection" [style]="{width: '30vw'}">
  <form #f="ngForm" (ngSubmit)="createNewCollectionLoad()">
    <div class="content">
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <input required class="new-input" id="float-input" type="text" pInputText
            [(ngModel)]="collectionLoad.loadName" name="loadName" style="width: 100%" />
          <label for="float-input">Load Name*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-calendar required class="new-input" [(ngModel)]="collectionLoad.ETD" name="ETD" [showTime]="true"
            [showButtonBar]="true" [readonlyInput]="true" inputId="time" appendTo="body" [style]="{'width': '100%'}">
          </p-calendar>
          <label class="pad-left" for="float-input" [style]="{'padding-top': '5px'}">Estimated
            Departure*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <input required class="new-input" id="float-input" type="text" pInputText
            [(ngModel)]="collectionLoad.driverName" name="driverName" style="width: 100%" />
          <label for="float-input">Drivers Name*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required [autoDisplayFirst]="false" [options]="transporters" class="new-input" optionLabel="label"
            optionValue="value" type="text" [(ngModel)]="collectionLoad.transporter" name="transporter"
            [style]="{'width':'100%'}" [filter]="true" [showClear]="true" appendTo="body">
          </p-dropdown>
          <label for="float-input">Transporter*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="planAssignmentType"
            optionLabel="txt" optionValue="id" [filter]="true" filterBy="label,value.description" [showClear]="true"
            [(ngModel)]="collectionLoad.dispatchPlanType" name="dispatchPlanType" appendTo="body">
          </p-dropdown>
          <label for="float-input">Load Assignment Type*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="locationTypes"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label, value.description"
            [showClear]="true" [(ngModel)]="collectionLoad.pickupLocationType" name="pickupLocationType"
            (onChange)="onCollectionPickUpLocationType()" appendTo="body">
          </p-dropdown>
          <label for="float-input">Pickup Location Type*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="locationsPickUp"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label, value.description"
            [showClear]="true" [(ngModel)]="collectionLoad.pickupLocation" name="pickupLocation" appendTo="body">
          </p-dropdown>
          <label for="float-input">Pickup Location*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="locationTypes"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label,value.description"
            [showClear]="true" [(ngModel)]="collectionLoad.dropoffLocationType" name="dropoffLocationType"
            (onChange)="onCollectionDropoffLocationType()" appendTo="body">
          </p-dropdown>
          <label for="float-input">Dropoff Location*</label>
        </span>
      </div>
      <div class="ui-g-12">
        <span class="ui-g-12 ui-float-label">
          <p-dropdown required class="locationDrop" [autoDisplayFirst]="false" [options]="locationsDropOff"
            optionLabel="description" optionValue="id" [filter]="true" filterBy="label, value.description"
            [showClear]="true" [(ngModel)]="collectionLoad.dropoffLocation" name="dropoffLocation" appendTo="body">
          </p-dropdown>
          <label for="float-input">Destination*</label>
        </span>
      </div>
    </div>
  </form>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Create New Collection Load" class="width-100"
      (click)="createNewCollectionLoad()" [disabled]="!f.valid"></button>
  </p-footer>
</p-dialog>
<p-table [columns]="loadTableColumns" [value]="loads" dataKey="dispatchLoadPlanId" [rows]="25" [lazy]="true">
  <ng-template pTemplate="caption">
    Collection Load Planning
    <button pButton type="button" icon="pi pi-plus" label="Create a new Load" class="caption-add-cargo-button"
      (click)="createLoad()"></button>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Show Cargo</th>
      <th *ngFor="let col of columns">
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
    <tr>
      <td>
        <a [pRowToggler]="rowData">
          <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            style="font-weight: 800; font-size: 26px;"></i>
        </a>
      </td>
      <td *ngFor="let col of columns" [ngClass]="{cssClass: expression}" a>
        {{ rowData[col.field] }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
    <tr>
      <td colspan="12">
        <div class="ui-g-12">
          <cargo-table [loadId]="rowData.dispatchLoadPlanId" [rowData]="rowData" [loadName]="rowData.name"
            [loadType]="loadTypes.COLLECTION" (refreshLoad)="getCurrentDispatchLoads()" [statusData]="statusData"
            [managerType]="1">
          </cargo-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>