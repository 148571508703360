import { Component, OnInit } from '@angular/core';
import { BackBoardTable } from '../../interfaces/global.interfaces';
import { Api2Service } from '../../services/api2.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MessageService } from "primeng/api";

@Component({
  selector: 'vessel-board',
  templateUrl: './vessel-board.component.html',
  styleUrls: ['./vessel-board.component.css']
})
export class VesselBoardComponent implements OnInit {
  showSpinner: boolean = false;
  backBoard: BackBoardTable[];
  names: string[];
  notes: string;
  display: boolean = false;
  displayPosition: boolean;
  selectedDate: any;
  currentMonthName: string;
  formatETA: any;

  position: string;
  constructor(
    private api: Api2Service,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.GetBackBoardData();
  }

  async GetBackBoardData() {
    this.showSpinner = true;
    this.currentMonthName = moment().format('MMMM');
    let boardData = await this.api.getBackBoardData();
    if (boardData) {
      for (let i = 0; i < boardData.length; i++) {
        boardData[i].eta = moment(boardData[i].eta).format('DD/MM/yyyy');
      }
      this.backBoard = boardData;
      this.names = boardData.map(data => { return { label: data.name, value: data.name }; });
      this.names = _.uniqBy(this.names, 'label');
      this.formatETA = moment(boardData.eta).format('DD/MM/yyyy');
    }
    this.showSpinner = false;
    console.log(boardData);
  }
  async GetBackBoardDataWithMonth() {
    this.showSpinner = true;
    var monthDate = moment(this.selectedDate).format('yy/MM/DD');
    this.currentMonthName = moment(monthDate).format('MMMM');
    let boardData = await this.api.GetBackBoardDataWithMonth(monthDate);
    if (boardData) {
      for (let i = 0; i < boardData.length; i++) {
        boardData[i].eta = moment(boardData[i].eta).format('DD/MM/yyyy');
      }
      this.backBoard = boardData;
      this.names = boardData.map(data => { return { label: data.name, value: data.name }; });
      this.names = _.uniqBy(this.names, 'label');
    }
    this.showSpinner = false;
    console.log(boardData);
  }
  showPositionDialog(position: string, notes) {
    if (!notes) {
      this.toastMessage('info', 'No notes on vessel / voyage', '');
      return;
    }

    this.position = position;
    this.displayPosition = true;
    this.notes = notes;
  }

  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}