import { Injectable } from '@angular/core';
import { WAProduct } from '../../../interfaces/whatsapp.interface';
import { WhatsappApiService } from '../../../services/api/whatsapp-api.service';
import { MessageService } from 'primeng';
import { WAProducts } from '../../../enums';

@Injectable({
  providedIn: 'root'
})
export class WhatsappProductService {

  private _products: WAProduct[];
  private _selectedProduct: WAProduct;

  get products(): WAProduct[] { return this._products; }
  get dtLiveProductId(): number { return WAProducts.NOVA_DT; }
  get selectedProduct(): WAProduct | null { return this._selectedProduct; }
  set selectedProduct(selectedProduct: WAProduct) { this._selectedProduct = selectedProduct; }

  constructor(
    private messageService: MessageService,
    private whatsappApiService: WhatsappApiService
  ) { }

  public async getWAProducts() {
    if (this._products) return;
    try {
      const retval = await this.whatsappApiService.WAGetProducts()
      if (!retval) this.toastMessage('error', 'Failed to get whatsapp products. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this._products = retval.result;
        this._selectedProduct = retval.result[0];
      }
    } catch (err) {
      this.toastMessage('error', 'Failed to get whatsapp products. Please contact support', '');
    }
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }) }
}