import { MessageService } from 'primeng/api';
import { LogBase } from './../../../services/logger.service';
import { WATemplate, WATemplateParams, WABroadcastTemplate, WACargo } from '../../../interfaces/global.interfaces';
import { WhatsappApiService } from '../../../services/api/whatsapp-api.service';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng';
import * as moment from 'moment';
import { Title } from "@angular/platform-browser";
import { FilterDataService } from '../../filter-search/filter-search-data.service';
import { LegalEntityApiService } from '../../../services/api/legal-entity-api.service';
import { ApiService } from '../../../services/api.service';
import { WhatsappProductService } from '../whatsapp-product/whatsapp-product.service';

@Component({
  selector: 'app-whatsapp-manager',
  templateUrl: './whatsapp-manager.component.html',
  styleUrls: ['./whatsapp-manager.component.scss']
})
export class WhatsappManagerComponent implements OnInit {

  selectedType: string = "Vessels";

  waTemplates: WATemplate[] = [];
  waTemplateParams: WATemplateParams[] = [];
  selectedWATemplate: WATemplate = null;

  showSpinner: boolean = false;
  showDialog: boolean = false;
  broadCastingTemplate: boolean = false;
  templateParmSpinner: boolean = false;

  constructor(
    private whatsappService: WhatsappApiService,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
    private log: LogBase,
    private messageService: MessageService,
    private legalEntityApiService: LegalEntityApiService,
    public filterDataService: FilterDataService,
    public whatsappProductService: WhatsappProductService,
    private titleService: Title
  ) { this.titleService.setTitle("WhatsApp Manager"); }

  async ngOnInit() { await this.refreshList(); }

  async refreshList() {
    this.showSpinner = true;
    await this.getAllWATemplates();
    this.showSpinner = false;
  }

  async getAllWATemplates() {
    try {
      const resp = await this.whatsappService.WAGetAllTemplates(this.whatsappProductService.dtLiveProductId);
      if (!resp) this.toastMessage('error', 'Failed to get templates. Please contact support', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.waTemplates = resp.result;
        this.selectedWATemplate = this.waTemplates[0];
        await this.getWATemplateParams();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get templates. Please contact support', '');
      this.log.error(error);
    }
  }

  async getWATemplateParams() {
    if (!this.selectedWATemplate) return;
    this.waTemplateParams = null;
    try {
      const resp = await this.whatsappService.WAGetTemplateParams(this.selectedWATemplate.id);
      if (!resp) this.toastMessage('error', 'Failed to get templates params. Please contact support', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.waTemplateParams = resp.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get template params. Please contact support', '');
      this.log.error(error);
    }
  }

  confirmBroadCast() {
    this.confirmationService.confirm({
      header: 'Broadcast Confirmation',
      message: 'Are you sure you want to broadcast the template to all the consignee(s)?',
      accept: async () => {
        this.confirmationService.close();
        await this.broadcastTemplate();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  canBroadCastTemplate(form: any): boolean {
    if (form.valid) {
      if (!this.filterDataService.selectedFiles || this.filterDataService.selectedFiles?.length == 0) return true;
      else if (this.filterDataService.selectedFiles && this.filterDataService.selectedFiles.length > 0) return false;
    } else return true;
  }

  async selectATemplate() {
    await this.getAllWATemplates();
    await this.getWATemplateParams();
    this.showDialog = true;
  }

  async broadcastTemplate() {
    this.broadCastingTemplate = true;
    try {
      const wABroadcastTemplate: WABroadcastTemplate = {
        template: this.selectedWATemplate,
        cargo: await this.findAndReplaceTemplateBody()
      };
      const retval = await this.whatsappService.WABroadcastTemplate(wABroadcastTemplate);
      if (!retval) this.toastMessage('error', 'Failed to broadcast template to users. Please contact support', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        this.showDialog = false;
        this.waTemplateParams = null;
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to broadcast template to users. Please contact support', '');
      this.log.error(error);
    }
    this.broadCastingTemplate = false;
  }

  async findAndReplaceTemplateBody() {
    try {
      let cargo: WACargo[] = [];
      
      for (let j = 0; j < this.filterDataService.selectedFiles.length; j++) {
        const file = this.filterDataService.selectedFiles[j];
        
        let body = this.selectedWATemplate.body;

        for (let i = 0; i < this.waTemplateParams.length; i++) {

          const name = this.waTemplateParams[i].variableName;
          let value = this.waTemplateParams[i].value;

          if (name == 'fileId') {
            value = `${file.fileId}`;
          } else if (name == 'car') {
            value = await this.getFileCargoDescriptions(file.fileId);
          }
          const type = this.waTemplateParams[i].variableType;

          //if there is a new template and the type doesn't exist here add it 
          //can format the value however needed
          if (type == 'date') {
            value = moment(this.waTemplateParams[i].value).format('YYYY/MM/DD');
          } else if (type == 'datetime') {
            value = moment(this.waTemplateParams[i].value).format('YYYY/MM/DD hh:mm a');
          }

          body = body.replace('#' + name + '#', value);
        }
        const legalEntity = await this.legalEntityApiService.getLegalEntityById(file.legalEntityId);

        cargo.push({
          dbnNumber: file.dbnNumber,
          fileId: file.fileId,
          body: body,
          cellPhone: legalEntity.cellphone,
          telephone1: legalEntity.telephone1,
          firstName: legalEntity.name,
          lastName: legalEntity.lastName
        })
      }
      return cargo;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getFileCargoDescriptions(fileId: number): Promise<string> {
    const resp = await this.apiService.getFileCargo(fileId);
    if (!resp) {
      this.toastMessage('error', 'Failed to get a files cargo. Please contact support', '');
      return;
    }

    let cargoDescription = '';
    
    for (const vehicle of resp.Vehicles) {
      cargoDescription += vehicle.Description.replace(/[\n\r\t]/g, "") + ', ';
    }
    for (const vehicle of resp.ContainerItems) {
      cargoDescription += vehicle.Description.replace(/[\n\r\t]/g, "") + ', ';
    }

    if (cargoDescription.substring(cargoDescription.length - 1) == ',') {
      cargoDescription = cargoDescription.substring(cargoDescription.length - 1);
    }

    return cargoDescription;
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
