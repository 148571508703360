<p-progressSpinner *ngIf="showSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"
  animationDuration="2s"></p-progressSpinner>

<p-tabView *ngIf="!showSpinner">
  <p-tabPanel header="Documents" leftIcon="fas fa-file-alt">
    <div class="ui-g-12" *ngIf="documents && documents.length > 0">
      <p-table #dt [value]="documents" [columns]="itemCols" [rows]="1000" [scrollable]="true" scrollHeight="550px"
        [autoLayout]="true">
        <p-header>Documents</p-header>
        <ng-template pTemplate="caption">
          <div class="p-d-flex custom-note-buttons">
            <span class="p-input-icon-left create-item">
              <button pRipple class=" p-button-info ui-button-success " pButton type="button"
                pTooltip="Refresh Documents" [icon]="loadIcon" (click)="refreshList()"></button>
            </span>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search keyword" style="color: #fff;" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}">
              <b>{{col.header}}</b>
              <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </th>
            <th style="width: 10%;">
              <b>Actions</b>
            </th>
          </tr>
        </ng-template>
        <ng-template let-rowData let-columns="columns" pTemplate="body">
          <tr>
            <td class="text-wrap" *ngFor="let col of columns">
              <ng-container *ngIf="col.field != 'StatusDate'">
                {{checkIfValueIsDate(rowData[col.field])}}
              </ng-container>
              <div class="status" *ngIf="col.field == 'StatusDate'">
                <label ngClass="{{ setStatusTypeColour(rowData.StatusId)}}">
                  {{rowData.Status}}
                </label>
                <label>
                  {{checkIfValueIsDate(rowData[col.field])}}
                </label>
              </div>
              <ng-container *ngIf="col.field == 'StatusDate' && rowData[col.field]==null">Not Verified
              </ng-container>
            </td>
            <td style="width: 10%;">
              <button #viewFile pRipple class="p-button-info"
                *ngIf="rowData.FileType == 'pdf' || rowData.FileType == 'svg' || rowData.FileType == 'jpg' || rowData.FileType == 'png'"
                pButton (click)="displayImageViewerDialogue(rowData)" type="button" icon="fas fa-book"
                (onHide)="closeViewer()" pTooltip="View File" tooltipPosition="left"></button>
              <button pRipple class="p-button-info" type="button"
                (click)="documentApiService.downloadDocument(rowData.Id, rowData.FileName)" pButton
                icon="fas fa-file-download" pTooltip="Download File" tooltipPosition="left"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Upload Document" leftIcon="fas fa-file-upload">
    <div class="ui-g">
      <div class="ui-g-5"></div>
      <div class="ui-g-2">
        <p-progressSpinner *ngIf="uploadDocumentSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8"
          fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
      </div>
      <div class="ui-g-5"></div>
      <div class="ui-g-12">
        <div class="ui-g-4" id="fileUploadChoice">
          <p-dropdown [options]="uploadChoices" (onChange)="dropDownChoice()" [(ngModel)]="selectedChoice"
            placeholder="Select a type of File" optionLabel="label" [style]="{'width':'100%'}" [filter]="true">
          </p-dropdown>
        </div>
      </div>
      <div class="ui-g-12 md-12" id="fileUpload" *ngIf="hasChosenType">
        <p-fileUpload name="[]" url="{{apiUrl}}" (onUpload)="onUpload($event)" (onError)="onError()"
          (onBeforeUpload)="onBeforeSend($event)" [headers]="theUploadHeaders">
        </p-fileUpload>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Upload Border Duties" leftIcon="fas fa-file-upload">
    <div class="ui-g">
      <div class="ui-g-5"></div>
      <div class="ui-g-2">
        <p-progressSpinner *ngIf="uploadDocumentSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8"
          fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
      </div>
      <div class="ui-g-5"></div>
      <div class="ui-g-2 label-font"><label>Border Post:</label></div>
      <div class="ui-g-10">
        <p-dropdown [options]="borderPost" [(ngModel)]="selectedBorderPost" [style]="{'width':'30%'}"
          placeholder="Select a Border Post" optionLabel="label">
        </p-dropdown>
      </div>
      <div class="ui-g-12 md-12" id="fileUpload" *ngIf="selectedBorderPost">
        <p-fileUpload name="[]" url="{{apiBorderDutiesUrl}}" (onUpload)="onBorderDutiesUpload($event)"
          (onError)="onError()" (onBeforeUpload)="onBeforeBorderDutiesSend($event)" [headers]="theUploadHeaders">
        </p-fileUpload>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Upload SAD500" leftIcon="fas fa-file-upload">
    <div class="ui-g">
      <div class="ui-g-5"></div>
      <div class="ui-g-2">
        <p-progressSpinner *ngIf="uploadDocumentSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8"
          fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
      </div>
      <div class="ui-g-5"></div>
      <div class="ui-g-2 label-font"><label>Bill of Entry No:</label></div>
      <div class="ui-g-10"><input type="text" pInputText [(ngModel)]="BillofEntryNo" /></div>
      <div class="ui-g-2 label-font"><label>Bill of Lading:</label></div>
      <div class="ui-g-10"><input type="text" pInputText [(ngModel)]="BillOfLading" /></div>
      <div class="ui-g-2 label-font"><label>MRN Number:</label></div>
      <div class="ui-g-10"><input type="text" pInputText [(ngModel)]="MRNNumber" /></div>
      <div class="ui-g-2 label-font"><label>LRN Number:</label></div>
      <div class="ui-g-10"><input type="text" pInputText [(ngModel)]="LRNNumber" /></div>
      <div class="ui-g-2 label-font"><label>Parent Item Number:</label></div>
      <div class="ui-g-10"><input type="text" pInputText [(ngModel)]="ParentItemNumber" /></div>
      <div class="ui-g-12 md-12" id="fileUpload"
        *ngIf="BillofEntryNo && BillOfLading && LRNNumber && MRNNumber && ParentItemNumber">
        <p-fileUpload name="[]" url="{{apiSAD500Url}}" (onUpload)="onSAD500Upload($event)" (onError)="onError()"
          (onBeforeUpload)="onBeforeSAD500Send($event)" [headers]="theUploadHeaders">
        </p-fileUpload>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

<p-dialog header="Add Border Post" modal="true" [style]="{'width': '500px'}">

  <div class="ui-g-4">
    <label>Border Post :</label>
  </div>
  <div class="ui-g-8">
    <input type="text" pInputText />
  </div>
  <div class="ui-g-4">
    <label>Exit Border Post :</label>
  </div>
  <div class="ui-g-8">
    <input type="text" pInputText />
  </div>
  <div class="ui-g-4">
    <label>Default CIF Destination :</label>
  </div>
  <div class="ui-g-8">
    <input type="text" pInputText />
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" label="Save"></button>
    <button pRipple class="p-button-info" pButton type="button" label="Cancel"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Preview" [dismissableMask]="true" [positionTop]="60"
  [contentStyle]="{width: '800px', height: '550px'}" [responsive]="true" [draggable]="false" (onHide)="closeViewer()"
  [(visible)]="displayImageViewer">
  <app-image-viewer class="imgView" *ngIf="images.length>0" [zoomInButton]="false" [showPDFOnlyOption]="false"
    [showOptions]="false" [fullscreen]="false" [zoomOutButton]="false" [rotate]="false" [images]="images"
    [idContainer]="'idOnHTML'" [loadOnInit]="true">
  </app-image-viewer>
</p-dialog>