import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BorderPostDropdown, Dropdown, FileLoads, FileRowItemDescriptonAndId, FilesReadyForDelivery, FilesWithIssues, LocationType, PrePlanLoadInfo } from '../../../interfaces/global.interfaces';
import { Api2Service } from '../../../services/api2.service';
import { MessageService } from 'primeng';
import { FileIssueType } from '../../../enums';
import * as moment from 'moment';

@Component({
  selector: 'app-preplanning',
  templateUrl: './preplanning.component.html',
  styleUrls: ['./preplanning.component.scss']
})
export class PreplanningComponent implements OnInit {
  @Output() refreshLoad = new EventEmitter<void>();

  borderPosts: BorderPostDropdown[] = [];
  selectedBorderPost: BorderPostDropdown | null = null;
  selectedLoadPlan: FileLoads | null = null;
  borderPostFiles: string;
  filesReady: FilesReadyForDelivery[];
  loadCargo: FileRowItemDescriptonAndId[] = [];
  filteredLoads: FileLoads[];
  selectedFiles: FilesReadyForDelivery[];
  loadInfo: PrePlanLoadInfo;
  addReadyFilesToLoad: boolean = false;
  locationTypes: LocationType[];
  locations: LocationType[];
  selectedTransporter: Dropdown = null;
  loadInfoTransporter: Dropdown;
  editedLoadInfoTransporter: Dropdown;
  transporters: Dropdown[] = [];
  public dialogHeader: string = '';
  public filesWithIssues: FilesWithIssues[] | null = null;
  public showDialog: boolean = false;
  showDialogCreate: boolean = false;
  public promoteLoad: boolean = false;
  public showLoadCargoTable: boolean = false;
  public borderPostSpinner: boolean = false;
  public editLoadInfo: boolean = false;
  deliveryLoad = {
    loadName: "",
    driverName: "",
    ETD: null,
    dropoffLocationType: null,
    dropOffLocation: null,
    transporter: null
  };

  editedLoads = {
    editedLoadName: "",
    editedDriverName: "",
    editedETD: null,
    editedTrailer: "",
    editedTruck: ""
  };

  loadCargoColumns: { field: string, header: string; }[] = [
    { field: 'dbnNumber', header: 'DBN Number' },
    { field: 'description', header: 'Description' },
    { field: 'zarAmount', header: 'Cargo Value' },
  ];


  constructor(
    private api2: Api2Service,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    await this.onLocationType();
    await this.GetTransporters();
  }

  //Get location type for load plan destination
  //newLoadType: 1 = Collection, 2 = Delivery
  //newLoadType to decide which dialog to show when creating a new load
  async createLoad() {
    this.locationTypes = await this.api2.getDispatchPlannerLocationTypes();
    this.showDialogCreate = true;
  }

  //Delivery Planning - Create New Load Plan
  async createNewPrePlanLoad() {
    try {
      let payload = {
        "LoadName": this.deliveryLoad.loadName,
        "DriverName": this.deliveryLoad.driverName,
        "ETD": moment(this.deliveryLoad.ETD).format('YYYY/MM/DD, HH:mm:ss'),
        "LocationId": this.deliveryLoad.dropOffLocation.id,
        "TransporterId": this.selectedTransporter.value
      };
      const retval = await this.api2.CreateNewPrePlanLoad(payload);
      if (retval) {
        this.messageService.add({
          severity: 'success',
          summary: 'Successfully create new pre-plan load',
          detail: ''
        });
        this.showDialogCreate = false;
        this.deliveryLoad = {
          loadName: "",
          driverName: "",
          ETD: null,
          dropoffLocationType: null,
          dropOffLocation: null,
          transporter: null
        };
        this.selectedTransporter = null;
        this.showDialog = false;
        await this.FilterFiles();
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Failed to create new Pre-Plan Load Plan',
          detail: 'Please contact support'
        });
      }
    } catch (ex) {
      this.messageService.add({
        severity: 'error',
        summary: ex,
        detail: 'Please contact support'
      });
    }
  }

  //Get a list of files that have the selected borderpost in their route
  async FilterFiles() {
    this.filesReady = [];
    this.borderPostSpinner = true;
    console.log(this.selectedBorderPost);
    try {
      let retval = await this.api2.FilesReadyForBorderpostsPrePlanning(this.selectedBorderPost.id);
      if (retval) {
        this.filesReady = retval;
        await this.getLoadsForWithFilteredBorderpost();
        console.log(retval);
      }
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to get files for selected borderpost',
        detail: 'Please contact support'
      });
    }
    this.borderPostSpinner = false;
  }

  //Get a list of loads that have the selected Borderpost as their final destination
  //Status not = 8 - Offloaded
  async getLoadsForWithFilteredBorderpost() {
    try {
      let retval = await this.api2.GetLoadsForFilteredBorderPosts(this.selectedBorderPost.id);
      if (retval) {
        this.filteredLoads = retval;
      }
    }
    catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to get loads for selected borderpost',
        detail: 'Please contact support'
      });
    }
  }

  //Get list of Borderposts - 2
  async onLocationType() {
    this.borderPosts = await this.api2.GetLocationFromLocationId(2);
    if (!this.borderPosts) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to get location types for selected location',
        detail: 'Please contact support'
      });
    }
  }

  public async getLocationType() {
    this.locations = await this.api2.GetLocationFromLocationId(this.deliveryLoad.dropoffLocationType.id);
    if (!this.locations) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to get locations for creating load',
        detail: 'Please contact support'
      });
    }
  }

  //Add selected files to a load plan
  async AddToDeliveryPrePlan() {
    const fileIds: number[] = this.selectedFiles.map(x => +x.dbnNumber.split("/")[1]);
    const payload = {
      "FileIds": fileIds,
      "LoadId": this.selectedLoadPlan.id
    };

    const addLoad = await this.api2.AddToDeliveryPrePlan(payload);
    await this.FilterFiles();
    if (!addLoad) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed to add files to delivery load - ${this.selectedLoadPlan.name}. Please contact support.`
      });
    } else if (addLoad.errorCode !== 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: addLoad.errorMessage
      });
      this.filesWithIssues = [];
      for (const file of addLoad.result) {
        this.filesWithIssues.push({
          dbnNumber: file.dbnNumber,
          fileIssueType: FileIssueType.ERROR,
          message: file.failReason
        });
      }
      this.dialogHeader = 'Error(s) Adding Cargo to Delivery Load';
      this.showDialog = true;
    } else {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Files have been added to load ${this.selectedLoadPlan.name}` });
      this.selectedFiles = null;
      this.filesWithIssues = null;
      this.dialogHeader = '';
      this.showDialog = false;
      await this.recallPrePlanCargoItems();
    }
  }

  //remove a cargo item from the selected load plan
  async removeCargoItem(itemToRemove: FileRowItemDescriptonAndId) {
    let payload = {
      "FileRowItemsId": itemToRemove.fileRowId,
      "LoadId": this.selectedLoadPlan.id
    };
    let removed = await this.api2.RemoveDeliveryPrePlanCargoItem(payload);
    if (removed.errorCode == 0) {
      await this.recallPrePlanCargoItems();
      await this.FilterFiles();
      this.scrollToBottom();
      this.messageService.add({
        severity: 'success',
        summary: 'Cargo removed successfully',
        detail: 'The item you selected was successfully removed from the load Load' + this.selectedLoadPlan.name
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to remove cargo'
      });
    }
  }

  //Refresh the cargo items on the load plan
  async recallPrePlanCargoItems(): Promise<void> {
    let retval = await this.api2.GetPrePlanCargoItemsByLoadId(this.selectedLoadPlan.id);
    if (retval) {
      this.promoteLoad = true;
      console.log(retval);
      this.loadCargo = retval.cargoItems;
      this.loadInfo = retval.loadInfo;
      this.loadInfo.estimatedDeparture = moment(this.loadInfo.estimatedDeparture).toDate();
      this.loadInfoTransporter = {
        label: this.loadInfo.transporterName,
        value: this.loadInfo.transporterId
      };
      this.editedLoadInfoTransporter = this.loadInfoTransporter;
      this.editedLoads = {
        editedDriverName: this.loadInfo.driverName,
        editedLoadName: this.loadInfo.loadName,
        editedETD: this.loadInfo.estimatedDeparture,
        editedTrailer: this.loadInfo.trailerInfo,
        editedTruck: this.loadInfo.truckInfo
      };
      this.showLoadCargoTable = true;
      console.log(this.loadInfo);
      console.log("Before scrollToBottom()"); // Add this
      this.scrollToBottom();
      console.log("After scrollToBottom()"); // Add this
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to refresh delivery cargo items',
        detail: 'Failed to recall delivery load plan items - Please contact support'
      });
    }
  }

  //Get list of transporters for Creating pre-plan load
  async GetTransporters() {
    try {
      let retval = await this.api2.getActiveTransporters();
      if (retval) {
        this.transporters = retval;
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Failed to get transporters',
          detail: 'Failed to get transporters - Please contact support'
        });
      }
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to get transporters',
        detail: err
      });
    }
  }

  public async editLoadDialog() {
    this.editLoadInfo = true;
  }

  public async editLoad() {
    try {
      const payload = {
        "LoadId": this.selectedLoadPlan.id,
        "LoadName": this.editedLoads.editedLoadName,
        "DriverName": this.editedLoads.editedDriverName,
        "ETD": moment(this.editedLoads.editedETD).format('YYYY/MM/DD, hh:mm:ss'),
        "TransporterId": this.editedLoadInfoTransporter.value,
        "TrailerInfo": this.editedLoads.editedTrailer,
        "TruckInfo": this.editedLoads.editedTruck,
      };

      const editLoad = await this.api2.EditDeliveryDispatchLoadInformation(payload);
      if (editLoad) {
        this.editedLoadInfoTransporter = null;
        this.messageService.add({ severity: 'success', summary: 'Load information updated' });
        await this.recallPrePlanCargoItems();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Failed to update',
          detail: 'Failed to update pre-plan load information. Please contact support'
        });
      }
    }
    catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to update',
        detail: 'Failed to update pre-plan load information. Please contact support'
      });
    }
    this.editLoadInfo = false;
  }

  public async PromotePrePlanLoadToDeliveryLoad() {
    try {
      let retval = await this.api2.PromotePrePlanLoadToDeliveryLoad(this.selectedLoadPlan.id);
      if (retval.errorCode == 0) {
        this.messageService.add({
          severity: 'success',
          summary: 'Successfully promoted load to Delivery Planning'
        });
        this.refreshLoad.emit();
        this.selectedLoadPlan = null;
        await this.getLoadsForWithFilteredBorderpost();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Failed to promote pre-plan',
          detail: 'Failed to promote pre-plan load to delivery. please contact support'
        });
      }
    }
    catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed to promote pre-plan',
        detail: 'Failed to promote pre-plan load to delivery. please contact support'
      });
    }
  }
  //Additional functions
  public viewFile(dbnNumber: string) {
    const fileId = dbnNumber.split('/')[1];
    window.open(`file-view?query=${fileId}`);
  }

  //Scroll to bottom when selected load
  scrollToBottom() {
    setTimeout(() => {
      window.scroll(
        {
          behavior: "smooth",
          top: document.body.scrollHeight
        }
      );
    }, 100);
  }
  public formatDate(thedate) {
    return moment(thedate).format('YYYY/MM/DD, hh:mm:ss');
  }
}