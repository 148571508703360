import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { LogBase } from "../../../services/logger.service";
import { ConfirmationService } from "primeng";
import { MessageService } from "primeng/api";
import { Title } from "@angular/platform-browser";
import * as _ from "lodash";
import { Dropdown, FilesWithIssues } from "../../../interfaces/global.interfaces";
import { AssignToType, FileIssueType, GenericType, Groups } from "../../../enums";
import { TaskApiService } from "../../../services/api/task-api.service";
import { ReferenceApiService } from "../../../services/api/reference-api.service";
import { ReassignRobinRobin, RoundRobinTask, RoundRobinUser } from "../../../interfaces/round.robin.interface";
import { UserTaskApiService } from "../../../services/api/user-tasks/user-tasks-api.service";
import { AuthenticationService } from "../../../services/api/auth/authentication.service";

@Component({
  selector: "app-file-assignment",
  templateUrl: "./file-assignment.component.html",
  styleUrls: ["./file-assignment.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService],
})
export class FileAssignmentComponent implements OnInit {

  public allUsers: Dropdown[];
  public activeUsers: Dropdown[];
  public selectedRoundRobinLegalEntityId: number | null = null;
  public groups: Dropdown[];
  public selectedAssignTo: Dropdown | null = null; // the assign to id (either round robin user id or group id)
  public assignToTypes: Dropdown[] = [];
  public selectedAssignToType: Dropdown | null = null;
  public files: RoundRobinTask[];
  public selectedFiles: RoundRobinTask[];
  public filesWithIssues: FilesWithIssues[] = [];
  public dialogHeader: string = 'Files with Issues for reassignment';
  public dialogFooter: string = '*Please note, files will only be reassigned if there are files with issues';
  private _reassignFiles: ReassignRobinRobin[] = [];

  public showDialog: boolean = false;
  public showAssignees: boolean = false;
  public showLoadingSpinner: boolean = false;
  private reassign = true;
  public reassignSpinner: boolean = false;
  public roundRobinAdminUser: boolean = this.authService.canEdit(Groups.ROUND_ROBIN_ADMIN);

  public selectedGroup: Groups;
  public allUserGroups: RoundRobinUser[] = [];

  public get assignToType(): typeof AssignToType { return AssignToType; }

  constructor(
    private authService: AuthenticationService,
    private taskApiService: TaskApiService,
    private userTaskApiService: UserTaskApiService,
    private referenceApiService: ReferenceApiService,
    private log: LogBase,
    private messageService: MessageService,
    private titleService: Title
  ) {
    this.titleService.setTitle("AVECS Round Robin Management");
  }

  async ngOnInit() {
    if (this.roundRobinAdminUser) {
      await this.loadPage();
    }
  }

  public async loadPage() {
    this.showLoadingSpinner = true;
    await this.getAssignToTypes();
    await this.getAndFilterAllFilesWithAssignedUsers();
    await this.getUsersAndGroups();
    this.showLoadingSpinner = false;
  }

  public async refresh() {
    this.showLoadingSpinner = true;
    await this.getAndFilterAllFilesWithAssignedUsers();
    await this.getUsersAndGroups();
    this.showLoadingSpinner = false;
  }

  private async getAssignToTypes() {
    try {
      const retval = await this.referenceApiService.getAssignToTypes();
      if (!retval) this.toastMessage('error', 'Failed getting files', 'Please contact support');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.assignToTypes = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get assign to types', 'Please contact support');
      this.log.error(err);
    }
  }

  private async getUsersAndGroups() {
    try {
      const resp = await this.referenceApiService.getAllGroupsAndRoundRobinUsers();
      if (!resp) this.toastMessage('error', 'Failed getting round robin users. Please contact support', '');
      else if (resp.errorCode !== 0) this.toastMessage('error', resp.errorMessage, '');
      else {
        this.allUserGroups = resp.result;
        //groups
        const uniqueGroups: any[] = _.uniqBy(resp.result, 'group');
        const groups: Dropdown[] = _.orderBy(uniqueGroups.map((x: { groupId: Groups; group: string; }) => ({ value: x.groupId, label: x.group })), 'label', 'asc');
        this.groups = [...groups];
        //users
        const uniqueUsers: any[] = _.uniqBy(resp.result, 'legalEntityId');
        const allUsers: Dropdown[] = _.orderBy(uniqueUsers.map((x: { legalEntityId: number; name: string; }) => ({ value: x.legalEntityId, label: x.name })), 'label', 'asc');
        this.allUsers = [...allUsers];
        //active users
        const activeUsers: Dropdown[] = _.orderBy(_.uniqBy(this.allUserGroups.filter(u => u.active === true), 'legalEntityId').map((x: { legalEntityId: number; name: string; }) => ({ value: x.legalEntityId, label: x.name })), 'label', 'asc');
        this.activeUsers = [...activeUsers];
      }
    } catch (error) {
      this.toastMessage('error', 'Failed getting round robin users. Please contact support', '');
      this.log.error(error);
    }
  }

  public async getAndFilterAllFilesWithAssignedUsers() {
    this.files = [];
    this.selectedFiles = [];
    try {
      const resp = await this.taskApiService.getAndFilterAllFilesWithAssignedUsers(this.selectedRoundRobinLegalEntityId, this.selectedGroup);
      if (!resp) this.toastMessage('error', 'Failed getting files', 'Please contact support');
      else if (resp.errorCode !== 0) this.toastMessage('error', resp.errorMessage, '');
      else this.files = resp.result;
      this.showAssignees = true;
    } catch (error) {
      this.toastMessage('error', 'Failed getting Files', 'Please contact support');
      this.log.error(error);
    }
  }

  private handleIndividualReassign() {
    for (const file of this.selectedFiles) {
      //find the legal entity id and group id that matches
      //if no match then that legal entity does not have group permissions
      const user = this.allUserGroups.find(g => g.legalEntityId === this.selectedAssignTo.value && file.groupId === g.groupId);
      if (!user) {
        const message: string = `${this.selectedAssignTo.label} is not in the ${file.assignedGroup} group.`;
        this.addFileWithIssue(file.dbnNumber, FileIssueType.ERROR, message);
        this.reassign = false;
        continue;
      }
      //reassigning to the same user
      else if (user.roundRobinUserId === file.assignedToId) {
        const message: string = `Cannot reassign task to ${this.selectedAssignTo.label}, they are already assigned to the task.`;
        this.addFileWithIssue(file.dbnNumber, FileIssueType.ERROR, message);
        this.reassign = false;
        continue;
      }
      //add to reassign file
      this.addReassignFile(user.roundRobinUserId, user.name, this.selectedAssignToType.value, file.fileId, file.assignedToId, file.dbnNumber, file.groupId, file.genericTypeId);
    }
  }

  private handleGroupReassign() {
    for (const file of this.selectedFiles) {
      //assignment type is different, eg trying to assign to group from individual
      if (this.selectedAssignToType.value !== file.assignedToTypeId) {
        const message: string = `Cannot reassign file because the assigned to is ${file.assignedTo}. You are trying to reassign file as ${this.selectedAssignToType.label}`;
        this.addFileWithIssue(file.dbnNumber, FileIssueType.ERROR, message);
        this.reassign = false;
        continue;
      }
      //trying to reassign to a different group eg finance to file owner
      else if (file.groupId !== this.selectedAssignTo.value) {
        const message: string = `Cannot reassign file from ${file.assignedGroup} to ${this.selectedAssignTo.label} because they are in different groups`;
        this.addFileWithIssue(file.dbnNumber, FileIssueType.ERROR, message);
        this.reassign = false;
        continue;
      }
      //reassign to the same group
      else if (file.groupId === this.selectedAssignTo.value) {
        const message: string = `Cannot reassign file to same group it already is ()${file.assignedGroup}`;
        this.addFileWithIssue(file.dbnNumber, FileIssueType.ERROR, message);
        this.reassign = false;
        continue;
      }

      //add to reassign file
      this.addReassignFile(this.selectedAssignTo.value, this.selectedAssignTo.label, this.selectedAssignToType.value, file.fileId, file.assignedToId, file.dbnNumber, file.groupId, file.genericTypeId);
    }
  }

  public async reassignFiles() {
    this.filesWithIssues = [];
    this._reassignFiles = [];
    this.reassign = true;

    switch (this.selectedAssignToType.value) {
      case AssignToType.INDIVIDUAL:
        this.handleIndividualReassign();
        break;

      case AssignToType.GROUP:
        this.handleGroupReassign();
        break;

      default:
        this.reassign = false;
        this.toastMessage('error', 'Failed to reassign files. Reassignment type is not supported', '');
        break;
    }

    if (!this.reassign) {
      this.showDialog = true;
      return;
    }

    this.reassignSpinner = true;
    const resp = await this.userTaskApiService.reassignUserTasks(this._reassignFiles);
    if (!resp) this.toastMessage('error', 'Failed to reassign files. Please contact support', '');
    else if (resp.errorCode !== 0) this.toastMessage('error', resp.errorMessage, '');
    else {
      this.filesWithIssues = resp.result;
      await this.handleFilesWithIssues(resp.errorMessage);
    }
    this.reassignSpinner = false;
  }

  private async handleFilesWithIssues(errorMessage: string) {
    const errors: FilesWithIssues[] | null = this.filesWithIssues?.filter(file => file.fileIssueType === FileIssueType.ERROR);

    if (errors?.length > 0) {
      this.showDialog = true;
    } else {
      this.toastMessage('success', errorMessage, '');
      await this.refresh();
    }
  }

  private addFileWithIssue(dbnNumber: string, fileIssueType: FileIssueType, message: string) {
    this.filesWithIssues.push({
      dbnNumber: dbnNumber,
      fileIssueType: fileIssueType,
      message: message
    });
  }

  private addReassignFile(assignToId: number, assignTo: string, assignToType: AssignToType, fileId: number, assignFrom: number, dbnNumber: string, groupId: Groups, genericTypeId: GenericType) {
    this._reassignFiles.push({
      assignToId: assignToId,
      assignTo: assignTo,
      assignToTypeId: assignToType,
      fileId: fileId,
      assignFromId: assignFrom,
      groupId: groupId,
      dbnNumber: dbnNumber,
      genericTypeId: genericTypeId
    });
  }

  public viewFile(fileId: number) { window.open(`file-view?query=${fileId}`); }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}