import { DateTime } from "luxon";
import { AssignToType, FileDocumentSource, FileIssueType, Type, BorderPostStatus } from "../enums";
import { NumberLiteralType } from "typescript";

export interface FileOwnerTask {
  id: number;
  completed: number;
  taskName: string;
  showSpinner: boolean;
}

export interface FileOwnerInfoView {
  contactNo: string;
  fullName: string;
  legalEntityId: number;
  legalEntityTypeId: number;
  route: string;
  cargoValue: number;
}

export interface TaskItem {
  TaskInstanceId: string;
  FileId: number;
  ProcessInstanceId: string;
  Name: string;
  Assignee: string;
  Due: Date;
  DisplayDate: string;
  Overdue: boolean;
  Reference: number;
  DBNNumber: string;
  NotesFiles: boolean;
  LegalEntityId: number;
  LegalEntityTypeId: number;
  FullName: string;
  ETA: Date;
  DisplayETA: string;
  VesselName: string;
  VoyageNumber: string;
}

export interface TaskInstance {
  TaskId: string;
  TaskDefinitionKey: string;
  ProcessDefinitionKey: string;
  ProcesssDefintionId: string;
  ProcessInstanceId: string;
  CaseInstanceId: string;
  Name: string;
  Description: string;
  Owner: string;
  Assignee: string;
  StartTime: string;
  EndTime: string;
  Duration: string;
  DeleteReason: string;
  Priority: string;
  DueDate: string;
  FollowUpDate: string;
}

export interface FileEmailAdress {
  Id: number;
  Address: string;
}

//Client Interface
export interface ClientAndCityDetails {
  Id: number;
  FullName: string;
  Country: string;
  DirtyCountryName: string;
  DirtyCityName: string;
  CountryId: number;
}

export interface Country {
  value: number;
  label: string;
}

//Cities Interface
export interface City {
  Id: number;
  Name: string;
}

export interface Region {
  value: number;
  label: string;
}

// Open File Interfaces

export interface ShipmentDetails {
  fileDocumentSourceId: FileDocumentSource;
  filetype: number;
  Cif: boolean;
  SupplierId: number;
  referenceNo: string;
  MblNo: string;
  PortOfDeparture?: any;
  PortOfArrival?: any;
  VesselNameTranshipId?: number;
  VoyageNoTranshipId?: number;

  Etd?: any;
  Sob?: any;
  ETA?: any;

  CourierCompanyId?: number;
  TrackingNo?: string;
  CourierOriginalReceivedDate?: any;

  AgentId?: number;
  Route?: any;
  VesselName?: string;
  VoyageNumber?: string;
}

// Financial Invoices
// BF CIF
export interface CIFInvoices {
  id: number;
  name: string;
}

export interface Dropdown {
  value: number;
  label: string;
}

export interface BorderPostDropdown {
  id: number;
  description: string;
}

export interface Role {
  value: number;
  label: string;
  group: number;
}

export interface LegalEntityFiles {
  fileId: number;
  dbnNumber: string;
  foreignReference: string;
  created: Date;
  complete: number;
}

export interface WAChat {
  id: number;
  serverNumber: string;
  whatsappUserId: number;
  firstname: string;
  lastName: string;
  fromName: string;
  from: string;
  insertDate: Date;
  accountSid: string;
  messages: WAMessage[];
  message: string;
  assigned: boolean;
  assignedUserId: number;
  claimedBy: string;
  refNo: string;
  unreadMessages: number;
  chatExpiryDate: Date;
  lastClaimedBy: string;
  groupName: string;
  groupId: number;
  groupRGB: string;
}

export interface WAMessage {
  productNumber: string;
  serverNumber: string;
  messageSID: string;
  chatId: number;
  id: number;
  who: string;
  from: string;
  body: string;
  mediaUrl: string;
  filename: string;
  friendlyname: string;
  extension: string;
  insertDate: Date;
  sendDate: Date;
  deliveredDate: Date;
  readDate: Date;
  undeliveredDate: Date;
  file: File;
  whatsappUserId: number;
  serverUser: number;
  direction: number;
}

export interface WaMessageByChats {
  chatId: WAMessage[];
}

export interface WAMedia {
  serveFilepath: string;
  filename: string;
  friendlyname: string;
  body: string;
  mimetype: string;
  sendDate: string;
}

export interface WAGroups {
  id: number;
  txt: string;
  groupRGB: string;
  productId: number;
}

export interface WARooms {
  roomId: number;
  txt: string;
  chats: WAChat[];
  filterOptions: WARoomAutoResponderItems[];
}

export interface WARoomAutoResponderItems {
  itemId: number;
  txt: string;
}

export interface WARoomTab {
  index: number;
  txt: string;
}

export interface WATemplate {
  id: number;
  txt: string;
  body: string;
  serverNumber: string;
  productId: number;
}

export interface WATemplateParams {
  id: number;
  templateId: number;
  variableName: string;
  display: string;
  variableType: string;
  description: string;
  value: string;
}

export interface WACargo {
  dbnNumber: string;
  fileId: number;
  body: string;
  cellPhone: string;
  telephone1: string;
  firstName: string;
  lastName: string;
}

export interface WABroadcastTemplate {
  template: WATemplate;
  cargo: WACargo[];
}

export interface WATemplateMessages {
  templateName: string;
  refNo: string;
  fullName: string;
  number: string;
  chatStatusId: number;
  chatStatus: string;
  sentDate: string;
  undeliveredDate: string;
  error: string;
  chatExpirydate: string;
  sentByUser: string;
}

export interface WAUnmatchedRefNo {
  id: number;
  refNo: string;
  fullName: string;
  number: string;
  chatStatus: string;
  chatStatusId: number;
  createdDate: string;
}

export interface WATermsAndConditions {
  acceptTerms: number;
  filesApproved: number;
}

export interface WARoomCount {
  count: number;
  roomName: string;
}

export interface WARoomsAndChats {
  refNo: string;
  roomId: number;
  roomName: string;
  chatId: number;
  avecsUser: string;
  fullName: string;
  number: string;
  expiryDate: string;
  createdDate: string;
  lastClaimedBy: string;
  timeDiff: string;
  client: string;
  direction: number;
}

export interface ILocationComponent {
  Id: number;
  Location: string;
  LocationTypeId: number;
  LocationType: string;
}

export interface MasterRoute {
  LoadLocationName: string;
  UnloadLocationName: string;
  LoadLocationId: number;
  UnloadLocationId: number;
  RouteLegs: RouteLegs[];
}

export interface RouteList {
  id: number;
  active?: number;
  loadLocationId: number;
  loadLocationName: string;
  parentRouteId: number;
  routeOrder: number;
  routeTypeDescription: string;
  routeTypeId: number;
  unLoadLocationName: string;
  unloadLocationId: number;
}

export interface SelectedMasterRoute {
  loadLocationId: number;
  loadLocationName: string;
  unloadLocationId: number;
  unLoadLocationName: string;
  active: number;
  routeLegs: RouteLegsList[];
}

export interface RouteLegsList {
  id: number;
  loadLocationId: number;
  loadLocationTypeId: number;
  loadLocationName: string;
  loadLocationType: string;
  parentRouteId: number;
  routeOrder: number;
  routeTypeDescription: string;
  routeTypeId: number;
  unloadLocationTypeId: number;
  unloadLocationId: number;
  unLoadLocationName: string;
  unloadLocationType: string;
  actionType: number;
}

export interface FilesThatUserRoute {
  dbnNumber: string;
  currentlyAt: string;
}

export interface RouteType {
  id: number;
  description: string;
}

export interface LocationType {
  id: number;
  description: string;
}

export interface RouteLocation {
  Id: number;
  Location: string;
  LocationTypeId: number;
  LocationType: string;
}

export interface CurrentRoutes {
  routeId: number;
  startLocationId: number;
  endLocationid: number;
  route: string;
}

export interface RouteLegs {
  loadLocationName: string;
  loadLocationType: string;
  unLoadLocationName: string;
  unloadLocationType: string;
  loadLocationId: number;
  unloadLocationId: number;
  routeTypeId: number;
  routeType: string;
}

export interface BorderPostDuties {
  dbnNumber: string;
  customerName: string;
  borderPost: string;
  route: string;
}

export interface WAVoyageTemplates {
  templateCount: number;
  templateName: string;
  vesselId: number;
  vesselName: string;
  voyageId: number;
  voyageNumber: number;
}

export interface WAVoyageTemplateDetails {
  fileNumber: string;
  firstname: string;
  lastname: string;
  body: string;
  sendDate: string;
}

export interface MonthlyStats {
  statName: string;
  files: CarMovements[];
}

export interface CarMovements {
  month: number;
  amount: number;
  monthName: string;
  yearSelected: number;
}

export interface ShipArrivalVisualFiles {
  handover: string;
  data: ShipArrivalVisual[];
}

export interface ShipArrivalVisual {
  id: number;
  title: string;
  vesselName: string;
  voyageNumber: any;
  reCount: number;
  clCount: number;
  phCount: number;
  start: string;
  end?: string; //nullable
  amount?: number;
}

export interface FileStatusCount {
  dbnCount: number;
  reCount: number;
  phCount: number;
}

export interface VehicleVesselVoyage {
  vesselName: string;
  voyageNumber: string;
  noOfVehicles: string;
  ETA: DateTime;
  dayDifferenceBetweenLastETA: string;
}
export interface BackBoardTable {
  name: string;
  vessel: string;
  eta: Date;
  totalCleared: number;
  totalHandover: number;
  totalPartialHandover: number;
  notes: string;
}

export interface VesselCalendarNotes {
  id: number;
  name: string;
  createdBy: string;
  updatedBy: string;
  vessel: vesselNotesVesselNames;
  voyage: vesselNotesVoyageNumbers;
  vesselName: string;
  voyageNumber: number;
  transhippedVessel?: string;
  created: Date;
  updated: Date;
  notes: string;
}

export interface vesselNotesVesselNames {
  vesselName: string;
  vesselId: number;
}

export interface vesselNotesVoyageNumbers {
  Id: number;
  Name: string;
  ETA: Date;
  CargoItemCount: number;
  AssingnedTo: string;
  AssignedToId: number;
}

export interface UserTasks {
  staffMember: string;
  legalEntityId: number;
  numberOfTasks: number;
  imagePath: string;
  userTasks: OutstandingUserTasks[];
}

export interface OutstandingUserTasks {
  dbnNumber: string;
  assignedTo: string;
  status: string;
  task: string;
  daysLeftOpen: string;
  colour: string;
}
export interface VoyageCentric {
  vesselName: string;
  voyageNumber: string;
  vesselId: number;
  voyageId: number;
  eta: string;
  countOfLocationType: number;
  clearingStatusId: number;
  clearingStatusCount: number;
  dbnCount: number;
  reCount: number;
  phCount: number;
  totalCount: number;
  locationCount: CurrentLocations[];
}

export interface CurrentLocations {
  countCurrLocByLocType: number;
  currentLocationId: number;
  currentLocationTypeId: number;
  location: string;
  voyageId: number;
}

export interface FilesAtLocation {
  fileId: string;
  description: string;
  currentLocation: string;
}

export interface FilesNotArrivedAtFinalDestion {
  dbn: string;
  currentLocation: string;
  currentLocationId: number;
  currentLocationTypeId: number;
  loadDate: string;
  unloadDate: string;
  lid: string;
  ulid: string;
  id: number;
  voyageNumber: string;
  eta: string;
  vesselName: string;
  foreignReference: string;
  description: string;
  daysSinceETA: number;
  fileOwner: string;
}

export interface AccordionLocationData {
  currentLocationName: string;
  currentLocationId: number;
  lessThanFortyFiveDays?: number;
  fourtyFiveDaysToThreeMonths?: number;
  threeToFourMonths?: number;
  overFiveMonths?: number;
  daysSinceETA: number;
}

export interface ClearingAgent {
  legalEntityId: number;
  name: number;
  email: string;
}

export interface AmountOutstandingArrearNotice{
  dbnNumber: string;
  supplier: string;
  cif: string;
  amt: number;
  symbol: string;
  consigneeFullName: string;
  email: string;
  currentLocation: string;
  unloadDate: string;
  daysAtLocation: number;
  flood: string;
  chassisNo: string;
  manufacturer: string;
  model: string;
  vesselName: string;
  voyageNumber: string;
  eta: string;
  fullRoute: string;
  inspectedOrNot: string;
  dutiesUploadDate: string;
  dutiesPaid: string;
  t896: string;
  arrearNoticeDateOne: string;
  arrearNoticeDateTwo: string;
  letterOfDemandDate: string;
  finalNoticeDate: string;
  note: string;
}

export interface TableColumns {
  field: string;
  header: string;
  width?: string;
}

export interface CurrencyRate {
  Currency: string;
  Rate: number;
}

export interface Statement {
  Address1: string;
  Address2: string;
  Address3: string;
  Addressee: string;
  AmountPaid: number;
  Balance: number;
  BankAccountDetailsOne: BankAccountDetails;
  BankAccountDetailsTwo: BankAccountDetails;
  BankAccountOne: number;
  BankAccountTwo: number;
  BorderPost: string;
  BorderPostId: number;
  City: string;
  CurrencySymbol: string;
  DBNNumber: string;
  DisplayeDate: Date;
  Email: string;
  FileId: number;
  ForeignReference: string;
  GrossAmount: number;
  InvoiceCurrencyId: number;
  InvoiceDate: string;
  InvoiceItemDescriptions: InvoiceItemDescription[];
  InvoiceNumber: number;
  NettAmount: number;
  PastelAccount: string;
  PastelCode: string;
  Refund: number;
  RegistrationNumber: string;
  Sendmessage: false;
  SpecificDiscount: number;
  StatementLineItems: StatementLineItems[];
  StatementNumber: number;
  UserName: string;
  VATAmount: number;
  VATNumber: string;
  VATRate: number;
  VIP: boolean;
  instanceVars: null;
  mapName: string;
  messageName: string;
  processInstanceId: string;
  Cancelled: number;
  ClearingStatusId: number;
  InvoiceRefunds: Payment[];
  InvoiceLineItems: InvoiceItem[];
}

export interface StatementLineItems {
  Id: number;
  Type: string;
  Cancelled: boolean;
  PaymentDate: string;
  TransactionDate: Date;
  Reference: number;
  Amount: number;
  Discount: number;
  SubTotal: number;
  VAT: number;
  Due: number;
  Balance: number;
}

export interface InvoiceItemDescription {
  FileId: number;
  FileRowItemId: number;
  Description: string;
  Quantity: number;
}

export interface BankAccountDetails {
  Description: string;
  BankName: string;
  AccountNumber: string;
  SwiftCode: string;
  BranchName: string;
  BranchCode: string;
  BranchAddress: string;
}

export interface InvoiceItem {
  InvoiceNumber: number;
  InvoiceTypeId: number;
  InvoiceDate: string;
  InvoiceType: string;
  Invoicee: string;
  Currency: string;
  DocumentId: number;
  Cancelled: boolean;
  subTotal: number;
  SpecificDiscount: number;
  costTotal: number;
  VAT: number;
  balance: number;
  DiscountPercentage: number;
  VATPercentage: number;
}

export interface Payment {
  ITRef: string;
  PaymentId: number;
  amountPaid: number;
  balance: number;
  comments: number;
  displayDate: string;
  invoiceId: number;
  paymentDate: string;
}

export interface LineItem {
  Amount: number;
  Description: string;
  Cancelled: string;
  DiscountPercentage: number;
  Gross: number;
  Id: number;
  Nett: number;
  PastelCode: string;
  Quantity: number;
  VAT: number;
  VATPercentage: number;
}

export interface FileStatus {
  dbnNumber: string;
  clearingStatusId: number;
  clearingAgentId: number;
  foreignReference: string;
  fileId: number;
}

export interface SetLegalEntityDefaultBorderPost {
  legalEntityId: number;
  borderPostId: number; // this is actually the location id for the border post id
}

export interface HandoverFile {
  fileId: number;
  consigneeLegalEntityId: number;
  dbnNumber: string;
  name: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  borderPost: string;
  makeModel: string;
  chassisNo: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  vip: boolean;
  clearingAgentId: number;
  clearingStatusId: number;
  clearingAgentStaffMembers: Dropdown[];
}

export interface NoteItem {
  note: string;
  created: string;
  createdBy: string;
}

export interface DispatchPlanType {
  id: number;
  txt: string;
}

export interface DispatchLoads {
  dispatchLoadPlanId: number;
  name: string;
  dateCreated: Date;
  estimatedDeparture: Date;
  DispatchLoadPlanStatus: string;
  DispatchLoadStatusId: number;
  numberOfCargoOnLoad: number;
  destination: string;
  pickupLocation: string;
  dropoffLocation: string;
  driverName: string;
  cargoLoadAssignmentStatusId: number;
  cargoLoadAssignmentStatus: string;
  cargoLoadAssignmentId: number;
  assignedDate: Date;
  loadCargoValue: number;
  trailerInfo: string;
  truckInfo: string;
}

export interface DispatchCollecionLoads {
  dispatchLoadPlanId: number;
  name: string;
  dateCreated: Date;
  estimatedDeparture: Date;
  dispatchLoadPlanStatus: string;
  cargoValue: number,
  dispatchLoadStatusId: number;
  numberOfCargoOnLoad: number;
  destination: string;
  pickupLocation: string;
  dropoffLocation: string;
  driverName: string;
  loadPlanAssignmentType: number;
  cargoLoadAssignmentStatusId: number;
  cargoLoadAssignmentStatus: string;
  cargoLoadAssignmentId: number;
  assignedTo: string;
  assignedDate: Date;
  loadCargoValue: number;
  transporterId: number;
}

export enum LoadType {
  COLLECTION = 1,
  DISPATCH = 2
}

export interface FilesReadyForDelivery{
  dbnNumber: string
  cargoDescription: string   
  route: string
  currentLocation: string
  fullName: string 
  borderDutiesPaid: string
  borderDutiesUploaded: string
  cif: string
  amountDue:number 
  paidDate: string
  finalDestination: string 
  clearingInvoice: string   
  chassisNo: string 
  flood: string 
  zarAmount: number
  dispatchCargoLoadExists: number
  daysSinceReady: number;
  loadName: string;
}

export interface PrePlanLoadInfo{
  loadName: string;
  estimatedDeparture: Date;
  driverName: string;
  transporterName: string
  transporterId: number
  truckInfo: string
  trailerInfo: string
}
export interface CargoForLoadPlanner {
  id: number;
  fileRowItemId: number;
  description: string;
}

export interface FileLoads {
  id: number;
  name: string;
}

export interface FileRowItemDescriptonAndId {
  fileRowId: number;
  dbnNumber: string;
  description: string;
  routeStatus: string;
  zarAmount: number;
}

export interface LocationType {
  id: number;
  description: string;
}

export interface field {
  key: string,
  header: string,
  type: Type,
  identical?: boolean,
  width: string;
}

export interface FilesWithIssues {
  dbnNumber: string;
  message: string;
  fileIssueType: FileIssueType;
}

export interface BorderPost {
  id?: number;
  locationId: number;
  name: string;
  exitBorderPost?: string;
  defaultCIFDestination?: string;
  borderPostStatusId?: BorderPostStatus;
  borderPostStatus?: string;
}