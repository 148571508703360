import { Dropdown } from './../../interfaces/global.interfaces';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { CompanyStaffMembers, LegalEntity, LegalEntityCompanyTypes, LegalEntityRoles, UserGroups } from '../../interfaces/legal-entity-interfaces';
import { HttpResponse } from '../../interfaces/result-interfaces';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";

@Injectable({
  providedIn: 'root'
})
export class LegalEntityApiService {

  private serverUrl = `${this.config.getServerUrl()}api/LegalEntity/`;

  constructor(
    private config: ConfigService,
    private router: Router,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  async getLegalEntityById(contactId: number) : Promise<LegalEntity> {
    try {
      var url = `${this.serverUrl}GetLegalEntityById?LegalEntityId=${contactId}`;
      const LegalEntity = await this.webApi.post(null, url, null);
      if (LegalEntity.errorCode == 0) {
        return LegalEntity.result;
      } 
      return undefined;
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetLegalEntityRoles() : Promise<LegalEntityRoles[]> {
    try {
      var url = `${this.serverUrl}GetLegalEntityRoles`;
      const types = await this.webApi.get(null, url);
      if (types.errorCode == 0) {
        return types.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async saveOrUpdateLegalEntity(payload: any) : Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}SaveOrUpdateLegalEntity`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCompanyTypes() : Promise<LegalEntityCompanyTypes[]> {
    try {
      var url = `${this.serverUrl}GetCompanyTypes`;
      const types = await this.webApi.get(null, url);
      if (types.errorCode == 0) {
        return types.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkForDuplicateEmailAddresses(legalEntityId: any) : Promise<Boolean> {
    try {
      var url = `${this.serverUrl}checkForDuplicateEmailAddresses?LegalEntityId=${legalEntityId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getEmailsByLegalEntityId(contactId: any) : Promise<any> { 
    try {
      const url = `${this.serverUrl}getEmailsByLegalEntity?LegalEntityId=${contactId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async convertPersonToCompany(legalEntityId: number, newCompanyName: string) : Promise<Boolean> {
    try {
      var url = `${this.serverUrl}convertPersonToCompany?LegalEntityId=${legalEntityId}&NewName=${newCompanyName}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async updateSystemUser(legalEntityId: number, password: string, accountStatus: boolean) : Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}UpdateSystemUser?legalEntityId=${legalEntityId}&password=${password}&status=${accountStatus}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async saveSystemUser(legalEntityId: number, password: string, accountStatus: boolean, username: string) : Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}SaveSystemUser?legalEntityId=${legalEntityId}&password=${password}status=${accountStatus}&username=${username}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async hasActiveUserAccount(legalEntityId: number): Promise<number> {
    try {
      var url = `${this.serverUrl}HasActiveUserAccount?legalEntityId=${legalEntityId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addCompanyPerson(companyId: number, personId: number, roleId: number) : Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}AddCompanyPerson?companyId=${companyId}&personId=${personId}&roleId=${roleId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCompanyStaff(companyId: number) : Promise<CompanyStaffMembers[]> {
    try {
      var url = `${this.serverUrl}GetCompanyStaff?companyId=${companyId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async removeStaffMemberFromCompany(companyId: number, legalEntityId: number) : Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}RemoveStaffMemberFromCompany?companyId=${companyId}&legalEntityId=${legalEntityId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async editLegalEntityCompanyRoles(payload: any, companyId: number, legalEntityId: number) : Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}EditLegalEntityCompanyRoles?companyId=${companyId}&legalEntityId=${legalEntityId}`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getStaffMemberUnassignedPermissions(legalEntityId: number): Promise<UserGroups[]> {
    try {
      var url = `${this.serverUrl}getStaffMemberUnassignedPermissions?legalEntityId=${legalEntityId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getStaffMemberPermissions(legalEntityId: number): Promise<UserGroups[]> {
    try {
      var url = `${this.serverUrl}getStaffMemberPermissions?legalEntityId=${legalEntityId}`;
      const retval = await this.webApi.post(null, url, null);
      if (retval.errorCode == 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async addPermission(legalEntityId: number, groupId: number): Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}AddPermission?legalEntityId=${legalEntityId}&groupId=${groupId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }
  
  async removePermission(legalEntityId: number, groupId: number): Promise<HttpResponse> {
    try {
      var url = `${this.serverUrl}RemovePermission?legalEntityId=${legalEntityId}&groupId=${groupId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getClearingAgents(): Promise<HttpResponse> {
    try {
      const url = `${this.serverUrl}getClearingAgents`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getClearingAgentStaffEmailAddresses(legalEntityId: any): Promise<Dropdown[]> {
    try {
      const url = `${this.serverUrl}getClearingAgentStaffEmailAddresses?LegalEntityId=${legalEntityId}`;
      const retval = await this.webApi.get(null, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async getCountryCodes(search: string): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetCountryCodes?search=${search}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}
