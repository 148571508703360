<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <p-panel [style]="{'height':'120px', 'margin-bottom':'10px'}">
      <p-header>
        Filter
      </p-header>
      <div class="ui-g-12">
        <span class="ui-g-4 ui-float-label">
          <p-calendar [(ngModel)]="startDate" dateFormat="yy/mm/dd" [style]="{'width': '100%'}">
          </p-calendar>
          <label class="float-label" for="float-input">Start Date *</label>
        </span>
        <span class="ui-g-4 ui-float-label">
          <p-calendar [(ngModel)]="endDate" dateFormat="yy/mm/dd" [style]="{'width': '100%'}">
          </p-calendar>
          <label class="float-label" for="float-input">End Date *</label>
        </span>
        <div class="ui-g-4">
          <button *ngIf="!showSpinner" pButton type="button" label="Search" class="p-button-info width-100"
            [disabled]="!startDate || !endDate" (click)="getBFJCIFRates()" style="color: coral;"></button>
          <button *ngIf="showSpinner" pRipple class="p-button-info width-100" pButton type="button" disabled>
            <i class="pi pi-spin pi-spinner font-size-2em"></i>
          </button>
        </div>
      </div>
    </p-panel>
    <p-panel>
      <p-header>
        Upload BFJ CIF
      </p-header>
      <p-progressSpinner *ngIf="showUploadSpinner" [style]="{width: '40px', height: '40px', display: 'flex'}"
        strokeWidth="6" fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>
      <p-fileUpload id="upload" name="file[]" [url]="apiUrl" [headers]="headers" accept="{{ uploadMimeTypes }}"
        (onUpload)="onUpload($event)" (onBeforeUpload)="onBeforeUpload()" (onError)="onError()">
        <ng-template pTemplate="content">
          <li *ngFor="let file of uploadedFiles">{{file.name}}</li>
        </ng-template>
      </p-fileUpload>
      <div *ngIf="bfjCIFImportIssues && bfjCIFImportIssues.length !== 0 && !showUploadSpinner" class="errors">
        <dynamic-table-basic [data]="bfjCIFImportIssues" title="Errors" [paginator]="true">
        </dynamic-table-basic>
        <label>*Please note.
          Files with no issues have been imported.
        </label>
      </div>
    </p-panel>
  </div>
  <div class="load-table ui-g-12" *ngIf="filterRates">
    <dynamic-table-basic [data]="filterRates" [title]="'BFJ CIF Rates'" [paginator]="true" [export]="true"
      [exportFileName]="'BFJ CIF - No Exceptions ' + startDate + ' ' + fromDate">
    </dynamic-table-basic>
  </div>
  <div class="load-table ui-g-12" *ngIf="filterRatesExceptions">
    <dynamic-table-basic [data]="filterRatesExceptions" [title]="'BFJ CIF Rates - Exceptions'" [paginator]="true"
      [export]="true" [exportFileName]="'BFJ CIF - Exceptions ' +  startDate + '  ' +  endDate">
    </dynamic-table-basic>
  </div>
</div>