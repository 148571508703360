
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpResponse } from '../../interfaces/result-interfaces';
import { ConfigService } from "../config.service";
import { LogBase } from "../logger.service";
import { WebApi2Service } from "../web.api2.service";

@Injectable({
  providedIn: 'root'
})
export class HandoverApiService {

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/handover/getHandoverFileDetails
  // Controller Name: HandoverController.cs
  // Proc Name: pr_GetWarehouses
  async getHandoverFileDetails(fileId: any): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + "api/handover/getHandoverFileDetails?FileId=" + fileId;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
  }

}