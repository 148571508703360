
import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { LogBase } from '../logger.service';
import { WebApi2Service } from '../web.api2.service';
import { HttpResponse } from '../../interfaces/result-interfaces';
import { FilePreferences } from '../../interfaces/file.preference.interface';

@Injectable({
  providedIn: 'root'
})
export class FilePreferenceApiService {
  private serverUrl = `${this.config.getServerUrl()}api/FilePreference/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/filter/GetManageFilePreferenceTypes
  // Controller Name: FilePreferenceController.cs
  // Proc Name: pr_getManageFilePreferenceTypes
  public async GetManageFilePreferenceTypes(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetManageFilePreferenceTypes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/GetFilePreferenceTypes
  // Controller Name: FilePreferenceController.cs
  // Proc Name: pr_GetFilePreferenceTypes
  public async GetFilePreferenceTypes(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetFilePreferenceTypes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/GetFilesPreferences
  // Controller Name: FilePreferenceController.cs
  // Proc Name: pr_GetFilesPreferences
  public async GetFilesPreferences(fileIds: number[]): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetFilesPreferences`;
      return await this.webApi.post(null, url, fileIds as any);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/GetFilePreferenceHistory
  // Controller Name: FilePreferenceController.cs
  // Proc Name: pr_GetFilePreferenceHistory
  public async GetFilePreferenceHistory(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetFilePreferenceHistory?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/filter/SaveFilesPreferences
  // Controller Name: FilePreferenceController.cs
  public async SaveFilesPreferences(files: FilePreferences[]): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}SaveFilesPreferences`;
      return await this.webApi.post(null, url, files as any);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

}