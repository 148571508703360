import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { MessageService, Table, TabView } from 'primeng';
import * as _ from 'lodash';
import { FilterDataService } from './filter-search-data.service';
import { TableColumns } from '../../interfaces/global.interfaces';
import { maxWidthOfColumnInfo } from '../on-demand/any-view-table/dynamic-table/dynamic-table.component';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.css']
})

export class FilterSearchComponent implements OnInit {

  @ViewChild("dt", { static: false }) table: Table;
  @ViewChild(TabView) tabView: TabView;
  @Input('ruleSetId') ruleSetId: number = 0;

  c1: string = '';
  c2: string = '';
  c3: string = '';
  c4: string = '';
  c5: string = '';

  cols: TableColumns[] = [
    { field: "dbnNumber", header: "DBN Number" },
    { field: "cargoDescription", header: "Cargo Description" },
    { field: "vesselName", header: "Vessel Name" },
    { field: "voyageNumber", header: "Voyage Number" },
    { field: "route", header: "route" },
    { field: "currentlyAt", header: "Currently At" },
    { field: "borderDutiesPaid", header: "Border Duties Paid" },
    { field: "borderDutiesUploaded", header: "Border Duties Uploaded" },
    { field: "cif", header: "CIF/Non CIF" },
    { field: "amountDue", header: "Ammount Due" },
    { field: "fileStatus", header: "File Status" }
  ];

  showSpinnerForTab: boolean = false;
  renderTable: boolean = false;

  constructor(
    private messageService: MessageService,
    public data: FilterDataService,
    private cd: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    if (!this.data.clearingTypes || this.data.clearingTypes.length == 0) {
      this.showSpinnerForTab = true;
      await this.data.filterGetClearingStatusTypes();
      this.showSpinnerForTab = false;
    }
  }

  /* #File Ids - Start */
  onAddFileId(event: any): void {
    if (!Number(event.value) || event.value >= 2147483647 || event.value.includes('e') || event.value.includes('.') || event.value <= 0) {
      this.data.fileIds.pop();
    }
  }
  /* #File Ids - End */


  /* #Actions - Start */
  public viewFile(fileId: number) {
    window.open(`file-view?query=${fileId}`);
  }
  /* #Actions - End */


  /* #Component - Start */
  async onTabSelect(tabIndex: number): Promise<void> {
    this.data.selectedTabIndex = tabIndex;
    switch (this.tabView.tabs[tabIndex].header) {
      case 'Shipping':
        if (!this.data.vessels || this.data.vessels.length == 0) {
          this.showSpinnerForTab = true;
          await this.data.getVessels();
          this.showSpinnerForTab = false;
        }
        break;

      case 'Routes':
        if (!this.data.masterRoutes || this.data.masterRoutes.length == 0) {
          this.showSpinnerForTab = true;
          await this.data.getFileMasterRoutes();
          this.showSpinnerForTab = false;
        }
        break;

      case 'Storage Point':
        if (!this.data.storagePoints || this.data.storagePoints.length == 0) {
          this.showSpinnerForTab = true;
          await this.data.getStoragePoints();
          this.showSpinnerForTab = false;
        }
        break;

      case 'Current Location':
        if (!this.data.locationTypes || this.data.locationTypes.length == 0) {
          this.showSpinnerForTab = true;
          await this.data.getLocationTypes();
          this.showSpinnerForTab = false;
        }
        break;
    }
  }

  sortFilteredTable(event: any): void {
    const data = event.data;
    const sortByField = event.field;
    const order = event.order;
    //asc
    if (order == 1) {
      if (sortByField == 'selectedFiles' && this.data.selectedFiles && this.data.selectedFiles.length != 0) {
        this.sortBySelectedFiles(order);
      } else {
        this.data.filteredFiles = _.orderBy(data, [sortByField], ['asc']);
      }
    }
    //desc
    else if (order == -1) {
      if (sortByField == 'selectedFiles' && this.data.selectedFiles && this.data.selectedFiles.length != 0) {
        this.sortBySelectedFiles(order);
      } else {
        this.data.filteredFiles = _.orderBy(data, [sortByField], ['desc']);
      }
    }
  }

  sortBySelectedFiles(order: number): void {
    for (var item of this.data.selectedFiles) {
      let selectedItem = this.data.filteredFiles.find(x => x.fileId == item.fileId);
      if (selectedItem) {
        let index = this.data.filteredFiles.indexOf(selectedItem);
        if (order == 1) {
          this.data.filteredFiles.splice(index, 1);
          this.data.filteredFiles.unshift(item);
        } else if (order == -1) {
          this.data.filteredFiles.splice(index, 1);
          this.data.filteredFiles.push(item);
        }
      }
    }
  }

  filterColumn(event: any, field: string, matchmode: string) {
    if (event.value) {
      this.table.filter(event.value.label, field, matchmode);
    } else {
      this.table.filter('', field, 'any');
    }
  }

  calcwidth() {
    for (var c of this.cols) {
      c.width = maxWidthOfColumnInfo(c.field, this.data.filteredFiles);
    }
    this.renderTable = true;
  }

  async filterFiles(methodName: string) {
    this.resetTable();
    this.renderTable = false;
    await this.data[methodName](this.ruleSetId);
    this.calcwidth();
  }

  resetTable() {
    if (!this.table) return;
    this.table.reset();
    this.table.clearState();
    this.c1 = '';
    this.c2 = '';
    this.c3 = '';
    this.c4 = '';
    this.c5 = '';
    this.table.sortOrder = 0;
    this.table.sortField = '';
  }

  detechChanges() { this.cd.detectChanges(); }
  onFailedFilesClose() { this.data.failedFiles = []; this.data.errorDialogTitle = ''; }
  toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
  /* #Component - End */
}