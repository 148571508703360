import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../../services/api/auth/authentication.service';
import { Router } from '@angular/router';
import { LogBase } from './../../services/logger.service';
import { MessageService } from 'primeng/api';
import { ApiService } from './../../services/api.service';
import { Title } from "@angular/platform-browser";
import { version } from '../../../../package.json';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  display: boolean = false;
  update: boolean = false;
  loginSpinner: boolean = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private log: LogBase,
    private messageService: MessageService,
    private api: ApiService,
    private titleService: Title,

  ) {
    this.titleService.setTitle("AVECS LOGIN");
  }

  async ngOnInit() {
    try {
      var uptodate = await this.api.checkCurrentAppVersion(version);
      if (uptodate && uptodate == true) this.display = true;
      else this.update = true;
    } catch (error) {
      this.log.error(error)
    }
  }

  async login() {
    this.loginSpinner = true;
    try {
      let loginResponse = await this.auth.authenticateLogin(this.loginForm.value.username, this.loginForm.value.password);
      if (loginResponse && loginResponse.token) {
        this.router.navigate(['/task-list']);
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Either your UserName or Password are incorrect'
        });
      }
    } catch (error) {
      this.log.error(error);
      console.error(error);
    }
    this.loginSpinner = false;
  }
}