import { Component, Input, OnInit } from '@angular/core';
import { FileApiService } from '../../services/api/file-api.service';

@Component({
  selector: 'file-number',
  templateUrl: './file-number.component.html',
  styleUrls: ['./file-number.component.scss']
})
export class FileNumberComponent implements OnInit {

  @Input() fileNumber: string
  copyTxt: string = "Copy";

  constructor(
    private fileApiService: FileApiService
  ) { }

  async ngOnInit() {
    //if the file number being passed in doesn't have the '/'
    //file being passed in is the file id NOT the dbn number
    //go get the dbn number
    if (!this.fileNumber.toString().includes('/')) {
      let retval = await this.fileApiService.getDBNNumber(+this.fileNumber);
      if (retval && retval.errorCode == 0) {
        this.fileNumber = retval.result;
      }
    }
  }

  clipBoard(str: string) {
    if (str.includes("/")) str = str.split("/")[1];
    navigator.clipboard.writeText(str);
    this.copyTxt = "Copied";
    setTimeout(() => this.copyTxt = "Copy", 1000);
  }
  fileType(number) {
    let prefix = number.split('/')[0];
    return isNaN(prefix) ? prefix.toLowerCase() : "dbn";
  }
}
