import { Injectable } from '@angular/core';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';
import { InvoiceType } from '../../../enums';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApiService {

  private serverUrl = `${this.config.getServerUrl()}api/invoice/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  //Endpoint: api/invoice/createInvoice
  //Controller: InvoiceController.cs
  //Proc Name: Multiple Procs Called
  public async CreateInvoice(payload: any, emailInvoicee: boolean): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}createInvoice?emailInvoicee=${emailInvoicee}`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/getInvoiceByInvoiceId
  //Controller: InvoiceController.cs
  //Proc Name: Multiple Procs Called
  public async GetInvoiceByInvoiceId(id: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getInvoiceByInvoiceId?invoiceId=${id}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/getInvoiceSetUpData
  //Controller: InvoiceController.cs
  //Proc Name: Multiple Procs Called
  public async GetInvoiceSetUpData(fileId: number, invoiceType: number = 0): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getInvoiceSetUpData?fileId=${fileId}&invoiceType=${invoiceType}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/editInvoice
  //Controller: InvoiceController.cs
  //Proc Name: Multiple Procs Called
  public async EditInvoice(payload: any, emailInvoicee: boolean): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}editInvoice?emailInvoicee=${emailInvoicee}`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/getLineItemsForFileId
  //Controller: InvoiceController.cs
  //Proc Name: Multiple Procs Called
  public async GetLineItemsForFileId(fileId: number, currencyId: number, isAdhoc: boolean): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getLineItemsForFileId?fileId=${fileId}&currencyId=${currencyId}&isAdhoc=${isAdhoc}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/getLineItemsForStorageInvoice
  //Controller: InvoiceController.cs
  //Proc Name : pr_GetStorageInvoiceLineItems
  public async GetStorageLineItems(WarehouseLocationId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}getLineItemsForStorageInvoice?WarehouseLocationId=${WarehouseLocationId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/GetFinanceSummary
  //Controller: InvoiceController.cs
  //Proc Name: Multiplie Procs Called
  public async GetFinanceSummary(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetFinanceSummary?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/cancelInvoice
  //Controller: InvoiceController.cs
  //Proc Name: Multiplie Procs Called
  public async CancelInvoice(fileId: number, invoiceId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}cancelInvoice?fileId=${fileId}&invoiceId=${invoiceId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/isThereAnActiveInvoiceByFileIdAndInvoiceType
  //Controller: InvoiceController.cs
  //Proc Name: pr_GetInvoicesByGenericIdAndInvoiceType
  public async IsThereAnActiveInvoiceByFileIdAndInvoiceType(fileId: number, invoiceType: InvoiceType): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}isThereAnActiveInvoiceByFileIdAndInvoiceType?fileId=${fileId}&invoiceType=${invoiceType}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  //Endpoint: api/invoice/manualGenerateStorageInvoice
  //Controller: InvoiceController.cs
  //Proc Name: 
  public async ManualGenerateStorageInvoice(fileId: number, daysToChargeBuffer: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}manualGenerateStorageInvoice?fileId=${fileId}&daysToChargeBuffer=${daysToChargeBuffer}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}