import {ChangeDetectorRef, Component,NgZone,OnInit,ViewChild} from '@angular/core';
import {FullCalendar} from 'primeng/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {LogBase} from './../../services/logger.service';
import {Api2Service} from '../../services/api2.service';
import { MessageService,Tooltip} from 'primeng';
import { ShipArrivalVisual,ShipArrivalVisualFiles, VehicleVesselVoyage, VesselCalendarNotes, vesselNotesVesselNames, vesselNotesVoyageNumbers, FileStatusCount} from '../../interfaces/global.interfaces';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { ApiService } from '../../services/api.service';
import { start } from 'repl';


document.addEventListener('DOMContentLoaded', function() {
  let calendarEl: HTMLElement = document.getElementById('fc'); 
})

@Component({
  selector: 'ship-arrivals',
  templateUrl: './ship-arrivals.component.html',
  styleUrls: ['./ship-arrivals.component.css'],
})

export class ShipArrivalsComponent implements OnInit {
  VesselNotes: VesselCalendarNotes[];
  vesselNames: vesselNotesVesselNames[];
  note: VesselCalendarNotes[];
  voyageNumber: vesselNotesVoyageNumbers[];
  clonedNotes: {[s: string]: VesselCalendarNotes} = {};
  events: ShipArrivalVisual[];
  vehicleVessel: VehicleVesselVoyage[];
  today = moment();
  shipArrival: ShipArrivalVisualFiles[];
  dateValue: any;
  BeginDate: any;
  EndDate: any;
  showSpinner: boolean = false;
  calendar: any;
  nextListener: any;
  prevListener: any;
  currentDate: any;
  newNote: boolean;
  emptyValue: any;
  checkedColumns = {};
  showMonth: any;
  cols: any[];
  noteId: number;
  noteData: any;
  eventVesselName: string;
  eventVoyageNumber: any;
  eventRECount: number;
  eventCLCount: number;
  eventPHCount: number;
  displayNote: boolean = false;
  SelectedVessel: vesselNotesVesselNames;
  SelectedVoyage: vesselNotesVoyageNumbers;
  SelectedTranshipVessel: vesselNotesVesselNames;
  SelectedTranshipVoyage: vesselNotesVoyageNumbers;
  transVesselId: any;
  transVoyageId: any;

  @ViewChild('fc') fc: any;
  @ViewChild('dt') table: Table;
  
  constructor(
    private log: LogBase,
    private api: Api2Service,
    private api1: ApiService,
    private messageService: MessageService,
    public cd: ChangeDetectorRef,
    private ngZone: NgZone
    ) {}

  //Targets Full Calendar options that css can't
  options = {
    eventClick:async(arg) => {
      this.eventVesselName = arg.event.extendedProps.vesselName;
      this.eventVoyageNumber = arg.event.extendedProps.voyageNumber;
      this.eventRECount = arg.event.extendedProps.reCount;
      this.eventCLCount = arg.event.extendedProps.clCount;
      this.eventPHCount = arg.event.extendedProps.phCount;
      this.table.filterGlobal(this.eventVesselName, "contains");
      this.table.sortField;
      this.SelectedVessel = this.vesselNames.find(x => x.vesselName == this.eventVesselName);
      await  this.getVoyageNumbers();
      this.SelectedVoyage= this.voyageNumber.find(x => x.Name == this.eventVoyageNumber);
    },
    eventMouseEnter: (arg) => {
      var tooltip = new Tooltip(arg.el, this.ngZone 
        );
        tooltip.activate;
    },
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    contentHeight: () => {
      console.log(screen.width);
      if (screen.width < 1920) {
        return 430
      } else {
        return 550
      }
    },
    //headerToolbar is the set button (today, prevMpnth, nextMonth)
    header: { 
      right: 'prevYear, prev, today, next, nextYear'
    },
    buttonText: {
      today: 'Today',
      prev: "Prev Month",
      prevYear: "Prev Year",
      next: "Next Month",
      nextYear: "Next Year"
    },
    buttonTitle:{
      prev: "Previous Month"
    }, 
    buttonIcons: {},
    displayEventTime: false,
    editable: true
  }

  async ngOnInit() {
    await this.getNumberOfVehicles();
    await this.getVesselNotes();
    await this.getVesselsCurrentMonth();
    await this.GetVoyageNumbersforNotes();
    await this.GetListOfVesselsForNotes();
    
    this.cols = [{
        field: 'name',
        header: 'Name'
      },
      {
        field: 'vessel',
        header: 'Vessel'
      },
      {
        field: 'transhippedVessel',
        header: 'Transhipped Vessel'
      },
      {
        field: 'remarks',
        header: 'Remarks'
      },
      {
        field: 'notes',
        header: 'Notes'
      }
    ]
  }

  getFileCountForCurrentMonth() {
    const files = this.events.filter(x => moment(this.fc.calendar.view.title, 'MMMM YYYY').format('YYYY/MM') == moment(x.start).format('YYYY/MM'));
    let totalDBN = 0;
    let totalRE = 0;
    let totalPH = 0;
    for (var file of files) {
      totalDBN += file.clCount;
      totalRE += file.reCount;
      totalPH += file.phCount;
    }
    
    return {
      totalDBN,totalRE,totalPH
    }
  }
  

  async getVesselArrivalWithFiles() {
    this.showSpinner = true;
    try {
      this.fc.calendar.gotoDate(this.BeginDate);
      var startDate = moment(this.BeginDate).format('yy/MM/DD');
      let arrival = await this.api.getVesselArrivalVisual(startDate, this.EndDate);
      if (arrival) {
        this.events = arrival;
      } else {
        this.toastMessage('error', 'Failed to get ship arrival data and file counts', '')
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get ship arrival data and file counts', '');
      this.log.error(error)
    }
    this.showSpinner = false;
    this.SelectedVoyage = this.voyageNumber.find(x => x.Name == this.eventVoyageNumber);
  }

  SelectEndDate() {
    this.EndDate = moment(this.EndDate).format('yy/MM/DD');
  }

  //Display current months calendar visuals
  async getVesselsCurrentMonth() {
    this.showSpinner = true;
    let monthlyArrival = await this.api.getCurrentVesselArrivalVisual();
    if (monthlyArrival) {
      this.events = monthlyArrival;
      console.log(this.events);
    }
    this.showSpinner = false;
  }

  async getNumberOfVehicles(){
    let numbervehicle = await this.api.getCurrentNumberVehiclePerVoyage();
    if(numbervehicle){
      this.vehicleVessel = numbervehicle;
    }
  }

  async getNumberOfVehiclesDateRange(startDate, EndDate) {
    try {
      let vehiclesDateRange = await this.api.getCurrentNumberVehiclePerVoyageDateRange(startDate, EndDate);
    if (vehiclesDateRange) {
        this.vehicleVessel = vehiclesDateRange;
      } else {
        this.toastMessage('error', 'Failed to get ship arrival data and file counts', '')
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get ship arrival data and file counts', '');
      this.log.error(error)
    }
  }
  //Get vessel notes
  async getVesselNotes() {
    let Notes = await this.api.getVesselCalendarNotes();
    if (Notes) {
      this.VesselNotes = Notes;
    }
  }

  async GetListOfVesselsForNotes(){
    let vesNames = await this.api.GetListOfVesselsForNotes();
    if(vesNames){
      this.vesselNames = vesNames;
    }
  }
  async GetVoyageNumbersforNotes(){
    let voyNumbers = await this.api.GetVoyageNumbersforNotes();
    if(voyNumbers){
      this.voyageNumber = voyNumbers;
    }
  }
 
  async addNote() {
    this.displayNote = true;
  }
  async refreshNotes(){
    this.table.reset();
    this.eventVesselName = "";
    this.eventVoyageNumber = "";
    this.eventRECount = null;
    this.eventCLCount = null;
    this.eventPHCount = null;
  }
  //Update vessel notes
  async updateVesselNotes() {
    this.showSpinner = true;
    let payload = {
      vessel: this.SelectedVessel, 
      voyage: this.SelectedVoyage,
      notes: this.noteData,
      transhippedVesselId: this.SelectedTranshipVessel  ? this.SelectedTranshipVessel.vesselId : null,
    }
    try {
      var retval = await this.api.updateVesselNotes(payload);
      if (retval) {
        this.messageService.add({severity:'success', summary:'Success', detail:'Note has been added'});
        this.getVesselNotes();
      } else {
        this.messageService.add({severity:'error', summary:'Success', detail:'Failed to update notes'});
      }
    } catch (ex) {}
    this.showSpinner = false;
    this.displayNote = false;
    this.SelectedVessel = null;
    this.SelectedVoyage = null;
    this.SelectedTranshipVessel = null;
    this.noteData = null;
  }

  async getVoyageNumbers(){
    this.voyageNumber = await this.api1.getVoyagesByVesselId(this.SelectedVessel.vesselId);  
  }

  onRowEditInit(VesselNotes: VesselCalendarNotes) {
    this.clonedNotes[VesselNotes.createdBy] = {
      ...VesselNotes
    };
  }

  onRowEditSave(VesselNotes: VesselCalendarNotes) {
    delete this.clonedNotes[VesselNotes.id];
  }

  onRowEditCancel(VesselNotes: VesselCalendarNotes, index: number) {
    this.note[index] = this.clonedNotes[VesselNotes.id];
    delete this.clonedNotes[VesselNotes.id];
  }

  newRow() {
    return {
      id: 0,
      name: '',
      vessel: '',
      transhipedVessel: '',
      remarks: '',
      notes: ''
    };
  }

  async SetVesselNotesToInactive(vesselNoteId) {
    this.showSpinner = true;
    this.noteId = vesselNoteId;
    try {
      var retval = await this.api.SetVesselNotesToInactive(this.noteId);
      if (retval) {
        this.toastMessage('success', 'Success', 'Costing Row Updated')
      } else {
        this.toastMessage('error', 'Error', 'Failed to update costing row')
      }
    } catch (ex) {}
    this.getVesselNotes();
    this.showSpinner = false;
  }
  
  onDateCreated(value) {
    this.table.filter(this.formatDate(value).toString(), 'created', 'contains')
  }

  formatDate(date) {
    return moment(date).format('yy/MM/DD') //ensure DD/MM to get number not day(Tuesday)
  }

  toastMessage(severity, summary, detail) {
    this.messageService.add({
      summary: summary,
      detail: detail
    });
  }
}