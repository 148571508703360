import { RoutesApiService } from './../../../../services/api/routes-api.service';
import { CheckBorderPost } from './../../../../interfaces/user-tasks/file-owner-user-tasks';
import { LogBase } from './../../../../services/logger.service';
import { FileOwnerUserTaskApiService } from '../../../../services/api/user-tasks/file-owner-user-task-api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ActiveRoutes } from '../../../../interfaces/routes-interfaces';

@Component({
  selector: 'app-check-border-post',
  templateUrl: './check-border-post.component.html',
  styleUrls: ['./check-border-post.component.scss']
})
export class CheckBorderPostComponent implements OnInit {

  public fileId: number = 0;
  public userTask: CheckBorderPost;
  public routes: ActiveRoutes[] = [];
  public selectedRoute: ActiveRoutes;

  public postSpinner: boolean = false;
  public showSpinner: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private log: LogBase,
    private routesApiService: RoutesApiService,
    private fileOwnerUserTaskApi: FileOwnerUserTaskApiService
  ) { }

  public async ngOnInit() {
    this.route.params.subscribe(async params => { 
      this.showSpinner = true;
      this.fileId = params['fileId'];
      if (!this.fileId) {
        this.toastMessage('error', 'Failed to get the file. Please contact support.', '');
      } else {
        await this.GetCheckBorderPostUserTask();
        await this.GetRoutes();
      }
      this.showSpinner = false;
    });
  }

  private async GetCheckBorderPostUserTask() {
    try {
      let retval = await this.fileOwnerUserTaskApi.GetCheckBorderPostUserTask(this.fileId);
      if (!retval) {
        this.toastMessage('error', 'Failed to set up page. Please contact support.', '');
      } else if (retval.errorCode != 0) {
        this.toastMessage('error', retval.errorMessage, '');
      } else {
        this.userTask = retval.result;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  private async GetRoutes() {
    try {
      let retval = await this.routesApiService.getActiveRoutes();
      if (!retval) {
        this.toastMessage('error', 'Failed to get routes. Please contact support.', '');
      } else if (retval.errorCode != 0) {
        this.toastMessage('error', retval.errorMessage, '');
      } else {
        this.routes = retval.result;
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  public async changeRoute() {
    this.postSpinner = true;
    try {

      if (!this.selectedRoute.route.includes(this.userTask.borderPostToBe)) {
        this.toastMessage('warn', 'The required border post is not in the selected route', '');
        this.postSpinner = false;
        return;
      }

      const retval = await this.fileOwnerUserTaskApi.DoCheckBorderPostUserTask(this.fileId, this.selectedRoute.routeId);
      if (!retval) {
        this.toastMessage('error', 'Failed to update route. Please contact support.', '');
      } else if (retval.errorCode != 0) {
        this.toastMessage('error', retval.errorMessage, '');
      } else {
        this.toastMessage('success', retval.errorMessage, '');
        this.router.navigate(['/task-list']);
      }
    } catch (err) {
      this.log.error(err);
      this.toastMessage('error', 'Failed to change the route. Please contact support.', '');
    }
    this.postSpinner = false;
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
