<summary-dashboard></summary-dashboard>
<p-card>
  <p-table #dt [value]="userTasks" dataKey="legalEntityId" [loading]="loading"
    [globalFilterFields]="['staffMember','driverName','destination', 'assignedTo']">
    <ng-template pTemplate="caption">
      <div class="table-toolbar">
        <div>
          <button pRipple pButton label="Add Adhoc UserTask" (click)="showDialog=true"></button>
        </div>
        <div>
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
          <input (input)="dt.filterGlobal($event.target.value, 'contains')" type="text" pInputText size="50"
            placeholder="Global Filter" style="width:auto; color: white">
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th>Image</th>
        <th pSortableColumn="staffMember">Staff Member <p-sortIcon field="staffMember"></p-sortIcon>
        <th pSortableColumn="numberOfTasks">Tasks Outstanding<p-sortIcon field="numberOfTasks"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user let-expanded="expanded">
      <tr>
        <td style="width: 3rem">
          <button type="button" pButton pRipple [pRowToggler]="user"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        </td>
        <td>
          <img [src]="'assets/images/usertasks/' + user.imagePath" width="100"
            onerror="this.onerror = null;
            this.src='https://cdn.shopify.com/s/files/1/0433/1952/5529/products/66428_POPAnimation_OnePiece_BuggyTheClown_GLAM-WEB.png?v=1674735536'" />
        </td>
        <td>
          {{user.staffMember}}
        </td>
        <td>
          <div *ngFor="let taskCount of user.numberOfTasks">
            {{ taskCount.taskName }}: {{ taskCount.count }}<br />
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-userTask>
      <tr>
        <td colspan="7">
          <div class="ui-g-12">
            <p-table #dt2 class="rowTable" [value]="userTask.userTasks" dataKey="id" [paginator]="true" [rows]="10"
              [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['dbnNumber','task']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true">
              <ng-template pTemplate="caption">
                <b>Showing {{dt2.totalRecords}} Records</b>
                <div style="text-align: right">
                  <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                  <input (input)="dt2.filterGlobal($event.target.value, 'contains')" type="text" pInputText size="50"
                    placeholder="Global Filter" style="width:auto; color: white">
                </div>
              </ng-template>
              <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="dbnNumber">DBN Number<p-sortIcon field="dbnNumber"></p-sortIcon>
        </th>
        <th pSortableColumn="assignedTo">Assigned To<p-sortIcon field="assignedTo"></p-sortIcon>
        </th>
        <th pSortableColumn="status">Task Status<p-sortIcon field="status"></p-sortIcon>
        </th>
        <th pSortableColumn="task">Task Name<p-sortIcon field="task"></p-sortIcon>
        </th>
        <th pSortableColumn="daysLeftOpen">Number of Days Outstanding<p-sortIcon field="daysLeftOpen">
          </p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tasks>
      <tr>
        <td>{{tasks.dbnNumber || 'N/A'}}</td>
        <td>{{tasks.assignedTo}}</td>
        <td>{{tasks.status}}</td>
        <td>{{tasks.task}}</td>
        <td [ngStyle]="{'color': tasks.colour}">{{tasks.daysLeftOpen}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">There are no outstanding tasks.</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
  </td>
  </tr>
  </ng-template>
  </p-table>
</p-card>

<p-dialog [(visible)]="showDialog" [draggable]="false" [resizable]="false" [modal]="true"
  (onHide)="!addingAdhocUserTask ? closeDialog() : '';" [responsive]="true"
  [style]="{'width': '28vw', 'height': 'auto'}" [positionTop]="60" [dismissableMask]="true">
  <p-header>
    Add Adhoc User Task
  </p-header>

  <div class="ui-g-12 dialog">
    <span class="ui-g-12 ui-float-label">
      <p-dropdown [options]="adhocUserTaskTypes" [(ngModel)]="selectedAdhocUserTaskType"
        (onChange)="selectedFileOption = null" [disabled]="addingAdhocUserTask" [filter]="true" [showClear]="true"
        appendTo="body" [style]="{'width': '100%'}" [autoDisplayFirst]="false">
      </p-dropdown>
      <label class="float-label" for="float-input">User Task Type *</label>
    </span>

    <ng-container *ngIf="selectedAdhocUserTaskType">
      <span class="ui-g-12 ui-float-label" *ngIf="selectedAdhocUserTaskType == userTaskType.ADHOC_USER_TASK_FOR_A_FILE">
        <p-autoComplete [(ngModel)]="selectedFileOption" [suggestions]="fileOptions"
          [disabled]="!selectedAdhocUserTaskType || addingAdhocUserTask" (completeMethod)="searchForFiles($event.query)"
          minLength="3" dataKey="value" appendTo="body" field="label" [style]="{'width': '100%'}"
          [inputStyle]="{'width':'100%'}" [disabled]="addingAdhocUserTask">
        </p-autoComplete>
        <label class="float-label" for="float-input">File *</label>
      </span>

      <span class="ui-g-12 ui-float-label">
        <p-dropdown [options]="users" [(ngModel)]="selectedUser" [filter]="true" [showClear]="true" appendTo="body"
          [disabled]="disableUserDropDown()" [autoDisplayFirst]="false" [style]="{'width':'100%'}">
        </p-dropdown>
        <label class="float-label" for="float-input">User *</label>
      </span>

      <span class="ui-g-12 ui-float-label">
        <input class="width-100" maxlength="50" [disabled]="!selectedUser || addingAdhocUserTask || (selectedAdhocUserTaskType == userTaskType.ADHOC_USER_TASK_FOR_A_FILE &&
          !selectedFileOption)" type="text" pInputText [style]="{'width':'100%'}" [(ngModel)]="userTaskHeading">
        <label class="float-label" for="float-input">User Task Heading *</label>
      </span>

      <textarea [(ngModel)]="userTaskDescription" [rows]="8" [cols]="30" [disabled]="!selectedUser || addingAdhocUserTask || !userTaskHeading ||
       (selectedAdhocUserTaskType == userTaskType.ADHOC_USER_TASK_FOR_A_FILE && !selectedFileOption)"
        placeholder="Please put a user task description" pInputTextarea></textarea>

      <button *ngIf="!addingAdhocUserTask" pRipple pButton label="Add Adhoc UserTask" (click)="addAdhocUserTask()"
        [disabled]="!selectedUser || !userTaskHeading || !userTaskDescription || (selectedAdhocUserTaskType == userTaskType.ADHOC_USER_TASK_FOR_A_FILE && !selectedFileOption)"></button>

      <button *ngIf="addingAdhocUserTask" [disabled]="true" pRipple class="p-button-info" pButton type="button">
        <i class="pi pi-spin pi-spinner font-size-2em"></i>
      </button>
    </ng-container>
  </div>
</p-dialog>