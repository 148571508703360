<summary-dashboard></summary-dashboard>

<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <p-panel [style]="{'height':'100px'}">
      <p-header>
        Filter
      </p-header>
      <div class="ui-g-1">
        <label>Month:</label>
      </div>
      <div class="ui-g-2">
        <p-calendar class="monthSelected" [(ngModel)]="selectedDate" view="month" dateFormat="mm/yy"
          [readonlyInput]="true" [yearNavigator]="true" yearRange="2000:2030">
        </p-calendar>
      </div>
      <div class="ui-g-2">
        <button *ngIf="!showSpinner" pButton type="button" class="p-button-info width-100"
          (click)="GetBackBoardDataWithMonth()" label="Search"></button>
        <button *ngIf="showSpinner" [disabled]="true" pRipple class="p-button-info width-100" pButton type="button">
          <i class="pi pi-spin pi-spinner font-size-2em"></i>
        </button>
      </div>
    </p-panel>
  </div>

  <div class="ui-g-12">
    <p-table [loading]="showSpinner" #dt [value]="backBoard" [tableStyle]="{'min-width': '50rem'}" [rows]="50"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['name',
      'vessel', 'notes']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <label>Showing data for <strong>{{currentMonthName}}</strong></label>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search vessel board" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th colspan="2">Name</th>
          <th colspan="2">Vessel</th>
          <th colspan="2">ETA</th>
          <th colspan="2">Total Number</th>
          <th colspan="2">Total DBN</th>
          <th colspan="2">Total RE</th>
          <th colspan="2">Total PH</th>
          <th colspan="2">Notes</th>
        </tr>
        <tr>
          <th colspan="2">
            <p-dropdown class="width-100" [options]="names" [filter]="true" [autoDisplayFirst]="false"
              [showClear]="true" [style]="{'width':'100%', 'min-width': 'unset'}"
              (onChange)="dt.filter($event.value, 'name', 'equals')">
            </p-dropdown>
          </th>
          <th colspan="2">
            <input class="width-100" pInputText type="text"
              (input)="dt.filter($event.target.value, 'vessel', 'contains')">
          </th>
          <th colspan="2">
            <input class="width-100" pInputText type="text" (input)="dt.filter($event.target.value, 'eta', 'contains')">
          </th>
          <th colspan="2"></th>
          <th colspan="2"></th>
          <th colspan="2"></th>
          <th colspan="2"></th>
          <th colspan="2">
            <input class="width-100" pInputText type="text"
              (input)="dt.filter($event.target.value, 'notes', 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td colspan="2">{{product.name}}</td>
          <td colspan="2">{{product.vessel}}</td>
          <td colspan="2">{{product.eta}}</td>
          <td colspan="2">{{product.totalNumber}}</td>
          <td colspan="2">{{product.totalCleared}}</td>
          <td colspan="2">{{product.totalHandover}}</td>
          <td colspan="2">{{product.totalPartialHandover}}</td>
          <td colspan="2">{{product.notes}}
            <button class="note-extend" type="button" (click)="showPositionDialog('bottom', product.notes)"
              pTooltip="View notes" tooltipPosition="left" pButton icon="far fa-eye"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Notes Extended" [(visible)]="displayPosition" [position]="position" [modal]="true"
  [style]="{width: '50vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p>{{notes}}</p>
</p-dialog>