import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { LogBase } from "../../../services/logger.service";
import { ActivatedRoute } from "@angular/router";
import { ConfigService } from "./../../../services/config.service";
import { ConfirmationService } from "primeng";
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import * as _ from "lodash";
import { MenuItem } from "primeng/api";
import { Title } from "@angular/platform-browser";
import * as moment from 'moment';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: "app-file-items",
  templateUrl: "./file-items.component.html",
  styleUrls: ["./file-items.component.css"],
  providers: [ConfirmationService]
})
export class FileItemsComponent implements OnInit {

  @Input() activeIndex: number;
  @Output() indexEventFunction = new EventEmitter<any>();
  @Output() canCreateFile = new EventEmitter();
  //@Output() canCreateFile = new EventEmitter<boolean>();

  msgs: any[] = [];
  taskname = "File Items";
  make;

  // Form
  fileItemsForms: any = [];

  //num: RegExp = /[0-9]+(\.[0-9][0-9]?)/;
  blockSpecial: RegExp = /^[^<>*!]+$/
  blockSpace: RegExp = /[^\s]/;
  ccRegex: RegExp = /[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/;
  noSpecial: RegExp = /^[^<>*!]+$/

  // Checks
  validReferenceNo: boolean = false;
  //notEmptyRefNo: boolean = false;
  validChassisNo: boolean = false;
  notEmptyChassisNo: boolean = false;
  newModel: boolean = false;

  activeNewVehicleModelFormIndex: any;

  // Options
  vehicleMakes: any;
  vehicleModels: any;
  colours: any;
  fuelTypes: any;
  transmissionTypes: any;
  descriptionOptions: any;

  // Model
  newVehicleModel: any;

  // Item Choices
  fileItemTypes: MenuItem[];

  // Payloads
  fileItemsPayload = [];
  setupOption: number;

  //savedFileItems = [];

  shipmentDetails: any
  carLimit: boolean = false;
  carCount: number = 0;

  containerList: any[];
  selectedContainer: any;

  years: any[];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private config: ConfigService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private log: LogBase,
    private router: Router,
    private titleService: Title,
    private authService: AuthenticationService

  ) {
    this.titleService.setTitle("AVECS File Items");
  }

  async ngOnInit() {
    try {
      this.yearMake();
      this.setupMenuItems();

      this.vehicleMakes = await this.api.getVehicleMakes();
      this.colours = await this.api.getColors();
      this.fuelTypes = await this.api.getFuelTypes();
      this.transmissionTypes = await this.api.getTransmissionTypes();


      let retval = await JSON.parse(localStorage.getItem('shipmentDetails'));
      if (retval) {
        this.shipmentDetails = retval;
      }

      await this.checkIfProgress();

    } catch (error) {
      this.showError("An error occured", error);
      this.log.error(error);
    }
  }


  async addContainer() {

    this.router.navigate(['/containers', 'add']);
  }


  async addModel() {

    this.router.navigate(['/manage-vehicles', 'add']);
  }


  // async searchContainersForForm(event: any) {
  //   try {
  //     var res = await this.api.searchForContainer(event);
  //     if (res) {
  //       this.containerList = res
  //     }

  //   } catch (err) {
  //     this.log.error(err);
  //   }


  // }



  async searchContainers(event: any) {
    try {
      var res = await this.api.searchForContainer(event.query);
      this.containerList = res
    } catch (err) {
      this.log.error(err);
    }
  }


  async Back() {
    try {
      this.activeIndex = 1;

      await this.saveToLocalStorageAndEmitEvent();
    } catch (err) {
      this.log.error(err);
    }
  }

  async saveToLocalStorageAndEmitEvent() {

    try {
      localStorage.setItem('pageStateRules', JSON.stringify({ activeIndex: this.activeIndex }));
      this.indexEventFunction.emit({ action: 'index changed', params: this.activeIndex })
    } catch (err) {
      this.log.error(err);
    }
  }



  checkIfProgress() {

    var fileItemsPayload = localStorage.getItem('fileItemsPayload');
    if (fileItemsPayload) {
      this.fileItemsPayload = JSON.parse(fileItemsPayload);
      if (this.fileItemsPayload) {
        for (var x = 0; x < this.fileItemsPayload.length; x++) {
          let item = this.fileItemsPayload[x];
          this.createFormForInProgressFileItem(item);
        }
      }
    }
  };

  createFormForInProgressFileItem = async (item: any) => {
    try {
      switch (item.formType) {
        case 1:
          var model = "";
          if (item.models) model = item.formData.vehicleModel;

          if (item.formData.containerString != "")
            item.formData.containerId = {
              Id: 1,
              ISOContainerGroupId: 0,
              ContainerNumber: item.formData.containerString
            };

          item.selectedContainer = item.formData.containerId;
          let vehicleForm = {
            Id: item.Id,
            form: this.fb.group({
              vehicleMake: [item.formData.vehicleMake, Validators.required],
              vehicleModel: [model, Validators.required],
              modelCode: [item.formData.modelCode],
              year: [item.formData.year.selectedYear, Validators.required],
              month: [item.formData.month],
              grade: [item.formData.grade],
              chassisNo: [item.formData.chassisNo, Validators.required],
              mileage: [
                item.formData.mileage,
                Validators.pattern(/^-?([0-9]\d*)?$/),
              ],
              colour: [item.formData.colour, Validators.required],
              fuel: [item.formData.fuel],
              transmission: [item.formData.transmission],
              length: [item.formData.length, Validators.required],
              width: [item.formData.width, Validators.required],
              height: [item.formData.height, Validators.required],
              weight: [item.formData.weight, Validators.required],
              engineNo: [item.formData.engineNo],
              containerId: [item.formData.containerId],
              containerSealNo: [item.formData.containerSealNo]
            }),
            models: item.models,
            showSaveButton: item.showSaveButton,
            notEmptyEmptyRefNo: item.notEmptyEmptyRefNo,
            validChassisNo: item.validChassisNo,
            notEmptyChassisNo: item.notEmptyChassisNo,
            active: item.active,
            unActivecss: item.unActivecss,
            formType: item.formType,
            selectedContainer: item.selectedContainer
          };

          if (!item.showSaveButton) vehicleForm.form.disable();
          this.fileItemsForms.push(vehicleForm);
          break;
        case 2:

          if (item.formData.containerString != "")
            item.formData.containerId = {
              Id: 1,
              ISOContainerGroupId: 0,
              ContainerNumber: item.formData.containerString
            };
          item.selectedContainer = item.formData.containerId;

          let engineForm = {
            Id: item.Id,
            form: this.fb.group({
              engineNo: [item.formData.engineNo, Validators.required],
              description: [item.formData.description, Validators.required],
              length: [item.formData.length, Validators.required],
              width: [item.formData.width, Validators.required],
              height: [item.formData.height, Validators.required],
              weight: [item.formData.weight, Validators.required],
              containerId: [item.formData.containerId],
              containerSealNo: [item.formData.containerSealNo]
            }),
            showSaveButton: item.showSaveButton,
            active: item.active,
            unActivecss: item.unActivecss,
            formType: item.formType,
            selectedContainer: item.selectedContainer
          };

          if (!item.showSaveButton) engineForm.form.disable();
          this.fileItemsForms.push(engineForm);
          break;
        case 3:

          if (item.formData.containerString != "")
            item.formData.containerId = {
              Id: 1,
              ISOContainerGroupId: 0,
              ContainerNumber: item.formData.containerString
            };
          item.selectedContainer = item.formData.containerId;

          if (item.formData.descriptionString != "")
            item.formData.description = {
              Id: 1,
              Name: item.formData.descriptionString,
            };

          let partForm = {
            Id: item.Id,
            form: this.fb.group({
              description: [item.formData.description, Validators.required],
              length: [item.formData.length, Validators.required],
              width: [item.formData.width, Validators.required],
              height: [item.formData.height, Validators.required],
              weight: [item.formData.weight, Validators.required],
              containerId: [item.formData.containerId],
              containerSealNo: [item.formData.containerSealNo],
              quantity: [item.formData.quantity],
            }),
            showSaveButton: item.showSaveButton,
            active: item.active,
            unActivecss: item.unActivecss,
            formType: item.formType,
            selectedContainer: item.selectedContainer
          };

          if (!item.showSaveButton) partForm.form.disable();
          this.fileItemsForms.push(partForm);
          break;
        case 4:

          if (item.formData.containerString != "")
            item.formData.containerId = {
              Id: 1,
              ISOContainerGroupId: 0,
              ContainerNumber: item.formData.containerString
            };
          item.selectedContainer = item.formData.containerId;

          if (item.formData.descriptionString != "")
            item.formData.description = {
              Id: 1,
              Name: item.formData.descriptionString,
            };
          let otherForm = {
            Id: item.Id,
            form: this.fb.group({
              description: [item.formData.description, Validators.required],
              length: [item.formData.length, Validators.required],
              width: [item.formData.width, Validators.required],
              height: [item.formData.height, Validators.required],
              weight: [item.formData.weight, Validators.required],
              containerId: [item.formData.containerId],
              containerSealNo: [item.formData.containerSealNo],
              quantity: [item.formData.quantity],
            }),
            showSaveButton: item.showSaveButton,
            active: item.active,
            unActivecss: item.unActivecss,
            formType: item.formType,
            selectedContainer: item.selectedContainer
          };

          if (!item.formData.showSaveButton) otherForm.form.disable();
          this.fileItemsForms.push(otherForm);
          break;
      }
    } catch (error) {
      this.showError("An error occured", error);
      this.log.error(error);
    }
  };

  isVehicle() {
    let res = localStorage.getItem('fileType');
    if (res) {
      var fileType = JSON.parse(res);
      if (fileType.value == 2 || fileType.value == 1 || fileType.value == 3) {
        if (this.fileItemsForms.length > 0) return false;
        else return true;
      }
    }
    return true;
  };


  setupMenuItems() {
    let res = localStorage.getItem('fileType');
    if (res) {
      var fileType = JSON.parse(res);

      if (this.carLimit) {

        if (fileType.value == 1 || fileType.value == 2 || fileType.value == 3) {
          this.setupOption = 2;
          this.fileItemTypes = [
            {
              label: "Vehicle",
              icon: "fas fa-car",
              disabled: true,
              command: () => {
                this.addNewItem(1);
              },
            },
          ];
        } else {
          this.setupOption = 1;
          this.fileItemTypes = [
            {
              label: "Vehicle",
              icon: "fas fa-car",
              disabled: true,
              command: () => {
                this.addNewItem(1);
              },
            },
            {
              label: "Engine",
              icon: "fas fa-box",
              command: () => {
                this.addNewItem(2);
              },
            },
            {
              label: "Part",
              icon: "fas fa-cogs",
              command: () => {
                this.addNewItem(3);
              },
            },
            {
              label: "Other",
              icon: "fas fa-question-circle",
              command: () => {
                this.addNewItem(4);
              },
            },
          ];
        }
      } else {

        if (fileType.value == 1 || fileType.value == 2 || fileType.value == 3) {
          this.setupOption = 2;
          this.fileItemTypes = [
            {
              label: "Vehicle",
              icon: "fas fa-car",
              disabled: false,
              command: () => {
                this.addNewItem(1);
              },
            },
          ];
        } else {
          this.setupOption = 1;
          this.fileItemTypes = [
            {
              label: "Vehicle",
              icon: "fas fa-car",
              disabled: false,
              command: () => {
                this.addNewItem(1);
              },
            },
            {
              label: "Engine",
              icon: "fas fa-box",
              command: () => {
                this.addNewItem(2);
              },
            },
            {
              label: "Part",
              icon: "fas fa-cogs",
              command: () => {
                this.addNewItem(3);
              },
            },
            {
              label: "Other",
              icon: "fas fa-question-circle",
              command: () => {
                this.addNewItem(4);
              },
            },
          ];
        }


      }
    }
  };

  yearMake() {
    this.years = [];
    let startYear = 1908;
    let endYear = moment(new Date()).year();
    let status = true;
    while (status) {
      this.years.push({ 'selectedYear': startYear });
      if (startYear == endYear) {
        status = false;
      }
      startYear++;
    }
    this.years = _.orderBy(this.years, ['selectedYear'], ['desc']);
  }

  validFileItemsForm = (form: any) => {
    let type = form.formType;
    if (type == 1) {
      var res = this.checkValidVehicleForm(form);
      return res;
    }
    if (form.form.valid) return true;
    else return false;
  };

  checkValidVehicleForm = (form: any) => {
    if (form.form.valid && form.validReferenceNo && form.validChassisNo) {
      return true;
    } else {
      return false;
    }
  };

  disableVehicleModelButton = (form: any) => {
    var formIndex = form.Id - 1;
    this.activeNewVehicleModelFormIndex = formIndex;
    var data = this.fileItemsForms[formIndex].form.get("vehicleMake").value;
    if (data) {
      return false;
    } else {
      return true;
    }
  };

  hasContainerNoInserted = (form: any) => {
    var index = _.findIndex(this.fileItemsForms, (o: any) => {
      return o.Id == form.Id;
    });
    if (index >= 0) {
      this.fileItemsForms[index].form.controls.containerSealNo.errors = {
        required: true,
      };
      this.fileItemsForms[index].form.controls.containerSealNo.status =
        "INVALID";
      this.fileItemsForms[index].form.status = "INVALID";
    }
  };

  hasNoContainerNoInserted = (form: any) => {
    var index = _.findIndex(this.fileItemsForms, (o: any) => {
      return o.Id == form.Id;
    });
    if (index >= 0) {
      this.fileItemsForms[index].form.value.containerSealNo = "";
      this.fileItemsForms[index].form.controls.containerSealNo.errors = null;
      this.fileItemsForms[index].form.controls.containerSealNo.status = "VALID";
      this.fileItemsForms[index].form.status = "VALID";
    }
  };

  containerNoCheck = (form: any) => {
    if (form) {
      let formcontainerId = form.form.value.containerId;
      if (formcontainerId !== "") {
        this.hasContainerNoInserted(form);
      } else {
        this.hasNoContainerNoInserted(form);
      }
    }
  };

  addNewItem = (type: number) => {
    var unActivecss = {};
    if (this.fileItemsForms.length > 0)
      unActivecss = { "background-color": "#D3D3D3", opacity: "0.8" };
    switch (type) {
      case 1:
        this.carCount = this.carCount + 1;
        var fileType = JSON.parse(localStorage.getItem('fileType')).Name;
        if (fileType == "FCL Quotation" || fileType == "FCL CIF") {
          if (this.carCount == 4) {
            this.carLimit = true;
          }
        } else {
          this.carLimit = true;
        }
        this.setupMenuItems();

        let vehicleForm = {
          Id: this.fileItemsForms.length + 1,
          form: this.fb.group({
            vehicleMake: ["", Validators.required],
            vehicleModel: ["", Validators.required],
            modelCode: [""],
            year: [{ selectedYear: null }, Validators.required],
            month: [""],
            grade: [""],
            chassisNo: ["", Validators.required],
            mileage: ["",],
            colour: ["", Validators.required],
            fuel: [""],
            transmission: [""],
            length: ["", Validators.required],
            width: ["", Validators.required],
            height: ["", Validators.required],
            weight: ["", Validators.required],
            engineNo: [""],
            containerId: [""],
            containerSealNo: [""]
          }),
          models: [],
          showSaveButton: true,
          notEmptyEmptyRefNo: false,
          validChassisNo: false,
          notEmptyChassisNo: false,
          active: false,
          unActivecss: unActivecss,
          formType: 1,
          selectedContainer: ''
        };

        this.fileItemsForms.push(vehicleForm);

        break;
      case 2:
        let engineForm = {
          Id: this.fileItemsForms.length + 1,
          form: this.fb.group({
            engineNo: ["", Validators.required],
            description: ["", Validators.required],
            length: ["", Validators.required],
            width: ["", Validators.required],
            height: ["", Validators.required],
            weight: ["", Validators.required],
            containerId: [""],
            containerSealNo: [""]
          }),
          showSaveButton: true,
          active: false,
          unActivecss: unActivecss,
          formType: 2,
          selectedContainer: ''
        };

        this.fileItemsForms.push(engineForm);
        break;
      case 3:
        let partForm = {
          Id: this.fileItemsForms.length + 1,
          form: this.fb.group({
            description: ["", Validators.required],
            length: ["", Validators.required],
            width: ["", Validators.required],
            height: ["", Validators.required],
            weight: ["", Validators.required],
            containerId: [""],
            containerSealNo: [""],
            quantity: [1],
          }),
          showSaveButton: true,
          active: false,
          unActivecss: unActivecss,
          formType: 3,
          selectedContainer: ''
        };

        this.fileItemsForms.push(partForm);
        break;
      case 4:
        let otherForm = {
          Id: this.fileItemsForms.length + 1,
          form: this.fb.group({
            description: ["", Validators.required],
            length: ["", Validators.required],
            width: ["", Validators.required],
            height: ["", Validators.required],
            weight: ["", Validators.required],
            containerId: [""],
            containerSealNo: [""],
            quantity: [1],
          }),
          showSaveButton: true,
          active: false,
          unActivecss: unActivecss,
          formType: 4,
          selectedContainer: ''
        };

        this.fileItemsForms.push(otherForm);
        break;
    }
  };

  activeItem = (form: any) => {
    var formIndex = _.findIndex(this.fileItemsForms, (o: any) => {
      return o.Id == form.Id;
    });

    this.fileItemsForms[formIndex].unActivecss = {};
    this.fileItemsForms[formIndex].active = true;

    for (var x = 0; x < this.fileItemsForms.length; x++) {
      if (x != formIndex) {
        this.fileItemsForms[x].unActivecss = {
          "background-color": "#D3D3D3",
          opacity: "0.8",
        };
        this.fileItemsForms[x].active = false;
      }
    }
  };



  getVehicleModels = async (event: any, form: any) => {
    try {
      form.models = await this.api.getVehicleModels(event.value.Id);
      var formId = form.Id - 1;
      // if (form.models) {
      //   this.fileItemsForms[formId].form
      //     .get("vehicleModel")
      //     .setValue(form.models[0]);
      // }
    } catch (error) {
      this.showError(
        "Error Getting Vehicle Models (getVehicleModels())",
        error
      );
      this.log.error(error);
    }
  };


  insertNewVehicleModel = async () => {
    try {
      if (this.newVehicleModel) {
        var vehicleMake = this.fileItemsForms[
          this.activeNewVehicleModelFormIndex
        ].form.get("vehicleMake").value;
        var payload = {
          VehicleModel: this.newVehicleModel,
          VehicleMakeId: vehicleMake.Id,
          PurposeCode: 2,

        };
        var res = await this.api.insertNewVehicleModel(payload);
        if (res) {
          this.newModel = false;
          this.vehicleModels = await this.api.getVehicleModels(
            this.fileItemsForms[this.activeNewVehicleModelFormIndex].form.value
              .vehicleMake.Id
          );
        } else {
          this.messageService.add(
            {
              severity: "error",
              summary: "",
              detail: "There was an error with creating a new vehicle model",
            },
          );
        }
      } else {
        this.messageService.add(
          {
            severity: "info",
            summary: "",
            detail: "Please enter a vehicle model",
          },
        );
      }
    } catch (error) {
      this.showError(
        "Error Inserting New Vehicle Model (insertNewVehicleModel())",
        error
      );
      this.log.error(error);
    }
  };

  RemoveItem = (form: any) => {

    if (form.formType == 1) {
      this.carLimit = false;
      this.setupMenuItems();

    }

    var index = _.findIndex(this.fileItemsForms, (o: any) => {
      return o.Id == form.Id;
    });
    if (index >= 0) {
      this.fileItemsForms.splice(index, 1);
    }

    for (var x = 0; x < this.fileItemsForms.length; x++) {
      let item = this.fileItemsForms[x];
      item.Id = x + 1;
    }

    var savedFileItemIndex = _.findIndex(this.fileItemsPayload, (o: any) => {
      return o.Id == form.Id;
    });

    if (savedFileItemIndex >= 0) {
      this.fileItemsPayload.splice(savedFileItemIndex, 1);
    }

    for (var x = 0; x < this.fileItemsPayload.length; x++) {
      let item = this.fileItemsPayload[x];
      item.Id = x + 1;
    }

    localStorage.setItem("fileItemsPayload", JSON.stringify(this.fileItemsPayload));

    if (this.fileItemsPayload.length == 0) {
      this.canCreateFile.emit(false);
    } else {
      this.canCreateFile.emit(true);
    }


  };



  removeFromFileItemsPayloadIfExists = (form: any) => {
    var index = _.findIndex(this.fileItemsPayload, function (o) {
      return o.Id == form.Id;
    });
    if (index >= 0) {
      this.fileItemsPayload.splice(index, 1);
    }
    var savedFileIndex = _.findIndex(this.fileItemsPayload, function (o) {
      return o.Id == form.Id;
    });
    if (savedFileIndex >= 0) {
      this.fileItemsPayload.splice(index, 1);
    }
  };

  editItem = (form: any) => {
    if (form) {
      var index = _.findIndex(this.fileItemsForms, (o: any) => {
        return o.Id == form.Id;
      });
      if (index >= 0) {
        this.fileItemsForms[index].showSaveButton = true;
        this.fileItemsForms[index].form.enable();
      }
    }
  };

  saveItem = async (form: any) => {

    try {

      var containerexists = true;

      let formId = form.Id;
      this.removeFromFileItemsPayloadIfExists(form);
      var formData = form.form.value;

      if (form.formType == 1) {
        // Vehicle
        formData = this.getColourIdFromFormData(formData);
        formData = this.getFuelIdFromFormData(formData);
        formData = this.getTransmissionIdFromFormData(formData);
        formData = this.getVehicleMakeIdFromFormData(formData);
        formData = this.getVehicleModelIdFromFormData(formData);
        formData = this.getSelectedYear(formData)
      } else {
        // Not Vehicle
        formData = this.getDescriptionFromFormData(formData);
      }

      formData = this.getContainerFromFormData(formData);

      formData.Id = formId;
      formData.type = form.formType;
      //this.fileItemsPayload.push(formData);
      form.showSaveButton = false;

      this.disableForm(form);
      this.setSavedFileItemsToInactive();

      let savedFileItem = {
        Id: form.Id,
        active: form.active,
        formType: form.formType,
        showSaveButton: form.showSaveButton,
        unActivecss: form.unActivecss,
        formData: formData,
        models: form.models ? form.models : [],
        validReferenceNo: form.validReferenceNo ? form.validReferenceNo : false,
        notEmptyEmptyRefNo: form.notEmptyEmptyRefNo
          ? form.notEmptyEmptyRefNo
          : false,
        validChassisNo: form.validChassisNo ? form.validChassisNo : false,
        notEmptyChassisNo: form.notEmptyChassisNo
          ? form.notEmptyChassisNo
          : false,
      };

      this.fileItemsPayload.push(savedFileItem);

      // this.fileItemsPayload = this.savedFileItems;

      localStorage.setItem(
        "fileItemsPayload",
        JSON.stringify(this.fileItemsPayload)
      );





      if (this.setupOption == 1) { // is Container

        if (form.form.value.containerId.ContainerNumber) {
          containerexists = await this.api.ContainerExists(form.form.value.containerId.ContainerNumber);
        } else {
          containerexists = false;
        }
      }

      if (containerexists) {
        this.canCreateFile.emit(true);

      } else {

        this.messageService.add({ severity: 'error', summary: 'The Container Number Does not Exist in the Database', detail: 'The Container Number Does not Exist in the Database' });

      }


    } catch (error) {
      this.log.error(error);
    }
  };

  setSavedFileItemsToInactive() {
    for (var x = 0; x < this.fileItemsPayload.length; x++) {
      let item = this.fileItemsPayload[x];
      item.unActivecss = { "background-color": "#D3D3D3", opacity: "0.8" };
    }
  };

  disableForm = (form: any) => {
    var index = _.findIndex(this.fileItemsForms, (o: any) => {
      return o.Id == form.Id;
    });
    this.fileItemsForms[index].form.disable();
  };

  getDescriptionFromFormData = (formData: any) => {
    if (formData.description) {
      if (formData.description.Id) {
        formData.descriptionString = formData.description.Name;
      } else {
        formData.descriptionString = formData.description;
      }
    }
    return formData;
  };

  getContainerFromFormData = (formData: any) => {
    if (formData.containerId) {
      if (formData.containerId.Id) {
        formData.containerString = formData.containerId.ContainerNumber;
      } else {
        formData.containerString = formData.ContainerNumber;
      }
    }
    return formData;
    return formData;
  };

  getColourIdFromFormData = (formData: any) => {
    if (formData.colour) {
      formData.colourId = formData.colour.Id;
    }
    return formData;
  };

  getFuelIdFromFormData = (formData: any) => {
    if (formData.fuel) {
      formData.fuelId = formData.fuel.Id;
    }
    return formData;
  };

  getTransmissionIdFromFormData = (formData: any) => {
    if (formData.transmission) {
      formData.transmissionId = formData.transmission.Id;
    }
    return formData;
  };

  getVehicleMakeIdFromFormData = (formData: any) => {
    if (formData.vehicleMake) {
      formData.vehicleMakeId = formData.vehicleMake.Id;
    }
    return formData;
  };

  getVehicleModelIdFromFormData = (formData: any) => {
    if (formData.vehicleModel) {
      formData.vehicleModelId = formData.vehicleModel.Id;
    }
    return formData;
  };
  getSelectedYear(formData) {
    if (formData.year) {
      formData.year = formData.year.selectedYear
    }
    return formData;
  }


  displayItemLabel = (form: any) => {
    let type = form.formType;
    switch (type) {
      case 1:
        return "Vehicle";
      case 2:
        return "Engine";
      case 3:
        return "Part";
      case 4:
        return "Other";
    }
  };

  searchDescriptions = async (event) => {
    try {
      this.descriptionOptions = await this.api.searchDescriptions(event.query);
    } catch (err) {
      this.log.error(err);
    }
  };

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message:
        friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;

        var userName = '';
        let _res = this.authService.getUsername
        if (_res) {
          userName = _res;
        }

        var date = new Date();
        var emailBody: string = "Component Name: " + this.taskname + "<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";

        var subject: string =
          "NovaDT Error Message - Component: " + this.taskname;
        var res = this.api.sendFreeTextEmail(
          this.config.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          -1,
          -1
        );
      },
      reject: (err) => {
        alert(err.toString());
      },
    });
  }
}
