//dt live
export const environment = {
  production: true,
  isDebugMode: false,
  loginfo: true,
  logwarning: true,
  logerror: true,
  timeZoneAdjustment: 2,
  nodeUrl: "https://docutrack.avecs.co.za:3000/",
  url: "https://docutrack.avecs.co.za:50063/",
  camundaCockpitURL: "http://docutrack.avecs.co.za:8080/camunda/",
  supportEmail: "dev@avecs.co.za",
  SAEX: 'https://saexports.co.za/',
  WA: 'https://www.avecscomms.com/',
  AvecsDeepLink: 'https://avecs.co.za/dpl/',
  AvecsFinance: 'https://avecs.co.za/finance',
  uatMessage: ''
};

//local
// export const environment = {
//   production: false,
//   isDebugMode: true,
//   loginfo: true,
//   logwarning: true,
//   logerror: true,
//   timeZoneAdjustment: 2,
//   nodeUrl: 'http://localhost:3000/',
//   url: 'http://localhost:50063/', 
//   camundaCockpitURL: 'http://localhost:8080/camunda/',
//   supportEmail: 'dev@avecs.co.za',
//   SAEX: 'http://localhost:81/',
//   WA: 'http://localhost:3000/',
//   AvecsDeepLink: 'http://localhost:4100/dpl/',
//   AvecsFinance: 'http://localhost:4100/finance', 
//   uatMessage: ''
// };

//uat
// export const environment = {
//   production: false,
//   isDebugMode: false,
//   loginfo: true,
//   logwarning: true,
//   logerror: true,
//   timeZoneAdjustment: 2,
//   nodeUrl: 'http://beta.avecs.co.za:3000/',
//   url: 'http://beta.avecs.co.za:50063/',
//   camundaCockpitURL: 'http://beta.avecs.co.za:8080/camunda/',
//   supportEmail: 'dev@avecs.co.za',
//   SAEX: 'https://saexports.co.za/',
//   WAKenya: 'http://41.78.245.238/',
//   AvecsDeepLink: 'https://avecs.co.za/dpl/',
//   AvecsFinance: 'https://avecs.co.za/finance'
// };

//uat3 - finance
// export const environment = {
//   production: false,
//   isDebugMode: false,
//   loginfo: true,
//   logwarning: true,
//   logerror: true,
//   timeZoneAdjustment: 2,
//   nodeUrl: 'https://doc.heimdall.marsserver.net/',
//   url: 'https://api.heimdall.marsserver.net/',
//   camundaCockpitURL: 'http://camunda.heimdall.marsserver.net/camunda/',
//   supportEmail: 'dev@avecs.co.za',
//   SAEX: 'http://saexports.marsserver.net/',
//   WA: 'http://whatsapp.marsserver.net/',
//   AvecsDeepLink: 'http://avecs.marsserver.net:8580/dpl/',
//   AvecsFinance: 'http://avecs.marsserver.net:8580/finance'
// };

//newuat
// export const environment = {
//   production: false,
//   isDebugMode: false,
//   loginfo: true,
//   logwarning: true,
//   logerror: true,
//   timeZoneAdjustment: 2,
//   nodeUrl: 'http://avecs.marsserver.net:3000/',
//   url: 'http://avecs.marsserver.net:50063/',
//   camundaCockpitURL: 'http://avecs.marsserver.net:8080/camunda/',
//   supportEmail: 'dev@avecs.co.za',
//   SAEX: 'http://saexports.marsserver.net/',
//   WA: 'http://whatsapp.marsserver.net/',
//   AvecsDeepLink: 'http://avecs.marsserver.net:8580/dpl/',
//   AvecsFinance: 'http://avecs.marsserver.net:8580/finance',
//   uatMessage: 'Dispatch - UAT'
// };

//dt cloud
// export const enviro//   NOT CONFIGUREDnment = {

//   production: false,
//   isDebugMode: false,
//   loginfo: true,
//   logwarning: true,
//   logerror: true,
//   timeZoneAdjustment: 2,
//   nodeUrl: 'http://41.78.247.129:3000/',
//   url: 'http://41.78.247.129:50063/',
//   camundaCockpitURL: 'http://41.78.247.129:8080/camunda/',
//   supportEmail: 'dev@avecs.co.za',
//   SAEX: 'https://saexports.co.za/',
//   WA: 'http://41.78.245.238//',
//   AvecsDeepLink: 'https://avecs.co.za/dpl/',
//   AvecsFinance: 'https://avecs.co.za/finance'
// };
