import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDataService } from '../../filter-search/filter-search-data.service';
import { MessageService } from 'primeng';
import { FilePreferenceApiService } from '../../../services/api/file-preference-api.service';
import { ManageFilePreferenceTypes, FilePreferences } from '../../../interfaces/file.preference.interface';
import { FilePreferenceType, Groups } from '../../../enums';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';

@Component({
  selector: 'file-preferences',
  templateUrl: './file-preferences.component.html',
  styleUrls: ['./file-preferences.component.scss']
})
export class FilePreferencesComponent implements OnInit {

  @Input() fileIds: number[] = [];
  public cols: ManageFilePreferenceTypes[] | null = null;
  public files: FilePreferences[] | null = null;
  public selectedColumns: ManageFilePreferenceTypes[] = [];

  public filePreferenceSearch: string = '';
  public ruleSet: number = 0;
  public showSpinner: boolean = false;
  public saveSpinner: boolean = false;
  public allFilesSelected: boolean = false;
  public isAccountsAdmin: boolean = false;
  @Output() public onLoaded: EventEmitter<void> = new EventEmitter();

  constructor(
    public filterDataService: FilterDataService,
    private authService: AuthenticationService,
    private filePreferenceApiService: FilePreferenceApiService,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    try {
      this.showSpinner = true;
      await this.getManageFilePreferenceTypes();

      if (!this.fileIds || this.fileIds.length === 0) {
        this.toastMessage('error', 'Failed to manage file preferences. Please contact support.', '');
        return;
      }

      this.isAccountsAdmin = this.authService.canEdit(Groups.ACCOUNTS_ADMIN);

      await this.getFiles();
      this.showSpinner = false;
      this.onLoaded.emit();
    } catch (error) {
      console.log(error);
    }
  }

  private async getManageFilePreferenceTypes(): Promise<void> {
    try {
      const retval = await this.filePreferenceApiService.GetManageFilePreferenceTypes();
      if (!retval) this.toastMessage('error', 'Failed to get file preference types. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.cols = retval.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get file preference types. Please contact support.', '');
    }
  }

  public async getFiles(): Promise<void> {
    this.showSpinner = true;
    try {
      const retval = await this.filePreferenceApiService.GetFilesPreferences(this.fileIds);
      if (!retval) this.toastMessage('error', 'Failed to get file(s). Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.files = retval.result;
        //when there is one file we'll just auto select all column
        if (this.fileIds.length === 1) {
          this.selectedColumns = [...this.cols];
          this.onFilePreferenceTypeSelect();
        }
        this.scrollToBottom();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get file(s). Please contact support', '');
    }
    this.showSpinner = false;
  }

  public async saveFilesPreferences(): Promise<void> {
    this.saveSpinner = true;
    try {
      const retval = await this.filePreferenceApiService.SaveFilesPreferences(this.files);
      if (!retval) this.toastMessage('error', 'Failed to save file(s) preferences. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.toastMessage('success', retval.errorMessage, '');
    } catch (error) {
      this.toastMessage('error', 'Failed to get save file(s) preferences. Please contact support', '');
    }
    this.saveSpinner = false;
  }

  public onAllSelect(checked: boolean): void {
    const filteredFiles: FilePreferences[] = this.files.filter(f => f.dbnNumber.includes(this.filePreferenceSearch));

    const filePreferences: FilePreferenceType[] = this.selectedColumns.map(c => c.filePreferenceTypeId);

    filteredFiles.filter(f => {
      f.enabled = checked;
      f.preferences.filter(fe => filePreferences.includes(fe.filePreferenceTypeId))
        .forEach(fe => fe.enabled = checked);
    });

    this.selectedColumns.filter(c => c.enabled = checked);
  }

  public onColumnAllSelect(checked: boolean, filePreferenceTypeId: FilePreferenceType): void {
    const filteredFiles: FilePreferences[] = this.files.filter(f => f.dbnNumber.includes(this.filePreferenceSearch));

    filteredFiles.filter(f => f.preferences.filter(fe => {
      if (fe.filePreferenceTypeId === filePreferenceTypeId) {
        fe.enabled = checked;
        this.OnFilePreferenceSelect(f.fileId, fe.filePreferenceTypeId);
      }
    }));
  }

  public onRowAllSelect(checked: boolean, fileId: number): void {
    const filteredFiles: FilePreferences[] = this.files.filter(f => f.dbnNumber.includes(this.filePreferenceSearch));

    const filePreferences: FilePreferenceType[] = this.selectedColumns.map(c => c.filePreferenceTypeId);

    filteredFiles.filter(f => {
      if (f.fileId === fileId) {
        f.preferences.filter(fe => filePreferences.includes(fe.filePreferenceTypeId))
          .forEach(fe => {
            fe.enabled = checked;
            this.OnFilePreferenceSelect(f.fileId, fe.filePreferenceTypeId);
          });
      }
    });
  }

  public OnFilePreferenceSelect(fileId: number, filePreferenceTypeId: FilePreferenceType): void {
    const file = this.files.find(f => f.fileId === fileId);

    const column = this.cols.find(c => c.filePreferenceTypeId === filePreferenceTypeId);

    const filteredFiles: FilePreferences[] = this.files.filter(f => f.dbnNumber.includes(this.filePreferenceSearch));

    const allFilesForColumnChecked = filteredFiles.every(f => {
      const x = f.preferences.find(fe => fe.filePreferenceTypeId === filePreferenceTypeId);
      return x?.enabled;
    });

    column.enabled = allFilesForColumnChecked;

    file.enabled = this.selectedColumns.every(selectedColumn => {
      const filePreference = file.preferences.find(fe => fe.filePreferenceTypeId === selectedColumn.filePreferenceTypeId && (!this.filePreferenceSearch || file.dbnNumber.includes(this.filePreferenceSearch)));
      return filePreference?.enabled;
    });

    this.handleAllFilesSelected();
  }

  public handleAllFilesSelected(): void {
    const filteredFiles: FilePreferences[] = this.files.filter(f => f.dbnNumber.includes(this.filePreferenceSearch));

    const filePreferences: FilePreferenceType[] = this.selectedColumns.map(c => c.filePreferenceTypeId);

    if (filePreferences?.length == 0) {
      this.allFilesSelected = false;
      return;
    }

    this.allFilesSelected = filteredFiles.every(f => {
      return filePreferences.every(filePreferenceTypeId => {
        return f.preferences.some(fe => fe.filePreferenceTypeId === filePreferenceTypeId && fe.enabled === true);
      });
    });
  }

  public onFilePreferenceTypeSelect(): void {
    const filteredFiles: FilePreferences[] = this.files.filter(f => f.dbnNumber.includes(this.filePreferenceSearch));

    filteredFiles.filter(f => f.preferences.filter(fe => this.OnFilePreferenceSelect(f.fileId, fe.filePreferenceTypeId)));
  }

  public scrollToBottom(): void {
    setTimeout(() =>
      window.scroll({
        top: document.body.scrollHeight - window.innerHeight,
        behavior: "smooth",
      }), 105
    );
  }

  private toastMessage(severity: string, summary: string, detail: string): void { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}