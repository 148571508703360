import { Component, OnInit, ViewChild } from '@angular/core';
import { Accordion, ConfirmationService, Table } from 'primeng';
import { MessageService } from 'primeng/api';
import { LogBase } from '../../../services/logger.service';
import { Title } from "@angular/platform-browser";
import { BorderPost, Dropdown, TableColumns } from "../../../interfaces/global.interfaces";
import { ReferenceApiService } from '../../../services/api/reference-api.service';
import * as moment from 'moment';
import { HandoverMatrix, PartialHandoverMatrix, ROROCIFBorderPostMatrix, ROROCIFMatrix, ROROCIFMatrixItems, ROROMatrix, StandardMatrix } from '../../../interfaces/finance-interfaces';
import * as _ from 'lodash';
import { GeneralStatus } from '../../../enums';

@Component({
  selector: 'app-manage-invoice-items',
  templateUrl: './manage-invoice-items.component.html',
  styleUrls: ['./manage-invoice-items.component.scss'],
  providers: [ConfirmationService]
})
export class ManageInvoiceItemsComponent implements OnInit {
  @ViewChild('we') public roroCIFMatrixTable: Table;
  @ViewChild('accordion') accordion: Accordion;

  public roroMatrixColumns: TableColumns[] = [
    { field: 'borderPost', header: 'Border Post' },
    { field: 'lengthStart', header: 'Length Start' },
    { field: 'lengthEnd', header: 'Length End' },
    { field: 'clearingRate', header: 'Clearing Rate' },
    { field: 'deliveryRate', header: 'Delivery Rate' },
    { field: 'tollRate', header: 'Toll Rate' },
    { field: 'bankCharge', header: 'Bank Charge' }
  ];
  public standardMatrixColumns: TableColumns[] = [
    { field: 'pastelCode', header: 'Pastel Code' },
    { field: 'description', header: 'Description' },
    { field: 'amountRand', header: 'Amount Rand' },
  ];
  public partialHandoverMatrixColumns: TableColumns[] = [
    { field: 'pastelCode', header: 'Pastel Code' },
    { field: 'description', header: 'Description' },
    { field: 'amount', header: 'Amount' }
  ];
  public handoverMatrixColumns: TableColumns[] = [
    { field: 'name', header: 'Shipping Company' },
    { field: 'pastelCode', header: 'Pastel Code' },
    { field: 'description', header: 'Description' },
    { field: 'amount', header: 'Amount' }
  ];
  public roroCIFMatrixColumns: TableColumns[] = [
    { field: 'startLength', header: 'Start Length' },
    { field: 'endLength', header: 'End Length' },
    { field: 'costUSD', header: 'Cost USD' }
  ];

  public shippingLines: Dropdown[] = [];
  public showLoadingSpinner: boolean = false;
  public roroMatrix: ROROMatrix[] = [];
  public handoverMatrix: HandoverMatrix[] = [];
  public standardMatrix: StandardMatrix[] = [];
  public partialHandoverMatrix: PartialHandoverMatrix[] = [];
  public selectedROROCIFMatrix: ROROCIFMatrix | undefined;
  public roroCIFBorderPostMatrix: ROROCIFBorderPostMatrix[] = [];

  public selectedShippingLine: number | undefined;
  public pastelCode: string = '';
  public description: string = '';
  public amount: number | undefined;
  public showHandoverMatrixDialog: boolean = false;

  public standardPastelCode: string = '';
  public standardDescription: string = '';
  public standardLineAmount: number | undefined;
  public showStandardMatrixDialog: boolean = false;

  public partialHandoverPastelCode: string;
  public partialHandoverDescription: string;
  public partialHandoverAmount: number | undefined;
  public showPartialHandoverMatrixDialog: boolean = false;

  public toDateYearRange: string = '';
  public selectedBorderPost: BorderPost | undefined;
  public borderPosts: BorderPost[];
  public generalStatusOptions: Dropdown[] = [];
  public showNewROROCIFMatrixDialog: boolean = false;
  public roroCIFMatrixIssues: string = '';
  public selectedBorderPostIndex: number | undefined;

  public actionSpinner: boolean = false;

  public get generalStatus(): typeof GeneralStatus { return GeneralStatus; }

  constructor(
    private referenceApiService: ReferenceApiService,
    private messageService: MessageService,
    private log: LogBase,
    private titleService: Title
  ) {
    this.titleService.setTitle("AVECS Manage Invoice Defaults");
  }

  async ngOnInit() {
    this.showLoadingSpinner = true;
    try {
      await this.getShippingLines();
      await this.getROROMatrix();
      await this.getHandoverMatrix();
      await this.getPartialHandoverMatrix();
      await this.getStandardMatrix();
      await this.getROROCIFMatrix();
      await this.getBorderPosts();
      await this.getGeneralStatus();
      const currentYear = new Date().getFullYear();
      //year range format 2000:2030
      this.toDateYearRange = `${currentYear - 5}:${currentYear + 5}`;
    } catch (err) {
      this.toastMessage('error', 'Failed to initialize the page, please contact support.', '');
      this.log.error(err);
    }
    this.showLoadingSpinner = false;
  }

  private async getBorderPosts() {
    try {
      const retval = await this.referenceApiService.getBorderPosts();
      if (!retval) this.toastMessage('error', 'Failed to get border posts. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.borderPosts = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get border posts. Please contact support.', '');
      this.log.error(err);
    }
  }

  private async getGeneralStatus() {
    try {
      const retval = await this.referenceApiService.getGeneralStatus();
      if (!retval) this.toastMessage('error', 'Failed to get general status. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.generalStatusOptions = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get general status. Please contact support.', '');
      this.log.error(err);
    }
  }

  private async getShippingLines() {
    try {
      const retval = await this.referenceApiService.getShippingLines();
      if (!retval) this.toastMessage('error', 'Failed to get Shipping Lines. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.shippingLines = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get Shipping Lines. Please contact support', '');
      this.log.error(err);
    }
  }

  private async getROROMatrix() {
    try {
      const retval = await this.referenceApiService.getROROMatrix();
      if (!retval) this.toastMessage('error', 'Failed to get RORO matrix. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.roroMatrix = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get RORO matrix. Please contact support', '');
      this.log.error(err);
    }
  }

  private async getHandoverMatrix() {
    try {
      const retval = await this.referenceApiService.GetHandoverMatrix();
      if (!retval) this.toastMessage('error', 'Failed to get Handover matrix. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.handoverMatrix = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get Handover matrix. Please contact support', '');
      this.log.error(err);
    }
  }

  private async getPartialHandoverMatrix() {
    try {
      const retval = await this.referenceApiService.GetPartialHandoverMatrix();
      if (!retval) this.toastMessage('error', 'Failed to get Partial Handover matrix. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.partialHandoverMatrix = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get Partial Handover matrix. Please contact support', '');
      this.log.error(err);
    }
  }

  private async getStandardMatrix() {
    try {
      const retval = await this.referenceApiService.GetStandardMatrix();
      if (!retval) this.toastMessage('error', 'Failed to get Standard matrix. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.standardMatrix = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get Standard matrix. Please contact support', '');
      this.log.error(err);
    }
  }

  public async updateROROMatrix(row: ROROMatrix) {
    try {
      if (row.clearingRate === 0) {
        this.toastMessage('error', 'Clearing rate cannot be 0', '');
        return;
      } else if (!Number(row.clearingRate)) {
        this.toastMessage('error', 'Clearing rate is not a number', '');
        return;
      } else if (row.deliveryRate === 0) {
        this.toastMessage('error', 'Delivery rate cannot be 0', '');
        return;
      } else if (!Number(row.deliveryRate)) {
        this.toastMessage('error', 'Delivery rate is not a number', '');
        return;
      } else if (row.tollRate != 0 && !Number(row.tollRate)) {
        this.toastMessage('error', 'Toll rate is not a number', '');
        return;
      }

      const retval = await this.referenceApiService.updateROROMatrix(row);
      if (!retval) this.toastMessage('error', 'Error', 'Failed to update RORO matrix. Please contact support.');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.toastMessage('success', 'Success', retval.errorMessage);
    } catch (error) {
      this.log.error(error);
    }
  }

  public async AddStandardMatrix() {
    this.actionSpinner = true;
    try {
      const retval = await this.referenceApiService.addStandardMatrix(this.standardPastelCode, this.standardDescription, this.standardLineAmount);
      if (!retval) this.toastMessage('Error', 'Error', 'Failed to add Standard Matrix. Please contact support.');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        await this.getStandardMatrix();
        this.toastMessage('success', 'Success', retval.errorMessage);
        this.showStandardMatrixDialog = false;
        this.standardPastelCode = '';
        this.standardDescription = '';
        this.standardLineAmount = 0;
      }
    } catch (error) {
      this.log.error(error);
    }
    this.actionSpinner = false;
  }

  public async updateStandardMatrix(rowData: StandardMatrix) {
    try {
      if (rowData.amount !== 0 && !Number(rowData.amount)) {
        this.toastMessage('error', 'Amount is not a number', '');
        return;
      }

      const retval = await this.referenceApiService.updateStandardMatrix(rowData.id, rowData.amount);
      if (!retval) this.toastMessage('Error', 'Error', 'Failed to update Standard Matrix. Please contact support.');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        await this.getStandardMatrix();
        this.toastMessage('success', 'Success', retval.errorMessage);
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async addPartialHandoverMatrix() {
    this.actionSpinner = true;
    try {
      const payload = {
        description: this.partialHandoverDescription,
        pastelCode: this.partialHandoverPastelCode,
        amount: this.partialHandoverAmount
      };
      const retval = await this.referenceApiService.addPartialHandoverMatrix(payload);
      if (!retval) this.toastMessage('error', 'Failed to add partial handover matrix. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', 'Success', retval.errorMessage);
        await this.getPartialHandoverMatrix();
        this.showPartialHandoverMatrixDialog = false;
        this.partialHandoverAmount = null;
        this.partialHandoverDescription = null;
        this.partialHandoverPastelCode = null;
      }
    } catch (error) {
      this.log.error(error);
    }
    this.actionSpinner = false;
  }

  public async updatePartialHandoverMatrix(row: PartialHandoverMatrix) {
    try {
      if (!Number(row.amount)) {
        this.toastMessage('error', 'Amount is not a number', '');
        return;
      }

      const retval = await this.referenceApiService.updatePartialHandoverMatrix(row);
      if (!retval) this.toastMessage('error', 'Failed to update partial handover matrix. Please contact support.', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        this.toastMessage('success', retval.errorMessage, '');
        await this.getPartialHandoverMatrix();
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async addHandoverMatrix() {
    this.actionSpinner = true;
    try {
      const retval = await this.referenceApiService.addHandoverMatrix(this.selectedShippingLine, this.pastelCode, this.description, this.amount);
      if (!retval) this.toastMessage('error', 'Error', 'Failed to add handover matrix. Please contact support.');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else {
        await this.getHandoverMatrix();
        this.selectedShippingLine = undefined;
        this.amount = undefined;
        this.pastelCode = '';
        this.description = '';
        this.toastMessage('success', 'Success', retval.errorMessage);
        this.showHandoverMatrixDialog = false;
      }
    } catch (error) {
      this.log.error(error);
      this.toastMessage('error', 'Error', 'Failed to add handover matrix. Please contact support.');
    }
    this.actionSpinner = false;
  }

  public async updateHandoverMatrix(row: HandoverMatrix) {
    try {
      if (row.amount !== 0 && !Number(row.amount)) {
        this.toastMessage('error', 'Amount is not a number', '');
        return;
      }

      const retval = await this.referenceApiService.updateHandoverMatrix(row);
      if (!retval) this.toastMessage('error', 'Error', 'Failed to update handover matrix. Please contact support.');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.toastMessage('success', 'Success', retval.errorMessage);
    } catch (error) {
      this.log.error(error);
    }
  }

  private async getROROCIFMatrix() {
    try {
      const retval = await this.referenceApiService.getROROCIFMatrix();
      if (!retval) this.toastMessage('error', 'Failed to get RORO CIF matrix. Please contact support', '');
      else if (retval.errorCode !== 0) this.toastMessage('error', retval.errorMessage, '');
      else this.roroCIFBorderPostMatrix = retval.result;
    } catch (err) {
      this.toastMessage('error', 'Failed to get RORO CIF matrix. Please contact support', '');
      this.log.error(err);
    }
  }

  public async upsertROROCIFMatrix() {
    this.actionSpinner = true;
    try {
      const datesEditted: boolean = this.hasUserEdittedDatesForROROCIFMatrix();
      const matrixItemsEditted: boolean = this.hasUserEdittedMatrixItemsForROROCIFMatrix();
      const roroCIFMatrix: ROROCIFMatrix = this.currentROROCIFMatrix();

      if (!datesEditted && !matrixItemsEditted && roroCIFMatrix.statusId === this.selectedROROCIFMatrix.statusId) {
        this.toastMessage('info', 'No edits made', '');
        this.actionSpinner = false;
        return;
      }

      this.handleROROCIFMatrixIssues(datesEditted, matrixItemsEditted);

      if (this.roroCIFMatrixIssues) {
        this.toastMessage('error', 'There are issues to resolve in order to save', '');
        this.actionSpinner = false;
        return;
      }

      this.selectedROROCIFMatrix.fromDate = this.formatDate(this.selectedROROCIFMatrix.fromDate);
      this.selectedROROCIFMatrix.toDate = this.formatDate(this.selectedROROCIFMatrix.toDate);

      const retval = await this.referenceApiService.upsertROROCIFMatrix(this.selectedROROCIFMatrix);
      if (!retval) this.toastMessage('error', 'Failed update RORO CIF Matrix. Please contact support.', '');
      else {
        if (retval.errorCode !== 0) {
          this.toastMessage('error', retval.errorMessage, '');
          this.roroCIFMatrixIssues = retval.result;
          await this.getROROCIFMatrix();
        } else {
          this.toastMessage('success', retval.errorMessage, '');
          await this.getROROCIFMatrix();

          const newROROCIFMatrixId: number | null = retval.result;
          if (newROROCIFMatrixId) {
            const roroCIFMatrix: ROROCIFMatrix = this.roroCIFBorderPostMatrix[this.selectedBorderPostIndex].matrix.find(r => r.id === retval.result);
            this.viewROROCIFMatrix(roroCIFMatrix);
          }
        }
      }
    } catch (error) {
      this.log.error(error);
      this.toastMessage('error', 'Failed update RORO CIF Matrix. Please contact support.', '');
    }
    this.actionSpinner = false;
  }

  public addBorderPostToCIFROROMatrix() {
    const exists = this.roroCIFBorderPostMatrix.find(b => b.locationId === this.selectedBorderPost.locationId);

    if (exists) {
      this.toastMessage('error', 'Border post already exists in matrix', '');
      return;
    }

    this.roroCIFBorderPostMatrix.push({
      borderPost: this.selectedBorderPost.name,
      locationId: this.selectedBorderPost.locationId,
      matrix: []
    });
    this.showNewROROCIFMatrixDialog = false;

    const index = this.roroCIFBorderPostMatrix.findIndex(x => x.locationId === this.selectedBorderPost.locationId);

    setTimeout(() => {
      this.accordion.tabs.map(tab => tab.selected = false);
      this.accordion.tabs[index].selected = true;
      this.scrollToBottom();
    }, 100);
  }

  public addROROCIFMatrix(matrix: ROROCIFBorderPostMatrix) {
    this.roroCIFMatrixIssues = '';
    this.selectedROROCIFMatrix = {
      id: 0,
      borderPost: matrix.borderPost,
      borderPostCode: null,
      locationId: matrix.locationId,
      fromDate: null,
      toDate: null,
      items: [],
      status: 'Active',
      statusId: GeneralStatus.ACTIVE
    };

    this.scrollToBottom();
  }

  public addNewCIFROROMatrixItem() {
    this.selectedROROCIFMatrix.items.push({
      id: 0,
      startLength: null,
      endLength: null,
      costUSD: null,
      cifROROMatrixId: this.selectedROROCIFMatrix.id
    });
  }

  public updateROROCIFMatrixItem(index: number) {
    this.selectedROROCIFMatrix.items.splice(index, 1);
  }

  public handleROROCIFMatrixIssues(datesEditted: boolean, matrixItemsEditted: boolean) {
    this.roroCIFMatrixIssues = '';

    if (datesEditted && !matrixItemsEditted) {
      this.validateMatrixDates();
    } else if (!datesEditted && matrixItemsEditted) {
      this.validateMatrixItems();
    } else if (datesEditted && matrixItemsEditted) {
      this.validateMatrixDates();
      this.validateMatrixItems();
    }

    this.orderSelectedROROCIFMatrixItems();
  }

  private validateMatrixItems() {
    if (this.selectedROROCIFMatrix.items.length === 0) {
      this.roroCIFMatrixIssues += `There are no matrix items\n`;
    }

    for (let i = 0; i < this.selectedROROCIFMatrix.items.length; i++) {
      const currentMatrixItem = this.selectedROROCIFMatrix.items[i];

      if (!currentMatrixItem.startLength && !currentMatrixItem.endLength) {
        this.roroCIFMatrixIssues += `Matrix item ${i + 1} has no start length and end length\n`;
      } else if (!currentMatrixItem.startLength) {
        this.roroCIFMatrixIssues += `Matrix item ${i + 1} has no start length\n`;
      } else if (!currentMatrixItem.endLength) {
        this.roroCIFMatrixIssues += `Matrix item ${i + 1} has no end length\n`;
      }

      if (currentMatrixItem.costUSD !== 0 && !Number(currentMatrixItem.costUSD)) {
        this.roroCIFMatrixIssues += `Matrix item ${i + 1} has no valid cost usd\n`;
      }
    }
  }

  private validateMatrixDates() {
    const selectedFromDate = moment(this.selectedROROCIFMatrix.fromDate);
    const selectedToDate = moment(this.selectedROROCIFMatrix.toDate);

    if (!this.selectedROROCIFMatrix.fromDate && !this.selectedROROCIFMatrix.toDate) {
      this.roroCIFMatrixIssues += 'There is no from date and to date for the matrix.\n';
    } else if (!this.selectedROROCIFMatrix.fromDate) {
      this.roroCIFMatrixIssues += 'There is no from date for the matrix.\n';
    } else if (!this.selectedROROCIFMatrix.toDate) {
      this.roroCIFMatrixIssues += 'There is no to date for the matrix.\n';
    } else if (selectedFromDate.isSame(selectedToDate)) {
      this.roroCIFMatrixIssues += 'From and To date cannot be the same.\n';
    }

    if (selectedToDate.isBefore(selectedFromDate, 'day')) {
      this.roroCIFMatrixIssues += 'Cannot add the matrix. The from date is before the to date.\n';
    }
  }

  public viewROROCIFMatrix(roroCIFMatrix: ROROCIFMatrix) {
    this.selectedROROCIFMatrix = _.cloneDeep(roroCIFMatrix);
    this.selectedROROCIFMatrix.fromDate = this.formatDate(this.selectedROROCIFMatrix.fromDate);
    this.selectedROROCIFMatrix.toDate = this.formatDate(this.selectedROROCIFMatrix.toDate);
    this.orderSelectedROROCIFMatrixItems();
    this.roroCIFMatrixIssues = '';
    this.scrollToBottom();
  }

  public orderSelectedROROCIFMatrixItems() {
    this.selectedROROCIFMatrix.items = _.orderBy(this.selectedROROCIFMatrix.items, ['startLen'], ['asc']);
  }

  private hasUserEdittedDatesForROROCIFMatrix(): boolean {
    // it's a new matrix the user is adding so return true
    if (this.selectedROROCIFMatrix.id === 0) {
      return true;
    }

    const roroCIFMatrix: ROROCIFMatrix = this.currentROROCIFMatrix();

    if (this.formatDate(roroCIFMatrix.fromDate) !== this.formatDate(this.selectedROROCIFMatrix.fromDate) || this.formatDate(roroCIFMatrix.toDate) !== this.formatDate(this.selectedROROCIFMatrix.toDate)) {
      return true;
    }

    return false;
  }

  private hasUserEdittedMatrixItemsForROROCIFMatrix(): boolean {
    // it's a new matrix the user is adding so return true
    if (this.selectedROROCIFMatrix.id === 0) {
      return true;
    }

    const roroCIFMatrix: ROROCIFMatrix = this.currentROROCIFMatrix();

    for (let index = 0; index < roroCIFMatrix.items.length; index++) {
      const matrixItem: ROROCIFMatrixItems = roroCIFMatrix.items[index];

      if (matrixItem.costUSD !== this.selectedROROCIFMatrix.items[index].costUSD) return true;
    }

    if (roroCIFMatrix.items.length !== this.selectedROROCIFMatrix.items.length) return true;

    return false;
  }

  public roroCIFMatrixToolTip(): string {
    if (!this.selectedROROCIFMatrix || this.selectedROROCIFMatrix.id === 0) return '';

    const datesEditted: boolean = this.hasUserEdittedDatesForROROCIFMatrix();
    const matrixItemsEditted: boolean = this.hasUserEdittedMatrixItemsForROROCIFMatrix();

    const roroCIFMatrix: ROROCIFMatrix = this.currentROROCIFMatrix();

    if (roroCIFMatrix.statusId !== this.selectedROROCIFMatrix.statusId && datesEditted && matrixItemsEditted) {
      return 'Cannot save changes because you have made changes to the matrix dates and items';
    } else if (roroCIFMatrix.statusId !== this.selectedROROCIFMatrix.statusId && datesEditted) {
      return 'Cannot save changes because you have changed the matrix dates';
    } else if (roroCIFMatrix.statusId !== this.selectedROROCIFMatrix.statusId && matrixItemsEditted) {
      return 'Cannot save changes because you have changed the matrix items';
    }

    return '';
  }

  public currentROROCIFMatrix(): ROROCIFMatrix | null {
    if (!this.selectedROROCIFMatrix) return null;
    const borderPostROROCIFMatrix: ROROCIFBorderPostMatrix = this.roroCIFBorderPostMatrix.find(r => r.locationId === this.selectedROROCIFMatrix.locationId);
    return borderPostROROCIFMatrix.matrix.find(m => m.id === this.selectedROROCIFMatrix.id);
  }

  public scrollToBottom() {
    setTimeout(() =>
      window.scroll({
        top: document.body.scrollHeight - window.innerHeight,
        behavior: "smooth",
      }), 105
    );
  }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
  public formatDate(date) { return moment(date).format('YYYY/MM/DD'); }
}