import { WhatsappApiService } from '../../../services/api/whatsapp-api.service';
import { WAMedia, WAMessage } from '../../../interfaces/global.interfaces';
import { LogBase } from './../../../services/logger.service';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from "lodash";

@Component({
  selector: 'app-whatsapp-chats',
  templateUrl: './whatsapp-chats.component.html',
  styleUrls: ['./whatsapp-chats.component.scss']
})
export class WhatsappChatsComponent implements OnInit {

  @Input() fileNumber: string = null;
  showSpinner: boolean = false;

  chatMessages: WAMessage[] = null;
  selectedChatMessages: WAMessage[] = null;
  selectedDate: any = null;
  chatMessageDates: any[];
  chatMedia: WAMedia[] = null;
  viewMedia: boolean = false;
  mediaToView: any[] = [];
  base64: string = null;

  constructor(
    private log: LogBase,
    private messageService: MessageService,
    private whatsappService: WhatsappApiService,
    private http: HttpClient
  ) { }

  async ngOnInit() {
    this.showSpinner = true
    if (this.fileNumber) {
      await this.getChatMessagesByRefNo();
      await this.getChatMedia();
    }
    this.showSpinner = false;
  }

  async refresh() {
    this.showSpinner = true;
    await this.getChatMessagesByRefNo();
    await this.getChatMedia();
    this.showSpinner = false;
  }

  async getChatMessagesByRefNo() {
    try {
      const messages = await this.whatsappService.WAGetChatMessagesByRefNo(this.fileNumber);
      if (!messages) this.toastMessage('error', 'Failed to get chat messages by ref no. Please contact support.', '');
      else if (messages.errorCode != 0) this.toastMessage('error', messages.errorMessage, '');
      else {
        this.chatMessages = messages.result;
        this.getAllDatesFromChatMessages();
      }
    } catch (error) {
      this.toastMessage('error', 'Failed to get chat messages by ref no. Please contact support.', '');
      this.log.error(error)
    }
  }

  async getChatMedia() {
    try {
      const media = await this.whatsappService.WAGetChatMedia(this.fileNumber);
      if (!media) this.toastMessage('error', 'Failed to get chat media by ref no. Please contact support.', '');
      else if (media.errorCode != 0) this.toastMessage('error', media.errorMessage, '');
      else this.chatMedia = media.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get chat media by ref no. Please contact support', '');
      this.log.error(error)
    }
  }

  getAllDatesFromChatMessages() {
    let dates: any[] = [];
    this.chatMessageDates = [];

    for (var m of this.chatMessages) {
      dates.push({ date: this.formatDate(m.insertDate) });
    }

    let uniqueDates = _.uniqBy(dates, 'date');
    uniqueDates = _.orderBy(uniqueDates, ['date'], ['desc']);

    this.chatMessageDates.push({ date: 'All' });

    for (var d of uniqueDates) {
      this.chatMessageDates.push(d)
    }

    this.selectedDate = this.chatMessageDates[0];
    this.selectedChatMessages = this.chatMessages;
  }

  viewWAMediaPreview(file: WAMedia) {
    this.mediaToView[0] = file.serveFilepath;
    this.viewMedia = true;
  }

  closeWAMediaPreview() {
    this.mediaToView = [];
    this.viewMedia = false;
  }

  downloadMedia(file: WAMedia) {
    try {
      this.http.get(file.serveFilepath, { responseType: "blob" }).subscribe((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = async (event: any) => {
          this.base64 = event.target.result;
          this.base64 = this.base64.replace(`data:${file.mimetype};base64,`, '');
          this.save(file.filename, this.base64, false, file.mimetype);
        };

        reader.onerror = async (event: any) => {
          this.log.error('Failed: file could not be read', event.target.error.code, 'whatsapp-chats.downloadMedia()');
          this.base64 = null;
          this.toastMessage('File could not be downloaded', '', 'Failed');
        };
      });
    } catch (err) {
      this.log.error('Failed: file could not be read', 'whatsapp-chats.downloadMedia()');
      this.toastMessage('File could not be downloaded.', '', 'Failed');
    }
  }

  private save(name, data, isBinary, type) {
    if (isBinary) {
      var bytes = new Array(data.length);
      for (var i = 0; i < data.length; i++) {
        bytes[i] = data.charCodeAt(i);
      }
      data = new Uint8Array(bytes);
    }
    var blob = this.base64ToBlob(data, 512, type);
    let objectURL = window.URL.createObjectURL(blob);
    let anchor = document.createElement('a');
    anchor.href = objectURL;
    anchor.download = name;
    anchor.click();
    URL.revokeObjectURL(objectURL);
  }

  private base64ToBlob(b64Data, sliceSize = 512, type) {
    let byteCharacters = atob(b64Data); //data.file there
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: type });
  }

  getChatMessagesByDate() {
    if (this.selectedDate.date == 'All') {
      this.selectedChatMessages = this.chatMessages;
    } else {
      this.selectedChatMessages = this.chatMessages.filter(x => this.formatDate(x.insertDate) === this.selectedDate.date)
    }
  }

  formatDate(date: any) {
    return moment(date).format('yy/MM/DD')
  }

  formatTime(time: any) {
    return moment(time).format('YYYY/MM/DD - h:mm a');
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail })}
}