
<p-progressSpinner id="p-spinner" *ngIf="showSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8"
  fill="#EEEEEE" animationDuration="2s"></p-progressSpinner>

<div class="ui-g card card-w-title" *ngIf="!showSpinner">
  <div class="ui-g-12">
    <h1>Contacts</h1>
  </div>
  <p-table class="ui-g-12" #dt [value]="contacts" [pageLinks]="3" [rows]="30" [paginator]="true" [(selection)]="selectedContact" [autoLayout]="true"
    dataKey="Id" selectionmode="single" [globalFilterFields]="['Name']">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left ">
          <p-tieredMenu #menu [model]="menuItems" [popup]="true"></p-tieredMenu>
          <button pRipple class="p-button-info ui-button-success" #btn (click)="menu.toggle($event)" type="button"
            icon="fas fa-plus" pButton pTooltip="Add Contact">
          </button>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="Name">Name<p-sortIcon field="Name"></p-sortIcon>
        </th>
        <th pSortableColumn="Email">Email<p-sortIcon field="Email"></p-sortIcon>
        </th>
        <th pSortableColumn="Telephone">Telephone<p-sortIcon field="Telephone"></p-sortIcon>
        </th>
        <th pSortableColumn="Cellphone">Cellphone<p-sortIcon field="Cellphone"></p-sortIcon>
        </th>
        <th>Actions</th>
      </tr>
      <tr>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'Name', 'startsWith')"
            placeholder="Search by Name" class="p-column-filter width-100">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'Email', 'startsWith')"
            placeholder="Search by Email" class="p-column-filter width-100">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'Telephone', 'startsWith')"
            placeholder="Search by Telephone" class="p-column-filter width-100">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'Cellphone', 'startsWith')"
            placeholder="Search by Cellphone" class="p-column-filter width-100">
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-contact>
      <tr [pSelectableRow]="contact" (dblclick)="routeToLegalEntityEdit(contact.Id, contact.LegalEntityTypeId)">
        <td>
          <i *ngIf="contact.LegalEntityTypeId == 2" class="far fa-building"></i>
          <i *ngIf="contact.LegalEntityTypeId ==1" class="fas fa-user-tie"></i>
          {{contact.Name}}
        </td>
        <td>{{contact.Email}}</td>
        <td>{{contact.Telephone}}</td>
        <td>{{contact.Cellphone}}</td>
        <td>
          <button *ngIf="contact.LegalEntityTypeId == 2" pRipple pButton class="p-button-info" label="View"
            tooltipPosition="left" pTooltip="View Company"
            icon="far fa-building" (click)="routeToLegalEntityEdit(contact.Id,contact.LegalEntityTypeId)">
          </button>
          <button *ngIf="contact.LegalEntityTypeId ==1" pRipple pButton class="p-button-info" label="View"
            icon="fas fa-user-tie" (click)="routeToLegalEntityEdit(contact.Id,contact.LegalEntityTypeId)">
          </button>
          <button pButton *ngIf="contact.LegalEntityTypeId ==1" type="button" icon="fas fa-qrcode"
            class="ui-button-rounded" (click)="setupVCard(contact.Id)" pTooltip="View QR Code"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog header="Confirmation" icon="far fa-question-circle" width="425" #cd>
  <p-footer>
    <button pRipple class="p-button-info" type="button" pButton icon="far fa-times-circle" label="No"
      (click)="cd.reject()"></button>
    <button pRipple class="p-button-info" type="button" pButton icon="far fa-check-circle" label="Yes"
      (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>


<p-dialog header="Scan QR Code for Contact Info" [style]="{width: '20vw'}" [dismissableMask]="true"
  [(visible)]="displayQRCode" [responsive]="true" showEffect="fade" [modal]="true">
  <div class="ui-g">
    <div class="ui-g-12 centerInDiv">
      <qr-code [value]="vCardData" [size]="200"></qr-code>
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" label="Done" type="button" (click)="displayQRCode = false" pButton></button>
  </p-footer>
</p-dialog>