<div class="ui-g-12 center" *ngIf="showSpinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration="2s">
  </p-progressSpinner>
</div>

<div *ngIf="!showSpinner && userTask" class="ui-g card card-w-title">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 file-number-header">
      <h3>WhatsApp Talk To Agent For </h3>
      <file-number [fileNumber]="userTask.dbnNumber"></file-number>
    </div>
  </div>

  <div class="ui-g-12">
    <p-panel [style]="{'height':'180px'}">
      <p-header>
        Client Information
      </p-header>

      <div class="ui-g-4">
        <div class="information">
          <strong>First Name</strong>
          <label>
            {{ userTask.legalEntityFirstName || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Last Name</strong>
          <label>
            {{ userTask.legalEntityLastName || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Whatsapp User Number</strong>
          <label>
            {{ userTask.whatsappUserNumber || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Product Number</strong>
          <label>
            {{ userTask.productNumber || 'N/A' }}
          </label>
        </div>
      </div>

      <div class="ui-g-4">
        <div class="information">
          <strong>Chat Ref Number</strong>
          <label>
            {{ userTask.refNo || 'N/A' }}
          </label>
        </div>
      </div>
    </p-panel>

    <div class="ui-g-12 user-task-text">
      <label>The whatsapp user has requested to talk to an agent. Please go log into WhatsApp and find their chat.
      </label>
    </div>

    <div class="actions ui-g-12">
      <button pRipple pButton label="Mark user task as completed and close WhatsApp Chat" [disabled]="postSpinner"
        (click)="showDialog=true"></button>
      <button pRipple pButton label="Go to WhatsApp" (click)="goToWhatsapp()" [disabled]="postSpinner"></button>
    </div>
  </div>
</div>

<p-dialog header="Confirmation" [dismissableMask]="true" [positionTop]="60" *ngIf="showDialog"
  [contentStyle]="{width: '650px', height: '90px'}" [responsive]="true" [draggable]="false"
  (onHide)="showDialog = false;" [(visible)]="showDialog" [modal]="true">
  <label>Are you sure you want to mark the user task as completed and close the WhatsApp chat?</label>
  <div class="actions ui-g-12">
    <button *ngIf="!postSpinner" pRipple pButton label="Yes" (click)="completeUserTask()"></button>
    <button *ngIf="postSpinner" pRipple class="p-button-info submit" pButton type="button" [disabled]="true">
      <i class="pi pi-spin pi-spinner font-size-2em"></i>
    </button>
    <button pRipple class="p-button-info submit" pButton type="button" [disabled]="postSpinner" label="No">
    </button>
  </div>
</p-dialog>