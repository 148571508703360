
<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h3>{{openDisplayType}}</h3>
    <p-steps [model]="items" [(activeIndex)]="index" [readonly]="true"></p-steps>
  </div>

  <div class="ui-g-12">
    <app-shipping-details *ngIf="index==0" [activeIndex]="index" (indexEventFunction)="updateIndexEvent($event)">
    </app-shipping-details>
    <app-consignee-details *ngIf="index==1" [activeIndex]="index" (indexEventFunction)="updateIndexEvent($event)">
    </app-consignee-details>
    <app-file-items *ngIf="index==2" [activeIndex]="index" (canCreateFile)="SetCreateFileStatus($event)"
      (indexEventFunction)="updateIndexEvent($event)">
    </app-file-items>
  </div>
  <div class="p-col-12 p-lg-4 p-md-4">
    <button pRipple pButton class="p-button-info margin-right-5px" *ngIf="!showSpinner" icon="fa fa-times-circle"
      iconPos="right" label="Cancel File" (click)="displayCancel = true"></button>
    <button pRipple pButton class="p-button-info " *ngIf="!showSpinner" [disabled]="!CreateFile"
      icon="fa fa-arrow-circle-right" iconPos="right" label="Create File" (click)="createFile()"></button>
  </div>

  <div class="p-col-12 p-lg-10 p-md-6">
    <p-progressSpinner *ngIf="showSpinner" [style]="{width: '50px', height: '50px'}" strokeWidth="6" fill="#EEEEEE"
      animationDuration="1s"></p-progressSpinner>
  </div>
</div>

<p-dialog header="Attention" modal="true" [(visible)]="displayCancel" [style]="{width: '25vw'}" [baseZIndex]="10000">
  <div class="ui-g">
    <div class="ui-g-12">
      <label>You have not completed the creation of the file. Are you sure you want to cancel?</label>
    </div>

    <div class="ui-g-12">
      <button pRipple class="p-button-info" pButton type="button" (click)="clearStorageAndGoToTaskList()"
        label="Yes"></button>
      <button pRipple class="p-button-info" pButton type="button" (click)="displayCancel = false"
        label="Cancel"></button>
    </div>
  </div>
</p-dialog>