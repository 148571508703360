<div class="ui-g card card-w-title">
  <div class="ui-g-12">
    <h1>Containers</h1>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-12 centerSpinner" *ngIf="showLoadingSpinner">
      <p-progressSpinner id="p-spinner" [style]="{ width: '30px', height: '30px' }" strokeWidth="6" fill="#EEEEEE"
        animationDuration="2s"></p-progressSpinner>
    </div>
  </div>

  <div class="ui-g-12">
    <p-table #dt [value]="containers" [rows]="50" [paginator]="true" selectionmode="single" [scrollable]="true"
      scrollHeight="350px" [(selection)]="selectedContainer" dataKey="Id" [globalFilterFields]="['ContainerNumber',
      'ContainerDescription', 'Code']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left">
            <button pRipple class="p-button-info ui-button-success" pButton pTooltip="Add Container"
              (click)="showContainerDialog()" type="button" style="float: right; margin-right: 15px"
              icon="fas fa-plus"></button>
          </span>
          <span class="p-input-icon-left p-ml-auto">
            <i style="color: white;" class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Search containers" style="color: #fff !important;" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Container Number</th>
          <th>Description</th>
          <th>Length</th>
          <th>Height</th>
          <th>Code</th>
          <th>Added</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-container>
        <tr>
          <td>{{container.ContainerNumber}}</td>
          <td>{{container.ContainerDescription}}</td>
          <td>{{container.Length}}</td>
          <td>{{container.Height}}</td>
          <td>{{container.Code}}</td>
          <td>{{container.Added}}</td>
          <td>
            <button pRipple pButton class="p-button-info" icon="far fa-folder" pTooltip="View Container Items"
              tooltipPosition="bottom" (click)="viewContainer(container.ContainerNumber)">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="ui-g-12 ui-md-4">
  <button pRipple class="p-button-info" *ngIf="showBackBtn" pButton type="button" label="Back" style="width:50%;"
    (click)="goBack()"></button>
</div>

<p-dialog header="Add Container:" modal="true" responsive="true" [(visible)]="addContainerDialogVisible" width="700"
  height="300">
  <div class="ui-g">
    <div class="ui-g-3">
      <label>Container Number: </label>
    </div>
    <div class="ui-g-9">
      <input class="width-100" type="text" pInputText [(ngModel)]="ContainerNumber" />
    </div>
    <div class="ui-g-3">
      <label>ISO Container Description: </label>
    </div>
    <div class="ui-g-9">
      <p-dropdown [style]="{width:'100%'}" [autoDisplayFirst]="false" appendTo="body" [options]="ISOContainerGroups"
        optionLabel="Description" [(ngModel)]="SelectedISOCode" [filter]="true">
      </p-dropdown>
    </div>
    <div class="ui-g-12">
      <button pRipple class="p-button-info width-100" pButton type="button"
        [disabled]="!ContainerNumber || !SelectedISOCode" (click)="addContainer()" label="Add"></button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Warning:" modal="true" [(visible)]="addContainerWarningDialogVisible" width="500" height="300">
  <div class="ui-g">
    <div class="ui-g-12">
      <label><b>The Container already exists in the database.</b></label>
    </div>
  </div>
  <p-footer>
    <button pRipple class="p-button-info" pButton type="button" (click)="hideContainerWarningDialog()"
      label="Okay"></button>
  </p-footer>
</p-dialog>